import React, { useState, useRef, useEffect} from 'react';
import {QRCodeCanvas} from 'qrcode.react';
import {useLocation,  Link} from "react-router-dom";
import Layout from "../Layout/Layout";
import Form from 'react-bootstrap/Form'
import AdminFamily from "../pages/addAdminFamily"
import {creatTokenPUB, isAuthenticated, AllMyUserReF} from "../auth";
import { ELAKAY_URl1 } from "../../config"
import CardUSer from "../pageInside/cardUser"
import FilterUserTeam from "../pageInside/filterUserTeam"
import SearchBar from '../pageInside/admin_associate_searchBar';
import ShopUserView from "./lakayUser/ShopUser";
import Modal from 'react-bootstrap/Modal'
import { Row } from 'react-bootstrap';
import AlertPage from '../pageInside/alertPage';
import "./admin_associate.css";
import { prices } from "../pageInside/fixedPrices";
import { FaUser,  FaStore, FaUsersBetweenLines} from "react-icons/fa6";
import { AiFillProduct } from "react-icons/ai";
import Filter_searchProductUser from '../pageInside/filter_searchProductUser';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const MarketplacePage = (infoSource) => {
  let marketplaceContent = infoSource.laguangeElakay('admAssPage', infoSource.languageAPI)
  return (
    <div className="content p-4">
      <div className="alert alert-info p-4" style={{ overflow: 'auto', textAlign: 'center' }}>
        {marketplaceContent.map((section, index) => (
          <section key={index} style={{ marginBottom: '20px' }}>
            <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>{section.title}</h2>
            {section.text.map((paragraph, idx) => (
              <p key={idx} dangerouslySetInnerHTML={{ __html: paragraph }} style={{ textAlign: 'left'}}></p>
            ))}
            {section.list.length > 0 && (
              <ul style={{ textAlign: 'left', display: 'inline-block' }}>
                {section.list.map((item, id) => (
                  <li key={id}>{item}</li>
                ))}
              </ul>
            )}
          </section>
        ))}
      </div>
    </div>
  );
};


const Admin_associate = (props) => {
const { infoSource } = props

const {
  userInfo
} = infoSource

  const location = useLocation(); // Surveille les changements d'URL
  const query = useQuery();
  const getPageBoo = () => {
     
    const page = query.get("page") || null;
    const usel = query.get("usel") || 0
    console.log('done')
    return {
      page,
      usel
    }
};

const filterlist =  {
  asc: 'asc',
  limit : 50,
  skip : 0,
  roleName: 'null',
  myUser: false,
  grpByrole : false,
  sortBy: 'createdAt',
  userID: '',
  search: ''
}

    const [pageSelected, setPageSelected] = useState(getPageBoo().page);
    const [selectRole, setSelectRole] = useState(Number(getPageBoo().usel));
    const [loading, setLoading] = useState(false);
    const [userTeam, setUseTeam] = useState([])
    const[nbrDays, setNbrDays] = useState(1)
    const { token } = isAuthenticated();
    const [showFilterOurTeam, setSowFilterOurTeam] = useState(false)
    const [showPromoUsPr, setSowPromoUsPr] = useState(false)
    const [pagePromoUsPr, setpagePromoUsPr] = useState({
      user_id: null,
      prod_id: null    
    })
    const [refShopName, setRefShopName] = useState('') 
    const [title, setTitle] = useState(refShopName)
    const [getFilterOurTeam, setFilterOurTeam]= useState(filterlist)
    const [url, setUrl] = useState('https://elakay.com');
    const [promoInfo, setPromoInfo] = useState({});
   const imageRef = useRef();
   const prevSearchRef = useRef(location.search);
   const [getFullName, setFullName] = useState(null)
   const [getRolaName, setRoleName] = useState(null)
   const [pageTeam, setPageTeam]=useState(true)
   const [pageProd, setPageProd]=useState(false)
    const[filterShop, setFilterShop] = useState(false)
    const [getCalFilShop, setCalFilShop] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [messagetext, setMessagetext]= useState(null)
   const [pageShop, setPageShop]=useState(false)
       const [myFilters, setMyFilters] = useState({
               filters: {"categoriesID.gategoriesLink": [] }
           });
           const {
            search
           } = getFilterOurTeam
useEffect(() => {
  const currentSearch = location.search;

  if (prevSearchRef.current !== currentSearch) {
    setSelectRole(Number(getPageBoo().usel))
    setPageSelected(getPageBoo().page);
      prevSearchRef.current = currentSearch; // Met à jour la valeur précédente
  }
  loadAllMyUserRef(getFilterOurTeam)
  

}, [location.search,  search]);

const getValidId = (id1, id2) => {
  // Vérifie si id1 est vide, null, undefined, ou une chaîne vide
  if (!id1) {
      return id2;
  }
  return id1;
}

  const handlePrice = value => {
 
         let array = [];
         for (let key in prices) {
             if (prices[key]._id === parseInt(value)) {
                 array = prices[key].array;
             }}
         return array;
     };
 
     const handleFilters = (filters, filterBy) => {
         const newFilters = { ...myFilters };
         newFilters.filters[filterBy] = filters;
 
         if (filterBy === "price") {
             let priceValues = handlePrice(filters);
             newFilters.filters[filterBy] = priceValues;
         }
        
         setMyFilters(newFilters);
     };

const styles = {
  container: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    padding: "10px",
    margin: "10px",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    fontSize: "25px",
  },
  icon: {
    width: "100%",
    maxWidth: "400px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "16px",
  },
};

const styles2 = {
  container: {
    left: "30px", /* Espace depuis la droite */
    display: "flex",
    gap: "10px",
    // margin: "60px",
    padding:'20px'
  },
  base: {
    cursor: "pointer",
    padding: "1px 4px",
    borderRadius: "5px",
    fontWeight: "bold",
    transition: "background-color 0.3s, color 0.3s",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    margin: "5px",
  },
  active: {
    backgroundColor: "#F5F5F5", // Light red
    border: "4px solid #000000",
    color: "#d32f2f", // Darker red
  },
  unActive: {
    backgroundColor: "#DCDCDC", // Dark red
    border: "1px solid #708090",
    color: "#000000",
  },
};

const btnNotOk = (a)=>{
  if(a==='customer' || a==='seller' || a==='provider'){
      return false 
  }
  return true
}
const btnNotOk2 = (a)=>{
  if(a==='customer'){
      return false 
  }
  return true
}

const btnOrtherUser = ()=>{
    setPageTeam(true)
    setPageProd(false)
    setPageShop(false)
}

const btnShopUser = ()=>{
    setPageTeam(false)
    setPageProd(false)
    setPageShop(true)
}

const btnShopPromo = (s=false, usId = null, prId= null, t = "")=>{

  setSowPromoUsPr(s)
  // setTitle()
  if(s){
    setTitle(t)
    setpagePromoUsPr({
      user_id: usId,
      prod_id: prId 
    })
  }else{
  setTitle("")
  setpagePromoUsPr({
    user_id: usId,
    prod_id: prId 
  })
}
}

      const btnCardUser = ()=>{
        return <div 
        style={{...styles.container}}
        >
             {btnNotOk(getFilterOurTeam.roleName) && <span style={{
                  ...styles2.base,
                  ...styles2.unActive,
                //   ...(getFilterOurTeam.asc === "asc" ? styles2.active : {}),
                }}
                 onClick={btnOrtherUser}
                 >
                <FaUsersBetweenLines />
                </span>}

                {btnNotOk2(getFilterOurTeam.roleName) &&  <> 
               
                <span
                  style={{
                    ...styles2.base,
                    ...styles2.unActive,
                    // ...(getFilterOurTeam.asc === "desc" ? styles2.active : {}),
                  }}
                  onClick={btnShopUser}
                 >
                <FaStore />
                </span> 
                <span
                  style={{
                    ...styles2.base,
                    ...styles2.unActive,
                    ...(getFilterOurTeam.asc === "desc" ? styles2.active : {}),
                  }}
                 onClick={()=>btnShopPromo(true, getValidId(getFilterOurTeam.userID,  userInfo._id), null, refShopName)}
                 >
                promo
                </span>
                </>}
                </div> 
      }

const loadAllMyUserRef = (filTeam = getFilterOurTeam)=>{
  AllMyUserReF(token, filTeam).then(data => {
              if (data.error) {
                      console.log("data Not Found");
                  } else {
                    setUseTeam(data)
                    setSowFilterOurTeam(false)  
                  }
              })
}
  const copyToClipboard = (jsonData) => {
    const jsonStr = JSON.stringify(jsonData, null, 2);
    navigator.clipboard.writeText(jsonData).then(() => {
      // alert('JSON copié dans le presse-papiers!');
      setShowAlert(true)
      setMessagetext("JSON copié dans le presse-papiers!")
    }).catch((err) => {
      console.error('Erreur lors de la copie : ', err);
    });
  };
    const copyImageToClipboard = async () => {
        try {
          // Accéder à l'élément <img> référencé
          const imgElement = imageRef.current;
    
          // Créer un canvas temporaire pour dessiner l'image
          const canvas = document.createElement('canvas');
             if (!canvas) {
          // alert('Canvas non trouvé.');
          setShowAlert(true)
          setMessagetext('Canvas non trouvé.')
          return;
        }
          canvas.width = imgElement.width;
          canvas.height = imgElement.height;
    
          // Dessiner l'image sur le canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(imgElement, 0, 0);

          // Convertir le canvas en Blob
          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, 'image/png')
          );
    
          // Copier l'image (Blob) dans le presse-papier
          await navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
    
          // alert('Image copiée dans le presse-papier !');
          setShowAlert(true)
          setMessagetext('Image copiée dans le presse-papier !')
        } catch (error) {
          console.error('Erreur lors de la copie de l\'image :', error);
        }
      };

      const downloadImage = async () => {
        try {
          // Accéder à l'élément <img>
          const imgElement = imageRef.current;
          const canvas = document.createElement('canvas');
            if (!canvas) {
                //  alert('Canvas non trouvé.');
                 setShowAlert(true)
                 setMessagetext('Canvas non trouvé.')
                    return;
                        }
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;
        const ctx = canvas.getContext('2d');
          ctx.drawImage(imgElement, 0, 0);
          // Créer un lien temporaire pour le téléchargement
          const url = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = url; // URL de l'image (source de l'image)
          link.download = 'downloaded-image.png'; // Nom du fichier téléchargé
          link.click(); // Déclencher le téléchargement
    
          // alert('Image téléchargée avec succès !');
          setShowAlert(true)
          setMessagetext('Image téléchargée avec succès !')
        } catch (error) {
          console.error('Erreur lors du téléchargement de l\'image :', error);
        }
      };
   const handleChange = () => event => {
    const value = event.target.value;
    setNbrDays(value)
};
const handleChange1 = () => event => {
    const value = event.target.value;
    setTitle(value)
};

const handleSelPromo = () => event =>{
    const value = event.target.value;
    let getProm
    let newUrl
    if(value !== ''){
       getProm =  infoSource.getPubToken[value]
    newUrl =`${ELAKAY_URl1}/userPUB/${getProm.token}`   
    }else{
     getProm = {}
    newUrl = 'https://elakay.com/'
    }
   
    setTitle("")
    setPromoInfo(getProm)
    setUrl(newUrl)
}

const btnCreatTokenPUB =()=>{
    creatTokenPUB(token, title, nbrDays, pagePromoUsPr.user_id,  pagePromoUsPr.prod_id).then(data => {
        if (data.error) {
            console.log(data.error)
        } else {
            setTitle("")
            let newUrl =`${ELAKAY_URl1}/userPUB/${data.token}`
            setUrl(newUrl)
        }
    }) 

}
      const formSelect = (array) => (
         <div className="form-group">
        <label className="text-muted">
            {/* select a promotion  */}
            {infoSource.language(infoSource, "label", "selPromo")}
         </label>
        <select onChange={handleSelPromo()} className="form-control">
        <option value={''} >{infoSource.language(infoSource, "label", "pleaseSelect")}</option>
        {array.map((obj, j) =>{
         return <option value={j} >{obj.title}</option>
        })}
        </select>
    </div> 
        )
const linkQRcode = (url="elakay.com")=>{
   
    return <div className="row mt-3">
            <div className="col col-12 col-md-4"> 
            <div className="d-grid gap-2 mx-auto">
            <button className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>copyToClipboard(url)}>{infoSource.language(infoSource, "label", "copyLink")}</button>
            <button className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>copyImageToClipboard()}>{infoSource.language(infoSource, "label", "copyQrcode")}</button>
            <button className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>downloadImage()}>{infoSource.language(infoSource, "label", "downloadQrCode")}</button>
            </div>
            </div>
            <div className="col col-12 col-md-8 p-4">
            <QRCodeCanvas
            ref={imageRef}
            value={url}
            size={170}
            bgColor="#ffffff"
            fgColor="#000000"
            level="H"
          />
  
            </div>
   
       </div>
   }

   const formTimer = ()=>{
    return <> 
     {<h1>{nbrDays} {infoSource.language(infoSource, "label", "expDay")}</h1>}
        
             <Form.Group>
                      <Form.Label className="mt-3">{infoSource.language(infoSource, "label", "entDayexp")}</Form.Label>
                     <Form.Control 
                     className="form-range mb-4 rounded-4"
                     onChange={handleChange("nbrDays")}
                     type="range"
                     value={nbrDays > 0 ? nbrDays : 0}
                     min="1" 
                     max="7"
                     // step="0.25"
                     />
             </Form.Group>
    
             </>
 }



const creatQRCode = ()=>{
   return <div>
    <div className="alert alert-info p-4" style={{  'overflow': 'auto', 'maxHeight' : "500px"}}>
        {formTimer()}
        <div className="form-group">
         <label className="valuetext-muted">{infoSource.language(infoSource, "label", "titlePromo")}</label>
         <input onChange={handleChange1()} type="text" className="form-control" placeholder={'enter your the title of your promotions'} value={title} />
    </div>
        <div className="d-grid gap-2 mx-auto">
        {title != "" ?
        <button className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>btnCreatTokenPUB()}>{infoSource.language(infoSource, "label", "creatLink")}</button>
        :
        <button className="m-1 py-3 px-0 btn btn-outline-info rounded-4 shadow-lg disabled">{infoSource.language(infoSource, "label", "creatLink")}</button>
        }
        </div> 
        {infoSource && infoSource.getPubToken && formSelect(infoSource.getPubToken)}
   </div>
     <div className="alert alert-secondary">
<h5>{promoInfo.title}</h5>
     { linkQRcode(url)}
     
      </div>
      </div>
}
const allUserteam =(array) => (
  <Row className="m-1" xs={1} lg={2}>
       { array &&  array.map((U,i)=>{
       return  <div className="col p-3" key={i}>
        <CardUSer
        infoSource={infoSource}
         fullName={U.firstname + ' '+ U.lastname}
         email={U.email}
         roleName ={U.userActiveRole.roleName}
         nbrUser={U.nombreTeam}
         getFilterOurTeam = {getFilterOurTeam}
         setFilterOurTeam = {setFilterOurTeam}
         loadAllMyUserRef={loadAllMyUserRef}
         setFullName={setFullName}
         setRoleName={setRoleName}
         setPageTeam={setPageTeam}
         setPageProd={setPageProd}
         setPageShop={setPageShop}
         User_id = {U._id}
      />
      </div>
      })}

  </Row>
)

const groupeUserteam =  (
  <div>
 {getFilterOurTeam.grpByrole && userTeam.groupedByRole &&  userTeam.groupedByRole.map((U,i)=>{
       return  <div className="col p-3" key={i}>
        <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {U._id}
            </h5></div>
            {allUserteam(U.users)}
      </div> })}
  </div>
)

const OnlyMe_MyTeaMUsesr = ()=>{
  if(getFilterOurTeam.userID === userInfo._id || getFilterOurTeam.userID === ''){
    if(getFilterOurTeam.myUser){
    return "My Personal Team"

    }else{
      return "My Team and Associated Teams"
    }

  }else{
    return 'the Team of ' + ' ' +  getFullName + ' ' + ' as '+ ' ' +  getRolaName
  }

}

const CustomerVIewShop =()=>{
  return      <ShopUserView
   prices={prices}
  setLoading={setLoading}
  infoSource={infoSource}
  promoMaker={true}
  // readUser={readUser}
  // ShopName={}
  setRefShopName={setRefShopName}
  refShopName={refShopName}
  btnShopPromo={btnShopPromo}
  setFilterShop={setFilterShop}
  setCalFilShop={setCalFilShop}
  myFilters={myFilters}
  userID={getValidId(getFilterOurTeam.userID,  userInfo._id)}
 />
}


const UrUserView = ()=>{
    return <div className="content p-4">
     {/* <div className="alert alert-info p-4" style={{  'overflow': 'auto', 'maxHeight' : "400px"}}>
         {formTimer()}
         {infoSource && infoSource.getPubToken && formSelect(infoSource.getPubToken)}
    </div> */}
      <div className="alert alert-secondary">
             <h2 className="text-muted">
             {infoSource.language(infoSource, "label", "collBetTeam")}
      </h2>
     {pageTeam && <>

         {getFilterOurTeam.userID === userInfo._id || getFilterOurTeam.userID !='' && <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>{setFilterOurTeam(filterlist); loadAllMyUserRef(filterlist); setFullName(null); setRoleName(null)} }>
                    {/* Filters */}
                    {infoSource.language(infoSource, "label", "compTeam")}
          </div>}
           <span className='h4'>{OnlyMe_MyTeaMUsesr()}</span>
           <SearchBar 
           infoSource={infoSource}
           getFilterUserProd={getFilterOurTeam}
           setFilterUserProd ={setFilterOurTeam}
           setSowFilterOurTeam={setSowFilterOurTeam}
           />
    {!getFilterOurTeam.grpByrole && allUserteam(userTeam.paginatedResults)}

    {getFilterOurTeam.grpByrole && groupeUserteam}
      </>}
{pageProd&& <>

</>}

{pageShop && <>

  <div> {btnCardUser()}</div>
  {/* <Filter_searchProductUser
  infoSource={infoSource}
  /> */}
  {CustomerVIewShop()}
</>}
       </div>


       </div>
 }
  
 const UrWallet = ()=>{
    return <div className="content p-4">
          <div className="alert alert-info p-4" style={{  'overflow': 'auto', 'maxHeight' : "400px"}}>
      <h1 className="text-muted">{infoSource.laguangeElakay('title', infoSource.languageAPI).comingSoon}</h1>
      </div>
       </div>
 }

 const OrderPromo = ()=>{
    return <div className="content p-4">
        <div className="alert alert-info p-4" style={{  'overflow': 'auto', 'maxHeight' : "400px"}}>
     <h1 className="text-muted">{infoSource.laguangeElakay('title', infoSource.languageAPI).comingSoon}</h1>
     </div>
      
      
       </div>
 }

    const activeLink = (a) =>{
        return pageSelected === a? 'active': 'notActive'
     }

     let sidebarStyle = {
        'overflow': 'auto',
       'maxHeight' : "700px"
      }


    const sideBarForm =()=>{

        return <div className="sidebar" styles={{'height': '100%'}}>
           <Link className={activeLink('')} onClick={()=>setPageSelected('')}>{infoSource.language(infoSource, "label", "introduction")}</Link>
            <Link className={activeLink('elakayPromo')} onClick={()=>setPageSelected('elakayPromo')}>{infoSource.language(infoSource, "label", "creProElakay")}</Link>
            <Link className={activeLink('usersAdd')} onClick={()=>setPageSelected('usersAdd')}>{infoSource.language(infoSource, "label", "addUser")}</Link>
            <Link className={activeLink('usersView')} onClick={()=>{setPageSelected('usersView');  loadAllMyUserRef()}}>{infoSource.language(infoSource, "label", "viewUser")}</Link>
            <Link className={activeLink('wallet')} onClick={()=>setPageSelected('wallet')}>waLlet</Link>
            <Link className={activeLink('allOrderPromo')} onClick={()=>setPageSelected('allOrderPromo')}>{infoSource.language(infoSource, "label", "allOrdPromo")}</Link>
                </div>
                }

                const filterOurTeam = ()=>{
                  return <Modal
                  size="md"
                  show={showFilterOurTeam}
                  onHide={() => setSowFilterOurTeam(false)}
                  // dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
              >
                  <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                      <h2>
                          {/* {infoSource.language(infoSource, "label", "ordFilt")} */}
                          {infoSource.language(infoSource, "label", "userFilt")}
                          </h2>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                <FilterUserTeam
                 infoSource= {infoSource}
                getFilterOurTeam = {getFilterOurTeam}
                setFilterOurTeam = {setFilterOurTeam}
                loadAllMyUserRef={loadAllMyUserRef}
                setSowFilterOurTeam={setSowFilterOurTeam}
                filterlist={filterlist}
                />
          
                  </Modal.Body>
              </Modal>
          
              }

              const PromoUsPr = ()=>{
                return <Modal
                size="lg"
                show={showPromoUsPr}
                onHide={() => btnShopPromo()}
                // dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    <h2>
                        {/* {infoSource.language(infoSource, "title", "ordFilt")} */}
                        {infoSource.language(infoSource, "label", "userFilt")}
                        </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {creatQRCode()}
              
                </Modal.Body>
            </Modal>
        
            }
              
    const contentForm =(a)=>{
     if (a === 'elakayPromo') {
        return <div className="content p-4"> {creatQRCode()} </div>     
        }else if(a === 'allOrderPromo'){
            return OrderPromo() 
        }else if(a === 'wallet'){
            return  UrWallet()  
        }else if(a === 'usersView'){
            return UrUserView()   
        }else if(a === 'usersAdd'){
         return <div className="content p-4"> <AdminFamily infoSource ={infoSource} usel = {getPageBoo().usel} selectRole ={selectRole} setSelectRole={setSelectRole}/> </div>
      }else{
         return MarketplacePage(infoSource)
        }
        }

    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            filterShop={filterShop}
            setFilterShop={setFilterShop}
            handleFilters={ handleFilters}
            setMyFilters={setMyFilters}
            getCalFilShop={getCalFilShop}
            userShop= {true}
            loading={loading}
            SliderPunShow={false}
        >
       <div style={infoSource.styleNav}>
        {sideBarForm()} 
        {/* {userProfileCard()} */}
        {contentForm(pageSelected)}
        {filterOurTeam()}
        {PromoUsPr()}
        <AlertPage
        showAlert={showAlert} 
        setShowAlert={setShowAlert}
        messagetext={messagetext}
        />
    </div>
        </Layout>
    );
};

export default Admin_associate;