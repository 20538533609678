import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Pages from '.'

function CodeEdit(props) {
    const {
        infoSource,
        editAdd, 
        setEditAdd,
        nameObj,
        edit_add,
        token,
        AlllanguageSelect,
        languageUsertra,
        languageTradictor,
        updateLanguageElakay0,
        deleteLanguageElakay0
    } = props

    const [showEmailPage, setShowEmailPage] = useState(false);

    const emailTemplates = [
      {
        title: "send e-mail confirmation",
        data: {
          user: { firstname: "John", lastname: "Doe" },
          subject: "Welcome to Elakay - Account Activation",
          random: 123456,
          url: "https://www.example.com",
          urlLogo: "http://localhost:2284/elakay_api/icon/logo_elakay/logoelakay.ico",
          texts: {
            welcomeTitle: "Welcome to Elakay",
            introduction: `to the Elakay community! We’re thrilled to have you join us.`,
            instructions: `To activate your account and gain full access to all our services, please use the verification code below:`,
            verificationCodeLabel: `Verification Code: `,
            expiryNotice: `⚠️ This code is valid for 20 minutes. Make sure to use it within this time; otherwise, it will expire, and you will need to request a new one to complete your activation.`,
            accountRemovalWarning: `Important Note: If you do not activate your account within 7 days, it will be automatically removed from our database.`,
            // encouragement: `By activating your account, you are joining a dynamic network of professionals, artisans, and entrepreneurs committed to success. Discover personalized tools, growth opportunities, and the support of a community with shared values.`,
            supportNote: `If you have any questions or need assistance with activating your account, our team is here to help.`,
            signature: `See you soon, The Elakay Team`
          }
        },
        htmlTemplate: `
          <div style="text-align:center; max-width: 600px; margin: auto; background-color: #f9f9f9; font-family: Arial, sans-serif; border-radius: 8px; overflow: hidden; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
            <div style="background-color: #007399; padding: 20px; text-align: center; color: white;">
              <img src="\${urlLogo}" alt="Logo" style="width: 40px; height: 40px; vertical-align: middle; filter: brightness(0) invert(1); margin-right: 10px;">
              <span style="font-size: 28px; font-weight: bold; vertical-align: middle;">Elakay</span>
            </div>
            <div style="padding: 30px; color: #333;">
              <h2 style="font-size: 24px; color: #007399; margin-bottom: 15px;">\${texts.welcomeTitle}</h2>
              <h3 style="font-size: 20px; margin-bottom: 20px;">\${user.firstname} \${user.lastname},</h3>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.introduction}</p>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.instructions}</p>
              
              <div style="background-color: #007399; color: white; font-size: 20px; font-weight: bold; text-align: center; padding: 10px; border-radius: 8px; margin: 20px 0;">
                \${texts.verificationCodeLabel}  \${random}
              </div>
              
              <p style="font-size: 16px; color: #d9534f; margin-bottom: 15px;">\${texts.expiryNotice}</p>  
              <a href="\${url}" style="display: inline-block; text-decoration: none; background-color: #007399; color: white; padding: 12px 30px; font-size: 18px; font-weight: bold; border-radius: 8px; text-align: center; margin-top: 20px;">
                Activate My Account
              </a>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.accountRemovalWarning}</p>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 25px;">\${texts.supportNote}</p>
              
            
              
              <p style="font-size: 16px; line-height: 1.5; color: #555; margin-top: 30px;">\${texts.signature}</p>
            </div>
          </div>
        `
      }
      , 
      {
        title: "send e-mail verificatiom",
        data: {
          user: { firstname: "John", lastname: "Doe" },
          subject: "Elakay Security Alert - New Device Login Verification",
          random: 123456,
          url: "https://www.example.com",
          urlLogo: "http://localhost:2284/elakay_api/icon/logo_elakay/logoelakay.ico",
          texts: {
            welcomeTitle: "New Device Login Verification",
            introduction: `we detected a login attempt to your Elakay account from a new device.`,
            instructions: `To complete this connection, please enter the verification code below on your computer:`,
            verificationCodeLabel: `Verification Code: `,
            expiryNotice: `⚠️ This code will expire in 20 minutes.`,
            encouragement: `If this login attempt was not made by you, please secure your account immediately by changing your password. Our support team is available if you have any questions or need assistance.`,
            signature: `Thank you for choosing Elakay, The Elakay Security Team`
          }
        },
        htmlTemplate: `
          <div style="text-align:center; max-width: 600px; margin: auto; background-color: #f9f9f9; font-family: Arial, sans-serif; border-radius: 8px; overflow: hidden; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
            <div style="background-color: #007399; padding: 20px; text-align: center; color: white;">
              <img src="\${urlLogo}" alt="Logo" style="width: 40px; height: 40px; vertical-align: middle; filter: brightness(0) invert(1); margin-right: 10px;">
              <span style="font-size: 28px; font-weight: bold; vertical-align: middle;">Elakay</span>
            </div>
            <div style="padding: 30px; color: #333;">
              <h2 style="font-size: 24px; color: #007399; margin-bottom: 15px;">\${texts.welcomeTitle}</h2>
              <h3 style="font-size: 20px; margin-bottom: 20px;">Hello \${user.firstname} \${user.lastname},</h3>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.introduction}</p>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.instructions}</p>
              
              <div style="background-color: #007399; color: white; font-size: 20px; font-weight: bold; text-align: center; padding: 10px; border-radius: 8px; margin: 20px 0;">
                \${texts.verificationCodeLabel} \${random}
              </div>
              
              <p style="font-size: 16px; color: #d9534f; margin-bottom: 15px;">\${texts.expiryNotice}</p>
              <a href="\${url}" style="display: inline-block; text-decoration: none; background-color: #007399; color: white; padding: 12px 30px; font-size: 18px; font-weight: bold; border-radius: 8px; text-align: center; margin-top: 20px;">
                Verify My Device
              </a> 
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.encouragement}</p>

              <p style="font-size: 16px; line-height: 1.5; color: #555; margin-top: 30px;">\${texts.signature}</p>
            </div>
          </div>
        `
      },
      
      {
        title: "resend Code Verification",
        data: {
          user: { firstname: "John", lastname: "Doe" },
          subject: "Elakay - Resend Verification Code",
          random: 123456,
          url: "https://www.example.com",
          urlLogo: "http://localhost:2284/elakay_api/icon/logo_elakay/logoelakay.ico",
          texts: {
            welcomeTitle: "Resend Verification Code",
            introduction: `here is your new verification code to complete the login on your new device.`,
            instructions: `To finalize your connection, please enter the verification code below on your computer:`,
            verificationCodeLabel: `New Verification Code:`,
            expiryNotice: `⚠️ This code will expire in 20 minutes.`,
            encouragement: `If this login attempt wasn’t made by you, please secure your account by changing your password. Our support team is here if you need further assistance.`,
            signature: `Best regards, The Elakay Security Team`
          }
        },
        htmlTemplate: `
          <div style="text-align:center; max-width: 600px; margin: auto; background-color: #f9f9f9; font-family: Arial, sans-serif; border-radius: 8px; overflow: hidden; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
            <div style="background-color: #007399; padding: 20px; text-align: center; color: white;">
              <img src="\${urlLogo}" alt="Logo" style="width: 40px; height: 40px; vertical-align: middle; filter: brightness(0) invert(1); margin-right: 10px;">
              <span style="font-size: 28px; font-weight: bold; vertical-align: middle;">Elakay</span>
            </div>
            <div style="padding: 30px; color: #333;">
              <h2 style="font-size: 24px; color: #007399; margin-bottom: 15px;">\${texts.welcomeTitle}</h2>
              <h3 style="font-size: 20px; margin-bottom: 20px;">\${user.firstname} \${user.lastname},</h3>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.introduction}</p>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.instructions}</p>
              
              <div style="background-color: #007399; color: white; font-size: 20px; font-weight: bold; text-align: center; padding: 10px; border-radius: 8px; margin: 20px 0;">
                \${texts.verificationCodeLabel} \${random}
              </div>
              
              <p style="font-size: 16px; color: #d9534f; margin-bottom: 15px;">\${texts.expiryNotice}</p>
              <a href="\${url}" style="display: inline-block; text-decoration: none; background-color: #007399; color: white; padding: 12px 30px; font-size: 18px; font-weight: bold; border-radius: 8px; text-align: center; margin-top: 20px;">
                Verify My Device
              </a> 
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.encouragement}</p>
                          
              <p style="font-size: 16px; line-height: 1.5; color: #555; margin-top: 30px;">\${texts.signature}</p>
            </div>
          </div>
        `
      },
      {
        title: "email Change Password",
        data: {
          user: { firstname: "John", lastname: "Doe" },
          subject: "Elakay - Password Reset Request",
          token: "uniqueToken123",
          url: "https://www.example.com/reset-password/${token}",
          urlLogo: "http://localhost:2284/elakay_api/icon/logo_elakay/logoelakay.ico",
          texts: {
            welcomeTitle: "Reset Your Password",
            introduction: ` we received a request to reset the password for your Elakay account.`,
            instructions: `To reset your password, please click the link below. This link will take you to a secure page where you can set a new password for your account.`,
            warning: `⚠️ For security purposes, this link will expire in 30 minutes. If you did not request a password reset, please ignore this email or contact our support team immediately.`,
            signature: `Thank you, The Elakay Team`
          }
        },
        htmlTemplate: `
          <div style="text-align:center; max-width: 600px; margin: auto; background-color: #f9f9f9; font-family: Arial, sans-serif; border-radius: 8px; overflow: hidden; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
            <div style="background-color: #007399; padding: 20px; text-align: center; color: white;">
              <img src="\${urlLogo}" alt="Logo" style="width: 40px; height: 40px; vertical-align: middle; filter: brightness(0) invert(1); margin-right: 10px;">
              <span style="font-size: 28px; font-weight: bold; vertical-align: middle;">Elakay</span>
            </div>
            <div style="padding: 30px; color: #333;">
              <h2 style="font-size: 24px; color: #007399; margin-bottom: 15px;">\${texts.welcomeTitle}</h2>
              <h3 style="font-size: 20px; margin-bottom: 20px;">Hello \${user.firstname} \${user.lastname},</h3>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.introduction}</p>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 15px;">\${texts.instructions}</p>
              
              <a href="\${url}" style="display: inline-block; text-decoration: none; background-color: #007399; color: white; padding: 12px 30px; font-size: 18px; font-weight: bold; border-radius: 8px; text-align: center; margin: 20px 0;">
                Reset My Password
              </a>
              
              <p style="font-size: 16px; color: #d9534f; margin-top: 20px;">\${texts.warning}</p>
              
              <p style="font-size: 16px; line-height: 1.5; color: #555; margin-top: 30px;">\${texts.signature}</p>
            </div>
          </div>
        `
      },  
      {
        "title": "about us",
        "data": {
          "aboutUs": {
            "title0": "Who we are",
            "text00": "We are a community driven by a shared mission: to catalyze business growth and success across America. We believe in the power of collaboration, innovation, and mutual support to help our members - artisans, professionals, and business administrators - realize their full potential. Our goal is to create an ecosystem where every business, large or small, has the tools and resources to thrive.",
            "text01": "",
            "text02": "At Elakay, we are not just an e-commerce platform; we are a community committed to three core principles that guide everything we do: fostering growth, supporting small business, and celebrating individuality. These values are at the heart of everything we do and shape the unique experience we provide to our users. ",
            "title1": "Our vision",
            "text10": "Imagine a future where every entrepreneur finds a supportive community, accessible resources, and unlimited growth opportunities. We envision a world where barriers to entrepreneurship are lowered, innovation is fueled by diversity, and success is celebrated collectively.",
            "title2": "Our values",
            "text20": "our mission is based on three essential pillars: growth, support for small businesses, and individuality. We believe in the transformative impact of telecommunications technology to break down barriers, providing our sellers with expanded reach and our buyers with unprecedented diversity. Supporting small businesses is at the heart of our approach. By investing in these entities, we contribute to their evolution into large, successful companies, fostering a rich and diversified economic ecosystem. Individuality is our third pillar, encouraged through a platform that values and celebrates the unique expression of each seller. This approach guarantees a satisfying purchasing experience for our customers, enriched by the discovery of products that reflect the personality and creativity of their creators. At Elakay, we are dedicated to building a community where every member can grow, express themselves freely, and contribute to a dynamic and inclusive marketplace.",
            "list0": "Collaborative Platform: An online space to connect, learn, and grow together.",
            "list1": "Training and Mentoring: Programs designed to equip our members with the skills needed for success.",
            "list2": "Funding Support: Facilitate access to financial resources to transform ideas into thriving businesses.",
            "list3": "Promotion and Visibility: Providing channels to increase the reach and impact of our businesses.",
            "title3": "How We Act",
            "text30": "To realize our vision, we are implementing a series of strategic initiatives:",
            "list4": "Networking and Events: Organize enriching meetings to forge strong connections and discover new opportunities.",
            "title4": "Growth through Telecommunications",
            "text40": "In a world where technology is rapidly evolving, we recognize that access to effective means of communication is essential to drive growth. Elakay leverages telecommunications to break geographic and temporal barriers, allowing our sellers to reach a wider audience and our buyers to discover an unparalleled variety of products. This approach allows us to offer a platform where abundance of choice and ease of access go hand in hand with profitability.",
            "title5": "Support Small Businesses",
            "text50": "We firmly believe that supporting small businesses is the foundation of a thriving economy. At Elakay, every small business receives the support they need to grow and thrive. We invest in the success of \"underdogs\", providing them with the tools and resources to reach new heights. By highlighting these companies, we are helping to create an ecosystem where dreams come true and every effort is valued.",
            "title6": "Celebrating Individuality",
            "text60": "Creative expression and individuality are at the heart of our mission. Elakay actively encourages our sellers to innovate and express themselves in unique ways. We offer them a platform where their uniqueness is not only accepted, but also celebrated. This commitment to individuality ensures that our buyers not only find products that meet their needs, but also items that bring a personal and original touch to their everyday lives.",
            "title7": "Together Towards a Promising Future",
            "text70": "With these principles in action, Elakay proudly stands as a trusted partner for your purchases. We are committed to providing an enriching experience for all of our users, whether they are buyers looking for unique products or sellers aspiring to expand their reach. Together, we are building a future where every purchase is a source of pride and every sale a step toward success.",
            "text71": "",
            "text72": "Join us on this adventure. At Elakay, your growth is our success, and your individuality is our inspiration. Together, let's shape a world where small businesses flourish and every voice is heard.",
            "url": "http://localhost?aboutus=true"
          },
          
        },
        htmlTemplate: `
  <div style="max-width: 600px; margin: auto; background-color: GhostWhite; font-family: Arial, sans-serif; color: #333; padding: 30px; border-radius: 8px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
  <h2 style="font-size: 24px; color: #007399; text-align: center; margin-bottom: 20px;">About Us</h2>

  <h3 style="color: #007399; font-size: 20px; margin-bottom: 10px;">\${aboutUs.title0}</h3>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px;">\${aboutUs.text00}</p>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px;">\${aboutUs.text01}</p>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 20px;">\${aboutUs.text02}</p>

  <h3 style="color: #007399; font-size: 20px; margin-bottom: 10px;">\${aboutUs.title1}</h3>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 20px;">\${aboutUs.text10}</p>

  <h3 style="color: #007399; font-size: 20px; margin-bottom: 10px;">\${aboutUs.title2}</h3>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px;">\${aboutUs.text20}</p>

  <ul style="font-size: 16px; line-height: 1.6; margin: 15px 0 20px 20px; color: gray;">
    <li style="margin-bottom: 10px;">\${aboutUs.list0}</li>
    <li style="margin-bottom: 10px;">\${aboutUs.list1}</li>
    <li style="margin-bottom: 10px;">\${aboutUs.list2}</li>
    <li style="margin-bottom: 10px;">\${aboutUs.list3}</li>
  </ul>

  <a href="\${aboutUs.url}" style="display: inline-block; text-align: center; background-color: #007399; color: white; padding: 10px 20px; font-size: 16px; font-weight: bold; border-radius: 8px; text-decoration: none;">
    See More
  </a>
</div>
      ` },
      {
        _id: "675d14b39be5667e99431b8c",
        title: "email Sell Product",
        data: {
          user: { firstname: "intel", lastname: "travail" },
          products: [
            {
              productID: "66549a1eb442b8a33ad49f9b",
              urlImg: "http://localhost:2284/elakay_api/img/67664f839fd670bbbf03d019/67664faa9fd670bbbf03d03c",
              filterPro: "66549a2bb442b8a33ad49fb9",
              price: 22,
              salePrice: 0,
              count: 1,
              status: "Not processed",
              _id: "675d14b39be5667e99431b8d",
              createdAt: "2024-12-14T05:16:35.387Z",
              updatedAt: "2024-12-14T05:16:35.387Z"
            }
          ],
          shippingAddress: {
            firstName: "intel",
            lastName: "travail",
            tel: "+1 347 781 3174",
            line1: "1091 Hancock St",
            city: "Brooklyn",
            state: "New York",
            postal_code: "11221",
            country: "US"
          },
          amountTotal: 30.45
        },
        htmlTemplate: `
          <div style="max-width: 600px; margin: auto; background-color: #f9f9f9; font-family: Arial, sans-serif; border-radius: 8px; overflow: hidden; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
            <!-- Header -->
            <div style="background-color: #007399; padding: 20px; text-align: center; color: white; border-radius: 8px 8px 0 0;">
              <h1 style="font-size: 28px; font-weight: bold; font-family: 'Times New Roman', Charcoal, sans-serif; margin: 0;">Elakay</h1>
            </div>
      
            <!-- Content -->
            <div style="padding: 30px; color: #333;">
              <h2 style="font-size: 22px; color: #007399; margin-bottom: 20px;">Congratulations, You Sold a Product!</h2>
              <h3 style="font-size: 20px; margin-bottom: 20px;">
              A customer purchased \${products[0].count} of your product(s) on Elakay.com
              </h3>
      
              <!-- Product Image -->
          <div style="display: flex; flex-wrap: wrap; gap: 15px; justify-content: center; margin-bottom: 20px;">
            \${products}
          </div>
      
              <!-- Shipping Address -->
              <h4 style="font-size: 18px; color: #007399; margin-bottom: 10px;">Shipping Address</h4>
              <p style="font-size: 16px; line-height: 1.5; margin-bottom: 20px;">
                \${shippingAddress.firstName} \${shippingAddress.lastName}<br>
                \${shippingAddress.line1}, \${shippingAddress.city}, \${shippingAddress.state}<br>
                \${shippingAddress.postal_code}, \${shippingAddress.country}<br>
                Tel: \${shippingAddress.tel}
              </p>
      
              <!-- Call to Action -->
              <p style="font-size: 16px; line-height: 1.5; text-align: center; margin-bottom: 20px;">
                Please click the button below to view this order.
              </p>
      
              <!-- View Order Button -->
              <div style="text-align: center;">
                <a href="#" style="text-decoration: none;">
                  <button style="background-color: #007399; border: none; color: white; padding: 12px 30px; font-size: 18px; font-weight: bold; border-radius: 8px; cursor: pointer; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);">
                    View Order
                  </button>
                </a>
              </div>
            </div>
          </div>
        `
      } ,
      {
        _id: "66ee07a56b836a92ce1f3460",
        title: "email Payment Success",
        data: {
          user: { firstname: "John", lastname: "Doe" },
          subject: "Elakay - Payment Confirmation",
          trackingUrl: "https://www.example.com/track-order/uniqueTracking123",
          viewOrderUrl: "https://www.example.com/view-order/uniqueOrder123",
          urlLogo: "http://localhost:2284/elakay_api/icon/logo_elakay/logoelakay.ico",
          orderImageUrl: "http://localhost:2284/elakay_api/img/67664f839fd670bbbf03d019/67664faa9fd670bbbf03d03c",
          texts: {
            welcomeTitle: "Payment Successful!",
            introduction: `your payment has been successfully processed.`,
            shippingInfo: `Your order is now being prepared for shipping. You can track your shipment using the link below:`,
            trackingLabel: "Track Your Order",
            viewOrderLabel: "View Order",
            closingMessage: `Thank you for shopping with Elakay! Your support helps our community grow stronger. Together, we're building a network of thriving entrepreneurs and satisfied customers.`,
            signature: `Best regards, The Elakay Team`
          }
        },
        htmlTemplate: `
          <div style="max-width: 600px; margin: auto; background-color: #f9f9f9; font-family: Arial, sans-serif; border-radius: 8px; overflow: hidden; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
            <!-- Header -->
            <div style="background-color: #007399; padding: 20px; text-align: center; color: white; border-radius: 8px 8px 0 0;">
              <img src="\${urlLogo}" alt="Logo" style="width: 40px; height: 40px; vertical-align: middle; filter: brightness(0) invert(1); margin-right: 10px;">
              <span style="font-size: 28px; font-weight: bold; vertical-align: middle;">Elakay</span>
            </div>
      
            <!-- Content -->
            <div style="padding: 30px; color: #333;">
              <h2 style="font-size: 24px; color: #007399; text-align: center; margin-bottom: 15px;">\${texts.welcomeTitle}</h2>
              <h3 style="font-size: 20px; margin-bottom: 20px; text-align: center;">Hello \${user.firstname} \${user.lastname},</h3>
              <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px; text-align: center;">\${texts.introduction}</p>
              <p style="font-size: 16px; line-height: 1.6; margin-bottom: 20px; text-align: center;">\${texts.shippingInfo}</p>
              
                <!-- Order Image with Hover Effect -->
        <div style="text-align: center; margin-bottom: 20px;">
          <a href="\${viewOrderUrl}" style="text-decoration: none;">
            <img
              src="\${orderImageUrl}"
              alt="Order Image"
              style="
                max-width: 50%;
                height: auto;
                border-radius: 8px;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
              "
              onmouseover="this.style.transform='scale(1.05)'; this.style.boxShadow='0px 8px 16px rgba(0,0,0,0.2)';"
              onmouseout="this.style.transform='scale(1)'; this.style.boxShadow='0px 4px 10px rgba(0,0,0,0.1)';"
            />
          </a>
        </div>

              <!-- Tracking Button -->
              <div style="text-align: center;">
                <a href="\${trackingUrl}" style="display: inline-block; text-decoration: none; background-color: #007399; color: white; padding: 12px 30px; font-size: 18px; font-weight: bold; border-radius: 8px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);">
                  \${texts.trackingLabel}
                </a>
              </div>
              
              <p style="font-size: 16px; line-height: 1.6; margin-top: 20px; text-align: center;">\${texts.closingMessage}</p>
              <p style="font-size: 16px; line-height: 1.6; color: #555; margin-top: 30px; text-align: center;">\${texts.signature}</p>
            </div>
          </div>
        `
      }
          
    ];
    
    const productTemplate = `
    <div style="flex: 1 1 calc(25% - 15px); text-align: center; max-width: 120px; border: 1px solid #ddd; padding: 10px; border-radius: 8px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
      <a href="#" style="text-decoration: none;">
        <img
          src="{urlImg}"
          alt="Product Image"
          style="width: 100%; height: auto; border-radius: 4px; margin-bottom: 10px;"
        />
      </a>
      <p style="font-size: 14px; margin: 5px 0;"><strong>Price:</strong> $${"{price}"}</p>
      <p style="font-size: 14px; margin: 5px 0;"><strong>Count:</strong> ${"{count}"}</p>
    </div>
  `;
   

let exempleJSOM = {
    "user": {
      "firstname": "John",
      "lastname": "Doe"
    },
    "random": 123456,
    "url": "https://www.example.com",
    "urlLogo": "http://localhost:2284/elakay_api/icon/logo_elakay/logoelakay.ico",
    "texts": {
      "welcomeTitle": "Welcome to elakay",
      "clickButton": "Please click the button below to validate your elakay account",
      "secureVerification": "And enter this secure verification",
      "codeText": "Code",
      "expiryText": "The secure verification will expire in 20 minutes",
      "accountRemoval": "If you do not activate your account in 7 days, your account will be removed from elakay data"
    },
     "images": {
      "logo": "http://localhost:2284/elakay_api/img/66776a15e7bf6478e57c12cf/66776a54e7bf6478e57c1309",
      "banner": "http://localhost:2284/elakay_api/pubXL/665274be4fe5fe9d482da097/66bbd36043543ff95d6fa969"
    },     
}

  const exmpleHTML_CSS = `
    <!-- Account Validation Section -->
    <div style="text-align:center; width: 100%">
       <h1 style="width:100%; color: white; padding: 10px; border-radius: 25px; background-color: #007399; font-family:'Times New Roman', Charcoal, sans-serif">
<img src="\${urlLogo}" alt="Logo" style="width:28px; height:28px; vertical-align: middle; margin-right: 0; display: inline; filter: brightness(0) invert(1);">
     elakay
      </h1>
      <h2>\${texts.welcomeTitle}</h2>
      <h3>\${user.firstname} \${user.lastname}</h3>
      <img src="\${images.logo}" alt="Logo" style="width:150px; height:150px;" />
      <p>\${texts.clickButton}</p>
      <p>\${texts.secureVerification}</p>
      <p>\${texts.codeText}: \${random}</p>
      <p>\${texts.expiryText}</p>
      <p>\${texts.accountRemoval}</p>
      <img src="\${images.banner}" alt="Banner" style="width:100%; height:auto;" />
      <a href="\${url}">
        <button style="background-color: #007399; border: none; color: white; padding: 10px 25px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer; border-radius: 25px; width:150px">
          Click here
        </button>
      </a>
    </div>
<!-- About Us Section -->
<div style="max-width: 600px; margin: auto; background-color: GhostWhite; font-family: Arial, sans-serif; color: #333; padding: 30px; border-radius: 8px; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);">
  <h2 style="font-size: 24px; color: #007399; text-align: center; margin-bottom: 20px;">About Us</h2>

  <h3 style="color: #007399; font-size: 20px; margin-bottom: 10px;">\${aboutUs.title0}</h3>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px;">\${aboutUs.text00}</p>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px;">\${aboutUs.text01}</p>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 20px;">\${aboutUs.text02}</p>

  <h3 style="color: #007399; font-size: 20px; margin-bottom: 10px;">\${aboutUs.title1}</h3>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 20px;">\${aboutUs.text10}</p>

  <h3 style="color: #007399; font-size: 20px; margin-bottom: 10px;">\${aboutUs.title2}</h3>
  <p style="font-size: 16px; line-height: 1.6; margin-bottom: 15px;">\${aboutUs.text20}</p>

  <ul style="font-size: 16px; line-height: 1.6; margin: 15px 0 20px 20px; color: gray;">
    <li style="margin-bottom: 10px;">\${aboutUs.list0}</li>
    <li style="margin-bottom: 10px;">\${aboutUs.list1}</li>
    <li style="margin-bottom: 10px;">\${aboutUs.list2}</li>
    <li style="margin-bottom: 10px;">\${aboutUs.list3}</li>
  </ul>

  <a href="\${aboutUs.url}" style="display: inline-block; text-align: center; background-color: #007399; color: white; padding: 10px 20px; font-size: 16px; font-weight: bold; border-radius: 8px; text-decoration: none;">
    See More
  </a>
</div>


  `
  const user = {
    fullName: "John Doe",
    email: "john.doe@example.com",
    verificationCode: "123456"
  };

//   const generateEmailTemplate = (user) => `
//   <div style="text-align: center; font-family: Arial, sans-serif; width: 100%; background-color: #f4f4f4; padding: 20px;">
//     <div style="max-width: 600px; margin: auto; background-color: white; border-radius: 10px; overflow: hidden;">
//       <h1 style="background-color: #007399; color: white; padding: 15px; font-size: 24px;">
//         Elakay Security Alert
//       </h1>
//       <div style="padding: 20px; text-align: left;">
//         <p style="font-size: 16px; color: #333;">Hello <strong>${user.fullName}</strong>,</p>
//         <p style="font-size: 16px; color: #333;">
//           We noticed a login attempt on your Elakay account from a new device. For security reasons, we require you to verify this attempt by entering the code below. This code will expire in 20 minutes.
//         </p>

//         <h2 style="font-size: 28px; color: #007399; text-align: center; letter-spacing: 2px; margin: 20px 0;">
//           ${user.verificationCode}
//         </h2>

//         <p style="font-size: 16px; color: #333;">
//           <strong>Email:</strong> ${user.email}
//         </p>
        
//         <p style="font-size: 16px; color: #333;">
//           Please enter this code in your Elakay app to complete the verification.
//         </p>

//         <p style="font-size: 16px; color: #333;">
//           If you did not attempt to sign in, please secure your account immediately by changing your password.
//         </p>

//         <p style="font-size: 16px; color: #333;">Thank you,<br/>The Elakay Team</p>
//       </div>

//       <div style="background-color: #f4f4f4; padding: 10px; text-align: center;">
//         <p style="font-size: 12px; color: #888;">
//           This code will expire in 20 minutes. If you did not request this, please ignore this email.
//         </p>
//       </div>
//     </div>
//   </div>
// `;

// const generateEmailTemplate1 = (user, token) => `
// <div style="text-align: center; font-family: Arial, sans-serif; width: 100%; background-color: #f4f4f4; padding: 20px;">
//   <div style="max-width: 600px; margin: auto; background-color: white; border-radius: 10px; overflow: hidden;">
//     <h1 style="background-color: #007399; color: white; padding: 15px; font-size: 24px;">
//       Elakay Account Confirmation
//     </h1>
//     <div style="padding: 20px; text-align: left;">
//       <p style="font-size: 16px; color: #333;">Hello <strong>${user.fullName}</strong>,</p>
//       <p style="font-size: 16px; color: #333;">
//         Thank you for signing up on Elakay! To activate your account, please verify your email address by clicking the button below. This code will expire in 20 minutes.
//       </p>

//       <h2 style="font-size: 28px; color: #007399; text-align: center; letter-spacing: 2px; margin: 20px 0;">
//         ${user.verificationCode}
//       </h2>

//       <p style="font-size: 16px; color: #333;">
//         <strong>Email:</strong> ${user.email}
//       </p>
      
//       <a href="https://elakay.com/active/${token}" style="display: inline-block; padding: 10px 20px; background-color: #007399; color: white; border-radius: 25px; font-size: 16px; text-decoration: none; margin-top: 20px;">
//         Activate Account
//       </a>

//       <p style="font-size: 16px; color: #333;">
//         If you did not sign up, you can ignore this email.
//       </p>

//       <p style="font-size: 16px; color: #333;">Thank you,<br/>The Elakay Team</p>
//     </div>

//     <div style="background-color: #f4f4f4; padding: 10px; text-align: center;">
//       <p style="font-size: 12px; color: #888;">
//         This link will expire in 20 minutes.
//       </p>
//     </div>
//   </div>
// </div>
// `;
    

const defExempleJSOM =  '<!-- enter your combined JSON here... -->'
    const defExmpleHTML_CSS = '<!-- enter your HTML Et CSS template here...-->'

    // JSON combiné pour les informations utilisateur et les textes des sections Account Validation et About Us
  const [jsonInput, setJsonInput] = useState(defExempleJSOM);

  // HTML combiné pour la validation de compte et la section "About Us"
  const [htmlTemplate, setHtmlTemplate] = useState(defExmpleHTML_CSS);

  const  selEmailType = ['about us', 'send e-mail confirmation', 'send e-mail verificatiom', 'resend Code Verification', 'email Change Password', 'email Actiivation Role', 'email Sell Product', 'email Markerting User', 'email New Product User', 'email Shop Product User', "email Payment Success"]
  const [deletePara, setDeletePara] = useState(false);
  const [checkdeletePara, setCheckDeletePara] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [srcDoc, setSrcDoc] = useState(''); 
  const [getTilte, setTilte] = useState('')
  let ObjetPage = {
    data: "",
    htmlTemplate : ""
  }
  const[paragra, setParagra]= useState(ObjetPage)


const replaceAray = (array, obj,  key, arrayRepl)=>{
    let linkData
    let data
    for (let i = 0; i < array.length; i++) {
        linkData = "${"+ key +"." + array[i] + "}"
        data = obj[key][array[i]]
        arrayRepl.push([linkData, data])
    }  
}

const checkIfFunction = (f)=>{
  return typeof f === 'object' ? true : false
}

const checkIfArray = (value) => {
  return Array.isArray(value) ? true : false;
};

const replaceArray = (array, key, arrayRepl) => {
  if(key ==='products'){
    const productCards = array.map((product) => {
      return productTemplate
        .replace("{urlImg}", product.urlImg)
        .replace("{price}", product.price)
        .replace("{count}", product.count);
    })
    .join("");
    let linkData = "${" + key + "}";
    arrayRepl.push([linkData, productCards]);
  }else{
  array.forEach((item, index) => {
    if (checkIfFunction(item)) {
      // Handle objects inside the array
      Object.keys(item).forEach((subKey) => {
        const linkData = "${" + key + "[" + index + "]." + subKey + "}";
        arrayRepl.push([linkData, item[subKey]]);
      });
    } else {
      // Handle primitive values inside the array
      const linkData = "${" + key + "[" + index + "]}";
      arrayRepl.push([linkData, item]);
    }
  });
}
};

const replaceObj = (array, obj,  key, arrayRepl)=>{
    let linkData
    let data
    for (let i = 0; i < array.length; i++) {
        linkData = "${"+ key +"." + array[i] + "}"
        data = obj[key][array[i]]
        arrayRepl.push([linkData, data])
    }  
}


  const secondaireData = (prObj, seObj, key, arrayRepl)=>{
    let newArray
    let linkData
    let data 
    console.warn({seObj})
    if (checkIfArray(seObj)) {
      // Handle array case
      replaceArray(seObj, key, arrayRepl);
    } else if (checkIfFunction(seObj)) {
      // Handle object case
      newArray = Object.keys(seObj ? seObj : {});
      replaceObj(newArray, prObj, key, arrayRepl);
    } else {
      // Handle primitive case
      linkData = "${" + key + "}";
      data = prObj[key];
      arrayRepl.push([linkData, data]);
    }
  }


const htmlReplaceData = (obj)=>{
    let arrayRepl = []
   let primaireData = Object.keys(obj ? obj:{});
   primaireData.forEach((da => secondaireData(obj, obj[da], da, arrayRepl)))
   return arrayRepl
}
  // Fonction qui sera appelée au clic du bouton "Run"
  const handleRunCode = () => {
    try {
        setSrcDoc('');
      // Parse le JSON entré par l'utilisateur
      const data = JSON.parse(jsonInput);
      // Remplacer les placeholders dans le HTML avec les valeurs du JSON
     let dynamicHtml = htmlTemplate
        let arrayReplace = htmlReplaceData(data)
        console.warn({arrayReplace})
        for (let i = 0; i < arrayReplace.length; i++) {
            let name = arrayReplace[i][1]
            let linkName  = arrayReplace[i][0]
           dynamicHtml = dynamicHtml.replace(linkName, name)
            
        }

      // Utilisation du HTML combiné et affichage du contenu dans un iframe
      setSrcDoc(dynamicHtml);
    } catch (error) {
      setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
    }
  };
  
  const convertArrayToObject = (array) => {
    const aboutUs = {};
    
    array.forEach((item, index) => {
      // Ajouter le titre
      aboutUs[`title${index}`] = item.title;
      
      // Ajouter les textes
      item.text.forEach((text, textIndex) => {
        aboutUs[`text${index}${textIndex}`] = text;
      });
  
      // Ajouter les éléments de la liste s'il y en a
      if (item.list.length > 0) {
        item.list.forEach((listItem, listIndex) => {
          aboutUs[`list${listIndex}`] = listItem;
        });
      }
    });
  
    // Ajouter un URL fixe comme dans l'exemple donné
    aboutUs['url'] = 'http://localhost?aboutus=true';
  
    return aboutUs;
  }

  const checkButton = (name)=>{
    if(name === 'JSON'){
        if(jsonInput != defExempleJSOM){
             return false
        }else{
            return true
        }
    }else if(name === 'HTML'){
        if(htmlTemplate != defExmpleHTML_CSS){
            return false
        }else{
            return true
        }
}
  }
  let aboutUs = convertArrayToObject(infoSource.laguangeElakay('aboutUs',  infoSource.languageAPI))

const getExemple = (name)=>{ 
  
  let objt = emailTemplates.find(objt => objt.title === getTilte)
 
    if(name === 'JSON'){
      
        if(checkButton(name)){
          exempleJSOM.aboutUs = aboutUs 
          let Jsom =  objt ? objt: {data:  exempleJSOM}
            // exempleJSOM.aboutUs = aboutUs
            let exemple = JSON.stringify(Jsom.data,  null, 2)
            setJsonInput(exemple)
        }else{
            setJsonInput('<!-- enter your combined JSON here... -->')
        }
    }else if(name === 'HTML'){
        if(checkButton(name)){
          let html = objt ? objt.htmlTemplate :  exmpleHTML_CSS
            setHtmlTemplate(html)
        }else{
            setHtmlTemplate('<!-- enter your HTML Et CSS template here...-->')
        }
    }
}
const buttonAdd =()=>{
  try {
    let data = JSON.parse(jsonInput)
        data.title = getTilte
        
    updateLanguageElakay0(languageTradictor._id, token, {[nameObj]: {_id: paragra._id, data, htmlTemplate}}, true).then(data => {
        if (data.error) {
           console.log(data.error)
        } else {
            setJsonInput('<!-- enter your combined JSON here... -->')
            setHtmlTemplate('<!-- enter your HTML Et CSS template here...-->')
            setTilte('')
            AlllanguageSelect()
            languageUsertra()
            setEditAdd(true)
            setParagra(ObjetPage)    
    }})
    }catch (error) {
    // Afficher l'erreur si le parsing échoue
    setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
  }

}
const buttonDelPara =()=>{
    deleteLanguageElakay0(languageTradictor._id, paragra._id, nameObj, token, true).then(data => {
        if (data.error) {
            console.log(data.error)
        } else {
            AlllanguageSelect()
            languageUsertra()
            setCheckDeletePara(false)
            setDeletePara(false)
            // setEditAdd(true)
            setParagra(ObjetPage)  
    }
    })
}

  const btnActiveExmple = () =>{
  return  <div className="row justify-content-center">
            <div className="col col-sm-12">
{
checkButton('JSON')?
<button  className="btn btn-primary float-right pb-2" onClick={()=>getExemple('JSON')}>SHOW JSON Exemple</button>
:
<button  className="btn btn-secondary float-right pb-2" onClick={()=>getExemple('JSON')}>Hide JSON Exemple</button>
}

{checkButton('HTML')?
<button  className="btn btn-primary float-right pb-2" onClick={()=>getExemple('HTML')}>SHOW HTML & CSS Exemple</button>
:
<button  className="btn btn-secondary float-right pb-2" onClick={()=>getExemple('HTML')}>Hide HTML & CSS Exemple</button>
}
<button  className="btn btn-primary float-right pb-2" onClick={()=>buttonAdd()}>save</button>

<button  className="btn btn-primary float-right pb-2" onClick={()=>setShowEmailPage(true)}>Show page</button>

<button  className="btn btn-primary float-right pb-2" onClick={handleRunCode}>Run</button>
      
    </div>
    </div>
  }
  let emailToken =  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzFhZDA3Nzk3YjM0ZTNiOTIzMmNjNzUiLCJpYXQiOjE3Mjk4MTA1NTF9.SsQHf1usaK-e_q9cRAbVwnyKWh3B_PXkunMBwU9RP3I"
  const pageIframe = ()=>{
           {/* Affichage du résultat dans un iframe */}
          return <div>
           { srcDoc &&  <h2>Result:</h2>}
          {  srcDoc &&  <iframe
                // srcDoc={generateEmailTemplate1(user, emailToken)}
                srcDoc={srcDoc}
                title="Result"
                sandbox="allow-scripts"
                style={{  backgroundColor: '#FFFFFF', width: '100%', minHeight: '1000px', border: '1px solid #ddd' }}
              />}
            </div>
}

const deleteAgree = () => {
         
    return (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                 size="xl"
                    show={deletePara}
                    onHide={() => setDeletePara(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h4 className="text-center">
                                {/* delete this items */}
                                {infoSource.language(infoSource, "title", "delThtems")}
                                </h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row justify-content-center m-4">
                        <textarea
        className='alert alert-success p-2'
          value={JSON.stringify(paragra,  null, 3)}
          onChange={(e) => setJsonInput(e.target.value)}
          placeholder="Enter the combined JSON here..."
          style={{ width: '100%', height: '500px', fontFamily: 'monospace', marginBottom: '20px' }}
        />
                            <div className="list-unstyled">
                                <input
                                    onChange={() => setCheckDeletePara(checkdeletePara ? false : true)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={checkdeletePara  ? 'checked' : ''}
                                    id="chButAgree"
                                />
                                <label className="form-check-label" for="chButAgree">
                                    {/* check the button if your are agree */}
                                    {infoSource.language(infoSource, "title", "chButAgree")}
                                    </label>
                                    
                            </div>
                          
                              {
                              paragra._id != undefined && checkdeletePara ?
                               <button onClick={() => buttonDelPara()} className="btn btn-primary float-right">Delete this param </button>
                               :
                               <button className="btn btn-primary float-right" disabled>Delete this param </button>
                            }
                      
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )
}

const printListeShop = () => (
    
            <Modal
                size="xl"
                show={showEmailPage}
                onHide={() =>  setShowEmailPage(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                <div> 
                    {selectObArData()}
                    <button  className="btn btn-primary float-left pb-2" onClick={handleRunCode}>Run</button>
                   {pageIframe()}
                </div>
                </Modal.Body>
            </Modal>
    
)


 const checkObjEmpty = (a)=>{
    return JSON.stringify(a) === '{}'

}

const handleTilte = () => event => {
    const value = event.target.value;
    checkTitleIsOk(languageTradictor[nameObj], value)
    setTilte(value)

};

const handleTilteData = () => event => {
    const value = event.target.value;
     setParagra(languageTradictor[nameObj][value])
    setTilte(languageTradictor[nameObj][value].data.title)
    setJsonInput(JSON.stringify(languageTradictor[nameObj][value].data,  null, 2))
    setHtmlTemplate(languageTradictor[nameObj][value].htmlTemplate)
   
    handleRunCode()
};

const selectObAr =()=>{
    return <div className="form-group">
           <label className="text-muted">
            {/* select languages and compare */}
            all title for edit 
            </label>
           <select value={getTilte} onChange={handleTilte()} className="form-control">
           <option value={''} > select tilte</option>
           {selEmailType.map((ob, i) =>{
            return <option value={ob} >{ob}</option>
           })}
           </select>
       </div>

}

const selectObArData =()=>{
    return <div className="form-group">
           <label className="text-muted">
            {/* select languages and compare */}
            data title for edit 
            </label>
           <select onChange={handleTilteData()} className="form-control" >
           <option value={0} > select data title</option>
           {!checkObjEmpty(languageTradictor) && languageTradictor[nameObj].map((ob, j) =>{
                return <option value={j} >{ob.data.title}</option>
               })}
           </select>
       </div>

}


const checkTitleIsOk = (array, title)=>{
  let newParagra = array.find(objt => objt.data.title === title)
  let Confirmation = array.some(item => item.data.title === title);

  if (!Confirmation) { 
    setJsonInput(defExempleJSOM)
    setHtmlTemplate(defExmpleHTML_CSS)
    setTilte(title)
    setParagra(ObjetPage)
    setEditAdd(true)
    // return true;
  } else { 
    setJsonInput(JSON.stringify(newParagra.data,  null, 2))
    setHtmlTemplate(newParagra.htmlTemplate)
    setTilte(newParagra.data.title)
     setParagra(newParagra)
    setEditAdd(false)
    // return false; 
  }
}

const loadErrorMessage = ()=>{
  return <div  className=" alert alert-danger" style={{  display: errorMessage ? "" : "none",  color: 'red', marginTop: '10px' }}>
      
  {errorMessage}
</div>
}

const btnAddEdit =()=>{
    
    return<> 
        <h6> edit / add</h6>
    <label className="switch">
      <input 
      type="checkbox" 
      checked={editAdd ? 'checked' : ''}
      />
      <span className="slider round"></span>
    </label>
    </>
    }

  return (
    <div>
    <div style={{ padding: '10px' }}>
   

      <h2>Elakay e-mail </h2> 
      <h2> Interactive Page with </h2>
      <h2>Combined JSON and HTML Template</h2>

      {showEmailPage ? 
      printListeShop()
      :
      ""
        // pageIframe()
        }
        {paragra._id != undefined &&  <button onClick={() => setDeletePara(true)} className="btn btn-primary float-right">Delete this param </button>}
    {btnAddEdit()}
    { selectObAr()}
    {/* {selectObArData()} */}
      {/* Textarea pour entrer le JSON */}
      <div>
        <h4>JSON Input</h4>
        <textarea
        className='p-4'
          value={jsonInput}
          onChange={(e) => {setJsonInput(e.target.value); setErrorMessage(null)}}
          placeholder="Enter the combined JSON here..."
          style={{ width: '80%', height: '400px', fontFamily: 'monospace', marginBottom: '20px' }}
        />
        
      </div>

      {/* Textarea pour entrer le HTML template */}
      <div>
        <h4>HTML Template</h4>
        <textarea
        className='p-4'
          value={htmlTemplate}
          onChange={(e) => {setHtmlTemplate(e.target.value); setErrorMessage(null)}}
          placeholder="Enter the HTML template here..."
          style={{ width: '100%', height: '600px', fontFamily: 'monospace', marginBottom: '20px' }}
        />

      </div>

      {/* Bouton Run */}
      {errorMessage && loadErrorMessage()}
      {btnActiveExmple()}
       {deleteAgree()}

    </div>
    </div>
  );
}

export default CodeEdit;
