import React from "react";
import { Link} from "react-router-dom";
import countries from "../../../countries.json";
import { Seller, } from "../../pageInside/Rolle"

const FormAddress = (props) => {
    const { 
        clickSubmit,
        values, 
        setValues,
        infoSource,
        phoneUser,
        loadCountries,
        allZipCodes, 
        setAllZipCodes,
        editBtnFunction,
        buttonActive = true,
        BorderActive = true 
        } = props
    const {
        firstName,
        lastName,
        shopName,
        tel,
        Address1,
        Address2,
        city,
        State,
        postalCode,
        country,
        error,
    } = values;


    function areStringsSameLength(string1, string2) {
        return  string1.length === string2.length;
      }
      
    const handleChange = name => event => {
        const value = event.target.value;

        
        if(name === "country"){ 
            setAllZipCodes([])
            loadCountries(value)
        }else if(name === "postalCode"){
            if(areStringsSameLength(allZipCodes[0], value)){
                loadCountries(country, value) 
            }

        }
        setValues({ ...values, [name]: value, error:false });
    };
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    const AdressTrue = firstName !== "" && lastName !== "" && tel !== "" && city !== "" && State !== "" && postalCode !=="" && country!== "" && Address1 !== "" 

      // Fonction générique pour rendre un champ input
  const renderInput = (id, value, label, placeholder, onChange, type = "text") => (
    <div className="form-group">
      <label className="text-muted">{label}</label>
      <input
        id={id}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        value={value}
        className="form-control"
      />
    </div>
  );
  

  // Fonction générique pour rendre un champ select
  const renderSelect = (id, value, label, options, onChange) => (
    <div className="form-group">
      <label className="text-muted">{label}</label>
      <select id={id} onChange={onChange} className="form-control" value={value}>
        <option value="">{infoSource.laguangeElakay("label", infoSource.languageAPI).pleaseSelect}</option>
        {options.map((option, i) => (
          <option key={i} value={option.code || option.International}>
            {option.name || option.International}
          </option>
        ))}
      </select>
    </div>
  );


    const formWithoutBorder = ()=>{
        return <>
        {showError()}
      
        <form className="m-4" onSubmit={clickSubmit}>
      {/* Country and Zip Code */}
      <div className="row">
        <div className="col-md-6">
          {renderSelect(
            "country",
            country,
            infoSource.laguangeElakay("label", infoSource.languageAPI).country,
            countries,
            handleChange("country")
          )}
        </div>
        <div className="col-md-6">
          {renderInput(
            "postal-code",
            postalCode,
            infoSource.laguangeElakay("label", infoSource.languageAPI).zipCode,
            infoSource.laguangeElakay("label", infoSource.languageAPI).zipCode,
            handleChange("postalCode")
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {renderInput(
            "city",
            city,
            infoSource.laguangeElakay("label", infoSource.languageAPI).city,
            infoSource.laguangeElakay("label", infoSource.languageAPI).city,
            handleChange("city")
          )}
        </div>
        <div className="col-md-6">
          {renderInput(
            "state",
            State,
            infoSource.laguangeElakay("label", infoSource.languageAPI).State,
            infoSource.laguangeElakay("label", infoSource.languageAPI).State,
            handleChange("State")
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          {renderInput(
            "address-line1",
            Address1,
            infoSource.laguangeElakay("label", infoSource.languageAPI).Address1,
            infoSource.laguangeElakay("label", infoSource.languageAPI).Address1,
            handleChange("Address1")
          )}
        </div>
        <div className="col-md-6">
          {renderInput(
            "address-line2",
            Address2,
            infoSource.laguangeElakay("label", infoSource.languageAPI).Address2,
            infoSource.laguangeElakay("label", infoSource.languageAPI).Address2,
            handleChange("Address2")
          )}
        </div>
      </div>

      {/* Remaining Form Fields */}
      <div className="row">
        <div className="col-md-6">
          {renderInput(
            "first-name",
            firstName,
            infoSource.laguangeElakay("label", infoSource.languageAPI).fisrtName,
            infoSource.laguangeElakay("label", infoSource.languageAPI).fisrtName,
            handleChange("firstName")
          )}
        </div>
        <div className="col-md-6">
          {renderInput(
            "last-name",
            lastName,
            infoSource.laguangeElakay("label", infoSource.languageAPI).lastName,
            infoSource.laguangeElakay("label", infoSource.languageAPI).lastName,
            handleChange("lastName")
          )}
        </div>
      </div>

      {Seller() && (
        <div className="form-group">
          {renderInput(
            "shop-name",
            shopName,
            infoSource.laguangeElakay("label", infoSource.languageAPI).shopName,
            infoSource.laguangeElakay("label", infoSource.languageAPI).shopName,
            handleChange("shopName")
          )}
        </div>
      )}

      <div className="form-group">
        {renderSelect(
          "tel",
          tel,
          infoSource.laguangeElakay("label", infoSource.languageAPI).phone,
          phoneUser,
          handleChange("tel")
        )}
      </div>

      {/* Buttons */}
      {buttonActive && (
        <div className="row justify-content-center">
        <div className="bg clearfix ">
          <Link onClick={()=>editBtnFunction()} className="btn btn-info pt-2 pb-2 pr-3 pl-3 rounded-4 float-left m-4">
            {infoSource.laguangeElakay("Button", infoSource.languageAPI).cancel}
          </Link>
          {AdressTrue ? (
            <button className="btn btn-info pt-2 pb-2 pr-3 pl-3 rounded-4  float-right m-4">
              {infoSource.laguangeElakay("Button", infoSource.languageAPI).save}
            </button>
          ) : (
            <button type="button" className="btn btn-infopt-2 pb-2 pr-3 pl-3 rounded-4  float-right disabled m-4">
              {infoSource.laguangeElakay("Button", infoSource.languageAPI).save}
            </button>
          )}
        </div>
        </div>
      )}
    </form>
        </>
    }
  const formAdd = () => {
    return <>
             <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 " style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourAddress}</h3>
                </div> 
              {/* **************************** */}
               
              <div className="contenair">
                    {formWithoutBorder()}
                
        </div>
            </div>
        </div>
    </>
  }
    return (
        <>
        {BorderActive ? formAdd (): formWithoutBorder()}
        </>
    )
};

export default FormAddress; 