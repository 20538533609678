// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-drawer{
    height: 100%;
    width: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    max-width: 280px;
    z-index: 4000;
    transform: translate(-100%);
    transition: transform 0.3s ease-out ;
    overflow: auto;
}

.side-drawer.open{
    transform: translatex(0);
    overflow: auto;
}
.side-drawer li{
    margin:0.5rem 0;
}
 
.side-drawer ul {
    height: 100%;
    list-style: none ;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.side-drawer a{
    color: #521751;
    text-decoration: none;
    font-size:1.3rem ;
}

.side-drawer a:hover,
.side-drawer a:active{
    color: #fa923f
}`, "",{"version":3,"sources":["webpack://./src/components/SIdeDrawer/SideDrawer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,0CAA0C;IAC1C,eAAe;IACf,MAAM;IACN,OAAO;IACP,gBAAgB;IAChB,aAAa;IACb,2BAA2B;IAC3B,oCAAoC;IACpC,cAAc;AAClB;;AAEA;IACI,wBAAwB;IACxB,cAAc;AAClB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;;IAEI;AACJ","sourcesContent":[".side-drawer{\n    height: 100%;\n    width: 100%;\n    background: white;\n    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);\n    position: fixed;\n    top: 0;\n    left: 0;\n    max-width: 280px;\n    z-index: 4000;\n    transform: translate(-100%);\n    transition: transform 0.3s ease-out ;\n    overflow: auto;\n}\n\n.side-drawer.open{\n    transform: translatex(0);\n    overflow: auto;\n}\n.side-drawer li{\n    margin:0.5rem 0;\n}\n \n.side-drawer ul {\n    height: 100%;\n    list-style: none ;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n.side-drawer a{\n    color: #521751;\n    text-decoration: none;\n    font-size:1.3rem ;\n}\n\n.side-drawer a:hover,\n.side-drawer a:active{\n    color: #fa923f\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
