import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { signup ,  signupUPdateRole, isAuthenticated, getElakayLang,  getUserPUBToken} from "../includes/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import logoElakay from '../includes/Layout/icon/LogoElakay.svg';
import EmailDataHtml from "../includes/pageInside/emailDataHtm";
import {SelectDataEmaail } from "../includes/auth/apiLanguage"
import jsSHA from "jssha";
import { roleUser } from "../includes/pageInside/roleUser";
import './user.css';
import {
    privacyPolicy,
    interpretationDefinitions,
    CollectingUsingYourPersonalData,
    ContactUs,
    TermsConditions
} from '../includes/pageInside/conditionTherm'

var sha1 = require('sha1'); 

const Signup = (props) => {
    const {
        infoSource
    }=props
    const {obj} = getElakayLang();
    let navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(true) 
    const [showConditions, setConditions] = useState(false);
     const { pubToken } = getUserPUBToken();
    const [values, setValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        error: "",
        verication:"",
        success: false
    });
    
    const roleSha1 = (roleUser, userID, index) => {
        if (!roleUser || !roleUser[index] || !roleUser[index].code || !roleUser[index].role || !userID) {
            throw new Error("Invalid input data");
        }
    
        // Fonction pour générer un SHA-1
        const sha1 = (data) => crypto.createHash("sha1").update(data).digest("hex");
    
        // Générer "kay" en combinant les hash SHA-1
        let kay = sha1(roleUser[index].code) + sha1(roleUser[index].role) + sha1(userID);
    
        // Créer un HMAC SHA-512 avec "kay" comme clé
        const shaObj = crypto.createHmac("sha512", kay);
        shaObj.update(kay);
        
        return shaObj.digest("hex"); // Retourne le hash SHA-512 en hexadécimal
    };

    const generateRoleHash = (roleUser, userID, index) => {
        if (!roleUser || !roleUser[index] || !roleUser[index].code || !roleUser[index].role || !userID) {
            throw new Error("Invalid input data");
        }
    
        // Générer la clé "kay"
        let kay = sha1(roleUser[index].code) + sha1(roleUser[index].role) + sha1(userID);
    
        // Créer un hash SHA-512 avec HMAC
        const shaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: kay, format: "TEXT" }
        });
    
        shaObj.update(kay);
    
        return shaObj.getHash("HEX"); // Retourne le hash en hexadécimal
    };


    const convertArrayToObject = (array) => {
        const aboutUs = {};
        
        array.forEach((item, index) => {
          // Ajouter le titre
          aboutUs[`title${index}`] = item.title;
          
          // Ajouter les textes
          item.text.forEach((text, textIndex) => {
            aboutUs[`text${index}${textIndex}`] = text;
          });
      
          // Ajouter les éléments de la liste s'il y en a
          if (item.list.length > 0) {
            item.list.forEach((listItem, listIndex) => {
              aboutUs[`list${listIndex}`] = listItem;
            });
          }
        });
      
        // Ajouter un URL fixe comme dans l'exemple donné
        aboutUs['url'] = 'http://localhost?aboutus=true';
      
        return aboutUs;
      }


    const { firstname, lastname, email, password, passwordConfirmation, success, error, verication } = values;

    const productTrue = firstname !== "" && lastname !== "" && email !== "" && password !== "" && passwordConfirmation !== "" && verication === ""
    const handleChange = firstname => event => {
        setValues({ ...values, error: false, [firstname]: event.target.value });
    };



    const clickSubmit = event => {
       
        event.preventDefault();
        signup({ firstname, lastname, email, password, passwordConfirmation, language: obj}, pubToken).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                if(!data){
                    setValues({ ...values, error: 'BAD REQUEST or email is not ok', success: false });
                }else{
                let role = data.next ?  generateRoleHash(roleUser, data.user._id, 5) : null
                data.next && signupUPdateRole({email: data.user.email,  userInfoRole:{role, roleName:'Royalty'}}, pubToken)
                setValues({
                    ...values,
                    firstname: "",
                    lastname: "",
                    email: "",
                    password: "",
                    passwordConfirmation: "",
                    error: "",
                    success: true
                });
            }
            }
        });;
    };

    const clickelakay = () => {
        navigate("/")
        window.location.reload()

    }
    const signUpForm = () => (
        <div>
            <div className="usHeader">
                <h1 onClick={() => clickelakay()}><img className="img-responsive" src={logoElakay} width="24" alt="logo elakay"/>elakay</h1>
            </div> <br />

            <form className="form-signin">
                <h4 className="text-center">{infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount}</h4>
                {showSuccess()}
                {showError()}
                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("firstname")} value={firstname} type="text" name="firstname" id="firstname" className="form-control" placeholder="Last name" required/>
                    <label className="text-muted" htmlFor="firstname">{infoSource.laguangeElakay('label', infoSource.languageAPI).fisrtName}</label>
                </div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("lastname")} value={lastname} type="text" name="lastname" id="lastname" className="form-control" placeholder="Last name" required />
                    <label htmlFor="lastname">{infoSource.laguangeElakay('label', infoSource.languageAPI).lastName}</label>
                </div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("email")} value={email} type="email" name="email" id="inputEmail" className="form-control" placeholder="Email address" required />
                    <label htmlFor="inputEmail">{infoSource.laguangeElakay('label', infoSource.languageAPI).email}</label>
                </div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("password")} value={password} type={showPassword ? "password" : "text"} name="password" id="inputPassword" className="form-control" placeholder="Password" />
                    <label htmlFor="inputPassword">{infoSource.laguangeElakay('label', infoSource.languageAPI).Password}</label>

                </div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("passwordConfirmation")} value={passwordConfirmation} type={showPassword ? "password" : "text"} name="passwordConfirmation" id="inputPassword2" className="form-control" placeholder="Password" required />
                    <label htmlFor="inputPassword2">{infoSource.laguangeElakay('label', infoSource.languageAPI).confirmPassword}</label>
                                    <span className="h4"  > {showPassword ? <FaEye onClick={() => setShowPassword(false)} /> : <FaEyeSlash onClick={() => setShowPassword(true)} />}</span> 
                                </div>

                <input type="hidden" name="verication" placeholder="" />
                 
                <Link to="#" onClick={() => setConditions(true)} >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</Link>
                  
                {
                productTrue ?
                   
                <button onClick={clickSubmit} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount}</button>
                 :
                    <button className="btn btn-lg btn-light btn-block">create account</button>
                }
                
                <Link className="btn btn-lg btn-block mt-3" to="/signin" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).signin}</Link>
            </form>
        </div>
    );
    const AddTermsAndConditions = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="lg"
                    show={showConditions}
                    onHide={() => setConditions(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2 className="text-center">Terms and Conditions</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {TermsConditions()}
                        {privacyPolicy()}
                        {interpretationDefinitions()}
                        {CollectingUsingYourPersonalData()}
                        {ContactUs()}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
            Your account elakay is created. Please  go to your email to validate your account <Link to="/signin">sign in</Link>
        </div>
    );
    if (isAuthenticated()) {
        return navigate("/")
    }

  
    return (
        <div>

            {signUpForm()}
            {AddTermsAndConditions()}
            {/* SelectDataEmaail() */}
        
        
              </div>
    );
};
export default Signup;