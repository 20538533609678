import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { elakay_API } from "../../config";
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Slider from '../slider/swiperProduct'
import CardCircle from '../pageInside/CardCircle'
const CardAllcategories0 = (props) => {
const {
    laguangeElakay,
    infoSource,
    setLevel,
     level
} = props

const ButtonViewCategories  =(ar)=>{ 
    setLevel({
        ...level,
        "level_2" : ar
    })

}

let StyleMenu0 = {
    borderBottom: "6px solid Gainsboro",
    backgroundColor: "GhostWhite"
}

let StyleMenu = {
    borderBottom: "6px solid Gainsboro",
    backgroundColor: "SkyBlue"
}
const styleFonc =(check)=>{
    return check ? StyleMenu : StyleMenu0
}
const level0 = ()=>{ 
    return <ul className="list-group list-group-flush">
     { level.level_1 &&  level.level_1.map((m,i) =>( <li key ={i} className="list-group-item" onClick={()=>ButtonViewCategories(m.supCategoryID)} style={ styleFonc(level.level_2._id === m.supCategoryID._id)}>
        {/* {m.supCategoryID.name[0]} */}
        {infoSource.getCategoryNames(m).name[0]}
        </li>))}
    
  </ul>

}
const level0View = ()=>{
   return  level.level_2.image_r &&  level.level_2.image_r.urlImg && <div className="card mx-auto viewPage"> 
    <Link to={`/product/${ level.level_2._id}`}>
        {/* <span className="card-title" style={{ fontSize: "50px" }}> <BsGridFill /></span> */}
      
        <span><h3>
            {/* View Page */}
            {infoSource.laguangeElakay('title', infoSource.languageAPI).viewPage}
            </h3></span>
        <img
            className="rounded"
            src={ level.level_2.image_r &&  level.level_2.image_r.urlImg}
            onError={event => {
            event.target.src = imgProductLakay
            event.onerror = null
                        }}
        alt={ level.level_2.name}
        style={{ height: "100%", width: "100%" }}
/>
    </Link>
    </div>
    
}
const level1 = ()=>{
  return  level.level_2.items.length > 0 && <Slider
  circle ={true}
  wm0={3}
  ProductSwiper={  level.level_2.items}
  classSlider={"container"}
  sliderShow={true}
/>
}
return (
    <div className="row align-items-center">
    <div className="col-5 col-md-5 mt-4 p-1">
    {level0()}
    </div>
    <div className="col-7 col-md-7 m-0 p-1">
        {level0View()}
    </div> 
    <div className="col-12">
        {level1()}
    </div>
    </div>
)



};

export default CardAllcategories0;