import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { isAuthenticated} from '../auth';
import { Link} from "react-router-dom";
import { FaRegTrashAlt,  FaEdit } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import { AddFilterName, getFilterName, deteleFilter, updateFilter, updateTranslFil} from "../auth/apiCategory";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import JsonAddLangueCat from "../pageInside/JsonAddLangueCat";
import EditableFilter from "../pageInside/editFilterElakay";
import "./category.css"


const CreatCategory = props => {
    const {
        infoSource,
        laguangeElakay
            } = props
  
    const { token} = isAuthenticated();
    const [name, setName] = useState("");
    const [filters, setFilters] = useState([]);
    const [showJsonAdd, setShowJsonAdd] = useState(false)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reName, setReName] = useState("");
    const [message, setMessage] = useState(false);
    const [success, setSuccess] = useState(false);
     const [filterItem, setFilterItem] = useState({})
    const [showDeleteAgree, setDeleteAgree] = useState(false);
    const [showEditName, setEditName] = useState(false);
    const [chek, setChek] = useState(false);
    const [seletName, setSeletName] = useState('');
    const handl = (a) => {
        setChek(a)
    }

    useEffect(() => {
        loadFilters(token)
    }, [token])
        
    let mp = 'm-0 p-1'
 const submitJsonInput =(value)=>{
  
        try {
            updateTranslFil(token, JSON.parse(value.getJsonInput), value.id ).then(data => {
                if (data.error) {
                    value.setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`)
                } else {
                    setShowJsonAdd(false)
                    loadFilters(token)
                    value.setErrorMessage(null)
                }
            });
        // setNewObejet(JSON.stringify(obj,  null, 3))
        value.setErrorMessage(null)
      //    
        } catch (error) {
      // Afficher l'erreur si le parsing échoue
      value.setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
      }
      }
    const clickSubmit = e => {
        e.preventDefault();

        setError("");
        setSuccess(false);
        // make request to api to create category
        AddFilterName (token, {name}).then(data => {
            if (data.error) {
                // loadCategory(elakayCategoryID);
                setError(data.error);
            } else {

                setError("");
                setSuccess(true);
                setMessage('the filters name: ' + name + ' is Create')
                setName("")
                loadFilters(token)
 
            }
        });
    };
// const LinkHref = (path)=>{
//     navigate(path)
// }
    const deleteFIL = (filterID, token) => {
        deteleFilter(filterID ,token).then(data => {
            if (data.error) {
                setError(data.error);
                // loadCategory(categoryID)
            } else {
                setError(false);
                setSuccess(true);
                setMessage('sub Items name ' + name + ' is deleted')
                loadFilters(token)
                setDeleteAgree(false)
                setChek(false)
            }
        });
    };
    const showSuccess = () => {
        if (success) {
            return <div className="alert alert-info" style={{ display: message ? "" : "none" }}> {message} </div>;
        }
    };

    const showError = () => {
        if (error) {
            return <div className="alert alert-danger" style={{ display: error ? "" : "none" }}> {error} </div>;
        }
    };

    const handleChange = e => {
        setError('');
        setName(e.target.value);
    };

    const loadFilters = (token) => {
        getFilterName(token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setFilters(data)
                setLoading(false)
            }
        });
    };

    const SubmitB = (name) => {
        setSeletName(name)
        setDeleteAgree(true)
        setEditName(false)
    }

    const SubmitA = (name) => {
        setSeletName(name)
        setDeleteAgree(false)
        setEditName(true)
       
    }

    const btnTranslateCat =(value)=>{
        setShowJsonAdd(true)
        setFilterItem(value)
    }

const clickSubmitEdit = (id, data) => {
        updateFilter(token, id, data).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setEditName(false)
                loadFilters(token)
            }
        });
    };

    const deleteAgree = (name, filterID, token) => {

        return (
            <div className="contaire">
                <div className="row justify-content-center " >
                    <Modal
                        show={showDeleteAgree}
                        onHide={() => setDeleteAgree(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h4 className="text-center">
                                    {/* delete this items */}
                                    {infoSource.language(infoSource, "title", "delThtems")}
                                    </h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row justify-content-center m-4">
                                <h5>
                                    {/* are you sure you want to delete this category name's  */}
                                    {infoSource.language(infoSource, "title", "surToDelete")}
                                    <span className="text-danger" > {name}</span> 
                                    </h5>
                                <div className="list-unstyled">
                                    <input
                                        onChange={() => handl(chek ? false : true)}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={chek ? 'checked' : ''}
                                        id="surToDelete"
                                    />
                                    <label className="form-check-label"  for="surToDelete">
                                        {/* check the button if your are agree */}
                                        {infoSource.language(infoSource, "title", "chButAgree")}
                                        </label>
                                </div>
                                {
                                    chek ?
                                        <Button onClick={() => deleteFIL(filterID, token)}>
                                             {infoSource.language(infoSource, 'Button', "submit")}
                                        </Button> :
                                        <button className="btn" >
                                         {infoSource.language(infoSource, 'Button', "submit")}
                                        </button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }
    const editNameCat = (name, FilterItem, filter_id ) => {
         
        return (
            <div className="contaire">
                <div className="row justify-content-center " >
                    <Modal
                        show={showEditName}
                        onHide={() =>setEditName(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h4 className="text-center">
                                Add, Edit & Remove Filter
                                    {/* {infoSource.language(infoSource, "Button", "edFilter")}  */}
                                   {" "} {name}</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <EditableFilter
                        filter = {{name, FilterItem, filter_id}}
                        clickSubmitEdit={clickSubmitEdit}
                        />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }

    const CategoryFom = () => (
        <div>
            <div className="boxCategory1" id="heading">

                <h2> 
                    {/* Filters Elakay  */}
                    {infoSource.language(infoSource, "title", "FilElakay")}
                    </h2>
                {showSuccess()}
                {showError()}
            </div>

            <div className="boxCategory">

                {filters.map((c, i) => (
                    <form className="container" key={i} >
                        <div className="itemCategory row">
                            <div className="col col-lg-6 Cacol">
                                <Link to={`items/${c._id}`}>  <h5 >
                                    {c.name}
                                </h5>
                                </Link>

                            </div>
                             <div className= {`col-1 col-lg-2 Cacol ${mp}`} >
                                     <span onClick={()=>btnTranslateCat({name: c.name, filter_id: c._id, FilterItem: c.FilterItem, lang: c.lang})}><MdLanguage /></span>
                                </div>
                            <div className={`col-2 col-lg-2 Cacol ${mp}`}>
                                    <p className="dellCategory" onClick={() => SubmitA(c.name)}><FaEdit /></p>
                            </div>
                            <div className="col col-lg-2 Cacol">
                                <span onClick={() => SubmitB(c.name)}  className="dellCategory"><FaRegTrashAlt /></span>
                            </div>
                        </div>
                        <input className="inputCategory" type="hidden" name="listName" value=""></input>
                        {
                            (c.name === seletName) && (
                            deleteAgree(c.name, c._id, token)
                            )
                            }

                            {(c.name === seletName) && editNameCat(c.name, c.FilterItem, c._id)}
                    </form>
                ))}
         
                    <form className="itemCategory" onSubmit={clickSubmit}>
                        <input
                            onBlur={() => setMessage("")}
                            className="inputCategory"
                            type="text"
                            placeholder="New Item"
                            onChange={handleChange}
                            value={name}
                            autoFocus
                            required
                        />
                        <button className="AddCategory">+</button>
                    </form>
            
            
            </div>

        </div>
    )

    return (
        <Layout
            infoSource ={infoSource}
            className="container blockEl"
            SliderPunShow={false}
            loading={loading}
        > 
        <JsonAddLangueCat
            infoSource ={infoSource}
            showJsonAdd={showJsonAdd}
            setShowJsonAdd={setShowJsonAdd}
            catalogItem={filterItem}
            submitJsonInput={submitJsonInput}
            filter={true}
            />
            <div style={infoSource.styleNav}>
            <div className="row " onBlur={() => setMessage("")}>
                <div className="col-md-8 offset-md-2 blockEl">
                    {CategoryFom()}
                </div>
            </div>
            </div>
        </Layout>
    );
};

export default CreatCategory;