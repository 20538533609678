import React from 'react';
import StarRating from "react-star-ratings";
import { vote, deleteVote } from "../../auth/apiProduct";


export const ratingVote =(a=[]) => {
    const sum = (total, num) => (
        total + Math.round(num)
    ); 
    const sumVote = (
    a.map((p) => p.voteNum)
    )
   return parseFloat(sumVote.reduce(sum, 0) / a.length)   
}

class Vote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: props.rating,
            starSize : props.starSize? props.starSize : '22px'
        };
    }

    onStarClick(nextValue, prevValue,) {
        // this.setState({ rating: nextValue });
        const {productID, token, ButtonAddComments} = this.props

        vote(productID, token, nextValue ).then( data => {
            if (!data) {
                console.log(data.error);
            }else{
                ButtonAddComments(false)
            }
            })

        // if(prevValue === nextValue){
        
        //     deleteVote(this.props.productID, this.props.token, nextValue).then(data => {
        //         if(data.error){
        //             console.log(data.error)
        //         }else{
        //             this.props.loadProductsView(this.props.productID)
                    
        //         }
        //     })
        
        // } else if (prevValue !== nextValue){
          
        //         vote(this.props.productID, this.props.token, nextValue ).then(
        //             this.props.loadProductsView(this.props.productID)
        //         )
        
        // }
    }

    render() {
        const { rating, starSize } = this.state;
       
        const click = (clickShow) => ( 
            clickShow && ( this.onStarClick.bind(this)))


        return (
            <StarRating
            name="vote"
            numberOfStars={5}
            rating={rating}
            starRatedColor="goldenRod"
            starDimension={starSize}
            starSpacing="1px"
            changeRating={click(this.props.clickShow)}
        />
        );
    }
}

export default Vote;