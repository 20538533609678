import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableElakay, {tableBody, tableHead, tableRow} from "./commentaire/formTable";
import moment from "moment";
import Loader from "../Layout/loader.gif";
import CardsForm from "../pageInside/creditCardType";
import Modal from 'react-bootstrap/Modal'
import { CustomersRetrieve, retrievePayment, PaymentIntent, listePM_createStripeCusTomer} from "../auth/stripe"
import contries from "../../countries.json";
import {
    createOrder,
    taxProductArea
} from "../auth/apiProduct";
import {
    ItemsOrder,
    prodDiscount,
    prodPrice,
    TaxOrder,
    getTotal
} from '../auth/allPriceFeet'

import {
    createAddressShip, createtransactionShip
} from "../auth/shipping";
import StripeError from "../pageInside/StripeError" 
import {getAddressById, isAuthenticated, getUserPUBToken  } from "../auth";

const adressSeleted =(a, b)=>{
    let y 
    a && a.filter(It =>{
    if(It[b] === true){
        y = It
    }
})
return y
}

function checkIfObjectIsEmpty(obj) {
    // Vérifiez si l'entrée est bien un objet
    if (typeof obj !== 'object' || obj === null) {
      console.error("Invalid input: not an object.");
      return false;
    }
  
    // Vérifiez si l'objet est vide
    if (Object.keys(obj).length === 0) {
      return false;
    }
  
    // Retourne "frais" si l'objet n'est pas vide
    return true;
  }

const Checkout = ({
    laguangeElakay,
    infoSource,
    // userInfo,
    Saddress,
    setSAddressFinal,
    addressSel,
    products,
    quantity,
    cart= false,
    count,
    feetShip = 1.3,
    addressShop
    // setRates,
    // rates,
}) => {
    let navigate = useNavigate()

    const [show, setShow] = useState(false);
    const [taxprod, setTaxProd] = useState({});
    const [laodShip, setLaodShip] = useState(true);
    const [PaymentCh, setPayment] = useState(false);
    const [addressFinal, setAddressFinal] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [IDpaymentMethods, setIDPaymentMethods] = useState('');


    const [data, setData] = useState({
        loading: false,
        success: false,
        clientToken: null,
        error: "",
        instance: {},
        address: "",
        redirectToFilter: false
    });

    const [SPM, setSPM] = useState({
        PM_id_sel: '',
        b_name:'',
        b_exp_month: '',
        b_exp_year:'',
        b_city: '',
        b_brand:'',
        b_last4:'',
        b_country: '',
        b_line1: '',
        b_postal_code: '',
        b_state: ''
    });
    const {
        PM_id_sel,
        b_name,
        b_exp_month,
        b_exp_year,
        b_city,
        b_brand,
        b_last4,
        b_country,
        b_line1,
        b_postal_code,
        b_state
    } = SPM;

const CheckTaxZone = (tax)=>{
return  tax ? tax.EstimatedCombinedRate : 0.088750
}
const checkQuantity = (Q)=>{
    return  Q === 0 ? 0 : 1
    }
      const { token} = isAuthenticated()
    const { pubToken } = getUserPUBToken();

    const redicectToFilters = (redirectToFilter) => {
        if (redirectToFilter) {
            return  navigate("/")
        }
    };
    const deliveryDay = (a) => {
        var today = new Date()
        var b = today.setDate(today.getDate() + a);;
         return b
    }

    const ItemsOrder = () => {
        return products.reduce((currentValue, nextValue) => {
            return currentValue + ( checkQuantity(nextValue.quantity) * nextValue.count * (nextValue.price * (100 - nextValue.salePrice) / 100))
        }, 0);
    };

    const ShippingOrder = () => {
        return products.reduce((currentValue, nextValue) => {
            // return products[0].shipping ? 0 : currentValue + (5 * feetShip)
            return 0
        }, 0);
    };

    const TaxOrder = () => {
        return products.reduce((currentValue, nextValue) => {
            return currentValue + (checkQuantity(nextValue.quantity) * nextValue.count *(nextValue.price * (100 - nextValue.salePrice) / 100) * CheckTaxZone(nextValue.taxprodZone))
        }, 0);
    };

    const CheckActiveBtn = () => {
        return products.reduce((currentValue, nextValue) => {
            return cart ? (currentValue + nextValue.quantity) >= (currentValue + nextValue.count) : quantity >= count
        }, 0);
    };
    const getTotal = () => {
        let a = ItemsOrder(products)
        // let a = 0
        let b = ShippingOrder() ? ShippingOrder(): 0
        let c = TaxOrder()
        let d = a + b + c
        return d
     
    };

    const laadingGIf = () => (
    
                    <div className="mx-auto" style={{ width: "200px", margin: "15%" }}>
                        <img src={Loader} className={`search-loading ${laodShip ? 'show' : 'hide'}`} alt="loader" />
                    </div>
    )

    const getCustomersRetrieve = (token) => {
        token && (
            CustomersRetrieve(token).then(data => {
                if (data.error){
                console.log("data not found")
                // window.location.href = '/'
             }else{
                    if (data.deleted){
                        setShow(data.deleted)
                    }else{
                    setIDPaymentMethods(data.invoice_settings.default_payment_method);  
                    }    
             }     
            })
            );

    };

    const Customercreate = (token) => {
        token && (
           
            listePM_createStripeCusTomer(token).then(data => {
                if (data.error){
                  console.log("data not found")
                  setShow(true)
              }else{
                    setPaymentMethods(data);  
              }    
            })
            );
    };

 

    useEffect(() => {
        Customercreate(token)
        getCustomersRetrieve(token)
        ViewPaymentDefault(token, IDpaymentMethods)
        newCartProd(products, PM_id_sel)
        // creatShippingAddd(token, Saddress, count)

    }, [token, IDpaymentMethods, Saddress, count, products]);

    const selectAdress = () => event => {
        const IdAdress = event.target.value;
        addressSelecctId(token, IdAdress)
    }
    const selectPayment = () => event => {
        const PM_id_2 = event.target.value;
        ViewPaymentDefault(token, PM_id_2)
    }
    const addressSelecctId = (token, adressId) => {

        adressId && (
            getAddressById(token, adressId).then(data => {
                if(!data){ 
                    console.log("ddata not found");
                }else{
                    setSAddressFinal({
                        firstName: data[0].firstName,
                        lastName: data[0].lastName,
                        tel: data[0].tel,
                        city: data[0].city,
                        country: data[0].country,
                        line1: data[0].Address1,
                        postal_code: data[0].postalCode,
                        state: data[0].State
                    })
                }
            })
        )
    }

    const newCartProd = async (newCart, PM_id_sel)=>{
        for (let i = 0; i < newCart.length; i++) {
          
            newCart[i].paymentIntent.payment_method = PM_id_sel; // Ajout du nouveau champ
        
        }
      }

    const ViewPaymentDefault = (token, IDpaymentMethods) => {
        IDpaymentMethods && (
            retrievePayment(token, { IDpaymentMethods }).then(data => {
                if(!data){
                    console.log("data not found")
                }else{
                    newCartProd(products, data.id)
                    setSPM({
                        PM_id_sel: data.id,
                        b_name: data.billing_details.name,
                        b_exp_month: data.card.exp_month,
                        b_exp_year: data.card.exp_year,
                        b_city: data.billing_details.address.city,
                        b_brand: data.card.brand,
                        b_last4: data.card.last4,
                        b_country: data.billing_details.address.country,
                        b_line1: data.billing_details.address.line1,
                        b_postal_code: data.billing_details.address.postal_code,
                        b_state: data.billing_details.address.state
                    })
                }
            })
      );

    };


    const newPostForm = () => (
        <>
            <form className="mb-3">
                <div className="form-group">
                    <label className="text-muted mt-3">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCheckOutProd2}</label>
                    <select onChange={selectAdress()} className="form-control">
                        <option value='0'>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
                        {
                            addressSel.map((Adre, i) => (
                                <option key={i} value={Adre._id}>({Adre.firstName} {Adre.lastName}) {Adre.Address1}, {Adre.city} {Adre.State} {Adre.postalCode} {Adre.country}</option>
                            ))
                        }
                    </select>
                </div>
                <button onClick={() => setAddressFinal(true)} type="button" className="btn btn-outline-primary m-4 float-left">{infoSource.laguangeElakay('Button', infoSource.languageAPI).change}</button>
            </form>
        </>
    )
    const buttonCheckout =()=>{
        createPaymentIntent()
        setPayment(false)

    }


        const newFormPaymentsel= () => (
        <>
            <form className="mb-3">
                <div className="form-group">
                    <label className="text-muted mt-3">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCheckOutProd3}</label>
                        <select onChange={selectPayment()} className="form-control">
                        <option value='0'>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
                        { paymentMethods &&  paymentMethods.map((Adre, i) => (
                                    <option key={i} value={Adre.id}>{Adre.card.brand}
                                     {/* ending */}
                                     {infoSource.laguangeElakay('label', infoSource.languageAPI).ending}: ...{Adre.card.last4}
                                      {/* expires */}
                                      {infoSource.laguangeElakay('label', infoSource.languageAPI).expires}: {Adre.card.exp_month}/{Adre.card.exp_year}</option>
                            ))
                        }
                    </select>
                        <Link to="/wallet?addCard=true" className="float-right m-3">{infoSource.laguangeElakay('Link', infoSource.languageAPI).addOrtherCard}</Link>
                </div>
            </form>
        </>
    )

    // products[0].paymentIntent.payment_method = PM_id_sel

   

    const createPaymentIntent = () => {
        token && (
            createOrder({token, pubToken}, createOrderDataNew)
                        .then(response => {
                            setData({
                                loading: false,
                                redirectToFilter: true,
                                success: true

                            });
                        
                }).catch(error => {
                    console.log(error);
                    setData({ loading: false });

                })
        )
    };

    // const createPaymentIntent = () => {
    //     token && (
    //         PaymentIntent(token, { PAYMENT_METHOD_ID: PM_id_sel, amount: parseFloat(getTotal()).toFixed(2) })
    //             .then(response => {
    //                const createOrderData = {
    //                     products: products,
    //                     transaction_id: response.id,
    //                     amount: response.amount / 100 ,
    //                     shippingAddress: Saddress,
    //                    shipping_amount_local: ShippingOrder(),
    //                     Delivered: deliveryDay()
    //                 };
    //                 createOrder(token, createOrderData)
    //                     .then(response => {
    //                         setData({
    //                             loading: false,
    //                             redirectToFilter: true,
    //                             success: true

    //                         });
    //                     })   
    //             }).catch(error => {
    //                 console.log(error);
    //                 setData({ loading: false });

    //             })
    //     )
    // };


const ItemIfon = ()=>(
    <div className="row mt-4 mb-4 justify-content-center">
        <div className="col col-12 col-md-11 col-lg-10 col-xl-9" style={{ border: "1px solid Gainsboro" }} >
            <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).itemDetail}</h4>
            {PaymentDetay2()}
        </div>
    </div>

)

    // let SelectedAddress = addressSelect !== "" ? addressSelect : addressDefault


    const {
        firstName,
        lastName,
        tel,
        city,
        country,
        line1,
        postal_code,
        state
    } = Saddress;

    const handleChange = name => event => {
        const value = event.target.value;
        setSAddressFinal({ ...Saddress, [name]: value });
    };
const backDefault =(token)=>{
    // DefaultSadress(token)
    setAddressFinal(false)

}

const createOrderDataNew = {
    products: products,
    amountTotal: parseFloat(getTotal()).toFixed(2),
    shippingAddress: Saddress,
   shipping_amount_local: ShippingOrder(),
    Delivered: deliveryDay()
};


 const ShippingAddress = () => (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-11 col-lg-10 col-xl-9" style={{ border: "1px solid Gainsboro" }} >
                <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).shippnigAddress}</h4>
             {Saddress ?
               <>
                     <h6>{firstName} {lastName}</h6>
                     <h6>{line1}</h6>
                     <h6>{city}</h6>
                     <h6>{`${state}, ${postal_code}, ${country}`}</h6>
             <h6>{infoSource.laguangeElakay('label', infoSource.languageAPI).phone} : {tel}</h6>
             <div>
                 {
                             addressFinal ? formAddress() : newPostForm()
                     }
             </div>
             
             </>
             :
             <span className="text-danger">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCheckOutProd1}</span>
               }
               <Link to="/address" className="float-right">{infoSource.laguangeElakay('Link', infoSource.languageAPI).changeDefaultAddress}</Link>
            </div>
            
        </div>

    )
  
    const Payment_Info = () => (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-11 col-lg-11 col-xl-9" style={{ border: "1px solid Gainsboro" }} >
                <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).paymentInfo}</h4>
                <div className="row">
                    <div className="col col-12  col-lg-7" >
                        {b_last4 && (
                        <CardsForm  
                        last4 = {b_last4} 
                        brand = {b_brand}
                        exp_month = {b_exp_month}
                        exp_year =  {b_exp_year}
                        name={b_name}
                        /> 
                        )}

                    </div>
                    <div className="col col-12 col-lg-5" >
                        <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).billingAddress}</h4>
                        <h6>{b_name}</h6>
                        <h6>{b_line1}</h6>
                        <h6>{b_city}</h6>
                        <h6>{`${b_state}, ${b_country}, ${b_postal_code}`}</h6>

                    </div>

                </div>
                {newFormPaymentsel()}
        </div>
        </div>

    )
    // val[key] != ''
    const checkPayBtn = () => {
        let pro = createOrderDataNew.products.length > 0 ? true : false
        let cusStriID = createOrderDataNew.products.every((p ) => p.paymentIntent.payment_method != '')? true : false
        let AdressTrue =    firstName !== "" && lastName !== "" && tel !== "" && city !== "" && country !== "" && line1 !== "" && postal_code !== "" && state !== "" 
        
    return pro && cusStriID && AdressTrue
}
    const Payment_Button = () => (
        
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-11 col-lg-10 col-xl-9">
            
          {
           checkPayBtn() ? 
            <button type="button" onClick={() => buttonCheckout()} className="btn btn-md btn-info btn-block mt-2">
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).buyNow}
            </button>
                :
            <button  className="btn btn-md btn-info btn-block mt-2 disabled">
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).buyNow}
            </button>
            }

            </div>
        </div>
    

    )
    const formAddress = () => (
        <>
            <label className="text-muted mt-3">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCheckOutProd2}</label>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).fisrtName}</label>
                <div className="controls">
                    <input id="address-line1" onChange={handleChange('firstName')} type="text" placeholder={firstName} className="form-control" />
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).lastName}</label>
                <div className="controls">
                    <input id="address-line1" onChange={handleChange('lastName')} type="text" placeholder={lastName} className="form-control" />
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).Address1}</label>
                <div className="controls">
                    <input id="address-line1" onChange={handleChange('line1')} type="text" placeholder={line1} className="form-control" />
                    <p className="help-block">{infoSource.laguangeElakay('title', infoSource.languageAPI).Address1}</p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).city}</label>
                <div className="controls">
                    <input id="city" onChange={handleChange('city')} type="text" placeholder={city} className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).State}</label>
                <div className="controls">
                    <input id="region" onChange={handleChange('state')} type="text" placeholder={state}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).zipCode}</label>
                <div className="controls">
                    <input id="postal-code" onChange={handleChange('postal_code')} type="text" placeholder={postal_code}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).country}</label>
                <div className="controls">
                    <select id="country" value={country} onChange={handleChange('country')} className="form-control" placeholder={country}>
                        <option value="Selected" >{infoSource.laguangeElakay('label', infoSource.languageAPI).country}</option>
                        {contries.map((c,i)=> <option value={c.code}>{c.name}</option>) }
                        </select>
                </div>

                <button onClick={() => backDefault(token)} type="button" className="btn btn-outline-primary m-4 float-left">{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button> <button onClick={() => setAddressFinal(false)} type="button" className="btn btn-outline-primary m-4 float-left">{infoSource.laguangeElakay('Button', infoSource.languageAPI).change}</button>
            </div>
        </>
    )
    

    const PaymentNotS2 =()=>(
        <table className="table table-sm">
            <tbody>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).items} : ({count}) </th>
                    <td>{(ItemsOrder(products)).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })} </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                    <td>{
                        isAuthenticated() ?
                            (ShippingOrder()).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) :
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                    }
                    </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                    <td>{(TaxOrder()).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                        {products[0].shipping}
                    </td>
                </tr>
                <tr className="text-danger">
                    <th style={{ fontSize: "18px" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).total}: </th> <td style={{ fontSize: "18px" }}>{(getTotal()).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}</td>
                </tr>
               {!products[0].shipping?
                <tr>
                <td>
                    <span>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping}</span> <br />{infoSource.laguangeElakay('label', infoSource.languageAPI).estDelivery} : {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping5}
                </td>
                </tr>:
                <tr>
                <td>
                    <span>{infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping1}</span> <br />{infoSource.laguangeElakay('label', infoSource.languageAPI).estDelivery} : {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping4}
                </td>
                </tr>}
            </tbody>
        </table>
        
    )

const PaymentDetay2 =()=>(
    isAuthenticated() ?
    <table className="table table-sm">
        <tbody>
            <tr>
            <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).items}({count}) :</th>
                <td>
                    {(ItemsOrder(products)).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD' })}
                </td>
            </tr>
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} :</th>
                <td>
                    {isAuthenticated() ?
                    (ShippingOrder()).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'}):
                    (0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    })}
                </td>
            </tr>
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax}: </th>
                <td>
                {(TaxOrder()).toLocaleString('en-US',{style: 'currency', currency: 'USD'})}
                {products[0].shipping}
                </td>
            </tr>
            <tr className="text-danger">
                <th style={{ fontSize: "18px"}}> 
                {infoSource.laguangeElakay('label', infoSource.languageAPI).total} :
                </th> 
                <td style={{ fontSize: "18px"}}>{(getTotal()).toLocaleString('en-US', {style: 'currency', currency: 'USD' })}</td>
            </tr>
            {
        
            !products[0].shipping ?
            <tr>
                <td>
                    <span>{infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping2}</span> <br /> {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping5}
                </td>
            </tr>:
            <tr>
            <td>
                <span> {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping1}</span> <br /> {infoSource.laguangeElakay('label', infoSource.languageAPI).estDelivery}: {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping4}
            </td>
            </tr>
            }
        </tbody>
    </table>
    : 
        PaymentNotS2()
        )

    const PaymentSlider = () => (
        <div>
                <Modal
                    size="xl"
                    show={PaymentCh}
                    onHide={() => setPayment(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2 className="text-primary">{infoSource.laguangeElakay('title', infoSource.languageAPI).checkout}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {checkIfObjectIsEmpty(addressShop) && Payment_Button()}
                    {ItemIfon()}
                    {ShippingAddress()}
                    {Payment_Info()}
                    {checkIfObjectIsEmpty(addressShop) && Payment_Button()}
                      </Modal.Body>
                </Modal>
            </div>
    )
    const BSbutton =()=>{

      return  ! isAuthenticated()?
          <Link to="/signin">
              <button className="btn btn-md btn-info btn-block">{infoSource.laguangeElakay('Button', infoSource.languageAPI).signinTocheck}</button>
          </Link>
          :
          !Saddress.country ? 
              <Link to="/address" className="btn btn-md btn-info btn-block">{infoSource.laguangeElakay('Button', infoSource.languageAPI).addAdress}</Link>
              :
              paymentMethods &&  paymentMethods.length === 0 ?
        <Link to="/wallet?addCard=true" className="btn btn-md btn-info btn-block">{infoSource.laguangeElakay('Button', infoSource.languageAPI).addCart}</Link>
            :  
                  products.length > 0 &&
                  checkIfObjectIsEmpty(addressShop)  && 
                   CheckActiveBtn() && (
                      <button type="button" onClick={() => setPayment(true)} className="btn btn-md btn-info btn-block mt-2">
                          {infoSource.laguangeElakay('Button', infoSource.languageAPI).buyNow}
                      </button>
                  )

        // if (!isAuthenticated()){
        //    return( <Link to="/signin">
        //         <button className="btn btn-md btn-info btn-block">Sign in to checkout</button>
        //     </Link>
        //     )
        // } else if (!Saddress.country){
        //     return (<Link to="/address" className="btn btn-md btn-info btn-block">Add address</Link>)
        // } else if (!paymentMethods) {
        //     return (
        //         <Link to="/wallet?addCard=true" className="btn btn-md btn-info btn-block"> Add Card </Link>
        //     )
        // }else{
        //     return (
        //         products.length > 0 &&
        //             quantity > 0 &&
        //                 quantity >= count && (
        //     <button type="button" onClick={() => setPayment(true)} className="btn btn-md btn-info btn-block mt-2">
        //         Buy now
        //     </button>
        //                 ))
        // }
    }


    return (
        <div>
            {PaymentDetay2()}
            {BSbutton()}
            {products.shipping}
            {PaymentSlider()}
            {redicectToFilters()}
            <StripeError
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                show={show}
                setShow={setShow} 
                listePM_createStripeCusTomer={listePM_createStripeCusTomer}
             />

            
        </div>
    );
};

export default Checkout;
