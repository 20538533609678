import React, { useState, useEffect } from "react";
import Loader from "../Layout/loader.gif";
import moment from "moment";
import {
    createAddressShipCarts
} from "../auth/shipping";


const ShippingCart = (props) => {
    const [laodShip, setLaodShip] = useState(true);
    const {
        shipAmout,
        SetShipAmount,
        token,
        products,
        Saddress,
        laguangeElakay,
        infoSource,
    } = props

   
    const [rates, setRates] = useState();

    const creatShippingAddd = () => {
        setLaodShip(true)
        !products.shipping && products.count && Saddress.country && (

            createAddressShipCarts(token, { products, Saddress }).then(data => {
                if (!data) {
                    console.log("bad connection")
                    setLaodShip(false)
                } else {
                    // setRates(data.rates)s
                    setRates(data) 
                    shipAmout.push(data.amount_local * products.count)
                    setLaodShip(false)
                }
            })
            )
        // products.shipping && (setLaodShip(false))
    };
const addShippAmount=(a)=>{
    SetShipAmount(a)
}

    useEffect(() => {
        addShippAmount(shipAmout)
        creatShippingAddd()
    }, [shipAmout]);

    const deliveryDay = (a) => {
        var today = new Date()
        var b = today.setDate(today.getDate() + a);;
        return b
    }

    const laadingGIf = () => (
        !products.shipping 
        ?
        <div className="mx-auto">
            <img src={Loader} width = "35px" className={`search-loading ${laodShip ? 'show' : 'hide'}`} alt="loader" />
        </div>
        :
        <div>
                <span>{infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping1}</span> <br /> {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping4}
        </div>
    )

    return (
        <>
        {
        laodShip
            ?
            laadingGIf() :
                    rates ?
        <div> 
            {moment(deliveryDay(rates.estimated_days)).format('LL')} - {moment(deliveryDay(rates.estimated_days + 5)).format('LL')} <br />
            {rates.estimated_days}-{rates.estimated_days + 5} {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping3}
            
        </div>
                  :
        <div>
            <span> {infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping2}</span> <br />{infoSource.laguangeElakay('text', infoSource.languageAPI).textShipping5}
        </div>
        }
        </>
    )

}

export default ShippingCart;