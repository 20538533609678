import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { BsGridFill } from "react-icons/bs";
import { laguangeElakay } from "../pageInside/dataElakay";
import { elakay_API } from "../../config";
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import { Row } from 'react-bootstrap';
import {GrView} from "react-icons/gr";
import Tabs from 'react-bootstrap/Tabs' 
import Tab from 'react-bootstrap/Tab'

import CardAllcategories from "../pageInside/cardAllcategories"

import "./allCategories.css"
let entylevel = {
    level_0: {nex:undefined, now:'level_0', pre:'level_1', level: 1, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_1: {nex:'level_0', now:'level_1', pre:'level_2', level: 2, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_2: {nex:'level_1', now:'level_2', pre:'level_3', level: 3, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_3: {nex:'level_2', now:'level_3', pre:'level_4', level: 4, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_4: {nex:'level_3', now:'level_4', pre:'level_5', level: 5, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_5: {nex:'level_4', now:'level_5', pre:'level_6', level: 6, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_6: {nex:'level_5', now:'level_6', pre:'level_7', level: 7, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_7: {nex:'level_6', now:'level_7', pre:'level_8', level: 8, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_8: {nex:'level_7', now:'level_8', pre:'level_9', level: 9, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_9: {nex:'level_8', now:'level_9', pre:'level_10', level: 10, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_10: {nex:'level_9', now:'level_10', pre:'level_11', level: 11, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
    level_11: {nex:'level_10', now:'level_11', pre: undefined, level: 12, color:"", ar:[], ar1:[], ar2:[], ar4:[]},
}

let levelarry = {
  level_0 : {ar:[], ar1:[], ar2:[]},
  level_1 : [],
  level_2 : {}

}
const AllCategories = (props) => {


const [loading0, setLoading] = useState(false); 
const [level, setLevel] = useState(levelarry)

const ButtonViewCategories  =(ar, subAr, id )=>{
    let y
    if(subAr.items.length === 0){  
         setLoading(false) 
    }else{
      setLoading(true)
        y =  checkRowLine(ar, id) 
        spliceArray(ar ,subAr, y, id)
    }
   

}

    const {
        infoSource
        } = props
        useEffect(() => {
            let w = document.getElementById("elakayRowl").clientWidth ;
            if(w){
                
            }
        }, [])

    // const spliceArray = (ar ,subAr, le, i )=>{

     
    //     var ar1, ar2;
      
    //     ar1 = ar.slice(0, i)
    //     ar2 = ar.slice(i)
    //     setLevel({
    //         ...level,    
    //         [le] : {...level[le], ar, ar1,  ar2, ar4: subAr.items}
    //     })

    // }

    const spliceArray = (ar ,subAr, i, id )=>{

     
        var ar1, ar2;
      
        ar1 = ar.slice(0, i)
        ar2 = ar.slice(i)
        setLevel({   
            "level_0" : { ar, ar1,  ar2},
            "level_1" : subAr.items,
            "level_2" : subAr.items[0].supCategoryID,
            "id" : id
        })

    }

 const checkRowLine = (ar, id)=>{
    let nxr;
    let w = document.getElementById("elakayRowl").clientWidth ;
  let i = ar.map(e => e.supCategoryID._id).indexOf(id) + 1;
    if (718 > w ){
        nxr = 2
    } else if(718 >= w){
        nxr = 3

    }else if (960 >= w){
        nxr = 3
    }else if (1140 >= w){
        nxr = 4
    }
    let l
   
    if(i/nxr  <= 1){
        l = 1
    }else if(i/nxr  <= 2){
        l = 2
    }else if(i/nxr <= 3){
        l = 3
    }else if(i/nxr <= 4){
        l = 4
    }else if(i/nxr <= 5){
        l = 5
    }else if(i/nxr <= 7){
        l = 7
    }else if(i/nxr <= 8){
        l = 8
    }else if(i/nxr <= 10){
        l = 9
    }else if(i/nxr <= 10){
        l = 10
    }else if(i/nxr <= 11){
        l = 11
    }

    return l*nxr

}

    const selectedCategories1 =(ar1)=>{

         return ar1 && ar1.map((m1,j) =>(
            <>
                            <div className="col p-" key={10+j}>
                                <div className="card">
                               
                                    <div className="card mx-auto"> 
                                    <Link to={`/product/${m1.supCategoryID._id}`}>
                                        {/* <span className="card-title" style={{ fontSize: "50px" }}> <BsGridFill /></span> */}
                                        <img
                                            className="rounded"
                                            src={`${elakay_API}/pubR/${m1.supCategoryID._id}`}
                                            onError={event => {
                                            event.target.src = imgProductLakay
                                            event.onerror = null
                                                        }}
                                        alt={m1.supCategoryID.name}
                                        style={{ height: "100%", width: "100%" }}
                    />
                                    </Link>
                                    </div>
                                    
                                    <div className="card-footer text-center p-1"><h5>
                                        {/* {m1.supCategoryID.name[0]} */}
                                        {infoSource.getCategoryNames(m1).name[0]}
                                        </h5></div>
                                </div>
                            </div>
    
                            </>
                        ))
         
     }
  
const selectedCategories =(ar)=>{

   return <div className="container-fluid" id='elakayRowl'>
    <Row xs={2} sm={2} md={3} lg={3} xl={4}>
    {ar && ar.map((m,i) =>(
        <>
                        <div className="col p-2" key={i}>
                            <div className="card">
                           
                                <div className="card mx-auto viewPage"> 
                                <Link to={`/product/${m.supCategoryID._id}`}>
                                    {/* <span className="card-title" style={{ fontSize: "50px" }}> <BsGridFill /></span> */}
                                  
                                    <span><h3>
                                        {/* View Page */}
                                        {infoSource.laguangeElakay('title', infoSource.languageAPI).viewPage}
                                        </h3></span>
                                    <img
                                        className="rounded"
                                        src={`${elakay_API}/pubR/${m.supCategoryID._id}`}
                                        onError={event => {
                                        event.target.src = imgProductLakay
                                        event.onerror = null
                                                    }}
                                    alt={m.supCategoryID.name[0]}
                                    style={{ height: "100%", width: "100%" }}
                />
                                </Link>
                                </div>
                                
                                <div className="card text-center p-1 viewCategories"> <span onClick={()=>ButtonViewCategories(ar, m.supCategoryID, m.supCategoryID._id )}><h5 className=""><GrView/></h5></span> <h5>
                                    {/* {m.supCategoryID.name[0]} */}
                                    {infoSource.getCategoryNames(m).name[0]}
                                    </h5></div>
                            </div>
                        </div>
                        </>
                    ))
                }
    </Row>
    </div>
}

        // let url = `${elakay_API}/pubR/${product.supCategoryID._id}`
    const menu = (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="container p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>elakay {infoSource.laguangeElakay('title', infoSource.languageAPI).menu}</h3>
                </div>
             {
                // level && level.level_0 && level.level_0.ar4 && 
                loading0
                ?
                <CardAllcategories
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                ButtonViewCategories={ButtonViewCategories}
                setLevel={setLevel}
                level ={level}
                />
               
:
             selectedCategories(infoSource.elakayCategories)
             }

            </div>
        </div>
    )



    return (
        <Layout
        infoSource ={infoSource}
            title="AllCategories Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            loading={false}
        >
            <div style={infoSource.styleNav}>
            {menu}
            </div>
        </Layout>
    );
};

export default AllCategories;