import React, { useState} from "react";
import imgProductLakay from '../../Layout/imgs/md3.jpg'
import './Gallery.css';

const copyToClipboard = (jsonData, func ) => {
 // Supprime les guillemets autour des valeurs (facultatif)
    
    navigator.clipboard.writeText(jsonData).then(() => {
      func()
    }).catch((err) => {
      console.error('Erreur lors de la copie : ', err);
    });
  };

const videoLoad = (a)=>{
    return   a && a.videoPath &&  <video width="100%" controls autoplay muted>
                <source src={a.videoPath}  alt={a.title} type="video/mp4" />
            </video>

}

const containerStyle = {
    display: "inline-block", // Permet au div de s'adapter à l'image
    position: "relative", // Si vous voulez ajouter du contenu par-dessus
  };

  const imageStyle = {
    display: "block", // Supprime les marges ou espaces automatiques autour de l'image
    maxWidth: "100%", // Pour s'assurer qu'elle s'adapte bien à l'écran (optionnel)
  };

  const overlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    backgroundColor: "rgba(0, 0, 255, 0.4)",
    padding: "1px",
    borderRadius: "20px",
  };


const imageLoad = (a, func)=>{
    return  a &&  a.imagePath && <div className="bg-image shadow-1-strong" style={containerStyle}>  
    <img
    className="embla__slide__img"
        // className="card-img-top"
        src={a.imagePath}
        onError={event => {
            event.target.src = imgProductLakay
            event.onerror = null
          }}
        alt={a.title}
        style={imageStyle}
    />
     <div className="card-body text-white text-center" style={overlayStyle}>
   <h5 className="card-title">{a.title}</h5>
   <a onClick={()=>copyToClipboard(a.imagePath, func)} className="btn btn   btn-outline-light btn-rounded btn-sm">copy url img</a>
 </div>
    </div>
}

const imageLoad1 = (a, func)=>{
    return  a &&  a.imagePath &&    <div
    className="bg-image card shadow-1-strong"
    style={{
     backgroundImage: `url(${a.imagePath})`,
     backgroundSize: 'cover', // Optionnel pour ajuster l'image
     backgroundPosition: 'center', // Optionnel pour centrer l'image
     display: "block", // Supprime les marges ou espaces automatiques autour de l'image
     width: "100%",

    //  height: '300px', // Optionnel pour donner une hauteur
   }}
    >
 <div className="card-body text-white">
   <h5 className="card-title">{a.title}</h5>
   <a onClick={()=>copyToClipboard(a.imagePath, func)} className="btn btn-outline-light">copy url img</a>
 </div>
</div>
}

const cardFilePage =(obj, func)=>{
    if(obj.VORI === "image"){

        return imageLoad(obj, func)

} else  if(obj.VORI === "video"){
    videoLoad(obj, func)
}
}

const AllFilePage =(props)=>{
    let ObjetPage = {
        title:'',
        image:'',
        video:'',
        thumbnail:''
    }
    
    const {
        classAlert,
        classText = 'col-12 col-md-9',
        classImg = 'col-12 col-md-10',
        classPara = 'col-lg-4 col-md-12 mb-4 mb-lg-0',
        paragra = ObjetPage,
        imageVideo = '',
        paragra0 = [],
        paraALLBoo = false,

    } = props 

    const [showAlert, setShowAlert] = useState(false);

    const triggerAlert = () => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000);
      };

    const alertCopy =()=>{
       return   showAlert && (
          <div className="alert alert-success" role="alert">
              The JSON has been successfully copied!
            </div>
          )}

  return  <div className="container my-5">
    {alertCopy()}
  <div className="row">
    {paraALLBoo?
    <div className={`column ${classImg}`}>
    {cardFilePage(paragra, triggerAlert)}
    </div>
   :
    // <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
    
    <> {paragra0.length > 0 && paragra0.map((ob, j) =>{
          return <div key={j} className={`${classPara}`}>
           {cardFilePage(ob, triggerAlert)}
           </div> 
        })}
        </>
   
    
    // </div>
 }
  </div>
</div>

}

export default  AllFilePage