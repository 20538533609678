import React, {useState} from "react";
import contries from "../../../countries.json";
// import useResponsiveFontSize from "../../useResponsiveFontSize";
import {
    useStripe,
    useElements, 
    CardElement
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import CardSection from './CardSection';

import {AttachPaymentMethod} from "../../auth/stripe"

export default function CardSetupForm({ 
    laguangeElakay,
    infoSource,
    address, 
    token, 
    getAddressById,
    setAddCards,
    Customercreate

}) {
    const [name, setName] = useState();
    const [addressA, setAddressA] = useState(false);
    const [loadingB, setLoadingB] = useState(true);  
    const [clientSecret, setClientSecret] = useState(); 

    const [Saddress, setAddress] = useState({
        city: "City",
        country: "",
        line1: "Address Line 1" ,
        postal_code: "zip or postal code",
        state: "state / province / region"
    });
    const {
        city,
        country,
        line1,
        postal_code,
        state
    } = Saddress;

    
    const selectAdress = () => event => {
        const IdAdress = event.target.value;
        addressId(token, IdAdress)
    }

    const addressId = (token, adressId) => {

        adressId && (
            getAddressById(token, adressId).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setAddress({
                        city: data[0].city,
                        country: data[0].country,
                        line1: data[0].Address1,
                        postal_code: data[0].postalCode,
                        state: data[0].State
                    })
                }
            }))
    }

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        setLoadingB(false)
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            // Include any additional collected billing details.
            name: name,
            address: Saddress
          },
        });
    
          AttachPaymentMethod(token, {PM_id: result.paymentMethod.id}).then(data => {        if (result.error) {

            console.log(data.error)
            setLoadingB(true)
            // Display result.error.message in your UI.
        } else {
           
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method()
            // to your server to save the
            // card to a Customer 
            setAddCards(false)
            setLoadingB(true)
             Customercreate(token)
        }

          })
       
    };


    const handleChange = name => event => {
        const value = event.target.value;
        setAddress({ ...Saddress, [name]: value });
    };

    const NameChange = () => event => {
        const value = event.target.value;
        setName(value);
    };


const selectAddress = ()=>(

    <div className="form-group">
        <label className="text-muted float-left">{infoSource.laguangeElakay('title', infoSource.languageAPI).billingAddress}</label>
        <select onChange={selectAdress()} className="form-control">
            <option value='0'>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
            {
                address.map((Adre, i) => (
                    <option key={i} value={Adre._id}>({Adre.firstName} {Adre.lastName}) {Adre.Address1}, {Adre.city} {Adre.State} {Adre.postalCode} {Adre.country}</option>
                ))
            }
        </select>
        <button onClick={() => setAddressA(true)} type="button" className="float-right btn btn-link mt-0">{infoSource.laguangeElakay('Link', infoSource.languageAPI).addOtherBillAddress}</button>
    </div>
)

const formAddress = () =>(
    <>
        <div className="form-group">
            <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).Address1}</label>
            <div className="controls">
                <input id="address-line1" onChange={handleChange('line1')} type="text" placeholder={line1} className="form-control" />
                <p className="help-block">{infoSource.laguangeElakay('title', infoSource.languageAPI).Address1}</p>
            </div>
        </div>
        <div className="form-group">
            <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).city}</label>
            <div className="controls">
                <input id="city" onChange={handleChange('city')} type="text" placeholder={city} className="form-control" />
                <p className="help-block"></p>
            </div>
        </div>
        <div className="form-group">
            <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).State}</label>
            <div className="controls">
                <input id="region" onChange={handleChange('state')} type="text" placeholder={state}
                    className="form-control" />
                <p className="help-block"></p>
            </div>
        </div>
        <div className="form-group">
            <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).zipCode}</label>
            <div className="controls">
                <input id="postal-code" onChange={handleChange('postal_code')} type="text" placeholder={postal_code}
                    className="form-control" />
                <p className="help-block"></p>
            </div>
        </div>
        <div className="form-group">
            <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).country}</label>
            <div className="controls">
                <select id="country" onChange={handleChange('country')} className="form-control" placeholder={country}>
                    <option value="Selected" >{infoSource.laguangeElakay('label', infoSource.languageAPI).selCountry}</option>
                    { contries.map((c,i)=> <option value={c.code}>{c.name}</option>) }              
                </select>
            </div>

            <button onClick={() => setAddressA(false)} type="button" className="float-right btn btn-link mt-0">{infoSource.laguangeElakay('Button', infoSource.languageAPI).selBillingAddress}</button>
        </div>
    </>
)

const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret:'seti_1KvttbF1003CDINHeEQ6aRRT_secret_LdAE9uEKS1zHQTY8kGeAzWbUwPT8aw4',
    appearance,
  };
    return (
        
        <form onSubmit={handleSubmit}>
              <div className="form-group ">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).fullName}</label>
                            <div className="controls">
                    <input 
                    onChange={NameChange()} 
                    type="text" id="full-name" 
                    placeholder={infoSource.laguangeElakay('label', infoSource.languageAPI).fullName}
                                    className="form-control" />
                                <p className="help-block"></p>
                            </div>
                </div>        
            {/* <label className="text-muted float-left"> Card details  </label> */}
            
            <div className="form-group">
            <div className="controls">
         
            <CardSection 
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
            />
             </div>
            </div>

            {
                addressA ? formAddress() : selectAddress()
            }

            <div className="form-group mt-4">

                {loadingB ?
                <button className="btn btn-outline-primary" type="submit" disabled={!stripe} >{infoSource.laguangeElakay('Button', infoSource.languageAPI).saveCard}</button>
                    :
                    <>
                        <span className="spinner-border spinner-border-sm mr-1" role="status"></span>
                        <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).loanding}</span>
                    </>
                }
                
            </div>
        </form>
    );
}
