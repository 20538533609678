import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import Filters from './FilterProduct'
import Price from "./productsPrice";
import Barcode0 from './Barcode0'
import { MdPrint } from "react-icons/md";
class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            myShop: props.MyShop,
            laguangeElakay:  props.laguangeElakay,
            infoSource: props.infoSource,
        };
    }

    

    render() {
        function calculateQuantities(product) {
            const quantities = product.filterProduct.reduce((total, item) => total + (item.quantity || 0), 0);
        
            return quantities
        }

        function calculateSales(product) {
            const sales = product.filterProduct.reduce((total, item) => total + (item.sold || 0), 0);
        
            return sales
        }
        function ListAllFilters(product) {
            return (
                <div>
                 
                        <div>
                            {product.filterProduct.map((item, itemIndex) => (
                                <ul key={itemIndex}
                                style={{
                                    backgroundColor: itemIndex % 2 === 0 ? '#d1e7f7' : '#e8e8e8',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    marginBottom: '10px',
                                    listStyleType: 'none', // Enlève les points des <li>
                                }}
                                >
                                    <li>Quantity: {item.quantity}</li>
                                    <li>Sold: {item.sold}</li>
                                    {Object.entries(item.filter || {}).map(([key, value]) => (
                                        <li key={key}>{key} : {value}</li>
                                    ))}
                                </ul>
                            ))}
                        </div>
                </div>
            );
        }

        const { order, laguangeElakay,  myShop, infoSource} = this.state
        const print = () => (

            <div style={{ width: "100%", height: "100%" }}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">
                                www.elakay.com
                                </th>
                            <th scope="col">

                            </th>
                            <th scope="col">

                            </th>
                            <th scope="col">
                              
                            </th>
                            <th scope="col"> <div className="m-1"> <Barcode0 code={'qrcode'} height={16} scale={1} includetext={true} canvas={`a${1}`} /></div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col">
                                # / Id
                                </th>
                            <th scope="col">
                            {infoSource.laguangeElakay('label', infoSource.languageAPI).name}
                                </th>
                            <th scope="col">
                            {infoSource.laguangeElakay('label', infoSource.languageAPI).quantity}
                                </th>
                            <th scope="col">
                            {infoSource.laguangeElakay('label', infoSource.languageAPI).sold}
                            </th>
                            <th scope="col">
                            {infoSource.laguangeElakay('label', infoSource.languageAPI).prix}
                            </th>
                            <th scope="col">
                            {infoSource.laguangeElakay('label', infoSource.languageAPI).details}
                                </th>
                        </tr>
                    </thead>
                    <tbody>
                        {myShop.map((o, i) => (
                            <tr key={i}>
                                <td>
                                    {i+1}
                                </td>
                                <td>
                                    {o.name}
                                </td>
                                <td>
                                    {calculateQuantities(o)}
                                </td>
                                <td>
                                    {calculateSales(o)}
                                </td>
                                <td>
                                    <Price
                                        products={o}
                                        showAddToCart={()=>(false)}
                                    />
                                </td>
                                <td>
                                    {ListAllFilters(o)}
                                    {/* < Filters categoryID={o.catalogID} filter={o.filter} /> */}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                </table>
            </div>

        )

        return (
            <div>
                {print()}
            </div>
        );
    }
}

const Example = props => {
    const { MyShop, laguangeElakay,  infoSource } = props
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const printPage = () => ( 
        <ComponentToPrint 
        laguangeElakay={laguangeElakay}
        infoSource ={infoSource}
        ref={componentRef}
         MyShop={MyShop} 
        />
    )

    return (
        
        <div className="container" style={{ minWidth: "800px" }}>
         
            <button className='my-3 py-1 btn btn-outline-primary float-right p-2 rounded-4' onClick={handlePrint}> <MdPrint className="h4"/> 
            {infoSource.laguangeElakay('Button', infoSource.languageAPI).printThisOut}
            </button>   
            {printPage()}
             </div>
    );
};

export default Example;