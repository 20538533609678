import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {isAuthenticated } from '../auth';
import { Link, useParams} from "react-router-dom";
import {FaRegTrashAlt} from "react-icons/fa";
import CheckboxFilter from "../pageInside/CheckBoxFilter";
import { getFilterName,  UnlinkFTC, linkFTC,  getCategoriesByID } from "../auth/apiCategory";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import "./category.css"


const CreatCategory = props => {
    const {
        infoSource,
        laguangeElakay
        } = props
    let params = useParams()
     const {token } = isAuthenticated();
    const [name, setName] = useState("");
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filterSel, setFilterSel] = useState([]);
    const [getCartItems, setgetCart] = useState([]); 
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [userInfo, setUserInfo] = useState([]); 
    const [message, setMessage] = useState(false);
    const catID = params.categoryId
    const [showDeleteAgree, setDeleteAgree] = useState(false);
    const [seletName, setSeletName] = useState('');
    const [chek, setChek] = useState(false);
    const handl = (a) => {
        setChek(a)
    }

    useEffect(() => { 
        loadFilters(token)
        loadFilterSel(catID, token)
    }, [catID, token])

const handleChange = event => {
    setName(event.target.value);
};

    const removeFilter = (catID, filID, token) => {
        UnlinkFTC(catID, filID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadFilterSel(catID, token)
                setDeleteAgree(false)
                setChek(false)
                checkSel(filID)
            }
        });

    };

    const handleToggle = (catID, filID, token, name) => () => {
        // return the first index or -1
        // deleteAgree(name, catID, filID, token) 
        linkFTC(catID, filID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
             if (data.Success){
               loadFilterSel(catID, token)
                }else{
                    SubmitB(name)
                } 
                checkSel(filID)
            }
        });    
    };

    const loadFilters = (token) => {
        getFilterName(token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setFilters(data);
                setLoading(false)

            }
        });
    };

    const loadFilterSel = (catID, token) => {
        getCategoriesByID(catID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCategories(data.cartegory)
                setFilterSel(data.filter);

            }
        });
    };

    const SubmitB = (name) => {
        setSeletName(name)
        setDeleteAgree(true)

    }
    const checkSel = (a) => {

        let c

        filterSel.forEach(Item => {
            if (a === Item._id._id) {
                c = true
            }
        })
        return c
    }

    const deleteAgree = (name, catID, filID, token) => {

        return (
            <div className="contaire">
                <div className="row justify-content-center " >
                    <Modal
                        show={showDeleteAgree}
                        onHide={() => setDeleteAgree(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h4 className="text-center">
                                    {/* delete this items */}
                                    {infoSource.language(infoSource, "title", "delThtems")}
                                    </h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row justify-content-center m-4">
                                <h5>
                                    {/* are you sure you want to delete this category name's  */}
                                    {infoSource.language(infoSource, "title", "surToDelete")}
                                    <span className="text-danger" > {name}</span> </h5>
                                <div className="list-unstyled">
                                    <input
                                        onChange={() => handl(chek ? false : true)}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={chek ? true : false}
                                        id="surToDelete"
                                    />
                                    <label className="form-check-label" for="surToDelete">
                                        {/* check the button if your are agree */}
                                        {infoSource.language(infoSource, "title", "chButAgree")}
                                        </label>
                                </div>
                                {
                                    chek ?
                                        <Button onClick={() => removeFilter(catID, filID, token)}>
                                           {infoSource.language(infoSource, 'Button', "submit")}
                                        </Button> :
                                        <button className="btn" > 
                                        {infoSource.laguangeElakay('Button', infoSource.languageAPI).submit}
                                        </button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }
  const CategoryFom = () =>(
      <div className="row mt-3">
   <div className="col col-12 col-md-6 col-lg-6">
              <CheckboxFilter 
              infoSource ={infoSource}
                  handleToggle={handleToggle}
                  filters={filters}
                  filterSel={filterSel}
                  catID={catID}
                  token={token}
                  checkSel={checkSel}
              />
          
     </div>
        <div className="col col-12 col-md-6 col-lg-6">
            
        <div className="boxCategory1" id="heading">
            <h3>
                {/* Filter for  */}
                {infoSource.language(infoSource, "title", "filterfor")}
                {Array.isArray(categories.name) && categories.name[0]} </h3>
              {showSuccess()}
              {showError()}
        </div>

      <div className="boxCategory">
        {filterSel.map((c, i)=>(
          <form  key={i} className="container" >
          <div className="itemCategory row">
          <div className="col col-lg-7">   
         <Link to={'/filter/'+categories._id+'/'+c._id._id}>
        <h5>
            {c._id.name}
       </h5> </Link>
         {/* its here  */}
            </div> 
            <div className="col col-lg-5">
                <span onClick={() => SubmitB(c._id.name)} className="dellCategory">
                    <FaRegTrashAlt />
                    </span>
        </div> {
             (c._id.name === seletName) && (
            deleteAgree(c._id.name, categories._id, c._id._id, token)
                  )}
      </div>
      <input className="inputCategory" type="hidden" name="listName" value=""></input>
                         
          </form>
      ))}
      </div>
            
      </div> 
   
      </div>

  );

  const createCategoryButton = () =>{
        return( 
            <Link to="/category" className="btn btn-outline-primary mt-2 mb-2">
                {/* View Elakay Category */}
                {infoSource.language(infoSource, "Button", "VieElaCat")}
            </Link>
    )
  }
    const showSuccess = () => {
        if (success) {
            return <div className="alert alert-info" style={{ display: message ? "" : "none" }}> {message} </div>;
        }
    };

    const showError = () => {
        if (error) {
            return <div className="alert alert-danger" style={{ display: error ? "" : "none" }}> {error} </div>;
        }
    };


return (
<Layout 
        infoSource ={infoSource}
        className="container blockEl"
        SliderPunShow={false}
        loading={loading}
  >
    <div style={infoSource.styleNav}>
  <div className="row">
            <div className="col-md-8 offset-md-2  blockEl" onBlur={() => setMessage("")}>
               {CategoryFom()}
            <div className="container">
               {createCategoryButton()}
            </div>
         </div>
</div></div>
  </Layout>
);

}; 

export default CreatCategory;