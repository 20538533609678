import React, { useState, useEffect } from "react";
import { Row } from 'react-bootstrap';
import { getPubCategories , getImages} from "../../auth/apiProduct";
import imgProductLakay from '../../Layout/imgs/md3.jpg' 

import ShowImage from "../../pageInside/ShowImage";

// import "../Card.css"


const CatPubProducts = (props) => {
  const {categoryID, userID} = props
  const [products, setProducts] = useState([]);


  const loadProductsBySell = (cid, uId) => {
    getPubCategories(cid, uId, 4, 'asc', "sold").then(data => {
      if(!data){
          console.log("data not found");
      }else{
            setProducts(data.products);
      } 
    });
};

useEffect(() => {
    loadProductsBySell(categoryID, userID);
}, [categoryID, userID]);

const imageShow =(p)=>{
let urlImg = p.galeries.length > 0 && p.galeries[0].images.length > 0 ? p.galeries[0].images[0] : p.galeriesID.galeries.length > 0 ? p.galeriesID.galeries[0].urlImg  : imgProductLakay
  return  <img  
    className="card-img-top"
    src={urlImg}
    onError={event => {
        event.target.src = imgProductLakay
        event.onerror = null
      }}
    alt={p.name}
    style={{ height: "100%", width: "100%" }}
/>
}
const productALL =()=>{
       
    return <Row className="m-1"  xs={2} >
         {
         products && products.map((product, i) => (
            <div className="col p-1">
        {imageShow(product)}
            </div>
             ))}
     </Row>
 }

 return productALL()
}
export default CatPubProducts;