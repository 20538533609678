import React, { useState, useEffect } from "react";
import { VoteStart } from './voteStartCount'
import {MdInsertComment, MdStar } from "react-icons/md";
import Table from 'react-bootstrap/Table';
import Vote, {ratingVote} from './vote'

const ViewVoteStarButton =(props)=>{

    const {
        infoSource,
        voteA,
        loadProductsView,
        productId,
        token,
        ButtonAddComments,
        ButtonBool = true,
        showComments,
        addComment, 
        setAddComment,
        setVoteNumSt,
        VoteNumSt
    } = props

    const [CoUsSt, setCoUsSt] = useState(false);
    let voteRating = voteA && ratingVote(voteA)

    let st5 = `${VoteStart(voteA, 5)}`
    let st4 = `${VoteStart(voteA, 4)}`
    let st3 = `${VoteStart(voteA, 3)}`
    let st2 = `${VoteStart(voteA, 2)}`
    let st1 = `${VoteStart(voteA, 1)}`

    const ButtonStcchoose = (a, b, N) => {
        if(a > 0){
        if (VoteNumSt === N) {
            setCoUsSt(false)
            setVoteNumSt()
        } else {
            setCoUsSt(b)
            setVoteNumSt(N)
        }
    }
    }

    const tableProgressVote = (
        <div>
            <Table style={{ borderStyle: "none" }}>
                <tbody>
                    <tr>
                        <td style={{ width: "90px", padding: "0px" }}>5 <span><MdStar /></span></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st5, true, 5))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st5}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{parseInt(st5)}%</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "90px", padding: "0px" }}>4 <MdStar /></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st4, true, 4))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st4}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{parseInt(st4)}%</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "40px", padding: "0px" }}>3 <MdStar /></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st3, true, 3))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st3}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{parseInt(st3)}%</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "15%", padding: "0px" }}>2 <MdStar /></td>
                        <td style={{ width: "80%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st2, true, 2))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st2}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{parseInt(st2)}%</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "40px", padding: "0px" }}>1 <MdStar /></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st1, true, 1))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st1}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{parseInt(st1)}%</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )

    const progresseVote = (
        <div style={{ textAlign: "center" }}>
            <span style={{ fontSize: "50px" }}>{parseFloat(voteRating ? voteRating : 0).toFixed(1)}</span>

            <div style={{ width: "100%", fontSize: "30px", marginBottom: "0px" }}>
                {
                    voteRating ?
                        <Vote
                            loadProductsView={loadProductsView}
                            productID={productId}
                            token={token}
                            rating={voteRating}
                        /> : ""}
                {!voteRating && (
                    <Vote
                        loadProductsView={loadProductsView}
                        productID={productId}
                        token={token}
                        rating={0}
                    />
                )}
            </div>
            <p style={{ fontSize: "12px", padding: "0", marginTop: "0px" }}>{voteA && voteA.length> 0 ? voteA.length: 0} users</p>
        </div>
    )


    return (
        <div className="row justify-content-center " >
        <div className="col col-12">

<div className="row" >
    <div className="col col-12 col-md-6 col-lg-4" >
        {progresseVote}
    </div>
    <div className="col col-12 col-md-6 col-lg-4 mt-3">
        {tableProgressVote}
    </div>
    {ButtonBool &&
    <div className="col col-12 col-md-12 col-lg-4 mt-4">
        
       { !showComments ?
        <button type="button" className="btn btn-md btn-info btn-block p-1 rounded-4" onClick={ButtonAddComments}>{infoSource.language(infoSource, "title", "viewComment")}</button>
        :
        !addComment?
        <button type="button" className="btn btn-md btn-info btn-block p-1 rounded-4" onClick={()=>setAddComment(true)}><span style={{ marginRight: "9px", fontSize: "20px" }}><MdInsertComment /></span>{infoSource.language(infoSource, "title", "addComment")}</button>
        :
        <button type="button" className="btn btn-md btn-info btn-block p-1 rounded-4" onClick={()=>setAddComment(false)}>{infoSource.language(infoSource, "title", "viewAllComment")}</button>
    }
    </div>
    }
    </div>
</div>  
</div>
    );

}

export default ViewVoteStarButton;