// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Demo__container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 30px);
    max-width: 230px;
    margin: 0 auto;
  }
  
  .Demo__some-network {
    text-align: center;
  }
  
  .Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    font-size: 14px;
  }
  
  .Demo__some-network__share-button {
    cursor: pointer;
  }
  
  .Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  .Share_circle {
    width: 32px;
    height: 32px;
    /* line-height: px; */
    text-align: center;
    justify-content: center;
    padding: 0;
    font-size: 19px;
    border-radius: 50%;
    color: white;
    background: rgb(0, 60, 128);
}  `, "",{"version":3,"sources":["webpack://./src/includes/pageInside/sharePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,8CAA8C;IAC9C,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,QAAQ;IACR,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;EACA;IACE,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,uBAAuB;IACvB,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,2BAA2B;AAC/B","sourcesContent":[".Demo__container {\n    display: grid;\n    grid-gap: 20px;\n    grid-template-columns: repeat(auto-fill, 30px);\n    max-width: 230px;\n    margin: 0 auto;\n  }\n  \n  .Demo__some-network {\n    text-align: center;\n  }\n  \n  .Demo__some-network__share-count {\n    display: inline-flex;\n    justify-content: center;\n    white-space: nowrap;\n    overflow: visible;\n    width: 0;\n    font-size: 14px;\n  }\n  \n  .Demo__some-network__share-button {\n    cursor: pointer;\n  }\n  \n  .Demo__some-network__share-button:hover:not(:active) {\n    opacity: 0.75;\n  }\n  .Share_circle {\n    width: 32px;\n    height: 32px;\n    /* line-height: px; */\n    text-align: center;\n    justify-content: center;\n    padding: 0;\n    font-size: 19px;\n    border-radius: 50%;\n    color: white;\n    background: rgb(0, 60, 128);\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
