import React, { useState, useEffect } from "react";
import { FcAlphabeticalSortingAz, FcAlphabeticalSortingZa } from "react-icons/fc";
const FilterUserTeam = props =>{
    const { 
        infoSource,
        getFilterOurTeam,
        setFilterOurTeam,
        loadAllMyUserRef,
        filterlist
    }=props

    let userListe = [{value : 'null', name:'all'}, {value : 'customer', name:'customer'}, {value :'seller', name:'seller'}, {value :'provider', name:'provider'}, {value : 'associate', name:'associate'}, {value : 'investment', name:'investment'}, {value :'administrator', name:'administrator'}, {value :'family', name:'family'}]
    let sortByliste = [{value : 'createdAt', name:'by created'} , {value : 'updatedAt', name:'by upadete'}, {value : 'firstname', name:'first name'}, {value : 'lastname', name:'last name'}, {value : 'email', name:'e-mail'}]
    let status = [{value: "none", name: 'none'}, {value: "Not processed", name: 'notProcessed'}, {value: "Processing", name:'processing'},{value: "Shipped", name: 'shipped'}, {value: "Delivered", name:'delivered'}, {value: "Cancelled", name:'cancelled'}] 
    // let statusName = ["Not processed", "Processing", "Shipped", "Delivered", "Cancelled"] 

    let date = ['days', 'months', 'years']
    const [userType, setOrderStatus] = useState("filtOrders.userType");
    const [orderDate, setOrderDate] = useState("filtOrders.orderDate");
    const [myd, setmyd] = useState("filtOrders.myd");
    const [nlimit, setNlimit] = useState("limit");
    const styles = {
        container: {
          display: "flex",
          gap: "10px",
          margin: "20px",
        },
        base: {
          cursor: "pointer",
          padding: "5px 15px",
          borderRadius: "5px",
          fontWeight: "bold",
          transition: "background-color 0.3s, color 0.3s",
        },
        active: {
          backgroundColor: "#F5F5F5", // Light red
          border: "4px solid #D3D3D3",
          color: "#d32f2f", // Darker red
        },
        unActive: {
          backgroundColor: "#DCDCDC", // Dark red
          color: "white",
        },
      };


const handleFilterTeam = name => event =>{
    setFilterOurTeam({...getFilterOurTeam, [name]: event.target.value})
}

const handleFilterTeamB  = name =>  event =>{
    setFilterOurTeam({...getFilterOurTeam, [name]: JSON.parse(event.target.value)})
}


const btnfilter = ()=>{
    loadAllMyUserRef(getFilterOurTeam)
}

const btnClrfilter = ()=>{
    setFilterOurTeam(filterlist)
    loadAllMyUserRef(filterlist)
}
    const statusForm = ()=>{
        return<div className="my-4"> 
           <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold 
             fw-bold">
                {/* Status */}
                {/* {infoSource.laguangeElakay('title', infoSource.languageAPI).status} */}
                {infoSource.language(infoSource, "title", "GrByStatus")}
                {/* user type */}
                </h5></div>
            {userListe.map((p, i) => (
            <h5 key={i}>
                <input
                    onChange={handleFilterTeam('roleName')}
                    value={p.value}
                    name={'userType'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    checked={p.value == getFilterOurTeam.roleName ? 'checked' : ''}
                    id={p.name}
                />
                <label className="form-check-label" for={p.name}>{p.name}</label>
            </h5>
        ))}
        </div>
    }
    
    const sortByForm = ()=>{
        return<div className="my-4"> 
          <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}>  <h5 className="my-0 mx-1  p-1 fw-bold fw-bold">
            {/* {infoSource.laguangeElakay('title', infoSource.languageAPI).filterdate} */}
            {/* sort by  */}
            {infoSource.language(infoSource, "title", "sortBy")}
            </h5></div>
            {sortByliste.map((p, i) => (
            <h5 key={i}>
                <input
                   onChange={handleFilterTeam('sortBy')}
                    value={p.value}
                    name={'sortBy'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    checked={p.value == getFilterOurTeam.sortBy ? 'checked' : ''}
                    id={p.name}
                />
                <label className="form-check-label" for={p.name}>{p.name}</label>
            </h5>
        ))}
        </div>
    }
    
    const orderBypageselect = ()=>{
    const pageOrder = []
    const startNumber = 10
    const endNumber = 300;

    for (let number = startNumber; number <= endNumber; number += 10) {
        pageOrder.push(number);
    }
      return <div className="my-4"> 
     <div className="m-0 p-1 mb-4" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold fw-bold">
         {/* Number order by page */}
         {infoSource.laguangeElakay('title', infoSource.languageAPI).orderPage}
         </h5></div>
      <select value={getFilterOurTeam.limit} onChange={handleFilterTeam('limit')} className="w-50 m-4 form-select">
      <option>
        {/* select number order by page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selOrderPage}
        </option>
      {pageOrder.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}


const ASC_DESC_Form =()=>{
    
    return<> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
       {infoSource.language(infoSource, "title", "descAsce")}      
{/* Descending & Ascending */}
        </h5></div>
        <h3 className="m-4">
        <span   style={{
          ...styles.base,
          ...styles.unActive,
          ...(getFilterOurTeam.asc === "asc" ? styles.active : {}),
        }}
         onClick={()=>setFilterOurTeam({...getFilterOurTeam, asc: 'asc'})}>
        <FcAlphabeticalSortingAz />
        </span>
        &#160;&#160;&#160;&#160;&#160;
        <span
          style={{
            ...styles.base,
            ...styles.unActive,
            ...(getFilterOurTeam.asc === "desc" ? styles.active : {}),
          }}
         onClick={()=>setFilterOurTeam({...getFilterOurTeam, asc: 'desc'})}>
        <FcAlphabeticalSortingZa />
        </span>
        </h3>
    </>
    }

const myUserForm =()=>{
    
    return<> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* My user addon */}
        {infoSource.language(infoSource, "title", "useraddon")}
        </h5></div>
    <label className="switch">
      <input 
      onChange={handleFilterTeamB('myUser')} 
      value={!getFilterOurTeam.myUser}
      type="checkbox" 
      checked={getFilterOurTeam.myUser ? 'checked' : ''}
      />
      <span className="slider round"></span>
    </label>
    </>
    }

    const userGroupeByStatus =()=>{
    
        return<> 
           <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
            {/* Groupe user By status */}
            {infoSource.language(infoSource, "title", "GrByStatus")}
            </h5></div>
        <label className="switch">
          <input 
          onChange={handleFilterTeamB('grpByrole')} 
          value={!getFilterOurTeam.grpByrole}
          type="checkbox" 
          checked={getFilterOurTeam.grpByrole ? 'checked' : ''}
          />
          <span className="slider round"></span>
        </label>
        </>
        }


    const btnForm = ()=>{
        return <div className="my-4"> 
                <button className="btn btn-dark rounded-3" onClick={btnClrfilter}>
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).clearfilter}
                </button>
                <button className="btn btn-dark rounded-3" onClick={btnfilter}>
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).setfilter}
                </button>

               
        </div>
    }
    return ( 
        <>
        {orderBypageselect()}
        {statusForm()}
        {sortByForm()}
        {ASC_DESC_Form()}
        {myUserForm()}
        {userGroupeByStatus()}
        {btnForm()} 
         </> 
        )
}
export default FilterUserTeam;