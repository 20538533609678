import React  from 'react';

export const tableBody =(a, es)=>{
    return <td style={es}>{a}</td>
}
export const tableHead =(a, es)=>{
    return <th style={es}>{a}</th>
}

export const tableRow =(a=[], ec, es)=>{
    return <tr className={ec} style={es}>{a.map((c,i)=>c)}</tr>
}
const TableElakay =(props) => {
    const{
        children,
        eClass,
        eStyle  
    } = props

    return <table className={eClass} style={eStyle}><tbody>{children}</tbody></table>
  
}

export default TableElakay