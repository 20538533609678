import React, { useState, useEffect } from "react";
import { FcAlphabeticalSortingAz, FcAlphabeticalSortingZa } from "react-icons/fc";

import DealToDay from "../pages/dealtoday";

function processData(data) {
    // Obtenez une liste plate avec tous les noms
    const allNames = data.flatMap(item => item.name);
    const nameCounts = allNames.reduce((acc, name) => {
        acc[name] = (acc[name] || 0) + 1;
        return acc;
    }, {});

    return data.map(item => {
        // Filtrer les noms qui ne sont pas communs à tous les tableaux
        const filteredNames = item.name.filter(name => nameCounts[name] < data.length);

        // Trouver un groupe de genre (comme "Men", "Women", "Boys")
        const genderGroup = filteredNames.find(name =>
            ["Men", "Women", "Boys", 'Girls', 'Babies'].includes(name.trim())
        );

        // Construire "cateryName" avec l'élément principal et le groupe de genre
        const categoryNameParts = [genderGroup, item.name[0]].filter(Boolean);
        const categoryName = categoryNameParts.join(" ").trim();

        return {
            ...item,
            cateryName: categoryName
        };
    });
}

const FilterUserProduct = props =>{
    const { 
        infoSource,
        getCategoriesUser,
        limit, 
        setLimit,
        setSowFilterOurTeam,
        getFilterUserProd,
        setFilterUserProd,
        infoFil,
        loadProductsByArrival,
        filtOrders = [],
        handleFilters,
    }=props

    // let status = [{value: "none", name: 'none'}, {value: "Not processed", name: 'notProcessed'}, {value: "Processing", name:'processing'},{value: "Shipped", name: 'shipped'}, {value: "Delivered", name:'delivered'}, {value: "Cancelled", name:'cancelled'}] 
    // let statusName = ["Not processed", "Processing", "Shipped", "Delivered", "Cancelled"] 
    let sortByliste = [{value : 'createdAt', name:'by created'} , {value : 'updatedAt', name:'by upadete'}, {value : 'name', name:'by name'}, {value : 'filterProduct.sold', name:'by sold'}, {value : 'filterProduct.quantity', name:'by quantity'}]
    let date = ['days', 'months', 'years']
    const [checked, setChecked] = useState([]);
    const [getCatUsser, setCatUsser] = useState(filtOrders.orderStatus);
    const [orderDate, setOrderDate] = useState(filtOrders.orderDate);
    const [myd, setmyd] = useState(filtOrders.myd);

    const styles = {
        container: {
          display: "flex",
          gap: "10px",
          margin: "20px",
        },
        base: {
          cursor: "pointer",
          padding: "5px 15px",
          borderRadius: "5px",
          fontWeight: "bold",
          transition: "background-color 0.3s, color 0.3s",
        },
        active: {
          backgroundColor: "#F5F5F5", // Light red
          border: "4px solid #D3D3D3",
          color: "#d32f2f", // Darker red
        },
        unActive: {
          backgroundColor: "#DCDCDC", // Dark red
          color: "white",
        },
      };


      const handleToggle = (c, d, v) => () => {
        let existingIndex;
        let updatedValues;
    
        // Find the index of the filter group by name (d)
        checked.forEach((filter, index) => {
          if (filter.name === d) {
            existingIndex = index;
          }
        });
    
        // Clone the checked array to avoid mutating state directly
        const newChecked = [...checked];
    
        if (existingIndex !== undefined) {
          // Check if the value (c) exists in the selected filter group
          const valueIndex = newChecked[existingIndex].value.indexOf(c);
          if (valueIndex === -1) {
            // Add value to the filter group if not present
            newChecked[existingIndex].value.push(c);
          } else {
            // Remove value if it already exists
            newChecked[existingIndex].value.splice(valueIndex, 1);
          }
          updatedValues = newChecked[existingIndex].value;
        } else {
          // If the filter group doesn't exist, add it
          newChecked.push({ name: d, value: [c] });
          updatedValues = newChecked[newChecked.length - 1].value;
        }
    
        // Update state and trigger external function
        setChecked(newChecked);
        handleFilters(updatedValues, v);
      };

const handlechange = event =>{
    setmyd(event.target.value)
}


const handleDate = event =>{
    let value = event.target.value
    if(value === "days"){
        setmyd(15)
    }else if(value === "months"){
        setmyd(6)
    } else if(value === "years"){
        setmyd(2024)
    }
    setOrderDate(value)
}
const btnfilter = ()=>{
    loadProductsByArrival(infoFil.token, infoFil.DealProduct, getFilterUserProd)
    setSowFilterOurTeam(false)
    // setFiltOrders({orderStatus, orderDate, myd})
}

const handleFilterTeam = name => event =>{
    setFilterUserProd({...getFilterUserProd, [name]: event.target.value})
}
const btnClrfilter = ()=>{
    setFilterUserProd({
            asc: 'asc',
            limit : 10,
            sortBy: 'createdAt',
            search: ''
          })
    setSowFilterOurTeam(false)
   
}
    const statusForm = ()=>{
        return<div className="my-4"> 
           <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold 
             fw-bold">
                {/* Status */}
                {infoSource.laguangeElakay('title', infoSource.languageAPI).status}
                </h5></div>
            {filtOrders.map((p, i) => (
            <h5 key={i}>
                <input
                    // onChange={()=>setOrderStatus(p.value)}
                    // value={`${p.value}`}
                    name={'status'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    // checked={p.value == orderStatus ? 'checked' : ''}
                    in={"status_" + p.name}
                />
                <label className="form-check-label" for={"status_" + p.name}>{infoSource.laguangeElakay('label', infoSource.languageAPI)[p.name]}</label>
            </h5>
        ))}
        </div>
    }
    
    const dateForm = ()=>{
        return<div className="my-4"> 
          <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}>  <h5 className="my-0 mx-1  p-1 fw-bold fw-bold">
            {/* filter date */}
            {infoSource.laguangeElakay('title', infoSource.languageAPI).filterdate}
            </h5></div>
            {date.map((p, i) => (
            <h5 key={i}>
                <input
                    onChange={handleDate}
                    value={`${p}`}
                    name={'date'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    checked={p == orderDate ? 'checked' : ''}
                    id={"date_" + p}
                />
                <label className="form-check-label" for={"date_" + p}>{p}</label>
            </h5>
        ))}
        </div>
    }
    
    const orderBypageselect = ()=>{
    const pageOrder = []
    const startNumber = 10
    const endNumber = 100;

    for (let number = startNumber; number <= endNumber; number += 10) {
        pageOrder.push(number);
    }
      return <div className="my-4"> 
     <div className="m-0 p-1 mb-4" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold fw-bold"> 
        {/* Number order by page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).orderPage}
        </h5></div>
      <select value={getFilterUserProd.limit}   onChange={handleFilterTeam('limit')} className="w-50 m-4 form-select">
      <option>
        {/* select number order by page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selOrderPage}
        </option>
      {pageOrder.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}


const checInputFil =(a, b)=>{
    return a && a.map((c, j) => (
        <li key={j} className="list-unstyled" style={{margin:'15px', padding:'15px', borderBottom: "1px solid #258ecd", }}>
             <input
             onChange={handleToggle(c._id, 'categories', "categoriesID.gategoriesLink")}
             type="checkbox"
              id={c._id}
             className="form-check-input check2"
             />
     <label className="form-check-label ml-2" for={c._id}>{c.cateryName}</label>
     </li>
     ))
 }

const daySelect = ()=>{
    const allDays = []
    const startDay = 1
    const endDay = 30;

    for (let day = startDay; day <= endDay; day++) {
        allDays.push(day);
    }
      return <div className="my-4"> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* filter by Days */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).filterDays}
        </h5></div>
      <select value={myd} onChange={handlechange} className="w-50 m-4 form-select">
      <option>
        {/* select the days */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selectdays}
        </option>
      {allDays.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}
const sortByForm = ()=>{
    return<div className="my-4"> 
      <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}>  <h5 className="my-0 mx-1  p-1 fw-bold fw-bold">
        {/* {infoSource.laguangeElakay('title', infoSource.languageAPI).filterdate} */}
        {/* sort by  */}
        {infoSource.language(infoSource, "title", "sortBy")}
        </h5></div>
        {sortByliste.map((p, i) => (
        <h5 key={i}>
            <input
               onChange={handleFilterTeam('sortBy')}
                value={p.value}
                name={'sortBy'}
                type="radio"
                className="form-check-input mr-2 ml-4 check2"
                checked={p.value == getFilterUserProd.sortBy ? 'checked' : ''}
                id={p.name}
            />
            <label className="form-check-label" for={p.name}>{p.name}</label>
        </h5>
    ))}
    </div>
}

const ASC_DESC_Form =()=>{
    
    return<> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
       {infoSource.language(infoSource, "title", "descAsce")}
{/* Descending & Ascending */}
        </h5></div>
        <h3 className="m-4">
        <span   style={{
          ...styles.base,
          ...styles.unActive,
          ...(getFilterUserProd.asc === "asc" ? styles.active : {}),
        }}
         onClick={()=>setFilterUserProd({...getFilterUserProd, asc: 'asc'})}>
        <FcAlphabeticalSortingAz />
        </span>
        &#160;&#160;&#160;&#160;&#160;
        <span
          style={{
            ...styles.base,
            ...styles.unActive,
            ...(getFilterUserProd.asc === "desc" ? styles.active : {}),
          }}
         onClick={()=>setFilterUserProd({...getFilterUserProd, asc: 'desc'})}>
        <FcAlphabeticalSortingZa />
        </span>
        </h3>
    </>
    }

const monthsSelect = ()=>{
    const allMonths = []
    const startMonth = 1
    const endMonth = 12;

    for (let month = startMonth; month <= endMonth; month++) {
        allMonths.push(month);
    }
      return <div className="my-4"> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* filter by months */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).filterMonths}
        </h5></div>
      <select value={myd} onChange={handlechange} className="w-50 m-4 form-select">
      <option>
        {/* select the month */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selectmonth}
        </option>
      {allMonths.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}

const yearsSelect = ()=>{
   const allyears = []
    var currentTime = new Date()
    var curYear = currentTime.getFullYear()
    const startYear = curYear - 6
    const endYear = curYear;

    for (let year = endYear; year >= startYear; year--) {
        allyears.push(year);
    }
      return <div className="my-4"> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* filter by years */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).filteryears}
        </h5></div>
      <select value={myd} onChange={handlechange} className="w-50 m-4 form-select">
      <option>
        {/* select the year */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selectyear}
        </option>
      {allyears.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}
    const btnForm = ()=>{
        return<div className="my-4"> 
                <button className="btn btn-dark rounded-3" onClick={btnClrfilter}>
                    {/* clear filter */}
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).filterDays}
                </button>
                <button className="btn btn-dark rounded-3" onClick={btnfilter}>
                    {/* set filter */}
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).selectdays}
                </button>

               
        </div>
    }
    return ( 
        <>
        {orderBypageselect()}
        <>
        <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold 
             fw-bold">
              {infoSource.language(infoSource, "title", "shopCategories")}
               {/* shop Categories */}
                {/* {infoSource.laguangeElakay('title', infoSource.languageAPI).categoriesPage} */}
                </h5></div>
        <ul>
        {checInputFil(processData(getCategoriesUser))}
        </ul>
        </>
        {/* {statusForm()} */}
        {sortByForm()}
        {/* {dateForm()}
        {orderDate === 'days' && daySelect()}
        {orderDate === 'months' && monthsSelect()}
        {orderDate === 'years' && yearsSelect()}  */}
        {ASC_DESC_Form()}
        {btnForm()}
         </> 
        )
}
export default FilterUserProduct;