import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import { Row } from 'react-bootstrap';
import { getLakayUser , getImages} from "../../auth/apiProduct";
import imgProductLakay from '../../Layout/imgs/md3.jpg' 

import ShowImage from "../../pageInside/ShowImage";

// import "../Card.css"


const UserProdFollow = (props) => {
  const {userID, infoSource} = props
  const [products, setProducts] = useState([]);
  //  const [getOwner, setOwner] = useState([]);
   const [shopName, setShopName] = useState('')


  const loadProductsBySell = (uId) => {
    getLakayUser(uId, 0, 4, "sold", null).then(data => {
      if(!data){
          console.log("data not found");
      }else{
            setProducts(data.products);
            // setOwner(data.owner)
            setShopName(data.owner.shopName && Array.isArray(data.owner.shopName) ? data.owner.shopName[0]: data.owner.shopName)
      } 
    });
};

useEffect(() => {
    loadProductsBySell(userID);
}, [userID]);

const imageShow =(p)=>{
let urlImg = p.galeries.length > 0 && p.galeries[0].images.length > 0 ? p.galeries[0].images[0] : p.galeriesID.galeries.length > 0 ? p.galeriesID.galeries[0].urlImg[0]  : imgProductLakay
  return  <img  
    className="card-img-top"
    src={urlImg}
    onError={event => {
        event.target.src = imgProductLakay
        event.onerror = null
      }}
    alt={p.name}
    style={{ height: "100%", width: "100%" }}
/>
}
const productALL =()=>{
       
    return<div className='card'  style={{ textAlign: 'left'}}>
      <div className="ps-2" style={{width: "100%", backgroundColor: '#DCDCDC', fontSize:'80%'}}>
      Lakay {shopName} <Link to={`/lakay-ShopSeller/${userID}`}> {infoSource.language(infoSource, "Link", "seeMore")}</Link>
      </div>
     <Row className="m-1"  xs={2} >
         {
         products && products.map((product, i) => (
            <div className="col p-1">
        {imageShow(product)}
            </div>
             ))}
                 
     </Row>

     {/* <h6 className='ps-3'> <Link to={`/lakay-ShopSeller/${userID}`}>See More</Link></h6> */}
     </div>
 }

 return productALL()
}
export default UserProdFollow;