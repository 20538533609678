import React, { useState, useEffect } from "react";
import { Row } from 'react-bootstrap';
import { getCategoriesByID, DeletePub } from "../auth/apiCategory";
import { isAuthenticated } from '../auth';
import imgLakay from '../Layout/imgs/Untitled-2.jpg';
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import PuB1500 from './Pub1500X500'
import PuB500 from './PubR400x400'
import PuB400 from './PubC400X400'
import { elakay_API } from "../../config";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";

// import { Family, Adminstrator } from "../pageInside/Rolle"
const categoyseleted = [];
const categoyIDseleted = [];
const PubProducts = props => {
    const {
        laguangeElakay,
        infoSource
        } = props
    const [categories, setCategories] = useState([])
    const [getpub, setPub] = useState([]);
    const [getpubC, setPubC] = useState([]);
    const [getpubR, setPubR] = useState([]);
    // const [loading, setLoading] = useState(true);
    const { token } = isAuthenticated();
    const [pubShowNav, setPubShowNav] = useState({
        showPuB1500X400FT: false,
        showPuB500X500FT:false,
        showPuB400X400FT:false
    })

    const {
        showPuB1500X400FT,
        showPuB500X500FT,
        showPuB400X400FT
    } = pubShowNav

    
    let elakayCategoryID = categories._id ? categories._id: '5ed4946fd2ba8812c20261a8'

    const loadCategory = elakayCategoryID => {
        
        getCategoriesByID(elakayCategoryID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCategories(data.cartegory)
                setPub(data.pub)
                setPubR(data.pub_R)
                setPubC(data.pub_C)
                // setLoading(false)

            }
        });
    };

    useEffect(() => {
        loadCategory(elakayCategoryID)
    }, [elakayCategoryID]);

    const seleteCategory = () => event => {
        const supcategory = event.target.value;
        categoyseleted.push(categories.name)
        categoyIDseleted.push(categories._id)
        loadCategory(supcategory)
    }

    const clickBack =(LA)=>{
        loadCategory(LA)
        categoyIDseleted.pop()
        categoyseleted.pop()
    }

    const ButtonBackCategory = () => {
        let last_le = categoyIDseleted[categoyIDseleted.length - 1]

        return (
            <span onClick={() => clickBack(last_le) } className="h5 float-left">
                <FaLongArrowAltLeft />
            </span>
        )
    }

    const selectProduct = () => (
        <div className="container">
        <form className="mb-3">
            <div className="form-group">
                <div className="boxCategory1 text-center " id="heading">
                        <h2> {ButtonBackCategory()} {categories.name} </h2>
                        {categoyseleted.map((l, j) => (<p className="d-inline" key={j}>{l}{" / "}</p>))}
                </div>
                <select value={0} onChange={seleteCategory()} className="form-control">
                    <option>{infoSource.laguangeElakay('label', infoSource.languageAPI).selectProdSell}</option>
                    {categories.items && categories.items.map((c, i) => (
                        <option key={i} value={c.supCategoryID._id}>
                            {/* {c.supCategoryID.name} */}
                            {infoSource.getCategoryNames(c).name}
                        </option>
                    ))}
                </select>
            </div>
           
        </form> 
        </div>
    )

    const ButtonPuB1500X400 = () => {
        setPubShowNav({
            ...pubShowNav,
            showPuB1500X400FT: true,
            showPuB500X500FT: false,
            showPuB400X400FT: false,
            shooseTypeOfPubFT: false,
        })
    };

    const ButtonPuB500X500 = () => {
        setPubShowNav({
            ...pubShowNav,
            showPuB1500X400FT: false,
            showPuB500X500FT: true,
            showPuB400X400FT: false,
            shooseTypeOfPubFT: false,
        })
    };
    const ButtonPuB400X400 = () => {
        setPubShowNav({
            ...pubShowNav,
            showPuB1500X400FT: false,
            showPuB500X500FT: false,
            showPuB400X400FT: true,
            shooseTypeOfPubFT: false,
        })
    };

    const ButtonCancel = () => {
        setPubShowNav({
            ...pubShowNav,
            showPuB1500X400FT: false,
            showPuB500X500FT: false,
            showPuB400X400FT: false,
            shooseTypeOfPubFT: true,
        })
    };



  const shoosePuB = ()=>(
      <div className="container">

        <Row xs={1} sm={2} md={3} lg={3} xl={3}>
            <div>
                  <div className="m-4">
                      <div className="mb-3" style={{ height: "100px", maxWidth:"280px"}}>
                  <img
                      className="rounded"
                      src={imgLakay}
                      alt={"imgage Lakay"}
                      style={{ width: "100%" }}
                  /> 
                 </div>
                      <button className="btn btn-primary float-left pt-2 pb-2 pr-2 pl-2" onClick={ButtonPuB1500X400}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).creatPub}</button>
</div>
            </div>
            <div>
              <div className="m-4">
                      <div className="mb-3" style={{ height: "117px", width: "117px"}}>
                 <img
                      className="rounded"
                      src={imgProductLakay}
                      alt={"imgage Lakay"}
                      style={{ height: "100%", width: "100%" }}
                  />  
                  </div>
                      <button className="btn btn-primary float-left pt-2 pb-2 pr-2 pl-2" onClick={ButtonPuB500X500}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).creatPub}</button>
                  </div>
              </div>
              {
                  elakayCategoryID !== "5ed4946fd2ba8812c20261a8" && (
              <div> 
                  <div className="m-4">
                      <div className="mb-3" style={{ height: "117px", width: "117px" }}>
                  <img
                      className="rounded-circle"
                      src={imgProductLakay}
                      alt={"imgage Lakay"}
                      style={{ height: "100%", width: "100%" }}
                  />
                    </div>
                      <button className="btn btn-primary float-left pt-2 pb-2 pr-2 pl-2" onClick={ButtonPuB400X400}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).creatPub}</button> 
                    </div>
              </div>
                  )}
        </Row>
      </div>
  )

  const showPub1500 =()=>(
      showPuB1500X400FT && (
          <PuB1500
              elakayCategoryID={elakayCategoryID}
              loadCategory={loadCategory}
              ButtonCancel={ButtonCancel}
              laguangeElakay={laguangeElakay}
              infoSource={infoSource}
          />
      )
  )
    const showPuB500 = () => (
        showPuB500X500FT && (
     
            <PuB500
                elakayCategoryID={elakayCategoryID}
                loadCategory={loadCategory}
                ButtonCancel={ButtonCancel}
                laguangeElakay={laguangeElakay}
                infoSource={infoSource}
            />
      )
    )

    const showPuB400= () => (
        showPuB400X400FT && (
            <PuB400 
                elakayCategoryID={elakayCategoryID}
                loadCategory={loadCategory}
                ButtonCancel={ButtonCancel}
                laguangeElakay={laguangeElakay}
                infoSource={infoSource}
            />
      )
    )

    const deleteImage = (categoryID, pubID, pub) => {
        DeletePub(token, categoryID, pubID, pub).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
            
                loadCategory(categoryID)
            }
        });
    };

    const loadPub1500x500 =()=>(
        getpub.length > 0 && (
        <div className="container mt-3 mb-3">
           <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
         <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).pub1500x500}</h3>
                </div>
            <Row xs={1} sm={2} md={3} lg={3} xl={3}>
            {
                    getpub.map((p, i)=>(
                        <div  key={i} className="p-1">
                            <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                        <img
                            className="rounded"
                            src={p.urlImg}
                            alt={p.user}
                            style={{ height: "100%", width: "100%" }}
                        />
                        <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                    <MdDeleteForever onClick={() => deleteImage(elakayCategoryID, p._id, 1)} />

                        </div>
                </div>
                </div>
                    ))
                   
            }
                 
            </Row>
        </div>

    ))

    const loadPub600x600 = () => (
   
        getpubR &&  getpubR.urlImg &&   <div className="container mt-3 mb-3">
            <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
                <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).pub600x600}</h3>
            </div>
            <Row xs={1} sm={2} md={3} lg={3} xl={3}>
                {
                
                        <div className="p-1">
                            <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                                <img
                                    className="rounded"
                                    src={getpubR.urlImg}
                                    alt={getpubR.user}
                                    style={{ height: "100%", width: "100%" }}
                                />
                                <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                    <MdDeleteForever onClick={() => deleteImage(elakayCategoryID,  getpubR._id, 3)} />
                                </div>
                            </div>
                            </div>
                

                }

            </Row>
        </div>
    )

    const loadPub400x400 = () => (
       
  getpubC &&  getpubC.urlImg &&  <div className="container mt-3 mb-3">
            <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
                <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).pub400x400}</h3>
            </div>
            <Row xs={1} sm={2} md={3} lg={3} xl={3}>
                
                        <div className="p-1" >
                            <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                                <img
                                    className="rounded-circle"
                                    src={getpubC.urlImg}
                                    alt={getpubC.user}
                                    style={{ height: "100%", width: "100%" }}
                                />
                                <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                    <MdDeleteForever onClick={() => deleteImage(elakayCategoryID, getpubC._id, 2)} />
                                </div>
                            </div></div>
           

            </Row>
        </div>
    )

    return (
        <div>
        {selectProduct()}
        {shoosePuB()}
        {showPub1500()}
        {showPuB500()}
        {showPuB400()}
        {loadPub1500x500()}
        {loadPub600x600()}
        {loadPub400x400()}

        </div>
    )
};

export default PubProducts;