const CheckTaxZone = (tax)=>{
    return  tax.taxprodZone ? tax.taxprodZone.EstimatedCombinedRate : 0.088750
    }


// const calculFeetElakayByCategories = (ord)=>{
//     let Su
//     return ord.reduce((currentValue, nextValue) => {
//         Su = currentValue + (nextValue.cat_Product.FeetsCategory &&  nextValue.cat_Product.FeetsCategory >= 6 ? nextValue.cat_Product.FeetsCategory : 6)
//         return Su ? Su : 0
//     }, 0);
// }

// const calculPF = (ord, pf)=>{

//     if(pf === 's'){
//         return ((calculFeetElakayByCategories(ord) * 3/12)/100)
//     }else if (pf === 'v'){
//         return ((calculFeetElakayByCategories(ord) * 5/12)/100)
//     }else if (pf === 'r'){
//         return ((calculFeetElakayByCategories(ord) * 4/12)/100)
//     }else{
//         return 1
//     }
    
// }

// const checkQuantity = (Q)=>{
//     return  Q ? Q : 1
//     }

    const CheckeFeetCat =(a, p)=>{
        let feet =  a.cat_Product.FeetsCategory &&  a.cat_Product.FeetsCategory >= 6 ? a.cat_Product.FeetsCategory : 6
        return ((feet * p)/100)
     }

    const DiscFeet = (a)=>{
        return(a.products.salePrice / 100)
    }

    const DiscForAdmin = (a)=>{
        return(a.prod_User_userActiveRole.discountForAdmin / 100)
    }

    const DiscV_Prod = (a)=>{
        return((100 - a.products.salePrice) / 100)
    }

    const BDcountPrice =(a)=>{
        return a.products.count * a.products.price
    }

    const ADcountPrice =(a)=>{
        return BDcountPrice(a) * DiscV_Prod(a)
    }

    const DcountPrice =(a)=>{
        return BDcountPrice(a) * DiscFeet(a)
    }

    const calShip = (ship)=>{
        return ship? ship : 0
    }

export  const ItemsOrder = (prd) => {
    let Su
    return prd.reduce((currentValue, nextValue) => {
        Su = currentValue + ADcountPrice(nextValue)
        return Su ? Su : 0
    }, 0);
};

export const prodDiscount = (prd) => {
    let Su 
    return  prd.reduce((currentValue, nextValue) => {
       Su =  currentValue + DcountPrice(nextValue)
        return Su ? Su : 0
    }, 0);
};

export const prodPrice = (prd) => {
    let Su
    return  prd.reduce((currentValue, nextValue) => {
        Su = currentValue + BDcountPrice(nextValue)
        return Su ? Su : 0
    }, 0);
};


export const getTotal = (ord, ship) => {
    let Su
    return  ord.reduce((currentValue, nextValue) => {
        Su = currentValue + ADcountPrice(nextValue) + calShip(ship) + (ADcountPrice(nextValue) * CheckTaxZone(nextValue))
        return Su ? Su : 0
    }, 0);
};


// export const getTotal = (ord, ship) => {
//     let sh = ship? ship : 0
//     let a = ItemsOrder(ord)
//     let c = TaxOrder(ord)
//     let e = sh
//     let d = a  + c + e
//     return d ? d : 0
// };




export const prodFrService0 = (ord, ship) => { 
    let Su
    return ord.reduce((currentValue, nextValue) => {
        Su = currentValue + ((ADcountPrice(nextValue) + DcountPrice(nextValue) + calShip(ship) + (ADcountPrice(nextValue) * CheckTaxZone(nextValue))) * CheckeFeetCat(nextValue,  5/12))
        return Su ? Su : 0
    }, 0);
};

export const prodFrService = (ord, ship) => { 
    // let sh = ship? ship : 0
    // let To = getTotal(ord, sh)
    // let FS = calculPF(ord, 's')
    // To *  FS
    let Su
    return ord.reduce((currentValue, nextValue) => {
        Su = currentValue + ((ADcountPrice(nextValue) + DcountPrice(nextValue) + calShip(ship) + (ADcountPrice(nextValue) * CheckTaxZone(nextValue))) * CheckeFeetCat(nextValue,  3/12))
        return Su ? Su : 0
    }, 0);
};


export const prodFrVente = (ord, ship) => {
//  let sh = ship? ship : 0
//  let To = getTotal(ord, sh)
//  let FV = calculPF(ord, 'v')
//  return To *  FV
let Su

return ord.reduce((currentValue, nextValue) => {
    Su = currentValue + ((ADcountPrice(nextValue) + DcountPrice(nextValue) + calShip(ship) + (ADcountPrice(nextValue) * CheckTaxZone(nextValue))) * CheckeFeetCat(nextValue,  5/12))
    return Su ? Su : 0
}, 0);
};


export const prodFrRef= (ord, ship) => {
    // let sh = ship? ship : 0
    // let To = getTotal(ord, sh)
    // let FR = calculPF(ord, 'r')
    // return To *  FR
    let Su
   
    return ord.reduce((currentValue, nextValue) => {
        Su = currentValue + ((ADcountPrice(nextValue) + DcountPrice(nextValue) + calShip(ship) + (ADcountPrice(nextValue) * CheckTaxZone(nextValue))) * CheckeFeetCat(nextValue,  4/12))
        return Su ? Su : 0
    }, 0);
};


export const prodDisAdmin= (ord, ship) => {
    // let sh = ship? ship : 0
    // let To = getTotal(ord, sh)
    // let FR = calculPF(ord, 'r')
    // return To *  FR
    let Su
   
    return ord.reduce((currentValue, nextValue) => {
        Su = currentValue + (BDcountPrice(nextValue) * DiscForAdmin(nextValue))
        return Su ? Su : 0
    }, 0);
};


export const TaxOrder = (prd) => {
    let Su 
    // return prd && prd.reduce((currentValue, nextValue) => {
    //     Su =  currentValue + (nextValue.products.count * (nextValue.products.price * (100 - nextValue.products.salePrice) / 100) * CheckTaxZone(nextValue))
    //     return Su ? Su : 0
    // }, 0);
    return prd && prd.reduce((currentValue, nextValue) => {
        Su =  currentValue + (ADcountPrice(nextValue) * CheckTaxZone(nextValue))
        return Su ? Su : 0
    }, 0);
};

export const prodFrTransaction = (ord, ship) => {
    // 2.9% + $0.30 par transaction reussie.
    // + 1% pour les cartes internationales.
    // + 1% pour les conversions de devises.
    let st = 0.029
    let stc = 0.30
    let ist = 0.01
    let cst = 0.01
// return  (getTotal(ord, sh) * st) + 0.30 

let Su
return ord.reduce((currentValue, nextValue) => {
    Su = currentValue + ((ADcountPrice(nextValue) + DcountPrice(nextValue) + calShip(ship) + (ADcountPrice(nextValue) * CheckTaxZone(nextValue)) ) * st) + stc
    return Su ? Su : 0
}, 0);

};

export const totalFrais = (ord, ship) => {
  let b =  prodFrService(ord, ship)
  let c = prodFrTransaction(ord, ship)
  let d = prodFrVente(ord, ship)
  let e = prodFrRef(ord, ship)
  let f = 0.50 * ord.length
  let h =  b + c + d + e + f

return  h ? h : 0

};

export const inComeSeller = (ord, ship) => {
    let a = ItemsOrder(ord)
    let b =  totalFrais(ord, ship)
    let h = a - b 

  return  h

  };

export const elakayFrais = (ord, ship) => {
    let b =  prodFrService(ord, ship)
    let d = prodFrVente(ord, ship)
    let e = prodFrRef(ord, ship)

    let f = 0.50 * ord.length 
   
    let h =  b + d + e + f

  return  h

  };

  export const calProfit =(ord, ship, pp)=>{

    let a = inComeSeller(ord,  0)
    let sp = ItemsOrder(ord)
    let cp =  parseFloat(a - pp - ship).toFixed(2)
    if(pp === 0 || pp == undefined ){
        return {profit: 0, loss : 0, statut: 'No cost price'}
    }else{
    if(cp > 0){
    return {profit: cp, loss : 0, statut: 'Profit'}
    }else if (cp < 0){
        return {profit: cp, loss :  Math.abs(cp), statut: 'loss'}
    }else { 
        return {profit: 0, loss : 0, statut: 'break-even point'}
       
    }
}
    }

    const calculateSellingPrice = (purchasePrice, saleFeeRate, saleFeeFixed, processingFeeRate, processingFeeFixed, salesTaxRate,  shippingCost, desiredProfit) =>{
        let sellingPrice = purchasePrice + desiredProfit;

        // Boucle pour affiner le prix de vente jusqu'à atteindre le profit souhaité
        for (let i = 0; i < 100; i++) {
            let saleFee = sellingPrice * saleFeeRate + saleFeeFixed;
            let processingFee = sellingPrice * processingFeeRate + processingFeeFixed;
    
            // Calcul du prix avant la taxe
            let priceBeforeTax = purchasePrice + saleFee + processingFee + shippingCost + desiredProfit;
    
            // Ajouter la taxe de vente pour obtenir le prix final
            sellingPrice = priceBeforeTax * (1 + salesTaxRate);
        }
    
        return {
            sellingPrice: sellingPrice,
            priceBeforeTax: sellingPrice / (1 + salesTaxRate)
        };
    }
    export const CalSellingPrice = (pp, pr, ship, tax)=>{

        let purchasePrice = pp;
        let saleFeeRate = 0.06;
        let saleFeeFixed = 0.50;
        let processingFeeRate = 0.029;
        let processingFeeFixed = 0.30;
        let salesTaxRate = tax ? Math.abs(tax.EstimatedCombinedRate) : 0.088750;
        const shippingCost = ship;
        let desiredProfit = pr;
        const result = calculateSellingPrice(purchasePrice, saleFeeRate, saleFeeFixed, processingFeeRate, processingFeeFixed, salesTaxRate,  shippingCost, desiredProfit);

        // let ic = pp + pr + a + b + ship
        // let perc = (100 - (ef + fp))/100
      
        return  result.priceBeforeTax
    }

    