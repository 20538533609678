import React, { Component} from "react";
import { FaLongArrowAltLeft, FaSlidersH } from "react-icons/fa";
// import './mystyle.sliderProduct.css'; 

class SliderMenuBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderShow: true,
            nameSlider: "Name of slider",
            linkSlider: "/"
        }
    }

    render() {
        const goback = ()=>{
            window.history.back()
        }
            const back =()=>(
            
                <span onClick={() => goback()} className="float-left" style={{ width: "40px", fontSize: "20px", display: "inline-block"}}>
                <span className="pl-2" ><FaLongArrowAltLeft /></span>
                   </span> 
                   
            )

        const Filter = () => (
            <span className="float-right" style={{ fontSize: "20px", width: "40px", display: "inline-block" }}>
                <span onClick={this.props.drawerClickHander} ><FaSlidersH /></span>
            </span>
        )
        
        return (
            this.props.sliderShow && (
                <div className = "menuBarSlider">
                <div className="contrainer">
                {/* <div className="justify-content-center" 
                style={{height:"30px"}}
                > */}
                {/* <div className="bg clearfix"> */}
                    <div className="row">
                        <div className="col col-sm-1 float-left"> 
                            {back()}
                        </div>
                           
                        <div className="col col-sm-10">
                            {/* <h1>hello</h1> */}
                        </div>
                         
                        <div className="col col-sm-1 float-right">
                            {Filter()}
                        </div>
                            
                </div></div></div>
            ))
    }
};
export default SliderMenuBar;