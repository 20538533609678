import { Shippo } from "shippo";

import { elakay_API } from "../../config";

const shippo = new Shippo({
    apiKeyHeader: "ShippoToken shippo_test_1fc30fd1526d238cb645fffa4acc0cf008d4da4c",
    shippoApiVersion: "2018-02-08",
  });

export const createAddressShip = (token, info) => {
    return fetch(`${elakay_API}/shipping/createShippingProd`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json; 
        })
        .catch(err => {
            console.log(err);
        });
};

export const createAddressShipCarts = (token, info) => {
    return fetch(`${elakay_API}/shipping/createShippingCarts`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json;
        })
        .catch(err => {
            console.log(err);
        });
};

export const createShippingCartsOne = (token, info) => {
    return fetch(`${elakay_API}/shipping/createShippingCartsOne`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json;
        })
        .catch(err => {
            console.log(err);
        });
};

export const createtransactionShip = (token, id) => {
    return fetch(`${elakay_API}/shipping/createtransactionShip/${id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getTransactionShip = (token, id) => {
    return fetch(`${elakay_API}/shipping/getTransactionShip/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json;
        })
        .catch(err => {
            console.log(err);
        });
};


export const getShipping = (token, id) => {
    return fetch(`${elakay_API}/shipping/getShipping/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
    })
        .then(response => {
            return response.json;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getTrackingShipping = (carrier, tracking_number) => {
let url = `https://track.goshippo.com/tracking/${carrier}/${tracking_number}`
let url1 = `https://api.goshippo.com/tracks/${carrier}/${tracking_number}`
let url2 = ` https://api.goshippo.com/tracks/usps/9205590164917312751089`
    return fetch(url2, {
        method: "GET",
        headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `ShippoToken shippo_live_cc111dd7e5679d2763a5669fdf5794e0c5f9b959`,
            'shippo-api-version': '2018-02-08'
        },
    })
        .then(response => {
            return response.json;
        })
        .catch(err => {
            console.log(err);
        });
}

export const getTrackingShippingUsps = (carrier, tracking_number) => {
   
    const urlUsps = 'https://api.usps.com/tracking/v3/tracking/9205590164917312751089'
  
fetch(urlUsps, {
  method: 'GET',
  headers: {
    // Accept: "application/json",
    "Content-Type": "application/json"
},
})
.then(response => response.json())
.catch(error => console.error('Error:', error));


    };

export const postTrackingShipping = (carrier, tracking_number) => {
    // let url = `https://api.goshippo.com/tracks/`
        //     return fetch(url, {
        //         method: "POST",
        //         headers: {
        //             // Accept: "application/json",
        //             "Content-Type": "application/json",
        //             'Authorization': `ShippoToken shippo_test_1fc30fd1526d238cb645fffa4acc0cf008d4da4c`,
        //             'SHIPPO-API-VERSION': '2018-02-08'
        //         },
        //         body: JSON.stringify({carrier: "usps", tracking_number: "9102969010383081813033", metadata:"Order 000123"})
        //     })
        //         .then(response => {
        //             return response.json;
        //         })
        //         .catch(err => {
        //             console.log(err);
        //         });
    
        const url = 'https://api.goshippo.com/tracks/';
    
        
    const headers = {
      'Authorization': 'ShippoToken shippo_live_cc111dd7e5679d2763a5669fdf5794e0c5f9b959',
      'Content-Type': 'application/x-www-form-urlencoded',
    //   'shippoApiVersion': "2018-02-08"
    };
    
    const data = new URLSearchParams();
    data.append('carrier', 'usps');
    data.append('tracking_number', '420112079470111206204265442275');
    data.append('metadata', 'Order 000123');
    
    fetch(url, {
      method: 'POST',
      headers: headers,
      body: data
    })
    .then(response => response.json())
    .catch(error => console.error('Error:', error)); 
        };

export const postTrackingShipping0 =  (async (carrier, tracking_number) => {

    // const result = await shippo.trackingStatus.create("usps", "9205590164917312751089", "Order 000123");
    let trackNUm = '420112079470111206204250455198'
    // Handle the result
     try {
        const trackingInfo = await shippo.trackingStatus.create(
          carrier,
          tracking_number
        );
      } catch (error) {
        console.error('Error creating tracking information:', error);
      }


    });

    export const getTrackingShipping1 =  (async (carrier, tracking_number) => {

        // const result = await shippo.trackingStatus.get("usps", "9205590164917312751089");

        // Handle the result
         await shippo.trackingStatus.get(carrier, tracking_number, "Order 000123").then(response => {
              return response.json;
          })
          .catch(err => {
              console.log(err);
          });;
    
    
        });

        export const createWebhooKShippoElakay = (async (carrier, tracking_number, email, obid) => {
         

            // const result = await shippo.webhooks.createWebhook({
            //     event: "track_updated",
            //     url: `http://localhost:3000/tracking_number/${obid.oid}/${obid.pid}`,
            //     active: false,
            //     isTest: true,
            //     object_owner: email
            //   });
            
            //   // Handle the result
            
            const url = 'https://api.goshippo.com/webhooks';
            const headers = {
              'Authorization': 'ShippoToken shippo_test_1fc30fd1526d238cb645fffa4acc0cf008d4da4c',
              'Content-Type': 'application/json'
            };
            
            const data = {
              url: `http://localhost:3000/tracking_number/${obid.oid}/${obid.pid}`,
              event: "track_updated",
              is_test: true,
              active: false,
              object_owner: email
            };
            
            fetch(url, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(data)
            })
            .then(response => response.json())
            .catch(error => console.error('Error:', error));
            
        
            });


        export const listWebhooksShippoElakay = (async (setTracking, carrier, tracking_number) => {
        // await  shippo.webhooks.listWebhooks().then(response => {
        //         setTracking(response)
        //           return response;
        //       }).catch(err => {
        //           console.log(err);
        //       });
            
        const url = 'https://api.goshippo.com/webhooks/';
        const headers = {
          'Authorization': 'ShippoToken shippo_live_cc111dd7e5679d2763a5669fdf5794e0c5f9b959',
          'Content-Type': 'application/json'
        };
        
        fetch(url, {
          method: 'GET',
          headers: headers
        })
        .then(response => response.json())
        .then(data => {
            setTracking({data})
        })
        .catch(error => console.error('Error:', error));
        
            
        
        
            });

            export const creatShipments = (async (setTracking, carrier, tracking_number) => {
               let  addressFrom =     {
                    name: "Shwan Ippotle",
                    company: "Shippo",
                    street1: "215 Clayton St.",
                    street3: "",
                    streetNo: "",
                    city: "San Francisco",
                    state: "CA",
                    zip: "94117",
                    country: "US",
                    phone: "+1 555 341 9393",
                    email: "shippotle@shippo.com",
                    isResidential: true,
                    metadata: "Customer ID 123456",
                    validate: true,
                  }
             let addressReturn =     {
                    name: "Shwan Ippotle",
                    company: "Shippo",
                    street1: "215 Clayton St.",
                    street3: "",
                    streetNo: "",
                    city: "San Francisco",
                    state: "CA",
                    zip: "94117",
                    country: "US",
                    phone: "+1 555 341 9393",
                    email: "shippotle@shippo.com",
                    isResidential: true,
                    metadata: "Customer ID 123456",
                    validate: true,
                  }
        
             
               let parcels = [
                    {
                      extra: {
                        cod: {
                          amount: "5.5",
                          currency: "USD",
                          paymentMethod: "CASH",
                        },
                        insurance: {
                          amount: "5.5",
                          content: "Laptop",
                          currency: "USD",
                          provider: "USPS",
                        },
                      },
                      metadata: "Customer ID 123456",
                      massUnit: "lb",
                      weight: "1",
                    template: "USPS_FlatRateGiftCardEnvelope",
                    },
                ]
          
             await shippo.shipments.create({  
                addressFrom,
                addressTo: addressReturn,
                parcels,
                extra: {
                    isReturn: true
                },
                async: false

                }).then(response => {
                        setTracking(response)
                          return response;
                      }).catch(err => {
                          console.log(err);
                      });
                    });
export const getTrackingUrl =  (async (url) => {
let elUrl = 'http://localhost:3000/'
let trakurl0 = 'https://track.goshippo.com/preview/0b2be4d9e9f242bd855f8ed932fd7c35?redirect=true'
let trakurl1 = 'https://track.goshippo.com/preview/0b2be4d9e9f242bd855f8ed932fd7c35'
let urltrack ="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=9470111206204265442275%2C"

// return fetch(`${elUrl}`, {
//     method: "GET",
//     headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json"
//     },
// }).then(response => {
//         return response.json;
//     })
//     .catch(err => {
//         console.log(err);
//     });

    return   fetch(elUrl).then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.text();
    })
    .then(htmlString => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Fonction pour extraire le texte en ignorant les balises HTML, CSS, et images
        function extractText(node) {
            let text = '';
            node.childNodes.forEach(child => {
                if (child.nodeType === Node.TEXT_NODE) {
                    text += child.textContent;
                } else if (child.nodeType === Node.ELEMENT_NODE && child.tagName !== 'STYLE' && child.tagName !== 'SCRIPT' && child.tagName !== 'IMG') {
                    text += extractText(child);
                }
            });
            return text;
        }

        const textContent = extractText(doc.body); // Affiche le texte extrait sans les balises HTML, CSS, et images
    })
    .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
    });
                    
                    
    });