import React, { useState, useEffect } from "react";
import {useParams, useNavigate} from "react-router-dom";
import Layout from "../Layout/Layout";
import { getProductsF } from "../auth/apiProduct"
import {isAuthenticated, getUserViewed, creatUserPUBToken, getUserPUBToken} from "../auth";
import { FirtIDCategory } from "../../config";
import { prices } from "../pageInside/fixedPrices";
import Slider from '../slider/swiperProduct'
import { laguangeElakay, language } from "../pageInside/dataElakay";
import CategoryPub from "./lakayUser/categoryPub";
import FollowPage from "./lakayUser/followPage";
import SwiperCatUserProd from "../slider/swiperUserProd/swiperCatUserProd";
import LoadingSwiper from "../slider/loadindSwiper";

function processData(data) {
    // Obtenez une liste plate avec tous les noms
    const allNames = data.flatMap(item => item.name);
    const nameCounts = allNames.reduce((acc, name) => {
        acc[name] = (acc[name] || 0) + 1;
        return acc;
    }, {});

    return data.map(item => {
        // Filtrer les noms qui ne sont pas communs à tous les tableaux
        const filteredNames = item.name.filter(name => nameCounts[name] < data.length);

        // Trouver un groupe de genre (comme "Men", "Women", "Boys")
        const genderGroup = filteredNames.find(name =>
            ["Men", "Women", "Boys", 'Girls', 'Babies'].includes(name.trim())
        );

        // Construire "cateryName" avec l'élément principal et le groupe de genre
        const categoryNameParts = [genderGroup, item.name[0]].filter(Boolean);
        const categoryName = categoryNameParts.join(" ").trim();

        return {
            ...item,
            cateryName: categoryName
        };
    });
}

const Home = (props) => {
    const {
        infoSource
        } = props
        const {
            getPromoInfo, 
            setPromoInfo
        } = infoSource
       let params = useParams()

        let tokenPub = params.token
         let navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [productsByArrival, setProductsByArrival] = useState([]);
    const [userViewedProduct, setuserViewedProduct] = useState([])
    const [productsBySell, setProductsBySell] = useState([]);
    const [DealToDayProducts, setDealToDayProducts] = useState([]);
    const [followShop, setFollowShop] = useState([]);
    const [search] = useState({ search:''});
    const [limit] = useState(50);
    const [skip] = useState(0);
    const { token } = isAuthenticated();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [pubCategoryProd0, setPubCategoryProd0] = useState([])
    const [nbrArr, setNbrArr] = useState(3)

  const elakayCategoryID  = FirtIDCategory
    const [myFilters, setMyFilters] = useState({
        filters: {}
    });
    let sizeWindow = window.innerWidth

    const reziArray = (a, n)=>{
        let arr = a.filter(obj => obj._id != null).slice(0, n)
        setPubCategoryProd0(arr)
        setNbrArr(n)
     }
 

     const resizeWidth = (w)=>{
         setWindowWidth(w)
     }
 
     const changeArray = (a)=>{
         // window.matchMedia(`(${w}: ${p})`).addEventListener('change', () => reziArray(a, n))
         // window.matchMedia(`(${w}: ${p})`).addEventListener('resize', () => reziArray(a, n))
         window.matchMedia(`(max-width: 768px`).addEventListener('change', () => reziArray(a, 3))
         window.matchMedia(`(max-width: 576px`).addEventListener('change', () => reziArray(a, 2))
         window.matchMedia(`(min-width: 576px`).addEventListener('change', () => reziArray(a, 2))
 
     if(windowWidth >= 768){
         reziArray(a, 3)
     }else{
         reziArray(a, 2)
     }
     }

    useEffect(() => {
         tokenPub && creatUserPUBToken(tokenPub, () => {
            window.location.reload()
            return navigate("/")
        })
        infoSource.userAdmin && infoSource.userAdmin._id &&  infoSource.userAdmin.pubCategoryProd.length > 0 && changeArray(infoSource.userAdmin.pubCategoryProd)
        resizeWidth(sizeWindow)
        productViewedUser(token)
        loadProductsByArrival(skip, limit, myFilters.filters)
        loadProductsDealToDay(skip, limit, myFilters.filters)  
        loadProductsBySell(skip, limit, myFilters.filters)
        
    }, [token, elakayCategoryID, skip, limit, myFilters.filters, sizeWindow])

    function getDeviceDetails() {
        const ua = navigator.userAgent;
        let device = "Unknown device";
        let model = "Unknown model";
      
        // Exemples de detection simplifiee
        if (/iPhone/.test(ua)) {
          device = "iPhone";
          model = "iPhone"; // Modèle spesifik difisil pou jwenn san API espesifik
        } else if (/android/i.test(ua)) {
          device = "Android";
          // Pou Android, modèl la ka plis varye e pi difisil pou jwenn
          const modelMatch = ua.match(/; (\w+)\sBuild\//);
          model = modelMatch ? modelMatch[1] : "Unknown model";
        } else if (/iPad/.test(ua)) {
          device = "iPad";
          model = "iPad";
        } else if (/Windows NT/.test(ua)) {
          device = "Windows";
          model = "PC";
        } else if (/Mac OS/.test(ua)) {
          device = "MacOS";
          model = "Mac";
        }
      
        return { device, model };
      }
      
     

    const handleFilters = (filters, filterBy) => {
       
        const newFilters = { ...myFilters };
        newFilters.filters[filterBy] = filters;

        if (filterBy === "price") {
            let priceValues = handlePrice(filters);
            newFilters.filters[filterBy] = priceValues;
        }
        loadProductsByArrival(skip, limit, myFilters.filters)
        loadProductsBySell(skip, limit, myFilters.filters)
        loadProductsDealToDay(skip, limit, myFilters.filters)
        setMyFilters(newFilters);
    };

    const handlePrice = value => {
        const data = prices;
        let array = [];

        for (let key in data) {
            if (data[key]._id === parseInt(value)) {
                array = data[key].array;
            }
        }
        return array;
    };

    const findToFollowU = (op, osf, oua, oui)=>{

        setFollowShop([])
        let newA 
        if(oua){
            newA = op.find(o1 => o1.owner._id  !== oui._id &&  o1.owner._id !== oua._id  && !osf.some(o2 =>o1.owner._id === o2._id) )
        }else{
            newA = op.find(o1 => o1.owner._id  !== oui._id  && !osf.some(o2 => o1.owner._id === o2._id ))
        }
        setFollowShop(newA)
    }

    const loadProductsByArrival = (skip, limit, filters) => {
        
        getProductsF(skip, limit, "createdAt", filters).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setProductsByArrival(data.products);
                setPromoInfo({
                    ...getPromoInfo, 
                    pub:  data.PromoInfo
                 })

            }
        });
    };

    const loadProductsBySell = (skip, limit, filters) => {
        getProductsF(skip, limit, "sold", filters).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setProductsBySell(data.products);
                setLoading(false)
            }
        });
    };

    const loadProductsDealToDay = (skip, limit, filters) => {
        getProductsF(skip, limit, "sold", {'dealTodays': [true]}).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setDealToDayProducts(data.products);
                
                // loadProductsDealToDay()
            }
        }).catch(error => {
            console.log(error);
        })
    };

    const productViewedUser = (token, laodFollow = true, obj) => {
        token && (
            getUserViewed(token).then(data => {
                if (!data) {
                    console.log("data Not Found");
                } else { 
                    let yourFollow
                    !laodFollow ? yourFollow = obj :  yourFollow = infoSource.getShopFollow
                    infoSource.userInfo && infoSource.userInfo._id && findToFollowU(data.products, yourFollow, infoSource.userAdmin, infoSource.userInfo)
                    setuserViewedProduct(data.products);
                }
            })
        )
    };
    
    let myStyles = {
        width: "100%",
        backgroundColor: '#f0f0f0'
    }

    return (
        <Layout
            infoSource ={infoSource}
            categoriesID={FirtIDCategory}
            titleElaka={"elakay.com"}
            loading={loading}
            prices={prices}
            handleFilters={handleFilters}
            setMyFilters={setMyFilters}
            getpub={infoSource.getpub}
        >
            <div style={infoSource.styleNav}>
        {infoSource.elakayCategories ?  <Slider
                infoSource ={infoSource}
                circle ={true}
                wm0={3}
                ProductSwiper={infoSource.elakayCategories}
                classSlider={"container"}
                sliderShow={true}
            />:
            <LoadingSwiper
            circle ={true}
            myStyles={myStyles}
            />}

        

{infoSource.userAdmin && infoSource.userAdmin._id &&  pubCategoryProd0.length > 0 && <CategoryPub
        nbrArr={nbrArr}
        userID={infoSource.userAdmin._id}
        infoSource ={infoSource}
        pubCategoryProd ={pubCategoryProd0}
        />}

{infoSource.userAdmin && infoSource.userAdmin._id &&  pubCategoryProd0.length > 0 && <CategoryPub
        Follow ={true}
        nbrArr={nbrArr}
        userID={infoSource.userAdmin._id}
        infoSource ={infoSource}
        pubCategoryProd ={pubCategoryProd0}
        />}

 {infoSource && infoSource.userInfo &&  infoSource.userInfo.followShopUser && infoSource.userInfo.followShopUser.length > 0 && <SwiperCatUserProd
            inView={true}
            myStyles={myStyles} 
            infoSource ={infoSource}
            />}

        {userViewedProduct &&  <Slider
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                 ProductSwiper={userViewedProduct}
                 User={infoSource.readUser}
                //  nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).yourViewed}
                 nameSlider={infoSource.language(infoSource, 'homePage', 'yourViewed')}
                 linkSlider={"/user-viewed"}
                 CataID={true}
                 myStyles={myStyles} 
                 />}

  {followShop && followShop._id  && infoSource.userInfo._id &&  <FollowPage 
         userID ={followShop.owner._id}
         setFollowShop={setFollowShop}
        productViewedUser={productViewedUser}
         token={token}
         nbrArr={nbrArr}
         product ={followShop}
         infoSource ={infoSource}
        />}

        {productsByArrival&&  <Slider
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                 ProductSwiper={productsByArrival}
                 User={infoSource.readUser}
                 nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).newProduct}
                 linkSlider={"/new-product"}
                 myStyles={myStyles} 
                 />
                 }

{/* {productsByArrival && < EmblaCarousel
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                 ProductSwiper={productsByArrival}
                 User={infoSource.readUser}
                 nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).newProduct}
                 linkSlider={"/new-product"}
                 myStyles={myStyles} 
                 />
                 } */}

      {productsBySell && <Slider
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                 ProductSwiper={productsBySell}
                 User={infoSource.readUser}
                 nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).bestSellers}
                linkSlider={"/best-sellers"}
                 myStyles={myStyles} 
                 />}



         {DealToDayProducts && <Slider
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                 ProductSwiper={DealToDayProducts}
                 User={infoSource.readUser}
                 nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).dealDay}
                 linkSlider={"/deal-today"}
                 myStyles={myStyles} 
                 />
                }
                 {/* {JSON.stringify(infoSource.navigation)} */}
                 </div>
                 {!productsByArrival && 
                 <>
                 <LoadingSwiper
                 myStyles={myStyles}
                 />
                  <LoadingSwiper
                 myStyles={myStyles}
                 />
                  <LoadingSwiper
                 myStyles={myStyles}
                 />
                  <LoadingSwiper
                 myStyles={myStyles}
                 />
                 </>}
                  </Layout>
    
    );
};

export default Home;