import React   from 'react';
import { Link} from 'react-router-dom';
import Showimage from './ShowImage'
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import Loader from "../Layout/loader.gif";
import { addItem, isAuthenticated} from "../auth";
import { MdRemoveCircleOutline, MdAddShoppingCart,  MdEdit, MdDeleteForever } from "react-icons/md";
import { FaPlus} from "react-icons/fa";
import { AddToDealToDay , getImages} from "../auth/apiProduct";
import Price from "../pageInside/productsPrice";
import "./Card.css"

const reLoadImages =(a, b, c)=>{

let nA = []
  a.forEach(item =>{
    if(b.includes(item.name)){
      nA.push([item.name, item.images[0]])
    }
  }) 
 return nA
}

const ProFilQuaGrToLe =(a)=>{
    return a.sort(({quantity:a}, {quantity:b}) => b-a)
}

const AddCartFilter =(a, n, f)=>{
  return a.filter(item =>item.filter[f] == n)
}

const Card = (props) => {
  const { token } = isAuthenticated();

const {
  laguangeElakay,
  infoSource,
  product,
  showAddToCartButton = true,
  showButtonUser = false,
  classCard0 = "col p-2",
  classCard1 ='card',
  loadProductsByArrival,
  setProductaddToDeal,
  setShowSideDeal,
  showSideDeal,
  DealProduct,
  CataID,
  showShopV,
  showB,
  owner = null,
  filterSel,
  promoMaker = false,
  btnShopPromo,
  refShopName,
  key = 0,
  edit = false
} = props

const {
  cartItems
} =  infoSource

  // const catalID = CataID !==undefined ? CataID : product.categoriesID.length > 0 ? product.categoriesID[0].catID : undefined

 const getCatalID = (CataID, product) => {
    if (CataID !== undefined) {
      return CataID;
    }
    
    if (
      product &&
      product.categoriesID &&
      product.categoriesID.length > 0
    ) {
      return product.categoriesID[0].catID;
    }
  
    return undefined;
  }

  const catalID =getCatalID(CataID, product)

  let proArrGrToLEss = ProFilQuaGrToLe(product.filterProduct)

  function getImgsa(product, imgProductLakay) {
    if (
      product &&
      product.galeries &&
      product.galeries.length > 0 &&
      product.galeries[0].images &&
      product.galeries[0].images.length > 0
    ) {
      // Retourne la première image du premier élément de "galeries"
      return product.galeries[0].images[0];
    } else if (
      product &&
      product.galeriesID &&
      product.galeriesID.galeries &&
      product.galeriesID.galeries.length > 0
    ) {
      // Retourne "urlImg" du premier élément de "galeriesID.galeries"
      const firstGallery = product.galeriesID.galeries[0];
      return Array.isArray(firstGallery.urlImg) ? firstGallery.urlImg[0] : firstGallery.urlImg;
    } else {
      // Retourne la valeur par défaut "imgProductLakay"
      return imgProductLakay;
    }
  }

  const imgsa =  getImgsa(product, imgProductLakay)
  
// const imgsa =  product.galeries.length > 0 && product.galeries[0].images.length > 0 ? product.galeries[0].images[0] : product.galeriesID && product.galeriesID.galeries.length > 0 ? product.galeriesID.galeries[0].urlImg  : imgProductLakay

  const addToCart = (a) => {
  
    let urlImg  = a[1]
    let filterProduct = a[0] ? AddCartFilter(proArrGrToLEss, a[0], 'color')[0]: proArrGrToLEss[0]
    
    if(token){
      addItem(token, { "count": 1, "categoryID": catalID, "productID": product._id,  "productOrner": product.owner._id,  urlImg, filterProduct: filterProduct._id}).then(response => {
      if (response.error) {
          console.log(response.error);
        } else {
        cartItems(token)
        }
      })}else{
      return window.location.href = "/signin"
      }
  } 
  const DeleteProduct = () => {
    // deleteUserProducts(token, product._id).then(response => {
    //   if (response.error) {
    //     console.log(response.error);
    //   } else {
    //     loadProductsByArrival()
    //   }
    // });
  }

  let StyleCartBut = {
    color:'#ffffff', 
    backgroundColor: '#007399', 
    width: "30px", 
    height: "30px", 
    padding: 0, 
    textAlign: "center", 
    fontSize: "1.2rem", 
    borderRadius: "50%"
  }

    const editButtonProduct = () =>{
      return (
        <Link to={`/product/edit/${product._id}`}>
          <span className="btn float-right mr-4" style={StyleCartBut}>
            <MdEdit/>
          </span>
          </Link>
        )

    }

  const deleteButtonProduct = () => {
    return (
        <span className="btn float-right mr-4" style={StyleCartBut}>
        <MdDeleteForever onClick={DeleteProduct} />
        </span>
      
    )
  } 

  const buttonDealB = (product) => {
    setShowSideDeal(!showSideDeal)
    setProductaddToDeal(product)
  }
  const click =propductID=> {
    AddToDealToDay(token, propductID, { "dealTodays": false, "discount": 0}).then(data => {
      if (data.error) {
        console.log(data.error)
      } else {
        loadProductsByArrival(token, DealProduct)
      }
    });
  };


  const addToDealButton = () => {

    return (
      !product.dealTodays ?
      <span onClick={() => buttonDealB(product)} className="btn float-right mr-4" style={StyleCartBut} data-toggle="tooltip" data-placement="top" title="add to deal"  >
        <FaPlus />
      </span>
     :
        <span onClick={() => click(product._id)} className="btn float-right mr-4" style={StyleCartBut} data-toggle="tooltip" data-placement="top" title="add to deal"  >
          <MdRemoveCircleOutline />
        </span>
      
    )
  } 

  const buttonModification = (a) =>(
    showButtonUser &&  (
        

    <div className="row justify-content-center">
        <div className="bg clearfix"> 
        {deleteButtonProduct()}
          {addToDealButton()}
          {editButtonProduct()}
         
      </div>
    </div>
    )
  )

  const showAddToCart =(a)=> {
    return (
      showAddToCartButton && (
        
        promoMaker?

        <span className="btn btn-dark btn-md py-0 px-1 rounded-4 float-right"  onClick={()=>btnShopPromo(true, null, product._id, refShopName +'; item: '+ product.name.substring(0, 30))}>
        {/* <MdAddShoppingCart onClick={()=>addToCart(a)}/> */}
        Promo
        </span>
        :
        <span className="btn float-right mr-4" style={StyleCartBut}>
      <MdAddShoppingCart onClick={()=>addToCart(a)}/>
      </span>
      ) 
    );
};
const sum =(total, num)=>(
 total + Math.round(num)
)
const sumVote = (
  product.vote.map((p)=>p.voteNum)
)
  let Dealstyle 
  Dealstyle = { minHeight: "83px" , textAlign: "left"}
  showButtonUser && (
 Dealstyle = {minHeight: "114px", textAlign: "left"}
  )
  const ratingVote = sumVote.reduce(sum, 0) / product.vote.length

//   const product0 =  Product(reLoadImages(product.galeries, filterSel['filterProduct.filter.color']))
//  const product1 =  Product(reLoadImages(product.galeries, proArrGrToLEss[0].filter.color))
//  const product2 =  Product([["", imgsa]])

  const Product =(a)=>{
    return a  && a.map((each, index) =>(
    <div key={key + index} className={classCard0}>
    <div className={classCard1}>
    <Showimage 
        infoSource ={infoSource}
        token={token}
        nbrUserVote={product.vote.length}
        rating={ratingVote}
        item={product}
        urlImg={each[1]}
        filterProduct={proArrGrToLEss}
        productId= {product._id}
        categoryId={catalID}
        showB={showB}
        showShopV={showShopV}
        filterColor={each[0]}
        owner_id={owner ? owner: product.owner._id}
        urlFilter={each[0] && `?color=${each[0]}`}
        url="product" 
        edit={edit}
    />
           <div className='card-body p-0 m-0'  style={Dealstyle} >
          <div className={`mt-1 cardFontSise font-weight-lighter text-muted`} ><span>{product.name.substring(0, 19)}...</span> </div>
        <Price
          products={product}
          showAddToCart={showAddToCart}
          filtername ={each}
        />
        <div>
            {buttonModification(each[0])}
        </div>
        </div>
          </div>
    </div>
    ))  
  }
  
  return product && product.galeries.length > 0 && product.galeries[0].images.length > 0 && filterSel && filterSel['filterProduct.filter.color'] && filterSel['filterProduct.filter.color'].length > 0
  ? Product(reLoadImages(product.galeries, filterSel['filterProduct.filter.color'])) 
  : 
  product.galeries.length > 0 && product.galeries[0].images.length > 0
  ?
  Product(reLoadImages(product.galeries, proArrGrToLEss[0].filter.color))
  :
  Product([["", imgsa]])
};

export default Card;