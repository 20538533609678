export const prices = [
    {
        _id: 0,
        name: "Any",
        array: []
    },
    {
        _id: 1,
        name: "$0 to $25",
        array: [0, 25]
    },
    {
        _id: 2,
        name: "$25 to $50",
        array: [25, 50]
    },
    {
        _id: 3,
        name: "$50 to $100",
        array: [50, 100]
    },
    {
        _id: 4,
        name: "$100 to $200",
        array: [100, 200]
    },
    {
        _id: 5,
        name: "200 than $400",
        array: [200, 400]
    },
      {
        _id: 6,
        name: "400 than $800",
        array: [400, 800]
    },
    {
        _id: 7,
        name: "800 than $1600",
        array: [800, 1600]
    },
      {
        _id: 8,
        name: "1600 than $3200",
        array: [1600, 3200]
    }
    ,
    {
        _id: 9,
        name: "More than $3200",
        array: [3200, 100000000]
    }
];