import { isAuthenticated } from "../auth";
import { roleUser } from "../pageInside/roleUser";
import jsSHA from "jssha";
var sha1 = require('sha1');

const { a, b, e, token } = isAuthenticated();

export const Family =(user)=> {
    let Familykay
    let FamilyshaObj
    let Familyrole
if (e === roleUser[0].email ){
    return true
}
if(token){
    if(a){
        Familykay = sha1(roleUser[0].code) + sha1(roleUser[0].role) + sha1(a)
    FamilyshaObj = new jsSHA("SHA-512", "TEXT", {
    hmacKey: { value: Familykay, format: "TEXT" }
        });
    FamilyshaObj.update(Familykay);
    Familyrole = FamilyshaObj.getHash("HEX")
    if (Familyrole === b){
       return  true
    } else {
        if (user === roleUser[0].email) {
            return true
        }else{
        return false
        }
    }
} return false
}
   
}

export const Adminstrator= (ch = false) => {

    let Adminkay
    let AdminshaObj
    let Adminrole
    if (token) {
    if (a) {
        Adminkay = sha1(roleUser[1].code) + sha1(roleUser[1].role) + sha1(a)
        AdminshaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: Adminkay, format: "TEXT" },
        });
        AdminshaObj.update(Adminkay);
        Adminrole = AdminshaObj.getHash("HEX");

    if (Adminrole === b) {
        return true
    } else if (Family()){
        return !ch ? true : false
    } else {
            return false
        
    }
        
} return false
}}

export const Investment = (ch = false) => {
    let Providerkay
    let ProvidershaObj
    let Providerrole
    
    if (token) {
    if (a) {
        Providerkay = sha1(roleUser[7].code) + sha1(roleUser[7].role) + sha1(a)
        ProvidershaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: Providerkay, format: "TEXT" },
        });
        ProvidershaObj.update(Providerkay);
        Providerrole = ProvidershaObj.getHash("HEX");
    

    if (Providerrole === b) {
        return true
    } else if (Adminstrator()){
        return !ch ? true : false
    }else {
            return false
    }
        } return false
}
}
export const Assoociate = (ch = false) => {
    let Providerkay
    let ProvidershaObj
    let Providerrole
    
    if (token) {
    if (a) {
        Providerkay = sha1(roleUser[6].code) + sha1(roleUser[6].role) + sha1(a)
        ProvidershaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: Providerkay, format: "TEXT" },
        });
        ProvidershaObj.update(Providerkay);
        Providerrole = ProvidershaObj.getHash("HEX");
    

    if (Providerrole === b) {
        return true
    } else if (Investment()){
        return !ch ? true : false
    }else {
            return false
    }
        } return false
}
}

export const Provider = (ch = false) => {
    let Providerkay
    let ProvidershaObj
    let Providerrole
    
    if (token) {
    if (a) {
        Providerkay = sha1(roleUser[3].code) + sha1(roleUser[3].role) + sha1(a)
        ProvidershaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: Providerkay, format: "TEXT" },
        });
        ProvidershaObj.update(Providerkay);
        Providerrole = ProvidershaObj.getHash("HEX");
    

    if (Providerrole === b) {
        return true
    } else if (Assoociate()){
        return !ch ? true : false
    }else {
            return false
    }
        } return false
}
}

export const Seller = (ch = false) => {
    let Sellerkay
    let SellershaObj
    let Sellerrole
    if (token) {
    if (a) {
        Sellerkay = sha1(roleUser[4].code) + sha1(roleUser[4].role) + sha1(a)
        SellershaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: Sellerkay, format: "TEXT" },
        });
        SellershaObj.update(Sellerkay);
        Sellerrole = SellershaObj.getHash("HEX");
   

    if (Sellerrole === b) {
        return true
    } else if (Provider()){
        return !ch ? true : false
    } else {
        return false
    } 
}
        return false
}
}


export const Customer = (ch = false) => {
    let Customerkay
    let CustomershaObj
    let Customerrole

    if(token){
    if (a) {
        Customerkay = sha1(roleUser[5].code) + sha1(roleUser[5].role) + sha1(a)
        CustomershaObj = new jsSHA("SHA-512", "TEXT", {
            hmacKey: { value: Customerkay, format: "TEXT" },
        });
        CustomershaObj.update(Customerkay);
        Customerrole = CustomershaObj.getHash("HEX");
    

    if (Customerrole === b) {
        return true
    } else if (Seller()){
        return !ch ? true : false
    } else {
        return false
        
    }} return false
}
   
}

 