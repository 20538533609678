import React, { useState, useRef, useEffect } from "react";
import { useLocation, Link } from 'react-router-dom';
import Layout from "../Layout/Layout";
import { getUserProducts, getProductsF } from "../auth/apiProduct";
import Card from '../pageInside/Card'
import { Row } from 'react-bootstrap';
import { getCart } from "../auth";
import { isAuthenticated} from "../auth";
// import MyButtonShop from "../Dwawer/buttonMyShop"
import MyProductDeal from '../pageInside/userProductDeal'
import UserPubProduct from '../pageInside/addPubGloabal'
import PubShop from '../pageInside/addPubShop'
import {MdEdit, MdPrint } from "react-icons/md";
import Printer from '../pageInside/printerMyshop'
import Modal from 'react-bootstrap/Modal'
// import { Family} from "../pageInside/Rolle"
import { getCategoriesByID } from "../auth/apiCategory";
import ButtonPagination from "../pageInside/ButtonPagination";
import {laguangeElakay} from "../pageInside/dataElakay";
import ShopUserView from "./lakayUser/ShopUser";
import { prices } from "../pageInside/fixedPrices";
import { Family } from "../pageInside/Rolle"
import nodata from '../Layout/imgs/nodata0.png';
import Orders from "./buyerOrder";
import  Sell from "../pages/addProduct"
import FilterUserTeam from "../pageInside/filterUserTeam"
import FilterUserProduct from "../pageInside/filterUserProduct";
import SearchBar from '../pageInside/admin_associate_searchBar';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const UserProducts = props => {
    const {
        infoSource
        } = props

        const {
            userInfo
        } = infoSource
        const filterlist =  {
            asc: 'asc',
            limit : 10,
            sortBy: 'createdAt',
            search: ''
          }

    const location = useLocation(); // Surveille les changements d'URL
    const query = useQuery();
    const [shopAllProdB, setShopAllProdB] =useState([true, false, false])
    const [DealProduct, setDealProduct] = useState(3)
    const prevSearchRef = useRef(location.search);

    const [showFilterOurTeam, setSowFilterOurTeam] = useState(false)
    const [getFilterUserProd, setFilterUserProd]= useState(filterlist)

    const {
        asc,
        limit,
        sortBy,
        search
        } = getFilterUserProd
    
    const PUB_Bool = query.get("BPUB") || true;

    // Définir les paramètres de la query string
    const getPageBoo = () => {
     
        const PSA = query.get("ppb") || null;
        const PSEL = query.get("sel") || null;
        const PTD = query.get("td") || null;
        const PFD = query.get("fd") || null;
        const PMD = query.get("mb") || null;
        const ODR = query.get("odr") || null;

        const defaultQeury = (a, b, c, d, e) => {
            return a ? a : b || c || d || e ? false : true;
        };
        PTD && setDealProduct(1)
        PFD &&  setDealProduct(0)
        !PTD && !PFD &&  setDealProduct(3)
        ODR ? setShopAllProdB([false, false, true]): setShopAllProdB([true, false, false])
        return {
            productShowAll: defaultQeury(PSA, PSEL, PTD, PFD, PMD),
            sellAddProdBoll: PSEL || false,
            productShowDealTrue: PTD || false,
            productShowDealFalse: PFD || false,
            userAddPubShow: PMD || false,
        };
    };

    const [pages, setPages] = useState(getPageBoo);
    const {
        userAddPubShow,
        productShowAll,
        sellAddProdBoll,
        productShowDealTrue,
        productShowDealFalse,
    } = pages


    const [productsByArrival, setProductsByArrival] = useState([]);
    // const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [siseProduct, setSiseProduct] = useState(0);
    const [getCategoriesUser, setCategoriesUser] = useState([]);

    const [shopPubB, setShopPubB] =useState(JSON.parse(PUB_Bool))
    const [showOrderView, setShowOrderView] = useState(false);
    const [loading, setLoading] = useState(true);  
    const [showSideDeal, setShowSideDeal] = useState(false)
    const [showListeShop, setShowListeShop] = useState(false);
    const [productaddToDeal, setProductaddToDeal] = useState([])
   
    const { token } = isAuthenticated();
    const [categories, setCategories] = useState([])
    const [categoryID, setCategoryID] = useState('5ed4946fd2ba8812c20261a8')
    const [text, setText] = useState();
    const[filterShop, setFilterShop] = useState(false)
    const [getCalFilShop, setCalFilShop] = useState([]);
 const [pageShop, setPageShop]=useState(false)

       const [myFilters, setMyFilters] = useState({
               filters: {"categoriesID.gategoriesLink": [] }
           });
    const loadCategory = ID => {
        let CaId = ID ? ID : '5ed4946fd2ba8812c20261a8'
        getCategoriesByID(CaId).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setCategories(data.cartegory)

            }
        });
    };
    const filterUrl =  { search: search || undefined, category: categoryID }

    useEffect(() => {
        const currentSearch = location.search;

        if (prevSearchRef.current !== currentSearch) {
            setPages(getPageBoo());
            prevSearchRef.current = currentSearch; // Met à jour la valeur précédente
        }

        // loadCategory(categoryID)
        loadProductsByArrival(token, DealProduct, myFilters)
    }, [token, DealProduct, location.search, myFilters, search]);

    

    const loadProductsByArrival = (token, DealProduct, myFilters) => {
        getUserProducts(token, skip, limit, {sortBy,  asc}, DealProduct,  filterUrl, myFilters.filters).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setProductsByArrival(data.products);
                setSiseProduct(data.siseProduct);
                setSize(data.size);
                setSkip(0);
                setShowSideDeal(false)
                setLoading(false)
                getCategoriesUser.length == 0 && setCategoriesUser(data.categories)
            }
        });
    };
  
  

    const loadMore = () => {
        let toSkip = skip + limit;
        getUserProducts(token, toSkip, limit, {sortBy,  asc}, DealProduct,  filterUrl,  myFilters.filters).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setProductsByArrival(data.products);
                setSiseProduct(data.siseProduct)
                setSize(data.size);
                setSkip(toSkip);
            }
        });
    };

    const loadMore0 = () => {
        let toSkip = skip - limit;
        getUserProducts(token, toSkip, limit, {sortBy,  asc}, DealProduct,  filterUrl, myFilters.filters).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setProductsByArrival(data.products);
                setSiseProduct(data.siseProduct);
                setSize(data.size);
                setSkip(toSkip);
            }
        });
    };
 
   
    const ButtonShoAllProduct = () => {
        setDealProduct(3)
        setPages({
            ...pages,
            userAddPubShow: false,
            productShowAll: true,
            sellAddProdBoll: false,
            productShowDealTrue: false,
            productShowDealFalse: false,
        })
       
    };

    const ButtonsellAddProd = () => {
        setDealProduct(3)
        setPages({
            ...pages,
            userAddPubShow: false,
            productShowAll: false,
            sellAddProdBoll: true,
            productShowDealTrue: false,
            productShowDealFalse: false,
        })
       
    };
    const ButtonDealProduct = () => {
        setDealProduct(1)
        setPages({
            ...pages,
            userAddPubShow: false,
            productShowAll: false,
            sellAddProdBoll: false,
            productShowDealTrue: true,
            productShowDealFalse: false
        }) 
        
    };
    const ButtonNotDealProduct = () => {
         setDealProduct(0)
        setPages({
            ...pages,
            userAddPubShow: false,
            productShowAll: false,
            sellAddProdBoll: false,
            productShowDealTrue: false,
            productShowDealFalse: true
        })
    };

    const ButtonNotUserPub = () => {
        setDealProduct(3)
        setPages({
            ...pages,
            userAddPubShow:true,
            productShowAll: false,
            sellAddProdBoll: false,
            productShowDealTrue: false,
            productShowDealFalse: false,
        })
    };


  const handlePrice = value => {
 
    let array = [];
    for (let key in prices) {
        if (prices[key]._id === parseInt(value)) {
            array = prices[key].array;
        }}
    return array;
};

const handleFilters = (filters, filterBy) => {
    const newFilters = { ...myFilters };
    newFilters.filters[filterBy] = filters;

    if (filterBy === "price") {
        let priceValues = handlePrice(filters);
        newFilters.filters[filterBy] = priceValues;
    }
   
    setMyFilters(newFilters);
};

    const myDealtrue = () => (
        !productShowDealTrue ?
            <button className="btn btn-md btn-primary float-right p-1" style={{ fontSize: "9px" }} onClick={ButtonDealProduct}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).mydeal}</button>
            : <button className="btn btn-md  float-right p-1" style={{ fontSize: "9px" }} >My deal</button>
    )

    const myDealfalse = () => (
        !productShowDealFalse ?
            <button className="btn btn-md btn-primary float-right p-1" style={{ fontSize: "9px" }} onClick={ButtonNotDealProduct}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).notindeal}</button>
            : <button className="btn btn-md  float-right p-1" style={{ fontSize: "9px" }} >not in deal</button>
    )
    const myDealAll = () => (
        !productShowAll ?
            <button className="btn btn-md btn-primary float-right p-1" style={{ fontSize: "9px" }} onClick={ButtonShoAllProduct}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).allProduct}</button>
            : <button className="btn btn-md  float-right p-1" style={{ fontSize: "9px" }} >All Product</button>
    )
    const AddDeal = () => (
        !userAddPubShow ?
            <button className="btn btn-md btn-primary float-right p-1" style={{ fontSize: "9px" }} onClick={ButtonNotUserPub}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).creataPub}</button>
            : <button className="btn btn-md  float-right p-1" style={{ fontSize: "9px" }} >Creat a Pub</button>
    )
    const ButtonMyShop = () => (
        
        <nav className="row">
            <h1 className="mb-3">{AddDeal()}</h1>

            <h1 className="mb-3">{myDealAll()}</h1>

            <h1 className="mb-3">{myDealfalse()}</h1>
         
            <h1 className="mb-3">{myDealtrue()}</h1>
      
        </nav>
    )

    const productALL = (
        <div>
        <Row className="m-1" xs={2} sm={2} md={3} lg={4} xl={5}>
            {
                productsByArrival.map((product, i) => (
                    <div className="col p-2" key={i}>
                    <Card
                        laguangeElakay={laguangeElakay}
                        infoSource ={infoSource}
                        classCard0 = "col p-0"
                        loadProductsByArrival={loadProductsByArrival}
                        product={product}
                        showAddToCartButton={false}
                        showButtonUser= {true}
                        setProductaddToDeal={setProductaddToDeal}
                        setShowSideDeal={setShowSideDeal}
                        showSideDeal={showSideDeal}
                        CataID={product.categoriesID[0].catID}
                        showShopV={false}
                        skip={skip}
                        limit={limit}
                    />
        </div>
                ))}

        </Row> </div>
    )
    const resultPagination = (
        <div>
            <div>
            {( parseInt(skip) -  parseInt(limit)) +  parseInt(limit) + 1} - {(( parseInt(skip) +  parseInt(limit)) > siseProduct) ? siseProduct : ( parseInt(skip) +  parseInt(limit))}{' '}
             {infoSource.language(infoSource, "label", "of")} {' '}
             {siseProduct} {' '}
             {infoSource.laguangeElakay('label', infoSource.languageAPI).items}
        </div>
            <div className="h3"><MdPrint onClick={() => setShowListeShop(true)} /> </div>
        </div>
    )

    const showAllProduct =()=>(
        productShowAll && (
        <div>
            {resultPagination}
            {filterCategory()}
            {productALL}
                < ButtonPagination
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    size={size}
                    skip={skip}
                    limit={limit}
                    siseProduct={siseProduct}
                    loadMore={loadMore}
                    loadMore0={loadMore0}
                />
        </div>
    ))

    const showDealProduct =()=>(
        productShowDealTrue && (
        <MyProductDeal 
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                DealProduct={DealProduct}
                setProductaddToDeal={setProductaddToDeal}
                setShowSideDeal={setShowSideDeal}
                showSideDeal={showSideDeal}
                productsByArrival={productsByArrival}
                loadProductsByArrival={loadProductsByArrival}
                loadM={loadMore}
                loadM0={loadMore0}
                siseProduct={siseProduct}
                filterCategory={filterCategory}
                categoryID={categoryID}
                text={text}
                size={size}
                skip={skip}
                limit={limit}


        />
    ))

    const sellerAddProd = ()=>{
       return sellAddProdBoll && <div>

       <Sell
       infoSource ={infoSource}
       />

        </div>
    }


    const showNotDealProduct = () => (
        productShowDealFalse && (
            <MyProductDeal
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                DealProduct={DealProduct}
                setProductaddToDeal={setProductaddToDeal}
                setShowSideDeal={setShowSideDeal}
                showSideDeal={showSideDeal}
                productsByArrival={productsByArrival}
                loadProductsByArrival={loadProductsByArrival}
                loadM={loadMore}
                loadM0={loadMore0}
                siseProduct={siseProduct}
                filterCategory={filterCategory}
                categoryID={categoryID}
                text={text}
                size={size}
                skip={skip}
                limit={limit}

            />
        ))

    const showUserPubPage = () => (
       
        userAddPubShow && shopPubB && (
            Family(userInfo.email) ?
            <UserPubProduct
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
            />
            :  <div> 
            <div className="row mt-4 mb-4 justify-content-center">
            <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
             </div>
             </div>
          
        )
        
        )
        const showUserPubShop = () => (
       
            userAddPubShow && !shopPubB && (
                <PubShop
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    userID={userInfo._id}
                />
            )
            
            )



    const printListeShop = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={showListeShop}
                    onHide={() => setShowListeShop(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                          {infoSource.laguangeElakay('title', infoSource.languageAPI).UserViewdListeShop}
          </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{
                            //  backgroundColor: 'coral',
                            //  width: '200px',
                            //  height: '65px',
                            //  border: '1px solid black',
                             overflow: 'scroll'
                        }}>
                        <Printer 
                         laguangeElakay={laguangeElakay}
                         infoSource ={infoSource}
                        MyShop={productsByArrival} 
                        />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )

    const handleChange = () => event => {
        const value = event.target.value;
       
        if (value === null || value === undefined){
            setCategoryID()
            // loadCategory()
        }else{
            setCategoryID(value)
            // loadCategory(value)
        }
    }

    const filterCategory = () => (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6">
            <SearchBar 
           infoSource={infoSource}
           getFilterUserProd={getFilterUserProd}
           setFilterUserProd={setFilterUserProd}
           setSowFilterOurTeam={setSowFilterOurTeam}
           />
            </div>
        </div>

    )
    let StyleMenu = {
        borderBottom: "6px solid SlateGray",
        backgroundColor: "rgba(139, 0, 0, 0.5)"
    }
    let StyleMenu0 = {
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
        borderBottom: "1px solid white",
        backgroundColor: "rgba(139, 0, 0, 0.2)"
    }
   
    let StyleMenu1 = {
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
        borderBottom: "6px solid #007399",
        backgroundColor: "rgba(135, 206, 235, 0.7)"
    }
    let StyleMenu2 = {
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
        // borderBottom: "1px solid #007399",
        backgroundColor: "rgba(135, 206, 235, 0.2)"
    }

    let ShowAllProduct = productShowAll ? StyleMenu : StyleMenu0
    let StylesellProd = sellAddProdBoll ? StyleMenu : StyleMenu0
   
    let ShowPubProduct = userAddPubShow ? StyleMenu : StyleMenu0
    let ShowDealProduct = productShowDealTrue || productShowDealFalse ? StyleMenu : StyleMenu0
    let ShowDealProductT = productShowDealTrue ? StyleMenu1 : StyleMenu2
    let ShowDealProductF = productShowDealFalse ? StyleMenu1 : StyleMenu2
    let ShowAllProductVT = shopAllProdB[0] ? StyleMenu1 : StyleMenu2
    let ShowAllProductVF = shopAllProdB[1] ? StyleMenu1 : StyleMenu2
    let ShowAllProductV3 = shopAllProdB[2] ? StyleMenu1 : StyleMenu2
    let ShowPubVT = shopPubB ? StyleMenu1 : StyleMenu2
    let ShowPubVF = !shopPubB ? StyleMenu1 : StyleMenu2

        let editProd_Id = productsByArrival.length > 0 && productsByArrival[0]._id
        let editCat_Id = productsByArrival.length > 0 && productsByArrival[0].categoriesID[0].catID

        const trueFalseBotton =(a, b, fa, fb)=>{
            if(a){
              return  fb
            }else if(b){
               return fa
            }else{
                return fa
            }
        }
        const trueFalseBotton0 =(a, b, fa, fb)=>{

            if(a){
                if(b){
                    fb(false)
                }else{
                    fb(true)
                }
            }else{
                return fa()
            }
        }

        const trueFalseBotton1 =(a, b, fa)=>{
            let indexVrai = b.findIndex(element => element === true);
            let nextIndex = (indexVrai + 1) % b.length;

            if(a){
                ButtonArray(nextIndex)
            }else{
                return fa()
            }
        }

        const ButtonArray =(index)=>{
    
           // Logique pour l'element à l'index 0
    if (index === 0) {
       setShopAllProdB([true, false, false]);
    }
    // Logique pour l'element à l'index 1
    else if (index === 1) {
       setShopAllProdB([false, true, false]);
    }
    // Logique pour l'element à l'index 2
    else if (index === 2 ){
        setShopAllProdB([false, false, true]);
    }
        }
        // query.get("odr") && ButtonArray(2)

    const shopProductMenu = () => (

        <div className="row mt-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" >
                <div className="col col-12 col-md-12" style={{ textAlign: "center"}} >
                    <div className="row">
                        <div className="col col-12 col-md-3" style={ShowAllProduct} onClick={()=>trueFalseBotton1(productShowAll, shopAllProdB, ButtonShoAllProduct)}>
                            <p className="m-2"> {infoSource.laguangeElakay('Button', infoSource.languageAPI).allProduct} </p>
                        </div>
                          {/* <div className="col col-12 col-md-2" style={StyleMenu0} onClick={()=>trueFalseBotton1(productShowAll, shopAllProdB, ButtonShoAllProduct, setShopAllProdB)}>
                            <p className="m-2">Order</p>
                        </div> */}
                        <div className="col col-12 col-md-3" style={StylesellProd} onClick={()=>  ButtonsellAddProd()}>
                            <p className="m-2">
                                {/* Sell */}
                                {infoSource.language(infoSource, "menuList", "sell")}
                                </p>
                        </div>
                       
                        <div className="col col-12 col-md-3" style={ShowDealProduct}  onClick={trueFalseBotton( productShowDealTrue, productShowDealFalse, ButtonDealProduct, ButtonNotDealProduct)}>
                            <p className="m-2">
                                {/* <MdEdit/>  */}
                            {/* Deal */}
                            {infoSource.language(infoSource, "title", "deal")}
                            </p>
                        </div>
                        <div className="col col-12 col-md-3" style={ShowPubProduct} onClick={()=>trueFalseBotton0(userAddPubShow, shopPubB, ButtonNotUserPub, setShopPubB)}>
                            <p className="m-2">
                                {/* <MdEdit/> */}
                                {infoSource.laguangeElakay('Button', infoSource.languageAPI).creataPub}</p>
                        </div>
                        {/* <div className="col col-12 col-md-2" style={StyleMenu0}>
                        <Link to={`/product/edit/${editProd_Id}/${editCat_Id}`}>
                              <p className="m-2"><MdEdit/> Edit </p>
                        </Link>
                        </div> */}
                        
                
                </div>
                </div>
            
            </div>
        </div>
    )

    const filterOurTeam = ()=>{
        return <Modal
        size="md"
        show={showFilterOurTeam}
        onHide={() => setSowFilterOurTeam(false)}
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h2>
                user Team Filters
                {/* {infoSource.language(infoSource, "title", "ordFilt")} */}

                </h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <FilterUserProduct
       infoSource= {infoSource}
       getCategoriesUser={getCategoriesUser}
      getFilterUserProd = {getFilterUserProd}
      setFilterUserProd = {setFilterUserProd}
      handleFilters={handleFilters}
      setSowFilterOurTeam={setSowFilterOurTeam}
      infoFil = {{token, DealProduct}}
      loadProductsByArrival={loadProductsByArrival}
      />

        </Modal.Body>
    </Modal>

    }

    const shopDealMenu = () => (

        <div className="row mt-2 mb-2 justify-content-center">
            <div className="col col-8 col-md-7 col-lg-6 p-0">
                <div className="col col-12 col-md-12" style={{ textAlign: "center"}}>
                    <div className="row">
                        <div className="col col-12 col-md-6" style={ShowDealProductT} onClick={ButtonDealProduct}>
                            <p className="m-2"> 
                                {/* in the deal  */}
                                {infoSource.language(infoSource, "title", "inDeal")}
                                </p>
                        </div>
                       
                        <div className="col col-12 col-md-6" style={ShowDealProductF} onClick={ButtonNotDealProduct}>
                            <p className="m-2">  
                                {/* out of the deal */}
                                {infoSource.language(infoSource, "title", "outDeal")}
                            </p>
                        </div>
                </div>
                </div>
            
            </div>
        </div>
    )

    const shopPubMenu = () => (

        <div className="row mt-2 mb-2 justify-content-center">
            <div className="col col-8 col-md-7 col-lg-6 p-0" >
                <div className="col col-12 col-md-12" style={{ textAlign: "center"}} >
                    <div className="row">
                        <div className="col col-12 col-md-6" style={ShowPubVT} onClick={()=>setShopPubB(true)}>
                            <p className="m-2"> 
                                {/* gloabals pub  */}
                                {infoSource.language(infoSource, "title", "gloPub")}
                                 </p>
                        </div>
                       
                        <div className="col col-12 col-md-6" style={ShowPubVF} onClick={()=>setShopPubB(false)}>
                            <p className="m-2"> 
                                {/* my shop pub */}
                                {infoSource.language(infoSource, "title", "myShopPub")}
                                </p>
                        </div>
                </div>
                </div>
            
            </div>
        </div>
    )
    const shopViewPage = () => (

         <div className="row mt-2 mb-2 justify-content-center">
            <div className="col col-9 col-md-8 col-lg-7 p-0" >
                <div className="col col-12 col-md-12" style={{ textAlign: "center"}} >
                    <div className="row">
                        <div className="col col-12 col-md-3" style={ShowAllProductVT} onClick={()=>ButtonArray(0)}>
                            <p className="m-2"> 
                                {/* Admin View */}
                                {infoSource.language(infoSource, "Button", "adminView")}
                                </p>
                        </div>
                       
                        <div className="col col-12 col-md-3" style={ShowAllProductVF}  onClick={()=>ButtonArray(1)}>
                            <p className="m-2"> 
                                {/* customer view */}
                                {infoSource.language(infoSource, "Button", "customerView")}
                                </p>
                        </div>
                        <div className="col col-12 col-md-2" style={ShowAllProductV3} onClick={()=>ButtonArray(2)}>
                            <p className="m-2">
                                {/* Order */}
                                {infoSource.language(infoSource, "Button", "order")}
                                </p>
                        </div>
                        <div className="col col-12 col-md-3" style={StyleMenu2}>
                        <Link to={`/product/edit/${editProd_Id}`}>
                              <p className="m-2"><MdEdit/>
                               {/* Edit  */}
                               {infoSource.language(infoSource, "Button", "edFilter")}
                               </p>
                        </Link>
                        </div>
                </div>
                </div>
            
            </div>
        </div>
    )



    const CustomerVIewShop =()=>{
        return  <>   
        <ShopUserView
        setLoading={setLoading}
        infoSource={infoSource}
        // readUser={readUser}
        // ShopName={}
        prices={prices}
        myShop={true}
        setFilterShop={setFilterShop}
        setCalFilShop={setCalFilShop}
        myFilters={myFilters}
        userID={userInfo._id}
       />
       </> 
    }
    return (
        <Layout
            infoSource ={infoSource}
            className="container-fluid"
            loadProductsByArrival={loadProductsByArrival}
            SliderPunShow={false}
            ShowSideDeal={showSideDeal}
            setShowSideDeal={setShowSideDeal}
            productaddToDeal={productaddToDeal}
            loading={loading}
            skip={skip}
            limit={limit}
            handleFilters={handleFilters}
            DealProduct={DealProduct}
            setFilterShop={setFilterShop}
            getCalFilShop={getCalFilShop}
            setMyFilters={setMyFilters}
            filterShop={filterShop}
            userShop= {true}
        >
            <div style={infoSource.styleNav}>
            {shopProductMenu()}
            {userAddPubShow && shopPubMenu()}  
            {productShowAll && shopViewPage()} 
            {(productShowDealTrue || productShowDealFalse) && shopDealMenu()}   
            
            {productShowAll && shopAllProdB[0] && showAllProduct()}
            {productShowAll && shopAllProdB[1] && CustomerVIewShop()}   
            {productShowAll && shopAllProdB[2] && <Orders infoSource={infoSource} />}

            {showDealProduct()}
            {showNotDealProduct()}

            {showUserPubPage()}
            {showUserPubShop()}
            {printListeShop()} 

            {sellerAddProd()}
            {filterOurTeam()}
            {/* {ButtonMyShop()} */}

 
            {(productShowDealTrue || productShowDealFalse) && shopDealMenu()}
            {productShowAll && shopViewPage()}
            {userAddPubShow && shopPubMenu()} 
            {shopProductMenu()}
            </div>
            </Layout>
    );
};

export default UserProducts;