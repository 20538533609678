import React, { useState } from "react";
import { CreatBankAccounts } from "../auth/stripe"
const FormBankCountry = ({
    country,
    setAddBankAcc, 
    isAuthenticated,
    setcountrySel,
    laguangeElakay,
    infoSource,
}) => {
const { token } = isAuthenticated();

    const [loadingB, setLoadingB] = useState(true);  
   const [valuesUS, setvaluesUS] = useState({
    country: 'US',
    currency: 'usd',
    account_holder_name: '',
    account_holder_type: 'individual',
    routing_number: '',
    account_number: '',
    ConAccount_number: "",
    error:"",
     success: false
})

const {
    account_holder_name,
    // account_holder_type,
    routing_number,
    account_number,
    ConAccount_numbe,
    error
} = valuesUS;


const handleChange = name => event => {
    const value = event.target.value;
    setvaluesUS({ ...valuesUS, error: false, [name]: value});
};

    const AccountBank = () => {
        
        setLoadingB(false)
        token && (
            CreatBankAccounts(token, valuesUS).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                    setvaluesUS({ ...valuesUS, error: data.error, success: false });
                    setLoadingB(true)
                } else {
                    
                    setvaluesUS({ 
                        ...valuesUS,
                        country: '',
                        currency: '',
                        account_holder_name: '',
                        account_holder_type: '',
                        routing_number: '',
                        account_number: '',
                        ConAccount_number: "",
                        error: "",
                        success: true
                    })
                    setcountrySel("Selected")
                    setAddBankAcc(false)
                    setLoadingB(true)

                }
            })
        )
    }

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

const FormBanking = () =>(
    <div>{
        country === 'US' && (
            <>
                {showError()}
        <form className="mb-3">

            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('label', infoSource.languageAPI).accountName}</label>
                <input onChange={handleChange('account_holder_name')} type="text" className="form-control" value={account_holder_name} />
            </div>
            <div className="form-group">
                        <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).accountType}</label>
                        <select onChange={handleChange('account_holder_type')} className="form-control">
                            <option value=''>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
                            <option value="individual">{infoSource.laguangeElakay('label', infoSource.languageAPI).individual}</option>
                            <option value="company">{infoSource.laguangeElakay('label', infoSource.languageAPI).company}</option>
                         
                        </select>
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('label', infoSource.languageAPI).routingNumber}</label>
                        <input onChange={handleChange('routing_number')} maxlength="9" type="text" className="form-control" value={routing_number} />
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('label', infoSource.languageAPI).accountNumber}</label>
                        <input onChange={handleChange('account_number')} type="text" className="form-control" value={account_number} />
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('label', infoSource.languageAPI).confAccountNumber}</label>
                        <input onChange={handleChange('ConAccount_numbe')} type="text" className="form-control" value={ConAccount_numbe} />
            </div>



        </form>
                <div className="form-group mt-4">
                    <button className="btn btn-outline-primary" onClick={() => setAddBankAcc(false)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>

                    <button className="btn btn-outline-primary">
                        {loadingB ?
                            <span onClick={() => AccountBank()}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).saveBank}</span>
                            :
                            <>
                                <span className="spinner-border spinner-border-sm mr-1" role="status"></span>
                                <span>{infoSource.laguangeElakay('label', infoSource.languageAPI).loanding}</span>
                            </>
                        }
                    </button>
                </div>
            </>
         ) }

       { country === "CA" && (

           <div>
               <h1>{infoSource.laguangeElakay('title', infoSource.languageAPI).comingSoon}</h1>
            </div>
        )}
    </div>

)

    return (
        FormBanking()
        
    )
};

export default FormBankCountry