import React, { useState } from "react";
import { isAuthenticated } from '../auth';
import { AddPubR400X400 } from "../auth/apiCategory";
import ImagesLoading from "../pageInside/imagesLoadingPub"
// import "./stl.css"

const Sell = props => {
    const { token } = isAuthenticated();
    const [days, setDays] = useState(1)
    const {
        laguangeElakay,
        infoSource,
        elakayCategoryID,
        loadCategory,
        ButtonCancel
    } = props


    const handleSubmit =(files, setPost) => {
       
        files.map(f => AddPubR400X400(token, days, elakayCategoryID, f.file).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
                loadCategory(elakayCategoryID)
                setPost({
                        title:"",
                        desc:"",
                        photos:[]
                        })
            }
        }))
    }

    const handleChange = event => {
        setDays(event.target.value)
    }

    const nbrDayForm = () => (
        <div>
            <form className="mb-3">
                <div className="form-group">
                    <div className="boxCategory1 text-center " id="heading">
                        <h4>
                        {infoSource.laguangeElakay('title', infoSource.languageAPI).nomber} {days} {infoSource.laguangeElakay('title', infoSource.languageAPI).days}
                        </h4>
                    </div>
                    <input
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                        min="1"
                        max="99"
                        aria-label="Amount (to the nearest dollar)"
                        value={days}
                    />
                </div>

            </form>

        </div>
    )
    const showPuB500X500 = () => (
    
            <div className="container">
                <div className="row justify-content-center " >

                    <div className="col col-12 col-md-11 col-lg-10 col-xl-8" style={{ border: "1px solid WhiteSmoke" }}>

                        <div className="row pb-2" style={{ textAlign: "center", border: "1px solid WhiteSmoke" }} >
                            <div className="col col-12 col-md-12" style={{ backgroundColor: "WhiteSmoke" }}>
                                <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).pub600x600}</h3>
                            </div>
                            <div className="col">
                            <div className="m-4">
                                {nbrDayForm()}
                            </div>

                                <div className="m-1">
                                   <ImagesLoading
                                        laguangeElakay={laguangeElakay}
                                        infoSource={infoSource}
                                        maxImgSize={1}
                                        namProduct={''}
                                        handleSubmit={handleSubmit}
                                    />
                                </div>
                                <button className="btn btn-primary float-left pt-2 pb-2 pr-2 pl-2" onClick={ButtonCancel}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>

                            </div>
                        </div>
                    </div>
                </div></div>
        )


    return (
        <div>
            {showPuB500X500()}
        </div>
    );
};

export default Sell;