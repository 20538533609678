// export const allDeviceUserArgent = () => {
//   const ua = navigator.userAgent;
//   let device = "Unknown device", 
//       model = "Unknown model", 
//       browser = "Unknown browser", 
//       navigation = true;

//   // Détection de l'appareil
//   if (/iPhone|iPad/.test(ua)) {
//     device = /iPhone/.test(ua) ? "iPhone" : "iPad";
//     model = device;
//     navigation = false;
//   } else if (/Android/.test(ua)) {
//     device = "Android";
//     const modelMatch = ua.match(/; (\w+)\sBuild\//);
//     model = modelMatch ? modelMatch[1] : "Unknown model";
//     navigation = false;
//   } else if (/Windows NT/.test(ua)) {
//     device = "Windows"; model = "PC";
//   } else if (/Mac OS/.test(ua)) {
//     device = "MacOS"; model = "Mac";
//   }

//   // Détection du navigateur
//   if (/Chrome/.test(ua) && !/Edge|OPR/.test(ua)) {
//     browser = "Google Chrome";
//   } else if (/Safari/.test(ua) && !/Chrome/.test(ua)) {
//     browser = "Safari";
//   } else if (/Firefox/.test(ua)) {
//     browser = "Mozilla Firefox";
//   } else if (/OPR|Opera/.test(ua)) {
//     browser = "Opera";
//   } else if (/Edge|Edg/.test(ua)) {
//     browser = "Microsoft Edge";
//   } else if (/MSIE|Trident/.test(ua)) {
//     browser = "Internet Explorer";
//   }

//   return { device, model, browser, navigation };
// };

export const allDeviceUserArgent =()=>{
  const ua = navigator.userAgent; 
  let device = "Unknown device";
  let model = "Unknown model";
  let browser = "Unknown browser";
  let navigation = true;
  let headShop = false

  // Exemples de détection simplifiée
  if (/iPhone/.test(ua)) {
    device = "iPhone";
    model = "iPhone"; // Modèle spesifik difisil pou jwenn san API espesifik
    navigation = false
    headShop = true
  } else if (/android/i.test(ua)) {
    device = "Android";
    // Pou Android, modèl la ka plis varye e pi difisil pou jwenn
    const modelMatch = ua.match(/; (\w+)\sBuild\//);
    model = modelMatch ? modelMatch[1] : "Unknown model";
    navigation = false
    headShop = true
  } else if (/iPad|Macintosh/.test(ua) && navigator.maxTouchPoints > 0) { 
    // Détection iPad (y compris sur iPadOS qui peut apparaître comme un Mac)
    device = model = "iPad";
    navigation = false;
  }  else if (/Windows NT/.test(ua)) {
    device = "Windows";
    model = "PC";
  } else if (/Mac OS/.test(ua)) {
    device = "MacOS";
    model = "Mac";
  }
 // Détection du navigateur
 if (ua.indexOf("Chrome") > -1 && ua.indexOf("Edge") === -1 && ua.indexOf("OPR") === -1) {
  browser = "Google Chrome";
} else if (ua.indexOf("Safari") > -1 && ua.indexOf("Chrome") === -1) {
  browser = "Safari";
} else if (ua.indexOf("Firefox") > -1) {
  browser = "Mozilla Firefox";
} else if (ua.indexOf("OPR") > -1 || ua.indexOf("Opera") > -1) {
  browser = "Opera";
} else if (ua.indexOf("Edge") > -1 || ua.indexOf("Edg") > -1) {
  browser = "Microsoft Edge";
} else if (ua.indexOf("MSIE") > -1 || ua.indexOf("Trident") > -1) {
  browser = "Internet Explorer";
}

return { device, model, browser, navigation, headShop};
}