import React, { useState, useEffect } from "react";
import { VoteStart } from './voteStartCount'
import {MdInsertComment, MdStar } from "react-icons/md";
import { FaUserAlt} from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { Like_disLike } from './voteLike'
import Vote, {ratingVote} from './vote'
import Modal from 'react-bootstrap/Modal'
import ViewVoteStarButton from './ViewVoteStarButton'
import UserComment from './userComment'
import {
    userDislikeCommentsProduct,
    userLikeCommentsProduct
} from "../../auth/apiProduct";

import "swiper/css/scrollbar";


const CommentaireUser = ({
    infoSource,
    voteA,
    comments,
    token,
    productId,
    loadProductsView,
    ButtonAddComments,
    showComments,
    setUserVote,
    SetSizeComments,
    setCommentsPro,
    commentProduct,
    setSkip,
    size,
    skip, 
    limit,
    setShowComments,
    ButtonBool = true
   
}) => {

    const [VoteNumSt, setVoteNumSt] = useState();
    const [CoUsSt, setCoUsSt] = useState(false);

    let voteRating = voteA && ratingVote(voteA)

    // const getUserCommmentaire = () => {
    //     getComments(productId, 0, 2, "createdAt").then(data => {
    //         if (!data) {
    //             console.log("data not found")
    //         } else {

    //             setCommentsPro(data)
    //         }

    //     }).catch(error => {
    //         console.log(error);
    //     });;
    // };
    // useEffect(() => {
    //     getUserCommmentaire()
    // }, []);

    let st5 = `${VoteStart(voteA, 5)}`
    let st4 = `${VoteStart(voteA, 4)}`
    let st3 = `${VoteStart(voteA, 3)}`
    let st2 = `${VoteStart(voteA, 2)}`
    let st1 = `${VoteStart(voteA, 1)}`

    const ButtonStcchoose = (a, b, N) => {
        if(a > 0){
        if (VoteNumSt === N) {
            setCoUsSt(false)
            setVoteNumSt()
        } else {
            setCoUsSt(b)
            setVoteNumSt(N)
        }
    }
    }
    
    const voteDisLike = comentID => {
        let id = productId
        token && (
            userDislikeCommentsProduct(token, id, comentID).then(data => {

                loadProductsView(productId)
                // NnLikeTrue()

            }).catch(error => {
                console.log(error);
            })
        )
    }

    const voteLike = comentID => {
        let id = productId
        token && (
            userLikeCommentsProduct(token, id, comentID).then(data => {
                loadProductsView(productId)

            }).catch(error => {
                console.log(error);
            })
        )
    }
    const progresseVote = (
        <div style={{ textAlign: "center" }}>
            <span style={{ fontSize: "50px" }}>{parseFloat(voteRating ? voteRating : 0).toFixed(1)}</span>

            <div style={{ width: "100%", fontSize: "30px", marginBottom: "0px" }}>
                {
                    voteRating ?
                        <Vote
                            loadProductsView={loadProductsView}
                            productID={productId}
                            token={token}
                            rating={voteRating}
                        /> : ""}
                {!voteRating && (
                    <Vote
                        loadProductsView={loadProductsView}
                        productID={productId}
                        token={token}
                        rating={0}
                    />
                )}


            </div>
            <p style={{ fontSize: "12px", padding: "0", marginTop: "0px" }}>{voteA ?  voteA.length: 0} users</p>
        </div>
    )
    
    const tableProgressVote = (
        <div>
            <Table style={{ borderStyle: "none" }}>
                <tbody>
                    <tr>
                        <td style={{ width: "90px", padding: "0px" }}>5 <span><MdStar /></span></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st5, true, 5))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st5}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "90px", padding: "0px" }}>4 <MdStar /></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st4, true, 4))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st4}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "40px", padding: "0px" }}>3 <MdStar /></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st3, true, 3))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st3}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "15%", padding: "0px" }}>2 <MdStar /></td>
                        <td style={{ width: "80%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st2, true, 2))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st2}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "40px", padding: "0px" }}>1 <MdStar /></td>
                        <td style={{ width: "90%", padding: "5px" }}>
                            <div className="progress" style={{ height: "10px" }} onClick={() => (ButtonStcchoose(st1, true, 1))}>
                                <div className="progress-bar btn-info" role="progressbar" style={{ width: `${st1}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )

    const BoutonLikeAndDislike = (k) => (
        <div className="mt-0 pt-0">
            <p className="btn mr-2 pl-2 pr-2 pt-1 pb-1" style={{ fontSize: "14px" }} onClick={() => (voteDisLike(k._id))}><AiFillDislike /><span style={{ fontSize: "12px", color: "gray" }}>({Like_disLike(k.Like, false)})</span></p>
            <p className="btn mr-2 pl-2 pr-2 pt-1 pb-1" style={{ fontSize: "14px" }} onClick={() => (voteLike(k._id))}><AiFillLike /><span style={{ fontSize: "12px", color: "gray" }}>({Like_disLike(k.Like, true)})</span></p>
        </div>
    )
    const voteUser = voteU => (
        <Vote rating={voteU} />
    )

    const CommentaireAllUser = () => {

        return (
            !CoUsSt && (
                comments.map((k, i) => (    
                    <div key={i} className="row m-3" style={{ borderBottom: "1px dotted Silver" }}>
                        <div>
                            <div className="m-0 p-0" style={{ fontSize: "18px" }}>
                                <FaUserAlt />
                                <span style={{ fontSize: "14px" }}>elakay User</span><br />
                                <span className="m-0 p-0" style={{ fontSize: "16px" }}>
                                    {voteA.map((p, o) => (
                                        <div key={o}>
                                            {k.user === p.userId && (
                                                voteUser(p.voteNum)
                                            )}
                                        </div>
                                    ))}
                                </span>
                            </div>
                            <p className="m-0" style={{ font: "15px Arial, sans-serif" }}>{k.name}</p>
                            <p className="m-0" style={{ fontSize: "12px" }}> {k.description}</p>
                            {BoutonLikeAndDislike(k)}

                        </div>
                    </div>
                ))
            )
        )
    }


    const CommentaireUserByStart = (st) => (
        CoUsSt && (
            comments.map((k, i) => (
               voteA.slice(1, 3).map((v, j) => (
                    k.user === v.userId && v.voteNum === st && (
                       
                        <div key={j} className="row m-3" style={{ borderBottom: "1px dotted Silver" }}>
                            <div>
                                <div className="m-0 p-0" style={{ fontSize: "18px" }}>
                                    <FaUserAlt />
                                    <span style={{ fontSize: "14px" }}>elakay User</span><br />
                                    <span className="m-0 p-0" style={{ fontSize: "16px" }}>
                                        {voteA.map((p, o) => (
                                            <div key={o}>
                                                {k.user === p.userId && (
                                                    voteUser(p.voteNum)
                                                )}
                                            </div>
                                        ))}
                                    </span>
                                </div>
                                <p className="m-0" style={{ font: "15px Arial, sans-serif" }}>{k.name}</p>
                                <p className="m-0" style={{ fontSize: "12px" }}> {k.description}</p>
                                {BoutonLikeAndDislike(k)}

                            </div>
                        </div>
                         
                    )   
                    )
                )
            ))
        )
    )

    
    const commentaireUser = (
        <div className="container" >
            <div className="row justify-content-center " >

                <div className="col col-12  col-lg-10 col-xl-8" style={{ border: "1px solid WhiteSmoke"}}>

                    <div className="row m-3" style={{ textAlign: "center", border: "1px solid WhiteSmoke" }} >
                        <div style={{ backgroundColor: "WhiteSmoke" }}>
                            <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).customerReviews}</h3>
                        </div></div>
                        <ViewVoteStarButton
                        infoSource={infoSource}
                        setVoteNumSt={setVoteNumSt}
                        VoteNumSt={VoteNumSt}
                        voteA={voteA}
                        token={token}
                        productId={productId}
                        loadProductsView={loadProductsView}
                        ButtonAddComments={ButtonAddComments}
                        showComments={showComments}
                        />
                    
                <>
                <UserComment 
                infoSource={infoSource}
                 skip={skip}
                 limit={limit}
                 setCommentsPro={setCommentsPro}
                 commentProduct={commentProduct}
                 SetSizeComments={SetSizeComments}
                 setUserVote={setUserVote}
                 comments={comments}
                 voteA={voteA}
                 productId={productId}
                 token={token}
                 size={size}
                 ButtonBool={false}

                //  loadMore={loadMore}
                //  loadMore0={loadMore0}
                //  ButtonProductPreview={ButtonProductPreview}
                //  siseProduct={sizeComments}

                />
                    {/* {CommentaireAllUser()} */}
                    {CommentaireUserByStart(VoteNumSt)}
                </>
     
                </div>
            </div>
        </div>

    )

    return (
        <>
            {commentaireUser}
        </>
    );
}

export default CommentaireUser;