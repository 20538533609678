import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaPhone, FaMapMarkerAlt,  FaUserAlt, FaAddressCard} from "react-icons/fa";
import Table from 'react-bootstrap/Table'
import { laguangeElakay  } from "../../pageInside/dataElakay";
import Modal from 'react-bootstrap/Modal';
import { MdSmartphone, MdEdit, MdMarkEmailRead} from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import PageEditForm from "./pageEditForm";
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'


const GetEditInfoUser = (props) => {
    const {
        checkInfoUser,
        userInfo,
        token,
        infoSource,
        setPagesUser,
        bnTelephone,
        setUserInfo,
        loadingUserAct
            } = props
            const [showEditNameModal, setShowEditNameModal] = useState(false);
            const [shooseEdit, setShooseEdit] = useState(false);


            const AddTermsAndConditions = () => (
                <div className="contaire">
                    <div className="row justify-content-center " >
                        <Modal
                            size="lg"
                            show={showEditNameModal}
                            onHide={() => setShowEditNameModal(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                           <PageEditForm
                              setUserInfo={setUserInfo} 
                              bnTelephone={bnTelephone}
                            setPagesUser={setPagesUser}
                           editShoose={shooseEdit}
                           infoSource={infoSource}
                           checkInfoUser={checkInfoUser}
                           userInfo ={userInfo}
                           token={token}
                           loadingUserAct={loadingUserAct}
                           />

                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            )

const bntEdit = (a, b, c)=>{
  return  <h4><MdEdit onClick={()=>{ b(true);  a(c) }} /></h4>
}
            const infoUser = (
          
                     
                        <div className="m-3">
                        <Table>
                            <tbody>
                           { checkInfoUser(userInfo, 'firstname', 'edit') && checkInfoUser(userInfo, 'lastname', 'edit') && <tr>
                                <td><span style={{ fontSize: "25px", paddingRight: "20px" }}><FaUserAlt /></span> </td>
                                <td><h4>{userInfo.firstname} {userInfo.lastname} </h4></td>
                                <td>{bntEdit(setShooseEdit, setShowEditNameModal, 'fullName')}  </td>
                            </tr>}
                        {  checkInfoUser(userInfo, 'email', 'edit') &&  <tr>
                                <td><span style={{ fontSize: "25px", paddingRight: "20px" }}><MdMarkEmailRead /></span> </td>
                                <td><h4> {`${userInfo.email}`} </h4></td>
                                <td>{bntEdit(setShooseEdit, setShowEditNameModal, 'email')}  </td>
                            </tr>}
                            <tr>
                                <td><span style={{ fontSize: "25px", paddingRight: "20px" }}><RiLockPasswordFill /></span> </td>
                                <td><h4> ****Pass Word****</h4></td>
                                <td>{bntEdit(setShooseEdit, setShowEditNameModal, 'passWord')}  </td>
                            </tr>

                    {
                     userInfo.telephone && userInfo.telephone.map((k,i)=>(
                      
                    <tr key={i}>
                        <td><span style={{ fontSize: "25px", paddingRight: "20px" }}><MdSmartphone/></span> </td>
                        <td><h4> {`${k.International}`} </h4></td>
                        <td>{bntEdit(setShooseEdit, setShowEditNameModal, 'telephone')}</td>
                    </tr>
                        ))}
            

                           {userInfo.address && userInfo.address.map((m, i) => (
                
                    <tr  key={i}>   
                                <td><span style={{ fontSize: "25px", paddingRight: "20px" }}><FaMapMarkerAlt /></span> </td>
                                <td><h4>{m.firstName} {m.lastName}<br /> {m.Address1}, {m.city} {m.State} {m.postalCode} {m.country}</h4></td>
                                <td>{bntEdit(setShooseEdit, setShowEditNameModal, 'address')}</td>
                       
                    </tr>
                        
                     ))
                        }
                            </tbody>
                        </Table>
                    </div>
            ) 

   
       
    return   <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                </div>
                { AddTermsAndConditions()} 
         <div className="m-4">
       {infoUser}
       </div>
         </div>
        </div>

 
    
           
     
    
};

export default GetEditInfoUser;