import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout"; 
import { getProductsF } from "../auth/apiProduct";
import Card from '../pageInside/Card'
import { Row} from 'react-bootstrap';
import { prices } from "../pageInside/fixedPrices";
import ButtonPagination from "../pageInside/ButtonPagination";
import { laguangeElakay } from "../pageInside/dataElakay";
import PaginationInfo from "../pageInside/paginationInfo"

const NewProducts = (props) => {
    const {
        infoSource
            } = props
    const {
        readUser
    } = infoSource
        const [productsByArrival, setProductsByArrival] = useState([]);
        const [search] = useState({ search: '' }); 
        const [limit] = useState(50);
        const [skip, setSkip] = useState(0);
        const [size, setSize] = useState(0);
        const [siseProduct, setSiseProduct] = useState(0);  
        const [loading, setLoading] = useState(true);
    const [myFilters, setMyFilters] = useState({
        filters: {}
    });  


    useEffect(() => {
        loadProductsByArrival(myFilters.filters)
    }, [ myFilters.filters]);

    const handleFilters = (filters, filterBy) => {
        const newFilters = { ...myFilters };
        newFilters.filters[filterBy] = filters;

        if (filterBy === "price") {
            let priceValues = handlePrice(filters);
            newFilters.filters[filterBy] = priceValues;
        }
        loadProductsByArrival(myFilters.filters);
        setMyFilters(newFilters);
    };

    const handlePrice = value => {
        const data = prices;
        let array = [];

        for (let key in data) {
            if (data[key]._id === parseInt(value)) {
                array = data[key].array;
            }
        }
        return array;
    };

    const loadProductsByArrival = (filters) => {

        getProductsF(skip, limit, "createdAt", filters).then(data => {
    
                    setProductsByArrival(data.products);
                    setSiseProduct(data.siseProduct);
                    setSize(data.size);
                    setSkip(0);
                    setLoading(false)
                
            }).catch(error => {
                console.log(error);
            });
        };
        
    const loadMore = () => {
        let toSkip = skip + limit;
        getProductsF(toSkip, limit, "createdAt", myFilters.filters).then(data => {
    
                setProductsByArrival(data.products);
                setSiseProduct(data.siseProduct)
                setSize(data.size);
                setSkip(toSkip);
            
        }).catch(error => {
            console.log(error);
        })
    };

    const loadMore0 = () => {
        let toSkip = skip - limit;
        getProductsF(toSkip, limit, "createdAt", myFilters.filters).then(data => {

                setProductsByArrival(data.products);
                setSiseProduct(data.siseProduct);
                setSize(data.size);
                setSkip(toSkip);
            
        }).catch(error => {
            console.log(error);
        });
    };


    const productALL = (
        <Row className="m-1" xs={2} sm={2} md={3} lg={4} xl={5}>
            {
                productsByArrival.map((product, i) => (
                    <div className="col p-2" key={i}>
                    <Card
                        laguangeElakay={laguangeElakay}
                        infoSource ={infoSource}
                        product={product}
                        classCard0 = "col p-0"
                    />
                </div>
                ))}

        </Row>
    ) 
    const resultPagination =(
        <div>
       {(skip - limit) + limit + 1} - {((skip + limit) > siseProduct) ? siseProduct : (skip + limit)}
        {/* of */}
        {infoSource.language(infoSource, "label", "of")}
         {siseProduct}
       </div> 
    )
 


    return (
        <Layout
            className="container-fluid"
            loading={loading}
            infoSource ={infoSource}
            SliderPunShow={false}
            handleFilters={handleFilters}
            prices={prices}
        >
            <div style={infoSource.styleNav}>
        <PaginationInfo
        skip={skip}
        limit={limit}
        siseProduct={siseProduct}
        infoSource={infoSource}
        prodCat={'New Product'}
        />
            {productALL}
            < ButtonPagination
                infoSource ={infoSource}
                size={size}
                skip={skip}
                limit={limit}
                siseProduct={siseProduct}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />
           </div>
            
        </Layout>
    );
};

export default NewProducts;