import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Layout from "../Layout/Layout";
// import Pages from '../../pageInside'
import Pages from './LanguagePage'

// import imgLog1 from '../imgs_steps/IMG_001.png';
// import imgLog2 from '../imgs_steps/IMG_002.png';
// import imgLog3 from '../imgs_steps/IMG_003.png';
// import imgLog4 from '../imgs_steps/IMG_004.png';

// import imgSetepR2 from '../imgs_steps/Step_R_1.png';
// import imgSetepR3 from '../imgs_steps/Step_R_2.png';
// import imgSetepR4 from '../imgs_steps/Step_R_3.png'; 
// import imgSetepR5 from '../imgs_steps/Step_R_4.png';
// import imgSetepR6 from '../imgs_steps/Step_R_5.png';


// import imgSetepS2 from '../imgs_steps/Step_S_2.png';
// import imgSetepS3 from '../imgs_steps/Step_S_3.png';
// import imgSetepS4 from '../imgs_steps/Step_S_4.png';
// import imgSetepS5 from '../imgs_steps/Step_S_5.png';

// import imgSetepP2 from '../imgs_steps/Step_P_2.png';
// import imgSetepP3 from '../imgs_steps/Step_P_3.png';
// import imgSetepP4 from '../imgs_steps/Step_P_4.png';
// import imgSetepP5 from '../imgs_steps/Step_P_5.png';

import nodata from '../Layout/imgs/nodata0.png';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const HowToStep = (props) => {
    const {
        infoSource
            } = props
    const [loading, setLoading] = useState(infoSource.loading);
    let query = useQuery();

    let  paragra0 =  !loading &&  infoSource.languageAPI.elakayInfoAPI

    let stepName = query.get("step")

    const checkProdKeyExit = (ad)=>{
        let ent 
        if(Array.isArray(ad)){
          let ac = ad[0]
          ent = Object.keys(ac ? ac:{});
          return ent.every(([key, val]) => ac[key] != undefined) && true
        } else{
           ent = Object.keys(ad ? ad:{});
           return ent.every(([key, val]) => ad[key] != undefined) && true
        }}

    const pageSteps = ()=>{
        if (!checkProdKeyExit(paragra0[stepName])){
           return <Pages  
            classAlert ={'container'}
            paragra0={paragra0[stepName]}
        />
        }else{
          return <div> 
          <div className="row mt-4 mb-4 justify-content-center">
          <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
           </div>
           </div>
        }
    }

    return (
        <Layout
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            infoSource ={infoSource}
            SliderPunShow={false}
            setLoading={false}
        >
        <div style={infoSource.styleNav}>
       {pageSteps()}
         {/* <Pages  
            classAlert ={'container'}
            paragra0={paragra0[stepName]}
        /> */}
    </div>
        </Layout>
    );
};

export default HowToStep;