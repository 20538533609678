import React from "react";
import { Link} from "react-router-dom";
// Import Swiper React component
import { Swiper, SwiperSlide } from "swiper/react";
import Card from '../pageInside/CardOrders'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css"; 

// import required modules
import { Pagination, Navigation, Virtual } from "swiper/modules";
import "./mystyle.sliderProduct.css"
const SwiperProduct = (props)  => {

  const {
    laguangeElakay,
    infoSource,
    nameSlider ,
    linkSlider ,
    myStyles,
    ProductSwiper,
    PreLinK= '/myshop?odr=true',
    view = 'view',
    Plurial = false,
    orderID,
    classLink,
    wm0 = 2,
    wm640 = 3,
    wm768 = 4,
    wm1024= 5,
   wm1200 = 6
  } = props

  let sliderShow = ProductSwiper.length > 0 ? true : false
  let classSlider = props.classSlider ? props.classSlider : "styles.sliderProduct"

  return (
    sliderShow && (
          
      <div className={`mt-4  p-2 ${classSlider}`} style={myStyles} >
          
          {linkSlider && (
          <div><span className="h3">{nameSlider ? nameSlider : ""}  </span><Link className={`"h6" ${classLink}`} to={linkSlider}>{infoSource.laguangeElakay('Link', infoSource.languageAPI).seeMore}</Link></div>
          )}
      
      <Swiper
         style={{
          "--swiper-navigation-color": "#007399",
          "--swiper-pagination-color": "#007399",
        }}
        slidesPerView={wm0}
        spaceBetween={10}
        slidesPerGroup={2}
        // loop={true}
        // navigation
        navigation ={infoSource && infoSource.navigation}
        loopFillGroupWithBlank={true}
        breakpoints={{
          568: {
            slidesPerView: wm640,
            spaceBetween: 10
          }, 
          768: {
            slidesPerView: wm768,
            spaceBetween:10,
          },
          1024: {
            slidesPerView: wm1024,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: wm1200,
            spaceBetween: 10,
          }
        }}
        modules={[Pagination,  Navigation, Virtual]}
        className="mySwiper"
      >
 {
   Plurial?
   ProductSwiper.map((product, i) => (
    <SwiperSlide key={i} >
    <Card
        laguangeElakay={laguangeElakay}
        infoSource ={infoSource}
        imageID={product._id}
        urlImg={product.urlImg}
        product={product}
        orderURL={`${PreLinK}/${orderID}/${product._id}/${view}`}
    />
 </SwiperSlide>
))
   :
 ProductSwiper.map((p, i) => (
    p.products.map((product, j) => (
  <SwiperSlide key={i} >
     <Card
        laguangeElakay={laguangeElakay}
        infoSource ={infoSource}
        urlImg={product.urlImg}
        imageID={product._id}
        product={product}
        // orderURL={`/b/orders/${p._id}/${product._id}/view`}
    />
  </SwiperSlide>
      )
      )
  )
  ) 
 }
      </Swiper>
      </div>
  )
  )
  ;
};
export default SwiperProduct; 
