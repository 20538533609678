import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { elakay_API } from "../../config";
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import Loader from "../Layout/loader.gif";
// import Showimage from './ShowImageCircle'
import "./Card.css"


 const laodImgPubCI = ()=>{
       return  imgProductLakay
    }

const Card = ({ 
    infoSource,
    product = {},
    classCard0 =  'justify-content-center',
    classCard1 = '',
   
}) => {
    // const [image, setImages] = useState(Loader)

    let url = `${elakay_API}/pubCi/${product.supCategoryID._id}`

    return (
        <div className={classCard0} style={{ width: "100%" }}>
           
            <div className={`card m-2 p-0 rounded-circle${classCard1}`}>
                <Link to={`/product/${product.supCategoryID._id}`}>
                <img
                    className="rounded-circle"
                    src={url}
                    onError={event => {
                        event.target.src = imgProductLakay
                        event.onerror = null
                      }}
                    alt={product.supCategoryID.name}
                    style={{ height: "100%", width: "100%" }}
                /> </Link> 
                </div>
            <div className="mt-0 text-center cardFontSise0"><Link to={`/product/${product.supCategoryID._id}`} style={{ color: "black" }}>
            {/* {product.supCategoryID.name[0]} */}
            {infoSource && infoSource.getCategoryNames(product).name[0]}
            </Link></div>
        </div>
    );
};

export default Card;