import React, { useState, useEffect } from "react";
import Slider from '../slider/swiperProduct'
import { getProductsF } from "../auth/apiProduct";
import { useParams } from "react-router-dom";

// import EmptyProductCatagies from '../Layout/imgs/No-products-1-4.png' 

const Products = props => {
    const {
        laguangeElakay,
        infoSource,
    }= props
    let params = useParams()
    const [products, setProducts] = useState([]);

    const itemCategoryID = props.categoryID ? props.categoryID : params.categoryId

    const loadProductsBySell = (cid) => {
        getProductsF(0, 50, "createdAt", {"categoriesID.gategoriesLink": [cid]}).then(data => {
                setProducts(data.products);
                
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        loadProductsBySell(itemCategoryID);
    }, [itemCategoryID]);
    
    let myStyles = {
        width: "100%",
        backgroundColor: '#f0f0f0'
    }
  
    const slider = (
        
        <Slider
            ProductSwiper={products}
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
            User={props.cartItems}
            nameSlider={props.nameSlider}
            linkSlider={props.linkSlider}
            showB={props.showB}
            myStyles={myStyles}
       />)

    return (
        <>
            {slider} 
        </>
  );
};

export default Products;