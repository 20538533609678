import React, { useState, useEffect, useRef } from "react";
import Layout from "../Layout/Layout";
import { Link, useParams } from "react-router-dom";
import {isAuthenticated, OrdersViewById, sellerlistOrdersStatus, updateOrder} from "../auth";
import Modal from 'react-bootstrap/Modal'
import Printer from '../pageInside/printerLabel1'
import Slider from '../slider/swiperOder'
import Loader from "../Layout/loader.gif"
import imgProductLakay from '../Layout/imgs/md3.jpg'
import Table from 'react-bootstrap/Table'
import Filters from '../pageInside/FilterProduct'
import Price from "../pageInside/productsPrice"
import ModalProdView from "../pageInside/modalProductView"
import {retrievePayment, getPaymentIntentView} from "../auth/stripe";
import {taxProductArea} from "../auth/apiProduct";
import SubmitCancelButtonSlider from "../pageInside/SubmitCancelButtonSlider";

import {createWebhooKShippoElakay, listWebhooksShippoElakay, postTrackingShipping0, postTrackingShipping ,creatShipments, getTrackingShippingUsps} from "../auth/shipping"
import {
    ItemsOrder,
    prodDiscount,
    prodPrice,
    prodFrService, 
    prodFrVente,
    prodFrRef,
    TaxOrder,
    getTotal,
    prodFrTransaction,
    totalFrais,
    inComeSeller,
    elakayFrais,
    calProfit
} from '../auth/allPriceFeet'

import moment from "moment";

const validateUrl = async (url, img = false) => {
    try {
      const response = await fetch(url, { method: "HEAD" }); // Requête HTTP HEAD pour ne récupérer que les en-têtes
      if (!response.ok) {
        return false; // L'URL n'est pas valide ou inaccessible
      }
  
      if(img){ 
        const contentType = response.headers.get("Content-Type");
         if (contentType && contentType.startsWith("image/")) {
        return true; // L'URL pointe vers une image
      }
      }else{
        return true
      }
     
  
      return false; // L'URL est valide mais ce n'est pas une image
    } catch (error) {
      return false; // Une erreur s'est produite, l'URL est invalide
    }
  };

  
function ReadMoreLess({infoSource,  text, textLess= 'futur.'}) {
    const [isExpanded, setIsExpanded] = useState(false);

    // Fonction pour basculer l'état
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    // Séparation du texte
    const previewLength = text.indexOf(textLess) + textLess.length;
    const previewText = text.slice(0, previewLength);
    const remainingText = text.slice(previewLength);
    const seeMore = infoSource.language(infoSource, "Link", "seeMore")
    const seeLess = infoSource.language(infoSource, "Link", "seeLess")
    return (
      <div 
      style={
        {
        padding: '15px',
         maxWidth: '600px',  
         backgroundColor: '#fff3cd', 
         color: '#856404', 
         borderRadius: '5px',
        border: '1px solid #ffeeba',
         margin: '0 auto' 
         }}>
        {/* Texte de prévisualisation */}
        <p>
        <strong>NB: </strong>
          {previewText}
          {!isExpanded && '...'} {/* Ajout des ellipses si non développé */}
       
  
        {/* Texte supplémentaire affiché uniquement si étendu */}
        {isExpanded && remainingText}
  
        {/* Bouton bascule */}
        <span className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={toggleExpand} style={{padding: '5px' }}>
          {isExpanded ? seeLess : seeMore}
        </span> 
        </p>
      </div>
    );
  }

const Orders = (props)  => {
    const {
        infoSource,
        getOrderID,
        setShowOrderView,
        showOrderView,
        clotureFeet = 0.50
        } = props

        const simulationPrice = {
            ItemsOrder: 0,
            prodDiscount : 0,
            prodPrice: 0,
            prodFrService: 0, 
            prodFrVente: 0,
            prodFrRef: 0,
            TaxOrder: 0,
            getTotal: 0,
            prodFrTransaction: 0,
            totalFrais: 0,
            inComeSeller: 0,
            elakayFrais: 0,
            statut: {profit: 0, loss : 0, statut: 'break-even point'}
        }
    let infoValue = {
        carrier: '',
        tracking: '',
        urlTracking: '',
        url_label: ''
        }
    // let infoValue0 ={
    //     carrier: 'USPS',
    //     tracking: '420112079470111206204250455198',
    //     urlTracking: 'https://track.goshippo.com/preview/0b2be4d9e9f242bd855f8ed932fd7c35?redirect=true',
    //     url_label: 'https://deliver.goshippo.com/20331b04afc44867979551bba08c4773.png?Expires=1748568879&Signature=P73ypyZuyH3IWwJjFn1C0t2RGVWrz7RtgfU0mZpdqpTpLofqLYc6M7MhnK9-asLmS0ZmmEmenDjxMVxMHVNjRYfI79B7p7omujjQnxRX31ibxMvE~4Y8iBt0LnxMlX1C~sGzi9rPzkJgdQn8n8ItaQ6Tm0fXmUoLVW7XaAiYgqVf9nUfbRY8T4U7S0ha5pJIWRDvS2grll41tF1QgkcQjtpqJCabxiXb-NJrcjOlqTtoIN5e8WWKYE9oKH9o~fykN6mJ1UdWiGh4iKGkHHRm1inpUERC8zWDZtEwERKu7PbrkmDLZOv1wxaNmZ2ZZ8B3Pb0rkHgbVPb~INY0DBeUdA__&Key-Pair-Id=APKAJRICFXQ2S4YUQRSQ'
    // }

    let params = useParams() 
    const [orders, setOrders] = useState([]);
    const { token } = isAuthenticated();
    const [loading, setLoading] = useState(false);
    const [ordersShipping, setOrdersShipping] = useState({})
    const [image, setImages] = useState(Loader);
    const [ordersProducts, setOrdersProducts] = useState([])
    const [showLabel, setShowLabel] = useState(false);
    const [showTracking, setShowTracking] = useState(false);
    const [info, setInfo]= useState(infoValue)
    const [trackingObj, setTrackingObj] = useState([]);
    const [label_Tracking, setLabel_Tracking] = useState([false, false]);
    const [orderPayment, setOrderPayment] = useState({})
    const [getSimulationPrice, setSimulationPrice]= useState(simulationPrice)


    let orderID = getOrderID.oid;
    let prodIDI = getOrderID.pid;
    // let imageID = prodIDI
const {
    carrier,
    tracking,
    urlTracking,
    url_label
} = info

const checkInfoBool = carrier  !== '' && tracking !== '' &&  urlTracking !== ''

  // Texte en français
  const textFrench =
    "Pourquoi est-il important de remplir cette fiche? Cher vendeur, il est essentiel de remplir les champs carrier, tracking, urlTracking et url_label pour assurer une meilleure expérience client et simplifier nos processus futurs. Voici pourquoi : Offrir une transparence aux consommateurs : En remplissant ces informations, vous permettez à vos clients de suivre leur commande en temps réel. Cela améliore leur confiance et réduit les demandes d'informations supplémentaires concernant leurs colis. Préparation pour des améliorations futures : À l'avenir, notre système s'appuiera uniquement sur le carrier et le tracking number pour gérer et suivre les commandes. En renseignant correctement ces champs maintenant, vous contribuez à une transition harmonieuse et sans interruption de nos services. Renforcer la satisfaction client : Lorsque les clients peuvent facilement accéder à des informations sur leur commande, leur satisfaction augmente, ce qui améliore vos évaluations en tant que vendeur et renforce la fidélité de vos acheteurs. Enfin, en remplissant ces champs, vous permettez également de finaliser le paiement pour la commande en question.";

  // Texte en anglais
  const textEnglish =
    "Why is it important to fill out this form? Dear seller, it is essential to complete the fields carrier, tracking, urlTracking, and url_label to ensure a better customer experience and simplify our future processes. Here's why: Providing transparency to consumers: By filling in this information, you allow your customers to track their orders in real time. This enhances their confidence and reduces additional inquiries about their packages. Preparing for future improvements: In the future, our system will rely only on the carrier and tracking number to manage and track orders. By correctly entering these fields now, you contribute to a smooth and uninterrupted transition in our services. Enhancing customer satisfaction: When customers can easily access information about their orders, their satisfaction increases, improving your ratings as a seller and fostering buyer loyalty. Finally, by filling in these fields, you also enable the payment to be finalized for the order in question.";

  // Texte en espagnol
  const textSpanish =
    "¿Por qué es importante completar este formulario? Estimado vendedor, es esencial completar los campos carrier, tracking, urlTracking y url_label para garantizar una mejor experiencia del cliente y simplificar nuestros procesos futuros. Aquí está el porqué: Proporcionar transparencia a los consumidores: Al completar esta información, permite que sus clientes rastreen sus pedidos en tiempo real. Esto mejora su confianza y reduce las consultas adicionales sobre sus paquetes. Prepararse para mejoras futuras: En el futuro, nuestro sistema dependerá únicamente del carrier y el tracking number para gestionar y rastrear los pedidos. Al ingresar estos campos correctamente ahora, contribuye a una transición fluida y sin interrupciones en nuestros servicios. Mejorar la satisfacción del cliente: Cuando los clientes pueden acceder fácilmente a la información sobre sus pedidos, su satisfacción aumenta, lo que mejora sus calificaciones como vendedor y fomenta la lealtad del comprador. Finalmente, al completar estos campos, también permite que se finalice el pago del pedido en cuestión.";

    const newCartProd = async (data)=>{
        for (let i = 0; i < data.length; i++) {
            const additionalData = await taxProductArea(data[i].prod_User_Address.State, data[i].prod_User_Address.postalCode)
            if (additionalData) {
                data[i].taxprodZone = additionalData.zoneTax; // Ajout du nouveau champ
            }   
        } 
        let objOrder =  data[0]
       
        setOrders(objOrder);
        setOrdersShipping(objOrder.shippingAddress);
        setOrdersProducts(objOrder.products);  

        loadSimulationPrice(objOrder, 0, 0, 0)
      }

    const loadOrders = (token, OID, PID) => {
        OrdersViewById(token, OID, PID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                newCartProd(data);
                // setOrdersProducts(data.products);
                data[0] &&  
                data[0].products &&  
                data[0].products.paymentIntent && 
                data[0].products.paymentIntent.payment_method != null 
                ? laodRetrievePayment(token, data[0].products.paymentIntent.payment_method): 
                laodPaymentMethodView(token, data[0].products.transaction_id)
                setLoading(false)
            }
        });
    };
    const laodRetrievePayment = (token, IDpaymentMethods) => {
        retrievePayment(token, {IDpaymentMethods}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setOrderPayment({
                        PM_id_sel: data.id,
                        b_name: data.billing_details.name,
                        b_exp_month: data.card.exp_month,
                        b_exp_year: data.card.exp_year,
                        b_city: data.billing_details.address.city,
                        b_brand: data.card.brand,
                        b_last4: data.card.last4,
                        b_country: data.billing_details.address.country,
                        b_line1: data.billing_details.address.line1,
                        b_postal_code: data.billing_details.address.postal_code,
                        b_state: data.billing_details.address.state
                    })
                }
            }
        );
    };

    const laodPaymentMethodView = (token, ID) => {
        getPaymentIntentView(token, ID).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setOrderPayment({
                        PM_id_sel: null,
                        b_name: null,
                        b_exp_month: data.cardInfo.card.exp_month,
                        b_exp_year: data.cardInfo.card.exp_year,
                        b_city: null,
                        b_brand: data.cardInfo.card.brand,
                        b_last4: data.cardInfo.card.last4,
                        b_country: null,
                        b_line1: null,
                        b_postal_code: null,
                        b_state: null
                    })
                    
            
                }
            }
        );
    };

    const loadSimulationPrice =(ord, ship, pp, pr)=>{
     let newInf = {   
                    ItemsOrder : ItemsOrder([ord]) ,  
                    prodDiscount : prodDiscount([ord]),
                    prodPrice : prodPrice([ord]),
                    prodFrService : prodFrService([ord], 0), 
                    prodFrVente : prodFrVente([ord], 0),
                    prodFrRef: prodFrRef([ord], 0),
                    TaxOrder : TaxOrder([ord]),
                    getTotal : getTotal([ord], 0),
                    prodFrTransaction : prodFrTransaction([ord], 0),
                    totalFrais : totalFrais([ord], 0),
                    inComeSeller: inComeSeller([ord], 0),
                    elakayFrais : elakayFrais([ord], 0),
                    statut: calProfit([ord], ship, pp),
                    // calSellingPrice : CalSellingPrice(Math.abs(pp), Math.abs(pr), 6, 2.9)
                }
              
                setSimulationPrice(newInf)
    }

    useEffect(() => {
        loadOrders(token, orderID, prodIDI);
        
    }, [token, orderID, prodIDI]);

    // const {
    //     customer,
    //     payment_method
    // } = orders.products.paymentIntent.customer

    const sum = (total, num) => (
        total + Math.round(num)
    )
    const countProd = (
        [orders].map((p) => p.count)
        // [1]
     )
     const countProduct = countProd.reduce(sum, 0)

    const PaymentAchat =()=>(
        <table className="table table-sm">
            <tbody>
                <tr>
                    <th>{countProduct} {infoSource.laguangeElakay('label', infoSource.languageAPI).items} : </th>
                    <td>{(getSimulationPrice.ItemsOrder).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })} </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                    <td>{
                         isAuthenticated() ?
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }) :
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                    }
                    </td>
                </tr>
                <tr>
                    <th>
                        {infoSource.laguangeElakay('label', infoSource.languageAPI).tax} 
                        :</th>
                    <td>{(getSimulationPrice.TaxOrder).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr className="table-primary">
                    <th className="text-danger" style={{ fontSize: "18px" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).total}: </th > <td className="text-danger" style={{ fontSize: "18px" }}>{(getSimulationPrice.getTotal).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}</td>
                </tr>
           
            </tbody>
        </table>
        
    )

    const PaymentAchVent =()=>(
        <table className="table table-sm">
            <tbody>
            <tr>
                    <th>
                        {/* prix befor discount  */}
                        {infoSource.language(infoSource, "sellPage", "pribefdisc")}
                        : </th>
                    <td>{(getSimulationPrice.prodPrice).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })} </td>
                </tr>
                <tr>
                    <th>
                        {/* prix after discount */}
                        {infoSource.language(infoSource, "sellPage", "prAftDisc")}
                        : </th>
                    <td>{(getSimulationPrice.ItemsOrder).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })} </td>
                </tr>
                <tr>
                    <th>
                        {/* Discount  */}
                        {infoSource.language(infoSource, "sellPage", "discount")}
                        {orders.products && orders.products.salePrice}%: </th>
                    <td>{(getSimulationPrice.prodDiscount).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                    <td>{
                        isAuthenticated() ?
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) :
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                    }
                    </td>
                </tr> 
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                    <td>{(getSimulationPrice.TaxOrder).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr className="table-primary">
                    <th className="text-danger" style={{ fontSize: "18px" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).total}: </th> <td className="text-danger" style={{ fontSize: "18px" }}>{(getSimulationPrice.getTotal).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}</td>
                </tr>
    
              <tr>
                    <th>
                        {/* Frais paiements 2.9% + $0.30  */}
                        {infoSource.language(infoSource, "sellPage", "fraiPaiem")}
                        : </th>
                    <td>{(getSimulationPrice.prodFrTransaction).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th>elakay Feet 6% + ${ parseFloat(clotureFeet).toFixed(2)}: </th>
                    <td>{(getSimulationPrice.elakayFrais).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr className="table-secondary">
                    <th className="text-danger" style={{ fontSize: "18px" }}>
                         {/* Total des Frais  */}
                         {infoSource.language(infoSource, "sellPage", "TotFrais")}
                         : </th> <td className="text-danger" style={{ fontSize: "18px" }}>{(getSimulationPrice.totalFrais).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}</td>
                </tr>
    
                <tr className="table-success">
                    <th className="text-danger">
                        {/* Revenu Net  */}
                        {infoSource.language(infoSource, "sellPage", "revNet")}: </th>
                    <td className="text-danger">{(getSimulationPrice.inComeSeller).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                
            </tbody>
        </table>
        
    )

    const PaymentVente =()=>(
        <table className="table table-sm">
            <tbody>
                <tr>
                    <th>{1} {infoSource.laguangeElakay('label', infoSource.languageAPI).items} : </th>
                    <td>{(getSimulationPrice.prodPrice).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })} </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                    <td>{
                        isAuthenticated() ?
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) :
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                    }
                    </td>
                </tr>
                <tr style={{ textDecoration: "line-through", fontStyle: "italic", fontFamily: "myFirstFont", fontSize: "15px" }}>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                    <td>{(getSimulationPrice.TaxOrder).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr style={{ textDecoration: "line-through", fontStyle: "italic", fontFamily: "myFirstFont", fontSize: "15px" }}>
                    <th>
                        {/* Discount  */}
                        {infoSource.language(infoSource, "sellPage", "discount")}
                        : </th>
                    <td>{(getSimulationPrice.prodDiscount).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th>
                        {/* Frais de Service */}
                        {infoSource.language(infoSource, "sellPage", "fraisServi")}
                         : </th>
                    <td>{(getSimulationPrice.prodFrService).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th> 
                        {/* Frais paiements   */}
                        {infoSource.language(infoSource, "sellPage", "fraispaie")}: </th>
                    <td>{(getSimulationPrice.prodFrTransaction).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th>
                        {/* Frais de Vente  */}
                        {infoSource.language(infoSource, "sellPage", "fraiVente")}
                        : </th>
                    <td>{(getSimulationPrice.prodFrVente).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th>
                        {/* Frais Gestion & d'Expedition */}
                        {infoSource.language(infoSource, "sellPage", "frGesExpedi")}
                         : </th>
                    <td>{(0).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
    
                <tr>
                    <th>
                        {/* Frais de Referencement  */}
                        {infoSource.language(infoSource, "sellPage", "fraiReferen")}
                        : </th>
                    <td>{(getSimulationPrice.prodFrRef).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr>
                    <th>
                        {/* Frais de Clôture  */}
                        {infoSource.language(infoSource, "sellPage", "fraisClotu")}: </th>
                    <td>{(clotureFeet).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
    
                <tr className="table-secondary">
                    <th className="text-danger" style={{ fontSize: "16px" }}>
                        {/* Total des Frais  */}
                        {infoSource.language(infoSource, "sellPage", "TotFrais")}
                        : </th> <td className="text-danger" style={{ fontSize: "16px" }}>{(getSimulationPrice.totalFrais).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}</td>
                </tr>
    
                <tr className="table-success">
                    <th className="text-danger">
                        {/* Revenu Net */}
                        {infoSource.language(infoSource, "sellPage", "revNet")} : </th>
                    <td className="text-danger">{(getSimulationPrice.inComeSeller).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
    
           
            </tbody>
        </table>
        
    )


    let shipAmount = parseFloat(0).toFixed(2)

    const objNotEmpty = (obj)=>{
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop))
                return true;
        }
    
        return false;
    }

    const productView = ()=>{
        return <table style={{width: '100%'}}>
          <tr>
     <th colspan="2"><h3>{orders.prod_name}</h3></th>
   </tr>
   <tr style={{width: '100%'}}>
     <td className="col-4 col-sm-3 col-md-2 ">
        <div className="position-absolute top-0">
 {orders && orders.products && <img
         className="embla__slide__img "
             src={orders.products.urlImg}
             onError={event => {
                 event.target.src = imgProductLakay
                 event.onerror = null
               }}
             alt={orders.prod_name}
             style={{width: "100%" }}
         />}</div>
     </td>
     <td className="p-1 md-5">  
             <h6>{orders.prod_Description}</h6>
          {orders && orders.prod_filterProduct && orders.prod_filterProduct.filter &&  < Filters filter={orders.prod_filterProduct.filter} nexLine={true} />}
          </td>
   </tr>
 </table>
    }

    const addTrackNumberBtn =()=>{
        setShowTracking(true)
        listWebhooksShippoElakay(setTrackingObj)
        
    }

    const textButtonProcess = (status)=>{
        if(status === "Not processed"){
          return  infoSource.language(infoSource, "Button", "startProcessing")
        }else if (status === "Processing"){
          return  infoSource.language(infoSource, "Button", "finishProcessing")
        }else{
        return infoSource.language(infoSource, "Button", "viewProcessing")
        }
    }

    const onChangeLabel_tack = (btn)=>{
        if(btn === 'View Tracking'){
            label_Tracking[0] ? setLabel_Tracking([false, false]): setLabel_Tracking([true, false])
        }
         if(btn === 'View Label'){
            label_Tracking[1] ?setLabel_Tracking([false, false]): setLabel_Tracking([false, true])
        }
    }

    const order = () => (
        <div>

            {
                    // <div  className="container mt-3 mb-3 p-0 pb-2">
                    <div>
                    <div className="m-2">
                {productView()}
                    </div>
                   <div style={{ backgroundColor: "#f0f0f0", color: "#000000" }}>
                            
                            <h6 className="p-1 m-0 pl-3" >
                                <span className="p-0 m-0">
                                    {moment(orders.createdAt).fromNow()}
                                    </span>
                            </h6>
                            <div className="row m-0">
                                <div className="col col-10 col-sm-9 col-md-6">
                                    <h5 className="pl-0">
                                        {/* Details de Vente */}
                                        {infoSource.language(infoSource, "sellPage", "DetailsVente")}
                                        </h5>
                                    {PaymentVente()}
                                    <h5>
                                        {/* Details des Achats */}
                                        {infoSource.language(infoSource, "sellPage", "detaiAchats")}
                                        </h5>
                                    {PaymentAchat()}
                                  {  objNotEmpty(orderPayment) && <><h5 className="pl-0">{infoSource.laguangeElakay('title', infoSource.languageAPI).paymentMethods}</h5>
                                    <div className="w-75 bg-white p-2 mb-3">
                                    <h6> <span>{`${orderPayment.b_brand} ending: ${orderPayment.b_last4}`}</span></h6>
                                    <h6> <span>{`Exp: ${orderPayment.b_exp_month} / ${orderPayment.b_exp_year}`} </span></h6>
                                    </div></>}
    </div>
            
                                <div className="col col-10 col-sm-9 col-md-6">
                                   {/* <h5>Details des Achats</h5>
                                   {PaymentAchat()} */}
                                   <h5>
                                    {/* Details Achats & vente */}
                                    {infoSource.language(infoSource, "sellPage", "detAchVent")}
                                    </h5>
                                   {PaymentAchVent()}
                                    <h5 className="pl-0">{infoSource.laguangeElakay('title', infoSource.languageAPI).shippnigAddress}</h5>
                                    <div className="w-75 bg-white px-1 py-2">
                                    <h6 >{ordersShipping.firstName} {ordersShipping.lastName}</h6>
                                    <h6>{ordersShipping.line1}</h6>
                                    <h6>{ordersShipping.city}, {ordersShipping.state}, {ordersShipping.postal_code}</h6>
                                    <h6>{ordersShipping.country}</h6>
                                    </div>
                                <div >
                                {/* <button className="mt-3 mb-3 btn btn-md btn-Ligh btn-block">
                                tchaking number
                </button> */}
                           {orders && orders.products && orders.products.status != "Cancelled" ?   <button onClick={() => addTrackNumberBtn()}  className=" w-75 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4">
                           {textButtonProcess(orders.products.status)}
                                </button> : <button className="w-75 my-3 py-1 btn-outline-red btn-md btn-block rounded-4">
                                    {infoSource.language(infoSource, "label", "statusCancelled")}
                                    </button>}
                                        <button onClick={() => setShowLabel(true)}  className="w-75 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4">
                                        {infoSource.laguangeElakay('Button', infoSource.languageAPI).printLabel}
                </button>
                    </div>
                                    
    </div>
                            </div>
                        </div>

                        <div className="m-2">
  


                        </div>
            
                    </div>
                    //  </div>
        
            }
        
        </div>
    )
const createWebhookBtn = ()=>{
    getTrackingShippingUsps('usps', '420112079470111206204250455198', infoSource.userInfo.email, getOrderID)

}
const clickBtnInfo = ()=>{
    updateOrder(token, getOrderID, {orders: {info}, payment:{amount: getSimulationPrice.getTotal, customer:orders.products.paymentIntent.customer, payment_method :orders.products.paymentIntent.payment_method } }).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
            setInfo(infoValue)
            loadOrders(token, orderID, prodIDI);
        }
    });
}

//    const updateOrdStatus=()=>{
//         updateOrder(token, getOrderID, {orders: {status: "Processing"}}).then(data => {
//             if (data.error) {
//                 console.log(data.error);
//             } else {
              
//             }
//         });
//     }

const handleOrder =name => event =>{
    const value = event.target.value;
    setInfo({...info, [name]: value})
}
  const isDisabledBtn = orders.products && orders.products.paymentIntent.customer === null && orders.products.paymentIntent.payment_method === null;
  
let carriersElakay = ['USPS', 'UPS', 'FedEx', 'DHL eCommerce', 'OnTrac']

const TrakingNumberForm = ()=>{
    let text1 = infoSource.language(infoSource, "text", "textOrderByuer")
        let textLess1 = infoSource.language(infoSource, "text", "textOrderByuerLess")
    return <div className="row m-0">
            <div className="m-2">
                {productView()}
                    </div>
          <ReadMoreLess  infoSource={infoSource} text={text1} textLess={textLess1}/>
        <form>
        <div className="form-group">
                        <label className="text-muted">
                            {/* select your carrier */}
                            {infoSource.language(infoSource, "label", "selectCarrier")}
                            </label>
                        <select onChange={handleOrder('carrier')} className="form-control" value={carrier} >
                            <option value={''} >{infoSource.laguangeElakay('label',  infoSource.languageAPI).pleaseSelect}</option>
                            {carriersElakay.map((car, i)=> <option key={i} value={car}>{car}</option>)}

                        </select> 
                    </div>

                    <div className="form-group">
                        <label className="text-muted">
                            {/* enter or Scan your tracking Number */}
                            {infoSource.language(infoSource, "label", "enterTrackNumber")}
                            </label>
                        <input onChange={handleOrder('tracking')}  type="text"  className="form-control" value={tracking}/>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">
                            {/* copy and paste your url tracking */}
                            {infoSource.language(infoSource, "label", "enterUrlTracking")}
                            </label>
                        <input onChange={handleOrder('urlTracking')} type="url" className="form-control" value={urlTracking} />
                    </div>
                    <div className="form-group">
                        <label className="text-muted">
                            {/* copy and paste your url label Images */}
                            {infoSource.language(infoSource, "label", "enterUrlLabelImages")}
                            </label>
                        <input onChange={handleOrder('url_label')}  type="url" className="form-control" value={url_label} />
                    </div>


        </form>
        <div style={{ display: "flex", alignItems: "center", gap: "20px", margin: "20px" }}>
      {/* Checkbox for View Label */}
      <div>
        <input
          onChange={()=>onChangeLabel_tack("View Label")}
          type="checkbox"
          id={"view_label"}
          className="form-check-input check2"
          checked={label_Tracking[1] ? 'checked' : ''}
        />
        <label className="form-check-label ml-2" htmlFor={"view_label"}>
          {/* View Label */}
          {infoSource.language(infoSource, "label", "viewLabel")}
        </label>
      </div>

      {/* Checkbox for View Tracking */}
      <div>
        <input
          onChange={()=>onChangeLabel_tack("View Tracking")}
          type="checkbox"
          id={"view_tracking"}
          className="form-check-input check2"
          checked={label_Tracking[0] ? 'checked' : ''}
        />
        <label className="form-check-label ml-2" htmlFor={"view_tracking"}>
          {/* View Tracking */}
          {infoSource.language(infoSource, "label", "viewTracking")}
        </label>
      </div>
    </div>
       {urlTracking && label_Tracking[0] && <iframe className="embed-responsive-item" src={urlTracking}  style={{width:'100%', minHeight:"500px"}} title="description">
        </iframe> }
      {url_label && label_Tracking[1] && <div className="col-sm-9 col-md-6 embed-responsive-item">
        <div style={{ width: "100%", height: "100%" }}>
                  
                  <img
                      src={url_label}
                      alt={"labal ship"}
                      style={{ height: "100%", width: "100%" }}
                  />
     
        </div>
        </div>}
        <div> 
        <button onClick={() => setShowTracking(false)}  className="w-25 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4 float-left">
                            {/* cancel */}
                        {infoSource.language(infoSource, "Button", "cancel")}
                </button>
            <SubmitCancelButtonSlider
            cancel={infoSource.language(infoSource, "title", "checkout")+'...'}
            isDisabled={!isDisabledBtn  && checkInfoBool ? false : true}
            coldisplay={'#87CEEB'}
            submitfunction={clickBtnInfo}
            />
           
               {/* { checkInfoBool ?  
               <button  onClick={() => clickBtnInfo()}  className="w-25 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4 float-right">
                
                {infoSource.language(infoSource, 'Button', "submit")}
                </button>:
                 <button  className="w-25 my-3 py-1 btn btn-outline-secondary btn-md btn-block float-right disabled rounded-4">
                 {infoSource.language(infoSource, 'Button', "submit")}
                 </button>
                } */}
        </div>
         
  </div>

}

    const printLabel = () => (
        <div className="container-sm">
            <div className="row justify-content-center " >
                <Modal
                    size="md"
                    show={showLabel}
                    onHide={() => setShowLabel(false)}
                    dialogClassName="modal-100w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title id="example-custom-modal-styling-title"> */}
                          {/* {infoSource.laguangeElakay('title', infoSource.languageAPI).viewdLabelOrder} */}
                        {/* </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>

                        <Printer 
                        infoSource={infoSource}
                        url_label={orders.products.info.url_label}
                        setShowLabel={setShowLabel}
                        />

                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )

    const TrackShow = ()=>{
      return  <Modal
        size="xl"
        show={showTracking}
        fullscreen={true}
        onHide={() => setShowTracking(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       { TrakingNumberForm()}
        </Modal.Body>
    </Modal>
    }
    const orderVieux = () => (

        <Modal
            size="xl"
            show={showOrderView}
            fullscreen={true}
            onHide={() => setShowOrderView(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {order()}
         </Modal.Body>
        </Modal>

)
    return (
           <>
            {/* {OrderCard()} */}
            {TrackShow()}
            {orderVieux()}
            {orders && orders.products && orders.products.info && orders.products.info.url_label && printLabel()}
             {/* <h1>{infoSource.laguangeElakay('title', infoSource.languageAPI).noLabelOrder}</h1> */}
            </>
    );
};

export default Orders;
