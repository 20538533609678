import React, { useState } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const RadioImg = (props) => {
    // const [images, setImages] = useState("");
    // const [textFil, setTextFil] = useState("");
const {
    images,
    setImages,
    textFil, 
    setTextFil,
    infoSource ,
    ImagesProd,
    items, 
    handFilterImage,
    DelFilterImage,
    imageTextDiv
}=props

    const handleChange = (value) => {
        console.log(JSON.stringify({items,  imageTextDiv},  null, '\t', 2))
        setTextFil(value);
        imageTextDiv.splice(0 ,1, value)
        // imageTextDiv.push(event.target.value)
    };
    const handleImages = (a) => {
        setImages(a);
        imageTextDiv.length > 0 ? 
        imageTextDiv.splice(1,1, a):
        imageTextDiv.push("", a)
    };

    const StyleFil = (a, d)=>{
        let b
        if(a == "color"){
            b = {
                display: "inline-block", 
                fontSize: "2.5rem",
                backgroundColor : d, 
                minWidth: '14px',
                minHeight: '14px',
                // width: "30px", 
                // height: "30px", 
                margin: "1px",
                padding: "1px",
                textAlign: "center",  
                border:  "1px solid lightgrey",  
                borderRadius: "50%"
            }
        } 
        return b
    
    }
    const StyleBorImg = (a)=>{
       
          let b 
          let c = items._id.name == "color"? textFil : "lightgrey"
          !a ? b = {
                position: "relative", 
                overflow: "hidden", 
                transition: "0.3s", 
                width: "85px", 
                border: !a && `1px solid ${c}`, 
                padding: "2px"
            }:
            b = {
                position: "relative", 
                overflow: "hidden", 
                transition: "0.3s", 
                width: "85px", 
                padding: "2px"
            }

       
        return b
    
    }

    const imagesSeletCSS =(a)=>{
       
        let x = {
            border: "1px solid lightgrey",
        }
        let y = {
            border: "2px solid #DAA520",
        }
      
       return a === images ? y : x
       
    }

const checKAIncludesB = (a, b)=>{
    return a.some(
        (elem) => JSON.stringify(elem) === JSON.stringify(b)
      )
}
const filterArray = ()=>{
       
   return   <div className="row  mt-4 mb-4">
    <div className="col col-12 col-md-6 p-0 contaire">
        {items.FilterItem.length > 0 ? 

         items.FilterItem.map((k, i)=>(  
        <div key={i}>
            <h5 onClick={()=>handleChange(k)} className="form-check-label" style={{  fontSize: "1.3rem",}}> <span style={StyleFil(items._id.name, k)}></span>   {k}</h5>
        </div>))
        : 
        items._id.FilterItem.map((k,i)=>(  
            <div key={i}>
                <h5 onClick={()=>handleChange(k)} className="form-check-label" for={items._id.name}><span style={StyleFil(items._id.name, k)}></span> {k}</h5>
            </div>))}
            </div>
            <div className="col col-12 col-md-6 p-0 contaire">
            
            <div className="m-2" style={StyleBorImg(items.formInput)} >
                    <div >
                        <img
                            src={imageTextDiv[1]}
                            alt={imageTextDiv[0]}
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        {items.textRead && <div style={{ float: "right", position: "absolute", bottom: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.4)", width: "100%", fontSize: "15px", textAlign: "center"}}>
                            {imageTextDiv[0]}
                        </div>}
                </div>
            </div>
            {imageTextDiv[0] && imageTextDiv[1]?

       checKAIncludesB(items.Images, imageTextDiv)? 
        <button type="button" className="btn btn-danger btn-sm m-1 float-right"  onClick={DelFilterImage}>{infoSource.language(infoSource, 'Button', "delete")}</button>
         :
            <button type="button" className="btn btn-primary btn-sm m-1 float-right"  onClick={handFilterImage}>{infoSource.language(infoSource, 'Button', "submit")}</button>
            
            :
            <button type="button" className="btn btn-primary btn-sm m-1 float-right" disabled>{infoSource.language(infoSource, 'Button', "submit")}</button>}
            </div>
    
            </div>
     
}

const formImages = (a) =>(
    <div className="row  mt-4 mb-4">
        <div className="col col-12 col-md-12  col-xl-10 p-0 contaire" style={{textAlign: "center", border: "1px solid Gainsboro" }} >
            <div className="container">
                <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).allImgItems}</h4>
            <Row xs={4} lg={6}>
                {ImagesProd && ImagesProd.galeries.map((nm, i) => (
                   <Col key={i} >
                    
                    <div className="m-2" style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }} onClick={() => handleImages(nm.urlImg)} >
                    <div style={imagesSeletCSS(nm.urlImg)}>
                        <img
                            src={nm.urlImg}
                            alt={nm._id}
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                            {/* <MdDeleteForever onClick={() => deleteImage(token, productsID, nm._id, a)} /> */}

                        </div>
                    </div>
                    </div>
                </Col>
                ))
                }
                    
            </Row>
            </div>


            {/* {!productAddImage && <button className="btn btn-primary float-right m-3" onClick={AddMoreImage}> {elakayInfo.Button.addMore}</button>} */}

            </div>
    </div>
)

    return (
        <>
       
        {formImages(ImagesProd)}
        {filterArray()}
        </>
    )
};

export default RadioImg; 