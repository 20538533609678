import React, { useState} from "react";

const RadioBoxCondition = (props) => {

    const {
        useC, 
        setUseC,
        newC, 
        setNewC,
        infoSource
    }=props
    // const [useC, setUseC] = useState(false);
    // const [newC, setNewC] = useState(false);

    // const handleToggle = event => {
    //     // return the first index or -1
    //     let c = event.target.value
        
    //     handleFilters(c);
    // };
    const handU = (a) => {

        setNewC(false)
        setUseC(a)

        if(a){
            props.handleFilters("Used");
        }else{
            props.handleFilters("");
        }
    }
    const handN = (a) => {
        setNewC(a)
        setUseC(false)
        if (a) {
            props.handleFilters("New");
        } else {
            props.handleFilters("");
        }
    }
const conditionBox = () =>{
    return   <>
            <input
                onChange={() => handN(newC ? false : true)}
                name="Condition"
                type="radio"
                className="form-check-input mr-2 ml-4"
                checked={newC ? 'checked' : ''}
                id="Condition"
            />
            <label className="form-check-label" for="Condition">
                {/* New */}
                {infoSource.language(infoSource, "label", "new")}
            </label>

            <input
                onChange={() => handU(useC ? false : true)}
                name="Condition"
                type="radio"
                className="form-check-input mr-2 ml-4"
                checked={useC ? 'checked' : ''}
                id="Condition1"
            />
            <label className="form-check-label" for="Condition1">
                {/* Used */}
                {infoSource.language(infoSource, "label", "used")}
                </label>
        
        </>

}
    return (
  conditionBox()
    )
};

export default RadioBoxCondition; 
