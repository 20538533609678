import React, { useState, useEffect } from "react";
import { Row } from 'react-bootstrap';
import { getCategoriesByID, DeletePub } from "../auth/apiCategory";
import { isAuthenticated, Add_ImgPub, userPub, deleteImgPub} from '../auth';
import imgLakay from '../Layout/imgs/Untitled-2.jpg';
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import { elakay_API } from "../../config";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { getLakayUser } from "../auth/apiProduct";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ElakayAccount from '../Layout/imgs/Elakay-account-1.png';
import RoyaltyUser from '../Layout/imgs/Royalty-user-1.png';
import SellerPub1 from '../Layout/imgs/Seller-pub-1.png';
import { language } from "../pageInside/dataElakay";
import { Family } from "../pageInside/Rolle"

import "./imagesLoading.css"
import jsSHA from "jssha";
var sha1 = require('sha1');
const categoyseleted = [];
const categoyIDseleted = [];

const PubShop = props => {
    const {
        imgSize = 0,
        laguangeElakay,
        infoSource,
        userID
        } = props
        const {
            userInfo
        } = infoSource
    const { token } = isAuthenticated();
    const [getUserPub, setUserPub] = useState([]);
    const [getCategoriesUser, setCategoriesUser] = useState([]);
    const [highlight, setHighlight] = useState(false)
    const [maxImgSize, setMaxImgSize] = useState(6)
    const [catID, setCatID] = useState('')
    const [pubInfo, getPubInfo] = useState({
        nameref: 'sliderShop',
        subRef: []
    })
    const[post, setPost]=useState({
        title:"",
        desc:"",
       photos:[]
    })

const onlyFamilly =(only)=>{
 if(only === 'sliderShop'){
        return true
    }else if(Family()){
    return true
}else{
return false
}
}
    useEffect(() => { 
        loadingUserPub(userID)
    }, [userID]);

    const {title, desc, photos} = post
    let sizeImg =  photos.length + imgSize 
    const imagesize = ['imgMa480', 'imgMi768', 'imgMi1024']
    let refObjName = ['avatar',  'Pub Follower', 'Pub Category', 'Btn Image Admin', 'Btn image Follower', 'slider Shop']
    let refObj = ['avatar', 'PubImgFollow', 'PubCategory', 'viewImagAdmin', 'viewImagforFollow', 'sliderShop']
    let Obj = ['avatar', 'PubImgFollow', 'PubCategory', 'viewImagAdmin.imgMa480', 'viewImagAdmin.imgMi768', 'viewImagAdmin.imgMi1024', 'viewImagforFollow.imgMa480', 'viewImagforFollow.imgMi768', 'viewImagforFollow.imgMi1024', 'sliderShop']

    let urlObj = ['user_avatar', 'img_Follow', 'pub_Category', 'img_Admin_Ma480', 'img_Admin_Mi768', 'img_Admin_Mi1024', 'img_forFollow_Ma480', 'img_forFollow_Mi768', 'img_forFollow_Mi1024', 'slider_Shop']

    const loadProductsByArrival = (uid, cid) => {

        getLakayUser(uid, 0, 100, "createdAt", cid).then(data => {
            setCategoriesUser(data.categories)
        }).catch(error => {
            console.log(error);
        });
    };

    const loadingUserPub =(uid)=>{
        userPub(uid).then(data => {
            if(!data){
    console.log('data not Found')
            }else{
        setUserPub(data)
            }
        })
    }

    const handSelectedPub =( b)=>{
        setCatID('')
        setPost({
            title:"",
            desc:"",
            photos:[]
            })
        if(b == 0){

        }else if( b == 1){
            setCategoriesUser([])
            setMaxImgSize(1)
            getPubInfo({
                nameref: refObj[b],
                subRef: []
            })
        
        }else if( b == 2){
            loadProductsByArrival(userInfo._id)
            setMaxImgSize(1)
            getPubInfo({
                nameref: refObj[b],
                subRef: []
            })
        
        }else if (b <= 4){
            setCategoriesUser([])
            setMaxImgSize(3)
            getPubInfo({
                nameref: refObj[b],
                subRef: imagesize 
            })
        }else if (b == 5){
            setCategoriesUser([])
            setMaxImgSize(6)
            getPubInfo({
                nameref: refObj[b],
                subRef: []
            })
        }else {
        }
    }


const laodImageSize = (v, fn)=>{
let w
let h
let imagesizeRef = imagesize[photos.length]
let  refob 
let n
let url_

  if('sliderShop' == v){
    w = 1500
    h = 650
    refob =  v
    n =  Obj.indexOf(refob)
    url_ = urlObj[n] +'/' + userID 
   
  }else if ('viewImagforFollow' == v){

    w = 1500
    h = 500
    refob =  v + '.' + imagesizeRef
    n =  Obj.indexOf(refob)
    url_ = urlObj[n] +'/' + userID
  }else if ('viewImagAdmin' == v){
    w = 1500
    h = 500
    refob =  v + '.' + imagesizeRef
    n =  Obj.indexOf(refob)
    url_ = urlObj[n] +'/' + userID
  }else{
    w = 500
    h = 500
    refob =  v
    n =  Obj.indexOf(refob)
    url_ = urlObj[n] +'/' + userID
  }
return {w, h, refob, n, url_}
}
const checkedListe = (value)=>{
let ix =  refObj.indexOf(pubInfo['nameref'])
return refObjName[ix] == value ? true : false
}
const handSelectedCatId =(catid)=>{
    setCatID(catid)
}
const selectProfitTargetPrice =()=>{
    return  <>
        
           { refObjName.map((ob, i) =>{
            return  i > 0 && <h5  className="m-1 w-75" style={{ borderBottom: "1px solid #258ecd", }}> 
                    <input 
                    type="radio"
                    className="form-check-input"
                    key={i} 
                    onChange={()=>handSelectedPub(i)} 
                    checked={checkedListe(ob)  ? 'checked' : ''} 
                    // name="ProfitPrice"
                    id={"refObjName_" + ob}
                     />
                       <label className="form-check-label mx-2" for={"refObjName_" + ob}>&#160;&#160;{ob}</label> 
                    </h5>
           })} 
       </>
}

const selectedGauche =()=>{
   return <div>
    {getCategoriesUser && getCategoriesUser.map((cat, i) => (
            <h5  className="m-1"  style={{borderBottom: "1px solid #258ecd"}}>
                <input 
                type="radio"
                className="form-check-input"
                key={i} 
                onChange={()=>handSelectedCatId(cat._id)} 
                checked={catID == cat._id  ? 'checked' : ''} 
                
                name="catSelected" 
                id={cat._id}
                />
                <label className="form-check-label" for={cat._id}>&#160;&#160;{cat.name.slice(0, -1).join(', ')}&#160;&#160;</label>
        {catID == cat._id &&    <img 
        style={{ width: "40px" }} 
        src={imgProductLakay} 
        alt="firt Step" 
        />}
        
            </h5>      
    ))}
</div>
}
let images = [
    ElakayAccount,
    RoyaltyUser,
    SellerPub1
]
const SliderPubUser = () => (
    <div className="mt-2">
  <Swiper
    spaceBetween={30}
    centeredSlides={true}
    loop={true}
    autoplay={{
      delay: 5000,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
    }}
    // navigation={true}
    navigation ={infoSource && infoSource.navigation}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    {
          images.map((each, index) => (
            <SwiperSlide key={index}>
                <img key={index} style={{ width: "100%", maxHeight: "400px" }} src={each} alt="firt Step" />
            </SwiperSlide>
        ))
    }
  </Swiper>
    </div>
)
const prodFollowALL =()=>{
    
    return <div className="card m-4 p-0">
    <table style={{width: '100%'}}>
        <tr>
            <td className="w-25 p-0">
        <div className="d-grid gap-2 mx-auto">
        <button className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg">{infoSource.laguangeElakay('Button', infoSource.languageAPI).viewlakay} </button>
          </div>
             </td>
            <td>
            <img style={{ width: "100%", maxHeight: "200px" }} src={imgLakay} alt="firt Step" />
             </td>
             </tr>
           </table>
            </div>
}

const AdminFallow =()=>{
       
    return <div className="card m-1 p-1 m-4">
    <table style={{width: '100%'}}>
        <tr>
       
            <td>
            <img style={{ width: "100%", maxHeight: "200px" }} src={imgLakay} alt="firt Step" />
             </td> 
             <td className="w-25 p-0">
            <div className="d-grid gap-2 mx-auto">
            <button className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg"> {infoSource.laguangeElakay('Button', infoSource.languageAPI).viewlakay}  </button>
            </div> 
             </td>
             </tr>
           </table>
            </div>
}

const showAlliste = ()=>{
  return  <div className="container ">
    <div className="row d-flex align-items-center justify-content-center"> 
        <div className="col col-12 col-md-6" >
            {selectProfitTargetPrice()}
        </div>
        <div className="col col-12 col-md-6" >
       {'PubImgFollow' == refObj[refObj.indexOf(pubInfo['nameref'])] && <img className="rounded mx-auto d-block" 
        style={{ width: "50%" }} 
        src={imgProductLakay} 
        alt="firt Step" 
        />}
            {selectedGauche()}
            {'viewImagAdmin' == refObj[refObj.indexOf(pubInfo['nameref'])] && AdminFallow()}
            {'viewImagforFollow' == refObj[refObj.indexOf(pubInfo['nameref'])] && prodFollowALL()}
            {'sliderShop' == refObj[refObj.indexOf(pubInfo['nameref'])] && SliderPubUser()}
        </div>
        </div>
        </div>

}
    const handlefilechange = e =>{
        let files = e.target.files;
        handleFiles(files)
        }

        const handleFiles=(files)=>{
            let arrPhotos = [];
            let sizeArr
            for (let file of files){
                 let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', ()=>{
                    let fileobj ={
                        file: file,
                        w: laodImageSize(pubInfo['nameref'], files.length).w,
                        h:laodImageSize(pubInfo['nameref'], files.length).h,
                        n: laodImageSize(pubInfo['nameref'], files.length).n,
                        userID,
                        type: file.type,
                        size: file.size,
                        src: reader.result
                    }
                
                arrPhotos.push(fileobj)
                if(( sizeImg+ files.length) > maxImgSize){
                    sizeArr = files.length -  ((sizeImg + files.length) - maxImgSize)
                }else{
                    sizeArr =   files.length
                }
                setPost({
                    ...post,
                    photos:[...photos, ...arrPhotos.slice(0, sizeArr)]
                })
                });
            }
        }

        const handledelete = e =>{
            let target = e.target.parentElement;
            let tagetIndex = target.dataset.imgindex * 1;
        setPost({
        ...post,
        photos:[...photos.slice(0, tagetIndex), ...photos.slice(tagetIndex + 1)]
        })
            
        }

    const handlehighlight = e =>{
        e.preventDefault();
        e.stopPropagation();
        setHighlight(true);
        }
        
        const handleunhighlight = e =>{
            e.preventDefault();
            e.stopPropagation();
            setHighlight(false);
        }
        
        const handledrop = e =>{
            e.preventDefault();
            e.stopPropagation();
            setHighlight(false);
            let dt = e.dataTransfer;
            let files = dt.files
            handleFiles(files)
        }

        const imagesSeletCSS =(a = 0)=>{
       
            let x = {
                border: "2px solid green",
            }
         
            let y = {
                border: "4px solid red",
            }
        
           return 500000 < a ? y : x
           
        }

        
        
        function handleSubmit() {
            let i = 0
            photos.map(f => {

                if ('viewImagforFollow' == pubInfo['nameref']){
                    if (i < 2) { i++
                        f.n = f.n + i
                     }
                }else if ('viewImagAdmin' == pubInfo['nameref']){
                    if (i < 2) { i++
                        f.n = f.n + i
                    }
                }
                Add_ImgPub(token, f.w, f.h, f.n, f.file, catID, f).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setPost({
                    title:"",
                    desc:"",
                    photos:[]
                    })
                    loadingUserPub(userID)
                }
            })})  
        }

    const activeBtnBool =( sp, v)=>{
        if ('viewImagforFollow' == v){
            return sp == 3 ?true: false
        }else if ('viewImagAdmin' == v){
            return sp == 3 ?true: false
        }else{
            return  sp > 0 ?true: false
        }

    }
    const checkImgSizeBtn =(a)=>{
return a.length > 0 && a.every(a=> a.size <  500000)? true : false
    }
const checkIfAddImg =(a)=>{
    if (a == 'PubCategory' ){
        return catID == ''? true :false
    }else{
       return a == '' ? true :false
    }
}

const inputError =(info, h)=>(
    <div className={"custom-file-drop-area redlight"}>
    <label htmlFor="filephotos">
{h ?<h1>{info}</h1> : info}
     </label>
</div> 
)
    const ImagesForm = () => (
        <div className="row justify-content-center">
        <div className="col col-12 col-md-10" >
        <div className="file-upload">
        {/* <h2>{namProduct}</h2> */}
        <h2 className="text-warning">{infoSource.laguangeElakay('title', infoSource.languageAPI).Psfai} </h2>
        <form className="" encType= "multipart/form-data"> 
        <div className="custom-file-preview">
                  {photos.length > 0 && photos.map((a, i) =>(
                    <div className="prev-img" key={i} data-imgindex={i} style={imagesSeletCSS(a.size)}>
                        <span onClick={handledelete}>&times;</span>
                        <img className="imgLoad" src={a.src} alt={a.name}/>
                    </div>
                    ))}
                </div>
            <div className="custom-form-group">
         {  checkIfAddImg(pubInfo['nameref'])?
         !onlyFamilly(pubInfo['nameref']) ? inputError(infoSource.laguangeElakay('title',  infoSource.languageAPI).comingSoon, true): inputError(language(infoSource,'label', 'dragDropImgSelc'), false)
    
       :
         sizeImg < maxImgSize 
         ?
         !onlyFamilly(pubInfo['nameref'])
         ? 
         inputError(infoSource.laguangeElakay('title',  infoSource.languageAPI).comingSoon, true):
                <div className={highlight? "custom-file-drop-area highlight": "custom-file-drop-area"} 
                onDragEnter={handlehighlight}
                onDragOver={handlehighlight}
                onDragLeave={handleunhighlight}
                onDrop={handledrop}
                >
                    <input type="file" name="photos" placeholder="Enter photos" multiple id="filephotos" onChange={handlefilechange} accept="image/*"/>
                    <label htmlFor="filephotos">{sizeImg}  
                        {infoSource.laguangeElakay('label', infoSource.languageAPI).imagesof} {maxImgSize} 
                        <br/> {infoSource.laguangeElakay('Button', infoSource.languageAPI).add} {maxImgSize- sizeImg} {infoSource.laguangeElakay('label', infoSource.languageAPI).morimagesnot}<br/> {infoSource.laguangeElakay('label', infoSource.languageAPI).dragDrop} </label>
                </div>

                :

                inputError(language(infoSource,'label', 'canDragDrop'), false)
               
                }
               
            </div>
        
        </form>
  <div>
   {activeBtnBool(photos.length , pubInfo['nameref']) && checkImgSizeBtn(photos) ?
    <button  className="btn btn-primary float-right pb-2" onClick={handleSubmit}>{infoSource.language(infoSource, 'Button', "submit")}</button>
   :
    <button  className="btn btn-primary float-right pb-2 disabled" >{infoSource.language(infoSource, 'Button', "submit")}</button>}
  </div>

 </div>
 </div>
     </div>

    )

const deleteimgPubFonc =(n, urlImg)=>{
    deleteImgPub(token, n, urlImg ).then(data => {
        if (!data) {
            console.log('image can not delete');
        } else {
            setPost({
            title:"",
            desc:"",
            photos:[]
            })
            loadingUserPub(userInfo._id)
        }
    })  
}

const showPubImgFollow =()=>{
 
    return getUserPub &&  getUserPub.PubImgFollow && getUserPub.PubImgFollow.urlImg && <div className="container mt-3 mb-3">
        <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
        <h4>{refObjName[1]}</h4>
            </div>
            <Row xs={2} sm={3} md={4} lg={5} xl={6}>
                    <div className="p-1">
                        <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                    <img
                        className="rounded"
                        src={getUserPub.PubImgFollow.urlImg}
                        alt={refObjName[1]}
                        style={{ width: "100%" }}
                    />
                    <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                <MdDeleteForever onClick={()=> deleteimgPubFonc(1, getUserPub.PubImgFollow.urlImg)}/>
                    </div>
            </div>
            </div> 
            </Row>   
    </div>
}

const showPubCategory =()=>{
 
    return getUserPub && getUserPub.PubCategory && getUserPub.PubCategory.length > 0 && <div className="container mt-3 mb-3">
        <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
        <h4>{refObjName[2]}</h4>
            </div>
            <Row xs={2} sm={3} md={4} lg={5} xl={6}>
            {
                   getUserPub.PubCategory.map((p, i)=>(
                        <div  key={i} className="p-1">
                            <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                        <img
                            className="rounded"
                            src={p.urlImg}
                            alt={refObjName[2]+ ': ' + i}
                            style={{ height: "100%", width: "100%" }}
                        />
                        <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                    <MdDeleteForever onClick={()=> deleteimgPubFonc(2, p.urlImg)}/>

                        </div>
                </div>
                </div>
                    ))
                   
            }  
            </Row>
    </div>
}


const showImagAdmin =()=>{
    //  let url0 =  refObjName[4].imagesize[0]
        return getUserPub && getUserPub.viewImagAdmin  && <div className="container mt-3 mb-3">
            <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
            <h4>{refObjName[3]}</h4>
                </div>
                <Row xs={2}  md={3}>
            
              {getUserPub.viewImagAdmin.imgMa480 && getUserPub.viewImagAdmin.imgMa480.urlImg &&  <div className="p-1">
                <h6>
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).imageM480}
                    {/* image Min 480 */}
                    </h6> 
                 <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                            <img
                                className="rounded"
                                src={getUserPub.viewImagAdmin.imgMa480.urlImg}
                                alt={refObjName[4]+ ' ' + imagesize[0]}
                                style={{ height: "100%", width: "100%" }}
                            />
                            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                        <MdDeleteForever  onClick={()=> deleteimgPubFonc(3, getUserPub.viewImagAdmin.imgMa480.urlImg)}/>
    
                            </div>
                    </div>
                    </div>}
            {getUserPub.viewImagAdmin.imgMi768 && getUserPub.viewImagAdmin.imgMi768.urlImg &&  <div className="p-1">
                 <h6>{infoSource.laguangeElakay('title', infoSource.languageAPI).imageM768}</h6> 
                <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                            <img
                                className="rounded"
                                src={getUserPub.viewImagAdmin.imgMi768.urlImg}
                                alt={refObjName[4]+ ' ' + imagesize[1]}
                                style={{ height: "100%", width: "100%" }}
                            />
                            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                            <MdDeleteForever  onClick={()=> deleteimgPubFonc(3, getUserPub.viewImagAdmin.imgMi768.urlImg)}/>
    
                            </div>
                    </div>
                    
                    </div>}
            {getUserPub.viewImagAdmin.imgMi1024 && getUserPub.viewImagAdmin.imgMi1024 &&  <div className="p-1">
                <h6>
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).imageM1024}
                    {/* image Min 1024 */}
                    </h6>    
                  <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                            <img
                                className="rounded"
                                src={getUserPub.viewImagAdmin.imgMi1024.urlImg}
                                alt={refObjName[4]+ ' ' + imagesize[2]}
                                style={{ height: "100%", width: "100%" }}
                            />
                            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                            <MdDeleteForever  onClick={()=> deleteimgPubFonc(3, getUserPub.viewImagAdmin.imgMi1024.urlImg)}/>
    
                            </div>
                    </div>
                    </div>}
                    
                </Row>
        </div>
    }


const showImagforFollow =()=>{
//  let url0 =  refObjName[4].imagesize[0]
    return getUserPub && getUserPub.viewImagforFollow && getUserPub.viewImagforFollow  && <div className="container mt-3 mb-3">
        <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
        <h4>{refObjName[4]}</h4>
            </div>
            <Row xs={2}  md={3}>
        
            {getUserPub.viewImagforFollow.imgMa480 && getUserPub.viewImagforFollow.imgMa480.urlImg &&  <div className="p-1">
                <h6>
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).imageM480}
                    {/* image Min 480 */}
                    </h6> 
                 <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                            <img
                                className="rounded"
                                src={getUserPub.viewImagforFollow.imgMa480.urlImg}
                                alt={refObjName[4]+ ' ' + imagesize[0]}
                                style={{ height: "100%", width: "100%" }}
                            />
                            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                        <MdDeleteForever onClick={()=> deleteimgPubFonc(4, getUserPub.viewImagforFollow.imgMa480.urlImg)}/>
    
                            </div>
                    </div>
                    </div>}
            {getUserPub.viewImagforFollow.imgMi768 && getUserPub.viewImagforFollow.imgMi768.urlImg &&  <div className="p-1">
                 <h6>
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).imageM768}
                    {/* image Min 768 */}
                    </h6> 
                <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                            <img
                                className="rounded"
                                src={getUserPub.viewImagforFollow.imgMi768.urlImg}
                                alt={refObjName[4]+ ' ' + imagesize[1]}
                                style={{ height: "100%", width: "100%" }}
                            />
                            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                        <MdDeleteForever onClick={()=> deleteimgPubFonc(4, getUserPub.viewImagforFollow.imgMi768.urlImg)}/>
    
                            </div>
                    </div>
                    
                    </div>}
            {getUserPub.viewImagforFollow.imgMi1024 && getUserPub.viewImagforFollow.imgMi1024 &&  <div className="p-1">
                <h6>
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).imageM1024} 
                    {/* image Min 1024 */}
                    </h6>    
                  <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                            <img
                                className="rounded"
                                src={getUserPub.viewImagforFollow.imgMi1024.urlImg}
                                alt={refObjName[4]+ ' ' + imagesize[2]}
                                style={{ height: "100%", width: "100%" }}
                            />
                            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                        <MdDeleteForever onClick={()=> deleteimgPubFonc(4, getUserPub.viewImagforFollow.imgMi1024.urlImg)}/>
    
                            </div>
                    </div>
                    </div>}
                
            </Row>
    </div>
}



const showSliderShop =()=>{
 
    return getUserPub && getUserPub.sliderShop && getUserPub.sliderShop.length > 0 && <div className="container mt-3 mb-3">
        <div className="col text-center" style={{ backgroundColor: "WhiteSmoke" }}>
        <h4>{refObjName[5]}</h4>
            </div>
            <Row xs={2}  md={3}>
            {
                   getUserPub.sliderShop.map((p, i)=>(
                        <div  key={i} className="p-1">
                            <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                        <img
                            className="rounded"
                            src={p.urlImg}
                            alt={refObjName[5]+ ': ' + i}
                            style={{ height: "100%", width: "100%" }}
                        />
                        <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                    <MdDeleteForever onClick={()=> deleteimgPubFonc(5, p.urlImg)}/>

                        </div>
                </div>
                </div>
                    ))
                    }  
            </Row>
    </div>
}
    return (
        <div>
{/* {btnCreatPub()} */}
{showAlliste()}
{ImagesForm()}
{showPubImgFollow()}
{showPubCategory()}
{showImagAdmin()}
{showImagforFollow()}
{showSliderShop()}
        </div>
    )
};

export default PubShop;