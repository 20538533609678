import React, { useState } from "react";
import { Link, Navigate } from 'react-router-dom';
import { CheckEmail, isAuthenticated} from "../includes/auth";
import Form from 'react-bootstrap/Form'
import logoElakay from '../includes/Layout/icon/LogoElakay.svg';
import './user.css';

const Signin = (props) => {

    const {
        infoSource
    }=props
    const [values, setValues] = useState({
        email: "",
        error: "",
        message:"",
        success: false,
    });

    const { email, error, message, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, success: false, [name]: event.target.value });
    };
    if (isAuthenticated()) {
        return <Navigate to="/" />;
    }
    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false, success: false, loading: true });
        CheckEmail({ email})
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false });
                } else {
                    setValues({
                        ...values,
                        message: data.message,
                        success: true
                    });
                
                }
            });
      
    };

    const signUpForm = () => (


        <div>
            <div className="usHeader">
                <Link to="/"><h1><img className="img-responsive" src={logoElakay} width="24" alt="logo elakay"/>elakay</h1></Link>
            </div> <br />


            <Form className="form-signin" action="" method="post">
                <h4 className="text-center">{infoSource.laguangeElakay('signinPage', infoSource.languageAPI).title1}</h4>
                {showSuccess()}
                {showError()}
                <div className="form-label-group">
                    <Form.Control onChange={handleChange("email")} type="email" value={email} name="email" id="inputEmail" className="form-control" placeholder="Email address" required />
                    <Form.Label htmlFor="inputEmail">{infoSource.laguangeElakay('label', infoSource.languageAPI).email}</Form.Label>
                </div>


                <input type="hidden" name="verication" placeholder="" />
                <div className="elakay_signin_input">

                </div>
        

                <button onClick={clickSubmit} className="btn btn-lg btn-block" type="submit" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).checkEmail}
                    </button>
                <Link className="btn btn-lg btn-block mt-3" to="/signin" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}
                </Link>

            </Form>
        </div>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
        {message} <Link to="/signin">
        {infoSource.laguangeElakay('Button', infoSource.languageAPI).signin}
        </Link>
        </div>
    );
  
    return (
        <div>

            {signUpForm()}

        </div>
    );

};

export default Signin;
