import React, { useState} from "react";
import imgLog3 from '../../imgs_steps/IMG_003.png';
import { Family} from "../../pageInside/Rolle"
import Modal from 'react-bootstrap/Modal';
import Pages from '.'

var fileAdding ={
    title:'',
    error:''   
}

const FormPageDataMail = (props) => {

    const { 
        laguangeElakay,
        infoSource,
        editAddByJson= false,
        getDataEmail,
        editAdd, 
        setEditAdd,
        nameObj,
        paragra,
        setParagra,
        ObjetPage,
        edit_add,
        token,
        Alllanguage,
        AlllanguageSelect,
        languageUsertra,
        languageTradictor,
        updateLanguageElakay0,
        deleteLanguageElakay0,
        updateLanguageFileUp,
        buttonAddLanguage
    }= props

    const [getNewDataEmail, setNewDataEmail] = useState({});
    const [] = useState(false)
    const [priObj, setPriObj]= useState()
    const [objet, setObejet] = useState()

    const inputOK = (a, b, c)=>{
        if(c == undefined){
            return  {v : b,  s: 'form-control'}
        }else  if(c == {}){
          return {v :"",  s: 'form-control is-invalid'}
      }else if(checkUndefName(c, a)){
          return {v : c[a],  s: "form-control is-valid" }
        }else{
          return {v :"",  s: 'form-control is-invalid'}
        }
      
      }

      const buttonAdd =()=>{
        let data = objet
            
        updateLanguageElakay0(languageTradictor._id, token, {[nameObj]: {_id: getNewDataEmail._id, data}}, Family()).then(data => {
            if (data.error) {
               console.log(data.error)
            } else {
                setNewDataEmail({})
                setObejet()
                setPriObj()
                AlllanguageSelect()
                languageUsertra()
                // setEditAdd(true)
                // setParagra(ObjetPage)    
        }
        })
    }
    const allSetData = (obj)=>{
        setNewDataEmail(obj)
        setObejet(obj.data)
    }
    const selTraDataMail =(title)=>{
        languageTradictor[nameObj].filter(obj => obj.data.title == title &&  allSetData(obj))
    }

    const TraDataMailBool =(array = [], title)=>{
        return array.filter(obj => obj.data.title  == title)[0] ? true : false
     }

    const handleTilteData = () => event => {
        const value = event.target.value; 
        setPriObj(getDataEmail[value].data)
        // TraDataMailBool(getDataEmail[value].data.title)
       value != 0 ? 
                    TraDataMailBool(languageTradictor[nameObj], getDataEmail[value].data.title)? 
                                selTraDataMail(getDataEmail[value].data.title) 
                                            : setObejet(getDataEmail[value].data) 
                         :  allSetData({})
    //    value != 0 && selTraDataMail(getDataEmail[value].data.title)
    };

    const handleChange = name => event => {
        const value = event.target.value;
        let pname = name[0]
        let sname = name[1]
    
        if(name.length == 2){
            setObejet({ ...priObj, [pname] : {...priObj[pname], [sname]: value}});
        
        }else{
            setObejet({ ...priObj, [pname]: value});
        }
    
    };
    const checkIfFunction = (f)=>{
        return typeof f === 'object' ? true : false
      }

const selectObArData =()=>{
    return <div className="form-group">
           <label className="text-muted">
            {/* select languages and compare */}
            data title for edit 
            </label>
           <select onChange={handleTilteData()} className="form-control" >
           <option value={0} > select data title</option>
           {getDataEmail && getDataEmail.map((ob, j) =>{
                return ob.data.title != 'about us' && <option value={j} >{ob.data.title}</option>
               })}
           </select>
       </div>

}

const checkUndefName = (a, b)=>{
    let aa  = a && Object.keys(a)
    return  a && aa.includes(b)
   } 

   const selNewName = (a, b)=>{
    return a? a : b
}

    const formInput1 = (a ,b, i, akey, c)=> {
        let inpClass = c == undefined ? "form-control" : checkUndefName(c, a) ? "form-control is-valid" : 'form-control is-invalid'
        return <div key = {i} className="form-group">
         <label className="text-muted">{a}</label>
         {b.length > 90 ?
         <textarea onChange={handleChange([akey, a])}  type="text" className={inpClass} placeholder={selNewName(objet[a], b)} value={selNewName(objet[a], '')} rows="4"/>
         :
             <input onChange={handleChange([akey, a])}  type="text" className={inpClass} placeholder={selNewName(objet[a], b)} value={selNewName(objet[a], '')} />
            }
         </div>

    }

    const formInput0 = (a ,b, i, c)=>{
        let oe1 = Object.entries(b)
         let inpClass = c == undefined ? "form-control" : checkUndefName(c, a) ? "form-control is-valid" : 'form-control is-invalid'
       return a != "title" && <div key={i} className="p-3">
             <label className="text-muted">{checkIfFunction(b)? <h4>{a}</h4> : a }</label>
             {checkIfFunction(b)?
             <div style={{ border: "1px solid WhiteSmoke" }} className="p-3"> 
            { oe1.map(([key, val], i) => { return formInput1(key, val, i, a)})}
             </div>
             :
           <div key = {i} className="form-group p-3">
                {b.length > 90 ?
                 <textarea onChange={handleChange([a])}  type="text" className={inpClass} placeholder={selNewName(objet[a], b)} value={selNewName(objet[a], '')} rows="4"/>
                    :
                <input onChange={handleChange([a])}  type="text" className={inpClass} placeholder={selNewName(objet[a], b)} value={selNewName(objet[a], '')} />
                }
            </div>
            }
        </div>
    }

    const ArrayFormInput = ()=>{
        let oe = priObj && Object.entries(priObj)

       return <div>
                {priObj && oe.map(([key, val], i) => { return formInput0(key, val, i)})}
                <div className="row justify-content-center">
                <div className="col col-sm-12">
                <button  className="btn btn-primary float-right pb-2" onClick={()=>buttonAdd()}>save</button>
            </div> </div> 
            </div> 
    }

    return (<>
    {(selectObArData())}
       {ArrayFormInput()}
         </>)

}

export default  FormPageDataMail