import React from 'react';
// Import Swiper React components
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import UserProdFollow from '../../pages/lakayUser/userProdFollow';
import CatPubProducts from './cardUserProd'
import PubProducts from '../../pages/lakayUser/pubProduct';
import CateryName from '../../pageInside/cateryName';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import '../styles.css';

// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination,  Virtual } from 'swiper/modules';

const SwiperCatUserProd = (props) => {
  const {
    title = "Your Follow Shop",
    infoSource,
    myStyles,
    nbrArr = 4,  
    wm0 = 2,
    wm640 = 2,
    wm768 = 3,
    wm1024= 3,
   wm1200 = 3,
   // shop user categories
   userID,
   pubCategoryProd,
   shop = false,
   inView= false,
   setSearch,
   setCi,
   setShopUser
  } = props
  const user_id =(u)=>{
    return u._id._id ? u._id._id: u._id
}

let classSlider = props.classSlider ? props.classSlider : "styles.sliderProduct" 
let shopUser = 'lakay'
const divPubCategoryProd =()=>{

}
  return (
    <div className={`mt-4  p-2 ${classSlider}`} style={myStyles} >
      <div><span className="h5">{inView && title}</span></div>
      <Swiper
           style={{
            "--swiper-navigation-color": "#007399",
            "--swiper-pagination-color": "#007399",
          }}
        cssMode={true}
        slidesPerView={wm0}
        spaceBetween={10}
        slidesPerGroup={2}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
            568: {
              slidesPerView: wm640,
              spaceBetween: 10
            },
            768: {
              slidesPerView: wm768,
              spaceBetween: 10
            },
            1024: {
              slidesPerView: wm1024,
              spaceBetween: 10
            },
            1200: {
              slidesPerView: wm1200,
              spaceBetween: 10
            }
          }}
        scrollbar={true}
        navigation ={infoSource && infoSource.navigation}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Keyboard, Scrollbar, Pagination, Navigation,  Virtual]}
        className="mySwiper"
      >
{
 shop?

   pubCategoryProd && pubCategoryProd.map((items, i) => (
    <SwiperSlide key={i}>
    <div className='card' style={{ textAlign: 'left'}}> 
       <CateryName items={items} />
           <PubProducts
        infoSource ={infoSource}
        setShopUser={setShopUser}
        userID={userID}
        categoryID={user_id(items)}
        />
    <h6 className='ps-3'>
    {
    inView?
    <Link onClick={()=>{setCi(user_id(items), items.cateryName); setSearch('')}}>
    {infoSource.language(infoSource, "Link", "seeMore")}
    </Link>
    :
    <Link to={`/lakay-ShopSeller/${userID}?ci=${user_id(items)}`}>
    {infoSource.language(infoSource, "Link", "seeMore")}
    </Link>
    }
    </h6>
    </div>
   
    </SwiperSlide>
     ))
 :
infoSource.userInfo && infoSource.userInfo.followShopUser && infoSource.userInfo.followShopUser.map((items, i)=>{
  return <>
    
    <SwiperSlide key={i}>
    {/* <div className='card m-1'  style={{ textAlign: 'left'}}>  */}
    <UserProdFollow
    infoSource={infoSource}
    userID={items._id}
    /> 
  
     {/* <h6 className='ps-3'>{items.name && items.name[0]} <Link to={`/lakay-ShopSeller/${items._id}`}>See More</Link></h6>
    </div> */}
    </SwiperSlide>
    </> 
})  
}


      </Swiper>
      </div>
  );
}

export default SwiperCatUserProd;