import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url_label : props.url_label
        };
    }


    render() {
        const {url_label } = this.state

        const print = () => (
           
          

                <div style={{ width: "100%", height: "100%" }}>
                  
                    <img
                        src={url_label}
                        alt={"labal ship"}
                        style={{ height: "100%", width: "100%" }}
                    />
                 
                    {/* <h1>{infoSource.laguangeElakay('title', infoSource.languageAPI).noLabelOrder}</h1> */}
                  
                  
                </div>

            )

        return (
            <div>
                {print()}
            </div>
        );
    }
}

const Example = props => {
    const { url_label,  infoSource, setShowLabel} = props
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const printPage = () => (
        <ComponentToPrint 
        url_label={url_label}
        ref={componentRef}
        />
    )

    return (
        <div className="container" style={{ width: "100%" ,  overflow: 'scroll'}}>
            {printPage()}
            <button onClick={() => setShowLabel(false)}  className="w-25 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4 float-left">
                            {/* cancel */}
                        {infoSource.language(infoSource, "Button", "cancel")}
            </button>
            <button className="w-50 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4 float-right" onClick={handlePrint}>
            {infoSource.language(infoSource, 'Button', "printThisOut")}
                </button>
        </div>
    );
};

export default Example;