// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
/* 
.btn-circle {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
}

.btn-circle i {
    position: relative;
    top: -1px;
}

.btn-circle-sm {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 0.9rem;
}

.btn-circle-lg {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.1rem;
}

.btn-circle-xl {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 1.3rem;
} */
.cardFontSise {
    margin-left: 0px;
    font-size: min(max(12px, 4vw), 16px);
    padding-left: 2px;
    /* text-align: center; */
}
.cardFontSise0 {
    font-size: min(max(10px, 4vw), 19px);
}
/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/`, "",{"version":3,"sources":["webpack://./src/includes/pageInside/Card.css"],"names":[],"mappings":"AAAA;;;;;;CAMC;AACD;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAkCG;AACH;IACI,gBAAgB;IAChB,oCAAoC;IACpC,iBAAiB;IACjB,wBAAwB;AAC5B;AACA;IACI,oCAAoC;AACxC;AACA;;;;;;CAMC","sourcesContent":["/*\n*\n* ==========================================\n* CUSTOM UTIL CLASSES\n* ==========================================\n*\n*/\n/* \n.btn-circle {\n    width: 45px;\n    height: 45px;\n    line-height: 45px;\n    text-align: center;\n    padding: 0;\n    border-radius: 50%;\n}\n\n.btn-circle i {\n    position: relative;\n    top: -1px;\n}\n\n.btn-circle-sm {\n    width: 35px;\n    height: 35px;\n    line-height: 35px;\n    font-size: 0.9rem;\n}\n\n.btn-circle-lg {\n    width: 55px;\n    height: 55px;\n    line-height: 55px;\n    font-size: 1.1rem;\n}\n\n.btn-circle-xl {\n    width: 70px;\n    height: 70px;\n    line-height: 70px;\n    font-size: 1.3rem;\n} */\n.cardFontSise {\n    margin-left: 0px;\n    font-size: min(max(12px, 4vw), 16px);\n    padding-left: 2px;\n    /* text-align: center; */\n}\n.cardFontSise0 {\n    font-size: min(max(10px, 4vw), 19px);\n}\n/*\n*\n* ==========================================\n* FOR DEMO PURPOSES\n* ==========================================\n*\n*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
