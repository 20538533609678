import React, { useState} from "react";
import Layout from "../Layout/Layout";
import { signout, isAuthenticated} from "../auth";
import nodata from '../Layout/imgs/nodata0.png';



const Message = (props) => {
    const {
        infoSource
        } = props

    const [loading, setLoading] = useState(false);




    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            loading={loading}
            SliderPunShow={false}
        >
    <div style={infoSource.styleNav}>
     <div className="row mt-4 mb-4 justify-content-center">
                  <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
                   </div>
        </div>
        </Layout>
    );
};

export default Message;