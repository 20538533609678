import React,{useState} from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Empty from "../Layout/icon/Empty.png";
import { MdDeleteForever } from "react-icons/md";
import { linkImagesToProduct } from "../auth/apiProduct";
import { language } from "../pageInside/dataElakay";

const getFiels = (a ,b)=>{
    var c = []
    if(a.length > 0){
    for (var i = 0; i <  a.length; i++)
    if(!c.includes(a[i][b])){
     c.push(a[i][b])
    }}
        return c
    }
    const objToArr = (a)=>{
        return a && {v: Object.values(a), k: Object.keys(a)}
    }

    function isObjectNullOrEmpty(obj) {
        // Vérifie si l'objet est nul ou indéfini
        if (obj === null || obj === undefined) {
            return true;
        }
    
        // Vérifie si l'objet est un type qui peut être vérifié comme vide (objets ou tableaux)
        if (typeof obj === 'object' && !Array.isArray(obj)) {
            return Object.keys(obj).length === 0; // Vérifie si l'objet n'a pas de clés
        }
    
        // Vérifie si c'est un tableau vide
        if (Array.isArray(obj)) {
            return obj.length === 0; // Vérifie si le tableau est vide
        }
    
        // Sinon, considère tout autre type comme non vide
        return false;
    }

const LinkImagesTofilterPro = props => {
    const [filterItem, setFilterItem] = useState({});
    const [firterName, setfirterName] = useState('');
const {
    laguangeElakay,
    infoSource,
    filtersProduct =[],
    setImagesSMS,
    imagesSms,
    imagesArray,
    setImagesArray,
    filterSel =[],
    productID,
    token,
}= props

const handleChange = event => {
    setfirterName(event.target.value);
    // imageTextDiv.push(event.target.value)
};
const deleteImgArr =(b)=>{
    setImagesArray(imagesArray.filter(item => item !== b));
    setImagesSMS("")
}

function handleSubmit() {
    linkImagesToProduct(token, productID, {name: firterName, images: imagesArray}).then(data => {
        if (data.error) {
            // console.log(data.error);
        } else {
            setImagesArray([])
            setfirterName('')
           ;

        }
    })
}

const selectFilter = ()=>{
    return <div >
    
        {
        !isObjectNullOrEmpty(filterItem)?
        <>
            <h5>
            {filterItem._id.name}
       </h5>
       {filterItem.FilterItem.length > 0 ? 
              filterItem.FilterItem.map((k, i )=>(  
                <div key={i}>
                    <input
                        onChange={handleChange}
                        value={k}
                        name={filterItem._id.name}
                        type="radio"
                        className="form-check-input mr-2 ml-4"
                        id={i+"_"+k}
                    />
                    <label className="form-check-label" for={i+"_"+k} style={{  fontSize: "1.3rem",}}> <span ></span>   {k}</label>
                </div>))
                : 
                filterItem._id.FilterItem.map((k, i)=>(  
                    <div key={i}>
                        <input
                            onChange={handleChange}
                            value={k}
                            name={filterItem._id.name}
                            type="radio"
                            className="form-check-input mr-2 ml-4"
                            id={i+"_"+k}
                        />
                        <label className="form-check-label" for={i+"_"+k}><span ></span> {k}</label>
                    </div>))
}
        </>
        :
        <>
      {  filterSel && filterSel.map((c, i)=> (
              <h5 key={i} onClick={()=>setFilterItem(c)}>
              {c._id.name}
         </h5>
        ))}
            <div>
            <input
                onChange={handleChange}
                value={"all Filter"}
                name={"filterItem._id.name"}
                type="radio"
                className="form-check-input mr-2 ml-4"
                id={"allFilIt_" }
            />
            <label 
            className="form-check-label" for={"allFilIt_" }
            >
                {/* For all Filter Items */}
                {language(infoSource, "label", "allFilIt")}
                </label>
            </div>
        </>
    }
</div>}

const imagesSelected = (a)=>{
return <div className="container">
   {a.length > 0 ?
<>
<h6>{!isObjectNullOrEmpty(filterItem) ? filterItem._id.name  : 'No filter'} : <span style={{color:"red", fontWeight: "bold"}}>{firterName}</span> 
{' '}
{/* link to those Images */}
{language(infoSource, "label", "lithImg")}
</h6>
 <Row xs={3} md={3}>
    {a && a.map((nm, i) => (
       <Col key={i} >
        <div className="m-1" style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}> 
            <img
                src={nm}
                alt={nm}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
            <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                <MdDeleteForever onClick={()=>deleteImgArr(nm)} />

            </div>
        </div>
    </Col>
    ))}      
</Row></> 
:
<div className="justify-content-center mt-2">
    <h6>
        {/* Images linking filter */}
        {language(infoSource, "label", "ImgLinFil")}
        </h6>
<img
    src={Empty}
    alt={"empty Images"}
    style={{width: "120px" }}
    />
</div>
    }
</div>

}

const selectFomr = ()=>{

    
  return<div className="container p-2"><div className="row mt-4 p-4 justify-content-center" style={{ border: "1px solid Gainsboro" }}>
   <div className="col col-12 col-md-10 p-2">
   {filterSel.length > 0?
   <div className="row">
    <div className="col col-12 col-md-6 p-2">
   {selectFilter()}
   <button type="button" className="btn btn-light btn-sm" onClick={()=>{setFilterItem({}); setfirterName("")}}>
        {/* close */}
        {language(infoSource, "Button", "clear")}
        </button>
   </div>
   <div className="col col-12 col-md-6 p-2">
  { imagesSelected(imagesArray)} 
  { imagesArray.length > 0 && !isObjectNullOrEmpty(filterItem) && firterName?
    <button  className="btn btn-primary float-right pb-2" onClick={handleSubmit}>{infoSource.language(infoSource, 'Button', "submit")}</button>
   :
    <button  className="btn btn-primary float-right pb-2 disabled" >{infoSource.language(infoSource, 'Button', "submit")}</button>}
  {imagesSms && <p style={{color:"green", fontWeight: "bold"}}>
    {imagesSms}
    </p>}
  </div>
 
   </div>
    :
    <>
    <h2 style={{textAlign:"center", color:"LightGrey"}}>
        {/* You can not link any image to filter */}
        {language(infoSource, "label", "canLinImgFil")}
        </h2>
    <h6 style={{textAlign:"center", color:"LightGrey"}}>
        {/* No filter Preview add yet */}
        {language(infoSource, "label", "filPreAddYet")}
        </h6>
    </>
    }
    </div>

    </div>
       </div>
}




return selectFomr()


}
export default LinkImagesTofilterPro;