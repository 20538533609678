import React, { useEffect } from "react";
import Card from '../pageInside/Card'
import { Row } from 'react-bootstrap';
import { isAuthenticated} from "../auth";
import ButtonPagination from "./ButtonPagination";

const UserProductDeal = props => {
    const { token } = isAuthenticated();

    const {
        laguangeElakay,
        infoSource,
        DealProduct,
        setProductaddToDeal,
        setShowSideDeal,
        showSideDeal,
        productsByArrival,
        loadProductsByArrival,
        loadM,
        loadM0,
        siseProduct,
        categoryID,
        filterCategory,
        text,
        size,
        skip,
        limit
    }= props

    const loadProducts = (token, Deal, catID) => {
        loadProductsByArrival(token, Deal, catID )
    };

    useEffect(() => {
        loadProducts(token, DealProduct, categoryID)
    }, [token, DealProduct, categoryID]);

    const loadMore = () => {
        loadM()
    };

    const loadMore0 = () => {
        loadM0()
    };


    const productALL = (
        <Row className="m-1" xs={2} sm={2} md={3} lg={4} xl={5}>
            {
                productsByArrival.map((product, i) => (
                    <div className="col p-2" key={i}>
                        <Card
                            laguangeElakay={laguangeElakay}
                            infoSource ={infoSource}
                            loadProductsByArrival={loadProductsByArrival}
                            product={product}
                            showAddToCartButton={false}
                            showButtonUser={true}
                            setProductaddToDeal={setProductaddToDeal}
                            setShowSideDeal={setShowSideDeal}
                            showSideDeal={showSideDeal}
                            classCard0 = "col p-0"
                            DealProduct={DealProduct}
                            showShopV={false}
                            skip={skip}
                            limit={limit}
                        />
                </div>
                ))}

        </Row>
    )
    const resultPagination = (
        <div>
            {(skip - limit) + limit + 1} - {((skip + limit) > siseProduct) ? siseProduct : (skip + limit)}
             {/* of  */}
             {infoSource.language(infoSource, "label", "of")}
             {siseProduct} 
             items
        </div>
    )


    return (
 <div>
            {resultPagination}
            {filterCategory()}
            {productALL}
            < ButtonPagination
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                size={size}
                skip={skip}
                limit={limit}
                siseProduct={siseProduct}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />
</div>
    )
      
};

export default UserProductDeal;