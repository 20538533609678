// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toogle_button{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1010;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 5px;
    box-sizing: border-box;
}

.toogle_button:focus{
    outline: none;
}

.toggle-button_line{
width: 25px;
height: 4px;
background: white;
}

.toggle-button_line1{
    width: 25px;
    height: 4px;
    background: black;
    }


`, "",{"version":3,"sources":["webpack://./src/components/SIdeDrawer/DrawerToggleButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,aAAa;IACb,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,UAAU;IACV,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;AACA,WAAW;AACX,WAAW;AACX,iBAAiB;AACjB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB","sourcesContent":[".toogle_button{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    z-index: 1010;\n    height: 24px;\n    width: 30px;\n    background: transparent;\n    border: none;\n    cursor: pointer;\n    padding: 0;\n    margin: 5px;\n    box-sizing: border-box;\n}\n\n.toogle_button:focus{\n    outline: none;\n}\n\n.toggle-button_line{\nwidth: 25px;\nheight: 4px;\nbackground: white;\n}\n\n.toggle-button_line1{\n    width: 25px;\n    height: 4px;\n    background: black;\n    }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
