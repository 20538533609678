import React from "react";
import Header from "./Header"
import MenuSliderBar from "../slider/menubarSlider";
import SiderDrawer from "../../components/SIdeDrawer/SideDrawer";
import SiderDrawerFilter from "../../components/SIdeDrawer/SideDrawer1";
import Backdrop from '../../components/Backdrop/Backdrop';


class Sidebar  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sideDrawerOpen: false,
            sideDrawerOpen1: false
        }
    };

    drawerToggleClickHander = () => {
        this.setState((prevState) => {
           
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    }

    drawerToggleClickFilter = () => {
        this.setState((prevState) => {
            return { sideDrawerOpen1: !prevState.sideDrawerOpen }
        })
    }

    drawerToggleClickAddToDeal = () => {
        this.setState((prevState) => {
            
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    }

    dackdropClickHander = () => {
        this.props.userShop && this.props.setFilterShop(false)
        this.setState({ sideDrawerOpen: false });
        this.setState({ sideDrawerOpen1: false });
    }

    render() {
          const {
            e1,
            categoriesID,
            handleFilters,
            prices,
            setMyFilters, 
            infoSource,
            AllCategorie,
            filterShop,
            getCalFilShop,
            userShop = false,
            outOffPage = false,
            allHeaderShop,
            infoHeaderShop = null
        } = this.props
        // let sideDrawer
        let backdrop

        if (this.state.sideDrawerOpen || this.state.sideDrawerOpen1 || filterShop) {
            backdrop = <Backdrop click={this.dackdropClickHander} />
        }
        return (
            <div>
                 {outOffPage ?
                 allHeaderShop(this.drawerToggleClickHander)
                 :
                <Header infoSource={infoSource} drawerClickHander={this.drawerToggleClickHander} />
                }
                <MenuSliderBar infoSource={infoSource} sliderShow={true} drawerClickHander={this.drawerToggleClickFilter} />

                <SiderDrawer infoSource={infoSource}  show={this.state.sideDrawerOpen} click={this.dackdropClickHander} />
                <SiderDrawerFilter
                    AllCategorie={AllCategorie}
                    infoSource={infoSource}
                    show={this.state.sideDrawerOpen1}
                    filterShop={filterShop}
                    e1={e1}
                    categoriesID={categoriesID}
                    handleFilters={handleFilters}
                    click={ this.dackdropClickHander}
                    prices={prices}
                    setMyFilters={setMyFilters}
                    getCalFilShop={getCalFilShop}
                    userShop={userShop}
                />

                {backdrop}
            </div>
        )
} }

export default Sidebar;