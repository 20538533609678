import React from "react";
import { Link } from "react-router-dom";
const Pages = (props) => {
    const ObjetPage = {
        title:'',
        text:'',
        list:'',
        video:'',
        thumbnail:'',
        image: ''
    }

    const { 
        classAlert,
        classText = 'col-12 col-md-9',
        classImg = 'col-12 col-md-10',
        classPara = 'row mt-4 mb-4 justify-content-center',
        paragra = ObjetPage,
        imageVideo = '',
        paragra0 = [],
        paraALLBoo = false,
        stylePara
    }= props
    
const {
    href,
    link,
    title,
    text,
    list,
    video,
    image
} = paragra
    


// ************************************************************************

const videoLoad = (a)=>{
    return   a && <video width="100%" controls autoplay muted>
                <source src={a} type="video/mp4" />
            </video>

}

const imageLoad = (a)=>{
    return  a && <img className="shadow-lg" style={{ width: "100%" }} src={a} alt="firt Step" />
}


const imageVideoLoad = (a, b, c)=>{
    if(a == 'image'){
      return imageLoad(b)
    }else if(a == 'video'){
      return videoLoad(c)
    }else{
       return null
    }
}

const listArrayForm = (a, t = 'a') =>{
 
    if(t == 'ul'){
      return  <ul type={t}>   
        { a.length > 0  && a.map((a,i)=>
            <li key={i}>
                   {a}  
               </li>
           )}
       </ul>
    }
    else{ 
      return  <ol type={t}>   
   { a.length > 0  && a.map((a,i)=>
       <li key={i}>
              {a}  
          </li>
      )}
  </ol>  
  } 

}

const titlePage = (t, n)=>{
    if(n == 1){
        return <h1>{t}</h1>
    }else if(n == 2){
        return <h2>{t}</h2>
    }else if(n == 3){
       return <h3>{t}</h3>
    }else if(n == 4){
        return <h4>{t}</h4>
    }else if(n == 5){
        return <h5>{t}</h5>
    }else if(n == 6){
        return <h6>{t}</h6>
    }else{
return <p><strong>{t}</strong></p>
    }
}

const stringToArray = (a)=>{
  
    if(Array.isArray(a)){
    return a.length == 1 && a[0] == '' ? []: a
    }else if(a != ''){
    return a.split(/\r?\n/)
    }
    else{
     return []
    }
   
}
const checkObjEmpty =(obj)=>{
if(Object.values(obj ? obj: {}).length > 0){
    return true
}else {
    return false
}

}

let keyImVd =  imageVideo ? imageVideo : paragra.VORI
let valueImg = image && image.src ? image.src: paragra.imagePath
let valueVid = video && video.src ? video.src: paragra.videoPath

const viewParagra = ()=>{
return <div className={classPara}>
    <div className={classText}>
   {/* <h3>{title}</h3> */}
   {titlePage(title, stylePara[0])}
   { stringToArray(text).map((a,i)=>{
   return  <p key={i}> {a}  </p>
   })} 
  {listArrayForm(stringToArray(list), stylePara[1])}
    </div>
    <div className={classImg}>
        {imageVideoLoad(keyImVd, valueImg, valueVid)}
    </div>
    <div className={classText}>
    {checkObjEmpty(link) && <Link to={link.href} >{link.title}</Link>}
    {checkObjEmpty(href) && <a href={href.href} >{href.title}</a>}
    </div>
    </div>
}
const viewAllParagraph =(obj)=>{
    return <>
       <div className={classText}>
            {obj.title.length > 3 && titlePage(obj.title, obj.stylePara[0])}
           {obj.text.map((a,i)=>{return <p key={i}> {a}  </p> })}
           {listArrayForm(obj.list, obj.stylePara[1])}
       </div>
       <div className={classImg}>
           {imageVideoLoad(obj.VORI, obj.imagePath, obj.videoPath)}
       </div>
       <div className={classText}>
       {checkObjEmpty(obj.link) && <Link to={obj.link.href} >{obj.link.title}</Link>}
        {checkObjEmpty(obj.href) && <a href={obj.href.href} >{obj.href.title}</a>}
        </div>
   </>
   }
   const viewParagra0 = ()=>{
    return <> {paragra0.length > 0 && paragra0.map((ob, j) =>{
          return <div key={j} className={classPara}>
           {viewAllParagraph(ob)}<br/>
           </div> 
        })}
        {/* {viewParagra()} */}
        </>
   }

const paragraALL = ()=>{
return <div className={classAlert}> 
        {paraALLBoo ? viewParagra() : viewParagra0()}
    </div>
}
    return paragraALL()

}

export default  Pages