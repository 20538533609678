export const shippingData = [
    {
        "USA": {
          "USPS": {
            "First-Class Mail": {
              "E-commerce": {
                "Standard": {
                  "Price": "$3 - $5",
                  "Delivery Time": "1-3 days"
                },
                "Account Holder": {
                  "Price": "$2.50 - $4.50",
                  "Delivery Time": "1-3 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$2.80 - $4.80",
                  "Delivery Time": "1-3 days"
                },
                "Account Holder": {
                  "Price": "$2.30 - $4.30",
                  "Delivery Time": "1-3 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$3.20 - $5.20",
                  "Delivery Time": "1-3 days"
                },
                "Account Holder": {
                  "Price": "$2.70 - $4.70",
                  "Delivery Time": "1-3 days"
                }
              }
            },
            "Priority Mail": {
              "E-commerce": {
                "Standard": {
                  "Price": "$7 - $15",
                  "Delivery Time": "1-3 days"
                },
                "Account Holder": {
                  "Price": "$6.50 - $14.50",
                  "Delivery Time": "1-3 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$6.80 - $14.80",
                  "Delivery Time": "1-3 days"
                },
                "Account Holder": {
                  "Price": "$6.30 - $14.30",
                  "Delivery Time": "1-3 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$7.20 - $15.20",
                  "Delivery Time": "1-3 days"
                },
                "Account Holder": {
                  "Price": "$6.70 - $14.70",
                  "Delivery Time": "1-3 days"
                }
              }
            }
          },
          "UPS": {
            "UPS Ground": {
              "E-commerce": {
                "Standard": {
                  "Price": "$10 - $20",
                  "Delivery Time": "1-5 days"
                },
                "Account Holder": {
                  "Price": "$9 - $19",
                  "Delivery Time": "1-5 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "1-5 days"
                },
                "Account Holder": {
                  "Price": "$8.50 - $18.50",
                  "Delivery Time": "1-5 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$10.50 - $20.50",
                  "Delivery Time": "1-5 days"
                },
                "Account Holder": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "1-5 days"
                }
              }
            }
          },
          "FedEx": {
            "FedEx Ground": {
              "E-commerce": {
                "Standard": {
                  "Price": "$10 - $20",
                  "Delivery Time": "1-5 days"
                },
                "Account Holder": {
                  "Price": "$9 - $19",
                  "Delivery Time": "1-5 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "1-5 days"
                },
                "Account Holder": {
                  "Price": "$8.50 - $18.50",
                  "Delivery Time": "1-5 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$10.50 - $20.50",
                  "Delivery Time": "1-5 days"
                },
                "Account Holder": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "1-5 days"
                }
              }
            }
          }
        },
        "Canada": {
          "Canada Post": {
            "Regular Parcel": {
              "E-commerce": {
                "Standard": {
                  "Price": "$10 - $20",
                  "Delivery Time": "2-9 days"
                },
                "Account Holder": {
                  "Price": "$9 - $19",
                  "Delivery Time": "2-9 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "2-9 days"
                },
                "Account Holder": {
                  "Price": "$8.50 - $18.50",
                  "Delivery Time": "2-9 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$10.50 - $20.50",
                  "Delivery Time": "2-9 days"
                },
                "Account Holder": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "2-9 days"
                }
              }
            },
            "Xpresspost": {
              "E-commerce": {
                "Standard": {
                  "Price": "$20 - $40",
                  "Delivery Time": "1-2 days"
                },
                "Account Holder": {
                  "Price": "$18 - $38",
                  "Delivery Time": "1-2 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$19 - $39",
                  "Delivery Time": "1-2 days"
                },
                "Account Holder": {
                  "Price": "$17 - $37",
                  "Delivery Time": "1-2 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$21 - $41",
                  "Delivery Time": "1-2 days"
                },
                "Account Holder": {
                  "Price": "$19 - $39",
                  "Delivery Time": "1-2 days"
                }
              }
            }
          },
          "Purolator": {
            "Purolator Ground": {
              "E-commerce": {
                "Standard": {
                  "Price": "$15 - $25",
                  "Delivery Time": "1-7 days"
                },
                "Account Holder": {
                  "Price": "$14 - $24",
                  "Delivery Time": "1-7 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$14.50 - $24.50",
                  "Delivery Time": "1-7 days"
                },
                "Account Holder": {
                  "Price": "$13.50 - $23.50",
                  "Delivery Time": "1-7 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$15.50 - $25.50",
                  "Delivery Time": "1-7 days"
                },
                "Account Holder": {
                  "Price": "$14.50 - $24.50",
                  "Delivery Time": "1-7 days"
                }
              }
            }
          },
          "Canpar Express": {
            "Canpar Ground": {
              "E-commerce": {
                "Standard": {
                  "Price": "$10 - $20",
                  "Delivery Time": "2-10 days"
                },
                "Account Holder": {
                  "Price": "$9 - $19",
                  "Delivery Time": "2-10 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "2-10 days"
                },
                "Account Holder": {
                  "Price": "$8.50 - $18.50",
                  "Delivery Time": "2-10 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$10.50 - $20.50",
                  "Delivery Time": "2-10 days"
                },
                "Account Holder": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "2-10 days"
                }
              }
            }
          },
          "Loomis Express": {
            "Loomis Ground": {
              "E-commerce": {
                "Standard": {
                  "Price": "$10 - $20",
                  "Delivery Time": "2-7 days"
                },
                "Account Holder": {
                  "Price": "$9 - $19",
                  "Delivery Time": "2-7 days"
                }
              },
              "Wholesale": {
                "Standard": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "2-7 days"
                },
                "Account Holder": {
                  "Price": "$8.50 - $18.50",
                  "Delivery Time": "2-7 days"
                }
              },
              "Retail": {
                "Standard": {
                  "Price": "$10.50 - $20.50",
                  "Delivery Time": "2-7 days"
                },
                "Account Holder": {
                  "Price": "$9.50 - $19.50",
                  "Delivery Time": "2-7 days"
                }
              }
            }
          }
        }
      },


    // 2eme object dans array
    {
        "USA": {
          "USPS": {
            "services": {
              "First-Class Mail": {
                "ecommerce": {
                  "standard": "$3 - $5",
                  "professional_account": "$2.80 - $4.75"
                },
                "wholesale": {
                  "standard": "$3 - $5",
                  "professional_account": "$2.80 - $4.75"
                },
                "retail": {
                  "standard": "$3 - $5",
                  "professional_account": "$2.80 - $4.75"
                },
                "delivery_time": "1-3 days"
              },
              "Priority Mail": {
                "ecommerce": {
                  "standard": "$7 - $15",
                  "professional_account": "$6.50 - $14.50"
                },
                "wholesale": {
                  "standard": "$7 - $15",
                  "professional_account": "$6.50 - $14.50"
                },
                "retail": {
                  "standard": "$7 - $15",
                  "professional_account": "$6.50 - $14.50"
                },
                "delivery_time": "1-3 days"
              },
              "Priority Mail Express": {
                "ecommerce": {
                  "standard": "$25 - $40",
                  "professional_account": "$23 - $38"
                },
                "wholesale": {
                  "standard": "$25 - $40",
                  "professional_account": "$23 - $38"
                },
                "retail": {
                  "standard": "$25 - $40",
                  "professional_account": "$23 - $38"
                },
                "delivery_time": "1-2 days"
              },
              "Parcel Select": {
                "ecommerce": {
                  "standard":  "$7 - $20",
                  "professional_account": "$6 - $18"
                },
                "wholesale": {
                  "standard":  "$7 - $20",
                  "professional_account": "$6 - $18"
                },
                "retail": {
                  "standard":  "$7 - $20",
                  "professional_account": "$6 - $18"
                },
                "delivery_time": "2-9 days"
              },
              "Media Mail": {
                "ecommerce": {
                  "standard":  "$3 - $6",
                  "professional_account": "$2.50 - $5"
                },
                "wholesale": {
                  "standard":  "$3 - $6",
                  "professional_account": "$2.50 - $5"
                },
                "retail": {
                  "standard":  "$3 - $6",
                  "professional_account": "$2.50 - $5"
                },
                "delivery_time": "2-8 days"
              }
            }
          },
          "UPS": {
            "services": {
              "UPS Ground": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "1-5 days"
              },
              "UPS 3 Day Select": {
                "ecommerce": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "wholesale": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "retail": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "delivery_time": "3 days"
              },
              "UPS 2nd Day Air": {
                "ecommerce": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "wholesale": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "retail": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "delivery_time": "2 days"
              },
              "UPS Next Day Air": {
                "ecommerce": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "wholesale": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "retail": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "delivery_time": "1 day"
              }
            }
          },
          "FedEx": {
            "services": {
              "FedEx Ground": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "1-5 days"
              },
              "FedEx Home Delivery": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "1-5 days"
              },
              "FedEx Express Saver": {
                "ecommerce": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "wholesale": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "retail": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "delivery_time": "3 days"
              },
              "FedEx 2Day": {
                "ecommerce": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "wholesale": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "retail": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "delivery_time": "2 days"
              },
              "FedEx Standard Overnight": {
                "ecommerce": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "wholesale": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "retail": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "delivery_time": "1 day"
              }
            }
          },
          "DHL eCommerce": {
            "services": {
              "SmartMail Parcel": {
                "ecommerce": {
                  "standard": "$5 - $10",
                  "professional_account": "$4.50 - $9"
                },
                "wholesale": {
                  "standard": "$5 - $10",
                  "professional_account": "$4.50 - $9"
                },
                "retail": {
                  "standard": "$5 - $10",
                  "professional_account": "$4.50 - $9"
                },
                "delivery_time": "2-8 days"
              },
              "SmartMail Parcel Plus": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "2-8 days"
              }
            }
          },
          "OnTrac": {
            "services": {
              "Ground Shipping": {
                "ecommerce": {
                  "standard": "$8 - $15",
                  "professional_account": "$7 - $14"
                },
                "wholesale": {
                  "standard": "$8 - $15",
                  "professional_account": "$7 - $14"
                },
                "retail": {
                  "standard": "$8 - $15",
                  "professional_account": "$7 - $14"
                },
                "delivery_time": "1-3 days"
              },
              "Sunrise Gold": {
                "ecommerce": {
                  "standard": "$15 - $30",
                  "professional_account": "$13 - $27"
                },
                "wholesale": {
                  "standard": "$15 - $30",
                  "professional_account": "$13 - $27"
                },
                "retail": {
                  "standard": "$15 - $30",
                  "professional_account": "$13 - $27"
                },
                "delivery_time": "1 day"
              },
              "DirectPost": {
                "ecommerce": {
                  "standard": "$5 - $10",
                  "professional_account": "$4.50 - $9"
                },
                "wholesale": {
                  "standard": "$5 - $10",
                  "professional_account": "$4.50 - $9"
                },
                "retail": {
                  "standard": "$5 - $10",
                  "professional_account": "$4.50 - $9"
                },
                "delivery_time": "2-8 days"
              }
            }
          }
        },
        "Canada": {
          "Canada Post": {
            "services": {
              "Regular Parcel": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "2-9 days"
              },
              "Xpresspost": {
                "ecommerce": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "wholesale": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "retail": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "delivery_time": "1-2 days"
              },
              "Priority": {
                "ecommerce": {
                  "standard": "$30 - $60",
                  "professional_account": "$27 - $54"
                },
                "wholesale": {
                  "standard": "$30 - $60",
                  "professional_account": "$27 - $54"
                },
                "retail": {
                  "standard": "$30 - $60",
                  "professional_account": "$27 - $54"
                },
                "delivery_time": "1 day"
              },
              "Expedited Parcel": {
                "ecommerce": {
                  "standard": "$15 - $25",
                  "professional_account": "$13.50 - $22.50"
                },
                "wholesale": {
                  "standard": "$15 - $25",
                  "professional_account": "$13.50 - $22.50"
                },
                "retail": {
                  "standard": "$15 - $25",
                  "professional_account": "$13.50 - $22.50"
                },
                "delivery_time": "1-7 days"
              }
            }
          },
          "Purolator": {
            "services": {
              "Ground": {
                "ecommerce": {
                  "standard": "$15 - $25",
                  "professional_account": "$13.50 - $22.50"
                },
                "wholesale": {
                  "standard": "$15 - $25",
                  "professional_account": "$13.50 - $22.50"
                },
                "retail": {
                  "standard": "$15 - $25",
                  "professional_account": "$13.50 - $22.50"
                },
                "delivery_time": "1-7 days"
              },
              "Express": {
                "ecommerce": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "wholesale": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "retail": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "delivery_time": "1-2 days"
              },
              "Same Day": {
                "ecommerce": {
                  "standard": "$50 - $100",
                  "professional_account": "$45 - $90"
                },
                "wholesale": {
                  "standard": "$50 - $100",
                  "professional_account": "$45 - $90"
                },
                "retail": {
                  "standard": "$50 - $100",
                  "professional_account": "$45 - $90"
                },
                "delivery_time": "1 day"
              }
            }
          },
          "Canpar Express": {
            "services": {
              "Ground": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "2-10 days"
              },
              "Select": {
                "ecommerce": {
                  "standard": "$15 - $30",
                  "professional_account": "$13.50 - $27"
                },
                "wholesale": {
                  "standard": "$15 - $30",
                  "professional_account": "$13.50 - $27"
                },
                "retail": {
                  "standard": "$15 - $30",
                  "professional_account": "$13.50 - $27"
                },
                "delivery_time": "1-5 days"
              },
              "Express": {
                "ecommerce": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "wholesale": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "retail": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "delivery_time": "1-2 days"
              }
            }
          },
          "Loomis Express": {
            "services": {
              "Ground": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "2-7 days"
              },
              "Loomis Express": {
                "ecommerce": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "wholesale": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "retail": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "delivery_time": "1-2 days"
              },
              "Priority": {
                "ecommerce": {
                  "standard": "$30 - $60",
                  "professional_account": "$27 - $54"
                },
                "wholesale": {
                  "standard": "$30 - $60",
                  "professional_account": "$27 - $54"
                },
                "retail": {
                  "standard": "$30 - $60",
                  "professional_account": "$27 - $54"
                },
                "delivery_time": "1 day"
              }
            }
          },
          "FedEx Canada": {
            "services": {
              "FedEx Ground": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "1-7 days"
              },
              "FedEx Economy": {
                "ecommerce": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "wholesale": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "retail": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "delivery_time": "2-4 days"
              },
              "FedEx Priority Overnight": {
                "ecommerce": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "wholesale": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "retail": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "delivery_time": "1 day"
              },
              "FedEx 2Day": {
                "ecommerce": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "wholesale": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "retail": {
                  "standard": "$25 - $50",
                  "professional_account": "$23 - $45"
                },
                "delivery_time": "2 days"
              }
            }
          },
          "UPS Canada": {
            "services": {
              "UPS Standard": {
                "ecommerce": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "wholesale": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "retail": {
                  "standard": "$10 - $20",
                  "professional_account": "$9 - $18"
                },
                "delivery_time": "2-7 days"
              },
              "UPS Express Saver": {
                "ecommerce": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "wholesale": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "retail": {
                  "standard": "$25 - $50",
                  "professional_account": "$22.50 - $45"
                },
                "delivery_time": "1-2 days"
              },
              "UPS Expedited": {
                "ecommerce": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "wholesale": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "retail": {
                  "standard": "$20 - $40",
                  "professional_account": "$18 - $36"
                },
                "delivery_time": "2-5 days"
              },
              "UPS Express": {
                "ecommerce": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "wholesale": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "retail": {
                  "standard": "$50 - $80",
                  "professional_account": "$45 - $75"
                },
                "delivery_time": "1 day"
              }
            }
          }
        }
      },
      
  // 3 2eme object dans array
  {
    "shipping_rates": {
      "USA": {
        "USPS": {
          "First-Class Mail": {
            "estimated_cost": "$3 - $5",
            "delivery_time": "1-3 days",
            "professional_account": "discounted rates available"
          },
          "Priority Mail": {
            "estimated_cost": "$7 - $15",
            "delivery_time": "1-3 days",
            "professional_account": "discounted rates available"
          },
          "Priority Mail Express": {
            "estimated_cost": "$25 - $40",
            "delivery_time": "1-2 days",
            "professional_account": "discounted rates available"
          },
          "Parcel Select": {
            "estimated_cost": "$7 - $20",
            "delivery_time": "2-9 days",
            "professional_account": "discounted rates available"
          },
          "Media Mail": {
            "estimated_cost": "$3 - $6",
            "delivery_time": "2-8 days",
            "professional_account": "discounted rates available"
          }
        },
        "UPS": {
          "Ground": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "1-5 days",
            "professional_account": "discounted rates available"
          },
          "3 Day Select": {
            "estimated_cost": "$20 - $40",
            "delivery_time": "3 days",
            "professional_account": "discounted rates available"
          },
          "2nd Day Air": {
            "estimated_cost": "$25 - $50",
            "delivery_time": "2 days",
            "professional_account": "discounted rates available"
          },
          "Next Day Air": {
            "estimated_cost": "$50 - $80",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          }
        },
        "FedEx": {
          "Ground": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "1-5 days",
            "professional_account": "discounted rates available"
          },
          "Home Delivery": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "1-5 days",
            "professional_account": "discounted rates available"
          },
          "Express Saver": {
            "estimated_cost": "$20 - $40",
            "delivery_time": "3 days",
            "professional_account": "discounted rates available"
          },
          "2Day": {
            "estimated_cost": "$25 - $50",
            "delivery_time": "2 days",
            "professional_account": "discounted rates available"
          },
          "Standard Overnight": {
            "estimated_cost": "$50 - $80",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          }
        },
        "DHL eCommerce": {
          "SmartMail Parcel": {
            "estimated_cost": "$5 - $10",
            "delivery_time": "2-8 days",
            "professional_account": "discounted rates available"
          },
          "SmartMail Parcel Plus": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "2-8 days",
            "professional_account": "discounted rates available"
          }
        },
        "OnTrac": {
          "Ground Shipping": {
            "estimated_cost": "$8 - $15",
            "delivery_time": "1-3 days",
            "professional_account": "discounted rates available"
          },
          "Sunrise Gold": {
            "estimated_cost": "$15 - $30",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          },
          "DirectPost": {
            "estimated_cost": "$5 - $10",
            "delivery_time": "2-8 days",
            "professional_account": "discounted rates available"
          }
        }
      },
      "Canada": {
        "Canada Post": {
          "Regular Parcel": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "2-9 days",
            "professional_account": "discounted rates available"
          },
          "Xpresspost": {
            "estimated_cost": "$20 - $40",
            "delivery_time": "1-2 days",
            "professional_account": "discounted rates available"
          },
          "Priority": {
            "estimated_cost": "$30 - $60",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          },
          "Expedited Parcel": {
            "estimated_cost": "$15 - $25",
            "delivery_time": "1-7 days",
            "professional_account": "discounted rates available"
          }
        },
        "Purolator": {
          "Ground": {
            "estimated_cost": "$15 - $25",
            "delivery_time": "1-7 days",
            "professional_account": "discounted rates available"
          },
          "Express": {
            "estimated_cost": "$25 - $50",
            "delivery_time": "1-2 days",
            "professional_account": "discounted rates available"
          },
          "Same Day": {
            "estimated_cost": "$50 - $100",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          }
        },
        "Canpar Express": {
          "Ground": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "2-10 days",
            "professional_account": "discounted rates available"
          },
          "Select": {
            "estimated_cost": "$15 - $30",
            "delivery_time": "1-5 days",
            "professional_account": "discounted rates available"
          },
          "Express": {
            "estimated_cost": "$25 - $50",
            "delivery_time": "1-2 days",
            "professional_account": "discounted rates available"
          }
        },
        "Loomis Express": {
          "Ground": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "2-7 days",
            "professional_account": "discounted rates available"
          },
          "Loomis Express": {
            "estimated_cost": "$20 - $40",
            "delivery_time": "1-2 days",
            "professional_account": "discounted rates available"
          },
          "Priority": {
            "estimated_cost": "$30 - $60",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          }
        },
        "FedEx Canada": {
          "Ground": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "1-7 days",
            "professional_account": "discounted rates available"
          },
          "Economy": {
            "estimated_cost": "$20 - $40",
            "delivery_time": "2-4 days",
            "professional_account": "discounted rates available"
          },
          "Priority Overnight": {
            "estimated_cost": "$50 - $80",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          },
          "2Day": {
            "estimated_cost": "$25 - $50",
            "delivery_time": "2 days",
            "professional_account": "discounted rates available"
          }
        },
        "UPS Canada": {
          "Standard": {
            "estimated_cost": "$10 - $20",
            "delivery_time": "2-7 days",
            "professional_account": "discounted rates available"
          },
          "Express Saver": {
            "estimated_cost": "$25 - $50",
            "delivery_time": "1-2 days",
            "professional_account": "discounted rates available"
          },
          "Expedited": {
            "estimated_cost": "$20 - $40",
            "delivery_time": "2-5 days",
            "professional_account": "discounted rates available"
          },
          "Express": {
            "estimated_cost": "$50 - $80",
            "delivery_time": "1 day",
            "professional_account": "discounted rates available"
          }
        }
      }
    }
  }   

]


export const shippingData2 = {
    "USA": {
        "USPS": {
            "First-Class Mail": {
                "ecommerce": {"standard": "$3 - $5", "professional_account": "$2.80 - $4.75"},
                "wholesale": {"standard": "$3 - $5", "professional_account": "$2.80 - $4.75"},
                "retail": {"standard": "$3 - $5", "professional_account": "$2.80 - $4.75"},
                "delivery_time": "1-3 days"
            },
            "Priority Mail": {
                "ecommerce": {"standard": "$7 - $15", "professional_account": "$6.50 - $14.50"},
                "wholesale": {"standard": "$7 - $15", "professional_account": "$6.50 - $14.50"},
                "retail": {"standard": "$7 - $15", "professional_account": "$6.50 - $14.50"},
                "delivery_time": "1-3 days"
            },
            "Priority Mail Express": {
                "ecommerce": {"standard": "$25 - $40", "professional_account": "$23 - $38"},
                "wholesale": {"standard": "$25 - $40", "professional_account": "$23 - $38"},
                "retail": {"standard": "$25 - $40", "professional_account": "$23 - $38"},
                "delivery_time": "1-2 days"
            }
        },
        "UPS": {
            "UPS Ground": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "1-5 days"
            },
            "UPS 3 Day Select": {
                "ecommerce": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "wholesale": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "retail": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "delivery_time": "3 days"
            },
            "UPS 2nd Day Air": {
                "ecommerce": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "wholesale": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "retail": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "delivery_time": "2 days"
            },
            "UPS Next Day Air": {
                "ecommerce": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "wholesale": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "retail": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "delivery_time": "1 day"
            }
        },
        "FedEx": {
            "FedEx Ground": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "1-5 days"
            },
            "FedEx Home Delivery": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "1-5 days"
            },
            "FedEx Express Saver": {
                "ecommerce": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "wholesale": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "retail": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "delivery_time": "3 days"
            },
            "FedEx 2Day": {
                "ecommerce": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "wholesale": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "retail": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "delivery_time": "2 days"
            },
            "FedEx Standard Overnight": {
                "ecommerce": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "wholesale": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "retail": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "delivery_time": "1 day"
            }
        },
        "DHL eCommerce": {
            "SmartMail Parcel": {
                "ecommerce": {"standard": "$5 - $10", "professional_account": "$4.50 - $9"},
                "wholesale": {"standard": "$5 - $10", "professional_account": "$4.50 - $9"},
                "retail": {"standard": "$5 - $10", "professional_account": "$4.50 - $9"},
                "delivery_time": "2-8 days"
            },
            "SmartMail Parcel Plus": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "2-8 days"
            }
        },
        "OnTrac": {
            "Ground Shipping": {
                "ecommerce": {"standard": "$8 - $15", "professional_account": "$7 - $14"},
                "wholesale": {"standard": "$8 - $15", "professional_account": "$7 - $14"},
                "retail": {"standard": "$8 - $15", "professional_account": "$7 - $14"},
                "delivery_time": "1-3 days"
            },
            "Sunrise Gold": {
                "ecommerce": {"standard": "$15 - $30", "professional_account": "$13 - $27"},
                "wholesale": {"standard": "$15 - $30", "professional_account": "$13 - $27"},
                "retail": {"standard": "$15 - $30", "professional_account": "$13 - $27"},
                "delivery_time": "1 day"
            },
            "DirectPost": {
                "ecommerce": {"standard": "$5 - $10", "professional_account": "$4.50 - $9"},
                "wholesale": {"standard": "$5 - $10", "professional_account": "$4.50 - $9"},
                "retail": {"standard": "$5 - $10", "professional_account": "$4.50 - $9"},
                "delivery_time": "2-8 days"
            }
        }
    },
    "Canada": {
        "Canada Post": {
            "Regular Parcel": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "2-9 days"
            },
            "Xpresspost": {
                "ecommerce": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "wholesale": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "retail": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "delivery_time": "1-2 days"
            },
            "Priority": {
                "ecommerce": {"standard": "$30 - $60", "professional_account": "$27 - $54"},
                "wholesale": {"standard": "$30 - $60", "professional_account": "$27 - $54"},
                "retail": {"standard": "$30 - $60", "professional_account": "$27 - $54"},
                "delivery_time": "1 day"
            },
            "Expedited Parcel": {
                "ecommerce": {"standard": "$15 - $25", "professional_account": "$13.50 - $22.50"},
                "wholesale": {"standard": "$15 - $25", "professional_account": "$13.50 - $22.50"},
                "retail": {"standard": "$15 - $25", "professional_account": "$13.50 - $22.50"},
                "delivery_time": "1-7 days"
            }
        },
        "Purolator": {
            "Ground": {
                "ecommerce": {"standard": "$15 - $25", "professional_account": "$13.50 - $22.50"},
                "wholesale": {"standard": "$15 - $25", "professional_account": "$13.50 - $22.50"},
                "retail": {"standard": "$15 - $25", "professional_account": "$13.50 - $22.50"},
                "delivery_time": "1-7 days"
            },
            "Express": {
                "ecommerce": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "wholesale": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "retail": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "delivery_time": "1-2 days"
            },
            "Same Day": {
                "ecommerce": {"standard": "$50 - $100", "professional_account": "$45 - $90"},
                "wholesale": {"standard": "$50 - $100", "professional_account": "$45 - $90"},
                "retail": {"standard": "$50 - $100", "professional_account": "$45 - $90"},
                "delivery_time": "1 day"
            }
        },
        "Canpar Express": {
            "Ground": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "2-10 days"
            },
            "Select": {
                "ecommerce": {"standard": "$15 - $30", "professional_account": "$13.50 - $27"},
                "wholesale": {"standard": "$15 - $30", "professional_account": "$13.50 - $27"},
                "retail": {"standard": "$15 - $30", "professional_account": "$13.50 - $27"},
                "delivery_time": "1-5 days"
            },
            "Express": {
                "ecommerce": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "wholesale": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "retail": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "delivery_time": "1-2 days"
            }
        },
        "Loomis Express": {
            "Ground": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "2-7 days"
            },
            "Loomis Express": {
                "ecommerce": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "wholesale": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "retail": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "delivery_time": "1-2 days"
            },
            "Priority": {
                "ecommerce": {"standard": "$30 - $60", "professional_account": "$27 - $54"},
                "wholesale": {"standard": "$30 - $60", "professional_account": "$27 - $54"},
                "retail": {"standard": "$30 - $60", "professional_account": "$27 - $54"},
                "delivery_time": "1 day"
            }
        },
        "FedEx Canada": {
            "FedEx Ground": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "1-7 days"
            },
            "FedEx Economy": {
                "ecommerce": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "wholesale": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "retail": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "delivery_time": "2-4 days"
            },
            "FedEx Priority Overnight": {
                "ecommerce": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "wholesale": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "retail": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "delivery_time": "1 day"
            },
            "FedEx 2Day": {
                "ecommerce": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "wholesale": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "retail": {"standard": "$25 - $50", "professional_account": "$23 - $45"},
                "delivery_time": "2 days"
            }
        },
        "UPS Canada": {
            "UPS Standard": {
                "ecommerce": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "wholesale": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "retail": {"standard": "$10 - $20", "professional_account": "$9 - $18"},
                "delivery_time": "2-7 days"
            },
            "UPS Express Saver": {
                "ecommerce": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "wholesale": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "retail": {"standard": "$25 - $50", "professional_account": "$22.50 - $45"},
                "delivery_time": "1-2 days"
            },
            "UPS Expedited": {
                "ecommerce": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "wholesale": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "retail": {"standard": "$20 - $40", "professional_account": "$18 - $36"},
                "delivery_time": "2-5 days"
            },
            "UPS Express": {
                "ecommerce": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "wholesale": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "retail": {"standard": "$50 - $80", "professional_account": "$45 - $75"},
                "delivery_time": "1 day"
            }
        }
    }
}



export const shippingZone = [
  {"state": "Connecticut", "zone": "Zone 1 (Northeast)"},
  {"state": "Maine", "zone": "Zone 1 (Northeast)"},
  {"state": "Massachusetts", "zone": "Zone 1 (Northeast)"},
  {"state": "New Hampshire", "zone": "Zone 1 (Northeast)"},
  {"state": "New Jersey", "zone": "Zone 1 (Northeast)"},
  {"state": "New York", "zone": "Zone 1 (Northeast)"},
  {"state": "Pennsylvania", "zone": "Zone 1 (Northeast)"},
  {"state": "Rhode Island", "zone": "Zone 1 (Northeast)"},
  {"state": "Vermont", "zone": "Zone 1 (Northeast)"},
  {"state": "Illinois", "zone": "Zone 2 (Midwest)"},
  {"state": "Indiana", "zone": "Zone 2 (Midwest)"},
  {"state": "Iowa", "zone": "Zone 2 (Midwest)"},
  {"state": "Kansas", "zone": "Zone 2 (Midwest)"},
  {"state": "Michigan", "zone": "Zone 2 (Midwest)"},
  {"state": "Minnesota", "zone": "Zone 2 (Midwest)"},
  {"state": "Missouri", "zone": "Zone 2 (Midwest)"},
  {"state": "Nebraska", "zone": "Zone 2 (Midwest)"},
  {"state": "North Dakota", "zone": "Zone 2 (Midwest)"},
  {"state": "Ohio", "zone": "Zone 2 (Midwest)"},
  {"state": "South Dakota", "zone": "Zone 2 (Midwest)"},
  {"state": "Wisconsin", "zone": "Zone 2 (Midwest)"},
  {"state": "Alabama", "zone": "Zone 3 (South)"},
  {"state": "Arkansas", "zone": "Zone 3 (South)"},
  {"state": "Delaware", "zone": "Zone 3 (South)"},
  {"state": "Florida", "zone": "Zone 3 (South)"},
  {"state": "Georgia", "zone": "Zone 3 (South)"},
  {"state": "Kentucky", "zone": "Zone 3 (South)"},
  {"state": "Louisiana", "zone": "Zone 3 (South)"},
  {"state": "Maryland", "zone": "Zone 3 (South)"},
  {"state": "Mississippi", "zone": "Zone 3 (South)"},
  {"state": "North Carolina", "zone": "Zone 3 (South)"},
  {"state": "Oklahoma", "zone": "Zone 3 (South)"},
  {"state": "South Carolina", "zone": "Zone 3 (South)"},
  {"state": "Tennessee", "zone": "Zone 3 (South)"},
  {"state": "Texas", "zone": "Zone 3 (South)"},
  {"state": "Virginia", "zone": "Zone 3 (South)"},
  {"state": "West Virginia", "zone": "Zone 3 (South)"},
  {"state": "Alaska", "zone": "Zone 4 (West)"},
  {"state": "Arizona", "zone": "Zone 4 (West)"},
  {"state": "California", "zone": "Zone 4 (West)"},
  {"state": "Colorado", "zone": "Zone 4 (West)"},
  {"state": "Hawaii", "zone": "Zone 4 (West)"},
  {"state": "Idaho", "zone": "Zone 4 (West)"},
  {"state": "Montana", "zone": "Zone 4 (West)"},
  {"state": "Nevada", "zone": "Zone 4 (West)"},
  {"state": "New Mexico", "zone": "Zone 4 (West)"},
  {"state": "Oregon", "zone": "Zone 4 (West)"},
  {"state": "Utah", "zone": "Zone 4 (West)"},
  {"state": "Washington", "zone": "Zone 4 (West)"},
  {"state": "Wyoming", "zone": "Zone 4 (West)"}
]

export const usaState =  [
  {"name": "Alabama", "abbreviation": "AL"},
  {"name": "Alaska", "abbreviation": "AK"},
  {"name": "Arizona", "abbreviation": "AZ"},
  {"name": "Arkansas", "abbreviation": "AR"},
  {"name": "California", "abbreviation": "CA"},
  {"name": "Colorado", "abbreviation": "CO"},
  {"name": "Connecticut", "abbreviation": "CT"},
  {"name": "Delaware", "abbreviation": "DE"},
  {"name": "Florida", "abbreviation": "FL"},
  {"name": "Georgia", "abbreviation": "GA"},
  {"name": "Hawaii", "abbreviation": "HI"},
  {"name": "Idaho", "abbreviation": "ID"},
  {"name": "Illinois", "abbreviation": "IL"},
  {"name": "Indiana", "abbreviation": "IN"},
  {"name": "Iowa", "abbreviation": "IA"},
  {"name": "Kansas", "abbreviation": "KS"},
  {"name": "Kentucky", "abbreviation": "KY"},
  {"name": "Louisiana", "abbreviation": "LA"},
  {"name": "Maine", "abbreviation": "ME"},
  {"name": "Maryland", "abbreviation": "MD"},
  {"name": "Massachusetts", "abbreviation": "MA"},
  {"name": "Michigan", "abbreviation": "MI"},
  {"name": "Minnesota", "abbreviation": "MN"},
  {"name": "Mississippi", "abbreviation": "MS"},
  {"name": "Missouri", "abbreviation": "MO"},
  {"name": "Montana", "abbreviation": "MT"},
  {"name": "Nebraska", "abbreviation": "NE"},
  {"name": "Nevada", "abbreviation": "NV"},
  {"name": "New Hampshire", "abbreviation": "NH"},
  {"name": "New Jersey", "abbreviation": "NJ"},
  {"name": "New Mexico", "abbreviation": "NM"},
  {"name": "New York", "abbreviation": "NY"},
  {"name": "North Carolina", "abbreviation": "NC"},
  {"name": "North Dakota", "abbreviation": "ND"},
  {"name": "Ohio", "abbreviation": "OH"},
  {"name": "Oklahoma", "abbreviation": "OK"},
  {"name": "Oregon", "abbreviation": "OR"},
  {"name": "Pennsylvania", "abbreviation": "PA"},
  {"name": "Rhode Island", "abbreviation": "RI"},
  {"name": "South Carolina", "abbreviation": "SC"},
  {"name": "South Dakota", "abbreviation": "SD"},
  {"name": "Tennessee", "abbreviation": "TN"},
  {"name": "Texas", "abbreviation": "TX"},
  {"name": "Utah", "abbreviation": "UT"},
  {"name": "Vermont", "abbreviation": "VT"},
  {"name": "Virginia", "abbreviation": "VA"},
  {"name": "Washington", "abbreviation": "WA"},
  {"name": "West Virginia", "abbreviation": "WV"},
  {"name": "Wisconsin", "abbreviation": "WI"},
  {"name": "Wyoming", "abbreviation": "WY"}
]