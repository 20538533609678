// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderProduct{
    padding: 6px;
    margin-top: 20px;
    min-height: 400px;
    max-height: 550px;
    background: white;
}
.menuBarSlider {
    width: 100%;
    height: 30px;
    margin-top: 88px;
}

    @media only screen and (max-width: 800px) {
        .menuBarSlider {
            width: 100%;
            height: 30px;
            margin-top: 88px;
        }
    }

    `, "",{"version":3,"sources":["webpack://./src/includes/slider/mystyle.sliderProduct.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;IAEI;QACI;YACI,WAAW;YACX,YAAY;YACZ,gBAAgB;QACpB;IACJ","sourcesContent":[".sliderProduct{\n    padding: 6px;\n    margin-top: 20px;\n    min-height: 400px;\n    max-height: 550px;\n    background: white;\n}\n.menuBarSlider {\n    width: 100%;\n    height: 30px;\n    margin-top: 88px;\n}\n\n    @media only screen and (max-width: 800px) {\n        .menuBarSlider {\n            width: 100%;\n            height: 30px;\n            margin-top: 88px;\n        }\n    }\n\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
