// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
  }
/*   
  body {
    margin: 0;
    font-family: Arial, sans-serif;
  } */
  
  /* .header {
    text-align: center;
    padding: 32px;
  } */
  
  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  .column {
    flex: 25% 1;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
  }
  
  @media (max-width: 800px) {
    .column {
      flex: 50% 1;
      max-width: 50%;
    }
  }
  
  @media (max-width: 600px) {
    .column {
      flex: 100% 1;
      max-width: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/includes/pages/LanguagePage/Gallery.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;EACxB;AACF;;;;KAIK;;EAEH;;;KAGG;;EAEH;IACE,aAAa;IACb,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,WAAS;IACT,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE;MACE,WAAS;MACT,cAAc;IAChB;EACF;;EAEA;IACE;MACE,YAAU;MACV,eAAe;IACjB;EACF","sourcesContent":["* {\n    box-sizing: border-box;\n  }\n/*   \n  body {\n    margin: 0;\n    font-family: Arial, sans-serif;\n  } */\n  \n  /* .header {\n    text-align: center;\n    padding: 32px;\n  } */\n  \n  .row {\n    display: flex;\n    flex-wrap: wrap;\n    padding: 0 4px;\n  }\n  \n  .column {\n    flex: 25%;\n    max-width: 25%;\n    padding: 0 4px;\n  }\n  \n  .column img {\n    margin-top: 8px;\n    vertical-align: middle;\n  }\n  \n  @media (max-width: 800px) {\n    .column {\n      flex: 50%;\n      max-width: 50%;\n    }\n  }\n  \n  @media (max-width: 600px) {\n    .column {\n      flex: 100%;\n      max-width: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
