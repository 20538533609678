import React from 'react';
import './DrawerToggleButton.css'



const drawerToggleButton = ({click, classToggle = 'toggle-button_line'}) =>(
    <button className="toogle_button" onClick={click}>
        <div className={classToggle}/>
        <div className={classToggle}/>
        <div className={classToggle}/>
    </button>

);
export default drawerToggleButton;