import React, { useState, useEffect  } from "react";
import Pages from "../LanguagePage";
import nodata from '../../Layout/imgs/nodata0.png';
import Timer from "../../pageInside/timer";
import {authenticate, UserAgree, UserStepUP } from "../../auth";
import "../../pages/addProduct.css" 
const  UserExitActAss = (props) => {

    const {
        laguangeElakay,
        infoSource,
        token,
        userActiveRole
        // clickAgree
    }= props

    let BnPlanAffaire = {
        planAffaire : true,
        termCondition: false,
        userAgree: false
    }
    let bnTermCondition ={
    planAffaire : false,
    termCondition: true,
    userAgree: false
    }

    let bnUserAgree ={
    planAffaire : false,
    termCondition: false,
    userAgree: true
    }

    const {
        customer,
        seller,
        provider,
        userid,
        role,
        roleName,
        level,
        maxRoyalite,
        maxSeller,
        maxProducer,
        maxAssociate,
        maxInvestment,
        maxAdmin,
        userAdmin,
        Admintean,
        experyDate,
    } = userActiveRole

    const [pages, setPages]= useState(BnPlanAffaire)
    const [planAfCh, setPlanAfCh] = useState(false);
    const [ternConCh, setTernConCh] = useState(false);
    const [agreeCh, setAgreeCh] = useState(false);
    const [roleNamePage, setRoleNamePage] = useState({
        plan: '',
        term:'',
        agree:''
    });
    
const {
    plan,
    term,
    agree
} = roleNamePage

    const {
        planAffaire,
        termCondition,
        userAgree,
        } = pages


        const chosingRolePage =(a)=>{
            if(a === 'customer'){
                setRoleNamePage({
                    plan: 'planUserRoyal',
                    term:'termConditionRoyal',
                    agree:'royalAgree'
                })
            }else if(a === 'seller'){
                setRoleNamePage({
                    plan: 'planUserSeller',
                    term: 'termConditionSeller',
                    agree: 'sellerAgree'
                })
            }else if(a === 'provider'){
                setRoleNamePage({
                    plan: 'planUserProvider',
                    term: 'termConditionProvider',
                    agree: 'providerAgree'
                })
            }else if(a === 'associate'){
                setRoleNamePage({
                    plan: 'planAffaireElakay',
                    term:'termConditionAssociate',
                    agree:'associateAgree'
                })
            }else if(a === 'investment'){
                setRoleNamePage({
                    plan: 'planAffaireInvest',
                    term:'termConditionInvest',
                    agree:'investmentAgree'
                })
            }else if(a === 'administrator'){
                setRoleNamePage({
                    plan: 'planAffaireAdmin',
                    term:'termConditionAdmin',
                    agree:'administratorAgree'
                })
            }else if(a ==='family'){
                setRoleNamePage({
                    plan: 'planAffaireFamily',
                    term:'termConditionFamily',
                    agree:'familyAgree'
                })
            }

        }

        useEffect(() => { 
            chosingRolePage(roleName)
        }, [roleName]);


    const checkProdKeyExit = (ad)=>{
        let ent 
        if(Array.isArray(ad)){
          let ac = ad[0]
          ent = Object.keys(ac ? ac:{});
          return ent.every(([key, val]) => ac[key] != undefined) && true
        } else{
           ent = Object.keys(ad ? ad:{});
           return ent.every(([key, val]) => ad[key] != undefined) && true
        }}
     
        // const clickAgree = () => {
        //     UserAgree(token).then(data => {
        //         if (data.error) {
        //             console.log(data.error)
        //         } else {
        //             authenticate(data, () => { 
        //               // create a page show them the new fuction they have link to page
        //                 var redirectToURL = '/your-step';
        //                 window.location.href = redirectToURL;      
        //             });
        //         }
        //     })
        // }

  

        const clickAgree = () => {
            UserStepUP(token, {customer, seller, provider, userid, role, roleName, level, maxRoyalite, maxSeller, maxProducer, maxAssociate, maxInvestment, maxAdmin, userAdmin, Admintean, agreeRole: true}).then(data => {
                if (data.error) {
                    console.log(data.error)
                } else {
                    authenticate(data, () => { 
                      // create a page show them the new fuction they have link to page
                        var redirectToURL = '/';
                        window.location.href = redirectToURL;      
                    });
                }
            })
        }

    const pageSteps = (a)=>{
        if (!checkProdKeyExit(a)){
           return <Pages  
           classAlert ={'container'}
            paragra0={a}
        />
        }else{
          return <div> 
          <div className="row mt-4 mb-4 justify-content-center">
            <div className="col-11">
          <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
           </div>
           </div></div>
        }
    }

    const bonPrev = (a)=>{
        return  <button onClick={()=> setPages(a)} className="btn btn-primary p-2 float-left" style={{ width: "120px" }}>
          {infoSource.laguangeElakay('Button', infoSource.languageAPI).previous}
        </button>
      }
     const  bonNext = (a, t = true)=>{
         return <button onClick={()=> setPages(a)} className="btn btn-primary p-2 float-right" style={{ width: "120px" }}>
          {infoSource.laguangeElakay('Button', infoSource.languageAPI).next}
       </button>
      }

      const allBon = (p, n, t)=>{
          return  <div className="row justify-content-center m-4">
          <div className="col col-sm-10">
              <div className="bg clearfix ">
                  { p && bonPrev(p)}
                  {n && bonNext(n, t)}
              </div>
          </div></div>
      }
      const  sendCodeBnt = ()=>{
        return planAfCh && ternConCh && agreeCh ? 
        <button onClick={()=> clickAgree()} className="btn btn-primary p-2 float-right" style={{ width: "120px" }}>
        {/* active user */}
        {infoSource.language(infoSource, "Button", "actUser")} 
      </button> : <button className="btn btn-primary p-2 float-right disabled" style={{ width: "120px" }}>
      {/* active user */}
      {infoSource.language(infoSource, "Button", "actUser")} 
      </button>
     }

     const  CheckBoxButton = ()=>{
        let c = planAfCh? false: true
        setPlanAfCh(c) 
     }
     const  CheckBoxButton0 = ()=>{
        let c = ternConCh? false: true
        setTernConCh(c) 
     }
     const  CheckBoxButton1 = ()=>{
        let c = agreeCh? false: true
        setAgreeCh(c) 
     }
      

    const  planAffairePage =()=>{
        return planAffaire && <div>
            {pageSteps(infoSource.laguangeElakay(plan,  infoSource.languageAPI))}
            {  allBon(undefined, bnTermCondition)}
            </div> 
    }   

    const  termConditionPage =()=>{
        return termCondition && <div>
               {pageSteps(infoSource.laguangeElakay(term,  infoSource.languageAPI))}
           
            {  allBon(BnPlanAffaire, bnUserAgree)}
            </div> 
    }



    const userAgreePage =()=>{
        return userAgree && <div>
            {pageSteps(infoSource.laguangeElakay(agree,  infoSource.languageAPI))}
            <div className="container">
            <div className="row mb-4 justify-content-center">
            <ul className="col-12 col-md-9">
                    <li> 
                    <input
                            onChange={CheckBoxButton}
                            type="checkbox"
                            className="form-check-input"
                            checked={ planAfCh ? 'checked' : ''}
                            id="planAffairLu"
                        />
                        <label className="form-check-label" for="planAffairLu">
                            {/* es ce que vous avez lu le plan d'affaire  */}
                            {infoSource.language(infoSource, "label", "planAffairLu")}
                            </label>
                    </li>
                    <li>
                    <input
                            onChange={CheckBoxButton0}
                            type="checkbox"
                            className="form-check-input"
                            checked={ternConCh? 'checked' : ''}
                            id="termConditionAgre"
                        />
                        <label className="form-check-label" for="termConditionAgre">
                            {/* es ce que vous ete d'acord avec les term et les condition */}
                            {infoSource.language(infoSource, "label", "termConditionAgre")}
                            </label>
                    </li>
                    <li className="list-unstyled">
                        <input
                            onChange={CheckBoxButton1}
                            type="checkbox"
                            className="form-check-input"
                            checked={agreeCh? 'checked' : ''}
                            id="acpContinue"
                        />
                        <label className="form-check-label" for="acpContinue">
                        {infoSource.language(infoSource, "title", "chButAgree")}
                             {/* avec le deux volet pour continue */}
                             {infoSource.language(infoSource, "label", "acpContinue")}
                             </label>
                    </li>
                </ul>
                
            </div>
            {sendCodeBnt()}
            </div>
            {  allBon(bnTermCondition, undefined)}
            </div> 
    }

    let plsh = planAffaire || termCondition || userAgree ? "active" : ''
    let tcsh = termCondition || userAgree ? "active" : ''
    let Agsh = userAgree ? "active" : ''

    const stepSeller = ()=>(
        //  <div className="boxCategory1 text-center">
   <div className="row">
  <div className="col-xs-12 col-md-8 offset-md-2 block pb-4">
    <div className="wrapper-progressBar">
      <ul className="progressBar">
        <li className={plsh}>
            {/* Plan D'affaire */}
            {infoSource.language(infoSource, "label", "planAffai")}
            </li>
        <li className={tcsh}>
            {/* Term & Condition */}
            {infoSource.language(infoSource, "label", "termCondi")}
            </li>
        <li className={Agsh}>
            {/* Final Agree */}
            {infoSource.language(infoSource, "label", "FinalAgree")}
            </li>
      </ul>
    </div>
  </div>
</div>
// </div>
    )

    {stepSeller()}

return <>  

<Timer
 infoSource={infoSource}
 nbrdays = {experyDate - new Date()} 
/>
{stepSeller()} 
 {planAffairePage()}
 {termConditionPage()}
 {userAgreePage()}
  </>
}

    export default  UserExitActAss ;