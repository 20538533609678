import React, { useState } from "react";
import { FaPhone} from "react-icons/fa";
import {activatorUserAdding} from "../../auth";
import PhoneInput, { parsePhoneNumber, formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, getCountryCallingCode  } from 'react-phone-number-input'
import { laguangeElakay  } from "../../pageInside/dataElakay";
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'


const AddtelephoneUserAssociate = (props) => {
    const {
        setPagesUser,
        bnAddAdress,
        setUserInfo,
        token,
        infoSource
            } = props

    const [value, setValue] = useState()


    const clickSubmit = () => {
        const data = { "country": en[parsePhoneNumber(value).country], "abrCountry": parsePhoneNumber(value).country, "ariaCode": getCountryCallingCode(parsePhoneNumber(value).country) , "National": formatPhoneNumber(value), "International": formatPhoneNumberIntl(value)}
        activatorUserAdding(token, 'telephone', data, true).then(data => {
            if (data.error) {
                console(data.error)
            } else {
                setValue("")
                setPagesUser(bnAddAdress)
                setUserInfo(data)
            }
        });
    };

    const TelephoneButton =()=>(
        <div>

     {  value && isValidPhoneNumber(value) ?
        <button className="btn btn-info float-right m-4 pt-2 pb-2 pr-3 pl-3" onClick={clickSubmit} >
                <span style={{ fontSize: "20px", paddingRight: "6px" }}><FaPhone /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addPhone}
        </button>
        :
            <button className="btn btn-info disabled float-right m-4 pt-2 pb-2 pr-3 pl-3">
                <span style={{ fontSize: "20px", paddingRight: "6px" }}><FaPhone /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addPhone}
        </button>
        }
        </div>
    )



    const telephoneuser =()=>(
    <div>
        {
            
            <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                placeholder={infoSource.laguangeElakay('label', infoSource.languageAPI).enterPhoneNumber}
                value={value}
                onChange={setValue}
                error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
            
        }
    </div>
    )

   
       
    return   <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).addPhoneNumber}</h4>
                </div>
         <div className="m-4">
       {telephoneuser()}
       
       </div>
                {TelephoneButton()}
         </div>
        </div>

 
    
           
     
    
};

export default AddtelephoneUserAssociate;