import React, { useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import "./ExpandingSearch.css";

function ExpandingSearch(props) {
const {
  infoSource,
  handleChange,
  setIsFocused,
  search,
  expanded, 
  setExpanded
} = props

 
  const inputRef = useRef(null);

  const handleClick = () => {
    // Si déjà ouvert, on "exécute" une recherche (exemple)
    if (expanded) {
      console.log("Recherche :", inputRef.current.value);
    } else {
      setExpanded(true);
      // Donne le focus à l'input
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  };

  const handleBlur = () => {
    // Si le champ est vide, on referme
    if (!inputRef.current.value) {
      setExpanded(false);
      setIsFocused(false);
    }
  };

  return (
    <div className={`exp-search-wrapper ${infoSource.headShop ? expanded ? "expanded" : "": "expanded"}`}>
      {/* Bouton (icône) */}
    {infoSource.headShop && <button className="exp-btn" onClick={handleClick}>
        {/* <i className="fa fa-search"></i> */}
        <FaSearch/>
      </button> } 

      {/* Champ texte */}
      <input
        ref={inputRef}
        onChange={handleChange}
        type="search"
        className="exp-input"
        placeholder="Search..."
        value={search}
        onBlur={handleBlur}
        onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
      />

      
      
    </div>
  );
}

export default ExpandingSearch;
