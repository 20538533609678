import React, { useState } from "react";
import { useNavigate, Link} from "react-router-dom";
import { signout, isAuthenticated, signOutALL} from "../auth";
import './menu.css';
import {FaAddressCard, FaSellcast, FaUserAlt, FaUserSlash, FaLongArrowAltLeft, FaUserCog, FaBoxes, FaShoppingCart, FaUserSecret, FaMoneyBill, FaHandsHelping, FaWallet, FaBuysellads, FaFirstOrder, FaSlidersH, FaLanguage } from "react-icons/fa";
import {MdContactPhone, MdClose, MdKeyboardArrowLeft, MdMessage, MdToday, MdAccountBox, MdCreateNewFolder, MdVerifiedUser, MdPublic, MdCheckBox, MdIndeterminateCheckBox, MdAddBusiness, MdLanguage } from "react-icons/md";
import { BsGridFill } from "react-icons/bs";
import { AiFillShop, AiFillMessage } from "react-icons/ai";
import { GrAscend, GrBusinessService } from "react-icons/gr";
import { GiSellCard, GiChessKing, GiFamilyTree } from "react-icons/gi";
import { RiUserCommunityFill } from "react-icons/ri";
import { Family, Adminstrator, Investment, Assoociate, Seller, } from "../pageInside/Rolle"
const { token } = isAuthenticated();

const MenuUser = (props) => {
     const chekobj = (ob)=> JSON.stringify(ob) === '{}'
    const checkUserLang =(a)=>{
        let ok 
        let l =   a.languageAPI.elakayInfoAPI
        let t = l.translator
        let s = l.supervisor
        let ad =  l.Admin
        let ut = !chekobj(l) && l.translator &&  l.translator.user
        let us = !chekobj(l)&& l.supervisor && l.supervisor.user
        let uA = !chekobj(l) && l.Admin && l.Admin.user
        let uId =  a.userInfo._id
       let Fa =  Family(props.userInfo.email)
        if(!chekobj(l) && uId == ut){
            return true
        }else if(!chekobj(l) && uId == us){
            return true
        }else if(!chekobj(l) && uId == uA){
            return true
        }
        else if(Fa){
            return true
        }
        else{
            return false
        }
return true
    }

    const {
        infoSource
    }=props

    const [simpleUser, setSimpleUser] = useState(true);
    const [Administrator, setAdministrator] = useState(false);
    const [elakayCategory, setelakayCategory] = useState(false);
    let classUser = 'menuUserClass open'
    let classAdmin = 'menuAdminClass'

    let ilcod = infoSource.languageAPI.checkLangObjDefault
    let ileia = !chekobj(infoSource.languageAPI.elakayInfoAPI) && infoSource.languageAPI.elakayInfoAPI
    let ilei = infoSource.languageAPI.elakayInfo

    let InfoApiButton = !infoSource.loading && ilcod(ileia, ilei, 'Button')
    let InfoApiMenuList =  !infoSource.loading && ilcod(ileia, ilei, 'menuList')
    let InfoApiMenuSertting =  !infoSource.loading && ilcod(ileia, ilei, 'menuSertting')

    const menuhande = (setelakayCategory, setSimpleUser, setAdministrator) => {
        setSimpleUser(false)
        setAdministrator(true)
        setelakayCategory(false)
    }
    const backhande = (setelakayCategory, setSimpleUser, setAdministrator) => {
        setSimpleUser(true)
        setAdministrator(false)
        setelakayCategory(false)
    }
    let myStyles = {
        color:'black'
    }

    if (Administrator) {
        classUser = 'menuUserClass'
        classAdmin = 'menuAdminClass open'
    };

    if (elakayCategory) {
        classUser = 'menuUserClass'
        classAdmin = 'menuAdminClass open'
    }

    let navigate = useNavigate()
    const HeaderSidebar = () => (
        <div className="headMenuUser">
            {!isAuthenticated() && (
                <>
                    <Link
                        className="btn btn-outline-light btn-md"
                        style={{ color: 'black' }}
                        to="/signin"
                    >
                      {InfoApiButton.signin}
                    </Link>

                    <Link
                        className="btn btn-outline-light btn-md"
                        style={{ color: 'black' }}
                        to="/signup"
                    >
                      {InfoApiButton.signup}
                    </Link>
                    <p><FaUserSlash /> {InfoApiButton.plSignIN}<MdClose className="xclose" onClick={props.click} /></p>
                </>
            )}

            {isAuthenticated() && (
                <>

                    <button
                        className="btn btn-outline-light btn-md"
                        style={{ cursor: "pointer", color: "#000000" }}
                        onClick={() =>

                            signout(token).then(() => {
                                navigate('/signin')
                            })
                        }
                    >
                        {InfoApiButton.signOut}
                    </button>

                    <span
                        className="btn btn-outline-light btn-md"
                        style={{ cursor: "pointer", color: "#000000" }}
                        onClick={() =>

                            signOutALL(token).then(() => {
                                navigate('/signin')
                            })
                        }
                    >
                        {InfoApiButton.signOutAll}
                    </span>

                    <p><FaUserAlt />{" " + props.userInfo.lastname + " " + props.userInfo.firstname} <MdClose className="xclose" onClick={props.click} /></p>
                </>
            )}
        </div>
    )
   

    const menuSimpleUser = () => (
        simpleUser && (
            <div className={classUser}   >
                <ul style={{ color: "black" }}>
                    {isAuthenticated() && Seller() &&
                        <li><Link to="/user-serting" style={myStyles}><FaUserCog className="mr-2 h2"/>{ InfoApiMenuList.userSetting}</Link><MdKeyboardArrowLeft className="mdclose" onClick={() => menuhande(setelakayCategory, setSimpleUser, setAdministrator)} /></li>
                    }
                    <li><Link to="/all-catageories" style={myStyles}> <BsGridFill className="mr-3 h2" />{ InfoApiMenuList.allCategory}</Link></li>
                    <li ><Link to="/your-step" style={myStyles}> <GrAscend className="mr-2 h2" />{InfoApiMenuSertting.yourStep}</Link></li>
                    <li><Link to="/profile" style={myStyles}> <FaUserSecret className="mr-2 h2" />{ InfoApiMenuList.myProfile}</Link></li>
                    <li><Link to="/account" style={myStyles}> <MdAccountBox className="mr-2 h2" />{ InfoApiMenuList.myAccount}</Link></li>
                    <li><Link to="/profile?addr=true" style={myStyles}> <FaAddressCard className="mr-2 h2" />{infoSource.laguangeElakay('Button', infoSource.languageAPI).addAdress}</Link></li>
                    <li><Link to="/profile?tel=true" style={myStyles}> <MdContactPhone className="mr-2 h2" />{infoSource.laguangeElakay('Button', infoSource.languageAPI).addTel}</Link></li>
                    <li><Link to="/c/orders" style={myStyles}><FaBoxes className="mr-2 h2" />{ InfoApiMenuList.myOrders}</Link></li>
                    <li><Link to="/cart" style={myStyles}> <FaShoppingCart className="mr-2 h2" />{ InfoApiMenuList.myCart}</Link></li>
                    <li><Link to="/deal-today"style={myStyles}> <MdToday className="mr-2 h2" />{ InfoApiMenuList.todayDeal}</Link></li>
                    <li> <Link to="/help-contact" style={myStyles}>  <FaHandsHelping className="mr-2 h2" />{ InfoApiMenuList.helpContact}</Link></li>
                    <li><Link to="/purchased" style={myStyles}> <FaMoneyBill className="mr-2 h2" />{ InfoApiMenuList.myPurchased}</Link></li>
                    <li><Link to="/wallet"style={myStyles}> <FaWallet className="mr-2 h2" />{ InfoApiMenuList.wallet}</Link></li>
                    {/* <li><Link to="/score-point" style={myStyles}> <BsGraphUp className="mr-2 h2" />{ InfoApiMenuList.myScorePoint}</Link></li> */}
                    <li><Link to="/message"style={myStyles}><MdMessage className="mr-2 h2" />{ InfoApiMenuList.myMessage}</Link></li>
                    {/* <li><Link to="" style={myStyles}> <FaCreditCard className="mr-2 h2"/>{ InfoApiMenuList.GiftCard}</Link></li> */}
                </ul>
            </div>
        ))

    const menuAdmin = () => (
        Administrator && (

            <div className={classAdmin}>
                <div className="headTitle"><h5>User Serting<FaLongArrowAltLeft className="mdclose" onClick={() => backhande(setelakayCategory, setSimpleUser, setAdministrator)} /></h5></div>
                <div style={{ overflow: "auto" }}>
                    <ul >
                        
                        
                        
                        {/* <li><Link to="/User-team" style={myStyles}> <FaUserFriends className="mr-2 h2" />{InfoApiMenuSertting.myTeam}</Link></li> */}
                    
                        <li><Link to={"/myshop"} style={myStyles}><AiFillShop className="mr-2 h2" />{InfoApiMenuSertting.myShop}</Link></li>
                       <li ><Link to={"/myshop?sel=true"} style={myStyles}><FaSellcast className="mr-2 h2" />{InfoApiMenuSertting.sell}</Link></li>
                        <li><Link to="/myshop?mb=true&BPUB=false" style={myStyles}><MdPublic className="mr-2 h2" />{infoSource.language(infoSource, 'title', 'myShopPub')}</Link></li>
                        <li ><Link to={"/myshop?odr=true"} style={myStyles}><FaFirstOrder className="mr-2 h2" /> {InfoApiMenuSertting.buyerCustomer}</Link></li> 
                        <li><Link to={"/myshop?td=true"} style={myStyles}><MdCheckBox className="mr-2 h2" />{InfoApiMenuSertting.yourProInDeal}</Link></li>
                        <li><Link to={"/myshop?fd=true"} style={myStyles}><MdIndeterminateCheckBox className="mr-2 h2" />{InfoApiMenuSertting.yourProOutDeal}</Link></li>
                     
                            {Assoociate() && (
                                <>
                                    <li><Link to='/admin_associat' style={myStyles}> <RiUserCommunityFill className="mr-2 h2" />{InfoApiMenuSertting.viewUser}</Link></li>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=1" style={myStyles}> <GiChessKing className="mr-2 h2" />{InfoApiMenuSertting.addUserRoyalty}</Link></li>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=2" style={myStyles}> <FaBuysellads className="mr-2 h2" />{InfoApiMenuSertting.addSeller}</Link></li>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=3" style={myStyles}><GiSellCard className="mr-2 h2" />{InfoApiMenuSertting.addProducer}</Link></li>
                                </>
                            )}
                        {Investment() && (
                                <>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=4" style={myStyles}> <GrBusinessService className="mr-2 h2" />{InfoApiMenuSertting.addAssociate}</Link></li>
                                </>
                            )}  
                        {
                        Adminstrator() && (
                            <>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=5" style={myStyles}> <MdAddBusiness className="mr-2 h2" />{InfoApiMenuSertting.addInvestment}</Link></li>
                            </>
                            )}
                            {Family(props.userInfo.email)  && (
                            <>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=6" style={myStyles}> <FaUserCog className="mr-2 h2" />{InfoApiMenuSertting.addAdministrator}</Link></li>
                                    <li><Link to="/admin_associat?page=usersAdd&usel=7" style={myStyles}> <GiFamilyTree className="mr-2 h2" />{InfoApiMenuSertting.addFamilly}</Link></li>
                                    
                            </>
                            )}
                             { Adminstrator() && checkUserLang(infoSource) &&  (
                            <>
                                    {/* <li><Link to="/Admin-sms" style={myStyles}> <AiFillMessage className="mr-2 h2" />{InfoApiMenuSertting.mesHelpContact}</Link></li> */}
                                   { <li><Link to="/elakay-language" style={myStyles}> <MdLanguage className="mr-2 h2" />{InfoApiMenuSertting.addLanguage}</Link></li>}
                            </>
                            )}
                        {Family(props.userInfo.email) && (
                            <>
                             <li><Link to="/traductor-team" style={myStyles}> <FaLanguage className="mr-2 h2" />{InfoApiMenuSertting.addTeanTraductor}</Link></li>
                                <li><Link to="/category" style={myStyles}> <MdCreateNewFolder className="mr-2 h2" />{InfoApiMenuSertting.creatCatalog}</Link></li>
                                <li><Link to="/filters" style={myStyles}> <FaSlidersH className="mr-2 h2" />{InfoApiMenuSertting.creatFilter}</Link></li>

                            </>
                        )
                        }
                    </ul>
                </div>
            </div>
        ))


    return (
        <div className="nemuUserContainer">
            {HeaderSidebar()}
            <div className="numeUserpage">
            {menuSimpleUser()}
            {menuAdmin()}
        </div>
        </div>

    )
};

export default MenuUser; 
