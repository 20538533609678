import React, { useState, useEffect} from "react";
import {Link} from "react-router-dom";
import PubProducts from "./pubProduct";
import ProductFollow from "./pubProdFollow";
import { Row } from 'react-bootstrap';
import { loadFollowUserShop , getImages} from "../../auth/apiProduct";
import { elakay_API } from "../../../config";
import imgProductLakay from '../../Layout/imgs/md3.jpg' 
import {isAuthenticated, addFollowUserShop } from "../../auth";
const FollowPage = (props) => {
const [shopUser, setShopUser] = useState('seller');
const {  
    nbrArr = 3,
    product, 
    productViewedUser,
    userID,
    token,
    infoSource,
    setFollowShop
 } = props

 const [productF, setProductsF] = useState([]);

 const loadShopUser =(a)=>{ 
  const exists = a.owner.address.some(address => address.id === a.addressShop);

  if (exists) {
    return a.owner.address.filter(AS =>  AS._id === a.addressShop)[0].shopName
   } else {
    if(a.owner.address.length > 0){
       let first1 = String(a.owner.address[0].firstName).charAt(0)
      let last1 =  String(a.owner.address[0].lastName).charAt(0)
      let FL = first1.concat(last1).toLocaleUpperCase()
      return FL
    }else{
       return ""
    }
   }
  
  //  let shop = a.owner.address.filter(AS =>  AS._id === a.addressShop)[0].shopName
  //  let first1 = String(a.owner.address[0].firstName).charAt(0)
  //  let last1 = String(a.owner.address[0].lastName).charAt(0)
  //  let FL = first1.concat(last1).toLocaleUpperCase()
  
  //  let lakayUser = shop ? shop.toLocaleUpperCase(): FL
  //  setShopUser(lakayUser)
 }

 const loadProductsBySellfollow = (uId, n) => {
    loadFollowUserShop(uId, n, 'asc', "sold").then(data => {
     if(!data){
       console.log("data error")
     }else{
           setProductsF(data.products);
           loadShopUser(data.products[0])
     }  
   })
};

const clickToFollowShop = () => {
  
  addFollowUserShop(token ,userID).then(data => { 
    infoSource.setShopFollow([])
      if(!data){
          console.log(data.error)
      }else{
        infoSource.readUser(token)
          productViewedUser(token, false,  data.followShopUser)
          
      }
  })
}; 

useEffect(() => {
    loadProductsBySellfollow(userID, nbrArr);
}, [userID, nbrArr]);

const imageShow =(p)=>{
let urlImg = p.galeries.length > 0 && p.galeries[0].images.length > 0 ? p.galeries[0].images[0] : p.galeriesID.galeries.length > 0 ? p.galeriesID.galeries[0].urlImg  : imgProductLakay
  return  <img  
    className="card-img-top"
    src={urlImg}
    onError={event => {
        event.target.src = imgProductLakay
        event.onerror = null
      }}
    alt={p.name}
    style={{ width: "100%" }}
/>
}


 const prodFollowALL =()=>{
       
    return <div className="card m-1 p-1 m-4">
    <table style={{width: '100%'}}>
        <tr>
       
            <td>
            <Row className="p-1"  xs={nbrArr}>
            {productF && productF.map((product, i) => <div key={i}>{imageShow(product)}</div>)}
             </Row>
             </td> 
             <td className="w-25 p-0">
            <div className="d-grid gap-2 mx-auto">
            <button  className="m-1 py-3 px-0 btn btn-outline-success rounded-4 shadow-lg" role="button" onClick={()=>clickToFollowShop()}>
    {/* Follow */}
    {infoSource.language(infoSource, "Button", "follow")}
    </button>
            </div> 
            <div className="d-grid gap-2 mx-auto">
                <Link  className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg"  to={`/lakay-ShopSeller/${userID}`} role="button">
                {/* view lakay  */}
                {infoSource.language(infoSource, "Button", "viewLakay")} 
                {" "}
                {shopUser}</Link>
            </div> 
             </td>
             </tr>
           </table>
             </div>
}
 

 return prodFollowALL()
}
export default FollowPage;