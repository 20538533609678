import React, { useState, useEffect } from 'react';

const EmailDataHtml = (props) => {

    const {
        jsonInput,
        htmlTemplate
    } = props

    const checkIfFunction = (f)=>{
        return typeof f === 'object' ? true : false
      }

      const replaceObj = (array, obj,  key, arrayRepl)=>{
        let linkData
        let data
        for (let i = 0; i < array.length; i++) {
            linkData = "${"+ key +"." + array[i] + "}"
            data = obj[key][array[i]]
            arrayRepl.push([linkData, data])
        }  
    }

    const secondaireData = (prObj, seObj, key, arrayRepl)=>{
        let newArray
        let linkData
        let data 
        
        if(checkIfFunction(seObj)){
        newArray =  Object.keys(seObj ? seObj:{});
         replaceObj(newArray, prObj, key, arrayRepl)
        }else{
            linkData = "${"+ key +"}"
            data = prObj[key] 
            arrayRepl.push([linkData, data])
        }
      }

      const htmlReplaceData = (obj)=>{
        let arrayRepl = []
       let primaireData = Object.keys(obj ? obj:{});
       primaireData.forEach((da => secondaireData(obj, obj[da], da, arrayRepl)))
       return arrayRepl
    }
    const handleRunCode = () => {
        try {
          // Parse le JSON entré par l'utilisateur
          const data = JSON.parse(jsonInput);

          
          // Remplacer les placeholders dans le HTML avec les valeurs du JSON
         let dynamicHtml = htmlTemplate
            let arrayReplace = htmlReplaceData(data)
            for (let i = 0; i < arrayReplace.length; i++) {
                let name = arrayReplace[i][1]
                let linkName  = arrayReplace[i][0]
               dynamicHtml = dynamicHtml.replace(linkName, name) 
            }
    
          // Utilisation du HTML combiné et affichage du contenu dans un iframe
         return dynamicHtml;
        } catch (error) {
          console.error('Invalid JSON:', error);
        }
      };

return handleRunCode()
}


export default  EmailDataHtml