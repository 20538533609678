import React, { useState} from "react";

const loadingTicket = (array ,allticket) => {
  const {
    _id,
    subTicket,
      } = allticket
  // Vérifiez si les entrées sont valides
  if (!Array.isArray(array) || !Array.isArray(subTicket)) {
    console.error("Invalid input: both arguments must be arrays.");
    return [];
  }

  // Vérifiez si `subTicketIds` ou `array` est vide
  if (subTicket.length === 0 || array.length === 0) {
    console.warn("No data to process: subTicket or array is empty.");
    return [];
  }

  // Filtrer les objets avec les `_id` correspondants
return array.filter(v => v._id != _id).filter(item => subTicket.includes(item._id))

 };

const FormPageTicket = (props)=>{
  
  const { 
    laguangeElakay,
    infoSource,
    editAdd, 
    setEditAdd,
    editAddByJson= false,
    nameObj,
    paragra,
    setParagra,
    ObjetPage,
    edit_add,
    token,
    AlllanguageSelect,
    languageUsertra,
    languageTradictor,
    updateLanguageElakay0,
    deleteLanguageElakay0,
    buttonAddLanguage
}= props

let objValue = {
  ticket :'',
  subTicket:[],
}

const checkProdKeyExit = (ad)=>{
  let ac =  ad != undefined ? ad[0]: []
  let ent = Object.keys(ac ? ac: {});
 return ent.every(([key, val]) => ad[key] != undefined) ? true : false
  }

  const checkObjEmpty = (a)=>{
    return JSON.stringify(a) === '{}'

}

const [addSubTicket, setAddSubTicket] = useState(false)
const [allticket, setAllTicket] = useState(objValue);
const [error, setError] = useState('')

const {
  ticket,
  subTicket,
    } = allticket

const showError = () => (
  <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
  >
      {error}
  </div>
);
const checkProductTrue = (ad)=>{
  let array =  Object.values(ad).map(e => e !== '');
   return array.every(a=> a === true) && true
  }

  const checkUndefName = (a, b)=>{
    let aa  = a && Object.keys(a)
    return  a && aa.includes(b)
   } 

   const inputOK = (a, b, c)=>{
    if(c == undefined){
        return  {v : b,  s: 'form-control'}
    }else  if(c == {}){
      return {v :"",  s: 'form-control is-invalid'}
  }else if(checkUndefName(c, a)){
      return {v : c[a],  s: "form-control is-valid" }
    }else{
      return {v :"",  s: 'form-control is-invalid'}
    }
  }

  const buttonAdd =()=>{
    updateLanguageElakay0(languageTradictor._id, token, {[nameObj]:{ _id: allticket._id, ticket: ticket, subTicket: subTicket.filter(v => v._id != allticket._id)}}, true).then(data => {
        if (data.error) {
           setError(data.error)
        } else {
            AlllanguageSelect()
            languageUsertra()
            setAddSubTicket(false)
            setAllTicket(objValue)    
    }
    })
}

  const handleChange = name => event => {
    const value = event.target.value;
    setAllTicket({ ...allticket, [name]: value});
};

const handleLanguage = () => event => {
  const value = event.target.value;
  let newObj = languageTradictor[nameObj][value]

  if(value == ''){
    setAddSubTicket(false)
    setAllTicket(objValue)
  }else{
  setAddSubTicket(true)
  setAllTicket(newObj)
  }
  
  // setStylePara(languageTradictor[nameObj][value].stylePara)
  // setParagra(languageTradictor[nameObj][value])
};

const CheckInputBool = (title)=>{
  let ti =  title !== ''
  return ti   
}

  const selectObAr =()=>{
    return <div className="form-group">
           <label className="text-muted">
            {/* select languages and compare */}
            {infoSource.language(infoSource, "label", "selLanguaComp")}
            </label>
           <select onChange={handleLanguage()} className="form-control">
           <option value={''} > select</option>
           {!checkObjEmpty(languageTradictor) && languageTradictor[nameObj].map((ob, j) =>{
            return <option value={j} >{ob.ticket}</option>
           })}
           </select>
       </div> 
       }

  const InputPage = (a ,b, i)=>{
    return <div key = {i} className="form-group">
     <label className="text-muted">{b}</label>
     {b.length > 90 ?
    //  <textarea onChange={handleChange(a)} type="text" className={inpClass} placeholder={b} value={allticket[a]} rows="4"/>
    <textarea onChange={handleChange(a)}  type="text" className={'form-control'} placeholder={b} value={allticket[a]} rows="4"/>
     :
    //  <input onChange={handleChange(a)} type="text" className={inpClass} placeholder={b} value={allticket[a]} />
         <input onChange={handleChange(a)}  type="text" className={'form-control'} placeholder={b} value={allticket[a]} />
        }
     </div>
 }
 
 let entries = languageTradictor[nameObj] != undefined  && languageTradictor[nameObj].length > 0   ? Object.entries(languageTradictor[nameObj]): [];
 const handleToggle = (ID) => () => { 
  let newSubticket
  if(subTicket.includes(ID)){
    newSubticket = subTicket.filter(v => v != ID)
  }else{
    newSubticket = subTicket.concat([ID])
  }
   setAllTicket({ ...allticket, subTicket : newSubticket});
};
const checkSel = (a) => {
return subTicket.includes(a)
}

 const formSubTicket = (array, aId)=>{
return <>
 <div className="boxCategory1" id="heading">
  <h4>{ticket}</h4>  
</div>
  <div className="boxCategory">

  {array && array.filter((v, j) => j > 3 && v._id != aId).map((c, i) => (
      <form key={i} className="container" >
      <div className="itemCategory row">
    
       <div className="col col-lg-2">
                      <input
                          className="form-check-input inputCategory"
                          onChange={handleToggle(c._id)}
                          value={c._id}
                          checked={checkSel(c._id) ? "checked" : ''}
                          type="checkbox"
                          name="listName"
                          id={c._id + "_"+ c.ticket}
                      />
                      <label className="form-check-label"  for={c._id + "_"+ c.ticket}></label>
      </div>     
       <div className="col col-lg-10">  
       
      <h5>
                          {c.ticket}
      </h5>
       </div> 
      </div>
      <input className="inputCategory" type="hidden" name="listName" value=""></input>
      </form>
   ))}
</div></> 
 }

 const formInput = ()=> {
  return <div onBlur={() => setError("")}>
   <h2>Form to edit / add menu Head</h2>
   {/* {btnAddEdit()} */}
   {showError()}
   <form className="mb-3">
 {<div className="form-group">
         <label className="valuetext-muted">Title of ticket</label>
         <input onChange={handleChange('ticket')} type="text" className="form-control" placeholder={'enter your the title of ticket'} value={ticket} />
    </div>}
    {addSubTicket && formSubTicket(languageTradictor[nameObj], allticket._id)}
   </form>
  { buttonAddLanguage(buttonAdd, CheckInputBool(ticket))}

   </div>  
   }
return <>
{selectObAr()}
{formInput()}
</>
}

export default FormPageTicket 