// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catSearchInp input{
    width: 90%;
    padding: 6px;
    margin: 1% 3%;
    border-radius: 50px;
    border: 1px solid;
}

.loadind_catSearch h5 {
    border-bottom: 1px solid  rgba(0, 0, 0, 0.169);
    color: black;
    padding: 10px;
    font-size: 16px;
    margin: 2px;
    transition: 0.3s;
}

.loadind_catSearch h5:hover{
    border-bottom: 3px solid  rgb(181, 3, 3);
    background-color: #ddd;
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/includes/pages/categories/catSearch.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,8CAA8C;IAC9C,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,wCAAwC;IACxC,sBAAsB;IACtB,UAAU;AACd","sourcesContent":[".catSearchInp input{\n    width: 90%;\n    padding: 6px;\n    margin: 1% 3%;\n    border-radius: 50px;\n    border: 1px solid;\n}\n\n.loadind_catSearch h5 {\n    border-bottom: 1px solid  rgba(0, 0, 0, 0.169);\n    color: black;\n    padding: 10px;\n    font-size: 16px;\n    margin: 2px;\n    transition: 0.3s;\n}\n\n.loadind_catSearch h5:hover{\n    border-bottom: 3px solid  rgb(181, 3, 3);\n    background-color: #ddd;\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
