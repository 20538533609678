import React, { useState , useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import Barcode from "react-barcode";
import AlertPage from "./alertPage";
import { toPng } from "html-to-image";
import "./BusinessCard.css";

const BusinessCard = ({infoSource, logo, companyName, role, qrValue, barcodeValue }) => {
    const [isFront, setIsFront] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const toggleCardSide = () => {
      setIsFront(!isFront);
    };
  const frontRef = useRef(null); // Référence pour la face avant
  const backRef = useRef(null);  // Référence pour la face arrière

  // Fonction pour télécharger un div en image
  const downloadDivAsImage = (ref, filename) => {
    if (!ref.current) return;
    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${filename}.png`;
        link.click();
        alert('Image téléchargée avec succès !');
      })
      .catch((err) => {
        console.error("Error generating image", err);
      });
  };

  return (
    <div>
      <div className="business_card_container" onClick={toggleCardSide}>
      <AlertPage
        showAlert={showAlert} 
        setShowAlert={setShowAlert}
        messagetext={'Image téléchargée avec succès !'}
        /> 
        {/* Front Face */}
        {isFront ? (
        <div className="business_card front p-2" ref={frontRef}>
          <div className="business_header">
            <div className="business_logo">
              <img src={logo} alt="Company Logo" className="business_logo_image" />
            </div>
            <div className="business_company_name">{companyName}</div>
          </div>
          <div className="business_footer">
            <div className="business_role">
              <h5>{role}</h5>
            </div>
            <div className="business_qr_code_container">
              <QRCodeCanvas value={qrValue} size={85} />
            </div>
          </div>
        </div>
 ) : (
       
        <div className="business_card back p-3" ref={backRef}>
            {/* Back Face */} 
          <div className="business_back_text">
            <p>
            {infoSource.language(infoSource, "text", "textBusinessCard")}
              {/* This card is a precious key. Do not let anyone scan its QR code
              until it is activated. Once activated, the QR code grants you a
              unique account. Use this account wisely, as it symbolizes your
              ability to effectively manage teams and oversee multiple
              companies. This card can serve as proof of your skills and open
              exceptional opportunities for you. */}
            </p>
          </div>
          <div className="business_barcode">
            {/* <Barcode value={barcodeValue} /> */}
          </div>
        </div>)}
      </div>  

      {/* Download Buttons */}

      <div style={{marginTop: "20px", display: "flex", gap: "10px"}}>
     { isFront ?  <button className="btn btn-outline-dark btn-md btn-block rounded-4" onClick={() => downloadDivAsImage(frontRef, "business_card_front")}>
     {infoSource.language(infoSource, "Button", "downloadFront")}
      {/* Download Front */}
        </button>
        :
        <button className="btn btn-outline-dark btn-md btn-block rounded-4" onClick={() => downloadDivAsImage(backRef, "business_card_back")}>
          {infoSource.language(infoSource, "Button", "downloadBack")}
          {/* Download Back */}
        </button>
        }
      </div>
    </div>
  );
};

export default BusinessCard;
