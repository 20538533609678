import React, { useState, useEffect } from "react";
import { Link, useLocation} from "react-router-dom";
import Layout from "../Layout/Layout"; 
import { signout, authenticate, isAuthenticated, readMe, UserStepUP, UserAgree } from "../auth";
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import ElakayAccount from '../Layout/imgs/Elakay-account-1.png';
import RoyaltyUser from '../Layout/imgs/Royalty-user-1.png';
import SellerPub1 from '../Layout/imgs/Seller-pub-1.png';
import Modal from 'react-bootstrap/Modal';
import {Adminstrator } from "../pageInside/Rolle"
import { roleUser } from "../pageInside/roleUser";
import { laguangeElakay } from "../pageInside/dataElakay";
import UserExitActAss from "./userPageExiste/actPageAssUserExit";
import StepAboutUser from "./userPageExiste/stepAboutUser";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import jsSHA from "jssha";
import {
    ContactUs,
    TermsConditions,
    ReturnAndRefundPolicy
} from '../pageInside/conditionTherm'
var sha1 = require('sha1');
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
// import "./sliderProduct.css"
 
const UserStep = (props) => {
    const {
        infoSource
        } = props
    let query = useQuery();
    let PSA =  query.get("agreeRole") ? true : false
    // const [userInfo, setUserInfo] = useState([]); 
    // const [getCartItems, setgetCart] = useState([]);
    const [loading, setLoading] = useState(false); 
    const [AgreeC, setAgreeC] = useState(false);
    const [showConditions, setConditions] = useState(false);
    const [showRole, setShowRole] = useState(false);
    // const [showConditionsRole, setConditionsRole] = useState(PSA);
    const { token } = isAuthenticated();
    
    const [Urole, setURole] = useState({
        userID: '',
        customer: '',
        seller: '',
        provider: '',
        role: '',
        endDate: '',
        roleName: '', 
        level: '',
        UserRoleName:'',
        UserEndDate:''
    });
    const {
        userID,
        customer,
        seller,
        provider,
        role,
        endDate,
        roleName, 
        level,
        UserRoleName,
        UserEndDate
    } = Urole

    const readUser = token => {
        token  && (
        readMe(token).then(data => {
            if (data.error) {
                console.log("data Not Found");
                signout(token).then(() => { window.location.href = '/signin' })
            } else {
                setURole({
                    userID: data.user._id,
                    customer: data.role.customer,
                    seller: data.role.seller,
                    provider: data.role.provider,
                    UserRoleName: data.role.roleName,
                    UserEndDate: data.role.endDate
                })
                // if(data.role.role !== null && data.role.agreeRole === false){
                //     setConditionsRole(true)
                // }
                setLoading(false)
            }
        })
        )
    };
 
    useEffect(() => {
        readUser(token)
    }, [token]);

    const [pages, setPages]= useState({})
    const [royality, setRoyality] = useState(false)
    const [selle, setSelle] = useState(false)
    const [provvider, setProvvider] = useState(false)
    const handleCon =(a)=> {
        setAgreeC(a)
    
    }

    const handleR =(a)=> {
        setRoyality(a)
        setSelle(false)
        setProvvider(false)
      
    }
    const handleS = (a) => {
        setRoyality(false)
        setSelle(a)
        setProvvider(false)

    }

    const handleP = (a) => {
        setRoyality(false)
        setSelle(false)
        setProvvider(a)

    }

    // const clickPassword = (role, endDate, roleName) => {

    //     let level = roleName  === 'customer' &&  1 || roleName === 'seller'  && 2 || roleName  === 'provider'  && 3
      
    //     UserStepUP(token, { role, endDate, customer, seller, provider, [roleName]: true, agreeRole: true, roleName, level}).then(data => {
    //         if (data.error) {
    //             console.log(data.error)
    //         } else {
    //             setUserRoyale(false)
    //             setSeller(false)
    //             setProvider(false)
    //             authenticate(data, () => { 
                
    //                 // create a page show them the new fuction they have link to page
    //                 var redirectToURL = '/your-step';
    //                 window.location.href = redirectToURL;
                   
    //             });

    //         }
    //     })


    // }

    const userKeyRole = (roleName, id, user)=>{
            
        let kay = sha1(roleUser[id].code) + sha1(roleUser[id].role) + sha1(userID);
            let shaObj = new jsSHA("SHA-512", "TEXT", {
                hmacKey: { value: kay, format: "TEXT" },
            });
            shaObj.update("This is a ");
            shaObj.update("test");
           let role = shaObj.getHash("HEX");
           let endDate
           !user ? 
           endDate = Date.now() + 6 * 30 * 24 * 3600 * 1000
             :
           endDate = Date.now() + 30 * 24 * 3600 * 1000

           let level = roleName  === 'customer' &&  1 || roleName === 'seller'  && 2 || roleName  === 'provider'  && 3
           setPages({
            planAffaire : true,
            termCondition: false,
            userAgree: false
           })
            setShowRole(true)
           setURole({
            ...Urole,
            roleName, 
            role, 
            level, 
            endDate
           })
          
    }



    // const clickAgree = () => {
    //     UserAgree(token).then(data => {
    //         if (data.error) {
    //             console.log(data.error)
    //         } else {
    //             setUserRoyale(false)
    //             setSeller(false)
    //             setProvider(false)
    //             authenticate(data, () => { 
    //               // create a page show them the new fuction they have link to page
    //                 var redirectToURL = '/your-step';
    //                 window.location.href = redirectToURL;      
    //             });
    //         }
    //     })
    // }
    
    let images = [
        ElakayAccount,
        RoyaltyUser,
        SellerPub1
    ]

    const zoomOutProperties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: false,
        scale: 0.4,
        arrows: true
    }

    const SliderPubUser = ()=>(
        <div className="mt-2">
           <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        navigation ={infoSource && infoSource.navigation}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {
              images.map((each, index) => (
                <SwiperSlide key={index}>
                    <img key={index} style={{ width: "100%", maxHeight: "400px" }} src={each} alt="firt Step" />
                </SwiperSlide>
            ))
        }
      </Swiper>
        </div>
    )

    const AddTermsAndConditions = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={showConditions}
                    onHide={() => setConditions(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2 className="text-center">{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {TermsConditions()}
                        {ReturnAndRefundPolicy()}
                        {ContactUs()}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
     
    // const AddTermsAndRole = () => (
    //     <div className="contaire">
    //         <div className="row justify-content-center " >
    //             <Modal
    //                 size="xl"
    //                 show={showConditionsRole}
    //                 onHide={() => setConditionsRole(false)}
    //                 dialogClassName="modal-90w"
    //                 aria-labelledby="example-custom-modal-styling-title"
    //                 centered
    //             >
    //                 <Modal.Header closeButton>
    //                     <Modal.Title id="example-custom-modal-styling-title">
    //                     <h2>all to Know about Associate </h2>
    //                         {/* <h2 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).role}, {infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition} </h2> */}
    //                     </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     {/* <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).haveBeenChosen} {UserRoleName}.</h4>
    //                 <ul>
    //                         <li><Link to="#" onClick={() => setConditions(true)} >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</Link></li>
    //                         <li><Link to="/help-contact" >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).HelpContact}</Link></li>
    //                         <li className="list-unstyled">
    //                             <input
    //                                 onChange={() => handleCon(AgreeC ? false : true)}
    //                                 type="checkbox"
    //                                 className="form-check-input"
    //                             />
    //                             <label className="form-check-label">
    //                          {infoSource.language(infoSource, "title", "chButAgree")}
    //                          </label>
    //                         </li>
    //                     </ul>
    //                     {
    //                         AgreeC?
    //                     <Button onClick={() => clickAgree()}>
    //                        {infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}
    //             </Button>:
    //                             <button className="btn" >{infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}</button>
    //                     } */}

    //             <UserExitActAss
    //                laguangeElakay={laguangeElakay}
    //                infoSource={infoSource}
    //                clickAgree={clickAgree}
                
    //             />
    //                 </Modal.Body>
    //             </Modal>
    //         </div>
    //     </div>
    // )

const Stepuser=()=>(
    <div className="container mt-4">
    <Accordion>
            <Accordion.Item eventKey="0">
      
                <Accordion.Header>
                       {infoSource.laguangeElakay('title', infoSource.languageAPI).beRoyalty}
                </Accordion.Header>
                <Accordion.Body>
                        <p>{infoSource.laguangeElakay('title', infoSource.languageAPI).note}</p>
                        <ul>
                            <li><Link to="#" onClick={() => setConditions(true)} >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</Link></li>
                            <li><Link to="/help-contact" >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).HelpContact}</Link></li>
                            <li className="list-unstyled">
                                <input
                                    onChange={() => handleR(royality ? false : true)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={royality ? 'checked' : ''}
                                    // controlled="checked"
                                    id="royality"
                                />
                                <label className="form-check-label" for="royality">
                                    {infoSource.language(infoSource, "title", "chButAgree")}
                                    </label>
                            </li>
                        </ul>
                    <h6>{infoSource.laguangeElakay('text', infoSource.languageAPI).textUserStep1}</h6>
                        {
                            royality?
                        <Button onClick={() => userKeyRole('customer', 5, customer)}>
                            {infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}
                </Button>:
                                <button className="btn" > {infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}</button>
                        }
                </Accordion.Body>
            </Accordion.Item> 

            <Accordion.Item eventKey="1">
           
                <Accordion.Header>
                {infoSource.laguangeElakay('title', infoSource.languageAPI).beSeller} 
      </Accordion.Header>
        
        
                <Accordion.Body>
                    <p>{infoSource.laguangeElakay('title', infoSource.languageAPI).note}</p>
                    <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).selAssList}
                        {/* Select an Associate from the List on Elakay */}
                        </h2>
                    <h1 className="text-muted">{infoSource.laguangeElakay('title', infoSource.languageAPI).comingSoon}</h1>
               {/* <ul>
                        <li><Link to="#" onClick={() => setConditions(true)} >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</Link></li>
                            <li><Link to="/help-contact" >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).HelpContact}</Link></li>
                            <li className="list-unstyled">
                                <input
                                    onChange={() => handleS(selle ? false : true)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selle ? 'checked' : ''}
                                    // checked={selle}
                                    id="selle"
                                />
                                <label className="form-check-label" for='selle>
                               {infoSource.language(infoSource, "title", "chButAgree")}
                                 </label>
                            </li>
               </ul>
                        <h6> {infoSource.laguangeElakay('text', infoSource.languageAPI).textUserStep1}</h6>
               {
                            selle?
                <Button onClick={() => userKeyRole('seller', 4, seller)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}</Button>
                :
                     <button className="btn"> {infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}</button>
               } */}
                </Accordion.Body>
         
        </Accordion.Item>

            <Accordion.Item eventKey="2">
              
                    <Accordion.Header>
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).beProvider}
                        {/* Become a Provider / Producer */}
                    </Accordion.Header>
            <Accordion.Body>
                        <p>{infoSource.laguangeElakay('title', infoSource.languageAPI).note}</p> 
                        <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).selAssList}
                            {/* Select an Associate from the List on Elakay */}
                            </h2>  
                        <h1 className="text-muted">{infoSource.laguangeElakay('title', infoSource.languageAPI).comingSoon}</h1>
                        {/* <ul>
                            <li><Link to={"3"} onClick={() => setConditions(true)} >Terms and Conditions</Link></li>
                            <li><Link to="/help-contact" >Help & contact</Link></li>
                            <li className="list-unstyled">
                                <input
                                    onClick={() => handleP(provvider ? false : true)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={provvider ? true : false}
                                />
                                <label className="form-check-label">
                              {infoSource.language(infoSource, "title", "chButAgree")}
                                </label>
                            </li>
                        </ul>
                    <h6>{infoSource.laguangeElakay('text', infoSource.languageAPI).textUserStep1}</h6>
                        {provvider?
                        <Button onClick={() => userKeyRole('provider', 3, provider)}>
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}
                </Button>
:
                        <button className="btn">{infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}</button>
                        } */}
                     
                    </Accordion.Body>
            </Accordion.Item>
    </Accordion>
    </div>
)

    // const getStartButton = (Role_user, endDate, roleName)=>{
    //     if ((Adminstrator() || UserRoleName === roleName) &&  Date.now() < new Date(UserEndDate).getTime()){
    //             return(
    //             <div className="row justify-content-center">
    //                     <button className="m-3 btn  ">{infoSource.laguangeElakay('Button', infoSource.languageAPI).ur} {roleName}</button>
    //             </div>
    //             )
    //         } else{
              
                
    //              if( token) {
    //                  return (    <div className="row justify-content-center">
    //                 <button onClick={() => (clickPassword(Role_user, endDate, roleName))} className="m-3 btn btn-info pt-2 pb-2 pr-3 pl-3 ">{infoSource.laguangeElakay('Button', infoSource.languageAPI).getStarded}</button>
    //                </div> )
    //                 }else{
    //                 return (<div className="row justify-content-center">
    //                 <Link to="/signin" className="m-3 btn btn-info pt-2 pb-2 pr-3 pl-3 ">{infoSource.laguangeElakay('Button', infoSource.languageAPI).signfirst}</Link>
    //                 </div> 
    //                 )
    //                 }
               
                
    //         }

    //     }



    // const UserRoyale = () => {
      
    //     let kay = sha1(roleUser[5].code) + sha1(roleUser[5].role) + sha1(userID);
    //             let shaObj = new jsSHA("SHA-512", "TEXT", {
    //                 hmacKey: { value: kay, format: "TEXT" },
    //             });
    //             shaObj.update("This is a ");
    //             shaObj.update("test");
    //            let Role_user = shaObj.getHash("HEX");

    //             let roleName = "customer"
    //             let endDate
    //             !customer ? 
    //                 endDate = Date.now() + 6 * 30 * 24 * 3600 * 1000
    //             :
    //                 endDate = Date.now() + 30 * 24 * 3600 * 1000

    //     return ( 
    //       <div className="contaire">
    //         <div className="row justify-content-center " >
    //             <Modal
                

    //                 show={showUserRoyale}
    //                 onHide={() => setUserRoyale(false)}
    //                 dialogClassName="modal-90w"
    //                 aria-labelledby="example-custom-modal-styling-title"
    //                 centered
    //             >
    //                 <Modal.Header closeButton>
    //                     <Modal.Title id="example-custom-modal-styling-title">
    //                          {infoSource.laguangeElakay('title', infoSource.languageAPI).royalty}  
    //       </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     <div className="row justify-content-center">
    //                     <div className="card pl-0 pr-0 pt-4 pb-4 m-4 justify-content-center" style={{width:"300px"}}>
                           
    //                                 <h4 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).beRoyalty}</h4>
    //                        <div className="m-3 
    //                        mx-auto" style={{ width: "100px", height: "100px", padding: 0, textAlign: "center", fontSize: "1.5rem", borderRadius: "50%", border: "1px solid black" }}>
    //                                     </div>
    //                        <div>
    //                                 <h5 className="text-center mb-0"> 12<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).month} </span> <br/> 100<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).years} </span></h5>
    //                        </div>
    //                        <h5 className="text-center">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).freeShipping}</h5>
    //                       {getStartButton(Role_user, endDate, roleName)}
    
    //                     </div>        
                      
    //         </div>

    //                 </Modal.Body>
    //             </Modal>
    //         </div>
    //     </div>

    // )}

    // const SellerPrimary = () => {
       
    //     let kay = sha1(roleUser[4].code) + sha1(roleUser[4].role) + sha1(userID);
    //             let shaObj = new jsSHA("SHA-512", "TEXT", {
    //                 hmacKey: { value: kay, format: "TEXT" },
    //             });
    //             shaObj.update("This is a ");
    //             shaObj.update("test");
    //             let Role_user = shaObj.getHash("HEX");
    //             let  roleName = "seller"
    //             let endDate
    //     !seller ?
    //         endDate = Date.now() + 6 * 30 * 24 * 3600 * 1000
    //         :
    //         endDate = Date.now() + 30 * 24 * 3600 * 1000 ;

    //       return (
    //          <div className="contaire">
    //         <div className="row justify-content-center " >
    //             <Modal
                
    //                 show={showSeller}
    //                 onHide={() => setSeller(false)}
    //                 dialogClassName="modal-90w"
    //                 aria-labelledby="example-custom-modal-styling-title"
    //             >
    //                 <Modal.Header closeButton>
    //                     <Modal.Title id="example-custom-modal-styling-title">
    //                      {infoSource.laguangeElakay('title', infoSource.languageAPI).seller} 
    //       </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     <div className="row justify-content-center">
    //                         <div className="card pl-0 pr-0 pt-4 pb-4 m-4 justify-content-center" style={{ width: "300px" }}>

    //                                   <h4 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).beSeller}</h4>
    //                             <div className="m-3 mx-auto" style={{ width: "100px", height: "100px", padding: 0, textAlign: "center", fontSize: "1.5rem", borderRadius: "50%", border: "1px solid black" }}>
    //                             </div>
    //                             <div>
    //                                 <h5 className="text-center mb-0"> 34<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).month} </span> <br /> 300<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).years} </span></h5>
    //                             </div>
    //                             <h5 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).freeShippingSeller}</h5>
    //                                   {getStartButton(Role_user, endDate, roleName)}
    //                         </div>
    //                     </div>

                   

    //                 </Modal.Body>
    //             </Modal>
    //         </div>
    //     </div>

    // )}

    // const Seller = () => {
       
    //     let kay = sha1(roleUser[4].code) + sha1(roleUser[4].role) + sha1(userID);
    //             let shaObj = new jsSHA("SHA-512", "TEXT", {
    //                 hmacKey: { value: kay, format: "TEXT" },
    //             });
    //             shaObj.update("This is a ");
    //             shaObj.update("test");
    //             let Role_user = shaObj.getHash("HEX");
    //             let  roleName = "seller"
    //             let endDate
    //     !seller ?
    //         endDate = Date.now() + 6 * 30 * 24 * 3600 * 1000
    //         :
    //         endDate = Date.now() + 30 * 24 * 3600 * 1000 ;

    //       return (
    //          <div className="contaire">
    //         <div className="row justify-content-center " >
    //             <Modal
                
    //                 show={showSeller}
    //                 onHide={() => setSeller(false)}
    //                 dialogClassName="modal-90w"
    //                 aria-labelledby="example-custom-modal-styling-title"
    //             >
    //                 <Modal.Header closeButton>
    //                     <Modal.Title id="example-custom-modal-styling-title">
    //                      {infoSource.laguangeElakay('title', infoSource.languageAPI).seller} 
    //       </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     <div className="row justify-content-center">
    //                         <div className="card pl-0 pr-0 pt-4 pb-4 m-4 justify-content-center" style={{ width: "300px" }}>

    //                                   <h4 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).beSeller}</h4>
    //                             <div className="m-3 mx-auto" style={{ width: "100px", height: "100px", padding: 0, textAlign: "center", fontSize: "1.5rem", borderRadius: "50%", border: "1px solid black" }}>
    //                             </div>
    //                             <div>
    //                                 <h5 className="text-center mb-0"> 34<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).month} </span> <br /> 300<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).years} </span></h5>
    //                             </div>
    //                             <h5 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).freeShippingSeller}</h5>
    //                                   {getStartButton(Role_user, endDate, roleName)}
    //                         </div>
    //                     </div>

                   

    //                 </Modal.Body>
    //             </Modal>
    //         </div>
    //     </div>

    // )}


    // const Provider = () => { 
        
    //     let kay = sha1(roleUser[3].code) + sha1(roleUser[3].role) + sha1(userID);
    //             let shaObj = new jsSHA("SHA-512", "TEXT", {
    //                 hmacKey: { value: kay, format: "TEXT" },
    //             });
    //             shaObj.update("This is a ");
    //             shaObj.update("test");
    //             let Role_user = shaObj.getHash("HEX");

    //             let  roleName = "provider"
    //             let endDate
    //             !provider ?
    //                 endDate = Date.now() + 6 * 30 * 24 * 3600 * 1000
    //                 :
    //                 endDate = Date.now() + 30 * 24 * 3600 * 1000;

    //   return (
    //         <div className="contaire">
    //         <div className="row justify-content-center " >
    //             <Modal
    //                 show={showProvider}
    //                 onHide={() => setProvider(false)}
    //                 dialogClassName="modal-90w"
    //                 aria-labelledby="example-custom-modal-styling-title"
    //                 centered
    //             >
    //                 <Modal.Header closeButton>
    //                     <Modal.Title id="example-custom-modal-styling-title">
    //                      {infoSource.laguangeElakay('title', infoSource.languageAPI).provider}
    //       </Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     <div className="row justify-content-center">
    //                     <div className="card pl-0 pr-0 pt-4 pb-4 m-4 justify-content-center" style={{ width: "300px" }}>

    //                               <h4 className="text-center">{infoSource.laguangeElakay('sell', infoSource.languageAPI).becomeProvider}</h4>
    //                         <div className="m-3 mx-auto" style={{ width: "100px", height: "100px", padding: 0, textAlign: "center", fontSize: "1.5rem", borderRadius: "50%", border: "1px solid black" }}>
    //                         </div>
    //                         <div>
    //                             <h5 className="text-center mb-0"> 66<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).month} </span> <br /> 600<span style={{ fontSize: "22px" }}>/{infoSource.laguangeElakay('label', infoSource.languageAPI).years} </span></h5>
    //                         </div>
    //                             <h5 className="text-center">{infoSource.laguangeElakay('title', infoSource.languageAPI).freeShippingBigSeller}</h5>
    //                               <div className="row justify-content-center">
    //                                   <button className="m-3 btn  ">{infoSource.laguangeElakay('title', infoSource.languageAPI).comingSoon}</button>
    //                               </div>
    //                               {/* {getStartButton(Role_user, endDate, roleName)} */}

    //                     </div>
    //                     </div>

    //                 </Modal.Body>
    //             </Modal>
    //         </div>
    //     </div>

    // )}

    return (
        <Layout
            infoSource ={infoSource}
            title="UserStep Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            loading={loading}
        >
            <div style={infoSource.styleNav}>
            {SliderPubUser()}
            {Stepuser()}
            {AddTermsAndConditions()}
            <StepAboutUser
             showRole ={showRole}
             setShowRole={setShowRole}
             laguangeElakay= {laguangeElakay}
             infoSource={infoSource}
             token={token}
             userActiveRole={Urole}
             pages ={pages}
             setPages ={setPages}
            />
            {/* {AddTermsAndRole()} */}
        </div>
        </Layout>
    );
};

export default UserStep;