import React, { useState} from "react";
import { Link, useParams, useNavigate} from 'react-router-dom';
import {userActivation, reSendCodeSecurity, authenticate, isAuthenticated} from "../includes/auth";
import Form from 'react-bootstrap/Form'
import logoElakay from '../includes/Layout/icon/LogoElakay.svg';
import './user.css';

const Active = (props) => {

    const {
        infoSource
    }=props
    let params = useParams()
    const [values, setValues] = useState({
        token: params.token,
        numberDigi: "",
        error: "",
        loading: false,
        Suc: false,
        redirectToReferrer: false
    });

    let navigate = useNavigate()
    const { token, numberDigi, loading, Suc, error, redirectToReferrer } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, Suc: false, [name]: event.target.value });
    };
 


    const clickSubmit = () => {
        setValues({ ...values, error: false, loading: true });
        userActivation({token, numberDigi})
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false, Suc: false});
                } else {  
                    authenticate(data, () => {
                        setValues({
                            ...values,
                            redirectToReferrer: true
                        });
                    });
                    window.location.reload()
                }
            });
    };

    const resendCode = () => {
        setValues({ ...values, error: false, loading: true });
        reSendCodeSecurity({ token, numberDigi })
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false, Suc: false});
                } else {
                    setValues({
                        ...values,
                        Suc: true,
                        error: false,
                        loading: false
                    });
                }
            });
    };


    const signUpForm = () => (


        <div>
         <div className="usHeader">
                <Link to="/"><h1><img className="img-responsive" src={logoElakay} width="24" alt="logo elakay"/>elakay</h1></Link>
            </div> <br />
<div className="form-signin">

            <form >
                    <h6 className="text-center m-4">{infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text0}</h6><h6 className="text-center m-4">* * * * * * * *</h6> <h6 className="text-center m-4">{infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text2}</h6> 
                {showLoading()}
                {showError()}
                {suscceSendCode()}

                <div className="form-label-group">
                        <Form.Control onChange={handleChange("numberDigi")} type="text" name="numberDigi" id="inputNumberdigi" maxlength="8" className="form-control" placeholder="Secure cerification code" required />
                    <Form.Label htmlFor="inputNumberdigi">{infoSource.laguangeElakay('label', infoSource.languageAPI).secuCode}</Form.Label>
                </div>


                <input type="hidden" name="verication" placeholder="" />
  
            </form>
            <div className="form-group mt-4">
                    <button className="btn btn-outline-primary" onClick={() => clickSubmit()} >
                        {infoSource.language(infoSource, 'Button', "submit")}
                        </button>
            </div>
                <h6 className="text-center m-4">{infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text4}</h6>
                <h6 className="text-center m-4"> <Link to="#" onClick={() => resendCode()}>
                {infoSource.laguangeElakay('Link', infoSource.languageAPI).newCode}
                 </Link> </h6>
        </div>
        </div>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
            <h6 className="text-center m-4"> <Link to="/Signup">
            {infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount}
            </Link> </h6>
        </div>
    );



    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>
                    {/* Loading... */}
                    {infoSource.language(infoSource, "title", "loanding")}
                    </h2>
            </div>
        );

    const suscceSendCode = () =>
        Suc && (
            <div className="alert alert-info"
                style={{ display: Suc ? "" : "none" }}
            >
                <h6 className="text-center m-4">{infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text5}</h6>
                <h6 className="text-center m-4"> <Link to="/Signup">{infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount}</Link> </h6>
            </div>
        );

  
    const redirectUser1 = () => {
        if (redirectToReferrer) {
            return window.location.href = '/signin';
        }
    }
    const redirectUser = () => { 
        if (isAuthenticated()) {
            return navigate("/")
               }
           }
   

    return (
        <div>
            {signUpForm()}
            {redirectUser1()}
            {redirectUser()}
            {/* {redirectUser0()}
            {redirectUser1()} */}

        </div>
    );
};
export default Active;
