import React from "react";
import { Link} from "react-router-dom";
import Layout from "../Layout/Layout";
import {FaAddressCard, FaMapMarkerAlt, FaUserAlt, FaUserCog, FaBoxes, FaShoppingCart, FaUserSecret, FaMoneyBill, FaHandsHelping , FaPhoneAlt} from "react-icons/fa";
import {  MdMessage, MdToday, MdContactPhone, MdAlternateEmail} from "react-icons/md";
import { BsGridFill } from "react-icons/bs";
import { GrAscend, GrBusinessService } from "react-icons/gr";
import Table from 'react-bootstrap/Table'
import { Row } from 'react-bootstrap';
import { Family, Adminstrator, Investment, Assoociate, Provider, Seller } from "../pageInside/Rolle"
import { laguangeElakay } from "../pageInside/dataElakay";
// import "./sliderProduct.css"
// import { Seller } from "../pageInside/Rolle"



const UserAccount = (props) => {
    const {
        infoSource
        } = props

    let userInfo = infoSource.userInfo ? infoSource.userInfo :[]

    let colClass ="col p-1"
    const roleUser =()=>(
        <>
        {
        Seller(true) && (
             <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).seller}</span>
        )}
         { Provider(true) && (
             <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).provider}</h4>
        )}
           {
        Assoociate(true) && (
             <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).assoociate}</span>
        )}   {
        Investment(true) && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).investment}</span>
            )}
       {Adminstrator(true) && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).Admin}</span>
        )}
        {Family() && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).family}</span>
        )}
        </>
    )
    let telephone =  infoSource.phoneUser && infoSource.phoneUser.filter(k => k.default === true)[0]
    let AddressTrue  = infoSource.address && infoSource.address.filter(k => k.addressDefault === true)[0]

    const infoUser = (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourInfo}  {roleUser()}</h3>
                </div>
                <div className="m-3">
               
                <Table>
                <tbody>
                    <tr>
                        <td><h4><span style={{ fontSize: "30px", paddingRight: "20px" }}><FaUserAlt /></span>  {`${userInfo.firstname} ${userInfo.lastname}`} </h4></td>
                    </tr>
                    <tr>
                        <td><h5><span style={{ fontSize: "30px", paddingRight: "20px" }}><MdAlternateEmail /></span>{userInfo.email} </h5></td>
                    </tr>
                    
                    {    telephone &&   <tr><td>
                    <div>
                                <h5>
                                 <FaPhoneAlt /> {telephone.International}
                                </h5>
                            </div>
                            </td></tr>}
                   {AddressTrue &&  <tr><td>
                                <h5><span> <FaMapMarkerAlt />  { AddressTrue.firstName} {AddressTrue.lastName}</span> <br /> <span style={{ paddingLeft: "20px" }}>  {AddressTrue.Address1}, {AddressTrue.city} {AddressTrue.State} {AddressTrue.postalCode} {AddressTrue.country}</span> <br />
                                    <span style={{ paddingLeft: "21px", fontSize: "14px" }}> {infoSource.laguangeElakay('title', infoSource.languageAPI).defaultDeliryAdress}</span>
                                </h5>
                                </td></tr>}
                    </tbody>
                </Table>
                {/* <Link to="/address">{infoSource.laguangeElakay('Link', infoSource.languageAPI).changeAddress}</Link> */}
            </div></div>
        </div>
    ) 


    const menu = (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="container p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).menu}</h3>
                </div>
                <div className="container-fluid">
                    <Row xs={2} sm={2} md={3} lg={3} xl={3}>

                        <div className={colClass}>
                        <Link to="/user-serting">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaUserCog /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).userSetting}</div>
                            </div>
                        </Link>
                        </div>

                        <div  className={colClass}>
                            <Link to="/all-catageories">
                            <div className="card">
                                
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <BsGridFill /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).allCategory}</div>
                           
                            </div> </Link>

                        </div>
                       
                        <div  className={colClass}>
                            <Link to="/your-step">
                            <div className="card">
                                
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <GrAscend /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).yourStep}</div>
                           
                            </div> </Link>

                        </div>
                        <div className={colClass}>
                        <Link to="/profile">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaUserSecret /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).myProfile}</div>
                            </div>
                        </Link>
                        </div>
                        
                        <div className={colClass}> 
                        <Link to="/profile?addr=true">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaAddressCard /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('Button', infoSource.languageAPI).addAdress}</div>
                            </div>
                        </Link>
                        </div>

                    <div className={colClass}>
                        <Link to="/profile?tel=true">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <MdContactPhone/></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('Button', infoSource.languageAPI).addTel}</div>
                            </div>
                        </Link>
                    </div>
                        
                        <div className={colClass}>  
                        <Link to="/c/orders">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaBoxes /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).myOrders}</div>
                            </div>
                        </Link>
                        </div> 
                        <div className={colClass}>
                        <Link to="/cart">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaShoppingCart /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).myCart}</div>
                            </div>
                        </Link>
                        </div>
                        <div className={colClass}>
                        <Link to="/message">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <MdMessage /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).myMessage}</div>
                            </div>
                        </Link>
                        </div>
                       
                        <div className={colClass}>
                        <Link to="/deal-today">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <MdToday /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).todayDeal}</div>
                            </div>
                        </Link>
                        </div>
                        <div className={colClass}>
                        <Link to="/help-contact">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaHandsHelping /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).helpContact}</div>
                            </div>
                        </Link></div>
                        <div className={colClass}>
                        <Link to="/purchased">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaMoneyBill /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).myPurchased}</div>
                            </div>
                        </Link>
                        </div>
                 </Row>
                </div>
            </div>
        </div>
    )


 

    return (
        <Layout
        infoSource ={infoSource}
            title="UserAccount Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            loading={false}
        >
        <div style={infoSource.styleNav}>
            {infoUser}
            {menu}
           </div>
        </Layout>
    );
};

export default UserAccount;