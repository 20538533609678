import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {isAuthenticated } from '../auth';
import { Link, useParams} from "react-router-dom";
import {FaRegTrashAlt, FaLongArrowAltLeft, FaLink,  FaEdit} from "react-icons/fa";
import CheckboxFilter from "../pageInside/CheckBoxFilter";
import { getFilterName,  UnlinkFTC, linkFTC,  getCategoriesByID, loadAllCategorie } from "../auth/apiCategory";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import "./category.css"

const categoyseleted = [];
const categoyIDseleted = [];

const checkSel = (a, n) => {

    let c

    a.forEach(Item => {
        if (n === Item._id._id) {
            c = true
        }
    })
    return c
}

const categorySeleted = (a =[], fo, va ) => (
    <div>
       
        <div className="boxCategory">
        <div className="container mb-4">
<div className="itemCategory row ">
    <h4>{a.name} Sub categories</h4>
</div></div>
            {a.items && a.items.map((c, i) => (
             <form key={i} className="container">
                    <div className="itemCategory row ">
                    <div className= {`col col-lg-2 Cacol ${va.ca}`} >
                        <p>
                           { c.supCategoryID.filter.length > 0 ?
                             "X"
                            :
                            <Link to={`/LinkCategory/${c.supCategoryID._id}`}><FaLink/></Link>
                        }
                            </p>
                    </div>
                        <div className={`col col-lg-6 Cacol ${va.ca}`}>
                        <Link to={`/category?i=${c.supCategoryID._id}`}>
                               {c.supCategoryID.name[0]} 
                               {/* {infoSource.getCategoryNames(c).name[0]} */}
                        </Link>

                        </div>
                        <div className={`col col-lg-2 Cacol ${va.ca}`}>
                                <p className="dellCategory"><FaEdit /></p>
                        </div>
                        {/* <div className={`col col-lg-2 Cacol ${va.ca}`}>
                                <p onClick={() => fo.b(c.supCategoryID.name)} className="dellCategory"><FaRegTrashAlt /></p>
                        </div> */}
                    </div>
                    <input className="inputCategory" type="hidden" name="listName" value=""></input>
                        {/* {
                           ( c.supCategoryID.name === va.sn) && (
                            fo.c(c.supCategoryID.name, va.id, c.supCategoryID._id, i)
                           )
                            }  */}
                
                    </form>
            ))}
        </div>

    </div>
)

const categorySeletedALL = (a =[], fo, va, Sn ) => (
    <div>
       
        <div className="boxCategory">
            <div className="container">
<div className="itemCategory row ">
    {Sn()}
</div></div>
            {a && a.map((c, i) => (
             <form key={i} className="container">
                    <div className="itemCategory row ">
                    <div className= {`col col-lg-2 Cacol ${va.ca}`} >
                            <input
                                // onChange={handleToggle(catID, c._id, token, c.name)}
                                value={c._id}
                                // checked={checkSel(c._id) ? "checked" : ''}
                                type="checkbox"
                                className="form-check-input inputCategory"
                                name="listName"
                                id={c._id+ "_"+ c.name}
                                />
                            <label className="form-check-label"  for={c._id+ "_"+ c.name}></label>
                    </div>
                        <div className={`col col-lg-6 Cacol ${va.ca}`}>
                            <p onClick={() => fo.a(c._id)} >
                               {c.name} 
                            </p>

                        </div>
                        <div className={`col col-lg-2 Cacol ${va.ca}`}>
                                {/* <p className="dellCategory"><FaEdit /></p> */}
                        </div>
                        {/* <div className={`col col-lg-2 Cacol ${va.ca}`}>
                                <p onClick={() => fo.b(c.name)} className="dellCategory"><FaRegTrashAlt /></p>
                        </div> */}
                    </div>
                    <input className="inputCategory" type="hidden" name="listName" value=""></input>
                        {/* {
                           ( c.name === va.sn) && (
                            fo.c(c.name, va.id, c._id, i)
                           )
                            }  */}
                
                    </form>
            ))}
        </div>

    </div>
)


const LinkCatToCat = props => {
    const {
        infoSource,
        laguangeElakay
        } = props
    let params = useParams()
     const {token } = isAuthenticated();
    const [name, setName] = useState("");
    const [categories, setCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [level, setLevel] = useState(0);
    const [search, setSearch] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [message, setMessage] = useState(false);
    const catID = params.categoryId
    const [showDeleteAgree, setDeleteAgree] = useState(false);
    const [chek, setChek] = useState(false);
    const handl = (a) => {
        setChek(a)
    }

    useEffect(() => {  
        loadCategory(catID)
        loadingAllCategories(level, search)
    }, [catID, token])

    const categoryLink = elakayCategoryID =>{
        loadCategory(elakayCategoryID, token);
            categoyseleted.push(categories.name)
            categoyIDseleted.push(categories._id)
            // setBackID(elakayCategoryID)
        }

    const clickBack = (elakayCategoryID) => {
        loadCategory(elakayCategoryID, token)
        categoyIDseleted.pop()
        categoyseleted.pop()
    }

    const SubmitB = (name) => {
       
    }


    const loadingAllCategories = (level, sea) => {
        loadAllCategorie(level, sea).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {  
                setAllCategories(data)       
            }
        });
    };

    const loadCategory = (id) => {
        setLoading(true) 
        getCategoriesByID(id).then(data => {
            if (data.error) {
                // createFirstCategory(token)
                console.log(data.error);
            } else {  
                setCategories(data.cartegory)
                setName('')
                setLoading(false)        
            }
        });
    };
    
let urlLink = `/filter/${categories._id}`;


const handleChange = event => {
    setName(event.target.value);
};

    const removeFilter = (catID, filID, token) => {
        UnlinkFTC(catID, filID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setDeleteAgree(false)
                setChek(false)
                checkSel(filID)
            }
        });

    };

    const handleToggle = (catID, filID, token, name) => () => {
        // return the first index or -1
        // deleteAgree(name, catID, filID, token) 
        linkFTC(catID, filID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
             if (data.Success){

                }else{
                    SubmitB(name)
                } 
                checkSel(filID)
            }
        });    
    };

    const checkSel = (a) => { 
        let c
         a.forEach(Item => {
            if (a === Item._id._id) {
                c = true
            }
        })

        return c
    }

    const deleteAgree = (name, catID, filID, token) => {

        return (
            <div className="contaire">
                <div className="row justify-content-center " >
                    <Modal
                        show={showDeleteAgree}
                        onHide={() => setDeleteAgree(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h4 className="text-center">
                                    {/* delete this items */}
                                    {infoSource.language(infoSource, "title", "delThtems")}
                                    </h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row justify-content-center m-4">
                                <h5>
                                    {/* are you sure you want to delete this category name's  */}
                                    {infoSource.language(infoSource, "title", "surToDelete")}
                                    <span className="text-danger" > {name}</span> </h5>
                                <div className="list-unstyled">
                                    <input
                                        onChange={() => handl(chek ? false : true)}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={chek ? true : false}
                                        id="surToDelete"
                                    />
                                    <label className="form-check-label" for="surToDelete">
                                        {/* check the button if your are agree */}
                                        {infoSource.language(infoSource, "title", "chButAgree")}
                                        </label>
                                </div>
                                {
                                    chek ?
                                        <Button onClick={() => removeFilter(catID, filID, token)}>
                                           {infoSource.language(infoSource, 'Button', "submit")}
                                        </Button> :
                                        <button className="btn" > {infoSource.language(infoSource, 'Button', "submit")} </button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }

    const handlesLevel = () => event => {
        let l = event.target.value;
        setLevel(l);
        loadingAllCategories(l, search)
    };
    const handleSearch = () => event => {
        let sea = event.target.value;
        setSearch(sea);
        loadingAllCategories(level, sea)
    };
    const selectLevel= () => (
       
     
            <div className="form-group m-4">
            
            <select onChange={handlesLevel('level')} className="form-control">
                            <option value={level} >
                                {/* select categories leve */}
                                {infoSource.language(infoSource, "label", "selCatleve")}
                                </option>
                            <option value="0">All level</option>
                            {/* <option value="1">1</option> */}
                            <option value="2">level 1</option>
                            <option value="3">level 2</option>
                            <option value="4">level 3</option>
                            <option value="5">level 5</option>
                            <option value="7">level 6</option>
                            <option value="8">level 7</option>
                            <option value="9">level 8</option>
                            <option value="10">level 9</option>
                            <option value="AddFil">
                                {/* All last level */}
                                {infoSource.language(infoSource, "label", "allLasLevel")}
                                </option>
                        </select>
            </div>
      
    )
    const searByCatName = () => (
       
     
        <div className="form-group">
        
        <div className="form-group">
                        <label className="text-muted">
                            {/* categories search by name */}
                            {infoSource.language(infoSource, "label", "catSearchName")}
                            </label>
                        <input onChange={handleSearch('search')} type="text" className="form-control" value={search} />
                    </div>
        </div>
  
)
  const CategoryFom = () =>(
<div>
<div className="boxCategory1" id="heading">
                
                <h2>  {categories.name}
                    {/* is the level  */}
                    {infoSource.language(infoSource, "label", "isTheLevel")}
                    {categories.categoryID && categories.categoryID.length - 1}</h2>
                <div className="text-center  text-muted">{categoyseleted.map((l, j) => (<p key={j} className="d-inline" >{l}{" / "}</p>))}</div>
                {showSuccess()}
                {showError()}
            </div>
    {selectLevel()}
      <div className="row mt-3">
   <div className="col col-12 col-md-6 col-lg-6">
   {categorySeleted(categories, { a:categoryLink, b:SubmitB}, {} )}
          
     </div>
        <div className="col col-12 col-md-6 col-lg-6">
            {categorySeletedALL(allCategories, { a:categoryLink, b:SubmitB}, {}, searByCatName )}
      </div> 
   
      </div>
      </div>

  );

  const createCategoryButton = () =>{
        return( 
            <Link to="/category" className="btn btn-outline-primary mt-2 mb-2">
                {/* View Elakay Category */}
                {infoSource.language(infoSource, "Button", "VieElaCat")}
            </Link>
    )
  }
    const showSuccess = () => {
        if (success) {
            return <div className="alert alert-info" style={{ display: message ? "" : "none" }}> {message} </div>;
        }
    };

    const showError = () => {
        if (error) {
            return <div className="alert alert-danger" style={{ display: error ? "" : "none" }}> {error} </div>;
        }
    };


return (
<Layout 
        infoSource ={infoSource}
        className="container blockEl"
        SliderPunShow={false}
        loading={loading}
  >
    <div style={infoSource.styleNav}>
  <div className="row">
            <div className="col-md-8 offset-md-2  blockEl" onBlur={() => setMessage("")}>
               {CategoryFom()}
            <div className="container">

               {createCategoryButton()}
            </div>
         </div>
</div></div>
  </Layout>
);

}; 

export default LinkCatToCat;