import React  from "react";
import { laguangeElakay } from "../pageInside/dataElakay";

const ButtonPagination = ({
    size,
    skip,
    limit,
    siseProduct,
    loadMore,
    loadMore0,
    infoSource,
    buttonClassL = 'btn btn-primary  pt-2 pb-2 pr-2 pl-2 rounded-4 float-left',
    buttonClassLD = 'btn pt-2 pb-2 pr-2 pl-2 rounded-4 float-left',
    buttonClassR = 'btn btn-primary pt-2 pb-2 pr-2 pl-2  rounded-4  float-right',
    buttonClassRD = 'btn pt-2 pb-2 pr-2 pl-2 rounded-4 float-right'
}) => {


    const loadMoreButton = () => {
        return (
            size > 0 &&
                siseProduct > (skip + limit) ? (
                <button onClick={loadMore} className={buttonClassR} style={{ width: "120px" }}>
                   {infoSource.laguangeElakay('Button', infoSource.languageAPI).next}
                </button>
            ) : (
                <button className={buttonClassRD} style={{ width: "120px" }}>
                  {infoSource.laguangeElakay('Button', infoSource.languageAPI).next}
                </button>
            )
        );
    };

    const loadMoreButton0 = () => {
        return (
            skip > 0 ? (
                <button onClick={loadMore0} className={buttonClassL} style={{ width: "120px" }}>
                  {infoSource.laguangeElakay('Button', infoSource.languageAPI).previous}
                </button>
            ) : (
                <button className={buttonClassLD} style={{ width: "120px" }}>
                  {infoSource.laguangeElakay('Button', infoSource.languageAPI).previous}
                </button>
            )
        );
    };
    return (
        <div className="row justify-content-center">
            <div className="col col-sm-8 col-lg-5">
                <div className="bg clearfix ">
                    {loadMoreButton0()}
                    {loadMoreButton()}
                </div>
            </div></div>
    )
};

export default ButtonPagination;
