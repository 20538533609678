import React, { useState, useEffect, useRef } from "react";
import Header from "../Layout/Header";
import HeaderShopSeller from "../Layout/headerShopSeller";
import "../Layout/allHeader.css"

import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import ShopUserView from "./lakayUser/ShopUser";
import { prices } from "../pageInside/fixedPrices";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ShopUser = props => { 
    let query = useQuery();
    let params = useParams()
    const [loading, setLoading] = useState(false);
    const[filterShop, setFilterShop] = useState(false)
    const [getCalFilShop, setCalFilShop] = useState([]);
    const [infoHeaderShop, setInfoHeaderShop]= useState({})
       const [lastScrollY, setLastScrollY] = useState(0);
       const [activeHeader, setActiveHeader] = useState(2);
       const startY = useRef(0);
       const isSwiping = useRef(false);
       const timeoutRef = useRef(null);
       const swipeThreshold = 50;
       const animationDuration = 300;
    
       const [myFilters, setMyFilters] = useState({
            filters: {"categoriesID.gategoriesLink": [] }
        });

    const {
        infoSource,
        timeoutDuration = 5000
            } = props
            const {
                readUser,
            } = infoSource

            let ci =  query.get("ci")      
            let userID = params.id;
            let ShopName = params.SEl;

  const handlePrice = value => {
 
         let array = [];
         for (let key in prices) {
             if (prices[key]._id === parseInt(value)) {
                 array = prices[key].array;
             }}
         return array;
     };
 
     const handleFilters = (filters, filterBy) => {
         const newFilters = { ...myFilters };
         newFilters.filters[filterBy] = filters;
 
         if (filterBy === "price") {
             let priceValues = handlePrice(filters);
             newFilters.filters[filterBy] = priceValues;
         }
        
         setMyFilters(newFilters);
     };
        // Fonction pour démarrer le setTimeout
   const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setActiveHeader(2);
    }, 3000); // Délai de 3 secondes
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // Défilement vers le bas (swipe up)    
        setActiveHeader(1);

    }else {
      setActiveHeader(2);
    }

    setLastScrollY(currentScrollY);
  };

  // Fonction pour annuler le setTimeout
  const cancelTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); 
    }
  };

  // Gestion des événements tactiles et souris
  const handleStart = (clientY) => {
    startY.current = clientY;
    isSwiping.current = true;
  };

  const handleMove = (clientY) => {
    if (!isSwiping.current) return;
    const deltaY = clientY - startY.current;
    
    if (Math.abs(deltaY) > swipeThreshold) {
      setActiveHeader(deltaY > 0 ? 1 : 2);
      isSwiping.current = false;
    }
  };

  const handleEnd = () => {
    isSwiping.current = false;
  };

  // Configuration des timeouts
     useEffect(() => {
       
       if (activeHeader === 1) {
         timeoutRef.current = setTimeout(() => {
           setActiveHeader(2);
         }, timeoutDuration);
       }
       window.addEventListener("scroll", handleScroll);
       return () => {window.removeEventListener("scroll", handleScroll); clearTimeout(timeoutRef.current)}
       // return () => clearTimeout(timeoutRef.current);
     }, [activeHeader, timeoutDuration, lastScrollY]);

     const allHeaderShop = (drawerClickHander)=>{
       return <div>
        {/* Header 1 */}
       <div
         className={`header0 header-primary ${
           activeHeader === 1 ? 'active' : activeHeader === 2 ? 'hidden' : ''
         }`}
         onMouseEnter={cancelTimeout} // Stop le timeout quand la souris entre
         onMouseLeave={startTimeout}  // Redémarre le timeout quand la souris quitte
       >
          <Header infoSource={infoSource} drawerClickHander={drawerClickHander} setActiveHeader={setActiveHeader} outOffPage={false}/>
       </div>
 
       {/* Header 2 */}
       <div
         className={`header0 header-secondary ${
           activeHeader === 2 ? 'active' : activeHeader === 1 ? 'hidden' : ''
         }`}
       >
        <HeaderShopSeller 
              infoSource={infoSource}
              userID={userID}
              drawerClickHander={drawerClickHander} 
              setFilterShop={infoHeaderShop.setFilterShop}
              onFilterShop={infoHeaderShop.onFilterShop}
              shopName={infoHeaderShop.shopName}
              loadindSearCh={infoHeaderShop.loadindSearCh}
              search={infoHeaderShop.search}
              setActiveHeader={setActiveHeader}
                />
       </div>

        </div>
     }

    return (
        <div
        className="swipe-container"
        onTouchStart={(e) => handleStart(e.touches[0].clientY)}
        onTouchMove={(e) => handleMove(e.touches[0].clientY)}
        onTouchEnd={handleEnd}
        onMouseDown={(e) => handleStart(e.clientY)}
        onMouseMove={(e) => handleMove(e.clientY)}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
      >
        <Layout
            className="container-fluid content"
            loading={loading}
            infoSource ={infoSource}
            SliderPunShow={false}
            filterShop={filterShop}
            setFilterShop={setFilterShop}
            handleFilters={ handleFilters}
            setMyFilters={setMyFilters}
            userShop= {true}
            outOffPage = {true}
            getCalFilShop={getCalFilShop}
            infoHeaderShop={infoHeaderShop}
            allHeaderShop={allHeaderShop}
        >
         
            <ShopUserView
            setInfoHeaderShop={setInfoHeaderShop}
            prices={prices}
             setLoading={setLoading}
             infoSource={infoSource}
             outOffPage = {false}
            //  readUser={readUser}
            //  ShopName={ShopName}
             userID={userID}
             setFilterShop={setFilterShop}
             setCalFilShop={setCalFilShop}
             myFilters={myFilters}
             ci={ci}
            />
        </Layout>
        </div>
    );
};

export default ShopUser;