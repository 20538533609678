import { elakay_API } from "../../config";

// --------------------------Create firts Category-------------------------------
export const FirstCategory = (token, category) => {
    return fetch(`${elakay_API}/categories/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(category)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Create Category-------------------------------
export const createCategory = (token, category, elakayCategoryID) => {
    return fetch(`${elakay_API}/categories/createSupCategory/${elakayCategoryID}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(category)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Reading Categories By ID-------------------------------
export const getCategoriesByID = elakayCategoryID => { 
    return fetch(`${elakay_API}/categories/readCategoryByID/${elakayCategoryID}`, {
        method: "GET"
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getCategoriesByName = (skip, limit, params) => { 
    return fetch(`${elakay_API}/categories/cartegoryShearch?search=${params}&skip=${skip}&order=desc&limit=${limit}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const loadAllCategorie = (a, n) => { 
    return fetch(`${elakay_API}/Allcategories?Level=${a}&name=${n}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// --------------------------Delete Category And Sub Category-------------------------------
export const deleteItemAndCategory = (categoryID, subCategoryID, token) => {
    return fetch(`${elakay_API}/categories/deleteItems/${categoryID}/${subCategoryID}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// --------------------------Create Filter Name for Product-------------------------------
export const createFilterName = (categoryID, token, name) => {
    return fetch(`${elakay_API}/categories/updateFilterName/${categoryID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Creat a filters--------------------------------
export const AddFilterName = (token, name) => {

    return fetch(`${elakay_API}/filters/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getFilterName = (token) => {
    return fetch(`${elakay_API}/filters/readFilters`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getFilterByID = (filterID, token) => {
    return fetch(`${elakay_API}/filters/readFiltersByID/${filterID}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const ReadFilterSelected = (catID, token) => {
    return fetch(`${elakay_API}/filters/ReadFilterSelected/${catID}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deteleFilter = (filterID, token) => {
    return fetch(`${elakay_API}/filters/deleteFilter/${filterID}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));

}

export const updateTranslFil = (token,  filter, filterID) => {
    return fetch(`${elakay_API}/filters/updateTranslFil/${filterID}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(filter)

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateFilter = (token, filterID,  filter) => {
    return fetch(`${elakay_API}/filters/updateFilter/${filterID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(filter)

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateFiltersValue = (token, filterID,  name) => {
    return fetch(`${elakay_API}/filters/updateFilterItem/${filterID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const removeFilterValue = (filterID, name, token) => {
    return fetch(`${elakay_API}/filters/DeleteFilterValue/${filterID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const linkFTC = (catID, filID, token) => {
    return fetch(`${elakay_API}/filters/linkFTC/${catID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UnlinkFTC = (catID, filID, token) => {
    return fetch(`${elakay_API}/filters/UnlinkFTC/${catID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const linkVFTC = (catID, filID, name, token) => {
    return fetch(`${elakay_API}/filters/linkVFTC/${catID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UnlinkVFTC = (catID, filID, name, token) => {
    return fetch(`${elakay_API}/filters/UnlinkVFTC/${catID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Delete Filter Name And Filter Value-------------------------------
export const deteleFilterName = (categoryID, filterID, token) => {
    return fetch(`${elakay_API}/categories/deleteFilter/${categoryID}/${filterID}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));

}

// --------------------------Delete Filter Value-------------------------------
export const deleteFilterValue = (categoryID, token, Filters) => {
    return fetch(`${elakay_API}/categories/deleteFilterValue/${categoryID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ Filters })

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Read Filter Value-------------------------------
export const readFilterValue = (elakayCategoryID, elakayfilterID) => {
    return fetch(`${elakay_API}/categories/readFilterValue/${elakayCategoryID}/${elakayfilterID}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
        
};

// --------------------------Update Filter Value -------------------------------
export const updateFilterValue = (categoryID, token, name, Filters) => {
    return fetch(`${elakay_API}/categories/updateFilterValue/${categoryID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ name, Filters })

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

// --------------------------Add a Pub 1500 X 400-------------------------------
export const AddPub1500X400 = (token, day, categoryID, photo) => {

    const body = new FormData()
    body.append('photo', photo)

    return fetch(`${elakay_API}/categories/Pub1500X400/${categoryID}?timer=${day}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


// --------------------------Add a Pub C 400 X 400--------------------------------
export const AddPubC400X400 = (token, day, categoryID, photo) => {

    const body = new FormData()
    body.append('photo', photo)

    return fetch(`${elakay_API}/categories/PubC400X400/${categoryID}?timer=${day}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Add a Pub R 400 X 400--------------------------------
export const AddPubR400X400 = (token, day, categoryID, photo) => {

    const body = new FormData()
    body.append('photo', photo)

    return fetch(`${elakay_API}/categories/PubR400X400/${categoryID}?timer=${day}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// --------------------------Add a Pub R 400 X 400--------------------------------
export const DeletePub = (token, categoryID, pubID, pub) => {

    return fetch(`${elakay_API}/categories/deletepub/${categoryID}/${pubID}/${pub}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UpadateNameCat = (token, name, CategoryID) => {
    return fetch(`${elakay_API}/categories/UpadateNameCat/${CategoryID}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(name)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateTranslationsCat = (token, catTranslate, CategoryID) => {
    return fetch(`${elakay_API}/categories/updateTranslations/${CategoryID}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(catTranslate)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UnlinkCTC = (categoryID, subCategoryID, token) => {
    return fetch(`${elakay_API}/categories/UnlinkCTC/${categoryID}/${subCategoryID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const LinkCTC = (categoryID, subCategoryID, token) => {
    return fetch(`${elakay_API}/categories/linkCTC/${categoryID}/${subCategoryID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};