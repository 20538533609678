import React, { useState} from "react";
import imgLog3 from '../../imgs_steps/IMG_003.png';
import { Family} from "../../pageInside/Rolle"
import notData  from '../../Layout/imgs/nodata0.png'
import Modal from 'react-bootstrap/Modal';
import Pages from '.'

var fileAdding ={
    title:'',
    error:'' ,
    message:''  
}

const FormPageErrorElakay = (props) => {
    const { 
        editAdd, 
        setEditAdd,
        editAddByJson= false,
        menu, 
        nameObj,
        objet,
        setObejet,
        infoElakay,
        edit_add,
        token,
        Alllanguage,
        AlllanguageSelect,
        getDataEmail,
        getDataMessErr,
        languageUsertra,
        languageTradictor,
        updateLanguageElakay0,
        buttonAddLanguage
    }= props
 const defExempleJSOM =  '<!-- enter your combined JSON here... -->'
    const allTitle = ['users', 'categories', 'elakay language', 'filters', 'help contact', 'images', 'messages', 'orders', 'products', 'pub elakay']
    
    const [newobjet, setNewObejet] = useState()
    const [editJson, setEditJson] = useState(true)
    const [getNotData, setNotData] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [jsonInput, setJsonInput] = useState(defExempleJSOM);
    const [getTilte, setTilte] = useState()

    const checkIfFunction = (f)=>{
        return typeof f === 'object' ? true : false
      }

      

const checkProdKeyExit = (ad, ld)=>{
    let ent = Object.keys(ad ? ad:{});
    let lent = Object.keys(ld ? ld:{});
   let apiEvery =   ent.forEach((key, val) => ad[key] != undefined)
   let lengthObj =   ent.length === lent.length
   return apiEvery && lengthObj ? true : false
    }

    const [error, setError] = useState('')
    
    const checkObjEmpty = (a)=>{
        return JSON.stringify(a) === '{}'
    
    }
    
    const checkProductTrue = (ad)=>{
        let array =  Object.values(ad).map(e => e !== '');
         return array.every(a=> a === true) && true
        }
    
        const buttonAdd =()=>{
            let data = JSON.parse(jsonInput)
        
                
            updateLanguageElakay0(languageTradictor._id, token, {[nameObj]:  data}, Family()).then(data => {
                if (data.error) {
                   console.log(data.error)
                } else {
                    languageUsertra()
                    // setEditAdd(true)
                    // setParagra(ObjetPage)    
            }
            })
        }


    let clickEditAdd = ()=>{
        setEditAdd(editAdd? false : true)
         if(!edit_add && !checkProdKeyExit(languageTradictor[nameObj], infoElakay)){
            setObejet(editAdd? languageTradictor[nameObj] : menu)
        }else{
            setError(editAdd? "they is no data to edit":"")
            setObejet(infoElakay)
        }
     }

    const btnAddEdit =()=>{
    
    return<> 
        <h6> edit / add</h6>
    <label className="switch">
      <input 
      onChange={clickEditAdd} 
      type="checkbox" 
      checked={editAdd ? 'checked' : ''}
      />
      <span className="slider round"></span>
    </label>
    </>
    }

    const btnEditJson =()=>{
    
        return<> 
            <h6> edit json input</h6>
        <label className="switch">
          <input 
          onChange={()=>{setEditJson(editJson?false: true); setErrorMessage(null)}} 
          type="checkbox" 
          checked={editJson ? 'checked' : ''}
          />
          <span className="slider round"></span>
        </label>
        </>
        }
        const selTraErrMess =(data, value)=>{
            languageTradictor[nameObj].filter(obj => { obj.title == value ?   setNewObejet(obj):   setNewObejet(data)})
        }

        const traErrMessBool =(array = [], title)=>{
           return array.filter(obj => obj.title == title)[0] ? true : false
        }

        const submitALLData = (obj, value, mss = null)=>{
            setNewObejet(obj)
            Family() &&  setJsonInput(JSON.stringify(obj,  null, 3))
            setErrorMessage(mss)
            setTilte(value)
        }
        const transformJson = (jsonObj) => {
            // Créer une copie de l'objet pour éviter de modifier l'original
            const newObj = { ...jsonObj };
          
            // Supprimer la clé '_id'
            delete newObj._id;
          
            // Fonction pour vider les valeurs d'un objet
            const clearValues = (obj) => {
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  obj[key] = "";
                }
              }
            };
          
            // Vider les valeurs des objets 'error' et 'message'
            clearValues(newObj.error);
            clearValues(newObj.message);
          
            return newObj;
          }
        
    const handleTilteData = () => event => {
        const value = event.target.value;  
        setNotData(false)
        if(Family()){  
            let data = infoElakay[0]
                data.title = value
            traErrMessBool(languageTradictor[nameObj], value)?
            languageTradictor[nameObj].filter(obj => obj.title == value && submitALLData(obj, value))
            : submitALLData(data, value)
        }else{
            if(traErrMessBool(languageTradictor[nameObj], value)){
            languageTradictor[nameObj].filter(obj => obj.title == value && submitALLData(obj, value))
             }else{
            if(traErrMessBool(getDataMessErr, value)){
                getDataMessErr.filter(obj => obj.title == value && submitALLData(transformJson(obj), value))
            }else{
            setNotData(true)
            submitALLData({}, value)
            }

            
             }
        }
        
    };

    const handleChange = name => event => {
        const value = event.target.value;
        let pname = name[0]
        let sname = name[1]
    
        if(name.length == 2){
            let newData = { ...newobjet, [pname] : {...newobjet[pname], [sname]: value}}
            setNewObejet(newData);
            setJsonInput(JSON.stringify(newData,  null, 3)); 
        }else{
            let newData = { ...newobjet, [pname]: value}
            setNewObejet(newData);
            setJsonInput(JSON.stringify(newData,  null, 3));
        }
    
    };

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    const checkUndefName = (a, b)=>{
        let aa  = a && Object.keys(a)
        return  a && aa.includes(b)
       } 

       const inputOK = (a, b, c)=>{
        if(c == undefined){
            return  {v : b,  s: 'form-control'}
        }else  if(c == {}){
          return {v :"",  s: 'form-control is-invalid'}
      }else if(checkUndefName(c, a)){
          return {v : c[a],  s: "form-control is-valid" }
        }else{
          return {v :"",  s: 'form-control is-invalid'}
        }
      
      }
      const checkValue = (val)=>{
        return languageTradictor[nameObj] != undefined ? languageTradictor[nameObj] : val
      }

      const selectObArData =()=>{
        return <div className="form-group">
               <label className="text-muted">
                {/* select languages and compare */}
                data title for edit 
                </label>
               <select onChange={handleTilteData()} className="form-control" >
               <option value={0} > select data title</option>
               {allTitle&& allTitle.map((ob, j) =>{
                    return <option value={ob} >{ob}</option>
                   })}
               </select>
           </div>
    
    }
    const formInput1 = (a ,b, i, akey, c)=> {
        let inpClass = c == undefined ? "form-control" : checkUndefName(c, a) ? "form-control is-valid" : 'form-control is-invalid'
        return <div key = {i} className="form-group">
         <label className="text-muted">{a}</label>
         {b.length > 90 ?
         <textarea onChange={handleChange([akey, a])}  type="text" className={inpClass} placeholder={getDataMessErr[akey]} value={newobjet[akey][a]} rows="4"/>
         :
             <input onChange={handleChange([akey, a])}  type="text" className={inpClass} placeholder={getDataMessErr[akey]} value={newobjet[akey][a]} />  
            }
         </div>

    }
    const btnSubmitJson = (obj)=>{
        try {
            setNewObejet(JSON.parse(obj))
            setEditJson(true)
            setErrorMessage(null)
            } catch (error) {
          // Afficher l'erreur si le parsing échoue
          setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
        }
    }
    const handlJsonInput =(obj)=>{
       
        try {
        setJsonInput(obj)
        // setNewObejet(JSON.stringify(obj,  null, 3))
        setErrorMessage(null)
    //    
        } catch (error) {
      // Afficher l'erreur si le parsing échoue
      setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
    }
    }
    const inputJson =()=>{
        // let newJSON = JSON.parse(jsonInput)
        // let newJSON = JSON.stringify(obj,  null, 3)
       return <div className="">
        <h4>JSON Input</h4> {btnEditJson()}
        <textarea
        className='p-4'
          value={jsonInput}
          onChange={(e) => !editJson && handlJsonInput(e.target.value)}
          placeholder={defExempleJSOM}
          style={{ width: '100%', minHeight: '600px', fontFamily: 'monospace', marginBottom: '20px' }}
        />
         <div className="row justify-content-center">
         <div className="col col-sm-12">
       {!editJson && <button  className="btn btn-primary float-left pb-2" onClick={()=> btnSubmitJson(jsonInput)}>submit</button>}
       </div>
       </div>
      </div>

    }

    const formInput0 = (a ,b, i, c)=>{
        let oe1 = Object.entries(b)
         let inpClass = c == undefined ? "form-control" : checkUndefName(c, a) ? "form-control is-valid" : 'form-control is-invalid'
       return a != "title" && a != "_id" && <div key={i} className="p-3">
             <label className="text-muted">{checkIfFunction(b)? <h4>{a}</h4> : a }</label>
             {checkIfFunction(b)?
             <div style={{ border: "1px solid WhiteSmoke" }} className="p-3"> 
            { oe1.map(([key, val], i) => { return formInput1(key, val, i, a)})}
             </div>
             :
             <div key = {i} className="form-group p-3">
                {b.length > 90 ?
                 <textarea onChange={handleChange([a])}  type="text" className={inpClass} placeholder={b} value={newobjet[a]} rows="4"/>
                    :
                <input onChange={handleChange([a])}  type="text" className={inpClass} placeholder={b} value={newobjet[a]} />
                }
            </div>
            }
        </div>
    }

 

   const formInput = ()=> {
  let oe = newobjet && Object.entries(newobjet)
    
   return <div>
  {getTilte && <h2>Message & Error of {getTilte}</h2>}
            {newobjet && oe.map(([key, val], i) => { return formInput0(key, val, i)})}
            <div className="row justify-content-center">
            <div className="col col-sm-12">
            {getTilte && editJson &&  <button  className="btn btn-primary float-right pb-2" onClick={()=>buttonAdd()}>save</button>}
            </div>
            </div>
        </div> 
    }

    const loadErrorMessage = ()=>{
        return <div  className=" alert alert-danger" style={{  display: errorMessage ? "" : "none",  color: 'red', marginTop: '10px' }}>
            
        {errorMessage}
      </div>
    }

    const notDataImgs = ()=>{
      return <div>
             <img
                            src={notData}
                            alt={"No data"}
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
        </div>
    }

    return <div>
        
        {selectObArData()}
        {Family() && inputJson()}
        {getNotData? notDataImgs() : !errorMessage ? editJson && formInput(): loadErrorMessage()}
         </div> 

}

export default  FormPageErrorElakay