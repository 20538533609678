import { elakay_API } from "../../config";
import { getUserPUBToken} from "./";
// import queryString from "query-string";
// const queryString = require('query-string');
const { pubToken } = getUserPUBToken();

export const createProduct = (token, product) => {
    return fetch(`${elakay_API}/products/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getProducts = (skip, limit, sortBy, deal, filters) => {
    return fetch(`${elakay_API}/products?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}&deal=${deal}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProductsF = (skip, limit, sortBy, filters) => {
 
    return fetch(`${elakay_API}/productsF?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            UserPUBToken : `Bearer ${pubToken}`
        },
        body: JSON.stringify(filters)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getProductSearch = (skip, limit, sortBy, ci, search='', filters ) => {
    return fetch(`${elakay_API}/productSearch?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}&ci=${ci}&search=${search}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(filters)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const createFilterProduct = (token, ProductID, Filters) => {
    return fetch(`${elakay_API}/products/createFilter/${ProductID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(Filters)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};
export const updateFilterProduct = (token, ProductID, filterID, Filters) => {
    return fetch(`${elakay_API}/products/updateFilter/${ProductID}/${filterID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(Filters)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const parcelProductUA = (token, ProductID, filterID, parcel) => {
    return fetch(`${elakay_API}/products/parcelProductUA/${ProductID}/${filterID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(parcel)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const deleteFilterProduct = (token, ProductID, filterID) => {
    return fetch(`${elakay_API}/products/deleteFilter/${ProductID}/${filterID}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const linkCTP = (token, ID, categories) => {
    return fetch(`${elakay_API}/products/linkCTP/${ID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(categories)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UnlinkCTP = (token, ID, catID) => {
    return fetch(`${elakay_API}/products/UnlinkCTP/${ID}/${catID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const createImageProduct = (token, filterID, ProductID, photo) => {
    const body = new FormData()
    body.append('photo', photo)
    return fetch(`${elakay_API}/products/updateGaleries/${ProductID}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            //'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        },
       
        body: body
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const getUserProducts = (token, skip, limit, sort, dealToday, params, filters) => {

    return fetch(`${elakay_API}/products/user?category=${params.category}&sortBy=${sort.sortBy}&asc=${sort.asc}&skip=${skip}&order=desc&limit=${limit}&dealToday=${dealToday}&search=${params.search}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // UserPUBToken : `Bearer ${pubToken}`
        },
        body: JSON.stringify(filters)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const getLakayUser = (id, skip, limit, sortBy, ci, search='', filters ) => {
    return fetch(`${elakay_API}/products/lakayUser/${id}?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}&ci=${ci}&search=${search}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(filters)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const commentProduct =(productId, skip, limit,) => {
    return fetch(`${elakay_API}/commentProduct/${productId}?skip=${skip}&limit=${limit}`, {
        method: "GET"
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProductPreview = productId => {
    return fetch(`${elakay_API}/products/preView/${productId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const readProductByCategoryID = (skip, limit, CategoryId) => {
    return fetch(`${elakay_API}/products/category/${CategoryId}?skip=${skip}&order=desc&limit=${limit}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const AddToDealToDay = (token, productID, deal) => {
    return fetch(`${elakay_API}/products/AddToDeal/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(deal)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

// add image note product

export const AddProductImageNote = (token, productID, photo) => {

    const body = new FormData()
    body.append('photo', photo)

    return fetch(`${elakay_API}/products/updateNoteImageProduct/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};
export const AddProductDescription = (token, productID, note) => {

    return fetch(`${elakay_API}/products/updateNoteDescriptionProduct/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(note)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleProductImageNote = (token, ProductID, galerieID) => {

    return fetch(`${elakay_API}/products/deleteNoteProduct/${ProductID}/${galerieID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            //'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteVote = (productID, token, vote) => {
    return fetch(`${elakay_API}/products/deleteVote/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ vote })

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

export const vote = (productID, token, vote) => {
    return fetch(`${elakay_API}/products/vote/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ vote })

    }).then(response => {
            return response.json();
        })
        .catch(error => {
            console.log(error);
        });
}; 

export const deleteImageProduct = (token, ProductID, galerieID) => {

    return fetch(`${elakay_API}/products/deleteGalerie/${ProductID}/${galerieID}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            //'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateProduct = (token, id, product) => {
    return fetch(`${elakay_API}/products/update/${id}`, {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(product)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

export const DeleteComments = (token, productID, commentID) => {
    return fetch(`${elakay_API}/products/DeleteComments/${productID}/${commentID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const creatUserViewed = (token, productID) => {
    return fetch(`${elakay_API}/user/viewed/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
       
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const userDislikeCommentsProduct = (token, productID, commentID) => {
    return fetch(`${elakay_API}/products/comments/dislike/${productID}/${commentID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

// dislike and like commentaire
export const userLikeCommentsProduct = (token, productID, commentID, like) => {
    return fetch(`${elakay_API}/products/comments/like/${productID}/${commentID}?ref=${like}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const createOrder = (tokenS, createOrderData) => {
    return fetch(`${elakay_API}/order/createOrders/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenS.token}`,
            UserPUBToken : `Bearer ${tokenS.pubToken}`
        },
        body: JSON.stringify(createOrderData)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}; 

// product comments elakay  et vote comments elakay

export const AddComments = (token, productID, comments) => {
    return fetch(`${elakay_API}/products/comments/${productID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(comments)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

export const list = (skip, limit, params) => {
    // let query = queryString.stringify(params);
    return fetch(`${elakay_API}/products/search?search=${params}&skip=${skip}&order=desc&limit=${limit}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getFilteredProducts = (skip, limit, filters = {}) => { 
    const data = {
        limit,
        skip,
        filters
    };
    return fetch(`${elakay_API}/products/by/search`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getComments = (productId, skip, limit, sortBy) => {
    return fetch(`${elakay_API}/products/getCommentaire/${productId}?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const linkFTP = (productID, filID, token) => {
    return fetch(`${elakay_API}/products/linkFTP/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UnlinkFTP = (productID, filID, token) => {
    return fetch(`${elakay_API}/products/UnlinkFTP/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const linkVFTP = (productID, filID, name, token) => {
    return fetch(`${elakay_API}/products/linkVFTP/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({name})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const UnlinkVFTP = (productID, filID, name, token) => {
    return fetch(`${elakay_API}/products/UnlinkVFTP/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({name})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const AddFormFilProView = (productID, filID, formValueFil, token) => {
    return fetch(`${elakay_API}/products/AddFormFilProView/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({formValueFil})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const TextFilProView = (productID, filID, textRead, token) => {
    return fetch(`${elakay_API}/products/textFilProView/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({textRead})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const AddFilImg = (productID, filID, Images, token) => {
    return fetch(`${elakay_API}/products/AddFilImg/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({Images})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const DeletFilImg = (productID, filID, Images, token) => {
    return fetch(`${elakay_API}/products/DeletFilImg/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({Images})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const FormInputFilProView = (productID, filID, formInput, token) => {
    return fetch(`${elakay_API}/products/FormInputFilProView/${productID}/${filID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({formInput})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getImages = (productId) => {
    return fetch(`${elakay_API}/img/${productId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const linkImagesToProduct = (token, productId, filImages) => {
  return fetch(`${elakay_API}/linkImagesToProduct/${productId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(filImages)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
    }

    export const getPubCategories =(categoryID, userID, limit, desc, sortBy)=> {
        return fetch(`${elakay_API}/user/getPubCategories/${categoryID}/${userID}?limit=${limit}&order=${desc}&sortBy=${sortBy}`, {
            method: "GET"
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };
    export const loadFollowUserShop =(userID, limit, desc, sortBy)=> {
        return fetch(`${elakay_API}/user/getFollowUserShop/${userID}?limit=${limit}&order=${desc}&sortBy=${sortBy}`, {
            method: "GET"
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };

    export const taxProductArea =(code, zipCode)=> {
        return fetch(`${elakay_API}/user/taxProdArea/${code}/${zipCode}`, {
            method: "GET"
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };