import React, {useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import imgLakay from '../Layout/imgs/Untitled-2.jpg';
import imgLakay1 from '../Layout/imgs/Elakay-account-1.png';
import imgLakay2 from '../Layout/imgs/Royalty-user-1.png';
import imgLakay3 from '../Layout/imgs/Seller-pub-1.png';
// import Loader from "../Layout/loader.gif";
import Loader1 from '../Layout/imgs/loading3.gif';
import { elakay_API } from "../../config";
import { getCategoriesByID } from "../auth/apiCategory";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const SwiperPub1500x400 = ({SliderPunShow = true, categoriesID, getpub, infoSource}) => {


  return (
    SliderPunShow && (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation ={infoSource && infoSource.navigation}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {
           getpub &&  getpub.map((each, index) => (
           
                <SwiperSlide key={index}> 
                <img style={{ width: "100%" }} src={each.urlImg} alt={each.urlImg} />
                </SwiperSlide>
            ))
        }
        
      </Swiper>
     
    </>
  ));
}
export default SwiperPub1500x400;
