import React, { useState} from "react";
import imgLog3 from '../../imgs_steps/IMG_003.png';
import Modal from 'react-bootstrap/Modal';
import Pages from '.'

var fileAdding ={
    title:'',
    text:[],
    list:[],
    link:{},
    href:{}    
}

const FormPageLang0 = (props) => {

    const { 
        laguangeElakay,
        editAddByJson= false,
        infoSource,
        editAdd, 
        setEditAdd,
        nameObj,
        paragra,
        setParagra,
        ObjetPage,
        edit_add,
        token,
        AlllanguageSelect,
        languageUsertra,
        languageTradictor,
        updateLanguageElakay0,
        deleteLanguageElakay0,
        updateLanguageFileUp,
        buttonAddLanguage
    }= props
    

const checkProdKeyExit = (ad)=>{
    let ac = ad[0]
    let ent = Object.keys(ac ? ac:{});
   return ent.every(([key, val]) => ad[key] != undefined) && true
    }

const checkObjEmpty = (a)=>{
    return JSON.stringify(a) === '{}'

}
   

    let objValue ={
            title:'',
            href:''
        }
    let listeType = ['1', 'a', 'A', 'i', 'I', 'ul']
    let textHead = [1, 2, 3, 4, 5,  6]
    const [error, setError] = useState('')
    const [addFille, setAddfille] = useState(false);
    const [viewPage, setViewPage] = useState(true);
    const [deletePara, setDeletePara] = useState(false);
    const [checkdeletePara, setCheckDeletePara] = useState(false);
    const [imageVideo, setImageVideo] = useState('');
    const [hreflink, setHrefLink] = useState('');
    const [stylePara, setStylePara] = useState([4, 'a']);
    const [value, setValue] = useState(objValue);

    // const[paragra, setParagra]= useState(ObjetPage)
const {
    title,
    text,
    list,
    video,
    href,
    link,
    thumbnail,
    image
} = paragra
    
const stringToArray = (a)=>{
  
    if(Array.isArray(a)){
    return a.length == 1 && a[0] == '' ? []: a
    }else if(a != ''){
    return a.split(/\r?\n/)
    }
    else{
     return []
    }
   
}

const arrayToString = (a)=>{
    let na 
    if(Array.isArray(a)){
    na = a.join('\r\n')
    }else{
    na = a
    }
    return na
}

    const checkProductTrue = (c)=>{
        let textfil  = "" 
        for (let fil in c){
            textfil  +=  c[fil] === '' && ''
     } 
     return textfil
        }
    

    const buttonAdd =()=>{
        updateLanguageElakay0(languageTradictor._id, token, {[nameObj]:{ _id: paragra._id, title: title, text: stringToArray(text), list: stringToArray(list),  href, link, stylePara}}, false).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setEditAdd(true)
                setParagra(ObjetPage)    
        }
        })
    }

    const buttonAddFile =()=>{
        let file = 'video' == imageVideo && video.file || 'image'  == imageVideo && image.file

        updateLanguageFileUp(languageTradictor._id, paragra._id, nameObj, token, file, imageVideo).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setEditAdd(true)
                setParagra(ObjetPage)    
        }
        })
    }


    const buttonDelPara =()=>{
        deleteLanguageElakay0(languageTradictor._id, paragra._id, nameObj, token).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setCheckDeletePara(false)
                setDeletePara(false)
                setEditAdd(true)
                setParagra(ObjetPage)  
        }
        })
    }


    const handleLanguage = () => event => {
        const value = event.target.value;
        setStylePara(languageTradictor[nameObj][value].stylePara)
        setParagra(languageTradictor[nameObj][value])
    };

    const selectObAr =()=>{
        return <div className="form-group">
               <label className="text-muted">
                {/* select languages and compare */}
                {infoSource.language(infoSource, "label", "selLanguaComp")}
                </label>
               <select onChange={handleLanguage()} className="form-control">
               <option value={0} > select</option>
               {!checkObjEmpty(languageTradictor) && languageTradictor[nameObj].map((ob, j) =>{
                return <option value={j} >{ob.title}</option>
               })}
               </select>
           </div> 
           }
  
    const handleStylePara = (i, e)=>{
        stylePara.splice(i, 1,  e)
        setStylePara(stylePara)
        setParagra({ ...paragra})
    }


    const selectTextHead =()=>{
        return <div className="form-group"> 
                        { textHead.map((ob) =>{
                return <> <input 
                            className="form-check-input"
                            onClick={()=>handleStylePara(0, ob)} 
                            checked={stylePara[0] == ob ? 'checked' : ''} 
                             type="radio" 
                            name="typeList" 
                            id={"typeList_" + ob}
                            />
                            
                           <label className="form-check-label" for={"typeList_" + ob}>{ob}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> 
                        </>
               })}
           </div>
    
    }

    const selectTypelist =()=>{
        return <div className="form-group">
               { listeType.map((ob) =>{
                return <> <input 
                         className="form-check-input"
                        onChange={()=>handleStylePara(1, ob)} 
                        checked={stylePara[1]  == ob ? 'checked' : ''}
                        type="radio" 
                        name="typeArray"
                        id={"typeArray_" + ob}
                     />
                           <label className="form-check-label"  for={"typeArray_" + ob}>type {ob}&#160;&#160;&#160;&#160;&#160;</label> 
                        </>
               })} 
           </div>
    
    } 
    let clickEditAdd = ()=>{
        setEditAdd(editAdd? false : true)
         if(!edit_add && !checkProdKeyExit(languageTradictor[nameObj])){
            let newParagra = languageTradictor[nameObj][0]
            if(editAdd){
                 setParagra(newParagra)
            }else if (editAddByJson){
                setParagra(newParagra)
            }else{
                setAddfille(false)
                 setParagra(ObjetPage)

            }
        }else{
            setError(editAdd? "they is no data to edit":"")
            setParagra(ObjetPage)
        }
          
 
     }
     
     let clickAddFile = ()=>{
        setAddfille(addFille? false : true)
     }

    const btnAddEdit =()=>{
    
    return<> 
        <h6> edit / add</h6>
    <label className="switch">
      <input 
      onChange={clickEditAdd} 
      type="checkbox" 
      checked={editAdd ? 'checked' : ''}
      />
      <span className="slider round"></span>
    </label>
    </>
    }

    const btnFile =()=>{
    
        return<> 
            <h6>text / fille</h6>
        <label className="switch">
          <input 
          onChange={clickAddFile} 
          type="checkbox" 
          checked={addFille ? 'checked' : ''}
          />
          <span className="slider round"></span>
        </label>
        </>
        }

 
    const handleChange = name => event => {
        const value = event.target.value;  
            setParagra({ ...paragra, [name]: value});
    };

    const handleFileChange = name => event => {
        const files = event.target.files;  
        for (let file of files){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', ()=>{
            let fileobj ={
                file: file,
                name : file.name,
                type: file.type,
                size: file.size,
                src: reader.result
            }
            setParagra({ ...paragra, [name]: fileobj});
        })
    }
    };
    
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    
    const handleVideoimage =(value)=>{ 
        setImageVideo(value)
        setParagra({ ...paragra,  video:'', thumbnail: '', image: ''});
    }
    const handleHrefLink =(value)=>{ 
        setHrefLink(value)
        setValue(objValue)
        setParagra({ ...paragra,   href:{}, link: {}});
    }

    const handleChangeHrfLink = name => event => {
       
        setValue({
            ...value,
            [name] : event.target.value
            })
            setParagra({ ...paragra,  [hreflink]: value});
    };

    const VideoAndImages = () => {
        return <div  className="form-group">
            <br/>
            <input  
                className="form-check-input" 
                onChange={()=>handleVideoimage('video')} 
                type="radio" 
                name="Image_video"
                id={"Image_video_AddVideo"}
                 />
            <label className="form-check-label" for={"Image_video_AddVideo"}>Add video</label>
            <input  
                className="form-check-input"
                onChange={()=> handleVideoimage('image')} 
                type="radio" 
                name="Image_video"
                id={"Image_video_AddImage"}
                />
            <label className="form-check-label" for={"Image_video_AddImage"}>Add image</label><br/>
          </div>
    }
    const hrefAndLink = () => {
        return <div  className="form-group">
            <br/>
            <input 
             className="form-check-input" 
             onChange={()=>handleHrefLink('href')} 
             type="radio" 
             name="href_link" 
             id={"href_link_AddHref"}
             />
            <label className="form-check-label" for={"href_link_AddHref"}>Add href</label>
            <input 
                 className="form-check-input"
                 onChange={()=> handleHrefLink('link')} 
                 type="radio" 
                 name="href_link"
                 id={"href_link_AddLink"}
                 />
            <label className="form-check-label" for={"href_link_AddLink"}>Add link</label><br/>
          </div>
    }

    const CheckInputBool = (title, text, list)=>{
        let ti =  title !== ''
        let te =  text  !== ''
        let li = list  !== ''
        let teli = li || te

        return ti && teli 
        
    }

    let paraALLBoo =  checkProductTrue(paragra) || !editAdd
const pageView =  !viewPage ? false : paraALLBoo


const deleteAgree = () => {
         
    return (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    show={deletePara}
                    onHide={() => setDeletePara(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h4 className="text-center">
                                {/* delete this items */}
                                {infoSource.language(infoSource, "title", "delThtems")}
                                </h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row justify-content-center m-4">
                        {<Pages  
        stylePara={stylePara}
        classAlert ={"alert alert-success"}
        paragra ={paragra}
        imageVideo={imageVideo}
        paraALLBoo ={pageView}
    /> }
                            <div className="list-unstyled">
                                <input
                                    onChange={() => setCheckDeletePara(checkdeletePara ? false : true)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={checkdeletePara  ? 'checked' : ''}
                                    id="chButAgree"
                                />
                                <label className="form-check-label" for="chButAgree">
                                    {/* check the button if your are agree */}
                                    {infoSource.language(infoSource, "title", "chButAgree")}
                                    </label>
                            </div>
                          
                              {
                              viewPage && paragra._id != undefined && checkdeletePara ?
                               <button onClick={() => buttonDelPara()} className="btn btn-primary float-right">Delete this param </button>
                               :
                               <button className="btn btn-primary float-right" disabled>Delete this param </button>
                            }
                      
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )
}

   const formInput = ()=> {
   return <div onBlur={() => setError("")}>
{
   !viewPage ?
   <button onClick={() => setViewPage(true)} className="btn btn-primary">
    {/* View Input */}
    {infoSource.laguangeElakay('title', infoSource.languageAPI).viewInput}
    </button>
   :
    <button onClick={() => setViewPage(false)} className="btn btn-primary">
        {/* View Page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).viewPage}
        </button>
}

{<Pages  
        stylePara={stylePara}
        classAlert ={"alert alert-success"}
        paragra ={paragra}
        imageVideo={imageVideo}
        paragra0={languageTradictor[nameObj]}
        paraALLBoo ={pageView}
    /> }
   
  { viewPage && <h2>Form to edit / add menu Head</h2>}
    {viewPage && btnAddEdit()}
    {viewPage && showError()} 
    {viewPage && paragra._id != undefined &&  <button onClick={() => setDeletePara(true)} className="btn btn-primary float-right">Delete this param </button>}
    {!editAdd && viewPage && selectObAr()}
   
    {/* <form className="mb-3"> */}
 {!addFille && viewPage && <> 
   <div className="form-group">
         <label className="text-muted">Title</label> {selectTextHead()}
         <input onChange={handleChange('title')} type="text" className="form-control" placeholder={'enter your the title'} value={title} />
         </div>
         <div className="form-group">
         <label className="text-muted">Text</label>
         <textarea onChange={handleChange('text')} type="text" className="form-control" placeholder={'enter your the text'} value={arrayToString(text)} rows="10"/>
        </div>
    <div className="form-group">
         <label className="text-muted">list </label> {selectTypelist()}
         <textarea onChange={handleChange('list')} type="text" className="form-control" placeholder={'enter your the liste'} value={arrayToString(list)} rows="3"/>
    </div>
     { hrefAndLink()}
    <div className="form-group">
         <label className="text-muted">Title of {hreflink}</label>
         <input onChange={handleChangeHrfLink('title')} type="text" className="form-control" placeholder={`enter your the title ${hreflink}`} value={value.title} />
         </div>
    <div className="form-group">
    <label className="text-muted">{hreflink}</label>
         <input onChange={handleChangeHrfLink('href')} type="text" className="form-control" placeholder={`enter your the ${hreflink}`} value={value.href} />
    </div>
    </>}
  
    {!editAdd  && viewPage && btnFile()}
   {addFille && <>
   {VideoAndImages()}
    {imageVideo == 'video' && <><div className="form-group">
         <label className="text-muted mr-3">Add a video </label>
         <input onChange={handleFileChange('video')} type="file" accept="video/mp4,video/*" name="video"/>
    </div>
    <div className="form-group">
    <label className="text-muted mr-3">add a thumbnail for the video </label>
    <input onChange={handleFileChange('thumbnail')} type="file" name="thumbnail" accept="image/*"/>
    </div>
    </>} 
    {imageVideo == 'image' &&   <div className="form-group">
         <label className="text-muted mr-3">add a images </label>
        <input  onChange={handleFileChange('image')} type="file" name="image" accept="image/*" />
    </div>}
    </>}



    {/* </form> */}
    {/* <img className="shadow-lg" style={{ width: "100%" }} src={image && image.src} alt="firt Step" /> */}
   { viewPage &&  (addFille  ?
   buttonAddLanguage(buttonAddFile,  !checkObjEmpty(languageTradictor) && image &&  image.name !== '' || video && video.name !=='' )
    :
   buttonAddLanguage(buttonAdd, CheckInputBool(title, text, list))
    )
    }
   </div> 
    }

    return (<>
        {formInput()}
        {deleteAgree()}
        </>)

}

export default  FormPageLang0 