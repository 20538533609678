import React from 'react';

const ProductsPrice =({
    page = 0,
    products,
    showAddToCart,
    showAddToCartButton = 1,
    tax = 0.1,
    tca = 0,
    shipping = 0.3,
    filtername

}) => {
  
    const newPrice = (
        products.price * (100 - products.salePrice) / 100
    )

    const OlderPrice = (
        products.salePrice > 0 ? "$ " + products.price: ""
    )

    // const thePrice = products.salePrice > 0 ? newPrice : products.price

const setPrice =(
    <div>
    {
   page === 0 && (
         <p className="h6 ml-2 m-0">
        $ {newPrice}  {showAddToCartButton && showAddToCart(filtername)} 
        <br /> 
        <span style={{ textDecoration: "line-through", fontStyle: "italic", fontFamily: "myFirstFont", fontSize: "15px" }}>  
        {OlderPrice}
        </span> </p>
    )
    }
    {
       page === 1 && (
         products.reduce((currentValue, nextValue) => {
             return currentValue + nextValue.count * ((nextValue.price * (100 - nextValue.salePrice) / 100) + ((nextValue.price * (100 - nextValue.salePrice) / 100) * tax) + ((nextValue.price * (100 - nextValue.salePrice) / 100) * shipping) + ((nextValue.price * (100 - nextValue.salePrice) / 100) * tca));
        }, 0)
    )  
    }
    </div>
)
    return (
        setPrice
    )
}

export default ProductsPrice