// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navFooter {
    color: #ffffff;
    background-color: #007399;
    position: relative;
    margin-top: 10px;
    margin-bottom: 0;
    bottom: 0;
    width: 100%;
    min-height: 300px;


}

.page-container {
    width: 100vw;
    position: relative;
    overflow: auto;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;

}

.elakayPage{ 
    width: 100%;
    overflow-x: hidden;
    position: fixed;
    /* position: relative; */
    overflow-y: auto;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.content-wrap {
    margin-bottom: 10px;
    padding-bottom: 2.5rem;
    /* Footer height */
}
@media only screen and (min-width: 1500px) {
    .page-container {
         margin: auto;
        position: relative;
        width: 75.5vw;
        min-height: 100vh;
        

    }


}`, "",{"version":3,"sources":["webpack://./src/includes/Layout/Footer.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,WAAW;IACX,iBAAiB;;;AAGrB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,SAAS;IACT,UAAU;IACV,OAAO;IACP,QAAQ;;AAEZ;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI;SACK,YAAY;QACb,kBAAkB;QAClB,aAAa;QACb,iBAAiB;;;IAGrB;;;AAGJ","sourcesContent":[".navFooter {\n    color: #ffffff;\n    background-color: #007399;\n    position: relative;\n    margin-top: 10px;\n    margin-bottom: 0;\n    bottom: 0;\n    width: 100%;\n    min-height: 300px;\n\n\n}\n\n.page-container {\n    width: 100vw;\n    position: relative;\n    overflow: auto;\n    min-height: 100vh;\n    margin: 0;\n    padding: 0;\n    left: 0;\n    right: 0;\n\n}\n\n.elakayPage{ \n    width: 100%;\n    overflow-x: hidden;\n    position: fixed;\n    /* position: relative; */\n    overflow-y: auto;\n    margin: 0;\n    padding: 0;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n}\n\n.content-wrap {\n    margin-bottom: 10px;\n    padding-bottom: 2.5rem;\n    /* Footer height */\n}\n@media only screen and (min-width: 1500px) {\n    .page-container {\n         margin: auto;\n        position: relative;\n        width: 75.5vw;\n        min-height: 100vh;\n        \n\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
