import React, { useState} from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {activatorUserAdding, deleteUserTelephoneAss} from "../../auth";
import PhoneInput, { parsePhoneNumber, formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, getCountryCallingCode  } from 'react-phone-number-input'
import Pages from '../LanguagePage'
import nodata from '../../Layout/imgs/nodata0.png';
import Table from 'react-bootstrap/Table'
import { MdSmartphone,  MdDeleteForever } from "react-icons/md";
import {laguangeElakay} from "../../pageInside/dataElakay"; 
import EditAdrressUserAss from "./editAdrressUserAss";
import en from 'react-phone-number-input/locale/en.json'

const PageEditForm = (props) => {
    const {
        setPagesUser,
        editShoose,
        infoSource,
        checkInfoUser,
        userInfo,
        token,
        bnTelephone,
        setUserInfo
        } = props

    
    let objSelName = {
        selFirtName: true,
        selLastName: false
    }

    const [selectName, setSelectName] = useState(objSelName)
    const {
        selFirtName,
        selLastName
    } = selectName

    const [showPassword, setShowPassword] = useState(true) 

    let userValue ={
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        email: userInfo.email,
        password: "",
        passwordConfirmation: "",
        error: "",
        success: false
    }
    let objAdress ={
        firstName: userInfo.address.length > 0 ? userInfo.address[0].firstName : '',
        lastName: userInfo.address.length > 0 ?  userInfo.address[0].lastName: '',
        shopName: userInfo.address.length > 0 ?  userInfo.address[0].shopName: '',
        tel: userInfo.address.length > 0 ? userInfo.address[0].tel: '',
        Address1: userInfo.address.length > 0 ? userInfo.address[0].Address1: '',
        Address2: userInfo.address.length > 0 ? userInfo.address[0].Address2: '',
        city: userInfo.address.length > 0 ? userInfo.address[0].city: '',
        State: userInfo.address.length > 0 ? userInfo.address[0].State: '',
        postalCode: userInfo.address.length > 0 ? userInfo.address[0].postalCode: '',
        country: userInfo.address.length > 0 ? userInfo.address[0].country: '',
        error: ""
    }

    const [adress, setAdress] = useState(objAdress);
    const [values, setValues] = useState(userValue)
    const [phone, setPhone] = useState()
    const { firstname, lastname, email, password, passwordConfirmation, success, error} = values;


    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };


    const handleSelectName = (a, b) => {
       
        setSelectName({
            selFirtName: a,
            selLastName: b
            })
    };

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const clickSubmit =  (fileName) => {
        let userInfo 
        if(fileName === 'user'){
        if(selFirtName && !selLastName) {
            userInfo ={firstname}
        }else if(!selFirtName && selLastName){
            userInfo ={lastname}
        }else if(selFirtName && selLastName){
            userInfo ={lastname, firstname}
        }
    }else if(fileName === 'telephone'){
        userInfo ={ "country": en[parsePhoneNumber(phone).country], "abrCountry": parsePhoneNumber(phone).country, "ariaCode": getCountryCallingCode(parsePhoneNumber(phone).country) , "National": formatPhoneNumber(phone), "International": formatPhoneNumberIntl(phone)}
    }else if(fileName === 'address'){
            userInfo = adress
    }
        
        //  event.preventDefault();
         activatorUserAdding( token, fileName , userInfo, true).then(data => {
             if (data.error) {
                 setValues({ ...values, error: data.error, success: false });
             } else {
                 if(!data){
                     setValues({ ...values, error: 'BAD REQUEST or email is not ok', success: false });
                 }else{
                 userValue.success = true
                //  setValues(userValue);
                 setUserInfo(data)
                //  setPagesUser(bnTelephone)
             }
             }
         });;
     };

     const DeleteTelephone = (id) => {
        deleteUserTelephoneAss(token, id).then(response => {
            if (response.error) {
                console.log(response.error);
            } else {
                setUserInfo(response)
                // setValues(response)
            }
        });
    }

const firtLastName =()=>{
    return <div>
        <div  className="form-group">
            <br/>
            <input 
            className="form-check-input"
            onChange={()=>handleSelectName(true, false)} 
             type="radio" 
             name="First_Last"
             id="First_Last"
              />
            <label className="form-check-label m-2" for="First_Last"> 
    {/* First name */}
    {infoSource.language(infoSource, 'label', "fisrtName")}
     </label>
            <input 
                className="form-check-input"
                onChange={()=> handleSelectName(false, true)} 
                type="radio" 
                ame="First_Last"
                id="First_Last1"
                />
            <label  className="form-check-label m-2" fo="First_Last1">
     {/* Last name  */}
     {infoSource.language(infoSource, 'label', "lastName")}
     </label>
            <input 
            className="form-check-input" 
            onChange={()=> handleSelectName(true, true)} 
            type="radio" 
            name="First_Last"
            id="First_Last2"
            />
            <label  className="form-check-label  m-2" for="First_Last2"> 
    {/* Full name */}
    {infoSource.language(infoSource, 'label', "fullName")}
    </label><br/>
          </div>

       
   {selFirtName && <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("firstname")} value={firstname} type="text" name="firstname" id="firstname" className="form-control" placeholder="Last name" required/>
                    <label className="text-muted" htmlFor="firstname">{infoSource.laguangeElakay('label', infoSource.languageAPI).fisrtName}</label>
                </div>}

    {selLastName  && <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("lastname")} value={lastname} type="text" name="lastname" id="lastname" className="form-control" placeholder="Last name" required />
                    <label htmlFor="lastname">{infoSource.laguangeElakay('label', infoSource.languageAPI).lastName}</label>
        </div>}
        <button  onClick={()=>clickSubmit('user')} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {/* edit */}
                {infoSource.language(infoSource, 'Button', "edit")}
        </button>
     
    </div>
}

const emailForm =()=>{
    return <div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("email")} value={email} type="email" name="email" id="inputEmail" className="form-control" placeholder="Email address" required />
                    <label htmlFor="inputEmail">{infoSource.laguangeElakay('label', infoSource.languageAPI).email}</label>
                </div>
    
        <button  onClick={()=>clickSubmit()} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {/* edit */}
                {infoSource.language(infoSource, 'Button', "edit")}
        </button>
     
    </div>
}
const passwordForm =()=>{
    return <div>     
<div className="form-label-group">
    <input onChange={handleChange("password")} type={showPassword ? "password" : "text"} name="password" id="inputPassword" className="form-control" placeholder="Password" />
    <label htmlFor="inputPassword">{infoSource.laguangeElakay('label', infoSource.languageAPI).Password}</label>
</div>

<div className="form-label-group">
    <input onChange={handleChange("passwordConfirmation")} type={showPassword ? "password" : "text"} name="passwordConfirmation" id="inputPassword2" className="form-control" placeholder="Password" required />
    <label htmlFor="inputPassword2">{infoSource.laguangeElakay('label', infoSource.languageAPI).confirmPassword}</label>
</div>
    <button onClick={()=>clickSubmit()} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {/* edit */}
                {infoSource.language(infoSource, 'Button', "edit")}
    </button>
    </div>
}
const telephoneForm =()=>{
    return <div>    
                    <Table>
                            <tbody>
                            {
                     userInfo.telephone && userInfo.telephone.map((k,i)=>(
                      
                    <tr key={i}>
                        <td><span style={{ fontSize: "25px", paddingRight: "20px" }}><MdSmartphone/></span> </td>
                        <td><h4> {`${k.International}`} </h4></td>
                        <td>
                        <span onClick={() => DeleteTelephone(k._id)} className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <MdDeleteForever />
            </span>
                        </td>
                    </tr>
                        ))}
                            </tbody>

                    </Table>
                    <div className="m-4">
    <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                placeholder={infoSource.laguangeElakay('label', infoSource.languageAPI).enterPhoneNumber}
                value={phone}
                onChange={setPhone}
                error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
            </div>
       <button onClick={()=>clickSubmit('telephone')} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {/* edit */}
                {infoSource.language(infoSource, 'Button', "edit")}
    </button>
    </div>
}

const adressForm =()=>{
    return <div>   

<EditAdrressUserAss
 laguangeElakay={laguangeElakay}
 setAdress = {setAdress}
 adress ={adress}
 phoneUser ={userInfo.telephone && userInfo.telephone}
 infoSource ={infoSource}
/>

<button onClick={()=>clickSubmit('address')} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {/* edit */}
                {infoSource.language(infoSource, 'Button', "edit")}
    </button>
    </div>
}

    const allForm = ()=>{
       return <div>
         {showError()}
        <form>
        
        {editShoose === 'email' && emailForm()}
       {editShoose === 'fullName' && firtLastName()}
       {editShoose === 'passWord' &&  passwordForm()}
       {editShoose === 'telephone' &&  telephoneForm()}
       {editShoose === 'address' &&  adressForm()}
 
        </form>
        </div>
    }

    return (
       <>
       
       { allForm() }
       </>
    );
};

export default PageEditForm;