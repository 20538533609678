import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import imageAnimeted from "../Layout/imgs/animated_loop_500x600.gif"
import imageAnimetedC from "../Layout/imgs/animated_looped_500px.gif"
import imgProductLakay from '../Layout/imgs/md3.jpg' 
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Mousewheel, Keyboard, Pagination, Navigation, Virtual } from 'swiper/modules';
import "./mystyle.sliderProduct.css"

const LoadingSwiper = (props)=>{

  const {
    circle = false,
    classCard0 =  'justify-content-center',
    myStyles,
    wm0 = 2,
    wm640 = 3,
    wm768 = 4,
    wm1024= 5,
   wm1200 = 6,
  } = props
  let arrayLong = 6
const cells = [];   
    const loadingImg = () => {
        for (let j = 0; j < arrayLong; j++) {
       cells.push( <SwiperSlide key={j} >
        <div className={circle? `card m-2 p-0 rounded-circle`: `card`}>
          <img
          className={circle? "rounded-circle" : "cembla__slide__img"}
              // className="card-img-top"
              src={circle?imageAnimetedC :imageAnimeted}
              onError={event => {
                  event.target.src = imgProductLakay
                  event.onerror = null
                }}
              alt={'loadindImgAminated'}
              style={{ height: "100%", width: "100%" }}
          /> 
          </div>
          </SwiperSlide>)
            }
            }
let style = circle ?{} :myStyles
  return (
  <div className={`mt-4  p-2`} style={style} >
      <Swiper
         style={{
          "--swiper-navigation-color": "#007399",
          "--swiper-pagination-color": "#007399",
        }}
        keyboard={{
          enabled: true,
        }}
        cssMode={true}
        slidesPerView={wm0}
        spaceBetween={10}
        slidesPerGroup={2}
        // loop={true}
        mousewheel={true}
        loopFillGroupWithBlank={true}
        breakpoints={{
          568: {
            slidesPerView: wm640,
            spaceBetween: 10
          },
          768: {
            slidesPerView: wm768,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: wm1024,
            spaceBetween: 10
          },
          1200: {
            slidesPerView: wm1200,
            spaceBetween: 10
          }
        }}
        modules={[ Mousewheel, Keyboard, Pagination,  Navigation, Virtual]}
        className="mySwiper"
      >
        {loadingImg()}
        {cells}
      </Swiper>

  </div>
  )

}

export default LoadingSwiper; 