import React, { useState, useEffect } from "react";
import { getProductsF } from "../auth/apiProduct";
import Slider from '../slider/swiperProduct'

const NewProduct = ({
    laguangeElakay,
    infoSource,
    readUser,
    showB
}) => {
    const [productsByArrival, setProductsByArrival] = useState([]); 
    const myFilters = useState({
        filters: { }
    });

    useEffect(() => {
        
        loadProductsByArrival(0, 50, myFilters.filters)

    }, [myFilters.filters])

    let myStyles = {
        width: "100%",
        backgroundColor: '#f0f0f0'
    }

    const loadProductsByArrival = (skip, limit, filters) => {
        getProductsF(skip, limit, "createdAt", filters).then(data => {
            if(!data){
                    console.log("data not found")
            }else{
                    setProductsByArrival(data.products);
                }
                

        })
    };

    return (
        <Slider
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
            ProductSwiper={productsByArrival}
            User={readUser}
            nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).newProduct}
            linkSlider={"/new-product"}
            myStyles={myStyles}
            showB={showB}
        />
    )

};

export default NewProduct;