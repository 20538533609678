import React, { useState, useEffect } from "react";
import { Row } from 'react-bootstrap';
import Slider from '../../slider/swiperProduct'
import { getProductsF } from "../../auth/apiProduct";
import { useParams } from "react-router-dom";
import { getPubCategories , getImages} from "../../auth/apiProduct";
import { Link} from 'react-router-dom';
import imgProductLakay from '../../Layout/imgs/md3.jpg' 
import Loader from "../../Layout/loader.gif";
import { addItem, isAuthenticated} from "../../auth";

import ShowImage from "../../pageInside/ShowImage";

// import "../Card.css"



const ProductFollow = (props) => {
  const {
    nbrArr = 1,
    categoryID, 
    userID, 
    setShopUser, 
    infoSource
  } = props
  const [productF, setProductsF] = useState([]);

  const getAddressShop = (allAddress, addressShop)=>{
    const exists = allAddress.some(address => address.id === addressShop);
    if (exists) {
     return allAddress.filter(AS =>  AS._id === addressShop)[0].shopName
    } else {
     if(allAddress[0].shopName){
      return allAddress[0].shopName
     }else{
      return allAddress[0].shopName
     }

    }
  }

  const loadShopUser =(a)=>{ 
    const exists = a.owner.address.some(address => address.id === a.addressShop);

    if (exists) {
      let shopUser = a.owner.address.filter(AS =>  AS._id === a.addressShop)[0].shopName
      setShopUser(shopUser)
      return shopUser
     } else {
      if(a.owner.address.length > 0){
         let first1 = String(a.owner.address[0].firstName).charAt(0)
        let last1 =  String(a.owner.address[0].lastName).charAt(0)
        let FL = first1.concat(last1).toLocaleUpperCase()
        setShopUser(FL)
        return FL
      }else{
          setShopUser('seller')
       return 'seller'
      }
    }

    // let shop = a.owner.address.filter(AS =>  AS._id === a.addressShop)[0].shopName
    // let first1 = String(a.owner.address[0].firstName).charAt(0)
    // let last1 =  String(a.owner.address[0].lastName).charAt(0)
    // let FL = first1.concat(last1).toLocaleUpperCase()
   
    // let lakayUser = shop ? shop.toLocaleUpperCase(): FL
    // setShopUser(lakayUser)
  }

  const loadProductsBySellfollow = (cid, uId) => {
    getPubCategories(cid, uId, nbrArr, 'asc', "sold").then(data => {
      if(!data){
        console.log("data error")
      }else{
            setProductsF(data.products);
           data.products[0] && data.products[0].owner && loadShopUser(data.products[0])
      }  
    })
};


useEffect(() => {
    loadProductsBySellfollow(categoryID, userID);
}, [categoryID, userID]);

const imageShow =(p)=>{
let urlImg = p.galeries.length > 0 && p.galeries[0].images.length > 0 ? p.galeries[0].images[0] : p.galeriesID.galeries.length > 0 ? p.galeriesID.galeries[0].urlImg  : imgProductLakay
  return  <img  
    className="card-img-top"
    src={urlImg}
    onError={event => {
        event.target.src = imgProductLakay
        event.onerror = null
      }}
    alt={p.name}
    style={{ width: "100%" }}
/>
}


 return productF && productF.map((product, i) => imageShow(product))
}
export default ProductFollow;