import React  from "react";
import Loader from "./loader.gif";
import Sidebar from "./Sidebar"; 
import AddToDeal from "../Dwawer/addTodeal"
import SliderPun135 from "../slider/swiperPub1500x400";
import Footer from "./Footer"


const Layout = (props) => {

    const {
        infoSource,
        loading ,
        children,
        SliderPunShow,
        ShowSideDeal,
        productaddToDeal,
        loadProductsByArrival,
        setShowSideDeal,
        skip,
        limit,
        DealProduct,
        e1,
        categoriesID,
        handleFilters,
        prices,
        setMyFilters,
        AllCategorie, 
        getpub,
        filterShop= false,
        setFilterShop,
        userShop= false,
        getCalFilShop,
        infoHeaderShop,
        allHeaderShop,
        outOffPage
    } = props

    
  
    
    const laadingGIf = () => (
        <div className="page-container">
        <div className="row justify-content-center">
            <div className="col col-12 col-md-11 col-lg-11 col-xl-7 ">
                <h2 className="mt-4">
                    {/* Loading... */}
                    {infoSource.language(infoSource, "title", "loanding")}
                    </h2>

                <div className="mx-auto" style={{ width: "200px", margin: "15%" }}>
                    <img src={Loader} className={`search-loading ${loading ? 'show' : 'hide'}`} alt="loader" />
                </div>

            </div>
        </div></div>
    )
    const buttonDealB = () => {
        setShowSideDeal(!ShowSideDeal)
    }

    const showLayout = (
        <div className="elakayPage" style={{minWidth:"320px"}}>
            <Sidebar
                infoSource ={infoSource}
                e1={e1}
                categoriesID={categoriesID}
                handleFilters={handleFilters}
                prices={prices}
                setMyFilters={setMyFilters}
                AllCategorie={AllCategorie}
                filterShop={filterShop}
                setFilterShop={setFilterShop}
                outOffPage={outOffPage}
                userShop={userShop}
                getCalFilShop={getCalFilShop}
                infoHeaderShop={infoHeaderShop}
                allHeaderShop={allHeaderShop}
            />  
            {
                loading
                    ?
                laadingGIf() :
                <>
                    <div className="page-container">
                        <SliderPun135
                            infoSource={infoSource}
                            categoriesID={categoriesID}
                            SliderPunShow={SliderPunShow}
                            getpub={getpub}
                        />
                      
                        <div>
                           
                            {children}
                        </div>
                  {productaddToDeal && ShowSideDeal && <AddToDeal
                            infoSource={infoSource}
                            show={ShowSideDeal}
                            buttonDealB={buttonDealB}
                            productaddToDeal={productaddToDeal}
                            loadProductsByArrival={loadProductsByArrival}
                            skip={skip}
                            limit={limit}
                            DealProduct={DealProduct}
                        /> }
 
                    </div></>
            }
            <Footer 
            infoSource={infoSource}
            elakayCategories = {infoSource.elakayCategories}
            />
        </div>
    )
    return (
        <div>
            {showLayout}
           
        </div>
    );

}
export default Layout;
