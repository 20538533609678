import React, { useState} from "react";

const FormPageLang = (props) => {

    const { 
        editAdd, 
        setEditAdd,
        menu, 
        nameObj,
        objet,
        setObejet,
        infoElakay,
        edit_add,
        token,
        AlllanguageSelect,
        languageUsertra,
        languageTradictor,
        updateLanguageElakay,
        buttonAddLanguage
    }= props
    

const checkProdKeyExit = (ad, ld)=>{
    let ent = Object.keys(ad ? ad:{});
    let lent = Object.keys(ld ? ld:{});
   let apiEvery =   ent.forEach((key, val) => ad[key] != undefined)
   let lengthObj =   ent.length === lent.length
   return apiEvery && lengthObj ? true : false
    }

const checkEditPro = (ad)=>{
    let ent = Object.keys(ad ? ad:[]);
    return  ent.length > 0 ? true : false 
}

    const [error, setError] = useState('')
    
    const checkObjEmpty = (a)=>{
        return JSON.stringify(a) === '{}'
    
    }
    
    const checkProductTrue = (ad)=>{
        let array =  Object.values(ad).map(e => e !== '');
         return array.every(a=> a === true) && true
        }
    

    const buttonAdd =()=>{
        updateLanguageElakay(languageTradictor._id, token, {[nameObj]:objet}).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setEditAdd(true)
                setObejet(menu)    
        }
        })

    }

    let clickEditAdd = ()=>{
      
        setEditAdd(editAdd? false : true)
         if(!edit_add && !checkProdKeyExit(languageTradictor[nameObj], infoElakay)){
            setObejet(editAdd? languageTradictor[nameObj] : menu)
        }else{
            setError(editAdd? "they is no data to edit":"")
            setObejet(infoElakay)
        }}

    const btnAddEdit =()=>{
    
    return<> 
        <h6> edit / add</h6>
    <label className="switch">
      <input 
      onChange={clickEditAdd} 
      type="checkbox" 
      checked={editAdd ? 'checked' : ''}
      />
      <span className="slider round"></span>
    </label>
    </>
    }

    const handleChange = name => event => {
        const value = event.target.value;
        setObejet({ ...objet, [name]: value});
    };
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    const checkUndefName = (a, b)=>{
        let aa  = a && Object.keys(a)
        return  a && aa.includes(b)
       } 

       const inputOK = (a, b, c)=>{
        if(c == undefined){
            return  {v : b,  s: 'form-control'}
        }else  if(c == {}){
          return {v :"",  s: 'form-control is-invalid'}
      }else if(checkUndefName(c, a)){
          return {v : c[a],  s: "form-control is-valid" }
        }else{
          return {v :"",  s: 'form-control is-invalid'}
        }
      
      }
      const checkValue = (val)=>{
        return languageTradictor[nameObj] != undefined ? languageTradictor[nameObj] : val
      }
    const InputPage = (a ,b, i, c)=>{
        let inpClass = c == undefined ? "form-control" : checkUndefName(c, a) ? "form-control is-valid" : 'form-control is-invalid'
        return <div key = {i} className="form-group">
         <label className="text-muted">{b}</label>
         {b.length > 90 ?
        //  <textarea onChange={handleChange(a)} type="text" className={inpClass} placeholder={b} value={objet[a]} rows="4"/>
        <textarea onChange={handleChange(a)}  type="text" className={ inputOK(a, b, c).s} placeholder={b} value={objet[a]} rows="4"/>
         :
        //  <input onChange={handleChange(a)} type="text" className={inpClass} placeholder={b} value={objet[a]} />
             <input onChange={handleChange(a)}  type="text" className={inputOK(a, b, c).s} placeholder={b} value={objet[a]} />
            }
         </div>
     }

     let entries = Object.entries(infoElakay);

     function getDocumentSizeInMB(document) {
        // Convertir le document en JSON string
        const jsonString = JSON.stringify(document);
      
        // Calculer la taille en octets
        const sizeInBytes = new Blob([jsonString]).size;
      
        // Convertir la taille en mégaoctets (1 Mo = 1024 * 1024 octets)
        const sizeInMB = sizeInBytes / (1024 * 1024);
      
        // Retourner la taille en Mo, formatée avec 2 décimales
        return sizeInMB.toFixed(2) + " MB";
      }

   const formInput = ()=> {
   return <div onBlur={() => setError("")}>
    <h2>Form to edit / add menu Head</h2>
    {btnAddEdit()}
    {showError()}
    <form className="mb-3">

    {entries.map(([key, val], i) => {
  return InputPage(key, val, i, checkValue({}))
})}
    </form>
   { buttonAddLanguage(buttonAdd, checkProductTrue(objet) && !checkObjEmpty(languageTradictor))}
     </div>  
    }

    return formInput()

}

export default  FormPageLang 