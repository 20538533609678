import React, { useState} from "react";
import { useLocation } from 'react-router-dom';
import Layout from "../Layout/Layout";
import { UpdateUserRoleByAdmin, UserByMail, isAuthenticated } from "../auth";
import PhoneInput, { parsePhoneNumber, formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, getCountryCallingCode  } from 'react-phone-number-input'
import Form from 'react-bootstrap/Form'
import ElakayAccount from '../Layout/imgs/Elakay-account-1.png';
import RoyaltyUser from '../Layout/imgs/Royalty-user-1.png';
import SellerPub1 from '../Layout/imgs/Seller-pub-1.png';
import jsSHA from "jssha";
import { roleUser } from "../pageInside/roleUser";
import { Family, Adminstrator, Investment, Assoociate} from "../pageInside/Rolle"
import SharePage from "../pageInside/sharePage";
import BusinessCard from '../pageInside/businessCard';
import {ELAKAY_URl, elakay_API} from "../../config"; 
import Timer from "../pageInside/timer";


// import required modules
// import { Autoplay, Pagination, Navigation } from "swiper";
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

var sha1 = require('sha1');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Message = (props) => {
    const {
        infoSource,
        usel = 0,
        selectRole, 
        setSelectRole
            } = props 
    let query = useQuery();
    // let usel  = query.get("usel") ? query.get("usel") : 0

    
    const [loading, setLoading] = useState(false);
   
    // const [userInfo, setUserInfo] = useState([]);
    const { token } = isAuthenticated();

    let ilcod = infoSource.languageAPI.checkLangObjDefault
    let ileia =  infoSource.languageAPI.elakayInfoAPI
    let ilei = infoSource.languageAPI.elakayInfo

    let InfoApiLabel =  !infoSource.loading && ilcod(ileia, ilei, 'label')
    let InfoApiMenuSertting =  !infoSource.loading && ilcod(ileia, ilei, 'menuSertting')
    let InfoApiText = !infoSource.loading && ilcod(ileia, ilei, 'text')
    let InfoApiButton = !infoSource.loading && ilcod(ileia, ilei, 'Button')
    
    let Rele_user
    let objUserRole = {
        userID: '',
        firstname: '',
        lastname: '',
        email: '',
        customer: '',
        seller: '',
        provider: '',
        password: '',
        userRole:'',
        error: '',
        message: '',
        linkAdmin: '',
        class_ErrName: 'alert alert-danger',
        success: '',
        maxRoyalite: 0,
        maxSeller: 0,
        maxProducer: 0,
        maxAssociate: 0,
        maxInvestment: 0,
        maxAdmin: 0,
        nbrDays: 0,
        passwordShow: false,
        emailExit: false
    }
    const [telPhone, setTelPhone] = useState()
    // const [selectRole, setSelectRole] = useState(Number(usel));
    const [emailUser, setEmailUser] = useState('')
    const [emailvsTelAdding, setEmailvsTelAdding] = useState(true);
    const [Urole, setURole] = useState(objUserRole);
    const [nbrTimer, setNbrTimer] = useState(0);
    const [tokenua, setTokenua] = useState(true);
    const [shareUrl, setShareUrl] = useState()
    const [showAlert, setShowAlert] = useState(null);

    const triggerAlert = (n, e) => {
        setShowAlert(`Sorry, you have entered a value greater than the maximum allowed, which is ${n}. The maximum value has been set by default.`);
        setShowAlert(`Sorry, you entered ${e}, which exceeds the maximum allowed value of ${n}. The maximum value will be displayed by default.`)
        setTimeout(() => setShowAlert(null), 6000);
      };

    let editroleuser
    let title =`Nous sommes ravis de vous annoncer qu’un message important concernant Elakay vous a ete envoye par un de nos associes. Pour decouvrir notre plan d’affaires, nos conditions, et explorer l’opportunite de devenir vous-même associe, veuillez cliquer sur le lien suivant; Nous sommes impatients de potentiellement vous accueillir au sein de notre equipe.`
    const {
        userID,
        firstname,
        lastname,
        email,
        customer,
        seller,
        provider,
        password,
        userRole,
        error,
        message,
        class_ErrName,
        success,
        maxRoyalite,
        maxSeller,
        maxProducer,
        maxAssociate,
        maxAdmin,
        maxInvestment,
        nbrDays,
        passwordShow,
        emailExit,
        linkAdmin
    } = Urole
                    // signupUPdateRole({email: data.email,  userInfoRole:{role: role(roleUser, data._id, 5), roleName:''}}, pubToken
    const roleSha1 = (roleUser, userID, index) => {
        if (!roleUser || !roleUser[index] || !roleUser[index].code || !roleUser[index].role || !userID) {
            throw new Error("Invalid input data");
        }
    
        // Fonction pour générer un SHA-1
        const sha1 = (data) => crypto.createHash("sha1").update(data).digest("hex");
    
        // Générer "kay" en combinant les hash SHA-1
        let kay = sha1(roleUser[index].code) + sha1(roleUser[index].role) + sha1(userID);
    
        // Créer un HMAC SHA-512 avec "kay" comme clé
        const shaObj = crypto.createHmac("sha512", kay);
        shaObj.update(kay);
        
        return shaObj.digest("hex"); // Retourne le hash SHA-512 en hexadécimal
    }; 


    const handleChange = () => event => {
        const value = event.target.value;
        setSelectRole(Number(value));
        setShareUrl('')
        setURole(objUserRole)
    };

    const handlePassword = name => event => {
        const value = event.target.value;
        setURole({ ...Urole, [name]: value });
        if(name==='linkAdmin'){
           let urlEl = ELAKAY_URl
           let urlOK = linkAdmin.includes(urlEl)
        }
    };

    const handleChangeNumber = name => event => {
        // const value = event.target.value;
        const maxNumber = infoSource.userInfo.userActiveRole[name]
        const inputValue = event.target.value;
        // Vérifie si la valeur est un nombre valide
        if (!isNaN(inputValue) && inputValue !== '') {
            const numericValue = Number(inputValue);

            // Applique la limite maximale si définie
           if(Family()){
            setURole({ ...Urole, [name]: numericValue });
           } else if (numericValue > maxNumber) {
                setURole({ ...Urole, [name]: maxNumber }); // Force la valeur maximale
                 triggerAlert(maxNumber, numericValue);
            } else {

                setURole({ ...Urole, [name]: numericValue });
            }
        } else if (inputValue === '') {
            setURole({ ...Urole, [name]: "" }); // Permet d'effacer l'entrée
        }
      
    };

    const handleEmail = () => event => {
        const value = event.target.value;
        setEmailUser(value);
    };

var betterRole = (role, sel)=>{
    let y = true
    let x = false
    let _a = role === 'user' &&  1
    let a = role === 'Royalty' &&  1
    let b = role === 'seller'  && 2
    let c = role === 'provider'  && 3
    let d = role === 'associate' && 4
    let e = role === 'investment' && 5
    let f = role === 'administrator' && 6
    let g = role ==='family' && 7

    let z = _a || a || b || c || d || e || f || g
   if (Number(sel) >= Number(z)) {
    return y
   }else {
    return x
   }
}
var editRole = (role, sel)=>{
    let y = true
    let x = false
    let _a = role === 'user' &&  0
    let a = role === 'Royalty' &&  1
    let b = role === 'seller'  && 2
    let c = role === 'provider'  && 3
    let d = role === 'associate' && 4
    let e = role === 'investment' && 5
    let f = role === 'administrator' && 6
    let g = role ==='family' && 7

    let z = _a || a || b || c || d || e || f || g

   if (Number(sel) === Number(z)) {
    return y
   }else {
    return x
   }
}

    const clickEmail = (email, sel) => {
       

        let data = telPhone ? 
            { "country": en[parsePhoneNumber(telPhone).country], 
            "abrCountry": parsePhoneNumber(telPhone).country, 
            "ariaCode": getCountryCallingCode(parsePhoneNumber(telPhone).country) , 
            "National": formatPhoneNumber(telPhone), 
            "International": formatPhoneNumberIntl(telPhone)
            }:
            { "country": '', 
            "abrCountry": '', 
            "ariaCode": '' , 
            "National": '', 
            "International": ''
            }
        UserByMail(token, email, data, emailvsTelAdding, Family()).then(data => {
           
            if (data.error) {
                setURole({ ...Urole, class_ErrName: data.class_name ? data.class_name: 'alert alert-danger', error: data.error, emailExit: data.emailExit, passwordShow: data.password});
            } else {
                if (betterRole(data.user.userActiveRole.roleName,  sel)){
                
                    let d = parseFloat(nbrDays)
                    let expirationday =  d && new Date().getTime() + d * 24 * 3600 * 1000
                    let final =    d &&  Math.abs(expirationday - new Date())
                     setNbrTimer(final)
           
                !data.emailExit && data.user.ativator[0].token ? setTokenua(true) : setTokenua(false)
                editroleuser =  editRole(data.user.userActiveRole.roleName,  sel);
               let messageUser  =  `L’utilisateur à ajouter possède dejà un compte ${data.user.userActiveRole.roleName}. Souhaitez-vous modifier ses capacites d’ajout d’utilisateurs dans ses equipes ? Vous pouvez augmenter ou diminuer ces capacites. Veuillez selectionner votre choix.`;
               !data.emailExit ?  setShareUrl(`${ELAKAY_URl}/user_Shoosing/${data.user.ativator[0].token}`) :  setShareUrl(`${ELAKAY_URl}/your-step/?us=${data.user.userActiveRole.roleName}`)
                
            setURole({
                ...Urole,
                userID: data.user._id,
                firstname: data.user.firstname,
                lastname: data.user.lastname,
                email: data.user.email,
                customer: data.user.userActiveRole.custormer,
                seller: data.user.userActiveRole.seller,
                provider: data.user.userActiveRole.provider,
                userRole:data.user.userActiveRole.roleName,
                maxRoyalite: data.user.userActiveRole.maxRoyalite,
                maxSeller:data.user.userActiveRole.maxSeller,
                maxProducer: data.user.userActiveRole.maxProducer,
                maxAssociate:data.user.userActiveRole.maxAssociate,
                maxInvestment: data.user.userActiveRole.maxInvestment,
                maxAdmin: data.user.userActiveRole.maxAdmin,
                message: editroleuser ? messageUser: data.massage,
                passwordShow: true ,
                emailExit: data.emailExit
            })
            }else{
                setURole({ ...Urole, error: "sorry elakay have something better for this user" });
            }
            
        }
        })
    }
    const clickPassword = (roleI, Rele_user, roleName) => {
        let userIn = {firstname, lastname, role: Rele_user, emailUser, roleName, maxRoyalite: maxRoyalite, maxSeller: maxSeller, maxProducer: maxProducer, maxAdmin: maxInvestment}
        let roleUs = { customer, seller, provider, userid: userID, role: Rele_user, [roleName]: true, roleName, level: roleI, maxRoyalite: Number(maxRoyalite), maxSeller: Number(maxSeller), maxProducer: Number(maxProducer), maxAssociate: Number(maxAssociate), maxInvestment: Number(maxAdmin), maxAdmin: Number(maxInvestment)}
        let myInfo = {userAdmin: infoSource.userInfo._id, password, nbrDays}

        let userTel = telPhone ? 
        { "country": en[parsePhoneNumber(telPhone).country], 
        "abrCountry": parsePhoneNumber(telPhone).country, 
        "ariaCode": getCountryCallingCode(parsePhoneNumber(telPhone).country) , 
        "National": formatPhoneNumber(telPhone), 
        "International": formatPhoneNumberIntl(telPhone)
        }:
        { "country": '', 
        "abrCountry": '', 
        "ariaCode": '' , 
        "National": '', 
        "International": ''
        }

        UpdateUserRoleByAdmin(token, userIn, roleUs, myInfo, userTel, emailExit, emailvsTelAdding).then(data => {
            if (data.error) {
                setURole({ ...Urole, class_ErrName: data.class_name, error: data.error });
            } else {
                !data.user.ativator[0].token ? setTokenua(false) : setTokenua(true)
                setEmailUser('')
                setSelectRole(0)
                setURole({
                    ...objUserRole,
                    success: data.success
                })
                setShareUrl(`${ELAKAY_URl}/user_Shoosing/${data.user.ativator[0].token}`)
            }
        }) 


    }

    const clickCancel = () => {
        setSelectRole(0)
        setURole(objUserRole)

    }

    const shareUser =()=>{
        return <div className="justify-content-center mt-3">
         
         <SharePage 
         shareUrl ={shareUrl}
         title ={title}
         />
             </div>
 
     }


    const showSuccess = () => (
        <div
            className="alert alert-success"
            style={{ display: success ? "" : "none" }}
        >
            {success}
            {tokenua && shareUser()}
        </div>
    );

    
    const telephoneuser =(roleI)=>(
        <div>
            { formTimer()}

                <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="US"
                    placeholder={infoSource.laguangeElakay('label', infoSource.languageAPI).enterPhoneNumber}
                    value={telPhone}
                    onChange={setTelPhone}
                    error={telPhone ? (isValidPhoneNumber(telPhone) ? undefined : 'Invalid phone number') : 'Phone number required'}
                />   
                
            
              {
                !passwordShow && (
                    telPhone && isValidPhoneNumber(telPhone) && nbrDays > 0?
                    <button onClick={() => (clickEmail(emailUser, roleI))} className="btn btn-primary float-right">{InfoApiButton.check}</button>
                    :  <button className="btn btn-primary float-right disabled">{InfoApiButton.check}</button>
                )}
        </div>
        )

    const formSelect = () => (
        !passwordShow && (
            <div  className="alert alert-info p-4" style={{  'overflow': 'auto', 'maxHeight' : "400px"}}>
            <div className="row justify-content-center m-4" >
                <div className="col col-12 col-md-11 col-lg- p-2" style={{ border: "1px solid WhiteSmoke" }}>
                   
                    <Form>
                        <Form.Group controlId="exampleForm.SelectCustom">
                            <Form.Label>{InfoApiLabel.customSelect}</Form.Label>
                            <Form.Control onChange={handleChange()} as="select" value={selectRole} >
                                <option>{InfoApiLabel.pleaseSelect}</option>
                                {Assoociate() && (
                                    <>
                                <option value="1">{InfoApiLabel.addUserRoyalty}</option>
                                <option value="2">{InfoApiMenuSertting.addSeller}</option>
                                <option value="3">{InfoApiMenuSertting.addProducer}</option>
                                </>
                                )}
                                 {Investment() && (
                                    <>
                                <option value="4">{InfoApiMenuSertting.addAssociate}</option>
                                </>)}
                                {Adminstrator() && (
                                <>
                                <option value="5">{InfoApiMenuSertting.addInvestment}</option>
                                </>)}
                                {Family() && (
                                <>
                                <option value="6">{InfoApiMenuSertting.addAdministrator}</option>
                                <option value="7">{InfoApiMenuSertting.addFamilly}</option>
                                </>
                                )}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    {btnAddEdit()}
                    {showSuccess()}
                </div></div></div>
        ))

    const formTimer = ()=>{
       return <> 
        {!passwordShow && <h1>{nbrDays} days for expiration</h1>}
           
                <Form.Group>
                         <Form.Label className="mt-3">
                            {/* enter the number day for expiration */}
                            {infoSource.language(infoSource, "label", "entDayexp")}
                            </Form.Label>
                        <Form.Control 
                        className="form-range mb-4"
                        onChange={handlePassword("nbrDays")}
                        type="range"
                        value={nbrDays > 0 ? nbrDays : 0}
                        min="0" 
                        max="90"
                        // step="0.25"
                        />
                         </Form.Group>
                </>
    }
    const formEmail = (supRole, roleI) => (
        <div onBlur={() => setURole({ ...Urole, error: "" })}>

         <Form>
         {formTimer()}
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>{InfoApiLabel.hisEmailaddress}</Form.Label>
                    <Form.Control onChange={handleEmail("email")} type="email" placeholder={InfoApiLabel.emailWantAdd}/>
                    <Form.Text className="text-muted">
                       {InfoApiText.textAddAdminFamilly1}
                    </Form.Text>
                </Form.Group>
                   
            </Form>
            {
                !passwordShow && emailUser != '' && nbrDays > 0?
                    <button onClick={() => (clickEmail(emailUser, roleI))} className="btn btn-primary float-right">{InfoApiButton.check}</button>
                    :
                    !passwordShow && <button  className="btn btn-primary float-right disabled">{InfoApiButton.check}</button>
                }
        </div>
    )

    const activeButton = ()=>{
        let a = maxRoyalite > 0 || maxRoyalite != ''
        let b = maxSeller > 0 || maxSeller != ''
        let c = maxProducer > 0 || maxProducer != ''
        let d = maxAssociate > 0 || maxAssociate != ''
        let e = maxAdmin > 0  || maxAdmin != ''
        let f = maxInvestment > 0 || maxInvestment != ''
        let g = nbrDays > 0 || nbrDays != ''
        let p = password != ''

        let g1Form = selectRole >= 4 && a && b && c && p && g
        let g2Form = selectRole >= 5 && g1Form && d 
        let g3Form = selectRole >= 6 && g2Form  && e
        let g4Form = selectRole >= 7 &&   g3Form && f

         if(selectRole < 4){
            return p && g
        } else if(selectRole === 4){
            return g1Form
        } else if(selectRole === 5) {
            return g2Form
        } else if(selectRole === 6) {
            return g3Form
        }else if(selectRole === 7) {
            return g4Form
        }else{
            return false
        }
    }

    const alertCopy =()=>{
        return   showAlert && (
           <div className="alert alert-success" role="alert">
               {showAlert}
             </div>
           )}

    const formPassWord = (roleI, Rele_user, roleName) => (
        passwordShow && (
            <div onBlur={() => setURole({ ...Urole, error: "" })}>
                 {alertCopy()}
                <Form>
                   
                        <Form.Group className="mb-4">
                           {roleI >= 4 && (  
                            <>
                        <Form.Label className="mt-4">{InfoApiLabel.maxRoyality}</Form.Label>
                        <Form.Control onChange={handleChangeNumber("maxRoyalite")} type="number" placeholder={maxRoyalite> 0 ? maxRoyalite : InfoApiLabel.maxUserRoyalitY} value={maxRoyalite}/>
                        <Form.Label className="mt-3">{InfoApiLabel.maxSeller}</Form.Label>
                        <Form.Control onChange={handleChangeNumber("maxSeller")} type="number" placeholder={maxSeller > 0 ? maxSeller : InfoApiLabel.maxUserSeller} value={maxSeller}/>
                        <Form.Label className="mt-3">{InfoApiLabel.maxProducer}</Form.Label>
                        <Form.Control onChange={handleChangeNumber("maxProducer")} type="number" placeholder={maxProducer > 0 ? maxProducer : InfoApiLabel.maxUserProducer} value={maxProducer}/>
                           </>
                           )}
                         {roleI >= 5 && (  
                            <>
                        <Form.Label className="mt-3">{InfoApiLabel.maxAssoociate}</Form.Label>
                        <Form.Control onChange={handleChangeNumber("maxAssociate")} type="number" placeholder={maxAssociate > 0 ? maxAssociate : InfoApiLabel.maxUserAssoociate} value={maxAssociate}/>
                         </>
                         )}

                        {roleI >= 6 && (  
                            <>
                        <Form.Label className="mt-3">{InfoApiLabel.maxInvestment}</Form.Label>
                        <Form.Control onChange={handleChangeNumber("maxAdmin")} type="number" placeholder={maxAdmin > 0 ? maxAdmin : InfoApiLabel.maxUserInvestment} value={maxAdmin}/>
                         </>
                         )}
                        {roleI >= 7 && (  
                            <>
                        <Form.Label className="mt-3">{InfoApiLabel.maxAdmin}</Form.Label>
                        <Form.Control onChange={handleChangeNumber("maxInvestment")} type="number" max="5" placeholder={maxInvestment > 0 ? maxInvestment :InfoApiLabel.maxUserAdmin} value={maxInvestment}/>
                        </>
                        )}
                    </Form.Group>
                    </Form>

                    
                    <div className="m-4" style={{ borderBottom: "2px solid red"}} ></div>
                     {/* <Form.Group>
                         <Form.Label className="mt-3">Add a Link direction for your Users</Form.Label>
                        <Form.Control 
                        onChange={handlePassword("linkAdmin")}
                        type="url"
                        placeholder={linkAdmin? linkAdmin : 'Add your elakay shope link'}
                        />
                      </Form.Group>  */}
                      <Form>
                        <Form.Group 
                        controlId="formBasicPassword"
                        >
                        <Form.Label>{InfoApiLabel.password}</Form.Label>
                        <Form.Control
                         onChange={handlePassword("password")} 
                         type="password" 
                         placeholder="Password" 
                         />
                        <Form.Text className="text-muted">
                          {InfoApiText.textAddAdminFamilly2}
                        </Form.Text>
                    </Form.Group>

                </Form>
                <button onClick={clickCancel} className="btn btn-primary float-left">{InfoApiButton.cancel}</button>
               { activeButton() 
               ? <button onClick={() => (clickPassword(roleI, Rele_user, roleName))} className="btn btn-primary float-right">{InfoApiButton.submit}</button>
                : <button  className="btn btn-primary float-right disabled">{InfoApiButton.submit}</button>}   
            </div>
        ))



    const formAddRole = (name, supRole, roleI, Rele_user, roleName) => (
        <div className="alert alert-secondary">
        <div className="row justify-content-center " >
            <div className="col col-12 col-md-11 col-lg-8" style={{ border: "1px solid WhiteSmoke" }}>
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h4>{InfoApiButton.add} {name}</h4>
                </div>
                {showError()}
                {showMessage()}
                {showTime()}
                {emailvsTelAdding ? formEmail(supRole, roleI): telephoneuser(roleI)}
                {formPassWord(roleI, Rele_user, roleName)}

            </div></div></div>
    )


    let name
    let roleI
    let supRole
    let roleName
    let kay
    let shaObj

    const selectedUser = (selectRole) => (
        <div>
            {
               Assoociate() &&  selectRole === 1 && (

                    kay = sha1(roleUser[5].code) + sha1(roleUser[5].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "USER IN YOUR TEAM",
                    supRole = [2, 3, 4, 5],
                    roleName = "Royalty",

                    formAddRole(roleUser[5].role, supRole, 1, Rele_user, roleName)
                )
            }
            {
              Assoociate() && selectRole === 2 && (

                    kay = sha1(roleUser[4].code) + sha1(roleUser[4].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "SELLER",
                    roleI = 2,
                    supRole = [4, 5],
                    roleName = "seller",
                    formAddRole(roleUser[4].role, supRole, roleI, Rele_user, roleName)
                )
            }
            {
               Assoociate() &&  selectRole === 3 && (
                    kay = sha1(roleUser[3].code) + sha1(roleUser[3].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "PROVIDER",
                    roleI = 3,
                    supRole = [4, 5],
                    roleName = "provider",
                    formAddRole(roleUser[3].role, supRole, roleI, Rele_user, roleName)
                )
            }
 
            {
                Investment() &&  selectRole === 4 && (
                    kay = sha1(roleUser[6].code) + sha1(roleUser[6].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "ASSOCIATE",
                    roleI = 4,
                    supRole = 5,
                    roleName = "associate",
                    formAddRole(roleUser[6].role, supRole, roleI, Rele_user, roleName)
                )
            }
            {
                Adminstrator() &&  selectRole === 5 && (
                    kay = sha1(roleUser[7].code) + sha1(roleUser[7].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "INVESTMENT",
                    roleI = 5,
                    supRole = 5,
                    roleName = "investment",
                    formAddRole(roleUser[7].role, supRole, roleI, Rele_user, roleName)
                )
            } 
            {
                Family() &&  selectRole === 6 && (
                    kay = sha1(roleUser[1].code) + sha1(roleUser[1].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "ADMINISTRATOR",
                    roleI = 6,
                    supRole = 5,
                    roleName = "administrator",
                    formAddRole(roleUser[1].role, supRole, roleI, Rele_user, roleName)
                )
            }
            {
                Family() &&  selectRole === 7 && (
                    kay = sha1(roleUser[0].code) + sha1(roleUser[0].role) + sha1(userID),
                    shaObj = new jsSHA("SHA-512", "TEXT", {
                        hmacKey: { value: kay, format: "TEXT" },
                    }),
                    shaObj.update(kay),
                    Rele_user = shaObj.getHash("HEX"),

                    name = "FAMILY",
                    roleI = 7,
                    supRole = 5,
                    roleName = "family",
                    formAddRole(roleUser[0].role, supRole, roleI, Rele_user, roleName)
                )
            }
   { shareUrl && <div style={{ display: "flex", justifyContent: "center", padding: "40px" }}>
      <BusinessCard
        logo={elakay_API + "/icon/logo_elakay/logoelakay.ico"} // Remplacez par votre logo
        companyName="elakay"
        role={''}
        qrValue={shareUrl} // Lien pour le QR code
        barcodeValue="1234567890" // Valeur pour le code-barres
      />
    </div>}
        </div>
    )
    let clickEditAdd = ()=>{
        setEmailvsTelAdding(emailvsTelAdding? false : true)
        setEmailUser()
        setTelPhone()
     }

    const btnAddEdit =()=>{
    
        return<div className="mt-3"> 
            <span> 
                {/* by telephone  */}
                {infoSource.language(infoSource, "label", "byTelephon")}
                </span>
        <label className="switch" for ="emailvsTelAdding">
          <input 
          onChange={clickEditAdd} 
          type="checkbox" 
          checked={emailvsTelAdding? 'checked' : ''}
          id="emailvsTelAdding"
          />
          <span className="slider round"></span>
        </label ><span> 
            {/* by email */}
            {infoSource.language(infoSource, "label", "byEmail")}
            </span>
        </div>
        }


    const showError = () => (
        <div
            className={class_ErrName}
            style={{ display: error ? "" : "none" }}
        >
            {error}
          
        </div>
    );
    const showMessage = () => (
     <div
            className="alert alert-primary"
            style={{ display: message ? "" : "none" }}
        >
            {message}
            
            {tokenua && shareUser()}
         
        </div>
    );
    // const redirectUser = () => {
    //    if (redirectToReferrer){
    //        return <Redirect to={`/u-w?us=${userID}&sel=${roleL}&pas=${passwordShow}`} />
    //     }
    // }

    // let images = [
    //     ElakayAccount,
    //     RoyaltyUser,
    //     SellerPub1
    // ]

    // const zoomOutProperties = {
    //     duration: 5000,
    //     transitionDuration: 500,
    //     infinite: true,
    //     indicators: false,
    //     scale: 0.4,
    //     arrows: true
    // }

    // let today =  new Date().getTime()
    // let expirationday =  new Date().getTime() + parseFloat(nbrDays) * 24 * 3600 * 1000
    // let nbrDayRestant = nbrDays > 0 ? Math.abs(new Date(nbrTimer) - new Date()) : 0
    // let nbrTimes =  parseFloat(nbrDays)
    
// let nbrDayRestant =  new Date(expirationday)
    const showTime = ()=>{
        // setTimeout setInterval    
        return passwordShow && nbrTimer > 0 && <Timer
                infoSource={infoSource}
                nbrdays = {nbrTimer} 
                />
            
    }

    return (
        <>
        
            {formSelect()}
            
            {selectedUser(selectRole)}
           
            </>
    );
};

export default Message;