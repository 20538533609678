import React, { useState } from "react";
import { AddProductDescription} from "../auth/apiProduct";
import { isAuthenticated } from '../auth/';
// import { laguangeElakay  } from "../pageInside/dataElakay";
import ImagesLoading from "../pageInside/imagesLoadingNote"

const Sell = props => {
    const {
        laguangeElakay,
        infoSource
        } = props
        
    const { token } = isAuthenticated()
    const [values, setValues] = useState({
        tilte: "",
        description: "",
        error: "",
        loading:"",
    }); 
    
    const {
        tilte,
        description,
        loading,
        error,
    } = values;

    const {
        loadProductsView,
        productIDEdit,
    } = props
    
    const [navPage, setNavPages] = useState({
        imagePreviweTF: false,
        FormNoteTF: false,
        informtionShowTF: true,
        buttonDescritptionTF: true,
        buttonPubTF: true
    })

    const {
        imagePreviweTF,
        FormNoteTF,
        informtionShowTF,
        buttonDescritptionTF,
        buttonPubTF
    } = navPage

 const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, error: false, [name]: value });
    };

    const clickSubmit = event => {

        event.preventDefault();
        setValues({ ...values, error: "", loading: true });

        AddProductDescription(token, productIDEdit, {tilte, description}).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    tilte: "",
                    description: "",
                    error: "",
                    loading: false,
                });
                loadProductsView(productIDEdit)

            }
        });
    };


    const imagePreviewS = () => (
        imagePreviweTF && (
            <div className="m-4">
                <ImagesLoading
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    loadProductsView={loadProductsView}
                    maxImgSize={1}
                    namProduct={''}
                    productIDEdit={productIDEdit}
                />
            </div>
        )
    )
    const formNote = () => (
        FormNoteTF && (
            <div className="row justify-content-center">
                <div className="col col-12 col-md-12 m-0" >
                    
    
                   <form className=" m-3" >
                        <div className="form-group">
                            <div className="form-group">
                                <label className="text-muted">{infoSource.laguangeElakay('label', infoSource.languageAPI).title}</label>
                                <input onChange={handleChange('tilte')} type="text" className="form-control" value={tilte} />
                            </div>

                            <div className="form-group">
                                <label className="text-muted">{infoSource.laguangeElakay('label', infoSource.languageAPI).information}</label>
                                <textarea
                                    onChange={handleChange('description')}
                                    className="form-control"
                                    rows="5"
                                    value={description}
                                />
                            </div> 
                        </div>
                        <button className="btn btn-primary float-right m-3" onClick={clickSubmit}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).add}</button>
                    </form>
            
                </div>
            </div>
        ))

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

const InformationSHow =()=>(
    informtionShowTF && ( 
        <p className="m-4  p-4 bg-info">
            {infoSource.laguangeElakay('text', infoSource.languageAPI).texaddProductNote1}
        </p>
    )
)

const addDescription =() =>{

    setNavPages({
        ...navPage,
        imagePreviweTF: false,
        FormNoteTF: true,
        informtionShowTF: true,
        buttonDescritptionTF: false,
        buttonPubTF: true

    })
}
    const addPub = () => {

        setNavPages({
            ...navPage,
            imagePreviweTF: true,
            FormNoteTF: false,
            informtionShowTF: true,
            buttonDescritptionTF: true,
            buttonPubTF: false

        })

    }
    const cancelNote =()=>{
        setNavPages({
            ...navPage,
            imagePreviweTF: false,
            FormNoteTF: false,
            informtionShowTF: true,
            buttonDescritptionTF: true,
            buttonPubTF: true

        })
    }


    const buttonAddDescriiption =()=>(
        buttonDescritptionTF && (
            <button className="btn btn-primary float-rigth pb-2" onClick={addDescription}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).info}</button>
        )
    )
    const buttonAddPub = () => (
        buttonPubTF && (
            <button className="btn btn-primary float-left pb-2" onClick={addPub}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).infoImage}</button>
        )
    )

    const noteproducts =()=>(

        <div className="row justify-content-center">
            <div className="col col-12 col-md-11 m-2 p-0" >
             
                <div className="boxCategory1 text-center " id="heading">
                    {showError()}
                    {showLoading()}
                </div>
                {InformationSHow()}
                {imagePreviewS()}
                {formNote()}
                {buttonAddDescriiption()}
                <button className="btn btn-primary float-left pb-2" onClick={cancelNote}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>
                 {buttonAddPub()}
            </div>
        </div>
    )

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).loanding}</h2>
            </div>
        );

    return noteproducts()
};

export default Sell;