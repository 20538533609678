import React, { useState, useEffect } from "react";
import { getLakayUser } from "../auth/apiProduct";
import Slider from '../slider/swiperProduct'
import jsSHA from "jssha";

const NewProduct = ({
    laguangeElakay,
    infoSource,
    addressShop,
    userID,
    readUser,
    showB,
    edit = false
}) => {
    const [productsByArrival, setProductsByArrival] = useState([]);

    useEffect(() => {
        loadProductsByArrival(userID, 0, 50)
    }, [userID])

    let myStyles = {
        width: "100%",
        backgroundColor: '#f0f0f0'
    }
    let first1 = String(addressShop.firstName).charAt(0)
    let last1 = String(addressShop.lastName).charAt(0)
    let FL = first1.concat(last1).toLocaleUpperCase()
    let lakayUser = addressShop.shopName ? addressShop.shopName.toLocaleUpperCase(): FL

    const loadProductsByArrival = (userID, skip, limit) => {
        userID && getLakayUser(userID, skip, limit, "createdAt").then(data => {
            if(!data){
                console.log("data not found")
            }else{
            setProductsByArrival(data.products);
            }
        })
    };

    return (
        <Slider
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
            ProductSwiper={productsByArrival}
            // User={readUser}
            nameSlider={`Lakay ${lakayUser}`}
            linkSlider={`/lakay-ShopSeller/${userID}`}
            myStyles={myStyles}
            showB={showB}
            edit ={edit}
        />

    )

};

export default NewProduct;