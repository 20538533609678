
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './Styles.css'
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};
function CardSection({
    laguangeElakay,
    infoSource
}) {
    return (
    <div >
          <label  className="text-muted">
      {infoSource.laguangeElakay('label', infoSource.languageAPI).cardDetails}
      </label>
            <CardElement
             options={CARD_ELEMENT_OPTIONS} 
             />
       
       </div>
    );
};
export default CardSection;