import React, { useState, useEffect } from "react";
import { elakay_API } from "../../config";
import imgProductLakay from '../Layout/imgs/md3.jpg'
import Price from "../pageInside/productsPrice"
import Loader from "../Layout/loader.gif";
import { Link } from "react-router-dom";
const Card = ({
    laguangeElakay,
    infoSource,
    product,
    classCard0 = "col p-0",
    classCard1="card",
    urlImg,
    key,
    orderURL = false
}) => {

    const nameImg = product.name ? product.name : 'elakay image'

    return (
        <div key ={key} className={classCard0}>
            <div className={classCard1}>
                <img
                    className="card-img-top"
                    src={urlImg}
                    onError={event => {
                        event.target.src = imgProductLakay
                        event.onerror = null
                      }}
                    alt={nameImg}
                    style={{ height: "100%", width: "100%" }}
                />
                <div className="card-body  p-0 m-0">
                <div className={`cardFontSise mt-1 `} ><span><span>{nameImg.substring(0, 19)}...</span></span> </div>
                <div style={{height:"44px"}}> 
                  { orderURL && <Link to={orderURL} className="float-right mr-2">{infoSource.laguangeElakay('label', infoSource.languageAPI).view}</Link>}
                <div className="float-left p-0 m-0">    
                <Price
                    showAddToCartButton={false}
                    products={product}
                /> 
                </div>
                </div>
                </div>

            </div>
        </div>
    );
};

export default Card;