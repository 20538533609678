import React, { Component } from "react";
import { FiDelete } from "react-icons/fi";
import Card from '../pageInside/CardOrders'
import { AddToDealToDay} from "../auth/apiProduct";
import './addTodeal.css';
import { isAuthenticated } from "../auth";

class siderDr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salePrice: 3,
            show: true
        }
    }

    handleChange = event => {
        this.setState({ salePrice: event.target.value });
    }
    

    render() {
        const{
            infoSource,
            loadProductsByArrival,
            productaddToDeal,
            buttonDealB,
            show,
            DealProduct
        } = this.props


        let ilcod = infoSource.languageAPI.checkLangObjDefault
        let ileia =  infoSource.languageAPI.elakayInfoAPI
        let ilei = infoSource.languageAPI.elakayInfo
    
        let InfoApiButton =  !infoSource.loading && ilcod(ileia, ilei, 'Button')
        let InfoApiLabel =  !infoSource.loading && ilcod(ileia, ilei, 'label')

        let drawerClass = 'add-to-Deal';
        if (show) {
            drawerClass = 'add-to-Deal open';
        }
        const { token } = isAuthenticated()

        const click = event => {
            event.preventDefault();

            AddToDealToDay(token, productaddToDeal._id, { "dealTodays": true, "discount": this.state.salePrice}).then(data => {
                if (data.error) {
                    console.log(data.error)
                } else {
                    loadProductsByArrival(token, DealProduct)
                }
            });
        };
 const addButton =()=>{
     if (this.state.salePrice>2){
      return   <button className="btn btn-primary float-right m-3" onClick={click}>{InfoApiButton.edit}</button>
     }else{
        return <button className="btn float-right m-3">{InfoApiButton.edit}</button>
     }
 }
        const data = {
            name: productaddToDeal ? productaddToDeal.name : "",
            price: productaddToDeal ? productaddToDeal.price : "",
            item: productaddToDeal,
            salePrice: this.state.salePrice
        }
        let url = show && productaddToDeal.galeriesID.galeries[0].urlImg
        const infoPRoduct = () => (
            <>
            <Card
                urlImg ={url}
                product={data}
            /> 
            </>
        )
        
        const formFilter = () => {
            return (
                <div style={{width:"100%"}}>
                <div className="mt-4 mb-4 justify-content-center">
                    <div className="col col-12 col-md-11 col-lg-9 col-xl-7 p-0" >
                        <nav className={drawerClass}>

                            <div className="col col-12 " style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                                <h5>{InfoApiLabel.AddDeal}</h5>
                            </div>

                            <div className="col col-md-6 col-lg-4 mx-auto" >
                                {infoPRoduct()}
                            </div>
                            <form className=" m-3" >

                                <div className="form-group">
                                    <label className="text-muted">{InfoApiLabel.addNumber}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon3">%</span>
                                            </div>
                                    <input onChange={this.handleChange.bind('salePrice')} type="number" className="form-control" value={this.state.salePrice} min="3" max="90" />
                                    </div>
                                        <label className="text-muted" style={{fontSize:'12px'}}> {InfoApiLabel.dealNumberMore3}</label>
                                </div>
                                <span className="btn btn-primary float-left m-3 pt-2 pb-2 " onClick={() => buttonDealB()} style={{ fontSize: "20px" }}><FiDelete /></span>
                                    {addButton()}
                            </form>
                        </nav>
                    </div>
                </div>
                </div>

            )
        }

        return (
           
                formFilter()


            
        )
    }
};

export default siderDr;