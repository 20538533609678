import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { isAuthenticated } from '../auth';
import { Link, useParams} from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { getFilterByID, updateFiltersValue, removeFilterValue} from "../auth/apiCategory";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import "./category.css"



const CreatCategory = props => {
    const {
        infoSource,
        laguangeElakay
            } = props

    let params = useParams()
    const [name, setFilters] = useState("");
    const [getCartItems, setgetCart] = useState([]);
    const [filter, setfilter] = useState([]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState([]);

    // destructure user and token from localstorage
    const { token } = isAuthenticated();
    const filterID = params.filterID
    const [showDeleteAgree, setDeleteAgree] = useState(false);
    const [chek, setChek] = useState(false);
    const [seletName, setSeletName] = useState('');
    const handl = (a) => {
        setChek(a)
    }
    // creat button filter is true
    const filterButton = true

    // load categories and set form data
    const loadFilters = (filterID, token) => {
        getFilterByID(filterID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setfilter(data)
                setLoading(false)
            }
        });
    };


    const clickSubmit = e => {
        e.preventDefault();

        setError("");
        setSuccess(false);
        // make request to api to create category
        updateFiltersValue(token, filterID, { name }).then(data => {
            if (data.error) {
                // loadCategory(elakayCategoryID);
                setError(data.error);
            } else {

                setError("");
                setSuccess(true);
                setMessage('the filters name: ' + name + ' is Create in ' + filter.name)
                setFilters("")
                loadFilters(filterID, token)
            }
        });
    };


    const deleteFIL = (filterID, name, token) => {
        removeFilterValue(filterID, {name}, token).then(data => {
            if (data.error) {
                setError(data.error);
                // loadCategory(categoryID)
            } else {
                setError(false);
                setSuccess(true);
                setMessage('the filters name: ' + name + ' is Create in ' + filter.name)
                setFilters("")
                loadFilters(filterID, token)
                setDeleteAgree(false)
                setChek(false)
            }
        });
    };

    useEffect(() => {
        loadFilters(filterID, token);
    }, [filterID, token]);

    const handleChange = event => {
        setFilters(event.target.value)
    };

    const SubmitB = (name) => {
        setSeletName(name)
        setDeleteAgree(true)

    }
    const deleteAgree = (name, filterID, token) => {

        return (
            <div className="contaire">
                <div className="row justify-content-center " >
                    <Modal
                        show={showDeleteAgree}
                        onHide={() => setDeleteAgree(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h4 className="text-center">
                                    {/* delete this items */}
                                    {infoSource.language(infoSource, "title", "delThtems")}
                                    </h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        <div className="row justify-content-center m-4">
                                <h5>
                                    {/* are you sure you want to delete this category name's  */}
                                    {infoSource.language(infoSource, "title", "surToDelete")}
                                    <span className="text-danger" > {name}</span> </h5>
                                <div className="list-unstyled">
                                    <input
                                        onChange={() => handl(chek ? false : true)}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={chek ? 'checked' : ''}
                                        id="surToDelete"
                                    />
                                    <label className="form-check-label" for="surToDelete">
                                        {/* check the button if your are agree */}
                                        {infoSource.language(infoSource, "title", "chButAgree")}
                                        </label>
                                </div>
                                {
                                    chek ?
                                        <Button onClick={() => deleteFIL(filterID, name, token)}>
                                          {infoSource.language(infoSource, 'Button', "submit")}
                                        </Button> :
                                        <button className="btn" >
                                        {infoSource.language(infoSource, 'Button', "submit")} 
                                        </button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }

    const CategoryFom = () => (

        <div>   
             <div className="boxCategory1" id="heading">
                        <h2>Value of {filter.name} </h2>
                {showSuccess()}
                {showError()}
             </div>
            <div className="boxCategory">


                {filter._id && filter.FilterItem.map((c, i) => (
                    <form className="container" key={i} >
                        <div className="itemCategory row">
                            <div className="col col-lg-7 Cacol">
                                 <h5 >
                                    {c}
                                </h5>

                            </div>
                            <div className="col col-lg-5 Cacol">
                                <span onClick={() => SubmitB(c) }  className="dellCategory"><FaRegTrashAlt /></span>
                            </div>
                        </div>
                        <input className="inputCategory" type="hidden" name="listName" value=""></input>
                        {(c === seletName) && (
                            deleteAgree(c, filter._id, token)
                            )}
                    </form>
                ))}
              

                <form className="itemCategory" onSubmit={clickSubmit}>
                    <input
                        onBlur={() => setMessage("")}
                        className="inputCategory"
                        type="text"
                        placeholder="New filter value"
                        onChange={handleChange}
                        value={name}
                        autoFocus
                        required
                    />
                    <button className="AddCategory">+</button>
                </form>
            </div>
            <p></p>

        </div>

    );

    const createCategoryButton = () => {
        return (
            <Link to={`/filters`} className="btn btn-outline-primary mt-2 mb-2">
                {/* View Elakay Filters */}
                {infoSource.language(infoSource, "Link", "ViElakayFil")}
            </Link>
        )
    }

    const showSuccess = () => {
        if (success) {
            return <div className="alert alert-info" style={{ display: message ? "" : "none" }}> {message} </div>;
        }
    };

    const showError = () => {
        if (error) {
            return <div className="alert alert-danger" style={{ display: error ? "" : "none" }}> {error} </div>;
        }
    };


    return (
        <Layout
            infoSource ={infoSource}
            className="container blockEl"
            SliderPunShow={false}
            loading={loading}
        >
            <div style={infoSource.styleNav}>
            <div className="row">
                <div className="col-md-8 offset-md-2  blockEl" onBlur={() => setMessage("")}>
                   
                    {CategoryFom()}
                    <div className="container">
                        {createCategoryButton()}
                        {/* {createFilterProduct(filterButton)} */}
                    </div>
                </div>
            </div>
            </div>
        </Layout>
    );

};

export default CreatCategory;