import React, {useState, useRef} from "react";
import Layout from "../Layout/Layout";
import {checkUserAddress, deleteUserAddress, isAuthenticated,  AddAddress, editAddressUser, getAddressUserById, getAddressCountries} from "../auth";
import { FaPlus, FaCheckCircle, FaCheckSquare} from "react-icons/fa";
import { MdHome, MdRadioButtonChecked, MdEdit, MdDeleteForever} from "react-icons/md";
import { Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MdSmartphone} from "react-icons/md";
import {laguangeElakay } from "./dataElakay";
import FormAddress from "./formInput/formAddress";
// import "./sliderProduct.css"



const AddressUser = (props) => {
    const {
        infoSource,
        handePage
            } = props

    const {
        phoneUser,
        userInfo,
        address,
        readUser
    } = infoSource

    let userValue = {
         firstName: "",
        lastName: "",
        shopName: null,
        tel: "",
        Address1: "", 
        Address2: "",
        city: '',
        State: '',
        postalCode: '',
        country: '',
        latitude: "",
        longitude: "",
        error: "",
        redirectToFilter: false
    }
    const [allZipCodes, setAllZipCodes] = useState([])
     const divAddressRef = useRef(null);
    const scrollToParagraph = (ref) => {
            ref.current.scrollIntoView({ behavior: 'smooth' });
          }
    const [page, setPage]= useState({
        pageAdd : false,
        pageEdit: false,
        addressID: null
    })
    const { token } = isAuthenticated();

    const [values, setValues] = useState(userValue);

    const {
        redirectToFilter
    } = values;

    const {
        pageAdd ,
        pageEdit,
        addressID
    } = page

    const loadCountries = (code, zipcode, page) => {
        getAddressCountries(code, zipcode).then(data => {
            if (data.error) {
                console.log("data Not Found");
            } else {
                if(!zipcode){
                    if(page !== "edit"){
                    userValue.country = code
                    setValues(userValue);
                    }
                setAllZipCodes(data.zipCodes)
                }else{
                    setValues({ ...values,
                        city: data.place,
                        State: data.state,
                        postalCode: data.zipcode,
                        latitude: data.latitude,
                        longitude: data.longitude,
                        error: false });
                }
            }
        });
    };

    const addressById = (token, AddressID, page) => {

        getAddressUserById(token, AddressID ).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {   
                loadCountries(data.address[0].country, undefined, page)
                setValues({
                    ...values, 
                    firstName: data.address[0].firstName,
                    lastName: data.address[0].lastName,
                    shopName: data.address[0].shopName,
                    tel: data.address[0].tel,
                    Address1: data.address[0].Address1,
                    Address2: data.address[0].Address2,
                    city: data.address[0].city,
                    State: data.address[0].State,
                    postalCode: data.address[0].postalCode,
                    country: data.address[0].country,
                    redirectToFilter: false
                })
    } })}

    const telAdding = ()=>(
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-4" style={{ border: "1px solid Gainsboro" }} >
            <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).noPhone}</h4>
            <h6>{infoSource.laguangeElakay('title', infoSource.languageAPI).clickPhoneNumber}</h6>
                <Link onClick={()=>handePage("pagePhone")} className="btn btn-outline-info btn-block rounded-4 p-1">
                        <span style={{ fontSize: "20px", paddingRight: "6px" }}><MdSmartphone /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addTel}      
                </Link>
        </div>
        </div>
    )
    const clickAdd = event => {

        event.preventDefault();
           
        AddAddress(token, values).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                readUser(token)
                userValue.redirectToFilter = true
                setValues(userValue);
            }
        });
    };
    
    const clickEdit = event => {

        event.preventDefault()
       
        editAddressUser(token, addressID, values).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                userValue.redirectToFilter = true
                setValues(userValue);
                readUser(token)
            }
        });
    };

    const DeleteAddress = (id) => {
        deleteUserAddress(token, id).then(response => {
            if (response.error) {
                console.log(response.error);
            } else {
                readUser(token)
            }
        });
    }

    const CheckDefaultAddress =id => {
        checkUserAddress(token, id).then(response => {
            if (response.error) {
                console.log(response.error);
            } else {
                readUser(token)
            }
        });
    }

    const editBtnFunction = (page, id)=>{
        if(page === "edit"){
        scrollToParagraph(divAddressRef)
            setPage({
                pageAdd : false,
                pageEdit: true,
                addressID: id
            })
            addressById(token, id, page)
        }else if (page === "add"){
            setPage({
                pageAdd : true,
                pageEdit: false,
                addressID: null
            })
            setAllZipCodes([])
            setValues(userValue)
        }else{
            setPage({
                pageAdd : false,
                pageEdit: false,
                addressID: null
            })
            setAllZipCodes([])
            setValues(userValue)
        }
    }

    const editButtonAddress = (id) => {
        return (
            <Link onClick={()=> editBtnFunction("edit", id)}>
                <span className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                    <MdEdit />
                </span>
            </Link>
        )

    }

    const deleteButtonAddress = id => {
        return (

            <span onClick={()=>DeleteAddress(id)} className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <MdDeleteForever />
            </span>

        )

    }

    const checkButtonAddress = (id, address)=> (
      
            address ?
            <span   className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <FaCheckCircle />
            </span>
            :
            <span onClick={() => CheckDefaultAddress(id)} className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <MdRadioButtonChecked />
            </span>
    ) 

    const buttonModification = () => (
     
            <div className="row justify-content-center">
                <div className="bg clearfix ">
                <Link onClick={()=>handePage()} className="btn btn-outline-info rounded-4 float-left m-4 pt-2 pb-2 pr-3 pl-3">
                <span style={{ fontSize: "18px", paddingRight: "6px" }}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).close}</span>
            </Link>

            <Link onClick={()=>{editBtnFunction("add"); scrollToParagraph(divAddressRef)}} >
            <span className="btn btn-info float-right m-4" style={{ width: "40px", height: "40px", padding: 0, textAlign: "center", fontSize: "1.5rem", borderRadius: "50%" }} data-toggle="tooltip" data-placement="top" title="add to deal"  >
                <FaPlus />
            </span>
            </Link>

                </div>
            </div>
        
    )
    const buttonEditDelete = (id, addressDefault) => (

        <div className="row justify-content-center">
            <div className="bg clearfix ">
                {editButtonAddress(id)} 
                {checkButtonAddress(id, addressDefault)}
                {deleteButtonAddress(id)}

            </div>
        </div>

    )

    const defaultAddress =address=>(
        address && (
            <span className="float-right"><FaCheckSquare />{infoSource.laguangeElakay('label', infoSource.languageAPI).default}</span>
        )
    )
    const AddressUser = (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>  {`${infoSource.laguangeElakay('title', infoSource.languageAPI).address} ${userInfo.firstname} ${userInfo.lastname}`}  </h3>
                </div>
                <div className="container-fluid">
                    <Row xs={2} sm={2} md={3} lg={3} xl={4}>
                        {
                            address.map((m, i) => (
                                <div key={i} style={{ minWidth: "310px" }}> 
                                <div  className="card m-2" style={{minWidth:"300px"}} >
                                        
                                        <p>{defaultAddress(m.addressDefault)} <br /><span style={{ fontSize: "18px" }}><MdHome />{m.firstName}  {m.lastName}</span>  <br /> <span style={{ paddingLeft: "22px" }}>{m.Address1} </span><br /> <span style={{ paddingLeft: "22px" }}>{m.city}, {m.State} {m.postalCode} {m.country}</span><br /> <span style={{ paddingLeft: "22px" }}>{m.tel}</span></p>
                                        {buttonEditDelete(m._id, m.addressDefault)}
                                </div>
                                </div>
                            ))

                        }
                        
                    </Row>
                    <div>
                        {buttonModification()}
                        </div>
                </div>
            </div>
        </div>
    )
    const showForm = ()=>{
       if(pageAdd){
          return true 
        }
        if(pageEdit){
            return true 
          }

        return false

    }


    return (
        <>
            {AddressUser}
            {phoneUser[0] != undefined ?
           <div ref={divAddressRef} > 
         {  showForm() && 
            <FormAddress
            editBtnFunction={editBtnFunction}
              clickSubmit={pageAdd ? clickAdd: clickEdit}
              values ={values} 
              setValues={setValues}
              infoSource={infoSource}
              phoneUser={phoneUser}
              laguangeElakay ={laguangeElakay}
              loadCountries={loadCountries}
              allZipCodes={allZipCodes}
            setAllZipCodes={setAllZipCodes}
            />}
            </div> :
            telAdding()
            }
             </>    
    );
};

export default AddressUser;