import React, { useState} from "react";
import { elakay_API } from "../../config";
import PreViewProduct from '../slider/swiperProdView'
import Vote from './commentaire/vote'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Price from "./productsPrice"
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import CommentaireUser from './commentaire/comentairePro'
import { json } from "react-router-dom";


const AddCartFilter =(a, n, f)=>{
    return a.filter(item =>item.filter[f] == n)
  }

  const objToArr = (a)=>{
    return a && {v: Object.values(a), k: Object.keys(a)}
}

const ModalProdView = (props) =>{
    const {
        infoSource,
        setShowListe,
        showListe,
        productV,
        image,
        // start
        skip,
        limit,
        voteA,
        comments,
        productId,
        token,
        setCommentsPro,
        SetSizeComments,
        setUserVote,
        setShowComments,
        commentProduct,
        loadProductsView,
        ButtonAddComments,
        showComments,
    }=props

const [key, setKey] = useState('details');

    const {
        // voteA,
        // comments,
        // filters,
        filter,
        values,
        // urlImg,
        filterColor
    } = productV
    
    const sum = (total, num) => (
        total + Math.round(num)
    );
    const sumVote = (
        voteA.map((p) => p.voteNum)
    )
    const ratingVote = parseFloat(sumVote.reduce(sum, 0) / voteA.length)


    const etoiteProduct = (
        <>
            {
                ratingVote && (
                    <h5 className='p-0 m-0'>
                        <Vote rating={ratingVote} />
                    </h5>
                )}
            {!ratingVote && (
                <h5 className='p-0 m-0'>
                    <Vote rating={0} />
                </h5>
            )
            }
        </>
    )
    let selectFilter = AddCartFilter(filter, filterColor,  'color')[0]? AddCartFilter(filter, filterColor, 'color')[0].filter : filter[0] && filter[0].filter
    let arrayFilter =  objToArr(selectFilter)
    
    const filterDetail = () =>{

      return <div className="mt-3">
      {   arrayFilter &&   arrayFilter.k.map((key , i) => (
                    <div key={i}>
                        <p style={{ minWidth: "130px", fontWeight: "bold", fontSize: "18px", display: "inline-block" }}>{key}: </p>
                        <p style={{ fontSize: "15px", display: "inline-block" }}>{arrayFilter.v[i]}</p>
                    </div>
                ))
      }
        </div>
    }

    const imgsa = [image]
    
    const imageSlider = (
        <>
        <PreViewProduct
        images={imgsa} 
        />
        </>
    )

    const detailsDescription = (
        <div >
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="details" title="Details">

                    {filterDetail()}

                </Tab>
                <Tab eventKey="description" title="Description">
                    <p className="mt-3">
                        {values.description}
                    </p>
                </Tab>
            </Tabs>
        </div>
    );
    const productView=()=>(
    <>
    <div className="row mt-3">
                <div className="col col-12 col-md-12 col-lg-5">
                {imageSlider}
                </div>
                <div className="col col-12 col-md-12 col-lg-7">
                    {infoProduct()}
            </div>
        </div>
                    <div className="row m-3 justify-content-center" >
                <div className="col col-12 col-md-11 col-lg-11 col-xl-7 ">
                {detailsDescription}
            </div>
            </div>
            </>
    )

    const infoProduct = () => (
        <div>
            {etoiteProduct}
            <Table>
                <tbody>
                    <tr>
                        <td><h3>{values.name}</h3></td>
                    </tr>
                    <tr><td>{values.description}</td>
                    </tr>

                    <tr>
                        <td>
                            <Price
                                showAddToCartButton={false}
                                products={values}
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>

        </div>
    )

    const ProductViewModal = () => (
        <div className="container">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={showListe}
                    onHide={() => setShowListe(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                        {infoSource.laguangeElakay('title', infoSource.languageAPI).itemsView}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {productView()}
 {comments && <CommentaireUser
                infoSource={infoSource}
                skip={skip}
                limit={limit}
                voteA={voteA} 
                comments={comments}
                productId={productId}
                token={token}
                setCommentsPro={setCommentsPro}
                SetSizeComments={SetSizeComments}
                setUserVote={setUserVote}
                setShowComments={setShowComments}
                commentProduct={commentProduct}
                loadProductsView={loadProductsView}
                ButtonAddComments={ButtonAddComments}
                showComments={showComments}
            />}    
           
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )

    return <> 
    { ProductViewModal()}
  
    </>

       


}
export default ModalProdView;
