// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StripeElement {
  height: 40px;
  width: 100%;
  min-width: 260px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}`, "",{"version":3,"sources":["webpack://./src/includes/pageInside/Stripe/Styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,6BAA6B;EAC7B,kBAAkB;EAClB,+BAA+B;EAE/B,iCAAiC;AACnC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,qBAAqB;AACvB;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".StripeElement {\n  height: 40px;\n  width: 100%;\n  min-width: 260px;\n  color: #32325d;\n  background-color: white;\n  border: 1px solid transparent;\n  border-radius: 4px;\n  box-shadow: 0 1px 3px 0 #e6ebf1;\n  -webkit-transition: box-shadow 150ms ease;\n  transition: box-shadow 150ms ease;\n}\n.StripeElement--focus {\n  box-shadow: 0 1px 3px 0 #cfd7df;\n}\n.StripeElement--invalid {\n  border-color: #fa755a;\n}\n.StripeElement--webkit-autofill {\n  background-color: #fefde5 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
