import React, { useState, useEffect } from "react";
import { MdDeleteForever, MdEdit, MdAdd, MdOutlineDeleteForever} from "react-icons/md";
import {useParams} from "react-router-dom";
import { FiDelete, FiCheckCircle, FiCircle,  FiEdit3,  } from "react-icons/fi";
import { FaLongArrowAltLeft} from "react-icons/fa";
import Layout from "../Layout/Layout";
import { getCategoriesByID, getCategoriesByName} from "../auth/apiCategory";
import { getProductPreview, updateFilterProduct, createFilterProduct, deleteFilterProduct,  deleteImageProduct, updateProduct, deleProductImageNote, linkCTP, UnlinkCTP, parcelProductUA, getImages} from "../auth/apiProduct";
import {getAddressById, isAuthenticated } from "../auth";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { elakay_API } from "../../config";
import AddProductNote from "../pageInside/addProductNote"
import ImagesLoading from "../pageInside/imagesLoading"
import LinkImagesTofilterPro from "../pageInside/linkImagesTofilterPro";
import  AddFilterProduct from "../pageInside/adfilterProduct"
import { laguangeElakay } from "../pageInside/dataElakay";
import AddParcel from "../pageInside/AddParcel";
import LakayUser from '../pageInside/lakayUser'
import ProdViewEdit from "../pageInside/ProdViewEdit";
import {GrClose} from "react-icons/gr";
import CatSelected from "./categories/catSelected";
import CatSearch from "./categories/catSearch";
import "./category.css"

const categoyseleted = []; 
const categoyIDseleted = [];
// const imagesArray = [];
const filter =  new Object();

const getFiels = (a ,b)=>{
    var c = []
    if(a && a.length > 0){
    for (var i = 0; i <  a.length; i++)
    if(!c.includes(a[i][b])){
     c.push(a[i][b])
    }}
        return c
    }

const EditProduct = props => {
    const {
        infoSource
            } = props

    const {
        userInfo = [],
        address = []
        } = infoSource
    
    let emptParcel = {
        length: "",
        width: "",
        height: "",
        weight: ""
    }

    let emptFiltersHand = {
        catID:"",
        quantity:"",
        priceFil:"",
        filter:{},
        parcel:{}
        }

    let params = useParams()
    // let categoryID = params.categoryId;
    let productsID = params.productId;
    const [catSelectedSearch, setcatSelectedSearch] = useState(true);
    const [categoryID,  setCategoryID] = useState()
    const [search, setSearch] = useState([]);
    const [addressSelect, setAddressSelect] = useState('');
    const [shopAddress, setShopAddress] = useState('');
    const [filtersCategory, setFiltersCategory] = useState([])
    const [imagesArray, setImagesArray] = useState([])
    const [imagesSms, setImagesSMS] = useState("")
    const [filtersProduct, setFiltersProduct] = useState([])
    const [filterSel, setFilterSel] = useState([]);
    const [ImagesProd, setImagesProd] = useState([]);
    const item = ""
    const nameImg = item ? item : 'elakay image'
    const { token } = isAuthenticated()
    const [note, setNote] = useState([])
    const [selectCatalogId, setSelectCatalogId] = useState();
    const [items, setItems] = useState([]);
    const [itemFilValue, setItemFilValue] = useState({});
    const [getcategories, setCategories] = useState([])
    const [btnLinkCTP, setBtnLinkCTP] = useState(true)  
    const [btnFilterPrice, setFilterPrice] = useState(false)   
    const [pageLAAI, setPageLAAI] = useState(true)
    const [getProduct, setProduct] = useState([])
    const [parcel, setParcel] = useState(emptParcel)

    const [FiltersHand, setFilterHand] = useState(emptFiltersHand)
   
    const [values, setValues] = useState({
        name: '',
        description: "",
        costPrice:"",
        price: "",
        shipping: ""
    });
    const {
        name,
        description,
        costPrice,
        price,
        shipping
    } = values;
// let quantity
// let priceFil
   const {
    catID,
    quantity,
    priceFil
   } = FiltersHand  

    const [pages, setPages]= useState({
        product : true,
        productFilte : false,
        productImage: false,
        categories: false,
        productNote: false,
        productAddImage: false,
        AddFilter:false,
        EditFilter:false,
        pageParcel:false,
        AddFilterPre:false


    })
    const {
        product,
        productFilte,
        productImage,
        categories,
        productNote,
        productAddImage,
        AddFilter,
        EditFilter,
        pageParcel,
        AddFilterPre
        } = pages


   const Buttonproduct  =()=>{
        loadCategory(categoryID)
        setPages({
            product: true, 
            categories: false,
            productFilte: false,
            productImage: false,
            productNote: false,
            productAddImage: false,
            AddFilter:false,
            EditFilter:false,
            pageParcel:false,
            AddFilterPre:false

        })
    }

    const ButtonCategories =()=>{
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
        loadCategory(category)
        setPages({
            product: false,
            categories: true,
            productFilte: false,
            productImage: false,
            productNote: false,
            productAddImage: false,
            AddFilter: false,
            EditFilter: false,
            pageParcel: false,
            AddFilterPre: false

        })
    }

    const ButtonproductFilte =()=>{
        loadCategory(categoryID)
        setPages({
            product: false,
            productFilte: true,
            productImage: false,
            productNote: false,
            categories: false,
            productAddImage: false,
            AddFilter: true,
            EditFilter: false,
            pageParcel: false,
            AddFilterPre: false

        })
        createdFilter(filtersCategory)
    }  

    const ButtonproductNote =()=>{
        loadCategory(categoryID)
        setPages({
            product: false,
            productFilte: false,
            productImage: false,
            productNote: true,
            categories: false,
            productAddImage: false,
            AddFilter: false,
            EditFilter: false,
            pageParcel: false,
            AddFilterPre: false

        })
    } 

const cleanFilterHand =()=>{
    setFilterHand(emptFiltersHand)
}

  const handleEeditFilter = () =>{
    setFilterHand(emptFiltersHand)
            setPages({
            product: false,
            productFilte: true,
            productImage: false,
            productNote: false,
            categories: false,
            productAddImage: false,
            AddFilter: false,
            EditFilter: true,
            pageParcel: false,
            AddFilterPre: false

        })
    }

    const handleFilterPre = () =>{
                
        setPages({
            product: false,
            productFilte: true,
            productImage: false,
            productNote: false,
            categories: false,
            productAddImage: false,
            AddFilter: false,
            EditFilter: false,
            pageParcel: false,
            AddFilterPre: true

        })
        createdFilter(filtersCategory)
    }
  
    const handlePageParcel= () =>{
                   
        setPages({
            product: false,
            productFilte: true,
            productImage: false,
            productNote: false,
            categories: false,
            productAddImage: false,
            AddFilter: false,
            EditFilter: false,
            pageParcel: true,
            AddFilterPre: false

        })
    } 

    const AddMoreImage =()=>{
        setPages({
            product: false,
            categories: false,
            productFilte: true,
            productImage: false,
            productNote: false,
            productAddImage: true,
            AddFilter: false,
            EditFilter: false,
            pageParcel: false,
            AddFilterPre: false

        })
    }
    const productTrue = name !== "" && price !== "" && description !== "" && shipping !== ""  && addressSelect !== undefined 
    const bntLinkImgtoFil = (b)=>{
       
        let t =  imagesArray.includes(b) 

        let c 
       if(!pageLAAI){
        if(!t){ 
        if(imagesArray.length === 4 ){
            setImagesSMS("you can only add 4 images")
        }else{
            setImagesSMS("")
        c = [...imagesArray, b]
            setImagesArray(c)
            }
        }else{
            setImagesSMS("")
           setImagesArray(imagesArray.filter(item => item !== b));
        }
    }

    }
    

    const addressId = (token, adressId) => {

        adressId && (
            getAddressById(token, adressId).then(data => {
                if (data.error) {
                    console.log(data.error);
                    setAddressSelect('')
                } else {
                    setAddressSelect(data[0])
                }
            }))
    }

    const allItemsCat = (data)=>{
        data && setFiltersCategory(data.filter) 
        data && setCategories(data.cartegory)
        data && setItems(data.items) 

    }

    const loadCategory =(categoryID)=> {
        getCategoriesByID(categoryID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {

                setFiltersCategory(data.filter) 
                setCategories(data.cartegory)
                setItems(data.items) 
                // createdFilter(data.filter)
            }
        });
    };

    const loadImages =(Id)=> {
        getImages(Id).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setImagesProd(data)
            }
        });
    };

    const loadProductsView = (productsID, a)  => {
        
        productsID && getProductPreview(productsID ).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
            
                loadImages(productsID)
                SelValfil(a, data.product.filters)
                setValues({  
                        name: data.product.name,
                        description: data.product.description,
                        costPrice: data.product.costPrice,
                        price: data.product.price,
                        shipping: data.product.shipping
                    })
                setParcel(data.filterProduct.length > 0 ? data.filterProduct[0].parcel: emptParcel)
                setCategoryID(data.product.categoriesID.length > 0 && data.product.categoriesID[0].catID._id)
                setAddressSelect(data.shopAddress)
                // setShopAddress(data.shopAddress)
                // setImagSize(data.galeries)
                setFiltersProduct(data.product.filterProduct)
                setFilterSel(data.product.filters)
                setSelectCatalogId(data.product.categoriesID)
                setNote(data.note)
                setProduct(data.product) 
                
            }
        });
    };
    const deleteImage = (token, Id, imageID, a) => {
        deleteImageProduct(token, Id, imageID).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
                loadProductsView(Id)
                imagesFilters(a)
                cleanFilterHand()
            }
        });
    };

    const deleteImageNote = (token, Id, imageID) => {
        deleProductImageNote(token, Id, imageID).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
                
                loadProductsView(Id)

            }
        });
    };


    const SelValfil =(a, b)=>{
      
        b && b.filter(Item => {
          if (Item._id._id === a){
            setItemFilValue(Item)
          }
        })
    }

    useEffect(() => {
        loadProductsView(productsID)
    }, [productsID, token]);


    const category = '5ed4946fd2ba8812c20261a8'
 
    const elakayCategoryID = params.categoryId ? params.categoryId : category;

    const clickBack = (LA) => {
        loadCategory(LA)
        categoyIDseleted.pop()
        categoyseleted.pop()
    }

    const seleteCategory = () => event => {
        const supcategory = event.target.value;
        
        if (supcategory !== category){
        categoyseleted.push(getcategories.name)
        categoyIDseleted.push(getcategories._id)
        loadCategory(supcategory)
        // setSelectCatalogId(supcategory)
    }else{
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
        loadCategory(supcategory)
    }
    }

    const SelFilterCat = ()=> event =>{
        const id = event.target.value;
        getCategoriesByID(id).then(data =>{
            if (data.error) {
                console.log(data.error);
            } else {
            setFiltersCategory(data.filter) 
            createdFilter(data.filter)
        }
        })
    }

    const ButtonBackCategory = () => {
        let last_le = categoyIDseleted[categoyIDseleted.length - 1]
        let CategoryIDSEl = last_le ? last_le : category
        return (
            <span onClick={() => clickBack(CategoryIDSEl)} className="h5 float-left">
                <FaLongArrowAltLeft />
            </span>
        )
    }
    const parcelChange = name => event => {
        const value = event.target.value;
        setParcel({ ...parcel, [name]: value });
    };

    const selectAdress = () => event => {
        const IdAdress = event.target.value;
        addressId(token, IdAdress)
    }
    
    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, error: false, [name]: value });
    };
    
    const createdFilter = (ArryFilter) => {

        for (let fil in filter){
        delete filter[fil]
        }
        ArryFilter.forEach(items => { 
            filter[items._id.name] = ""
            // filterArray.push(null)
        })
   
        setFilterHand({
            catID: getcategories._id,
            filter: filter,
            parcel:{},
            quantity:"",
            priceFil:""
            
        })
    }


    const handleFilter = (name) => e => { 
       let id =  FiltersHand._id
        const value = e.target.value;
        let a = name === 'quantity' ? value : FiltersHand.quantity ? FiltersHand.quantity : ""
        let b = name === 'priceFil' ? value : FiltersHand.priceFil ? FiltersHand.priceFil : ""
        
        filter[name] = value;
        delete filter['quantity']
        delete filter['priceFil']
        setFilterHand({
            ...FiltersHand,
            quantity: a ? parseInt(a): "",
            priceFil: b,
            filter: filter
        })
}


    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });

        updateProduct(token, productsID, { email: userInfo.email , name, description, costPrice, price, shipping,  addressShop: addressSelect._id, }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    inpProduct: false,
                    filterProduct: true,
                    productID: data.productID,
                    createdProduct: data.name,
                    // redirectToFilter: true
                    loading: false,
                });
                loadProductsView(productsID)
              
            }
        });
    };


    const clickSubCat = () => {

        setValues({ ...values, error: "", loading: true });
        let infoCat =  {
            catID: getcategories._id, 
            // name: getcategories.name, 
            gategoriesLink: getcategories.categoryID
        }

        linkCTP(token, productsID, infoCat).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                loadCategory(category)
                categoyseleted.splice(0, categoyseleted.length)
                categoyIDseleted.splice(0, categoyIDseleted.length)
                setValues({
                    ...values,
                    inpProduct: false,
                    filterProduct: true,
                    productID: data.productID,
                    createdProduct: data.name,
                    // redirectToFilter: true
                    loading: false,
                });
                setSearch([])
                loadProductsView(productsID)
              
            }
        });
    };

    const clickFilter = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });
        createFilterProduct(token, productsID, {filter, quantity: parseInt(quantity), priceFil, catID: getcategories._id}).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else { 
                cleanFilterHand()
                setValues({
                    ...values,
                    inpProduct: false,
                    filterProduct: false,
                    imgPreview: true,
                    productID: data.productID,
                    // redirectToFilter: true
                    loading: false,
                });
                ButtonproductFilte()
                loadProductsView(productsID)
               
            }
        });
    };
    const handleCheckPrice = c => () => {
            if(c){
                setFilterPrice(false)
                setFilterHand({ ...FiltersHand, 'priceFil': "" });
            }else{
                setFilterPrice(true)
                
            }
    }
    const clickFilter1 = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });
        updateFilterProduct(token, productsID, FiltersHand._id, {filter, quantity: parseInt(quantity), priceFil}).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,

                    inpProduct: false,
                    filterProduct: false,
                    imgPreview: true,
                    productID: data.productID,
                    // redirectToFilter: true
                    loading: false,
                });
                loadProductsView(productsID)
            }
        });
    };

    const clickParcel = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });
        parcelProductUA(token, productsID, FiltersHand._id, parcel).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,

                    inpProduct: false,
                    filterProduct: false,
                    imgPreview: true,
                    productID: data.productID,
                    // redirectToFilter: true
                    loading: false,
                });
                loadProductsView(productsID)
            }
        });
    };
    const clickFilter2 = (id) => {
       
        setValues({ ...values, error: "", loading: true });
        deleteFilterProduct(token, productsID, id).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({ ...values, error: "", loading: true });
                loadProductsView(productsID)
            }
        });
    };

    const dltLinkCTP= (a) => {
       
        setValues({ ...values, error: "", loading: true });
        UnlinkCTP(token, productsID, a).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({ ...values, error: "", loading: true });
                loadProductsView(productsID)
            }
        });
    };

    const clickButton2 =()=>{
        loadCategory(elakayCategoryID)
        // setValues(jsonValues)
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
        setValues({ ...values, selCategories: false, AcCat:"", AcInf:""});
    }

    let StyleMenu0 = {
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
        borderBottom: "None",
        backgroundColor: "White"
    }
    let StyleMenu1 = {
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
        borderBottom: "6px solid #007399",
    }

    let StyleMenu = {
        borderBottom: "6px solid Gainsboro",
        backgroundColor: "GhostWhite"
    }

    let paymentStyle = product ? StyleMenu : StyleMenu0

    let bankingStyle = productFilte || productImage || productAddImage ? StyleMenu : StyleMenu0

    let incommeStyle = productNote ? StyleMenu : StyleMenu0

    let categoryStyle = categories ? StyleMenu : StyleMenu0

    let filterAdd = AddFilter ? StyleMenu1 : StyleMenu0

    let filterPre = AddFilterPre ? StyleMenu1 : StyleMenu0

    let filterMore = EditFilter || pageParcel ? StyleMenu1 : StyleMenu0

    let FilterImg =  productImage || productAddImage ? StyleMenu1 : StyleMenu0

        const editProductMenu = () => (

        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" >
                <div className="col col-12 col-md-12" style={{ textAlign: "center"}} >
                    <div className="row">
                        <div className="col col-12 col-md-3" style={paymentStyle} onClick={Buttonproduct}>
                            <p className="m-2"><MdEdit/>
                            {infoSource.laguangeElakay('Button', infoSource.languageAPI).info}
                            </p>
                        </div>
                        <div className="col col-12 col-md-3" style={categoryStyle} onClick={ButtonCategories}>
                            <p className="m-2"><MdEdit />
                            {infoSource.laguangeElakay('title', infoSource.languageAPI).Categories}
                            </p>
                        </div>
                        <div className="col col-12 col-md-3" style={bankingStyle} onClick={ButtonproductFilte}>
                            <p className="m-2"><MdEdit />
                            {infoSource.laguangeElakay('title', infoSource.languageAPI).filtersImgs}
                            </p>
                        </div>
                        <div className="col col-12 col-md-3" style={incommeStyle} onClick={ButtonproductNote}>
                            <p className="m-2"><MdEdit />
                            {infoSource.laguangeElakay('title', infoSource.languageAPI).noteProduct}
                            </p>
                        </div>

                        
                
                </div>
                </div>
            
            </div>
        </div>
    )

    
    const filterButton =()=>(
        <>
                <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10" >
                <div style={{ textAlign: "center"}} >
                    <div className="row">
                   
                        <div className="col col-12 col-md-3" style={filterAdd} onClick={ButtonproductFilte}>
                            <p className="m-2"><MdAdd/>
                             {/* more Filter */}
                             {infoSource.language(infoSource, "title", "morFilter")}
                             </p>
                        </div> 
                        <div className="col col-12 col-md-3" style={filterMore} onClick={handleEeditFilter}>
                            <p className="m-2"><MdEdit />
                             {/* filter Product */}
                             {infoSource.language(infoSource, "title", "filProduct")}
                             </p>
                        </div> 
                        <div className="col col-12 col-md-3" style={filterPre} onClick={handleFilterPre}>
                            <p className="m-2"><MdAdd/>
                            {/* filter Preview */}
                            {infoSource.language(infoSource, "title", "filPreview")}
                            </p>
                        </div> 
                        <div className="col col-12 col-md-3" style={FilterImg}  onClick={AddMoreImage}>
                            <p className="m-2"><MdAdd/>
                            {/* Images */}
                            {infoSource.language(infoSource, "title", "images")}
                            </p>
                        </div> 
                       
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
    const e2 = filtersCategory.length > 0 ? false : true 
    const e1 = items.length > 0? true : false 

    const bntlin =(a) => ()=>{
        let b =  getcategories._id ? getcategories._id : 0
        if(a === b){ setBtnLinkCTP(false)}
    }
        // selcet categorie pour les vendre 
        let listeCatSelSea = ['by selection', 'by name search']

        const handleCatSelSea = (a)=>{
        loadCategory(category)
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
            if(a == 'by selection'){
                setcatSelectedSearch(true)
            }else if (a == 'by name search'){
                setcatSelectedSearch(false)
            }
        }
        
        const checkedSelSea = (a)=>{
            if(a){
            return 'by selection'
            }else{
            return 'by name search'
            }
        }

        const CheckingCat = ()=>{
            return <div className="form-group">
         {/* <span  className="btn p-2"  onClick={() => clickButton2()}><GrClose/></span> */}
        <h2>{catSelectedSearch && ButtonBackCategory()} elakay {infoSource.language(infoSource, "title", "Categories")}</h2>
            { listeCatSelSea.map((ob) =>{
        return <> <input   
                        className="form-check-input" 
                        onClick={()=>handleCatSelSea(ob)} 
                        checked={checkedSelSea(catSelectedSearch) == ob ? 'checked' : ''} 
                        type="radio" 
                        name="typeList" 
                        id={"typeList_" + ob}
                        />
                <label className="form-check-label" for={"typeList_" + ob}>{ob}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> 
            </>
        })}
        </div>
        
        }

        const selectCategory = () => {

            return<>
               {CheckingCat()}
              { catSelectedSearch ? <CatSelected
            //    clickButton2={clickButton2}
               ButtonBackCategory={ButtonBackCategory}
               categoyseleted={categoyseleted}
               seleteCategory={seleteCategory}
               laguangeElakay={laguangeElakay}
               infoSource={infoSource}
               categories={getcategories}
                /> : <CatSearch 
                search ={search}
                setSearch ={setSearch}
                getCategoriesByName={getCategoriesByName}
                categories={getcategories}
                loadCategory={loadCategory}
                />}
                </>
           }
           const calculateFontSize = (index, totalElements) => {
            const maxSize = 18;
            const minSize = 12;
            const sizeStep = (maxSize - minSize) / (totalElements - 1);
            return maxSize - (index * sizeStep);
          };

          const CategorieName = (a, l)=>{
            return <p>
            {Array.isArray(a) && a.map((word, index) => (
              <span 
                key={index} 
                style={{ fontSize: `${calculateFontSize(index, l)}px` }}
              >
                {word}{index < l - 1 ? ', ' : '.'}
              </span>
            ))} 
          </p>

          }
          
        const selectCateg = () => (
            categories && (
                <div className="row " >
                <div className="col-md-8 offset-md-2 blockEl">
                    <div className="m-3">
                    <table style={{borderCollapse: "collapse", width: "150px"}}>
                        <tbody>
                    {
                     selectCatalogId  && selectCatalogId.map((k, j)=>(
                            <tr key={j} >
                                {/* {bntlin(k.catID)} */}
                            <td style={{borderBottom:"1px solid #ddd"}}>
                                {/* <h5>{k.catID.name}</h5> */}
                                {CategorieName(k.catID.name, k.catID.name.length)}
  
                            </td>
                            <td style={{width: "12%", borderBottom:"1px solid #ddd"}}><h6><MdOutlineDeleteForever onClick={() =>dltLinkCTP(k.catID._id)}/></h6></td>
                            </tr>
                        ))
                    }
                    </tbody>
                    </table>
                    </div>
                {selectCategory()}
            {/* <form className="mb-3">
   
                <div className="form-group">
                
                    <div className="boxCategory1 text-center " id="heading">
                        <h2> {ButtonBackCategory()} {getcategories.name} </h2>
                        {categoyseleted && categoyseleted.map((l, j) => (<p className="d-inline" key={j}>{l}{" / "}</p>))}
                    </div>
            
                    <select value={0} onChange={seleteCategory()} className="form-control">
                        <option>{infoSource.laguangeElakay('label', infoSource.languageAPI).selectProdSell}</option>
                        <option value={category}>{infoSource.laguangeElakay('label', infoSource.languageAPI).allItems}</option>
                        {getcategories.items && getcategories.items.map((c, i) => (
                            
                            <option key={i} value={c.supCategoryID._id}>
    
                                {c.supCategoryID.name}
                                {infoSource.getCategoryNames(c).name[0]}
                            </option>
                        ))}
                    </select>
                </div>
            </form> */}
            {e1 && e2 ?
            <button className="btn btn-primary float-right m-3" disabled>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).linkProduct}
                </button>
            : 
            <button className="btn btn-primary float-right m-3" onClick={clickSubCat}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).linkProduct}
                </button> 
            }

            </div>
            </div>
            )
        )

    const formEdit =()=>(
        product && (
            <div className="row mt-4 mb-4 justify-content-center">
                <div className="col col-12 col-md-12 col-lg-9 col-xl-8 p-0" style={{border: "1px solid Gainsboro" }} >
                    <div className="col col-12 " style={{textAlign: "center", backgroundColor: "Gainsboro" }}>
                        <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).editProduct}</h3>
                 </div>
        <form className=" m-3" >
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).name}</label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} />
            </div>

            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).description}</label>
                 <textarea 
                onChange={handleChange('description')} 
                className="form-control" 
                value={description} 
                rows="4"
                />
            </div>
            <div className="form-group">
                            <label className="text-muted">Cost price</label>
                            {/* <input onChange={handleChange('costPrice')} type="number" min="0" className="form-control" value={costPrice} /> */}
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                 </div>
                                <input onChange={handleChange('costPrice')} type="number" min="0" className="form-control" value={costPrice} />
                </div>
            </div>
            <div className="form-group">

                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).price}</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                 </div>
                                <input onChange={handleChange('price')} type="number" min="0" className="form-control" value={price} />
                </div>
            </div>
            
            

            <div className="form-group">
                            <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).adressShop}</label>
                            <select onChange={selectAdress()} className="form-control" value={addressSelect && addressSelect._id} >
                                <option value='0'>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
                                {
                                address && address.map((Adre, i) => (
                                        <option key={i} value={Adre._id}>({Adre.firstName} {Adre.lastName}) {Adre.Address1}, {Adre.city} {Adre.State} {Adre.postalCode} {Adre.country}</option>
                                    ))
                                }
                            </select>
             </div>

            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).freeShipping}</label>
                <select onChange={handleChange('shipping')} className="form-control" value={shipping}>
                    <option>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
                    <option value={false}>{infoSource.laguangeElakay('label', infoSource.languageAPI).no} </option>
                    <option value={true}>{infoSource.laguangeElakay('label', infoSource.languageAPI).yes}</option>
                </select>
            </div>

                        {/* {parcelForm()} */}
          {  productTrue ?                
               <button className="btn btn-primary float-right m-3" onClick={clickSubmit}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}
                </button>
                :
                <span className="btn btn-primary float-right m-3 disabled">
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}
                </span>}
        </form>
            </div>
            </div>
        ))

        let filterSelected  =  FiltersHand ? FiltersHand.filter : filter
  
const FilTrue = Object.values(filterSelected).every(i => i!== "") && quantity !== "" 

    const allfilter = (filter) =>{
        let texFil = ""
        for (let fil in filter){
         texFil +=  fil + " : " + filter[fil] + " ; "
        }
        return texFil
    }

    const handleFilterSel = (a, b) =>{

        // loadCategory(b.catID)
        setFilterHand(b)
       if(b.priceFil) {
        setFilterPrice(true)
       }else{
        setFilterPrice(false)
       }
        b.parcel.width ?
        setParcel(b.parcel)
        : setParcel({
            length: "",
            width: "",
            height: "",
            weight: ""
        })

        for (let fil in filter){
            delete filter[fil]
            }

        let c = b.filter
            for (let d in c){
                let e = c[d]
                filter[d] = e     
                }

    }


const filtersS = (
         <div className="m-3">
                    <table style={{borderCollapse: "collapse", width: "100%"}}>
                        <tbody>
                    {
          filtersProduct.length > 0 && filtersProduct.map((k, j)=>(
                            <tr key={j} style={FiltersHand._id === k._id?{backgroundColor: "#D6EEEE"}:{backgroundColor: "#f5f5f5"}} >
                            <td style={{width: "7%", borderBottom:"1px solid #ddd"}}>
                            <h6>
                            {FiltersHand._id === k._id?<FiCheckCircle />:<FiCircle onClick={() => handleFilterSel(filtersProduct, k)}/>}
                            </h6> </td>
                            <td style={{borderBottom:"1px solid #ddd"}} onClick={() => handleFilterSel(filtersProduct, k)}>
                                {name} ({allfilter(k.filter)} {" quantity: " + k.quantity }, {"price: " + k.priceFil })
                            </td>
                            <td style={{width: "12%", borderBottom:"1px solid #ddd"}}>
                            <h6><MdOutlineDeleteForever onClick={()=> clickFilter2(k._id)}/></h6>
                            </td>
                            </tr>

                        ))
                    }</tbody>
                    </table>
                    </div>
        )

    const selFilterCat = ()=>(
        selectCatalogId && selectCatalogId.length > 0 && <div className="container">
<div className="m-4">
                    <select onChange={SelFilterCat()} className="form-control">
                        <option >{infoSource.laguangeElakay('label', infoSource.languageAPI).selectProdSell}</option>
                        {selectCatalogId && selectCatalogId.map((c, i) => (
                            
                            <option key={i} value={c.catID._id}>
    
                                {/* {c.catID.name.slice(0, -1).join(', ')} */}
                                {infoSource.getCategoryNames(c.catID, true).name.slice(0, -1).join(', ')}
                            </option>
                        ))}
                    </select>
    </div></div>

    )

    let Ga = productAddImage || pageParcel ?false : true 
 
    const formFilter = () => (
        Ga && (
            <div className="col col-12">
            <form className=" m-3" >
                        {filtersCategory && filtersCategory.map((k, i) => {
                            let translFil = infoSource.getFilTranslate(k)
                          return  <div key={i} className="form-group" >
                                <label className="text-muted">{translFil.name} {infoSource.laguangeElakay('label', infoSource.languageAPI).items} <span className='font-weight-light text-info'></span></label>

                                <select
                                    onChange={handleFilter(k._id.name)}
                                    className="form-control"
                                    value={FiltersHand.filter && FiltersHand.filter[k._id.name]}
                                >
                                    <option  value="" >
                                        {infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}
                                        </option>

                                        { translFil.FilterItem.map((c, j) => (
                                  
                                  <option key={j} value={c[0]}>
                                      {c[1]}
                                  </option>
                                    ))
                                }
                              <option value={"Not Specified"}>
                              {/* Not Specified */}
                              {infoSource.language(infoSource, "label", "notSpecified")}
                          </option>
                       
                                </select>

                            </div>
})}

                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).quantity}</label>
                        <input 
                        onChange={handleFilter('quantity')} 
                        type="number" 
                        min="0" 
                        className="form-control"  
                        value={quantity?quantity:""}
                        />
                    </div>
                    <div className="form-group">
                    <div className="ml-4">
                    <input
                        onChange={handleCheckPrice(btnFilterPrice)}
                        type="checkbox"
                        checked={btnFilterPrice ? "checked" : ''}
                        className="form-check-input"
                        id="priceCatNass"
                        />
                    <label className="text-muted" for="priceCatNass">
                        {/* Add a price for this category filter if nassary */}
                        {infoSource.language(infoSource, "label", "priceCatNass")}
                        </label>
                      </div>
                       
                    { btnFilterPrice  && <div className="input-group">
                        <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                    </div>
                    <input 
                    onChange={handleFilter('priceFil')} 
                    type="number" 
                    min="0" 
                    className="form-control" 
                    value={priceFil?priceFil:""} 
                    />
                    </div>}
                    </div>
                     </form>
                     </div>
                    ) )

    const AddFilterForm = ()=>(
        AddFilter && <>
     <div className="row  m-2 justify-content-center">
        <div className="col col-12 col-md-10 p-0" >
         {selFilterCat()}
         {formFilter()}
        {FilTrue?
        <button className="btn btn-primary float-right m-3" onClick={clickFilter}>
            {/* add Filter */}
            {infoSource.language(infoSource, "Button", "addFilter")}
            </button> 
            :
        <button className="btn btn-secondary float-right m-3">
            {/* add Filter */}
            {infoSource.language(infoSource, "Button", "addFilter")}
            </button>
        } 
        </div>
        </div>
        </>)

    const addFilterProductForm = ()=>(
        AddFilterPre &&  <>
                {/* {selFilterCat()} */}
                <AddFilterProduct
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                ImagesProd={ImagesProd}
                filters={filtersCategory}
                filterSel={filterSel}
                catID={productsID}
                token={token}
                filtersProduct={filtersProduct}
                loadProductsView={loadProductsView}
                items={itemFilValue}
                />
                </>
    )

    const EditFilterForm = ()=>(
        EditFilter && <>
         {filtersS}
         <div className="row  m-2 justify-content-center">
            <div className="col col-12 col-md-10 p-0" >

            <div className="float-left. m-2">
            {AddParcelBut}
            </div>
         {imagesFilters(FiltersHand.images)}
         {formFilter()}
        {FilTrue?
               <button className="btn btn-primary float-right m-3" onClick={clickFilter1}><FiEdit3/>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}
                </button>
               :
               <button className="btn btn-secondary float-right m-3"><FiEdit3/>
               {infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}
               </button>
        }
           </div>
        </div>
        </>)

 const NbrProdImgMax = filtersProduct && getFiels(getFiels(filtersProduct, "filter"), "color").length * 4 + 4
const NrbProdImg = ImagesProd.galeries ? ImagesProd.galeries.length : 0

  const addImagesForm = ()=>(
    productAddImage && <> 
   
     <div className="row  m-2 justify-content-center">
            <div className="col col-12 col-md-10 p-0" >
    
    {formImages(imagesArray)}
</div>
<div className="col col-12 col-md-10 p-0" >
    <div className="float-right m-2">
               {addMoreImgBut}
    </div>
    </div>
    <div className="col col-12 col-md-10 p-0" >
    {pageLAAI?
<>
    <ImagesLoading
        laguangeElakay={laguangeElakay}
        infoSource={infoSource}
        filterID ={FiltersHand._id ? FiltersHand._id: filtersProduct[0]._id}
        loadProductsView={loadProductsView}
        maxImgSize ={NbrProdImgMax}
        imgSize={NrbProdImg}
        namProduct={name}
        FiltersHand={FiltersHand}
        productIDEdit={productsID}
    />
    </>
    :
    <>
<LinkImagesTofilterPro
 laguangeElakay={laguangeElakay}
 infoSource={infoSource}
 setImagesSMS={ setImagesSMS}
imagesSms={imagesSms}
imagesArray={imagesArray}
setImagesArray ={setImagesArray}
filtersProduct ={filtersProduct}
filterSel={filterSel}
productID={productsID}
token={token}
/> 
</>
}
    </div>
</div>
    </>)

    const addParcelForm =()=>(
        pageParcel && <>
        {filtersS}
        <div className="row  m-2 justify-content-center">
            <div className="col col-12 col-md-10 p-0" >
            <div className="float-left. m-2">
            {addEditFilterBut}
            </div>
         <AddParcel
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
            parcel={parcel}
            parcelChange={parcelChange}
        />
        {FilTrue?
               <button className="btn btn-primary float-right m-3" onClick={clickParcel}>{parcel.length? <span><FiEdit3/> 
               {/* "edit" */}
               {infoSource.language(infoSource, "Button", "edit")}
               </span>  : <span> <FiEdit3/> 
                {/* add */}
                {infoSource.language(infoSource, "Button", "add")}
                </span>}</button>
               :
               <button className="btn btn-secondary float-right m-3">{parcel.length? <span><FiEdit3/> 
               {/* "edit" */}
               {infoSource.language(infoSource, "Button", "edit")}
               </span>  : <span> <FiEdit3/>
               {/* add */}
               {infoSource.language(infoSource, "Button", "add")}
               </span>}</button>
        }
        </div></div>
        </>
    )


    const imagesFilters = (a)=>(
    a &&  a.length > 0 ?   <div className="col">
        <h4>
            {/* images filter Selected */}
            {infoSource.language(infoSource, "title", "imgfilSelc")}
            </h4>
    <Row xs={3} sm={4} md={5} lg={6} xl={6}>
        {a && a.map((nm, i) => (
           <Col key={i}>
            <div className="m-2" style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                <img
                    src={`${elakay_API}/${nm}`}
                    alt={nm}
                    style={{ maxHeight: "50%", maxWidth: "50%" }}
                />
            </div>
        </Col>
        ))
        }
            
    </Row>
    </div>
:  a && <h4>
    {/* Not Image */}
    {infoSource.language(infoSource, "title", "notImage")}
    </h4>

    )

    const imagesSeletCSS =(a , b)=>{
       

        let x = {
            border: "1px solid lightgrey",
        }
        let y = {
            border: "3px solid #DAA520",
        }
        let e = a && a.includes(b)

       return e ? y : x
       
    }

    const formImages = (a) =>(
        
            <div className="row  mt-4 mb-4 justify-content-center p-2">
                <div className="col col-12 col-md-12  col-xl-10 p-0 contaire" style={{textAlign: "center", border: "1px solid Gainsboro" }} >
                    {/* <div className="col col-12" style={{  backgroundColor: "Gainsboro" }}>
                        <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).editImageProduct}</h3>
                    </div> */}
                
                    <div className="container">
                        <h4>
                            {/* All images item */}
                            {infoSource.language(infoSource, "title", "allimgItem")}
                            </h4>
                    <Row xs={3} md={5}>
                        {ImagesProd.galeries && ImagesProd.galeries.map((nm, i) => (
                           <Col key={i} >
                            
                            <div className="m-1" style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                                <div style={imagesSeletCSS(a, nm.urlImg)} onClick={()=>bntLinkImgtoFil(nm.urlImg)}>
                                <img
                                    src={nm.urlImg}
                                    alt={nm._id}
                                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                                />
                                <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                    <MdDeleteForever onClick={() => deleteImage(token, productsID, nm._id, a)} />

                                </div>
                            </div>
                            </div>
                        </Col>
                        ))
                        }
                            
                    </Row>
                    </div>

 
                    {/* {!productAddImage && <button className="btn btn-primary float-right m-3" onClick={AddMoreImage}> {infoSource.laguangeElakay('Button', infoSource.languageAPI).addMore}</button>} */}
    {imagesSms&& <p style={{color:"green", fontWeight: "bold"}}>{imagesSms}</p>}
                    </div>
            </div>
        )
    
    const formNote = () => (
        productNote && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-12 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 " style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).editNoteProduct}</h3>
                </div>    
                <AddProductNote
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                loadProductsView={loadProductsView}
                setPages={setPages}
                pages={pages}
                productIDEdit={productsID}
                />
             { note.length > 0 && (   
                 <div className=" m-3" >
                   
                        {
                        note && note.map((n, i)=>(
                            <div key={i}>   
                            {n.tilte && (
                            <>
                                        <p style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "3px" }}>{n.tilte} <span onClick={() => deleteImageNote(token, productsID, n._id)} className="float-right">< FiDelete /></span></p> 
                                    <p>{n.description}</p>
                             </>
                                )}
                                </div>
                            ))
                         }
                       

                            <div className="container">
                                <Row xs={2} sm={2} md={3} lg={4} xl={4}>
                                    {note && note.map((nm, i) => (
                                        !nm.tilte && (
                                    <Col key={i}>
                                <div className="m-2" style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%" }}>
                                <img
                                                    src={`${elakay_API}/products/readNoteGalerie/${nm._id}/${i}`} 
                                    alt={nameImg} 
                                    style={{ height: "100%", width: "100%" }} 
                                            />
                                        <div style={{ float: "right", position: "absolute", top: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", fontSize: "25px", color: "blue" }}>
                                        <MdDeleteForever onClick={() => deleteImageNote(token, productsID, nm._id)} />

                                        </div>
                                        </div>
                                        </Col>
                                    )
                                    ))
                                    }
                                </Row>
                            </div>   
                        </div>
                )}
                  
                    {/* <button className="btn btn-primary float-left m-3"  {infoSource.laguangeElakay('Button', infoSource.languageAPI).addMore}</button> */}
            </div>
        </div>
        ))
        

        const addEditFilterBut = <button className="btn btn-primary m-3" onClick={handleEeditFilter}> 
        {/* Edit filter */}
        {infoSource.language(infoSource, "Button", "edFilter")}
        </button>
        const addMoreImgBut =  pageLAAI ? <button className="btn btn-primary m-1" onClick={()=>{setPageLAAI(false)}}>
            {/* LInk image to filter  */}
            {infoSource.language(infoSource, "Button", "lnkImgFilt")}
            </button> 
            : 
            <button className="btn btn-primary m-1" onClick={()=>{setPageLAAI(true); setImagesArray([])}}> {infoSource.laguangeElakay('Button', infoSource.languageAPI).addMore}</button> 
        const AddParcelBut = <button className="btn btn-primary m-3" onClick={handlePageParcel}><MdAdd/>
        {/* add Parcel */}
        {infoSource.language(infoSource, "Button", "addParcel")}
        </button>                               

        const productFilters = ()=>(
            productFilte && (   
                <div className="row  mt-4 mb-4 justify-content-center" >
                <div className="col col-12 col-md-12 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 " style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                        <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).editFilterProduct}</h3>
                    </div>
                    {filterButton()}
                    {addFilterProductForm()}  
                    {AddFilterForm()}
                    {EditFilterForm()}
                    {addImagesForm()}
                    {addParcelForm()}
               
                  {/* {FiltersHand.parcel && <button className="btn btn-primary float-right m-3" onClick={handlePageParcel}><MdAdd/> add Parcel</button>} */}
            
                </div>
            </div>
            )
        )

        const prodViewEdit =()=>{
          return  <div className="row  mt-4 mb-4 justify-content-center" style={{backgroundColor: "WhiteSmoke"}}>
             <div className="col col-12 " style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                        <h3>
                            {/* Product View */}
                            {infoSource.language(infoSource, "title", "prodView")}
                        </h3>
                    </div>
                <ProdViewEdit
                 infoSource={infoSource}
                 laguangeElakay={laguangeElakay}
                 getProduct={getProduct}
                 getImages={getImages}
                />

            </div>

        }
        const getAddressShop = (allAddress, addressShop)=>{
            const exists = allAddress.some(address => address.id === addressShop);
            if (exists) {
             return allAddress.filter(AS =>  AS._id === addressShop)[0].shopName
            } else {
              return allAddress[0]
        
            }
          }

    return (
        <Layout
            title="Home Page"
            className="container-fluid"
            SliderPunShow={false}
            infoSource ={infoSource}
        >
            <div style={infoSource.styleNav}>
            <div className="p-4" >
           
            {editProductMenu()} 
            {selectCateg()}
            {formEdit()}
            {productFilters()}
            {formNote()}

            </div>

            {prodViewEdit()}           
             <LakayUser
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                addressShop={addressSelect}
                userID={userInfo._id}
                readUser={infoSource.readUser}
                showB={false}
                edit = {true}
            />
            </div>
            </Layout>
    );

};

export default EditProduct;
