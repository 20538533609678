import React, {useState} from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TableElakay, {tableBody, tableHead, tableRow} from "./commentaire/formTable";
import { json } from "react-router-dom";

const filterUse =  new Object();
const filArrName = []

const getFiels = (a ,b)=>{
    var c = []
    if(a.length > 0){
    for (var i = 0; i <  a.length; i++)
    if(!c.includes(a[i][b])){
     c.push(a[i][b])
    }}
        return c
    }

    const checkFilOK =(a, b)=>{
        return a && a.includes(b)    
      }


    const ShowFilterUP = (x, y)=>{
        let i  =  x.a && x.a.includes(x.b)
        let j  =  x.c && x.c.includes(x.b)
        let k  =  y.a && y.a.includes(y.b)     
       return k ? j : i
    }

    const objToArr = (a)=>{
        return a && {v: Object.values(a), k: Object.keys(a)}
    }

    const lineCheck = (a, b)=>{
        for (let i = 0; i < a.length; i++){
            if(a.length == 1){
                return true
            }else{
            if(i == 0 && a[i] === b){
                return false
            }else {
                return true
            }
        }

        }

    }
      const checkFil = (b, c)=>{
        let textfil  = "" 
        for (let fil in c){
            textfil  +=  b[fil] === c[fil] && ''
     } 
     return textfil
     }

const checkObjExit = (x, y, z =[], a)=>{
    let AP = getFiels(a , "filter")
    let APF 
    let DC =  new Object()
    let nanArr = getFiels(y, "_id")
    let AB = getFiels( nanArr,  "name")
   
for (const [key, value] of Object.entries(x)) {
    APF = getFiels( AP,  key)
    AB.forEach(element => {
        if(element == key){
        let AZ = z &&  z.includes(element)      
            if (!AZ){   
                if(DC[key] !== undefined) {
                 DC[key] = value
                }  else{
                    DC[key] = APF[0] 
                }  
                
            }    
        }
    });
   
  }
return DC
}

const seletfilItem = (x, y)=>{
    let nanArr = getFiels(y.c, "_id")
    let AB = getFiels( nanArr,  "name")
    let OFR
   
    x.a && x.a.filter(Item => {
        if(x.b(Item.filter, x.c) === ''){
            x.d.push(Item)
            OFR  = checkObjExit(y.d , y.c , y.e, x.d) 
            if(x.b(Item.filter, OFR) === ''){  
                y.b(Item)
                y.f(Item.filter["color"])
                y.g(Item)    
               
            }
            y.a(x.d)
         } 
         
    })

}

const checkCBFil = (a , b, c)=>{
  return a[b] == c ? "4px double DarkRed" : "2px solid lightgrey"
}

var itemImages = (a, b)=>{
    if(a.length > 0){
        b(a)
        return a 
        }
}

const PrevviewProFilt = (props) => {
    // const [filArrName, setfilArrName] = useState([]);
    const {
        infoSource,
        setQuantity,
        galeries= [],
        setImages,
        setTextFil,
        oneFilterPro =[],
        restAllFilterPro = [],
        setOneFilterPro,
        setALLFilterPro,
        allFilterPro =[],
        filter = [],
        setNewAP,
        imageTextDiv,
        del = false
    }= props

    const loadImages =(a)=>{
        galeries.filter(item =>{
            if(item.name == a){
                setImages(item.images) 
            }
            
        })
            
    }
    
const filSelect = allFilterPro && getFiels(allFilterPro, "filter")
const filSelectResALL = restAllFilterPro && getFiels(restAllFilterPro, "filter")

const FilterProSelect = (a, b, c, d, e)=>{

    let itemArr = [];
    let z = a._id.name
let t = checkFilOK(b, z)

    t && b.splice(0, b.length)
   if(t){ for (let fil in filterUse){
                delete filterUse[fil]
                }}
    
    filterUse[z] = e
    b.push(a._id.name)
    
    // checkObjExit(oneFilterPro.filter, filter, b)
    t?
    seletfilItem({a: restAllFilterPro, b: checkFil, c : filterUse, d: itemArr}, {a: setALLFilterPro, b: setOneFilterPro, c: filter, d: oneFilterPro.filter, e: filArrName, f: loadImages, g: setNewAP })
   : 
    seletfilItem({a:d, b: checkFil, c : filterUse, d: itemArr}, {a: setALLFilterPro, b: setOneFilterPro, c: filter, d: oneFilterPro.filter , e: filArrName, f: loadImages, g: setNewAP})

  }

const StyleFil = (a, d, n)=>{
    let tA =  lineCheck(filArrName, n)
    let tb = ShowFilterUP({a:getFiels(filSelect, n),  b: d, c :getFiels(filSelectResALL, n)}, {a: filArrName, b: n})
    let b
    if(a.formValueFil == "Square"){
        b = {
            display: "inline-block", 
            fontSize: "1.2rem",
            backgroundColor : d, 
            minWidth: '40px',
            minHeight: '40px',
            opacity: tb?  "1": "0.15",
            // width: "30px", 
            // height: "30px", 
            margin: "5px",
            padding: "5px",
            textAlign: "center",  
            border: tb? checkCBFil(oneFilterPro.filter, n, d):  "3px dotted red",  
            borderRadius: "15%"
        }
    } else if (a.formValueFil == "Circle"){
        b = {
            display: "inline-block", 
            fontSize: "1.2rem",
            backgroundColor : d, 
            minWidth: '40px',
            minHeight: '40px',
            opacity:tb?  "1": "0.15",
            // width: "30px", 
            // height: "30px", 
            margin: "5px",
            padding: "5px",
            textAlign: "center",  
            border: tb? checkCBFil(oneFilterPro.filter, n, d):  "3px dotted red",  
            borderRadius: "50%"
        }

    }
    return b

}
const StyleBorImg = (a, d, n)=>{
    let tb = ShowFilterUP({a:getFiels(filSelect, n),  b: d, c :getFiels(filSelectResALL, n)}, {a: filArrName, b: n})
    let b 
    let c = d == "color"? d : "lightgrey"
    !a ? b = {
          position: "relative", 
          overflow: "hidden", 
          transition: "0.3s", 
          opacity: tb?  "1": "0.15",
          width: "60px", 
          border: tb? checkCBFil(oneFilterPro.filter, n, d):  "3px dotted red",  
          padding: "2px"
      }:
      b = {
          position: "relative", 
          overflow: "hidden", 
          transition: "0.3s", 
          opacity: tb?  "1": "0.15",
          border: tb? checkCBFil(oneFilterPro.filter, n, d):  "3px dotted red",  
          width: "60px", 
          padding: "2px"
      }

 
  return b

}
const StyleSelect = (a, d)=>{
    let t = checkFilOK(getFiels(filSelect, a._id.name), d) 
    let tA =  lineCheck(filArrName, a._id.name)
    let b 
      b = {
        color: "red",
        textDecorationLine :"line-through",
          opacity: t? "1" : "0.25",
          border: !t && "3px dotted red", 
          width: "60px", 
          padding: "2px"
      }

 
  return b

}

const seletImageTextDiv = (t, i)=>{
    setImages(i)
    setTextFil(t)
    imageTextDiv.splice(0 ,1, t)
    imageTextDiv.splice(1,1, i)
}

    const divView = (a)=>(
        <>
        {a.FilterItem.length > 0 ? a.FilterItem.map((k, i) =>(
                  
                  <div  key={i} style={StyleFil(a, k, a._id.name)}  onClick={() => FilterProSelect(a, filArrName, filter,  allFilterPro, k)}>
                     {a.textRead ? k: ""}
                  </div>
              
                   
             )) : a._id.FilterItem.map((k, i )=>(
            
                       <div  key={i} style={StyleFil(a, k, a._id.name)} onClick={() => FilterProSelect(a, filArrName, filter,  allFilterPro, k)}>
                          {a.textRead ? k: ""}
                       </div>
                     
                  ))}
        </>
        )
const checkWithImg = (a , b)=>{
    if(a == true && b.length > 0){
        return true
    } else {

        return false
    }
}
const selectImg =(a)=>{
  let  b = {
    "background-image": `url(${a[1]})` 
    }

    return  <option style={b} >{a[0]}</option>
  
//    <div style={b}> <img
//                 src={a[1]}
//                 alt={a[0]}
//                 style={{ maxHeight: "100%", maxWidth: "100%" }}
//             /> {a[0]}
//         </div>
}
        const SelectView = (a)=>(
            <>
            {checkWithImg(a.textRead, a.Images)?
            
            <select  className="form-control">
            <option >
                {/* Select what you need */}
                {infoSource.language(infoSource, 'label', 'selWaNeed')}
                </option> 
           { a.Images.map(k =>( 
            selectImg(k)
             ))}
         </select>
            :
            a.FilterItem.length > 0 ? 
                <select  className="form-control">
                     <option >
                        {/* Select what you need */}
                        {infoSource.language(infoSource, 'label', 'selWaNeed')}
                        </option> 
                    { a.FilterItem.map(k =>{
                        let t = checkFilOK(getFiels(filSelect, a._id.name), k) 
                        let tA =  lineCheck(filArrName, a._id.name)
                   return t && <option value={k}><span >{k}</span></option>
                    })}
                  </select>
                    : 
                    <select  className="form-control">
                     <option >
                        {/* Select what you need */}
                        {infoSource.language(infoSource, 'label', 'selWaNeed')}
                        </option> 
                    { a._id.FilterItem.map(k =>{
                    let t = checkFilOK(getFiels(filSelect, a._id.name), k) 
                    let tA =  lineCheck(filArrName, a._id.name)
                   return t && <option value={k}><span >{k}</span></option>
                    })}
                  </select>
                  }
            </>
            )

            const SelectImageFil = (a, b)=>(
                <>
            <div className="row">
        <div className="p-0 contaire" style={{textAlign: "center"}} >
            <div className="container">
            <Row xs={3} md={5}>
                {b && b.map((nm, i) => (
                   <Col key={i} >
                    
                    <div className="m-2" 
                    style={StyleBorImg(a.formInput,  nm[0], a._id.name)}
                    onClick={() => {FilterProSelect(a, filArrName, filter,  allFilterPro, nm[0]); del && seletImageTextDiv(nm[0], nm[1])}}
                    >
                    <div>
                        <img
                            src={nm[1]}
                            alt={nm[0]}
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                        />
                        {a.textRead && <div style={{ float: "right", position: "absolute", bottom: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.4)", width: "100%", fontSize: "10px", textAlign: "center"}}>
                            {nm[0]}
                        </div>}
                    </div>
                    </div>
                </Col>
                ))
                }
                    
            </Row>
            </div>
            </div>
            
    </div>
                </>
                )
            const allFormFilter = (a)=> (
                <>
                {a.formValueFil == "Images"?
                SelectImageFil( a, a.Images)
                :a.formInput?
                divView(a)
                :
                SelectView(a)
                } 
                </>
            )

let nanArr = getFiels(filter, "_id")
    return (
        <>
        {
          filter &&  filter.map((c, i) => (
                <div key={i}> 
                <h5 className="p-0,  m-0">{c._id.name}</h5>
                {allFormFilter(c)}
                </div> 
            ))
        }
        </>
    )
};

export default PrevviewProFilt;
