import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Layout from "../Layout/Layout";
import { list, getProductsF, getProductSearch } from "../auth/apiProduct";
import Card from '../pageInside/Card'
import { Row } from 'react-bootstrap';
import { prices } from "../pageInside/fixedPrices";
import ButtonPagination from "../pageInside/ButtonPagination";
import { laguangeElakay } from "../pageInside/dataElakay";
import PaginationInfo from "../pageInside/paginationInfo";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResutlsSearch = props => { 
    const {
        infoSource
            } = props

    let query = useQuery();
    let cid =  query.get("ci") 
    let  search= query.get("ref")
    const [results, setResults] = useState([]);
    const[filterShop, setFilterShop] = useState(false)
    // const [search] = useState(query.get("ref"));
    const [searched] = useState(false);
    const [limit] = useState(50);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [sizeProduct, setSizeProduct] = useState(0);
    const [getCalFilShop, setCalFilShop] = useState({});
    const [myFilters, setMyFilters] = useState({
        filters: { name: search || undefined, "categoriesID.gategoriesLink": [] }
    });
    const checkObjEmpty = (a)=>{
        return JSON.stringify(a) === '{}'
    
    }
    // const searchData = (cid, search, myFilters) => {
    //     if (search) {
    //         getProductSearch(skip, limit, "sold", cid, search, myFilters.filters).then(
    //             response => {
    //                 if (response.error) {
    //                     console.log(response.error);
    //                 } else {
    //                     setResults(response.products);
    //                     setSizeProduct(response.siseProduct);
    //                     setSize(response.size);
    //                     setSkip(0);
    //                 }
    //             }
    //         );
    //     }
    // };

    const searchData = (cid, search, myFilters) => {

            getProductSearch(skip, limit, "sold", cid, search, myFilters.filters).then(
                response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        setResults(response.products);
                        setSizeProduct(response.siseProduct);
                        checkObjEmpty(getCalFilShop) && setCalFilShop({owner: response.owner, categoies: response.categories, filters: response.filters, prices})
                        setSize(response.size);
                        setSkip(0);
                    }
                }
            );
    };

    useEffect(() => {
        searchData(cid, search, myFilters);
    }, [cid, search, myFilters]);

  const handlePrice = value => {
 
         let array = [];
         for (let key in prices) {
             if (prices[key]._id === parseInt(value)) {
                 array = prices[key].array;
             }}
         return array;
     };
 
     const handleFilters = (filters, filterBy) => {
         const newFilters = { ...myFilters };
         newFilters.filters[filterBy] = filters;
 
         if (filterBy === "price") {
             let priceValues = handlePrice(filters);
             newFilters.filters[filterBy] = priceValues;
         }
        
         setMyFilters(newFilters);
     };


    const loadMore =()=> {
        let toSkip = skip + limit;
        if (search) {
            getProductSearch(toSkip, limit, "sold", cid, search, myFilters.filters).then(
                response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        setResults(response.products);
                        setSizeProduct(response.siseProduct);
                        setSize(response.size);
                        setSkip(toSkip);    
                    }
                }
            );
        }
    };

    const loadMore0 = () => {
        let toSkip = skip - limit;
            getProductSearch(toSkip, limit, "sold", cid, search, myFilters.filters).then(
                response => {
                    if (response.error) {
                        console.log(response.error);
                    } else {
                        setResults(response.products);
                        setSizeProduct(response.siseProduct);
                        setSize(response.size);
                        setSkip(toSkip);     
                    }
                }
            );
    
    }; 

    const searchMessage = (searched, results) => {
        if (searched && results.length > 0) {
            return `Found ${results.length} products ${sizeProduct} ${size}`;
        }
        if (searched && results.length < 1) {
            return `No products found`;
        }
    };

    const productALL = (results = []) => { 
        return (
            <div>
            <h2 className="mt-4 mb-4">
                {searchMessage(searched, results)}
            </h2>
        <Row className="m-1" xs={2} sm={2} md={3} lg={4} xl={5}>
            
            {results.map((product, i) => (
                 <div className="col p-2" key={i}>
                <Card 
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                product={product}  
                owner={product.owner}
                classCard0 = "col p-0"
                />
                </div>
            ))}
        </Row>
        </div> 
    )
    }

    return (
        <Layout
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            infoSource ={infoSource}
            prices={prices}
            userShop= {true}
            filterShop={filterShop}
            setFilterShop={setFilterShop}
            handleFilters={handleFilters}
            setMyFilters={setMyFilters}
            getCalFilShop={getCalFilShop}
        >
            <div style={infoSource.styleNav}>
                <PaginationInfo
        skip={skip}
        limit={limit}
        siseProduct={sizeProduct}
        infoSource={infoSource}
        prodCat={'search'}
        />
            {productALL(results)} 
            < ButtonPagination
             infoSource ={infoSource}
                size={size}
                skip={skip}
                limit={limit}
                siseProduct={sizeProduct}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />
            </div>
        </Layout>
    );
};

export default ResutlsSearch;