export const VoteStart = (
    voteA = [],
    Start
) => {

    let a = {}
    let b = []
    let al = voteA.length > 0 ? true : false
    let c
    let d =  al &&  voteA.length 

    if (Start === 5){
       al  &&  voteA.forEach(p => {
            if (p.voteNum === 5) {
                a.voteNum = p.voteNum
                b.push(a)
            }
        })
    }

    if (Start === 4) {
       al  &&  voteA.forEach(p => {
            if (p.voteNum === 4) {
                a.voteNum = p.voteNum
                b.push(a)
            }
        })    
    } 

        if (Start === 3) {
           al  &&  voteA.forEach(p => {
                if (p.voteNum === 3) {
                    a.voteNum = p.voteNum
                    b.push(a)
                }
            })
        }

    if (Start === 2) {
       al  &&  voteA.forEach(p => {
            if (p.voteNum === 2) {
                a.voteNum = p.voteNum
                b.push(a)
            }
        })
    }

    if (Start === 1) {
       al  &&  voteA.forEach(p => {
            if (p.voteNum === 1) {
                a.voteNum = p.voteNum
                b.push(a)
            }
        })
    }


    c = b.length

    if (c) {
        c = (c * 100)/ d 
         }
    return (
        c
    )
}