import React, { useState, useEffect } from "react";
import imgProductLakay from '../Layout/imgs/md3.jpg' 

import {taxProductArea} from "../auth/apiProduct";
import {shippingData, usaState} from "../pageInside/shippingInfo"
import CatSearch from '../pages/categories/catSearch'
import UsaCity from "../../usa.json";
import "./salesSimulation.css"

import {
    ItemsOrder,
    prodDiscount,
    prodPrice,
    prodFrService, 
    prodFrVente,
    prodFrRef,
    TaxOrder,
    getTotal,
    prodFrTransaction,
    totalFrais,
    inComeSeller,
    elakayFrais,
    calProfit, 
    CalSellingPrice
} from '../auth/allPriceFeet'

const SalesSimulation = (props)=>{
    const {
        search,
        setSearch,
        getCategoriesByName,
        categories,
        loadCategory,
        laguangeElakay,
        infoSource, 
        clotureFeet = 0.50
    }= props 

    const product = {
        price : 0,
        constPrice :0,
        salePrice: 0,
        count: 1
    }

    const gestionVente = {
        purchasePrice: 0,
        ProfitPrice : 0
    }
    const simulationPrice = {
        ItemsOrder: 0,
        prodDiscount : 0,
        prodPrice: 0,
        prodFrService: 0, 
        prodFrVente: 0,
        prodFrRef: 0,
        TaxOrder: 0,
        getTotal: 0,
        prodFrTransaction: 0,
        totalFrais: 0,
        inComeSeller: 0,
        elakayFrais: 0,
        statut: {profit: 0, loss : 0, statut: 'break-even point'}
    }
    const [shippingboo, setShippingboo ]= useState(false)
    const [getLinkShoose, setLinkShoose]= useState({contries:'', compagnies: '', services: '' })   
    const [getshipping, setShipping ]= useState([])
    const [deliveryTime, setDeliveryTime] = useState([])
    const [getShipPriceSelect, setShipPriceSelect] = useState({price: 0,  Select: ''})
    const [cellSeleted, setCelleseleted] = useState([])
    const [getProduct, setProduct]= useState(product)
    const [getTaxArea, setTaxArea]= useState({})
    const [getPurchaseProduct, setPurchaseProduct] = useState(gestionVente)
    const [getBySellProfit, setBySellProfit]= useState(true)
    
    const [getSimulationPrice, setSimulationPrice]= useState(simulationPrice)

    const newCartProd = async (data)=>{
     
            let info =  UsaCity.find(state => state.zip_code == data)
            let newData
            if (info){
                newData = await taxProductArea(info.state, info.zip_code)  
                setProduct({ 
                    price : CalSellingPrice(Math.abs(getPurchaseProduct.purchasePrice), Math.abs(getPurchaseProduct.ProfitPrice), getShipPriceSelect.price, newData.zoneTax), 
                    constPrice: CalSellingPrice(Math.abs(getPurchaseProduct.purchasePrice), Math.abs(getPurchaseProduct.ProfitPrice), getShipPriceSelect.price, newData.zoneTax),  
                    salePrice: 0,  
                    count: 1})
                setTaxArea(newData.zoneTax)
            }}


            const loadSimulationPrice =(ord, ship, pp, pr)=>{
             let newInf = {   
                            ItemsOrder : ItemsOrder([ord]) ,  
                            prodDiscount : prodDiscount([ord]),
                            prodPrice : prodPrice([ord]),
                            prodFrService : prodFrService([ord], 0), 
                            prodFrVente : prodFrVente([ord], 0),
                            prodFrRef: prodFrRef([ord], 0),
                            TaxOrder : TaxOrder([ord], 0),
                            getTotal : getTotal([ord], 0),
                            prodFrTransaction : prodFrTransaction([ord], 0),
                            totalFrais : totalFrais([ord], 0),
                            inComeSeller: inComeSeller([ord], 0),
                            elakayFrais : elakayFrais([ord], 0),
                            statut: calProfit([ord], 0, pp)

                        }
                      
                        setSimulationPrice(newInf)
            }


    useEffect(() => {


        loadSimulationPrice({products: getProduct, cat_Product: categories, taxprodZone:  getTaxArea }, getShipPriceSelect.price, getPurchaseProduct.purchasePrice, getPurchaseProduct.ProfitPrice)

    }, [getProduct, categories, getTaxArea, getShipPriceSelect, getPurchaseProduct]);


    const convertToNewArray = (obj) => {
        const result = [];
        for (const serviceName in obj.services) {
          const service = obj.services[serviceName];
          result.push({
            name: serviceName,
            ecommerce: {
              standard: service.ecommerce.standard,
              professional: service.ecommerce.professional_account
            },
            wholesale: {
              standard: service.wholesale.standard,
              professional: service.wholesale.professional_account
            },
            retail: {
              standard: service.retail.standard,
              professional: service.retail.professional_account
            },
            deliveryTime: service.delivery_time
          });
        }
        return { services: result };
      }

    const {
        price,
        salePrice,
        count
        } = getProduct

        let contryShip = ["USA",  "Canada"]
        let shippinselected = ['Minimum', 'Moyenne', 'Maximum']

        const keyValue = (get, set )=>{ 
            // let k = Object.keys(get)
            // let v = Object.values(get)
            if(set === 'keys'){
                return  Object.keys(get)
            }else if(set === 'values'){
                return Object.values(get)
            }else{
            return get
            }
        }
        const convertToArray = (priceRange)=>{
          // Enlever les symboles $ et les espaces, et separer par le tiret
  const parts = priceRange.replace(/\$/g, '').split('-').map(part => part.trim());

  // Convertir les parties en nombres flottants
  const prices = parts.map(part => parseFloat(part));

  return prices;
        }
        
        const handleChange=(name)=>event=>{
            let value = event.target.value
            if(name === 'price'){
                setLinkShoose({contries:'', compagnies: '', services: '' }) 
                setShipPriceSelect({price: 0,  Select: ''})
                setShipping([])
                setDeliveryTime('')
                setCelleseleted([])
                setShippingboo(false)
                setProduct({ ...getProduct, [name]: value, constPrice: value});   
            }else{
            setProduct({ ...getProduct, [name]: value});
            }
          
        }

        const handlePP=(name)=>event=>{
            setShippingboo(false)
            setShipping([])
            setDeliveryTime('')
            setCelleseleted([])
            setLinkShoose({contries:'', compagnies: '', services: '' }) 
            setShipPriceSelect({price: 0,  Select: ''})
        const value = event.target.value;
        if(name ==='purchasePrice'){
            setBySellProfit(true)
            setProduct({ price : 0, salePrice: 0, constPrice: 0, count: 1})
            setPurchaseProduct({  purchasePrice: value, ProfitPrice : 0})
        }
        if(name ==='ProfitPrice'){


            setProduct({ 
                price : CalSellingPrice(Math.abs(getPurchaseProduct.purchasePrice), Math.abs(value), getShipPriceSelect.price, getTaxArea), 
                constPrice: CalSellingPrice(Math.abs(getPurchaseProduct.purchasePrice), Math.abs(value), getShipPriceSelect.price, getTaxArea),  
                salePrice: 0,  
                count: 1})
            setPurchaseProduct({ ...getPurchaseProduct, [name]: value});
        }
        }

        const handleChangeAdress=(name)=> event=>{
            const value = event.target.value;
            newCartProd(value)
          
        }
let contries = shippingData[1]
// let compagnie = shippingData[1][contrie[0]]
let contrie = keyValue(shippingData[1], 'keys')
let usps = keyValue(shippingData[1][contrie[0]], 'keys')
let uspsService = keyValue(shippingData[1][contrie[0]][usps[0]]['services'], 'keys')
let services0 = keyValue(shippingData[1][contrie[0]][usps[0]]['services'][uspsService[3]], 'key')

const formPurchase = ()=>{
   return <form className="w-75">
             <div className="form-group">
                <label className="text-muted">
                    {/* Add your purchase price */}
                    {infoSource.language(infoSource, "sellPage", "AddPurchPrix")}
                    </label>
                <input onChange={handlePP('purchasePrice')} type="number" min="1" className="form-control rounded-5" value={getPurchaseProduct.purchasePrice} />
                    <span className="font-weight-light text-info mx-4">
                    {/* The purchase price is the total amount paid to acquire a product for resale, including the cost of the product, shipping, taxes, and any other associated expenses. In Elakay, this includes all costs incurred to get the product ready for sale. */}
                    {infoSource.language(infoSource, "text", "textSellSimul1")}
                    </span>
            </div>
    </form>
}

const addfieldForm =()=>{
   return <form className="my-3">
         {getBySellProfit?
          <div className="form-group">
                <label className="text-muted">Target selling price'</label>
                <input onChange={handleChange('price')} type="number" min="0" className="form-control" value={price} />
                <span className="font-weight-light text-danger mx-4">
                {/* Selling price you want to achieve */}
                {infoSource.language(infoSource, "sellPage", "SellWaAch")}
                </span>
            </div>
            :   <div className="form-group">
            <label className="text-muted">Target Profit price'</label>
            <input onChange={handlePP('ProfitPrice')} type="number" min="0" className="form-control" value={getPurchaseProduct.ProfitPrice} />
            <span className="font-weight-light text-danger mx-4">
            {/* Profit price you want to Desired  */}
            {infoSource.language(infoSource, "sellPage", "ProPriWaDes")}
            </span>
        </div>
            }
            <div className="form-group">
                <label className="text-muted">
                    {/* discount */}
                    {infoSource.language(infoSource, "sellPage", "discount")}
                    </label>
                <input onChange={handleChange('salePrice')} type="number" min="3" className="form-control" value={salePrice} />
            </div>
          
    </form>
}


const salesFromAdress = () =>{
  return <> <form className="my-3">
                                            
       <div className="form-group">
            <label className="text-muted">
                {/* your shop */}
                {infoSource.language(infoSource, "sellPage", "yourShop")}
                 {infoSource.laguangeElakay('label', infoSource.languageAPI).zipCode}</label>
                  <div className="controls">
                     <input id="postal-code" onChange={handleChangeAdress('postalCode')} type="text" placeholder={infoSource.laguangeElakay('label', infoSource.languageAPI).zipCode}
                                                        className="form-control"/>
                            <p className="help-block"></p>
                    </div>
        </div>
    </form>
    </>
}



let imgStyle ={
    float: 'left',
    // border: '1px dotted black',
    margin: '0px 0px 0px 0px',
    padding: '2px 10px 0px 0px'
}

const getShipping =(a, b, c, d, e)=>{
    let newArraySHip = convertToArray(a)
    setCelleseleted([c, d, e])
    setDeliveryTime([b, a])
    handleMinMoyMax(getShipPriceSelect.Select, newArraySHip)
    setShipping(newArraySHip)
}


const headTitle = ['E-commerce (Standard)', 'E-commerce (Pro Account)',  'Vente en Gros (Standard)', ' Vente en Gros (Pro Account)', 'Vente au Detail (Standard)', 'Vente au Detail (Pro Account)']
const cellStyleSelect = (a, b)=>{
    return cellSeleted[1] == a && cellSeleted[2] == b ? {backgroundColor: '#C0C0C0'}: {}
}
let objKey = shippingboo && getLinkShoose.contries && getLinkShoose.compagnies && Object.keys(shippingData[1][getLinkShoose.contries][getLinkShoose.compagnies]['services'])
let objvalue = shippingboo && getLinkShoose.contries && getLinkShoose.compagnies && Object.values(shippingData[1][getLinkShoose.contries][getLinkShoose.compagnies])
let newArray = objKey = shippingboo && getLinkShoose.contries && getLinkShoose.compagnies && convertToNewArray({services: objvalue && objvalue[0]})
const ShippingTable = () => {
    const data = newArray.services;

    return (
        <table className="saleSim">
          <thead>
            <tr>
              <th >
                {/* Service */}
                {infoSource.language(infoSource, "sellPage", "service")}
                </th>
              <th > 
                {/* E-commerce (Standard) */}
                {infoSource.language(infoSource, "sellPage", "EcomStandard")}
                </th>
              <th >
                {/* E-commerce (Pro Account) */}
                {infoSource.language(infoSource, "sellPage", "ecomProAccount")}
                </th>
              <th >
                {/* Vente en Gros (Standard) */}
                {infoSource.language(infoSource, "sellPage", "VentGroStand")}
                </th>
              <th >
                {/* Vente en Gros (Pro Account) */}
                {infoSource.language(infoSource, "sellPage", "VentGroPrAcco")}
                </th>
              <th >
                {/* Vente au Detail (Standard) */}
                {infoSource.language(infoSource, "sellPage", "VenDetaiStand")}
                </th>
              <th >
                {/* ente au Detail (Pro Account) */}
                {infoSource.language(infoSource, "sellPage", "entDetProAcc")}
                </th>
              <th >
                {/* Delai de Livraison */}
                {infoSource.language(infoSource, "sellPage", "delaiLivrai")}
                </th>
            </tr>
          </thead>
          <tbody>
            {data.map((service, index) => (
              <tr key={index}>
                <td >{service.name}</td>
                <td style={cellStyleSelect(index, 0)} onClick={()=>getShipping(service.ecommerce.standard, service.deliveryTime, service.name, index, 0)}><span>{service.ecommerce.standard}</span></td>
                <td style={cellStyleSelect(index, 1)}  onClick={()=>getShipping(service.ecommerce.professional, service.deliveryTime, service.name, index, 1)}>{service.ecommerce.professional}</td>
                <td style={cellStyleSelect(index, 2)} onClick={()=>getShipping(service.wholesale.standard, service.deliveryTime, service.name, index, 2)}>{service.wholesale.standard}</td>
                <td style={cellStyleSelect(index, 3)} onClick={()=>getShipping(service.wholesale.professional, service.deliveryTime, service.name, index, 3)}>{service.wholesale.professional}</td>
                <td style={cellStyleSelect(index, 4)} onClick={()=>getShipping(service.retail.standard, service.deliveryTime, service.name, index,  4)}>{service.retail.standard}</td>
                <td style={cellStyleSelect(index, 5)} onClick={()=>getShipping(service.retail.professional, service.deliveryTime, service.name, index, 5)}>{service.retail.professional}</td>
                <td >{service.deliveryTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
  };
const addShippingBoo = () =>{
    setLinkShoose({contries:'', compagnies: '', services: '' }) 
    setShipPriceSelect({price: 0,  Select: ''})
    setProduct({ ...getProduct, price: getProduct.constPrice})
    setShipping([])
    setDeliveryTime('')
    setCelleseleted([])
    if(shippingboo){
 setShippingboo(false)
    }else{
        setShippingboo(true)
    }
}
let allPrice = ['Purchase price', 'Applicable taxes', 'Shipping costs', 'Discounts', 'Payment fees', 'Service fees', 'Selling price you want to achieve', 'Desired profit', 'Potential loss']
let priceAdd = ['Target selling price', 'Desired profit',]
const infoDiscount = ()=>{
    return <div >
        <p> <img style={imgStyle} src={imgProductLakay} width="100"/>
        {/* We are launching a simulation to help you determine the optimal price at which you can sell your products by considering various essential factors. This simulation will not only enable you to understand how to set your selling prices competitively but also to achieve the desired profit while remaining attractive compared to competitors in the market. By participating in this simulation, you will be able to accurately evaluate how much you can sell your products for, taking into account your purchase price, applicable taxes, shipping costs, discounts, payment fees, service fees, the selling price you want to achieve, the desired profit, and any potential loss. This approach aims to provide you with a comprehensive analysis to optimize your sales strategies and maximize your profits. */}
        {infoSource.language(infoSource, "text", "textSellSimul")}
        </p>
        <ul>
       { allPrice.map((l, i)=><li key={i}>{l}</li>)}
        </ul>
    </div>
}

const TableInfoShip =()=>{
return    <>
<p>
    {/* We would like to inform you that you have the option to add the shipping cost to the product price if necessary. Many sellers adopt this approach while maintaining their competitiveness in the market. This strategy can help manage shipping expenses effectively and ensure your pricing remains attractive to customers. */}
    {infoSource.language(infoSource, "text", "textSellSimul0")}
</p>

<form className="my-3 w-75">
    <div className="form-group">
                <label className="text-muted">
                    {/* do you want to add the shipping cost to the product price? */}
                    {infoSource.language(infoSource, "sellPage", "WaaddshipCosProdPri")}
                    </label>
                <select value={shippingboo} className="mx-4" onChange={()=> addShippingBoo()}>
                    <option value='' >{infoSource.laguangeElakay('label',  infoSource.languageAPI).pleaseSelect}</option>
                    <option value={false}>{infoSource.laguangeElakay('label',  infoSource.languageAPI).no} </option>
                    <option value={true}>{infoSource.laguangeElakay('label',  infoSource.languageAPI).yes}</option>
                </select> 
                {/* <span className="font-weight-light text-info"> {infoSource.laguangeElakay('label',  infoSource.languageAPI).onChargeShipping}</span> */}
                <p className="font-weight-light text-danger"> 
                    {/* Shipping will be your responsibility. We are preparing a shipping business plan for those who want it. We will inform you as soon as it is ready. */}
                    {infoSource.language(infoSource, "sellPage", "shipResponsb")}
                    </p>
    </div>
</form>
    {shippingboo && selectTypelist()}
    {shippingboo && getLinkShoose.contries && selectcompagnies()}
    {  shippingboo && getLinkShoose.contries && getLinkShoose.compagnies && <>
    
    <h4 className="m-1">{getLinkShoose.compagnies}</h4>
    <h6 className="text-primary">
        {/* Frais de Shipping Local in  */}
        {infoSource.language(infoSource, "sellPage", "FraiShipLoc")}
        {getLinkShoose.contries}</h6>
      <ShippingTable 
      country={getLinkShoose.contries} 
      company={getLinkShoose.compagnies} 
      />
      </>}

      {selectMinMoyMax()}
      </>
}
const allForm =()=>{
    return <div className="row"> 
        <div className=" col col-md-6">
        {addfieldForm()}
        </div>
        <div className=" col col-md-6">
        {salesFromAdress()}
        </div>
    </div>
}
const handleStylePara = (v, k, o)=>{
    if(o ==='a'){
    setLinkShoose({contries: v})
    }else if (o ==='b'){
        setLinkShoose({...getLinkShoose, compagnies: v})
    }else if (o ==='c'){
        setLinkShoose({...getLinkShoose, services: v})
    }
}

const handleMinMoyMax = (o, k)=>{ 
    if(o ==='Minimum'){
        let shi = k[0] 
        let nwePriceProd = Math.abs(getProduct.constPrice) + shi
        setProduct({...getProduct, price: nwePriceProd})
        setShipPriceSelect({price: shi,  Select: 'Minimum'})
      
  
    }else if (o ==='Moyenne'){
        let shi = (k[0] +  k[1])/2
        let nwePriceProd = Math.abs(getProduct.constPrice) + shi
        setProduct({...getProduct, price: nwePriceProd})
        setShipPriceSelect({price: shi,  Select: 'Moyenne'})
       
       
    }else if (o ==='Maximum'){
        let shi = k[1]
        let nwePriceProd = Math.abs(getProduct.constPrice) + shi
        setProduct({...getProduct, price: nwePriceProd})
        setShipPriceSelect({price: shi,  Select: 'Maximum'}) 
       
    }else{
        let shi = (k[0] +  k[1])/2
        let nwePriceProd = Math.abs(getProduct.constPrice) + shi
        setProduct({...getProduct, price: nwePriceProd})
        setShipPriceSelect({price: shi,  Select: 'Moyenne'})
    }
}

const handPriceProfit =()=>{
        setShippingboo(false)
        setShipping([])
        setDeliveryTime('')
        setCelleseleted([])
        setLinkShoose({contries:'', compagnies: '', services: '' }) 
        setShipPriceSelect({price: 0,  Select: ''})
        setProduct({ price : 0, salePrice: 0,  constPrice: 0, count: 1})
        setPurchaseProduct({...getPurchaseProduct, ProfitPrice : 0})

    if(getBySellProfit){
        setBySellProfit(false)
           }else{
               setBySellProfit(true)
           }

}
const PrtagetPriCheck = (ob)=>{
    if('Target selling price' == ob  && getBySellProfit){
return true
    }else if('Desired profit' == ob  && !getBySellProfit){
return true
    }else{
        return false
    }
}
const selectProfitTargetPrice =()=>{
    return  <div className="form-group m-4">
           { priceAdd.map((ob, i) =>{
            return <> <input 
                        className="form-check-input" 
                        key={i} 
                        onChange={()=>handPriceProfit()} 
                        checked={PrtagetPriCheck(ob) ? 'checked' : ''} 
                        type="radio" 
                        name="ProfitPrice" 
                        id={"ProfitPrice_" + ob}
                        />
                       <label className="form-check-label mx-2" for={"ProfitPrice_" + ob}>{ob}&#160;&#160;&#160;&#160;&#160;</label> 
                    </>
           })} 
       </div>
}

const selectMinMoyMax =()=>{
    return getshipping.length > 0 && <div className="form-group mt-0 px-4">
            <h5>{cellSeleted[0]} {headTitle[cellSeleted[2]]}</h5>
           
           { shippinselected.map((ob, i) =>{
            return <> <input 
                            className="form-check-input" 
                            key={i} 
                            onChange={()=>handleMinMoyMax(ob, getshipping)} 
                            checked={getShipPriceSelect['Select'] == ob ? 'checked' : ''}
                             type="radio" 
                             name="shipPriceSelect"
                             id={"shipPriceSelect_" + ob}
                              />
                       <label className="form-check-label mx-2" for={"shipPriceSelect_" + ob}>{ob}&#160;&#160;&#160;&#160;&#160;</label> 
                    </>
           })}
           <table className="col-4 table table-sm" >
            <tbody>
            <tr>
                <th className="w-50"><span className="fw-bolder mr-3">
                    {/* shipping price */}
                    {infoSource.language(infoSource, "sellPage", "shipPrice")}:
                    </span> </th>
                <td>{deliveryTime[1]}</td>
            </tr>
            <tr>
                <th><span className="fw-bolder mr-3">
                    {/* Deliver time */}
                    {infoSource.language(infoSource, "sellPage", "delivTime")}:
                    </span> </th>
                <td>{deliveryTime[0]}</td>
            </tr>
            <tr>
                <th><span className="fw-bolder mr-3">
                    {/* shipping price selected:  */}
                    {infoSource.language(infoSource, "sellPage", "shipPriSel")}:
                    ({getShipPriceSelect.Select})</span> </th>
                <td>
                {shippingboo ?
                    (getShipPriceSelect.price).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) :
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                }
                </td>
            </tr>
            </tbody>
           </table>
         </div> 
}

const selectTypelist =()=>{
    return  <div className="form-group">
           { contryShip.map((ob, i) =>{
            return <> <input 
                        className="form-check-input"
                         key={i} 
                         onChange={()=>handleStylePara(ob, i, 'a')} 
                         checked={getLinkShoose['contries'] == ob ? 'checked' : ''} 
                         type="radio" 
                         name="contries"
                         id={"contries_" + ob}
                         />
                       <label className="form-check-label mx-2" for={"contries_" + ob}>{ob}&#160;&#160;&#160;&#160;&#160;</label> 
                    </>
           })} 
       </div>

}

const selectcompagnies =()=>{
    let objKey =  Object.keys(shippingData[1][getLinkShoose.contries])
    return  <div className="form-group">
           { objKey.map((ob, i) =>{
            return <> <input 
                        className="form-check-input"
                        key={i} 
                        onChange={()=>handleStylePara(ob, i, 'b')} 
                        checked={getLinkShoose['compagnies'] == ob ? 'checked' : ''} 
                        type="radio" 
                        name="compagnies" 
                        id={"compagnies_" +ob}
                        />
                       <label className="form-check-label mx-2" for={"compagnies_" + ob}>{ob}&#160;&#160;&#160;&#160;&#160;</label> 
                    </>
           })} 
       </div>

}

const PaymentAchat =()=>(
    <table className="table table-sm">
        <tbody>
            <tr>
                <th>{count} {infoSource.laguangeElakay('label', infoSource.languageAPI).items} : </th>
                <td>{(getSimulationPrice.ItemsOrder).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })} </td>
            </tr>
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                <td>{
                    shippingboo ?
                    (0).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) :
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                }
                </td>
            </tr>
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                <td>{(getSimulationPrice.TaxOrder).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr className="table-primary">
                <th className="text-danger" style={{ fontSize: "18px" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).total}: </th > <td className="text-danger" style={{ fontSize: "18px" }}>{(getSimulationPrice.getTotal).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}</td>
            </tr>
       
        </tbody>
    </table>
    
)

const profitLost = ()=>{
   return <>
   <h3>
    {/* Sales Similutation */}
    {infoSource.language(infoSource, "sellPage", "SalSimiL")}
    </h3>
   <table className="w-75 table table-sm">
    <tbody>
        <tr>
            <th> 
                {/* Purchase Price  */}
                {infoSource.language(infoSource, "sellPage", "PurPrice")}:
                </th>
            <td>{(getPurchaseProduct.purchasePrice).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })} </td>
        </tr>
        <tr>
            <th> 
                {/* Sales Price */}
                {infoSource.language(infoSource, "sellPage", "SalPrice")}:
                ({ shippingboo ?'with shipping price ':'without shipping price'}): </th>
            <td>
            {(getSimulationPrice.ItemsOrder).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
                
            </td>
        </tr>
        <tr>
            <th> 
                {/* Shipping Cost */}
                {infoSource.language(infoSource, "sellPage", "shipCost")}:
                 </th>
            <td>{(getShipPriceSelect.price).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
            </td>
        </tr>
        <tr>
            <th>
                 {/* In Come */}
                 {infoSource.language(infoSource, "sellPage", "inCome")}:
                  </th>
            <td>{(getSimulationPrice.inComeSeller).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
            </td>
        </tr>

        <tr className="text-7xl">
            <th>
                 {/* Profit */}
                 {infoSource.language(infoSource, "sellPage", "profit")}:
                  </th>
            <td>{(getSimulationPrice.statut.profit).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
            </td>
        </tr>
        <tr className="">
            <th> 
                {/* Loss  */}
                {infoSource.language(infoSource, "sellPage", "loss")}:
                </th>
            <td>{(getSimulationPrice.statut.loss).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
            </td>
        </tr >
        <tr className="">
            <th> 
                {/* Statut */}
                {infoSource.language(infoSource, "sellPage", "statut")}:
                 </th>
            <td>{getSimulationPrice.statut.statut}
            </td>
        </tr>
      
   
    </tbody>
</table>
</>
}

const PaymentAchVent =()=>(
    <table className="table table-sm">
        <tbody>
        <tr>
                <th>
                    {/* prix befor discount  */}
                    {infoSource.language(infoSource, "sellPage", "pribefdisc")}: 
                    </th>
                <td>{(getSimulationPrice.prodPrice).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })} </td>
            </tr>
            <tr>
                <th>
                    {/* prix after discount */}
                    {infoSource.language(infoSource, "sellPage", "prAftDisc")}:
                     </th>
                <td>{(getSimulationPrice.ItemsOrder).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })} </td>
            </tr>
            <tr>
                <th>
                    {/* Discount  */}
                    {infoSource.language(infoSource, "sellPage", "discount")}:
                    {getProduct.salePrice}%: </th>
                <td>{(getSimulationPrice.prodDiscount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                <td>{
                    shippingboo ?
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }) :
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                }
                </td>
            </tr> 
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                <td>{(getSimulationPrice.TaxOrder).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr className="table-primary">
                <th className="text-danger" style={{ fontSize: "18px" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).total}: </th> <td className="text-danger" style={{ fontSize: "18px" }}>{(getSimulationPrice.getTotal).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}</td>
            </tr>

          <tr>
                <th>
                    {/* Frais paiements 2.9% + $0.30 */}
                    {infoSource.language(infoSource, "sellPage", "fraiPaiem")}: 
                    </th>
                <td>{(getSimulationPrice.prodFrTransaction).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th>
                    elakay Feet 6% + $
                    { parseFloat(clotureFeet).toFixed(2)}: </th>
                <td>{(getSimulationPrice.elakayFrais).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr className="table-secondary">
                <th className="text-danger" style={{ fontSize: "18px" }}> 
                    {/* Total des Frais  */}
                    {infoSource.language(infoSource, "sellPage", "TotFrais")}: 
                    </th> <td className="text-danger" style={{ fontSize: "18px" }}>{(getSimulationPrice.totalFrais).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}</td>
            </tr>

            <tr className="table-success">
                <th className="text-danger">
                    {/* Revenu Net  */}
                    {infoSource.language(infoSource, "sellPage", "revNet")}: 
                    </th>
                <td className="text-danger">{(getSimulationPrice.inComeSeller).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            
        </tbody>
    </table>
    
)

const PaymentVente =()=>(
    <table className="table table-sm">
        <tbody>
            <tr>
                <th>{1} {infoSource.laguangeElakay('label', infoSource.languageAPI).items} : </th>
                <td>{(getSimulationPrice.prodPrice).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })} </td>
            </tr>
            <tr>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                <td>{
                    shippingboo ?
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }) :
                        (0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                }
                </td>
            </tr>
            <tr style={{ textDecoration: "line-through", fontStyle: "italic", fontFamily: "myFirstFont", fontSize: "15px" }}>
                <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                <td>{(getSimulationPrice.TaxOrder).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr style={{ textDecoration: "line-through", fontStyle: "italic", fontFamily: "myFirstFont", fontSize: "15px" }}>
                <th>
                    {/* Discount  */}
                    {infoSource.language(infoSource, "sellPage", "discount")}:
                     </th>
                <td>{(getSimulationPrice.prodDiscount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th>
                    {/* Frais de Service  */}
                    {infoSource.language(infoSource, "sellPage", "fraisServi")}: 
                    </th>
                <td>{(getSimulationPrice.prodFrService).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th>
                    {/* Frais paiements */}
                    {infoSource.language(infoSource, "sellPage", "fraispaie")}: 
                    </th>
                <td>{(getSimulationPrice.prodFrTransaction).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th> 
                    {/* Frais de Vente */}
                    {infoSource.language(infoSource, "sellPage", "fraiVente")}: 
                    </th>
                <td>{(getSimulationPrice.prodFrVente).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th> 
                    {/* Frais Gestion & d'Expedition  */}
                    {infoSource.language(infoSource, "sellPage", "frGesExpedi")}:
                     </th>
                <td>{(0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>

            <tr>
                <th>
                    {/* Frais de Referencement  */}
                    {infoSource.language(infoSource, "sellPage", "fraiReferen")}:
                     </th>
                <td>{(getSimulationPrice.prodFrRef).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>
            <tr>
                <th> 
                    {/* Frais de Clôture  */}
                    {infoSource.language(infoSource, "sellPage", "fraisClotu")}: 
                    </th>
                <td>{(clotureFeet).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>

            <tr className="table-secondary">
                <th className="text-danger" style={{ fontSize: "16px" }}> 
                    {/* Total des Frais  */}
                    {infoSource.language(infoSource, "sellPage", "TotFrais")}: 
                    </th> <td className="text-danger" style={{ fontSize: "16px" }}>{(getSimulationPrice.totalFrais).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}</td>
            </tr>

            <tr className="table-success">
                <th className="text-danger">
                    {/* Revenu Net  */}
                    {infoSource.language(infoSource, "sellPage", "revNet")}: 
                    </th>
                <td className="text-danger">{(getSimulationPrice.inComeSeller).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}
                </td>
            </tr>

       
        </tbody>
    </table>
    
)

const order = () => (
    <div>

        {
                // <div  className="container mt-3 mb-3 p-0 pb-2">
                <div>
                <div className="m-2">
                </div>
               <div style={{ backgroundColor: "#f0f0f0", color: "#000000" }}>
                        
                        <div className="row m-0">
                            <div className="col col-10 col-sm-9 col-md-6">
                                <h5 className="pl-0">
                                    {/* Details de Vente */}
                                    {infoSource.language(infoSource, "sellPage", "DetailsVente")}
                                    </h5>
                                {PaymentVente()}
                                <h5>
                                Details des Achats
                                    {infoSource.language(infoSource, "sellPage", "detaiAchats")}
                                    </h5>
                                {PaymentAchat()}
                            
</div>
        
                            <div className="col col-10 col-sm-9 col-md-6">
                               {/* <h5>Details des Achats</h5>
                               {PaymentAchat()} */}
                               <h5>
                                {/* Details Achats & vente */}
                                {infoSource.language(infoSource, "sellPage", "detAchVent")}
                                </h5>
                               {PaymentAchVent()}
                                {/* <h5 className="pl-0">{infoSource.laguangeElakay('title', infoSource.languageAPI).shippnigAddress}</h5> */}        
                            <div>
                            {/* <button className="mt-3 mb-3 btn btn-md btn-Ligh btn-block">
                            tchaking number
                                </button> */}
                             {/* <button className=" w-75 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4">
                                    Add thack number
                            </button> */}
                                    {/* <button className="w-75 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4">
                                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).printLabel}
                            </button> */}
                             </div>
                                
</div>
                        </div>
                    </div>

                    <div className="m-2">



                    </div>
        
                </div>
                //  </div>
    
        }
    
    </div>
)

return <>
{infoDiscount()}
{ <CatSearch 
         search ={search}
         setSearch ={setSearch}
         getCategoriesByName={getCategoriesByName}
         categories={categories}
         loadCategory={loadCategory}
         />}
{formPurchase()}
{selectProfitTargetPrice()}
{allForm()}
{TableInfoShip()}
{getSimulationPrice.ItemsOrder > 0 && profitLost()}
{getSimulationPrice.ItemsOrder > 0 && order()}
</>
}
export default SalesSimulation 