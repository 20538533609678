import { elakay_API } from "../../config";

export const AddTraductoTeam = (token, teamTraductor, up) => {
    return fetch(`${elakay_API}/language/AddTraductoTeam?upd=${up}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(teamTraductor)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const allLanguageElakay = (t) => { 
    return fetch(`${elakay_API}/language/allLanguageElakay?t=${t}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const LanguageElakayById = (token, ID) => { 
    return fetch(`${elakay_API}/language/LanguageElakayById/${ID}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const LanguageElakayByLanguage = (info, t) => { 
    return fetch(`${elakay_API}/language/LanguageElakayByLanguage?t=${t}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));
};

export const LanguageElakayByTUID = (token, lang, fam) => { 
    return fetch(`${elakay_API}/language/LanguageElakayByTUID?lang=${lang}&fam=${fam}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const LanguageElakayBySUID = (token) => { 
    return fetch(`${elakay_API}/language/LanguageElakayBySUID`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const LanguageElakayByAUID = (token) => { 
    return fetch(`${elakay_API}/language/LanguageElakayByAUID`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateLanguageElakay = (ID, token, languageInfo) => { 
   return fetch(`${elakay_API}/language/updateLanguageElakay/${ID}`, {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(languageInfo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateLanguageElakay0 = (ID, token, languageInfo, coding) => {
    return fetch(`${elakay_API}/language/updateLanguageElakay0/${ID}?coding=${coding}`, {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(languageInfo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteLanguageElakay0 = (ID, IDP, KEY, token, IV, coding = false) => {

    return fetch(`${elakay_API}/language/deleteLanguageElakay0/${ID}/${IDP}/${KEY}?I_V=${IV}&coding=${coding}`, {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updateLanguageFileUp = (ID, IDP, KEY, token, language, IV, coding) => {
    let languageInfo = new FormData()
    languageInfo.append('file', language)
    return fetch(`${elakay_API}/language/updateLanguageFileUp/${ID}/${IDP}/${KEY}?I_V=${IV}&coding=${coding}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            // "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: languageInfo
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const SelectDataEmaail = (array, title, value) => {
 try { 
    let objt = array.find(objt => objt.data.title === title)
    if (!objt) {
        throw new Error('no data found in elakay data base language');
      }
    return objt

} catch (err) {
    console.log(err);
}
};