import React from "react";
import Showimage from './ShowImage'
import Vote from './commentaire/vote'
import { isAuthenticated, updateItem, removeItem } from "../auth";
import { MdDelete} from "react-icons/md";
import { FaPlus, FaMinus} from "react-icons/fa";
import { FiDelete } from "react-icons/fi";
import Price from "./productsPrice"
import ShippingCart from "../pageInside/shippingCart";
 
const Card = ({
    shipAmout,
    SetShipAmount,
    Saddress,
    countProuct,
    product,
    CataID,
    laguangeElakay,
    infoSource,
}) => {
    const {
        cartItems
      } =  infoSource

    const { token } = isAuthenticated();
    const CartID = product.cartID
    const catalID = CataID ? CataID : product.categoryID
    const updateCart =(c, p)=>{
        updateItem(token, { "count": c, "CartID": p}).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    cartItems(token)
                    
                 
                }
            })
        }

    const deleteCart = (c, p) => {
        removeItem(token, { "count": c, "CartID": p }).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                cartItems(token)
    
            }
        })
    }


    const handleChange = CartID => event => {
        if (event.target.value >= 1) {
            let count =  event.target.value < product.quantity ? event.target.value : product.quantity
            updateCart(count, CartID)
        }

        if (event.target.value === 0) {
            deleteCart(event.target.value, CartID)
        }
    };

    const buttonMimus =()=>{
        let newCount = countProuct
        newCount --
        if (newCount === 0){ 
            deleteCart(newCount, CartID)
           
        }
        if (newCount>=1){
            updateCart(newCount, CartID)
       
        }
    }
    const buttonPLus = () => {
        let newCount = countProuct 
        if(newCount < product.quantity){
        newCount ++
        updateCart(newCount, CartID) 
        }
    }

    const buttonDeleteCart = ()=>{
        deleteCart(0, CartID)
    }

    const delete_Minus =(
        countProuct >1?<FaMinus/>:<MdDelete/>
    )

    const sum = (total, num) => (
        total + Math.round(num)
    )

    const sumVote = (
        product.vote.map((p) => p.voteNum)
    )
    const rating = sumVote.reduce(sum, 0) / product.vote.length

    const showVote = rating > 0 ? true : false

    const voteView = (
        showVote && (
            <div style={{ bottom: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", width: "100%", height: "25px", fontSize: "18px" }}><Vote rating={parseFloat(rating)} /></div>

        )
    )

    const inputCart = () =>{
        return (
        
        <div className="input-group" style={{width:"140px"}}>
            <div className="input-group-prepend">
                    <span className="input-group-text" onClick={buttonMimus}>{delete_Minus}</span>
            </div>
            <input 
            onChange={handleChange(CartID)}
            type="number"
            className="form-control" 
            min="0" 
            max={product.quantity}
            aria-label="Amount (to the nearest dollar)"
            value={countProuct}
            />
                <div className="input-group-append">
                    <span className="input-group-text" onClick={buttonPLus}><FaPlus/></span>
                </div>
            </div>
        
         )
    
    }
    const allfilter = (filter) =>{
        let texFil = ""
        for (let fil in filter){
         texFil +=  fil + " : " + filter[fil] + ";  "
        }
        return texFil
    }
    const urlFilter = (filter) =>{
        let texFil = ""
        for (let fil in filter){
            texFil +=  fil+"="+filter[fil]+"&"
        }
        return "?"+texFil
    }

    const stock = () => {
        if (product.proFilter.quantity === 0) {
            return <p className="text-danger">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart1}</p>
        }
        else if (product.proFilter.quantity < 20) {
            return <p className="text-danger">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart2} {product.proFilter.quantity} {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart3}</p>
        }
        else if (product.proFilter.quantity >= 20) {
            return <h6 className="text-success">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart4}</h6>
        }

    }
    let urlFil =  urlFilter(product.proFilter.filter)
   
    return (
        <div className="card mt-2" style={{minHeight:"134px"}}> 
            <div className="row">  
                <table className="col-10 col-md-11 col-lg-11" >
                    <tbody>
                    <tr className="row">
                <td className="col col-5 col-md-5 col-lg-5" style={{ maxWidth: "200px" }}>
                   
                <Showimage
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    item={product}
                    productId={product.productID}
                    categoryId={catalID}
                    urlImg={product.urlImg}
                    showB={false}
                    urlFilter={urlFil}

                />
                </td>
                
                        <td className="col col-7 col-md-7 col-lg-7">
                          <table>  
                              <thead>
                              <tr>
                        <th>
                            <span style={{fontSize:"16px"}}>{product.name.substring(0, 25)}...</span><br/>
                             
                                    </th>
                        
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                            {inputCart()}
                            <span style={{fontSize:"12px"}}>{allfilter(product.proFilter.filter)}</span>  
                                    </td>
                            </tr>
                            <tr>
                                 <td>
                                <Price
                                    showAddToCartButton={false}
                                    products={product}
                            />
                         </td>
                    </tr>
                    <tr>
                                <td>{voteView}</td>
                    </tr>
                    <tr>
                         <td>{stock()}</td>
                    </tr>
                    <tr>
                                       <td>
                                            <ShippingCart
                                                laguangeElakay={laguangeElakay}
                                                infoSource ={infoSource}
                                                shipAmout={shipAmout}
                                                SetShipAmount={SetShipAmount}
                                                token={token}
                                                Saddress={Saddress}
                                                products={product}
                                            />
                                            </td>
                    </tr>
                                    </tbody>
                    </table>
               </td>
               
           </tr>
                    </tbody>
           </table>
    
                <div className="col-2 col-md-1 col-lg-1">
                    <span ><p style={{ textAlign: "right", fontSize: "25px", paddingRight: "14px" }} onClick={buttonDeleteCart}><FiDelete /></p> </span>

           </div>  

           </div>
           </div>
       
    );
};

export default Card;