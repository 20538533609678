import React, { useState} from "react";
import Layout from "../Layout/Layout";
import {  UserByMail, isAuthenticated } from "../auth";
import { AddTraductoTeam } from "../auth/apiLanguage"
import { Family } from "../pageInside/Rolle"
import Modal from 'react-bootstrap/Modal';


const AddTraductorTeam = (props) => {
    const {
        infoSource,
        } = props
        let inforUser = {
            email:'',
            user: '',
            name:''
        }
 const { token } = isAuthenticated();
    const [loading, setLoading] = useState(false);
    const [updat, setUpdat] = useState(false);
    const [selectRole, setSelectRole] = useState("");
    const [email, setEmail] = useState("");
    const [traductorTeam, setTraductorTeam] = useState({
        error: '',
        language :{}
        });

const allLanguage = [
    {"code":"en","name":"English","nativeName":"English"},
    {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
    {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
    {"code":"fr","name":"French","nativeName":"français, langue française"},
    {"code":"pt","name":"Portuguese","nativeName":"Português"}
]
const {
    error,
    language,
    translator,
    supervisor,
    Admin
    }= traductorTeam

      const handleEmail = () => event => {
        const value = event.target.value;
        setEmail(value)
    };

    const handleChange = () => event => {
        const value = event.target.value;
        setSelectRole(value);
    };

    const handlelanguage = () => event => {
        const value = event.target.value;
        let all = allLanguage.filter(l => l.name == value)[0]
        setTraductorTeam({ ...traductorTeam, language : all? all: {} });
    };

    const addlanguage = (upd)=>{
        AddTraductoTeam(token, traductorTeam, upd).then(data => {
            if (data.error) {
                setTraductorTeam({ ...traductorTeam, error: data.error });
            } else {
                setTraductorTeam({
                    error: '',
                    language :{}
                }
                    )    
        }
        })
    }
    let showUpdate = error == 'Sorry they are a team in this case' ? true : false
    const clickEmail = (email, token) => {
        UserByMail(token, email, '', '', Family()).then(data => {
        
            if (data.error) {
                setTraductorTeam({ ...traductorTeam, error: data.error });
            } else {
                setEmail("")
                setSelectRole("")
                setTraductorTeam({ 
                    ...traductorTeam,
                    error: '',
                    [selectRole]: { 
                                email: data.user.email, 
                                user :data.user._id,
                                name: data.user.firstname +' '+ data.user.lastname
                                    }
                
                })    
        }
        })
    }

    const userSeleted =()=>{
         return <div className="form-group"  onBlur={() => setTraductorTeam({  ...traductorTeam, error: "" })}>
                <label className="text-muted">
                    {/* select type of user */}
                    {infoSource.language(infoSource, "label", "seltyUser")}
                    </label>
                <select onChange={handleChange()} className="form-control" value={selectRole}>
                <option value={""}>
                    Choose type of your user
                    </option>
                <option value={'translator'}>
                    translator
                    </option>
                <option value={'supervisor'}>
                    supervisor
                    </option>
                <option value={'Admin'}>
                    Admin
                    </option>
                </select>
            </div>

    }

    const LanguageSeleted =()=>{
        return <div className="form-group"  onBlur={() => setTraductorTeam({  ...traductorTeam, error: "" })}>
               <label className="text-muted">
                {/* select type of user */}
                {infoSource.language(infoSource, "label", "seltyUser")}
                </label>
               <select onChange={handlelanguage()} className="form-control" value={language.name? language.name:''}>
               <option value=''>
                {/* Choose a language */}
                {infoSource.language(infoSource, "label", "ChoLang")}
                </option>
               {allLanguage.map((l ,i)=>{
               return <option key={i} value={l.name}>{l.name}</option>
               })
               }
               </select>
           </div>

   }
    const CheckUser =()=>{
       return <div className="form-group" onBlur={() => setTraductorTeam({  ...traductorTeam, error: "" })}>
        {showError()}
        <form>
            <div className="form-group">
                <label className="text-muted">
                    {/* check user for */}
                    {infoSource.language(infoSource, "label", "cheUser")}
                     {selectRole} 
                     {/* if exit */}
                     {infoSource.language(infoSource, "label", "ifExit")}
                     </label>
                <input onChange={handleEmail()} type="text" className="form-control" placeholder={`enter the email for ${selectRole}`} value={email} />
            </div>

        </form>
    {selectRole && email?
    <button onClick={() => (clickEmail(email, token))} className="btn btn-primary float-right">
        {/* check email */}
        {infoSource.language(infoSource, "Button", "checkEmail")}
        </button> :
    <button className="btn btn-primary float-right" disabled>
        {/* check email */}
        {infoSource.language(infoSource, "Button", "checkEmail")}
        </button>
}
</div> 
    }
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    let l = language && language.name? true : false
    let t = translator && translator.user? true : false
    let s = supervisor && supervisor.user? true : false
    let A = Admin && Admin.user? true : false
    let LAST = t || s || A || l
    let ast = t || s || A
    let last = ast && l 

const typeUserAdding = ()=>{
   
  return    A && s && t ? addDIv("alert alert-primary",' a translator, Supervisor and Admin'  , false, ''): 
            t && s ? addDIv("alert alert-primary", 'a translator and supervisor'  , true, ' a Admin'): 
            A && s ?addDIv("alert alert-primary", 'a Admin and supervisor'  , true, ' a translator') :
            A && t ?addDIv("alert alert-primary", 'a Admin and translator'  , true, ' a supervisor') :
            t ? addDIv("alert alert-primary", 'a translator'  , true, ' a Supervisor and Admin'): 
            s ? addDIv("alert alert-primary", 'a Supervisor'  , true, ' a translator and Admin'): 
            A ? addDIv("alert alert-primary", 'a Admin'  , true, ' a Supervisor and translator') :
            addDIv("alert alert-warning" ,'noboby '  , true, ' a translator,a Supervisor and Admin')


}

const addDIv = (e, a, b= false, c)=>{
    return <div className={e}> 
        <h5>
            {/* you add  */}
            {infoSource.language(infoSource, "title", "yadd")}
            {a}
            </h5>
        {b && <h6> 
            {/* do you want add  */}
            {infoSource.language(infoSource, "title", "dwadd")}
            {c}
            </h6>}
    </div>
}

const addLang =()=>{
    return <div className="alert alert-info">
      { l && <>
        <h5>Language</h5>
        <h6>code: <span>{language.code}</span></h6>
        <h6>name: <span>{language.name}</span></h6>
        <h6>nativeName: <span>{language.nativeName}</span></h6>
        </>}
      { t && translator.email && <>
        <h5>translator</h5>
        <h6>email: <span>{translator.email}</span></h6>
        <h6>name: <span>{translator.name}</span></h6>
        </>}
      { s && supervisor.email && <>
        <h5>supervisor</h5>
        <h6>email: <span>{supervisor.email}</span></h6>
        <h6>name: <span>{supervisor.name}</span></h6>
        </>}
       { A && Admin.email && <>
        <h5>Admin</h5>
        <h6>email: <span>{Admin.email}</span></h6>
        <h6>name: <span>{Admin.name}</span></h6>
        </>}
       { last ?
        <button onClick={() => (addlanguage(false))} className="btn btn-primary">{infoSource.language(infoSource, 'Button', "submit")}</button>:
        <button  className="btn btn-primary" disabled>{infoSource.language(infoSource, 'Button', "submit")}</button>
        }

    </div>
} 


let clickEditAdd = (chek)=>{
   
     if(!chek){
       setUpdat(false)
        setTraductorTeam({ ...traductorTeam, error: "" });
    }else{
       setUpdat(true)
        
    }
 }
const agreeUpdate = () => (
    <div className="contaire">
        <div className="row justify-content-center " >
            <Modal
                size="lg"
                show={showUpdate}
                onHide={() => clickEditAdd(false)}
                dialogClassName="modal-90w "
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title"> */}
                        {/* <h2 className="text-center">{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</h2> */}
                    {/* </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                <div
                    className="alert alert-danger"
                    style={{ display: error ? "" : "none" }}
                    >
                    <h4>{error}</h4>
                    
                </div>
                <h5>
                    {/* are your sure you want to update your team */}
                    {infoSource.language(infoSource, 'title', "sureUpteamLang")}
                    </h5>
                <div> 
                <input 
                className="form-check-input"
                onClick={()=>clickEditAdd(false)}  
                type="radio" 
                name="typeList"
                id="typeList"
                 />
                 <label className="form-check-label" for="typeList">no &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> 
                <input 
                className="form-check-input"
                onClick={()=>clickEditAdd(true)} 
                 type="radio" 
                 name="typeList" 
                 id="typeList1"
                 />
                 <label className="form-check-label" for="typeList1">yes &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> 
    </div>
                { updat?
        <button onClick={() => (addlanguage(true))} className="btn btn-primary">
            {infoSource.language(infoSource, 'Button', "submit")}
            </button>:
        <button  className="btn btn-primary" disabled>
            {infoSource.language(infoSource, 'Button', "submit")}
            </button>
        }
                </Modal.Body>
            </Modal>
        </div>
    </div>
)


    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container"
            loading={loading}
            SliderPunShow={false}
        >
        <div style={infoSource.styleNav}>
        <div className="container">
            <div className="row"> 
            {LanguageSeleted()}
            <div className="alert alert-secondary">
            {userSeleted()}
            {CheckUser()}
            </div>
            </div>
            {typeUserAdding()}
            {LAST && addLang()}
            {agreeUpdate()}
</div></div>
        </Layout>
    );
};

export default AddTraductorTeam;