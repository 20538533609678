import React, { useState, useEffect } from "react";
import Products from '../pageInside/product'
import Layout from "../Layout/Layout"; 
import NewProduct from '../pageInside/NewProduct'
import { useParams } from "react-router-dom";
import { getCategoriesByID } from "../auth/apiCategory";
import { getProductsF } from "../auth/apiProduct";
import Card from '../pageInside/Card'
import { Row} from 'react-bootstrap';
import Col from 'react-bootstrap/Col'
import { signout, isAuthenticated} from "../auth";
import { prices } from "../pageInside/fixedPrices";
import EmptyProductCatagies from '../Layout/imgs/No-products-1-4.png' 
import SellerPub from '../Layout/imgs/Seller-pub-1.png' 
import ButtonPagination from "../pageInside/ButtonPagination";
import { laguangeElakay } from "../pageInside/dataElakay";
import { FirtIDCategory } from "../../config";
import Loader1 from '../Layout/imgs/loading3.gif';
import PaginationInfo from "../pageInside/paginationInfo";
const ProductCatalog = (props) => {
    const {
        infoSource
        } = props

    let params = useParams()
    let elakayCategoryID = params.categoryId
    
    const [myFilters, setMyFilters] = useState({
        filters: {"categoriesID.gategoriesLink": [] }
    });
    const [getpub, setPub] = useState([{ "urlImg": Loader1, "_id":1}]);
    const [loading, setLoading] = useState(true);  
    const [products, setProducts] = useState([]);
    const [limit] = useState(40);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [siseProduct, setSiseProduct] = useState();  
    const [CategoriesProd, setCategoriesProd] = useState([]); 
   
    const { token } = isAuthenticated();
    const [productSizeAll, setProductSizeAll] = useState([]);
  

    // load categories and set form data


       let myStyles ={
             width: "100%", 
             backgroundColor: '#f0f0f0' 
    }
 

    const loadProductsBySell = (newFilters) => {
        newFilters["categoriesID.gategoriesLink"] = [elakayCategoryID]

        getProductsF(skip, limit, "createdAt", newFilters).then(data => {
            if (data.error) {
               console.log(data.error);
            } else { 
       
                setProductSizeAll(data.size);
                setProducts(data.products);
                setSiseProduct(data.siseProduct);
                setSize(data.size);
                setSkip(0); 
                setLoading(false)
                
            }
        });
    };

    const loadCategories = elakayCategoryID => {
        
        getCategoriesByID(elakayCategoryID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCategoriesProd(data)
                setPub(data.pub)
            }  
        });
    }; 

    useEffect(() => {
        loadProductsBySell(myFilters.filters)
        loadCategories(elakayCategoryID)
           
    }, [elakayCategoryID, myFilters.filters]);

 const handlePrice = value => {

        let array = [];
        for (let key in prices) {
            if (prices[key]._id === parseInt(value)) {
                array = prices[key].array;
            }}
        return array;
    };

    const handleFilters = (filters, filterBy) => {
        const newFilters = { ...myFilters };
        newFilters.filters[filterBy] = filters;

        if (filterBy === "price") {
            let priceValues = handlePrice(filters);
            newFilters.filters[filterBy] = priceValues;
        }
        loadProductsBySell(myFilters.filters);
        setMyFilters(newFilters);
    };

    const loadMore = () => {
        let toSkip = skip + limit;
        getProductsF(toSkip, limit, "createdAt", myFilters.filters).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setProducts(data.products);
                setSiseProduct(data.siseProduct);
                setSize(data.size);
                setSkip(toSkip);
                
            }
        });
    };

    const loadMore0 = () => {
        let toSkip = skip - limit;
        getProductsF(toSkip, limit, "createdAt", myFilters.filters).then(data => {
            if (data.error) {
                console.log("data Not Found");
                signout(token).then(() => { window.location.href = '/signin' })
            } else {
                setProducts(data.products);
                setSiseProduct(data.siseProduct);
                setSize(data.size);
                setSkip(toSkip);
            }
        });
    };

    const e1 =  CategoriesProd && CategoriesProd.items && CategoriesProd.items.length > 0 ? false: true
    const P1 = productSizeAll > 0 ? true: false
    const SliderPunShow = !e1? true : false


    const NoProduct = ()=>{
       return  <>  
            <div className="row justify-content-center">
            <div className="col-11 col-md-10 col-lg-9 col-xl-8">
                <div className="shadow-lg mt-3 mb-3">
                    <img
                        className="rounded"
                        src={EmptyProductCatagies}
                        alt={"no Product"}
                        style={{ height: "100%", width: "100%" }}
                    />
                </div>
            </div>
            </div>

            <NewProduct
                  laguangeElakay={laguangeElakay}
                  infoSource ={infoSource}
                readUser={infoSource.readUser}
                />

            <div className="row justify-content-center">
                <div className="col-11 col-md-10 col-lg-9 col-xl-8">
                    <div className="shadow-lg mt-3 mb-3">
                    <img
                        className="rounded"
                        src={SellerPub}
                        alt={"Seller Pub"}
                        style={{ height: "100%", width: "100%" }}
                    />
                </div>
            </div>
            </div>

        </>
    }

    const sliderProduct  = ()=>{
    
       return P1  ? CategoriesProd && CategoriesProd.items && CategoriesProd.items.map((c, i) => (

                <div key={i}>
                    
                    <Products
                        laguangeElakay={laguangeElakay}
                        infoSource ={infoSource}
                        // nameSlider={c.supCategoryID.name[0]}
                        nameSlider={infoSource.getCategoryNames(c).name[0]}
                        linkSlider={`/product/${c.supCategoryID._id}`}
                        categoryID={c.supCategoryID._id}
                        SliderPunShow={SliderPunShow}
                    />

                </div> )) : NoProduct()
        }

    const resultPagination = (
        <div>
            <p>{(skip - limit) + limit + 1} - {((skip + limit) > siseProduct) ? siseProduct : (skip + limit)} 
                {/* of  */}
                {" "}
                {infoSource.language(infoSource, "label", "of")}
                {" "}
                {siseProduct} 
                {" "}
                {/* results */}
                {infoSource.language(infoSource, "label", "results")}
                </p>
        </div>
    )

    const productALL =(a)=>{
       
       return <Row className="m-1"  xs={2} sm={2} md={3} lg={4} xl={5}>
            {
             a && a.map((product, i) => (
                    <Card
                        laguangeElakay={laguangeElakay}
                        infoSource ={infoSource}
                        key ={i}
                        product={product}
                        CataID={product.categoriesID[0].catID}
                        filterSel={myFilters.filters}
                        classCard0 = "col p-2" 
                         /> 
                ))}
              
        </Row>
    }


    const productShow = () => {
        return(
       
        e1 ?
            P1 ? <>
               <PaginationInfo
        skip={skip}
        limit={limit}
        siseProduct={siseProduct}
        infoSource={infoSource}
        prodCat={''}
        />
            {productALL((products))}
            < ButtonPagination 
                infoSource ={infoSource}
                size={size}
                skip ={skip}
                limit={limit}
                siseProduct={siseProduct}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />
            </> : NoProduct()
            :
            sliderProduct()
        
        )
    }
    infoSource.CategoriesProd = CategoriesProd && CategoriesProd

    return ( 
        <Layout
            infoSource ={infoSource}
            categoriesID={elakayCategoryID}
            loading={loading} 
            prices={prices}
            handleFilters={handleFilters}
            setMyFilters={setMyFilters}
            // className="container-fluid"
            SliderPunShow={SliderPunShow}  
            e1={CategoriesProd && CategoriesProd.items && CategoriesProd.items.length}
            AllCategorie = {true}
            loadProductsBySell={loadProductsBySell}
            getpub={getpub}

        >
        <div style={infoSource.styleNav}>

            {productShow()}
        
        </div>
        </Layout>
    );
};

export default ProductCatalog;