import React, { useState } from "react";
import Vote from './commentaire/vote'

const RadioboxRating= (props) => {

   const {
    handleFilters ,
    allStars
    }= props
const {
    stars1, 
    setStars1,
    stars2, 
    setStars2,
    stars3, 
    setStars3,
    stars4, 
    setStars4,
    stars5, 
    setStars5,
}= allStars
    // const [stars1, setStars1] = useState(false);
    // const [stars2, setStars2] = useState(false);
    // const [stars3, setStars3] = useState(false);
    // const [stars4, setStars4] = useState(false);
    // const [stars5, setStars5] = useState(false);

    const hand1 = (a) => {
        setStars1(a)
        setStars2(false)
        setStars3(false)
        setStars4(false)
        setStars5(false)
        if (a) {
            handleFilters('1');
        } else {
            handleFilters("");
        }
    }
 
    const hand2 = (a) => {
        setStars1(false)
        setStars2(a)
        setStars3(false)
        setStars4(false)
        setStars5(false)
        if (a) {
            handleFilters('2');
        } else {
            handleFilters("");
        }
    }
    const hand3 = (a) => {
        setStars1(false)
        setStars2(false)
        setStars3(a)
        setStars4(false)
        setStars5(false)
        if (a) {
            handleFilters('3');
        } else {
            handleFilters("");
        }
    }    
    const hand4 = (a) => {
        setStars1(false)
        setStars2(false)
        setStars3(false)
        setStars4(a)
        setStars5(false)
        if (a) {
            handleFilters('4');
        } else {
            handleFilters("");
        }
    }
    const hand5 = (a) => {
        setStars1(false)
        setStars2(false)
        setStars3(false)
        setStars4(false)
        setStars5(a)
        if (a) {
            handleFilters('5');
        } else {
            handleFilters("");
        }
    }
const rating = ()=>{
     return <div>

            <h6> <input onChange={() => hand5(stars5 ? false : true)} value={5} checked={stars5 ? 'checked' : ''} name="Stars" type="radio" className="form-check-input mr-2 ml-4" id='stars5'/><label for="stars5" className="form-check-label"> <Vote rating={5} /></label></h6>
            <h6> <input onChange={() => hand4(stars4 ? false : true)} value={4} checked={stars4 ? 'checked' : ''} name="Stars" type="radio" className="form-check-input mr-2 ml-4" id='stars4'/> <label for="stars4" className="form-check-label"><Vote rating={4} /></label></h6>
            <h6> <input onChange={() => hand3(stars3 ? false : true)} value={3} checked={stars3 ? 'checked' : ''} name="Stars" type="radio" className="form-check-input mr-2 ml-4" id='stars3'/> <label for="stars3" className="form-check-label"><Vote rating={3} /></label></h6>
            <h6> <input onChange={() => hand2(stars2 ? false : true)} value={2} checked={stars2 ? 'checked' : ''} name="Stars" type="radio" className="form-check-input mr-2 ml-4" id='stars2'/> <label for="stars2" className="form-check-label"><Vote rating={2} /></label></h6>
            <h6> <input onChange={() => hand1(stars1 ? false : true)} value={1} checked={stars1 ? 'checked' : ''} name="Stars" type="radio" className="form-check-input mr-2 ml-4" id='stars1'/> <label for="stars1" className="form-check-label"><Vote rating={1} /></label></h6>

        </div>
}


    return (
   rating()
    )
};

export default RadioboxRating; 
