import React, { Component } from 'react'

export default class Timer extends Component {

    constructor(props) {
        super(props);
    this.state = {
        distance: this.props.nbrdays > 0? this.props.nbrdays : 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }
}

    componentDidMount() {
        this.myInterval = setInterval(() => {

            const {distance, days, hours, minutes, seconds} = this.state
        
    
            // if (seconds > 0) {
            //     this.setState(({ seconds }) => ({
            //         seconds: seconds - 1
            //     }))
            // }

            if (seconds === 0) {
                if (minutes === 0) {
                    if (hours === 0) {
                        if (days === 0) {
                            if(distance === 0 ){

                                 clearInterval(this.myInterval)

                            }else{
                                
                                this.setState(({ distance }) => ({
                                days: Math.floor((distance % (1000 * 60 * 60 * 24 * 90)) / (1000 * 60 * 60 * 24)),
                                hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                                minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                                seconds: Math.floor((distance % (1000 * 60)) / 1000)

                            }))
                            }      
                        } else {
                            this.setState(({ days }) => ({
                                days: days -1,
                                hours: 24,
                                minutes: 59,
                                seconds: 59,
                            }))
        
                        }
                    } else {
                        this.setState(({ hours }) => ({
                            hours: hours - 1,
                            minutes: 59,
                            seconds: 59,
                        }))
                    }
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }else{
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
           

        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

   

    render() { 
        const { distance, days, hours, minutes, seconds } = this.state
 const {
    infoSource
    } = this.props
        const timerShow = ()=>{
          return<div> 
           
            <table>
            <tr>
                <th><h1>{days < 10 ? `0${days}` : days}</h1></th>
                <th><h1>{hours < 10 ? `0${hours}` : hours}</h1></th>
                <th><h1>{minutes < 10 ? `0${minutes}`: minutes}</h1></th>
                <th><h1>{seconds < 10 ? `0${seconds}` : seconds}</h1></th>
             
            </tr>
            <tr>
                <td><h5>
                    {/* Days */}
                    {infoSource.language(infoSource, "title", "days")}
                    </h5></td>
                <td><h5>
                    {/* Hours */}
                    {infoSource.language(infoSource, "title", "hours")}
                    </h5></td>
                <td><h5>
                    {/* minutes */}
                    {infoSource.language(infoSource, "title", "minutes")}
                    </h5></td>
                <td><h5>
                    {/* Seconds */}
                    {infoSource.language(infoSource, "title", "seconds")}
                    </h5></td>
            </tr>
        </table> 
        <h2>
            {/* Time Remaining */}
            {infoSource.language(infoSource, "title", "timeRemaining")}
            </h2>
        </div>

        }
        let nbrD = this.props.nbrdays
        const activation = ()=>{
        
            if(nbrD <= 0){  
                return <h1>
                    {/* you compte is expireded */}
                    {infoSource.language(infoSource, "title", "compExp")}
                    </h1>
            } else {
                  return days < 90 && timerShow()
            }
        }

    return (
     <div className='d-flex align-items-center justify-content-center' style={{background:nbrD <= 0 ? 'Beige': 'LightBlue'}}>
          
            {activation()}
        </div>
    )
    }
}
