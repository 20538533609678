import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {isAuthenticated} from '../auth';
import { useNavigate, Navigate ,  Link, useLocation} from "react-router-dom";
import { FaRegTrashAlt, FaLongArrowAltLeft, FaLink,  FaEdit} from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import { GrLanguage } from "react-icons/gr";
import { createCategory, getCategoriesByID, deleteItemAndCategory, FirstCategory, UpadateNameCat, updateTranslationsCat } from "../auth/apiCategory";
import JsonAddLangueCat from "../pageInside/JsonAddLangueCat";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import { FirtIDCategory } from "../../config";


import "./category.css"


const categoyseleted = [];
const categoyIDseleted = [];


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AddCategory = (props) => { 
    const {
        infoSource,
        laguangeElakay
            } = props
    let query = useQuery();
    const navigate = useNavigate()
    const { token, a } = isAuthenticated();
    const [name, setName] = useState("");
    const [reName, setReName] = useState("");
    const category = FirtIDCategory
    const [categories, setCategories] = useState({});
    // const [items, setItems] = useState([]); 
    // const [filters, setFilters] = useState([]);
    const [loading, setLoading] = useState(false);  
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showDeleteAgree, setDeleteAgree] = useState(false);
    const [showEditName, setEditName] = useState(false);
    const [chek, setChek] = useState(false);
    const [catalogItem, setCatalogItem] = useState({})
    const [showJsonAdd, setShowJsonAdd] = useState(false)
    const [seletName, setSeletName] = useState('');
    const handl = (a) => {
        setChek(a)
    }


const elakayCategoryID = query.get("i")? query.get("i"): categories._id ? categories._id : category;
const {
    items = [],
    filter = []
    } = categories

    useEffect(() => {
        loadCategory(elakayCategoryID)
    },[elakayCategoryID])

const createFirstCategory =(token)=>{
    FirstCategory(token, { _id: FirtIDCategory, name: ["Elakay Categories"], categoryID: [category], owner: a}).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
            loadCategory(FirtIDCategory)
        }
    });
}

    const loadCategory = (elakayCategoryID) => {
        setLoading(true) 
        getCategoriesByID(elakayCategoryID).then(data => {
            if (data.error) {
                elakayCategoryID ===  FirtIDCategory  &&  createFirstCategory(token, elakayCategoryID)
                console.log(data.error);
            } else {  
                setCategories(data.cartegory)
                setName('')
                setLoading(false)        
            }
        });
    };

let urlLink = `/filter/${categories._id}`;

    const handleChange = e => {
        setError('');
        setName(e.target.value);
    };

    const handleChangeRe = e => {
        setError('');
        setReName(e.target.value);
    };

    const deleteItCat = (name, elakayCategoryID, subCategoryID) => {
        deleteItemAndCategory(elakayCategoryID, subCategoryID, token).then(data => {
            if (data.error) {
                setError(data.error);
                // loadCategory(categoryID)
            } else {
                infoSource.loadCategory(category)
                setError(false);
                setSuccess(true);
                setMessage('sub Items name ' + name[0] + ' is deleted in ' + categories.name[0])
                loadCategory(elakayCategoryID)
                setDeleteAgree(false)
                setChek(false)

            }
        });
    };

    const clickSubmit = e => {
        e.preventDefault();
         
        setError("");
        setSuccess(false);
        // make request to api to create category
        createCategory(token, { name }, elakayCategoryID).then(data => {
            if (data.error) {
                // loadCategory(elakayCategoryID);
                setError(data.error);
            } else {
               infoSource.loadCategory(category)
                setError("");
                 setSuccess(true);
                setMessage('sub Items name: ' + name + ' is Create in ' + categories.name)
                loadCategory(elakayCategoryID, token);
            }
        });
    };

    const submitJsonInput =(value)=>{
  
        try {
            updateTranslationsCat(token, JSON.parse(value.getJsonInput), value.id ).then(data => {
                if (data.error) {
                    value.setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`)
                } else {
                    setShowJsonAdd(false)
                    infoSource.loadCategory(category)
                    loadCategory(value.id);
                    value.setErrorMessage(null)
                }
            });
        // setNewObejet(JSON.stringify(obj,  null, 3))
        value.setErrorMessage(null)
      //    
        } catch (error) {
      // Afficher l'erreur si le parsing échoue
      value.setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
      }
      }

    const clickSubmitEdit = (name, id, subId) => {

        UpadateNameCat(token, { newName :reName,  oldName : name[0]}, subId).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                infoSource.loadCategory(category)
                setError("");
                 setSuccess(true);
                setMessage('Items name: ' + name + ' replaced by  ' + reName)
                loadCategory(id);
                setReName('')
            }
        });
    };
    const categoryLink = elakayCategoryID =>{
        loadCategory(elakayCategoryID);
            categoyseleted.push(categories.name)
            categoyIDseleted.push(categories._id)
            // setBackID(elakayCategoryID)
        }

    const clickBack = (elakayCategoryID) => {
        loadCategory(elakayCategoryID)
        categoyIDseleted.pop()
        categoyseleted.pop()
    }

    const ButLoadCat = (a, b) => {
        loadCategory(a, b);
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
    }
    const ButtonLink = () => {
        navigate(urlLink)
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
       
    }
    const SubmitB = (name) => {
        setSeletName(name)
        setDeleteAgree(true)
        setEditName(false)
    }

    const SubmitA = (name) => {
        setSeletName(name)
        setDeleteAgree(false)
        setEditName(true)
       
    }
    const e1 = items.length  > 0? true: false;
    const f1 = filter.length > 0? true: false;

        const deleteAgree = (name, CadegoriesID, supCategoryID, i ) => {
         
            return (
                <div key={i} className="contaire">
                    <div className="row justify-content-center " >
                        <Modal
                            show={showDeleteAgree}
                            onHide={() => setDeleteAgree(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <h4 className="text-center">
                                        {/* delete this items */}
                                        {infoSource.language(infoSource, "title", "delThtems")}
                                        </h4>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row justify-content-center m-4">
                                    <h5>
                                        {/* are you sure you want to delete this category name's  */}
                                        {infoSource.language(infoSource, "title", "surToDelete")}
                                        <span className="text-danger" > {name[0]}</span> </h5>
                                    <div className="list-unstyled">
                                        <input
                                            onChange={() => handl(chek ? false : true)}
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={chek ? 'checked' : ''}
                                            id="surToDelete"
                                        />
                                        <label className="form-check-label" for="surToDelete">
                                            {/* check the button if your are agree */}
                                            {infoSource.language(infoSource, "title", "chButAgree")}
                                            </label>
                                    </div>
                                    {
                                        chek ?
                                            <Button onClick={() => deleteItCat(name, CadegoriesID, supCategoryID)}>
                                                {infoSource.language(infoSource, 'Button', "submit")}
                                                
                                            </Button> :
                                            <button className="btn" > 
                                            {infoSource.language(infoSource, 'Button', "submit")}
                                            </button>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>

            )
        }

        const editNameCat = (name, CadegoriesID, supCategoryID, i ) => {
         
            return (
                <div key={i} className="contaire">
                    <div className="row justify-content-center " >
                        <Modal
                            show={showEditName}
                            onHide={() =>setEditName(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <h4 className="text-center">
                                        {/* Edit category  */}
                                        {infoSource.language(infoSource, "title", "editCategory")}
                                        {" "} {name[0]}</h4>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row justify-content-center m-4">
                            <div className="itemCategory">
                                <input
                                     onBlur={() => setMessage("")}
                                    className="inputCategory"
                                     type="text"
                                    placeholder="edit here"
                                    onChange={handleChangeRe}
                                    value={reName}
                                    autoFocus
                                    required
                                    />
                            <button className="AddCategory" onClick={() =>clickSubmitEdit(name, CadegoriesID, supCategoryID)}>+</button>
                            </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>

            )
        }

const addLangue = ()=>{
    return <Modal
    show={showEditName}
    onHide={() =>setEditName(false)}
    dialogClassName="modal-90w"
    aria-labelledby="example-custom-modal-styling-title"
>
    <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
            <h4 className="text-center">
                {/* Edit category  */}
                {infoSource.language(infoSource, "title", "editCategory")}
                {name[0]}</h4>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
    
    </Modal.Body>
</Modal>
}
    
let mp = 'm-0 p-1'

const BntCatLinkToCat =(link)=>{
    return <Navigate replace to={link} />
}

const btnTranslateCat =(value)=>{
    setShowJsonAdd(true)
    setCatalogItem(value)
}

    const CategoryFom = () => (
        <div>
            <div className="boxCategory1" id="heading">
                
                <h2> {ButtonBackCategory()} {categories.name[0]} </h2>
                {/* <div className="text-center  text-muted">{categoyseleted.map((l, j) => (<p key={j} className="d-inline" >{l}{" / "}</p>))}</div> */}
                <div className="text-center  text-muted">{categories.name.toReversed().join(' / ')}</div>
                {showSuccess()}
                {showError()}
            </div>
           
            <div className="boxCategory">

                {items.map((c, i) => (
                 <form key={i} className="container">
                        <div className="itemCategory row ">
                        <div className= {`col-2 col-lg-2 Cacol ${mp}`} >
                            <p>
                               { c.supCategoryID.filter.length > 0 ?
                                 "X"
                                :
                            <Link to={`/LinkCategory/${c.supCategoryID._id}`}><FaLink/></Link>
                            }
                                </p>
                        </div>
                        <div className= {`col-1 col-lg-1 Cacol ${mp}`} >
                        <span onClick={()=>btnTranslateCat({name: c.supCategoryID.name, category_id: categories._id, subCategory_id: c.supCategoryID._id, lang: c.supCategoryID.lang})}><MdLanguage /></span>
                        </div>
                            <div className={`col-5 col-lg-5 CacolCatPara ${mp}`}>
                                <p onClick={() => categoryLink(c.supCategoryID._id)} >
                                   {c.supCategoryID.name.join('; ')} 
                                </p>

                            </div>
                            <div className={`col-2 col-lg-2 Cacol ${mp}`}>
                                    <p className="dellCategory" onClick={() => SubmitA(c.supCategoryID.name)}><FaEdit /></p>
                            </div>
                            <div className={`col-1 col-lg-1 Cacol ${mp}`}>
                                    <p onClick={() => SubmitB(c.supCategoryID.name)} className="dellCategory"><FaRegTrashAlt /></p>
                            </div>
                        </div>
                        <input className="inputCategory" type="hidden" name="listName" value=""></input>

                            { showDeleteAgree && c.supCategoryID.name === seletName &&  deleteAgree(c.supCategoryID.name, categories._id, c.supCategoryID._id, i)} 

                            {showEditName && c.supCategoryID.name === seletName && editNameCat(c.supCategoryID.name, categories._id, c.supCategoryID._id, i)}
                    
                        </form>
                ))}
                {!f1 &&
                <form className="itemCategory" onSubmit={clickSubmit}>
                    <input
                        onBlur={() => setMessage("")}
                        className="inputCategory"
                        type="text"
                        placeholder="New Item"
                        onChange={handleChange}
                        value={name}
                        autoFocus
                        required
                    />
                    <button className="AddCategory">+</button>
                </form>
    
                }
            </div>

        </div>
    )

    const createFilterProduct = () => {
        return (
            <button onClick={() => ButtonLink()} className="btn btn-outline-primary mt-2 mb-2 pr-3 pl-3 rounded-4 float-right">
                {/* Create Filter Product */}
                {infoSource.language(infoSource, "Button", "creatFiltProd")}
            </button>

        )
    }
    const ButtonBackCategory = () => {
        let last_le = categoyIDseleted[categoyIDseleted.length - 1]
        return (
            <span onClick={() => clickBack(last_le)} className="h5 float-left">
                <FaLongArrowAltLeft/>
            </span>
        )
    }
   
   
    const createCategoryButton = () => {
        return (
            <button onClick={() => ButLoadCat(category, token)} className="btn btn-outline-primary mt-2 mb-2 pr-3 pl-3 rounded-4 float-left">
                {/* View Elakay Category */}
                {infoSource.language(infoSource, "Button", "VieElaCat")}
            </button>
      )
    }

    const showSuccess = () => {
        if (success) {
            return <div className="alert alert-info" style={{ display: message ? "" : "none" }}> {message} </div>;
        }
    };

    const showError = () => {
        if (error) {
            return <div className="alert alert-danger" style={{ display: error ? "" : "none" }}> {error} </div>;
        }
    };
    const redirectUser = (a, b) => { 
        if (a) {   
            categoyseleted.splice(0, categoyseleted.length)
            categoyIDseleted.splice(0, categoyIDseleted.length)
            return <Navigate replace to={b} />
            // return window.location.href = b;
            }
           }

    const theFirstCategory =()=>{
        return <div className="container">
        <div className="itemCategory row">
            <div className="col col-lg-7 Cacol">
            <button onClick={createFirstCategory} className="btn btn-outline-primary mt-2 mb-2 float-left">
                {/* get Started */}
                {infoSource.language(infoSource, "Button", "getStarted")}
            </button>
        </div>
        </div>
        </div>

    }
    return (
        <Layout
            infoSource ={infoSource}
            className="container blockEl"
            SliderPunShow={false}
            loading={loading}
        >
            <JsonAddLangueCat
            infoSource ={infoSource}
            showJsonAdd={showJsonAdd}
            setShowJsonAdd={setShowJsonAdd}
            catalogItem={catalogItem}
            submitJsonInput={submitJsonInput}
            />
            <div style={infoSource.styleNav}>
            {redirectUser(f1, urlLink)}
            <div className="row " onBlur={() => setMessage("")}>
                <div className="col-md-8 offset-md-2 blockEl">
                {categories._id ? <>
                {CategoryFom()}
                <div className="container">
                        {createCategoryButton()}
                        {!e1 && createFilterProduct()}
                </div>
                </>
                :
                theFirstCategory()
                }
                </div>
                 </div>
                  {/* <pre>{JSON.stringify({items, catalogItem, categories}, null, 2)}</pre> */}
                 </div>
        </Layout>
    );
};

export default AddCategory;