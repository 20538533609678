import React from 'react';

const IncomePrice = ({
    products,
    taxSell = 1,
    taxAdmin = 1
}) => {

    const newPrice = (
             products.reduce((currentValue, nextValue) => {
                 return currentValue + (nextValue.count * taxSell * taxAdmin * (nextValue.price * (100 - nextValue.salePrice) / 100))
                }, 0)
    )


    // const thePrice = products.salePrice > 0 ? newPrice : products.price

    return (
        (newPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })

    )
}

export default IncomePrice