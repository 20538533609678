import React, { useState, useEffect } from "react";
const FiltersIncomme = props =>{
    const { 
        infoSource,
        laguangeElakay,
        limit, 
        setLimit,
        setFiltOrders,
        setShowFilterOrder,
        filtOrders
    }=props

    let status = [{value: "none", name: 'none'}, {value: "Not processed", name: 'notProcessed'}, {value: "Processing", name:'processing'},{value: "Shipped", name: 'shipped'}, {value: "Delivered", name:'delivered'}, {value: "Cancelled", name:'cancelled'}] 
    // let statusName = ["Not processed", "Processing", "Shipped", "Delivered", "Cancelled"] 
    const incommeList = ['Incomme as Seller', 'Incomme for my Seller', 'Incomme for my Provider', 'Incomme for my Associate', 'Incomme for my Investment', 'Incomme for my Administrator', 'Incomme for my Familly', 'all Incomme']
    
    let date = ['days', 'months', 'years', 'date']
    const [orderStatus, setOrderStatus] = useState(filtOrders.orderStatus);
    const [orderDate, setOrderDate] = useState(filtOrders.orderDate);
    const [incomme, setIncomme] = useState(filtOrders.incomme);
    const [myd, setmyd] = useState(filtOrders.myd);
    const [nlimit, setNlimit] = useState(limit);


const handlechange = event =>{
    setmyd(event.target.value)
}

const handlPageOrder = event =>{
    setNlimit(event.target.value)
}

const handleDate = event =>{
    let value = event.target.value
    if(value === "days"){
        setmyd(15)
    }else if(value === "months"){
        setmyd(6)
    } else if(value === "years"){
        setmyd(2024)
    }
    setOrderDate(value)
}
const btnfilter = ()=>{
    setLimit(nlimit)
    setShowFilterOrder(false)
    setFiltOrders({orderStatus, orderDate, incomme, myd})
}

const btnClrfilter = ()=>{
    setLimit(3)
    setShowFilterOrder(false)
    setFiltOrders({orderStatus: 'none',  orderDate: 'months',  myd: 6})
}
    const statusForm = ()=>{
        return<div className="my-4"> 
    <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold 
             fw-bold">
                {/* Status */}
                {infoSource.laguangeElakay('title', infoSource.languageAPI).status}
                </h5></div>
            {status.map((p, i) => (
            <h5 key={i}>
                <input
                    onChange={()=>setOrderStatus(p.value)}
                    // value={`${p.value}`}
                    name={'status'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    checked={p.value == orderStatus ? 'checked' : ''}
                    id={"status_" + p.name}
                />
                <label className="form-check-label" for={"status_" + p.name}>{infoSource.laguangeElakay('label', infoSource.languageAPI)[p.name]}</label>
            </h5>
        ))}
        </div>
    }
    
    const IncommeForm = ()=>{
        return<div className="my-4"> 
           <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold 
             fw-bold">
                {/* Status */}
                {infoSource.laguangeElakay('title', infoSource.languageAPI).status}
                </h5></div>
            {incommeList.map((p, i) => (
            <h5 key={i}>
                <input
                    onChange={()=>setIncomme(i)}
                    // value={`${p.value}`}
                    name={'incomme'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    checked={i == incomme ? 'checked' : ''}
                    id={"ncomme_" + p}
                />
                <label className="form-check-label" fo={"ncomme_" + p}>{p}</label>
            </h5>
        ))}
        </div>
    }
    const dateForm = ()=>{
        return<div className="my-4"> 
          <div className="m-0 p-1" style={{backgroundColor: "WhiteSmoke"}}>  <h5 className="my-0 mx-1  p-1 fw-bold fw-bold">
            {/* filter date */}
            {infoSource.laguangeElakay('title', infoSource.languageAPI).filterdate}
            </h5></div>
            {date.map((p, i) => (
            <h5 key={i}>
                <input
                    onChange={handleDate}
                    value={`${p}`}
                    name={'date'}
                    type="radio"
                    className="form-check-input mr-2 ml-4 check2"
                    checked={p == orderDate ? 'checked' : ''}
                    id={"date_" + p}
                />
                <label className="form-check-label"for={"date_" + p}>{p}</label>
            </h5>
        ))}
        </div>
    }
    
    const orderBypageselect = ()=>{
    const pageOrder = []
    const startNumber = 1
    const endNumber = 20;

    for (let number = startNumber; number <= endNumber; number++) {
        pageOrder.push(number);
    }
      return <div className="my-4"> 
     <div className="m-0 p-1 mb-4" style={{backgroundColor: "WhiteSmoke"}}> <h5 className="my-0 mx-1  p-1 fw-bold fw-bold"> 
        {/* Number order by page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).orderPage}
        </h5></div>
      <select value={nlimit} onChange={handlPageOrder} className="w-50 m-4 form-select">
      <option>
        {/* select number order by page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selOrderPage}
        </option>
      {pageOrder.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}

const daySelect = ()=>{
    const allDays = []
    const startDay = 1
    const endDay = 30;

    for (let day = startDay; day <= endDay; day++) {
        allDays.push(day);
    }
      return <div className="my-4"> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* filter by Days */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).filterDays}
        </h5></div>
      <select value={myd} onChange={handlechange} className="w-50 m-4 form-select">
      <option>
        {/* select the days */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selectdays}
        </option>
      {allDays.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}



const monthsSelect = ()=>{
    const allMonths = []
    const startMonth = 1
    const endMonth = 12;

    for (let month = startMonth; month <= endMonth; month++) {
        allMonths.push(month);
    }
      return <div className="my-4"> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* filter by months */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).filterMonths}
        </h5></div>
      <select value={myd} onChange={handlechange} className="w-50 m-4 form-select">
      <option>
        {/* select the month */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selectmonth}
        </option>
      {allMonths.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}

const yearsSelect = ()=>{
   const allyears = []
    var currentTime = new Date()
    var curYear = currentTime.getFullYear()
    const startYear = curYear - 6
    const endYear = curYear;

    for (let year = endYear; year >= startYear; year--) {
        allyears.push(year);
    }
      return <div className="my-4"> 
       <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
        {/* filter by years */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).filteryears}
        </h5></div>
      <select value={myd} onChange={handlechange} className="w-50 m-4 form-select">
      <option>
        {/* select the year */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).selectyear}
        </option>
      {allyears.map((c, i) => (
            <option key={i} value={c}>
                {c}
            </option>
        ))}
      </select>
 
   </div>
}

const dateSelect = ()=>{
    const allyears = []
     var currentTime = new Date()
     var curYear = currentTime.getFullYear()
     const startYear = curYear - 6
     const endYear = curYear;
 
     for (let year = endYear; year >= startYear; year--) {
         allyears.push(year);
     }
       return <div className="my-4"> 
        <div className="m-0 mb-4" style={{backgroundColor: "WhiteSmoke"}}><h5 className="my-0 mx-1  p-1 fw-bold">
            {/* Choose a date */}
            {infoSource.laguangeElakay('title', infoSource.languageAPI).Choosedate} 
            </h5></div>
        {/* <label htmlFor="date-input">Choose a date:</label> */}
       
      <input
        type="date"
        id="date-input"
        value={myd}
        onChange={handlechange}
      />
  
    </div>
 }
    const btnForm = ()=>{
        return<div className="my-4"> 
                <button className="btn btn-dark rounded-3" onClick={btnClrfilter}>
                    {/* clear filter */}
                     {infoSource.laguangeElakay('Button', infoSource.languageAPI).clearfilter}
                </button>
                <button className="btn btn-dark rounded-3" onClick={btnfilter}>
                    {/* set filter */}
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).setfilter}
                </button>

               
        </div>
    }
    return ( 
        <>
        {orderBypageselect()}
        {/* {IncommeForm()} */}
        {statusForm()}
        {dateForm()}
        {orderDate === 'days' && daySelect()}
        {orderDate === 'months' && monthsSelect()}
        {orderDate === 'years' && yearsSelect()}
        {orderDate === 'date' && dateSelect()}
        {btnForm()} 
        </> 
        )
}
export default FiltersIncomme;