import React, { useState }  from "react";
import { Link } from 'react-router-dom';
import { Modal} from "react-bootstrap";
import logoElakay from './icon/LogoElakay1.svg';
import DrawerToggleButton from '../../components/SIdeDrawer/DrawerToggleButton';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaEdit,  FaSlidersH } from "react-icons/fa";
import ExpandingSearch from "./ExpandingSearch/ExpandingSearch";
import { GrUpdate } from "react-icons/gr";
import './Header.css';

const HeaderShopSeller = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [showModal, setShowModal] = useState(false);
   const [expanded, setExpanded] = useState(false);
    const {
      infoSource,
        userID,
        shopName = "Seller",
        loadindSearCh,
        onFilterShop,
        search,
        drawerClickHander,
        myShop = false,
        lakaySeller = false,
        showFilter = true,
        setEditStore,
        editStore,
        outOffPage, 
        setActiveHeader

    }= props
    const navbarStyle = {
        backgroundColor: "Snow",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "10px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        top: '0px' ,
        width: "100%",
        minWidth: "320px",
        height: "85px",
        position: "relative", // Allows absolute positioning of the input
      };
    
      const logoStyle = {
        fontSize: "24px",
        fontWeight: "bold",
        position: "absolute",
        color: 'black',
        left: "20px", // Keeps the title aligned to the left
        top: "30%",
        transform: "translateY(-50%)", // Centers it vertically
      };

      const menu3Bar = {
        fontSize: "29px",
        fontWeight: "bold",
        color:'black',
        // background: '#2563eb',
        textDecoration: 'none',
        display: 'inline-block',
        // width: '35px',
        // height: '35px',
        padding: '1px',
        margin: '3px',
        position: "absolute",
        color: 'black',
        left: "20px", // Keeps the title aligned to the left
        // bottom: "3px",
        top: "40px",
        // transform: "translateY(-50%)", // Centers it vertically
      };

      const menu4Bar = {
         textDecoration: 'none',
        position: "absolute",
        display: 'inline-block',
        // fontWeight: "bold",
        color:'black',
        left: "20px", // Keeps the title aligned to the left
        top: "46px",
      };
    
      const searchInputStyle = {
        position: "absolute",
        top: "30%",
        left: '70%',
        transform: "translate(-50%, -50%)", // Centers the input in the navbar
        width: "50%", // Width of the input
        padding: "8px 12px",
        // border: "1px solid #ccc",
        borderRadius: "4px",
        outline: "none",
        // backgroundColor: isFocused ? "#f9f9f9" : "#fff",
        transition: "all 0.3s ease-in-out",
        // boxShadow: isFocused ? "0 0 5px rgba(0, 0, 0, 0.2)" : "none",
      };
    
      const menuStyle = { 
        position: "absolute",
        listStyle: "none",
        display: "flex",
        // justifyContent: "center",
        left: "20px",
        padding: 0,
        margin: 0,
       
        // bottom: "10px", // Keeps the menu at the bottom of the navbar
        top: "48px",
        width: "100%",
      };
    
      const menuItemStyle = {
        padding: '4px',
        bottom: 0,
        margin: "0 6px",
      };
    
      const menuLinkStyle = {
        color: "#2c3e50",
        textDecoration: "none",
        fontSize: "16px",
        transition: "color 0.3s"
      };

      const parentCont = {
        position: "absolute",
        color: 'black',
        left: "20px", // Keeps the title aligned to the left
        top: "30%",
        // backgroundColor: '#eef',
        // transform: "translateY(-50%)",
          /* On place les 2 divs sur la même ligne */
        display: "flex",
        alignItems: 'left',
        gap: '16px', /* espace entre les deux divs */
        // margin: '20px'
        width: "100%",
      }
      const autoWidth = {
        /* Inline-block ou flex-shrink:0 pour prendre la place du texte */
        display: 'inline-block',
        whiteSpace: 'nowrap',   /* Empêche le retour à la ligne, la largeur suit le texte */
        // padding: '10px',
        // backgroundColor: '#eef',
        // border: '1px solid #ccc',
        fontSize: "24px",
        fontWeight: "bold",
        color: 'black',
        left: "1px", // Keeps the title aligned to the left
        top: "30%",
        transform: "translateY(-50%)", // Centers it vertically
        /* Pas de width fixée => la div s'adapte au contenu */
      }

      const fixedWidth = {
        display: 'inline-block',
        width: '60%', /* Largeur par défaut */
        minHeight: '40px',
        top: "30%",
        transform: "translateY(-50%)",
        // backgroundColor: '#fee',
        // border: '1px solid #ccc',
        /* Centrage vertical/horizontal du texte */
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center'
      }
      
      const handleChange = (event) => {
        let val = event.target.value
        if (val.length === 0) {
          loadindSearCh('')
        } else { 
          loadindSearCh(val) 
          };
      }
      const handleShow = () => {
        setShowModal(true);
    
        // Fermer le modal après 3 secondes
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      };
    

    
      return (
        <nav style={navbarStyle}>
        <div style={parentCont} >
          {/* Titre du Shop */}
          <div >
            <Link to={`/lakay-ShopSeller/${userID}`} className={`autoWidthHeaderShopSeller ${infoSource.headShop ? expanded ? "expanded" : "": ""}`}>
            Lakay {shopName}
            </Link>
          </div>
    
      <div style={fixedWidth}>
        <ExpandingSearch 
            infoSource={infoSource}
            handleChange={handleChange}
            setIsFocused={setIsFocused}
            search={search}
            expanded={expanded}
            setExpanded={setExpanded}
          />
      </div>
      </div>
          {/* Menu */}
        
          <ul style={menuStyle} >
            
            {!outOffPage && <><li style={{marginRight : '7%'}} onClick={drawerClickHander}>
              <DrawerToggleButton classToggle={"toggle-button_line1"}/>
              </li>
               <li onClick={()=>setActiveHeader(1)}>
              <GrUpdate />
              </li> 
              <li style={menuItemStyle}>
              <Link to={`/`} style={menuLinkStyle}>
                 <img  className="img-responsive" src={logoElakay} width="14" alt="logo elakay"/>
                elakay
              </Link>
            </li> </> } 
           {lakaySeller && <><li style={menuItemStyle}>
              <Link href="#" style={menuLinkStyle}>
                {infoSource.laguangeElakay('homePage', infoSource.languageAPI).bestSellers}
              </Link>
            </li> 
            <li style={menuItemStyle}>
              <Link href="#" style={menuLinkStyle}>
                {infoSource.laguangeElakay('headNenu', infoSource.languageAPI).todayDeal}
              </Link>
            </li></>}
            <li style={menuItemStyle}>
              <Link href="#" style={menuLinkStyle}>
                {infoSource.laguangeElakay('title',  infoSource.languageAPI).home}
              </Link>
            </li>
            <li style={menuItemStyle}>
              <Link onClick={handleShow} style={menuLinkStyle}>
                {infoSource.laguangeElakay('title',  infoSource.languageAPI).aboutUs}
              </Link>
            </li>
           {showFilter && <li style={menuItemStyle}>
              <Link onClick={onFilterShop} style={menuLinkStyle}>
                {/* <FaSlidersH /> */}
                 {infoSource.laguangeElakay('title',  infoSource.languageAPI).filter}
              </Link>
            </li>}
           { myShop &&  <li onClick={()=>setEditStore(!editStore)} style={menuItemStyle}>
            <FaEdit /> {infoSource.laguangeElakay('title',  infoSource.languageAPI).ediPageContent}
            </li>}
          </ul>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body className="text-center">
          <h1 className="text-danger">🚀 {infoSource.laguangeElakay('title',  infoSource.languageAPI).comingSoon}</h1>
        </Modal.Body>
      </Modal>
        </nav>
      );

      setEditStore={setEditStore}
      editStore={editStore}
}
export default HeaderShopSeller;