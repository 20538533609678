import React, { useState} from "react";
import Modal from 'react-bootstrap/Modal'

import "./CheckLngtofinish.css"



   const formListe =(a)=>{
<form>
{
    a.map((l, i)=>{

    })
}
</form>
   }

const CheckLngToFinish = (props) => {

      const { 
        setEditAdd,
        menu, 
        nameObj,
        objet,
        setObejet,
        infoElakay,
        token,
        AlllanguageSelect,
        languageUsertra,
        editAddByJson= false,
        elakayInfo,
        languageTradictor,
        updateLanguageElakay,
        buttonAddLanguage,
        showCheckLang = true,
        setShowCheckLang,
        activeLink,
        clickPageseleted
    }= props
    
    
let objArr = {
    objetKey: [],
    arrayKey:[]
}
let ntInclu = ['id', '_id', 'translator', 'supervisor', 'Admin','language', 'createdAt', 'updatedAt', '__v']
const [lngKeyObjArr, setLngKeyObjArr] = useState(objArr)
const [error, setError] = useState('')
const checkedList = (a, n)=> a.includes(n)
const needArray = (a, n)=> a.filter(v => !n.includes(v))
const getArray = (a, oA)=> a.filter(v => Array.isArray(oA[v]))
const getObj = (a, oA)=> a.filter(v => !Array.isArray(oA[v]))
const checkValue = (a, oA)=> a.filter(v => checkProductTrue(oA[v]))
const getKeyObjArr = (oa)=>{
    let allOA = Object.keys(oa)
    const result = Array.isArray(arr);
    
    let  i_id = allOA.indexOf('_id')
    let  i_i = allOA.indexOf('translator')
    let  i_ = allOA.indexOf('supervisor')
    if (i_id > -1) { 
        allOA.splice(i_id, 1); 
      }
    let obj
    let arr
}
// const checkProdKeyExit = (ad)=>{
//     let ent = Object.keys(ad ? ad:{});
//    return ent.every(([key, val]) => ad[key] != undefined) && true
//     }

    // const checkObjEmpty = (a)=>{
    //     return JSON.stringify(a) === '{}'
    
    // }
    
    const checkProductTrue = (ad, ei)=>{
        let array = objEqual1(ad, ei) ? Object.values(ad).map(e => e !== ''): false;

         return  !array? false : array.every(a=> a === true)? true : false
        }
    
        const checkProductArrTrue = (ad)=>{
            return ad && ad.length > 0 ? true: false;  
            }

const objEqual = (o1, o2)=> {
   return o1 !== null && o2!== null && typeof o1 === 'object' && Object.keys(o1).length > 0 
    ? Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o2).every(p => objEqual(o1[p], o2[p]))
    : o1 === o2};

const objStringConpare = (os1, os2)=> {
return os1 !== null && os1 !== '' && os1 !== undefined && os2 !== null && os2 !== '' && os2 !== undefined ? true: false
}

    const objEqual1 = (o1, o2)=> {
        return o1 !== null && o2 !== null && typeof o1 === 'object' && Object.keys(o1).length > 0  && Object.keys(o1).length === Object.keys(o2).length
    ? Object.keys(o2).every(p => objStringConpare(o1[p], o2[p]))
    : false; }

    // const buttonAdd =()=>{
    //     updateLanguageElakay(languageTradictor._id, token, {[nameObj]:objet}).then(data => {
    //         if (data.error) {
    //            setError(data.error)
    //         } else {
    //             AlllanguageSelect()
    //             languageUsertra()
    //             setEditAdd(true)
    //             setObejet(menu)    
    //     }
    //     })

    // }

    const showError = () => (
        
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const bntPageSeleted = (a)=>{
         activeLink(a)
        clickPageseleted(a)
        setShowCheckLang(false)
    }

    const impurtCheck = (a, i, c)=>{
        return  <div className="listItems" key={i} onClick={()=>bntPageSeleted(a)} >
          <input
           className="form-check-input p-3 Cacol" 
           type="checkbox" 
           name={a} 
           value={a} 
           checked={c ? true : false}
           id={a}
           />
           <label className="form-check-label p-3 Cacol" for={a}>{a}</label><br/>
           <div className="itemCategory" ></div>
           </div>
          }

    const listObjt =(a, c, de, ei)=>{
 return <ul>
      <h2>All Object</h2>
{
    a.map((l, i)=> impurtCheck(l, i, checkProductTrue(de[l], ei[l])))
}
</ul>
    }

    const listArray =(a, c, de, ei)=>{
        return <ul>
            <h2>All array</h2>
       {
           a.map((l, i)=> impurtCheck(l, i, checkProductArrTrue(de[l], ei[l])))
       }
       </ul>
           }
    let allArrNeed = needArray(Object.keys(languageTradictor), ntInclu)
    let allArrNeed_El = needArray(Object.keys(elakayInfo), ntInclu)
    let allobj = getObj(allArrNeed, languageTradictor) 
    let allobj_El = getObj(allArrNeed_El, elakayInfo)
    let allArrr = getArray(allArrNeed, languageTradictor)
    let allArrr_El = getArray(allArrNeed_El, elakayInfo)
   

    const printListeShop = () => (
         <Modal
                    size="xl"
                    show={showCheckLang}
                    onHide={() => setShowCheckLang(false)}
                    dialogClassName="modal-95w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title id="example-custom-modal-styling-title">
                          {InfoApiTilte.messageElakayAdmin}
          </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                    <div onBlur={() => setError("")}>
                        {showError()}
                        <div className="row"  style={{  'overflow': 'auto', 'maxHeight' : "500px"}}>
                        <div className="col-12 col-sm-6">
                        {listArray(allArrr_El,  allArrr, languageTradictor, elakayInfo)}
                        </div>
                        <div className="col-12 col-sm-6">
                        {listObjt(allobj_El,  allobj, languageTradictor, elakayInfo)}
                        </div>
                        
                        </div>
                     </div> 
                    </Modal.Body>
                </Modal>
    )



   

    //  let entries = Object.entries(infoElakay);

    return printListeShop()

}

export default  CheckLngToFinish 