export const roleUser = [
    {
        _id: 0,
        role: "Family",
        code: "1984",
        email: "yvesoxilus@gmail.com"
    },
    {
        _id: 1,
        role: "Administrator",
        code: "1986"
    },
     {
        _id: 2,
        role: "SellerAdmin",
        code: "2015"
    },
    {
        _id: 3,
        role: "Provider",
        code: "2014"
    },
    {
        _id: 4,
        role: "Seller",
        code: "2010"
    },
    {
        _id: 5,
        role: "Royalty",
        code: "2011"
    },  
    {
        _id: 6,
        role: "Associate",
        code: "2018"
    }, 
    {
        _id: 7,
        role: "Investment",
        code: "2022"
    } 
];