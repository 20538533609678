import React, { useState, useRef } from "react";

const SubmitCancelButtonSlider = (props) => {
    const { 
      infoSource,
        cancel = "Cancel",
        submit = "Submit",
        nrbTime = 3000,
        isDisabled= false,
        colSubmit = "#FFFFFF",
        colCancel = "#C0C0C0",
        colBGCdisplay = "#FFFFFF",
        colBorisplay = "#DCDCDC",
        width = "27%",
        submitfunction =()=> console.log("Action submitted!"),  
    } = props
    const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressIntervalRef = useRef(null); // Référence pour l'intervalle
  const hasSubmitted = useRef(false); // Garde une trace si l'action a été soumise

  const handleClick = () => {
    if (isProcessing) {
      // Annuler l'action en cours
      clearInterval(progressIntervalRef.current); // Arrêter l'intervalle immédiatement
      setIsProcessing(false);
      setProgress(0);
      hasSubmitted.current = false; // Réinitialise l'état soumis
      
    } else {
      // Commencer le traitement
      setIsProcessing(true);
      setProgress(0);
      hasSubmitted.current = false; // Réinitialise pour une nouvelle soumission

      const interval = 30; // Met à jour toutes les 30ms
      const totalTime =  nrbTime; // 3 secondes pour atteindre 100%
      const increment = (interval / totalTime) * 100;

      progressIntervalRef.current = setInterval(() => {
        setProgress((prev) => {
          if (prev + increment >= 100) {
            clearInterval(progressIntervalRef.current); // Arrêter l'intervalle
            setIsProcessing(false); // Réactiver le bouton
            setProgress(100);

            if (!hasSubmitted.current) {
              submitfunction()
              hasSubmitted.current = true; // Marque comme soumis pour éviter les répétitions
            }

            return 100;
          }
          return prev + increment;
        });
      }, interval);
    }
  };

  // Désactiver le bouton si les conditions ne sont pas remplies
//   const isDisabled = customer === null && payment_method === null;

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {/* Bouton avec slider intégré */}
      <button
        onClick={!isDisabled ? handleClick : undefined} // Désactive l'action si les conditions ne sont pas remplies
        disabled={isDisabled}
        style={{
          position: "relative",
          padding: "2px 1px",
          backgroundColor: isProcessing ? colCancel : colSubmit,
          color: isDisabled ? colBorisplay : "#000000",
          border: `2px solid ${isDisabled ? colBorisplay : "black"}`,
          borderRadius: "30px",
          cursor: isDisabled ? "not-allowed" : "pointer",
          overflow: "hidden",
          fontSize: "14px",
          width: width,
          float: "right"
        }}
      >
        <span style={{ position: "relative", zIndex: 1 }}>
          {isProcessing ? cancel : submit}
        </span>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: `${progress}%`,
            backgroundColor: isDisabled ? colBGCdisplay : colSubmit,
            transition: "width 30ms linear",
          }}
        ></div>
      </button>
      {isDisabled && (
        <p style={{ marginTop: "10px", color: "#f44336", fontSize: "14px" }}>
        {/* Please ensure that all required fields are completed, or the payment has already been made, no further action is required. */}
        {infoSource.language(infoSource, "text", "textsubcanbuttsli")}
        </p>
      )}
    </div>
  );
};

export default SubmitCancelButtonSlider;
