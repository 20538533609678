import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {useParams, useLocation } from "react-router-dom";
import { commentProduct, getProductPreview,  creatUserViewed, getImages, taxProductArea} from "../auth/apiProduct";
import {getAddressUserIfTrue, isAuthenticated, addItem} from "../auth";
// import {  getCategoriesByID} from "../auth/apiCategory";
import Empty from '../Layout/icon/Unavailable.png'
import imgProductLakay from '../Layout/imgs/md3.jpg' 
// import Loader from "../Layout/loader.gif";
import Checkout from "../pageInside/CheckoutProd";
import Vote, {ratingVote} from '../pageInside/commentaire/vote'
import SwiperProdView from '../slider/swiperProdView'
import { elakay_API } from "../../config";
import TabsElakay, {tabElakay} from "../pageInside/html/formTabs";
import { laguangeElakay } from "../pageInside/dataElakay";
import Price from "../pageInside/productsPrice"
import { MdAddShoppingCart} from "react-icons/md";
import {FaPlus, FaMinus } from "react-icons/fa";
// import stylesTable from "../pageInside/html/stylesTable.css";
import Products from '../pageInside/product'
import LakayUser from '../pageInside/lakayUser'
import CommentaireUser from '../pageInside/commentaire/comentairePro'
import NewProduct from '../pageInside/NewProduct'
import AddCommentProduct from '../pageInside/commentaire/UserAddCommentsProduct';
import PrevviewProFilt from "../pageInside/prevviewProFilt";
import EmblaCarousel from "../slider/ProdViewSlider/slider"
import Modal from 'react-bootstrap/Modal'
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const reLoadImages =(a, b, c)=>{
    let x = b.filter["color"]
    a.filter(item =>{
        if(item.name == x){
            c(item.images) 
        }
        
    })
        
}
const checkFil = (b, c)=>{
    let textfil  = "" 
    for (let fil in c){
        textfil  +=  b[fil] === c[fil] && ''
 } 

 return textfil
 }

const loadQueryFil =(a, b, y)=>{
    const c =  new Object();
   
    a.map(x => b.get(x._id.name) ? c[x._id.name] = b.get(x._id.name): "")
   let af = y && y.filter(It => checkFil(It.filter, c) === "")
    return af[0]
}


function isValid(variable) {
    if (variable === null || variable === undefined || variable === "") {
        return false;
    }
    if (Array.isArray(variable) && variable.length === 0) {
        return false;
    }
    if (typeof variable === "object" && Object.keys(variable).length === 0) {
        return false;
    }
    return true;
}

// const loadQueryFil =(a, b, y)=>{
//     const c =  new Object();
//     let af = []
//     a.map(x => b.get(x._id.name) ? c[x._id.name] = b.get(x._id.name): "")
  
//     y && y.filter(It =>{
//         if(checkFil(It.filter, c) === ""){
//             af.push(It)
//         }
//     })
//     return af[0]
// }

const ProFilQuaGrToLe =(a)=>{
    return a.sort(({quantity:y}, {quantity:x}) => x-y)
}
const ProductPreview = (props) => {

    const { infoSource } = props

    let params = useParams()
    let query = useQuery();
    let productID = params.productId;
    // let categoryID = params.categoryId;
    const [showComments, setShowComments] = useState(false)
    const [commentsPro, setCommentsPro] = useState([])
    const [sizeComments, SetSizeComments] = useState(0)
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    // const [size, setSize] = useState(0);
    const { token } = isAuthenticated();
    const [loading, setLoading] = useState(true);
    const [taxprodZone, setTaxProdZone] = useState({});
    const [getProduct, setProduct] = useState([])
    const [galeries, setGaleries] = useState([])
    const [images, setImages] = useState([])
    const [categoryID, setCategoryID] = useState('')
    const [oneFilterPro, setOneFilterPro] = useState({})
    const [restAllFilterPro, setRestALLFilterPro] = useState({})
    const [allFilterPro, setALLFilterPro] = useState({})
    const [filters, setFilters] = useState([]);
    const [getQueryFil, setQueryFil] = useState({});
    const [quantity, setQuantity] = useState(0)
    const [count, setcount] = useState(1)
    const [parcel, setParcel] = useState()
    const [addressShop, setAddressFrom] = useState({})
    const [itemUnAvailable, setItemUnAvailable] = useState(false);
    const [getUserVote, setUserVote]= useState()
    let filArrName = []
    const [Saddress, setSAddressFinal] = useState({
        firstName: "",
        lastName: "",
        city: "",
        country: "",
        line1: "",
        postal_code: "",
        state: ""
    });

const getTaxProdArea = (code, zipCode) => {

    taxProductArea(code, zipCode).then(data => {
        if (data.error){
        console.log("data tax prod erea not found")
     }else{
        setTaxProdZone(data.zoneTax)   
     }     
    })
   
};
        useEffect(() => {
            loadProductsView(productID)
            creatViewedUser(productID, token)
            addressId(token)
            ButtonAddComments(false)
            infoSource.getLangaugeAPI()
        }, [token, productID]);
    

        const creatViewedUser = (productID, token)=>{
               
            productID !== null &&  productID !== undefined && creatUserViewed(token, productID)
            
        }

        const addressId = (token) => {
      
            getAddressUserIfTrue(token).then(data => {
                if (data.error) {
                    console.log("data not Found")
                } else {
                    setSAddressFinal({
                        firstName: data[0].firstName,
                        lastName: data[0].lastName,
                        tel: data[0].tel,
                        city: data[0].city,
                        country: data[0].country,
                        line1: data[0].Address1,
                        postal_code: data[0].postalCode,
                        state: data[0].State
                        
                    })
                }
            })
    }
        const loadProductsView = (productID) => {
            getProductPreview(productID).then(data => {
                if (data.err) {
                    setItemUnAvailable(true)
                    console.log("data not Found")
                } else {
                    let gal = data.galeries
                    let prodFil = data.filterProduct
                    let catID = data.product.categoriesID && data.product.categoriesID
                    let filt = data.filters
            loadImages(productID, gal, loadQueryFil(filt, query, ProFilQuaGrToLe(prodFil)))
            setProduct(data.product) 
            setOneFilterPro(loadQueryFil(filt, query, ProFilQuaGrToLe(prodFil)))
            setALLFilterPro(ProFilQuaGrToLe(prodFil))
            setRestALLFilterPro(ProFilQuaGrToLe(prodFil))
            data.shopAddress &&  setAddressFrom(data.shopAddress) 
            data.shopAddress && getTaxProdArea(data.shopAddress.State,  data.shopAddress.postalCode)
            setGaleries(gal) 
            setFilters(catID[0].catID.filter)
            setCategoryID(catID[0].catID._id)
            setNewAP(loadQueryFil(filt, query, ProFilQuaGrToLe(prodFil)))
            setLoading(false)
            } 
            })
        }; 

      
        
        const setNewAP = (a)=>{
            setQuantity(a["quantity"]) 
            setParcel(a && a.parcel !== null || undefined && a.parcel.width !== null || undefined? a.parcel: restAllFilterPro[0].parcel && restAllFilterPro[0].parcel.width  !== null || undefined? restAllFilterPro[0].parcel: {}) 
            
        }

        const loadImages =(Id, a, b)=> {
            if (b && a[0] && a[0].images.length){
                reLoadImages(a,  b, setImages)
            }else{
            getImages(Id).then(data => {
                if (!data) {
                    // console.log(data.error);
                    setImages([imgProductLakay])
                } else {
                    setImages([data.galeries[0].urlImg])
                }
            })}
        };
      

const addToCart = () => {
    let urlImg = images[0]
    let filterProduct = oneFilterPro
    if (token) {
    addItem(token, { "count": count, "categoryID": categoryID, "productID": productID, "productOrner": getProduct.owner._id, urlImg, filterProduct: filterProduct._id}).then(response => {
        infoSource.cartItems(token)
    }).catch(error => {
        console.log(error);
    });
}else {
    return window.location.href = "/signin"
}
};

const ButtonAddComments=(show= true)=>{
        show && setShowComments(true)
    commentProduct(productID, skip, limit).then(data => {
        if (!data) {
            console.log(data.error);
          
        } else {
            setCommentsPro(data.commentsPagination)
            SetSizeComments(data.commentsCount[0] && data.commentsCount[0].totalComments)
            setUserVote(data.userVote[0] && data.userVote[0].vote)
        }
        })
         if(!isValid(token)){show && ( window.location.href = "/signin")} 
}


let voteRating = ratingVote(getProduct.vote)
const etoiteProduct = (
    <>
        {voteRating && (<h5 className='p-0 m-0'><Vote  rating={voteRating}/></h5>)}
        {!voteRating && (<h5 className='p-0 m-0'><Vote rating={0} /></h5>)}
    </>
    )

    const filterDetail = () =>(

        <div className="mt-3">
    {
   oneFilterPro  && filters && filters.map((k, i) => (
    <div key={i}>
    <p style={{minWidth: "130px", fontWeight:"bold", fontSize:"18px", display:"inline-block"}}>{k._id.name}: </p>  
           <p style={{ fontSize: "15px", display: "inline-block" }}>{ oneFilterPro.filter[k._id.name]}</p>
</div>
    ))}
    </div>
    )
    
const descriptionProduct = (
        <div className="container">
            {getProduct.note && getProduct.note.length > 0 && getProduct.note.map((k, j) => (
                k.tilte && (
                <div key={j}>
                <p style={{fontWeight: "bold", fontSize: "20px", marginBottom:"1px"}}>{k.tilte}</p>
                    <p>{k.description}</p>
                </div>
                )
            ))
            }
        </div>
    )
     const showImgNote = (
        <div className="row mt-4 mb-4 justify-content-center"> 
        <div className="col col-12 col-sm-10 col-md-9">
        <div className="row">
            {getProduct.note && getProduct.note.length > 0 && getProduct.note.map((k, j) => (
                !k.tilte && (
                    <div key={j} className="container m-3">
                        <img

                            src={`${elakay_API}/products/readNoteGalerie/${k._id}/${j}`}
                            alt={"elakay note Images"}
                            style={{ height: "100%", width: "100%" }}
                        />
                    </div>
                )
            ))
            }
        </div></div></div>
    )

const checkFilterPrice =(filPrice, prodPrice)=>{
    let checkprice = false
    if (filPrice === undefined){
        checkprice = true
    }else if (filPrice === null) {
        checkprice = true
    }else if (filPrice <= 0) {
        checkprice = true
    }else {
        checkprice = false
    }
     
   return checkprice ? prodPrice : filPrice
}
const priceProd = {price: checkFilterPrice(oneFilterPro.priceFil,  getProduct.price), salePrice: getProduct.salePrice, count}
const stock = (a) => {
  if (a == 0) {
        return <h6 className="text-danger">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart1}</h6> 
       }
       else if (a < 20) {
        return <h6 className="text-danger"> {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart2} {a} {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart3}</h6>
       }
        else if (a >= 20) {
        return <h6 className="text-success"> {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart4}</h6>
        }}

        const handleChange = event => {
            if (event.target.value >= 1) {
                setcount(event.target.value)
            }
        };
        const buttonPLus = () => {
            let newCount = count 
            newCount ++
            setcount(newCount)
        }
    
        const buttonMimus = () => {
             let newCount = count 
               newCount --
            if (newCount  >= 1) {     
                setcount(newCount)
            }
        }
        const inputCart = () => {
            return (
                <div className="input-group mb-2" style={{ width: "150px" }}>
                    <div className="input-group-prepend">
                        <span className="input-group-text" onClick={buttonMimus}><FaMinus /></span>
                    </div>
                    <input
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                        min="1"
                        max="99"
                        aria-label="Amount (to the nearest dollar)"
                        value={count}
                    />   
                    <div className="input-group-append">
                        <span className="input-group-text" onClick={buttonPLus}><FaPlus /></span>
                    </div>
                </div>)}

const cartItemsPRo = [{
    _id: productID,
    name: getProduct.name,
    email: getProduct.email,
    urlImg: images[0],
    filterPro: oneFilterPro,
    price: getProduct.price,
    shipping: getProduct.shipping,
    sold: getProduct.sold,
    taxProduct: getProduct.taxProduct,
    count,
    quantity,
    salePrice: getProduct.salePrice,
    categoryID,
    addressShop,
    // parcel: getProduct.,
    // productOrner: getProduct.owner._id,
    UserAdmin: getProduct.AdminTeam ? getProduct.AdminTeam : "62913e015572d50ef95fb77a",
    // shipId: rates.shipment,
    // rateShipId: rates.object_id,
    // provider: rates.provider
    }]

    const cartItemsPRoNew = [{
        productID: productID,
        urlImg: images[0],
        filterPro: oneFilterPro._id,
        price: checkFilterPrice(oneFilterPro.priceFil,  getProduct.price),
        sold: getProduct.sold,
        count,
        salePrice: getProduct.salePrice,
        taxprodZone,
        paymentIntent:{
            customer: infoSource.userInfo.StripeCustomerId,
            payment_method: null,
        }
        }]

const DataProFilt = {
filArrName,
setOneFilterPro,
setALLFilterPro,
restAllFilterPro,
filterProduct : allFilterPro,
filter : getProduct.filter

}
// <SwiperProdView images={images}/>
// const OPTIONS = {}
// const SLIDE_COUNT = images && images.length
// const SLIDES = Array.from(Array(SLIDE_COUNT).keys())
    const descrip = (
        <>
        <p>{getProduct.description}</p>  
        {descriptionProduct}
        </>
    )
  const productView = () => {
   return itemUnAvailable ?
   <>
    <img
        src={Empty}
        alt={"empty image"}
        style={{ height: "100%", width: "100%" }}
    />
    </>
    :
<>
<div className="row mt-3">
<div className="col col-12 col-md-6 col-lg-5">
  {images && images.length > 0 && <EmblaCarousel 
slides={images} 
options={{}}
/>}

</div>
<div className="col col-12 col-md-6 col-lg-4">

<table
className ={"table table-sm"}
>
    <tbody>
        <tr><th>{etoiteProduct}</th></tr>
        <tr><td>{<h5>{getProduct.name}</h5>}</td></tr>
        <tr><td>{getProduct.description}</td></tr>
        <tr><td><Price showAddToCartButton={false} products={priceProd}/></td></tr>
        <tr><td>{stock(quantity)}</td></tr>
        <tr><td>{inputCart()}</td></tr>
    </tbody>
</table>
<PrevviewProFilt
infoSource ={infoSource}
setQuantity={setQuantity}
galeries={galeries}
setImages={setImages}
oneFilterPro={oneFilterPro}
setOneFilterPro={setOneFilterPro}
setALLFilterPro={setALLFilterPro}
restAllFilterPro={restAllFilterPro}
allFilterPro = {allFilterPro}
filter ={getProduct.filters}
getQueryFil={getQueryFil}
setNewAP={setNewAP}

/>

</div>
<div className="col col-12 col-md-12 col-lg-3">
        <Checkout
            Saddress={Saddress}
            setSAddressFinal={setSAddressFinal}
            // taxprodZone={taxprodZone}
            addressShop={addressShop}
            addressSel={infoSource.address}
            products={cartItemsPRoNew}
            quantity={quantity}
            count={count}
            parcel={parcel}
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
        />
<button type="button" className="btn btn-md btn-info btn-block p-1 mt-2" onClick={addToCart}> <span style={{marginRight:"9px", fontSize:"20px"}}><MdAddShoppingCart /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addCart}</button>
</div>
</div>
<div className="row m-3 justify-content-center" >
    <div className="col col-12 col-md-11 col-lg-11 col-xl-7 ">
        <TabsElakay
        title={"details"}
        >
        {tabElakay(filterDetail(), "details", "Details")}
        {tabElakay(descrip, "description", "Description")}

        </TabsElakay>
   
</div>
</div>
</> 
  }
const ProductPageView =()=>(
    <div>
        {productView()}
         { categoryID &&  <Products
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                categoryID={categoryID}
                SliderPunShow={true}
                showB={false}
            />}
        {getProduct.owner && getProduct.owner._id &&  <LakayUser
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                addressShop={addressShop}
                userID={getProduct.owner._id}
                showB={false}
            />}
       {commentsPro && <CommentaireUser
                infoSource={infoSource}
                skip={skip}
                limit={limit}
                voteA={getUserVote} 
                comments={commentsPro}
                productId={productID}
                token={token}
                setCommentsPro={setCommentsPro}
                SetSizeComments={SetSizeComments}
                setUserVote={setUserVote}
                setShowComments={setShowComments}
                commentProduct={commentProduct}
                loadProductsView={loadProductsView}
                ButtonAddComments={ButtonAddComments}
                showComments={showComments}
            />}
            {showImgNote}
    </div>
)
const printListeShop = () => (
    <div className="contaire">
        <div className="row justify-content-center " >
            <Modal
                size="xl"
                show={showComments}
                onHide={() => setShowComments(false)}
                // dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      {InfoApiTilte.messageElakayAdmin}
      </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                { <AddCommentProduct
                                showComments={showComments}
                                ButtonAddComments={ButtonAddComments}
                                infoSource ={infoSource}
                                ProductsViewload={loadProductsView}
                                comments={commentsPro}
                                userInfo={infoSource.userInfo}
                                voteA={getUserVote}
                                productId={productID} 
                                setShowComments={setShowComments}
                                sizeComments={sizeComments}
                                commentProduct={commentProduct}
                                setUserVote={setUserVote}
                                setCommentsPro={setCommentsPro}
                                SetSizeComments={SetSizeComments}
                                size={commentsPro.length}
                                skip={skip}
                                limit={limit}
                                setSkip={setSkip}
                                />
                                }
                </Modal.Body>
            </Modal>
        </div>
    </div>
)


    return (
        <Layout
            className="container-fluid"
            SliderPunShow={false}
            infoSource ={infoSource}
             loading={loading}
        >
            <div style={infoSource.styleNav}>
          {ProductPageView()}
        {printListeShop()}

          <NewProduct
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    readUser={infoSource.readUser}
                    showB={false}
        /></div>
      </Layout>
    );

}

export default ProductPreview