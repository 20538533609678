import React, { useState, useRef } from 'react';



const Filter_searchProductUser = (props) => {
 const [isFocused, setIsFocused] = useState(false);
const { infoSource } = props

    const searchInputStyle = {
        position: "absolute",
        top: "30%",
        left: "65%",
        transform: "translate(-50%, -50%)", // Centers the input in the navbar
        width: "60%", // Width of the input
        padding: "8px 12px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        outline: "none",
        backgroundColor: isFocused ? "#f9f9f9" : "#fff",
        transition: "all 0.3s ease-in-out",
        boxShadow: isFocused ? "0 0 5px rgba(0, 0, 0, 0.2)" : "none",
      };
    

    return (
        <>
          <div className="btn btn-dark btn-md py-1 rounded-4">
                    {/* Filters */}
                    {infoSource.language(infoSource, "title", "filters")} 
          </div>  
              {/* Barre de recherche */}
              <input
            type="text"
            placeholder="Search..."
            style={searchInputStyle}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

        </>
    );
};

export default Filter_searchProductUser;
