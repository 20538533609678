import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { elakay_API } from "../../config";
import { Row } from 'react-bootstrap';
import {GrView} from "react-icons/gr";
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import CardAllcategories0 from "./cardAllcategories0";

const CardAllcategories = (props) => {
const { 
    laguangeElakay,
    infoSource,
    ButtonViewCategories,
    setLevel,
    level
} = props
let StyleMenu0 = {
    borderBottom: "solid Gainsboro",
    backgroundColor: "GhostWhite"
}

let StyleMenu = {
    borderBottom: "solid Gainsboro",
    backgroundColor: "SkyBlue"
}
const styleFonc =(check)=>{
    return check ? StyleMenu : StyleMenu0
}

return (
  <>
  <div className="container-fluid" id='elakayRowl'>
    <Row xs={2} sm={2} md={3} lg={3} xl={4}>
    {level.level_0.ar1 && level.level_0.ar1.map((m,i) =>(
        <>
                        <div className="col p-2" key={i}>
                            <div className="card" >
                           
                                <div className="card mx-auto viewPage"> 
                                <Link to={`/product/${m.supCategoryID._id}`}>
                                    {/* <span className="card-title" style={{ fontSize: "50px" }}> <BsGridFill /></span> */}
                                  
                                    <span><h3>
                                        {/* View Page */}
                                        {infoSource.laguangeElakay('title', infoSource.languageAPI).viewPage}
                                        </h3></span>
                                    <img
                                        className="rounded"
                                        src={`${elakay_API}/pubR/${m.supCategoryID._id}`}
                                        onError={event => {
                                        event.target.src = imgProductLakay
                                        event.onerror = null
                                                    }}
                                    alt={m.supCategoryID.name}
                                    style={{ height: "100%", width: "100%" }}
                />
                                </Link>
                                </div>
                                
                                <div style={ styleFonc(level.id === m.supCategoryID._id)} className="card text-center p-1 viewCategories" > <span onClick={()=>ButtonViewCategories(level.level_0.ar, m.supCategoryID, m.supCategoryID._id )} ><h5 ><GrView/></h5></span> <h5>
                                    {/* {m.supCategoryID.name[0]} */}
                                    {infoSource.getCategoryNames(m).name[0]}
                                    </h5></div>
                            </div>
                        </div>
                        </>
                    ))
                }
    </Row>
    </div>

{ level.level_1 && <div className="container-fluid" id='elakayRowl'>
< CardAllcategories0
laguangeElakay={laguangeElakay}
infoSource ={infoSource}
setLevel={setLevel}
level={level}
/>
    </div>}
    <div className="container-fluid" id='elakayRowl'>
    <Row xs={2} sm={2} md={3} lg={3} xl={4}>
    {level.level_0.ar2 && level.level_0.ar2.map((m,i) =>(
        <>
                        <div className="col p-2" key={i}>
                            <div className="card">
                           
                                <div className="card mx-auto viewPage"> 
                                <Link to={`/product/${m.supCategoryID._id}`}>
                                    {/* <span className="card-title" style={{ fontSize: "50px" }}> <BsGridFill /></span> */}
                                  
                                    <span><h3>
                                        {/* View Page */}
                                        {infoSource.laguangeElakay('title', infoSource.languageAPI).viewPage}
                                        </h3></span>
                                    <img
                                        className="rounded"
                                        src={`${elakay_API}/pubR/${m.supCategoryID._id}`}
                                        onError={event => {
                                        event.target.src = imgProductLakay
                                        event.onerror = null
                                                    }}
                                    alt={m.supCategoryID.name}
                                    style={{ height: "100%", width: "100%" }}
                />
                                </Link>
                                </div>
                                
                                <div style={ styleFonc(level.id === m.supCategoryID._id)}  className="card text-center p-1 viewCategories"> <span onClick={()=>ButtonViewCategories(level.level_0.ar, m.supCategoryID, m.supCategoryID._id )}><h5 className=""><GrView/></h5></span> <h5>
                                    {/* {m.supCategoryID.name[0]} */}
                                    {infoSource.getCategoryNames(m).name[0]}
                                    </h5></div>
                            </div>
                        </div>
                        </>
                    ))
                }
    </Row>
    </div>
  </>   
);
};


export default CardAllcategories;