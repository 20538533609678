// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-to-Deal{
    /* height: 30%; */
    width: 75%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 50%;
    right: 50%;
     /* min-width: 400px; */
   
    z-index: 4000;
    transform: translate(-100%);
    transition: transform 0.3s ease-out ;
}

.add-to-Deal.open{
   transform: translate(50%, -50%);
}
.add-to-Deal li{
    margin:0.5rem 0;
}

.add-to-Deal ul {
    height: 100%;
    list-style: none ;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.add-to-Deal a{
    color: #521751;
    text-decoration: none;
    font-size:1.3rem ;
}

.add-to-Deal a:hover,
.add-to-Deal a:active{
    color: #fa923f
}`, "",{"version":3,"sources":["webpack://./src/includes/Dwawer/addTodeal.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;IACV,iBAAiB;IACjB,0CAA0C;IAC1C,eAAe;IACf,QAAQ;IACR,UAAU;KACT,sBAAsB;;IAEvB,aAAa;IACb,2BAA2B;IAC3B,oCAAoC;AACxC;;AAEA;GACG,+BAA+B;AAClC;AACA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;;IAEI;AACJ","sourcesContent":[".add-to-Deal{\n    /* height: 30%; */\n    width: 75%;\n    background: white;\n    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);\n    position: fixed;\n    top: 50%;\n    right: 50%;\n     /* min-width: 400px; */\n   \n    z-index: 4000;\n    transform: translate(-100%);\n    transition: transform 0.3s ease-out ;\n}\n\n.add-to-Deal.open{\n   transform: translate(50%, -50%);\n}\n.add-to-Deal li{\n    margin:0.5rem 0;\n}\n\n.add-to-Deal ul {\n    height: 100%;\n    list-style: none ;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n.add-to-Deal a{\n    color: #521751;\n    text-decoration: none;\n    font-size:1.3rem ;\n}\n\n.add-to-Deal a:hover,\n.add-to-Deal a:active{\n    color: #fa923f\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
