import React from "react";
import { Link} from "react-router-dom";
import Showimage from './ShowImage'
import imgProductLakay from '../Layout/imgs/md3.jpg'
import Vote from './commentaire/vote'
import moment from "moment";
import { isAuthenticated, updateItem, removeItem } from "../auth";
import { MdDelete} from "react-icons/md";
import { FaPlus, FaMinus} from "react-icons/fa";
import { FiDelete } from "react-icons/fi";
import Price from "./productsPrice"
import ShippingCart from "./shippingCart";
import {taxProductArea} from "../auth/apiProduct";
 
const Card = ({
    product,
    laguangeElakay,
    infoSource,
    loadingTrakingOder,
    orderURL,
    feetShip = 1.3,
    setShowTrakingOrder,
    setTrackingOrder,
    setShowItermOrder,
    bnt = false,
    bntOderView 
}) => {

    const sum = (total, num) => (
        total + Math.round(num)
    )

    const sumVote = (
        product.products.productID &&  product.prod_vote.map((p) => p.voteNum)
    )
    const rating = product.products.productID && sumVote.reduce(sum, 0) / product.prod_vote.length

    const showVote = rating > 0 ? true : false

    const voteView = (
        showVote && (
            <div style={{ bottom: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255,0.1)", width: "100%", height: "25px", fontSize: "18px" }}> <Vote rating={parseFloat(rating)} /> </div>

        )
    )
    const checkQuantity = (Q)=>{
        return  Q === 0 ? 0 : 1
        }
        const CheckTaxZone = (tax)=>{
            return  tax ? tax.EstimatedCombinedRate : 0.088750
            }

        
    const ItemsOrder = () => {
        return product && product.products && [product.products].reduce((currentValue, nextValue) => {
            return currentValue + (nextValue.count * (nextValue.price * (100 - nextValue.salePrice)/100))
        }, 0);
    };


    const ShippingOrder = () => {
        return product && product.products && [product.products].reduce((currentValue, nextValue) => {
            return product[0].shipping ? 0 : currentValue + (5 * feetShip)
        }, 0);
    };

    const TaxOrder = () => {
        return product && product.products && [product.products].reduce((currentValue, nextValue) => {
            return currentValue + (checkQuantity(nextValue.quantity) * nextValue.count *(nextValue.price * (100 - nextValue.salePrice) / 100) * CheckTaxZone(nextValue.taxprodZone))
        }, 0);
    };

    const getTotal = () => {
        let a = ItemsOrder()
        // let b = ShippingOrder() ? ShippingOrder(): 0
        let c = TaxOrder()
        let d = a  + c
        return parseFloat(d).toFixed(2) 
    };

    const statusStyles = {
        "Not processed": { backgroundColor: "gray", color: "white" },
        "Processing": { backgroundColor: "blue", color: "white" },
        "Shipped": { backgroundColor: "orange", color: "black" },
        "Delivered": { backgroundColor: "green", color: "white" },
        "Cancelled": { backgroundColor: "red", color: "white" },
        "Return or replace": { backgroundColor: "purple", color: "white" },
        "Replace": { backgroundColor: "teal", color: "white" }
    };

    const statusStyles1 = {
        "Not processed": { borderStyle: "solid",  borderColor: "gray" },
        "Processing": { borderStyle: "solid",  borderColor:"blue" },
        "Shipped": { borderStyle: "solid",  borderColor: "orange" },
        "Delivered": {borderStyle: "solid",  borderColor: "green" },
        "Cancelled": { borderStyle: "solid",  borderColor: "red" },
        "Return or replace": { borderStyle: "solid",  borderColor: "purple" },
        "Replace": {borderStyle: "solid",  borderColor: "teal" }
    };

    const getStyles = (status) => {
        return statusStyles[status] || { backgroundColor: "white", color: "black" }; // Couleurs par défaut
    };
   
    const getStyles1 = (status) => {
        return statusStyles1[status] || {  borderStyle: "nome",  borderColor: 'black' }; // Couleurs par défaut
    };

    return (
        <div className="card mt-2" style={{  borderStyle: "solid",  borderColor: "red" , minHeight:"134px"}}> 
            <div className="row">  
                <table >
                    <tbody>
                    
                    <tr className="row" >
                <td className="col-3 col-ms-4" style={{ maxWidth: "200px" }}>
                   
                <img
                    className="card-img-top"
                    src={ product.products.urlImg}
                    onError={event => {
                        event.target.src = imgProductLakay
                        event.onerror = null
                      }}
                    // alt={nameImg}
                    style={{width: "100%" }}
                />
                </td>
                
                        <td className="col-5 ">
                          <table>  
                              <thead>
                              <tr>
                        <th colSpan={2}>
                            <span style={{fontSize:"16px"}}>{product.products.productID && product.prod_name.substring(0, 25)}...</span><br/>
                             
                                    </th>
                        
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                            {/* <span style={{fontSize:"12px"}}>{allfilter(product.proFilter.filter)}</span>   */}
                                    </td>
                            </tr>
                            <tr>

                            {/* <td>{infoSource.laguangeElakay('label', infoSource.languageAPI).items}: </td> */}
                                 <td>

                                <Price
                                    showAddToCartButton={false}
                                    products={product.products}
                            />
                 {/* <h6>{product.products.status}</h6> */}
                            <p className="p-0 m-0"> <span style={{ color: "#ff0000" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).amount} {parseFloat(getTotal()).toFixed(2)}</span></p>
                         </td>
                    </tr>
                    <tr>
                                <td>{voteView}</td>
                    </tr>
                    <tr>
                         {/* <td>{stock()}</td> */}
                    </tr>
                    <tr>
                                       <td>
                                       <span 
                                       
                                       style={{
                                        ...getStyles(product.products.status),
                                        fontSize:"10px",
                                        padding: "3px",
                                        margin:"3px",
                                        borderRadius: "9px",
                                        fontWeight: "bold",
                                        display: "inline-block" }} 
                                    // className="p-1 m-0"
                                       >{infoSource.laguangeElakay('title', infoSource.languageAPI).orderer}{" "}
                                {moment(product.createdAt).fromNow()}{" "}{product.products.status}
                                </span>   
                                            </td>
                    </tr>
                    {/* <tr>
                                       <td>
                                Shipping information   
                                            </td>
                    </tr> */}
                    <tr>
                                       <td>
                            { !bnt && <div className="my-1 py-1 btn btn-outline-dark btn-md rounded-4" onClick={()=>{setShowTrakingOrder(true); setTrackingOrder(product.products.info && product.products.info.urlTracking ? product.products.info.urlTracking: null)}}>
                                {infoSource.laguangeElakay('title', infoSource.languageAPI).tchakingnumber}
                                {/* tchaking number */}
                                </div> }
                                            </td>
                    </tr>
                                    </tbody>
                    </table>
               </td>
               <td className="col-4 col-ms-3">
               
               <div className="mb-3 mr-3">
               
               {bnt && product.products.status == "Not processed" ?
                  <Link className="my-3 py-1 btn btn-outline-dark btn-md  rounded-4 float-right"  onClick={()=> bntOderView(product.orders_id, product.products.productID, product.products._id)}> {infoSource.laguangeElakay('title', infoSource.languageAPI).checkout}</Link>
                  : 
                  <Link className="my-3 py-1 btn btn-outline-dark btn-md  rounded-4 float-right"  onClick={()=> bntOderView(product.orders_id, product.products.productID, product.products._id)}> {infoSource.laguangeElakay('Button', infoSource.languageAPI).viewOrder}</Link>
               }
                </div>
                </td>
               
           </tr>
                    </tbody>
           </table>
           </div>
             </div>
       
    );
};

export default Card;