import React, { useState } from "react";
import { Link,  useNavigate} from "react-router-dom";
import { list, getImages } from "../auth/apiProduct";
import Loader from "../Layout/loader.gif";
import { elakay_API } from "../../config";

import '../Layout/Header.css';

const Search = (props) => {
    const [searchShow, setSearchShow] = useState(false);
    const navigate = useNavigate(); // Hook pour la navigation
    const [searchVar, setSearchVar] = useState([]);
    const [search, setSearch] = useState([]);
    const [error, setError] = useState("");
    let limit = 10
    let skip = 0
const {
infoSource
} =props
    const searchData = (search) => {
        if (search) {
            list(skip, limit, search).then(
                response => {
                    if (response.error) {
                        setError(response.error)
                        console.log(response.error);
                    } else {
                        setSearch(response);
                    }
                }
            );
        }
    };

    const handleChange = name => event => {
        let val = event.target.value
        setError(false)
        // setSearch({ [name]: event.target.value });
        
        if (val.length === 0) {
            searchData("undefini")
        } else {
            setSearchShow(true)
            setSearchVar(val)
            searchData(val)
        }

    };
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    const loadImages =(Id)=> {

     return  getImages(Id).the
       
            }
    
            const directionSearch = (s, i)=>{
                    setSearchShow(false); // Cache l'affichage
                    window.location.href = `/search?ref=${s}&ci=${i}&Submit=ok`;
            }

    const loadingCategoyName = (search) => {
        return (
             <div className="card loadind_search ">
                {search.data && search.data.map((product, i) => (

                    <Link key={i} onClick={()=>directionSearch(searchVar, product.categoriesID[0].catID)}>
                        <h5  style={{ borderBottom: '1px solid  rgba(0, 0, 0, 0.1)'}} >
                            <span className="m-1"  style={{fontSize: '14px'}}>{product.name.substring(0, 20)}...</span>
                            <span className="float-right">
                                <img
                                    className="rounded pb-1"
                                    src={product.galeriesID.galeries[0].urlImg}
                                    alt={"images search"}
                                    style={{ height: "37px", width: "37px" }}
                                /> 
                            </span>
                        </h5>
                    </Link>
                ))}
                {showError()}
            </div>
        );
    };
    const searchForm = () => (
        <div>
            <form action={"/search"} >

                <div className="elakay_search">

                    <input
                        id="txtSearch"
                        className="elakay_input_search"
                        onChange={handleChange("search")}
                        type="search"
                        placeholder={infoSource.language(infoSource, "headNenu", "searchProd")}
                        name="ref"
                    />
                    <button className="elakay_button_search" name="Submit" value="ok" >
                        <svg width="17px" height="17px">
                            <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467 "></path>
                        </svg>
                    </button>

                </div>
                {searchShow && loadingCategoyName(search)}
            </form>
        </div>
    );

    return (
        <div>
            {searchForm()}

        </div>
    );
};

export default Search;
