import React  from "react";
import Accordion from 'react-bootstrap/Accordion'

const Checkbox = (props) => {


    const { 
        infoSource,
        handleFilters,  
        filters =[],
        checked, 
        setCheked
    } = props

    const handleToggle = (c, d)=> () => {
        let a 
        let e 
        let f 
        checked.forEach((k, i)=>{
            if(k.name === d){
        return a = i
            }

        })
        
        const b = [...checked];
        if (a !== undefined) {
            e = b[a].value.indexOf(c); 
            if(e === -1){
            b[a].value.push(c);
            }else{
            b[a].value.splice(e, 1);
            }
            f = b[a].value
        } else {
           
            b.push({name:d, value:[c]});
            let g = b.length -1
            f = b[g].value 
        }
       

        setCheked(b);
        handleFilters(f,  `filterProduct.filter.${d}`); 
    };

    const checInputFil =(a, b)=>{
       return a && a.map((c, j) => (
           <li key={j} className="list-unstyled">
                <input
                onChange={handleToggle(c[0], b)}
                type="checkbox"
                 id={c + "_" + b}
                className="form-check-input check2"
                />
        <label className="form-check-label ml-2" for={c + "_" + b}>{c[1]}</label>
        </li>
        ))
    }

        const FilChekboxForm = ()=>{ 
            
           return filters && filters.length > 0 && filters.map((k ,i)=>{ 
            let translFil = infoSource.getFilTranslate(k)
            let fil = k._id.name? k._id : k 
            let Filters = k.Filters
           return <Accordion.Item  key={i} eventKey={i + 8486} >
                
                    <Accordion.Header>
                    {translFil.name}
                    </Accordion.Header>
            
                            <Accordion.Body>
                        <div style={{overflow: "auto" }}>
                        <ul>

                        {checInputFil(translFil.FilterItem, fil.name, fil)}
                        {/* {Filters.length === 0 ?
                            checInputFil(fil.FilterItem, fil.name, fil)
                            :
                            checInputFil(fil.Filters ,fil.name, fil)
                        }            */}
                       </ul>

                        </div>
                            </Accordion.Body>
                    </Accordion.Item>
        })
                }

return FilChekboxForm()

};

export default Checkbox;