import React, { useState, useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import { VoteStart } from './voteStartCount'
import {MdInsertComment, MdStar } from "react-icons/md";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { Like_disLike } from './voteLike'
import Table from 'react-bootstrap/Table';
import ButtonPagination from "../ButtonPagination";
import Vote, {ratingVote} from './vote'
import {
    userDislikeCommentsProduct,
    userLikeCommentsProduct
} from "../../auth/apiProduct";

const UserComment =(props)=>{

    const {
        infoSource,
        skip, 
        limit,
        voteA,
        setCommentsPro,
        siseProduct,
        commentProduct,
        SetSizeComments,
        setUserVote,
        comments= [],
        productId,
        token,
        loadMore,
        loadMore0,
        ButtonProductPreview,
        size,
        ButtonBool = true,
    } = props

    // const [VoteNumSt, setVoteNumSt] = useState();
    // const [CoUsSt, setCoUsSt] = useState(false);
    // let voteRating = voteA && ratingVote(voteA)

    const voteDisLike = comentID => {
        let id = productId
        token && (
            userDislikeCommentsProduct(token, id, comentID).then(data => {
                commentProduct(id,  skip, limit).then(data => {
                    if (!data) {
                        console.log(data.error);
                      
                    } else {
                        setCommentsPro(data.commentsPagination)
                        SetSizeComments(data.commentsCount[0].totalComments)
                        setUserVote(data.userVote[0].vote)
                    }
                    })

            }).catch(error => {
                console.log(error);
            })
        )
    }

    const voteLike = comentID => {
        let id = productId
        token && (
            userLikeCommentsProduct(token, id, comentID).then(data => {
                commentProduct(id,  skip, limit).then(data => {
                    if (!data) {
                        console.log(data.error);
                      
                    } else {
                        setCommentsPro(data.commentsPagination)
                        SetSizeComments(data.commentsCount[0].totalComments)
                        setUserVote(data.userVote[0].vote)
                    }
                    })

            }).catch(error => {
                console.log(error);
            })
        )
    }
    const BoutonLikeAndDislike = (k) => (
        <div className="mt-0 pt-0">
            <p className="btn mr-2 pl-2 pr-2 pt-1 pb-1" style={{ fontSize: "14px" }} onClick={() => (voteDisLike(k._id))}><AiFillDislike /><span style={{ fontSize: "12px", color: "gray" }}>({Like_disLike(k.Like, false)})</span></p>
            <p className="btn mr-2 pl-2 pr-2 pt-1 pb-1" style={{ fontSize: "14px" }} onClick={() => (voteLike(k._id))}><AiFillLike /><span style={{ fontSize: "12px", color: "gray" }}>({Like_disLike(k.Like, true)})</span></p>
        </div>
    )
    const userName =(o)=>{
        
        let first1 = String(o.firstname).charAt(0)
        let last1 = String(o.lastname).charAt(0)
        let FL = first1.concat(last1).toLocaleUpperCase()
    return o.username? o.username : FL
    }
    const voteUser = voteU => (
        <Vote rating={voteU} />
    )
    const commentaireUser = (
        <div>
            <div className="row justify-content-center " >
                <div>
{/* 
                    <div className="row m-3 pb-2" style={{ textAlign: "center", border: "1px solid WhiteSmoke" }} >
                        <div className="col col-12 col-md-12" style={{ backgroundColor: "WhiteSmoke" }}>
                            <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).customerReviews}</h3>
                        </div>
                      
                    </div> */}
                    {
                      comments &&  comments.map((k, i) => (
                    <div key={i} className="row m-3" style={{ borderBottom: "1px dotted Silver" }}>
                        <div>
                            <div className="m-0 p-0" style={{ fontSize: "18px" }}>
                                <FaUserAlt />
                                <span style={{ fontSize: "14px" }}>{userName(k)}</span><br />
                                <span className="m-0 p-0" style={{ fontSize: "16px" }}>
                                    {voteA.map((p, o) => (
                                        <div key={o}>
                                            {k.user === p.userId && (
                                                voteUser(p.voteNum)
                                            )}
                                        </div>
                                    ))}
                                </span>
                            </div>
                            <p className="m-0" style={{ font: "15px Arial, sans-serif" }}>{k.title}</p>
                            <p className="m-0" style={{ fontSize: "12px" }}> {k.description}</p>
                            {BoutonLikeAndDislike(k)}

                        </div>
                        
                    </div>
                ))
                        }

                </div>
       {ButtonBool &&    < ButtonPagination
                infoSource ={infoSource}
                size={size}
                skip={skip}
                limit={limit}
                siseProduct={siseProduct}
                loadMore={loadMore}
                loadMore0={loadMore0}
                buttonClassL = 'my-3 py-1 btn btn-primary p-2 float-left btn-md rounded-4'
                buttonClassLD = 'my-3 py-1 btn p-2 float-left btn-md rounded-4'
                buttonClassR = 'my-3 py-1 btn btn-primary p-2 float-right btn-md rounded-4'
                buttonClassRD = 'my-3 py-1 btn p-2 float-right btn-md rounded-4'
            />}
            </div>
           {ButtonBool &&  <button className="btn btn-primary float-left m-3" onClick={()=>ButtonProductPreview()}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>}
        </div>

    )


    return (
            <>
            {commentaireUser}
            </>
    );

}

export default UserComment;