import React from "react";
import {GrClose} from "react-icons/gr";

const CatSelected = (props) => {
const {
    clickButton2,
    ButtonBackCategory,
    categoyseleted,
    seleteCategory,
    laguangeElakay,
    infoSource,
    categories
} = props
const calculateFontSize = (index, totalElements) => {
    const maxSize = 18;
    const minSize = 12;
    const sizeStep = (maxSize - minSize) / (totalElements - 1);
    return maxSize - (index * sizeStep);
  };

const selectProduct = () => (
    <form className="mb-3">

       
        <div className="form-group">
        
            <div className="boxCategory1 text-center " id="heading">
            <p>
      {Array.isArray(categories.name) && categories.name.map((word, index) => (
        <span 
          key={index} 
          style={{ fontSize: `${calculateFontSize(index, categories.name.length)}px` }}
        >
          {word}{index < categories.name.length - 1 ? ', ' : '.'}
        </span>
      ))}
    </p>
                
            </div>
            <select value={0} onChange={seleteCategory()} className="form-control">
                <option>{infoSource.laguangeElakay('label',  infoSource.languageAPI).selectProdSell}</option>
                {categories.items && categories.items.map((c, i) => (
                    <option key={i} value={c.supCategoryID._id}>

                        {/* {c.supCategoryID.name[0]} */}
                        {infoSource.getCategoryNames(c).name[0]}
                    </option>
                ))}
            </select>
        </div>
    </form>
    )


 return selectProduct()
}
export default CatSelected;

// categories.name
// elakayCategories
// elakayLanguage
// getElakayLang