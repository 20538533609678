import React, { useState, useEffect } from "react";
import { Link, useNavigate} from "react-router-dom";
import { elakay_API } from "../../config";
import Vote from './commentaire/vote'
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import Loader from "../Layout/loader.gif";
import Modal from 'react-bootstrap/Modal'
import ModalProdView from "../pageInside/modalProductView"
import ShopUserView from "../pages/lakayUser/ShopUser";
import { getCategoriesByID} from "../auth/apiCategory";
import {getProductPreview,  commentProduct, getImages} from "../auth/apiProduct";
import {UserVoteCount}from "./commentaire/userVoteCount"
import {GrView} from "react-icons/gr";
import { FaUser,  FaStore, FaUsersBetweenLines} from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";

const linkToProdView = (x, y)=>{
    if(x.a){
        x.b(x.c)
    }else{
    window.location.href = `/product/preview/${y.a}${y.c}`;
    }

}


function isValid(variable) {
    if (variable === null || variable === undefined || variable === "") {
        return false;
    }
    if (Array.isArray(variable) && variable.length === 0) {
        return false;
    }
    if (typeof variable === "object" && Object.keys(variable).length === 0) {
        return false;
    }
    return true;
}

const ShowImage = ({
    infoSource,
    imglength,
    item,
    filterProduct,
    nbrUserVote, 
    productId,
    categoryId,
    token,
    showB = true,
    showShopV = true,
    urlImg ,
    rating,
    filterColor,
    owner_id,
    urlFilter,
    edit = false
    }) => {
        const [galeries, setImagSize] = useState([])
        // const [voteA, setVoteA] = useState([]);
         const [loading, setLoading] = useState(false);
        const [getShowShop, setShowShop] = useState(false)
        const [filters, setFilters] = useState([]);
        const [filter, setFilter] = useState([])
        const [values, setValues] = useState({}) 
        const [showListe, setShowListe] = useState(false);

        const [showComments, setShowComments] = useState(false)
        const [commentsPro, setCommentsPro] = useState([])
        const [sizeComments, SetSizeComments] = useState(0)
        const [limit] = useState(10);
        const [skip, setSkip] = useState(0);
        const [isLoading, setIsLoading] = useState(true); // État pour suivre le chargement

        const [getUserVote, setUserVote]= useState([])
        const [myFilters, setMyFilters] = useState({
                filters: {"categoriesID.gategoriesLink": [] }
            });
        
        let navigate = useNavigate()
    const loadProductsView = () => {
        setShowListe(true)
        getProductPreview(productId).then(data => {
            if (!data) {
                // setItemUnAvailable(true)
                console.log("data not Found")
            } else {
                loadCategory(data.product.filterProduct[0].catID)
                setImagSize(data.galeries)
                // setVoteA(data.vote)
                // setComments(data.comments)
                setFilter(filterProduct)
                setValues(data.product)
                ButtonAddComments()
            }
        })
    };

    const ButtonAddComments=(show= true)=>{
        show && setShowComments(false)
    commentProduct(productId, skip, limit).then(data => {
        if (!data) {
            console.log(data.error);
        } else {
            setCommentsPro(data.commentsPagination)
            SetSizeComments(data.commentsCount[0] && data.commentsCount[0].totalComments)
            setUserVote(data.userVote[0] && data.userVote[0].vote)
        }
        })
}


    const loadCategory = (catalogID) => {
        getCategoriesByID(catalogID).then(data => {
            if (!data) {
                console.log("data not found")
            } else {

                setFilters(data.filter)
            }

        }).catch(error => {
            console.log(error);
        });;
    };

    const nameImg = item?item:'elakay image'
    const showVote = rating > 0? true : false
    const voteView = (
        showVote && (
            <div style={{ position: "absolute", bottom: "0", magirn: "0", padding: "0", backgroundColor: "rgba(255,255,255, 0.38)", width: "100%", height: "25px", fontSize: "18px" }}><Vote rating={parseFloat(rating)} /> ({UserVoteCount(nbrUserVote)})</div>

        )
    )
    const ProductView = (
        showB && (
            <div style={{ position:'absolute',top: '2px',  left: '2px', width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "20px", color:"#007399"}}><GrView onClick={loadProductsView}/></div>

        )
    )

    const shopView = (
        showShopV && (
            <div style={{ position:'absolute',top: '2px', right: '2px', width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "20px", color:"#007399"}}><FaStore onClick={()=>setShowShop(true)}/></div>

        )
    )
  
    const loadingImg = () => (
        
        <img
        className="embla__slide__img"
            // className="card-img-top"
            src={Loader}
            onError={event => {
                event.target.src = imgProductLakay
                event.onerror = null
              }}
            alt={nameImg}
            style={{ height: "100%", width: "100%" }}
        />
    
    )

    const ShopViewModal = () => (
        <div className="container">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={getShowShop}
                    onHide={() => setShowShop(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
            <ShopUserView
             setLoading={setLoading}
             infoSource={infoSource}
            //  readUser={readUser}
            //  ShopName={ShopName}
            showFilter={false}
             userID={owner_id}
             myFilters={myFilters}
            />
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )

    const LoadingImage = (urlImg, Loader, imgProductLakay, nameImg, isLoading ) => {
    //   const [isLoading, setIsLoading] = useState(true); // État pour suivre le chargement
    
      return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          {/* Affiche le loader tant que l'image principale n'est pas chargée */}
          {isLoading && (
            <img
              src={Loader}
              alt="Loading..."
              style={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            )}
    
          {/* Image principale */}
          <img
            className="embla__slide__img"
            src={urlImg}
            onLoad={() => setIsLoading(false)} // Désactiver le loader une fois chargé
            onError={(event) => {
              event.target.src = imgProductLakay; // Affiche imgProductLakay en cas d'erreur
              setIsLoading(false); // Désactiver le loader
              event.onerror = null; // Éviter les boucles d'erreur
            }}
            alt={nameImg}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              display: isLoading ? "none" : "block", // Cache l'image principale pendant le chargement
            }}
          />
        </div>
      );
    };
    
let urlFil = urlFilter ? urlFilter : '';

let urlImage = edit? `/product/edit/${productId}`:`/product/preview/${productId}${urlFil}`

return( 
    <div style={{ position: "relative", overflow: "hidden", transition: "0.3s", width: "100%"}}> 
        {/* <Link to={urlImage}> */}
        <a href={urlImage} >
            {
            LoadingImage(urlImg, Loader, imgProductLakay, nameImg, isLoading)
            }
            {voteView} 
            </a>
       {/* </Link>  */}
            {ProductView}
            {shopView}
         {ShopViewModal()}

            <ModalProdView
                infoSource={infoSource}
                skip={skip}
                limit={limit}
                voteA={getUserVote} 
                comments={commentsPro}
                productId={productId}
                token={token}
                setCommentsPro={setCommentsPro}
                SetSizeComments={SetSizeComments}
                setUserVote={setUserVote}
                setShowComments={setShowComments}
                commentProduct={commentProduct}
                loadProductsView={loadProductsView}
                ButtonAddComments={ButtonAddComments}
                showComments={showComments} 

                setShowListe={setShowListe}
                image={urlImg}
                showListe={showListe}
                productV= {{galeries, filters, filter, values, urlImg, filterColor}}
                />
         </div>
)
    
};

export default ShowImage;


