import React, { useState, useEffect  } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import {activatorUserAdding, userAssUpRoleTrueRandom} from "../../auth";
import AddinfoUser from "../addUserPage/addinforUser";
import AddtelephoneUserAssociate from "../addUserPage/addTelephoneUser";
import AddAddressUserAssociate from "../addUserPage/addAdressUser";
import GetEditInfoUser from "../addUserPage/getEditInfoUser";
import Pages from '../LanguagePage'
import nodata from '../../Layout/imgs/nodata0.png';
import {laguangeElakay} from "../../pageInside/dataElakay"; 
import Modal from 'react-bootstrap/Modal';
import Timer from "../../pageInside/timer";
import ActivatorPageAss from "../addUserPage/activatorPageAss";
// import Active from "../../user/active";

const  UserAddingRoleByToken = (props) => {

    const {
        infoSource
        } = props
        let params = useParams();
        let token = params.token;
    const [loading, setLoading] = useState(false);
    const [roleUser,  SetRoleUser] = useState('customer')
    const [userLeve, getUserleve] = useState()
    const [activePageAss, setActivePageAss] = useState(false)
    const [messageExp, setMessageExp] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [planAfCh, setPlanAfCh] = useState(false);
    const [ternConCh, setTernConCh] = useState(false);
    const [agreUsIn, setAgreUsIn] = useState(false);
    const [agreeCh, setAgreeCh] = useState(false);
    const [roleNamePage, setRoleNamePage] = useState({
        plan: '',
        term:'',
        agree:''
    });
let userRolle = [
                {name: 'customer', leve: 1}, 
                {name: 'seller', leve: 2}, 
                {name: 'provider', leve: 3},
                {name: 'associate', leve: 4},
                {name: 'investment', leve: 5}, 
                {name: 'administrator', leve: 6},
                {name: 'family', leve: 7}]

const {
    plan,
    term,
    agree,
    role
} = roleNamePage

    let BnPlanAffaire = {
        planAffaire : true,
        termCondition: false,
        addInfoUser: false,
        userAgree: false
    }
    let bnTermCondition ={
    planAffaire : false,
    termCondition: true,
    addInfoUser: false,
    userAgree: false
    }
    let bnAddInfoUser={
    planAffaire : false,
    termCondition: false,
    addInfoUser: true,
    userAgree: false
    }

    let bnUserAgree ={
    planAffaire : false,
    termCondition: false,
    addInfoUser: false,
    userAgree: true
    }

    const [pages, setPages]= useState(BnPlanAffaire)

    const {
        planAffaire,
        termCondition,
        addInfoUser,
        userAgree,
        } = pages

        let bninfoPersonnel ={
            infoPersonnel: true,
            telephone: false,
            AddAdress:false
        }

        let bnTelephone = {
            infoPersonnel: false,
            telephone: true,
            AddAdress:false
            }

        let bnAddAdress ={
            infoPersonnel: false,
            telephone: false,
            AddAdress:true
            }

        const [pagesUser, setPagesUser]= useState(bninfoPersonnel)

        const { 
            infoPersonnel,
            telephone,
            AddAdress  
        } = pagesUser

        useEffect(() => { 
            chosingRolePage(roleUser)
            loadingUserAct(token, '', '', false, false)
        }, [token, roleUser]);

        // let  paragra0 =  !infoSource.loading &&  infoSource.languageAPI.elakayInfoAPI

        let telephone1 = {
            country: 'United States',
            abrCountry: 'US',
            ariaCode: '1',
            National: '(347) 781-3174',
            International: '+1 347 781 3174'
        }
        let userA = {firstname: "Yves", lastname: "Oxilus", password: 'userElakay2018' }

       let address = {
              firstName: 'intel',
              lastName: 'travail',
              shopName: null,
              tel: '+1 347 781 3174',
              Address1: '1091 Hancock st',
              Address2: '',
              city: 'Brooklyn',
              State: 'New York',
              postalCode: '11221',
              country: 'US'
            }

        const loadingUserAct = (token, key, value, save, relaod) => {
            activatorUserAdding(token, key, value, save)
                .then(data => {
                    if (data.error) {
                        setMessageExp(true)
                        console.log(data.error)
                    } else {  
                        setMessageExp(false)
                        checkAllInfoEnt(data)
                        setUserInfo(data)
                        SetRoleUser(data.userActiveRole.roleName)
                        getUserleve(data.userActiveRole.level)
                        chosingRolePage(data.userActiveRole.roleName)
                        relaod &&  setPagesUser(bninfoPersonnel)
                    }
                });
        };
        
        const chosingRolePage =(a)=>{
            if(a === 'customer'){
                setRoleNamePage({
                    plan: 'planUserRoyal',
                    term:'termConditionRoyal',
                    agree:'royalAgree',
                    role: a
                })
            }else if(a === 'seller'){
                setRoleNamePage({
                    plan: 'planUserSeller',
                    term: 'termConditionSeller',
                    agree: 'sellerAgree',
                    role: a
                })
            }else if(a === 'provider'){
                setRoleNamePage({
                    plan: 'planUserProvider',
                    term: 'termConditionProvider',
                    agree: 'providerAgree',
                    role: a
                })
            }else if(a === 'associate'){
                setRoleNamePage({
                    plan: 'planAffaireElakay',
                    term:'termConditionAssociate',
                    agree:'associateAgree',
                    role: a
    
                })
            }else if(a === 'investment'){
                setRoleNamePage({
                    plan: 'planAffaireInvest',
                    term:'termConditionInvest',
                    agree:'investmentAgree',
                    role: a
                })
            }else if(a === 'administrator'){
                setRoleNamePage({
                    plan: 'planAffaireAdmin',
                    term:'termConditionAdmin',
                    agree:'administratorAgree',
                    role: a
                })
            }else if(a ==='family'){
                setRoleNamePage({
                    plan: 'planAffaireFamily',
                    term:'termConditionFamily',
                    agree:'familyAgree',
                    role: a
                })
            }

        }

        const handleUserRole =()=> event =>{
            let userRole = event.target.value;
            chosingRolePage(userRole)
        }

            const bonPrev = (a)=>{
              return  <button onClick={()=> setPages(a)} className="btn btn-primary p-2 float-left" style={{ width: "120px" }}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).previous}
              </button>
            }
           const  bonNext = (a, t = true)=>{
               return t? <button onClick={()=> setPages(a)} className="btn btn-primary p-2 float-right" style={{ width: "120px" }}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).next}
             </button> : <button onClick={()=> setMessageExp(true)} className="btn btn-primary p-2 float-right" style={{ width: "120px" }}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).next}
             </button>
            }

            const allBon = (p, n, t)=>{
                return  <div className="row justify-content-center m-4">
                <div className="col col-sm-10">
                    <div className="bg clearfix ">
                        { p && bonPrev(p)}
                        {n && bonNext(n, t)}
                    </div>
                </div></div>
            }
            const checkObjEmpty =(obj)=>{
                if(Object.values(obj ? obj: []).length > 0){
                    
                    return true
                }else {
                    // setMessageExp(true)
                    return false
                }
                
                }

            const checkProdKeyExit = (ad)=>{
                let ent 
                if(Array.isArray(ad)){
                  let ac = ad[0]
                  ent = Object.keys(ac ? ac:{});
                  return ent.every(([key, val]) => ac[key] != undefined) && true
                } else{
                   ent = Object.keys(ad ? ad:{});
                   return ent.every(([key, val]) => ad[key] != undefined) && true
                }}
        
        
            const pageSteps = (a)=>{
                    if (!checkProdKeyExit(a)){
                       return <Pages  
                       classAlert ={'container'}
                        paragra0={a}
                    />
                    }else{
                      return <div> 
                      <div className="row mt-4 mb-4 justify-content-center">
                        <div className="col-11">
                      <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
                       </div>
                       </div></div>
                    }
                }


         const  planAffairePage =()=>{
                return planAffaire && <div>
                    {pageSteps(infoSource.laguangeElakay(plan,  infoSource.languageAPI))}
                    {  allBon(undefined, bnTermCondition)}
                    </div> 
            }     


            const  termConditionPage =()=>{
                return termCondition && <div>
                       {pageSteps(infoSource.laguangeElakay(term,  infoSource.languageAPI))}
                   
                    {  allBon(BnPlanAffaire, bnAddInfoUser, checkObjEmpty(userInfo))}
                    </div> 
            }
            
            const ckeckIfEdit =(k, AO, B )=>{
                if(k == 'userInfo'&& B){
                    if(AO.firstName != 'user' &&  AO.lastName != 'elakay') {
                     return true
                    } else if(AO.firstName != undefined && AO.lastName != undefined){
                        return true
                    }else{
                        return false        
                }
            } else if(k == 'telephone'&& B){
                if(AO.length == 0){
                    return true
                } else {
                    return false
                }  
            }else if(k == 'address'&& B){
                if(AO.length == 0){
                    return true
                } else {
                    return false
                }  
            }
        }
        
            const checkObjEmpty1 =(obj)=>{
               let arry = obj && Object.values(obj)
         
                }
                const resteAllData =(a)=>{
                    checkAllInfoEnt(a)
                    setUserInfo(a)

                }
                
                const checkAllInfoEnt = (obj)=>{
                    let emailTel = obj.telephoneSing ? obj.telephoneSing.International.replaceAll(' ', '')+'@elakay.com': undefined
                    const allowed = ['firstname', 'lastname', 'email']
                    const allowedTel = ['country', 'abrCountry', 'ariaCode', 'National', 'International']
                    const allowedAdr = ['firstName', 'lastName', 'tel', 'Address1', 'city', 'State', 'postalCode', 'country'] 
                    let val = obj['firstname'] == 'user'&& obj['lastname'] == 'elakay' && obj['email'] == emailTel
                    let valArr = allowed.every((ar)=>  obj[ar] != '' && obj[ar] != undefined)
                    let valTel = allowedTel.every((ar)=> obj['telephone'].length > 0 &&  obj['telephone'][0][ar] != '' || obj['telephone'].length > 0 &&  obj['telephone'][0][ar] != undefined)
                    let valAdr = allowedAdr.every((ar)=> obj['address'].length > 0 &&  obj['address'][0][ar] != '' || obj['address'].length > 0 && obj['address'][0][ar] != undefined)
                    !val &&  valArr &&  valTel &&  valAdr && setAgreUsIn(true)
                }

        const checkInfoUser = (obj, ele, pa, upt, ifu)=>{

            let emailTel = obj.telephoneSing ? obj.telephoneSing.International.replaceAll(' ', '')+'@elakay.com': undefined
    
            let val = obj[ele] == 'user' || obj[ele] == 'elakay' || obj[ele] == emailTel || obj[ele] == undefined || obj[ele] == '' 
        
            let contunue 
           if(pa == 'edit'){
               if( obj && obj[ele] && val){
                contunue = false
               }else{
                contunue = true
               }
            }else if(pa == 'userInfo'){
                let a = obj && obj[ele] && !val && ifu && upt 
             
                a && setPagesUser(bnTelephone)
                contunue = true
            }else if(pa == 'telephone'){
                let a = obj && checkObjEmpty(obj[pa][0]) && !val && ifu && upt 
                a && setPagesUser(bnAddAdress)
                contunue = true
            }
            else if(pa == 'address'){
                let a = obj && checkObjEmpty(obj[pa][0]) && !val && ifu && upt
                a ? contunue = false : contunue = true
            }
            return  contunue

        }
        
        

            const planAddInfoUser =()=>{
                return messageExp && addInfoUser &&  <div> 
         {         checkObjEmpty(userInfo) &&  <GetEditInfoUser
                     setPagesUser={setPagesUser}
                     setUserInfo={resteAllData} 
                    bnTelephone={bnTelephone}
                    checkObjEmpty={checkObjEmpty}
                    checkInfoUser={checkInfoUser}
                    userInfo={userInfo}
                      token={token}
                      infoSource={ infoSource}
                      loadingUserAct={loadingUserAct}
                    />}
              {   checkInfoUser(userInfo, 'firstname', 'userInfo', infoPersonnel, addInfoUser) &&  infoPersonnel && < AddinfoUser
                    setPagesUser={setPagesUser}
                    bnTelephone={bnTelephone}
                    setUserInfo={resteAllData}
                    userInfo={userInfo}
                     infoSource={ infoSource}
                     checkInfoUser={checkInfoUser}
                     token={token}
                    />}

              { checkInfoUser(userInfo, 'firstname', 'telephone', telephone, addInfoUser) && telephone &&  <AddtelephoneUserAssociate 
                     setPagesUser={setPagesUser}
                     bnAddAdress={bnAddAdress}
                     setUserInfo={resteAllData}
                     infoSource={ infoSource}
                     token={token}
                    />}

              {  checkInfoUser(userInfo, 'firstname', 'address', AddAdress, addInfoUser) && AddAdress &&  <AddAddressUserAssociate
                     setPagesUser={setPages}
                     bnTelephone={bnUserAgree}
                     setUserInfo={resteAllData}
                      infoSource={ infoSource}
                      phoneUser={userInfo.telephone}
                      token={token}
                    />}
                    {  allBon(bnTermCondition, bnUserAgree)}
                    </div> 
            }
            let nbrDayRestant = checkObjEmpty(userInfo) ? Math.abs(new Date(userInfo.ativator[0].experyDate) - new Date()) : 0
            let endDays = checkObjEmpty(userInfo) ? new Date(userInfo.ativator[0].experyDate).getTime() : 0
            let startDays = checkObjEmpty(userInfo) ? new Date(userInfo.createdAt).getTime(): 0

            // if(nbrDayRestant === 0){
            //     setMessageExp(true)
            // }

            const  CheckBoxButton = ()=>{
                let c = planAfCh? false: true
                setPlanAfCh(c) 
             }
             const  CheckBoxButton0 = ()=>{
                let c = ternConCh? false: true
                setTernConCh(c) 
             }
             const  CheckBoxButton1 = ()=>{
                let c = agreeCh? false: true
                setAgreeCh(c) 
             }


    const clickAgree = () => {
        setActivePageAss(true)
        userAssUpRoleTrueRandom(token).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
                console.log("data")
            }
        })


    }
             const  sendCodeBnt = ()=>{
                return planAfCh && ternConCh && agreeCh && agreUsIn ? 
                <button onClick={()=> clickAgree()} className="btn btn-primary p-2 float-right" style={{ width: "120px" }}>
                {/* active user */}
                {infoSource.language(infoSource, "Button", "actUser")} 
              </button> : <button className="btn btn-primary p-2 float-right disabled" style={{ width: "120px" }}>
              {/* active user */}
              {infoSource.language(infoSource, "Button", "actUser")} 
              </button>
             }

            
        
            const userAgreePage =()=>{
                return userAgree && <div>
                    {pageSteps(infoSource.laguangeElakay(agree,  infoSource.languageAPI))}
                    <div className="container">
                    <div className="row mb-4 justify-content-center">
                    <ul className="col-12 col-md-9">
                            <li> 
                            <input
                                    onChange={CheckBoxButton}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={planAfCh? 'checked' : ''}
                                    id="planAffairLu"
                                />
                                <label className="form-check-label" for="planAffairLu">
                                    {/* es ce que vous avez lu le plan d'affaire  */}
                                    {infoSource.language(infoSource, "label", "planAffairLu")}
                                    </label>
                            </li>
                            <li>
                            <input
                                    onChange={CheckBoxButton0}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={ternConCh? 'checked' : ''}
                                    id="termConditionAgre"
                                />
                                <label className="form-check-label" for="termConditionAgre">
                                    {/* es ce que vous ete d'acord avec les term et les condition */}
                                    {infoSource.language(infoSource, "label", "termConditionAgre")}
                                    </label>
                            </li>
                            <li>
                            <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={agreUsIn? 'checked' : ''}
                                    id="allInfoOk"
                                />
                                <label className="form-check-label" for="allInfoOk">
                                    {/* tous les user information are ok  */}
                                    {infoSource.language(infoSource, "label", "allInfoOk")}
                                    </label>
                            </li>
                            <li className="list-unstyled">
                                <input
                                    onChange={CheckBoxButton1}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={agreeCh? 'checked' : ''}
                                    id="chButAgree"
                                />
                                <label className="form-check-label" for="chButAgree">
                                {infoSource.language(infoSource, "title", "chButAgree")}
                                     {/* avec le deux volet pour continue */}
                                     {infoSource.language(infoSource, "label", "acpContinue")}
                                     </label>
                            </li>
                        </ul>
                        
                    </div>
                    {sendCodeBnt()}
                    </div>
                    {  allBon(bnAddInfoUser, undefined)}
                    </div> 
            }
                let emptyObjStyle ={
                    position:"relative",
                    width: "323.52px",
                    height: "204px",
                    backgroundColor:"#191970",
                    background: "radial-gradient(ellipse at right bottom, #0077b3 10%, #80d4ff 30%,  #66ccff 70%, #1ab2ff 100%)", 
                    margin: "10px",
                    borderRadius:"15px",
                    color: "white",
                    boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
                }
            const allform =()=>{
               return <div className="row mt-4 mb-4 justify-content-center">
                <div className="col col-12 col-md-12 col-lg-11 col-xl-11 p-0" style={{border: "1px solid Gainsboro" }} >
                    <div className="col col-12 " style={{textAlign: "center", backgroundColor: "Gainsboro" }}> 
                 </div>
                 {checkObjEmpty(userInfo) && <Timer
                        infoSource={infoSource}
                        setMessageExp ={setMessageExp}
                        nbrdays = {nbrDayRestant} 
                        startDate={endDays}
                        enDate={endDays}
                        />}

                        {!checkObjEmpty(userInfo) &&  <Timer
                         infoSource={infoSource}
                        setMessageExp ={setMessageExp}
                        nbrdays = {nbrDayRestant}
                       
                        />
                    }
        
                
                {planAffairePage()}
                {termConditionPage()}
                {planAddInfoUser()}
                {userAgreePage()}
                 </div>
                 </div>
            
            }
            const activaTorPageForm = () => (
                <div className="contaire">
                    <div className="row justify-content-center " >
                        <Modal
                            size="lg"
                            show={activePageAss}
                            onHide={() => setActivePageAss(false)}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                            <ActivatorPageAss
                             laguangeElakay={ laguangeElakay}
                             infoSource={infoSource}
                             token={token}
                            />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            )
            const expireLink = () => (
                <div className="contaire">
                    <div className="row justify-content-center " >
                        <Modal
                            size="lg"
                            show={messageExp}
                            onHide={() => setMessageExp(false)}
                            // dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <h2>
                                        {/* Votre Compte est expire */}
                                        {infoSource.language(infoSource, "title", "compExp")}
                                        </h2>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <h4>
                                {/* Hello chere Partenaire */}
                                {infoSource.language(infoSource, "title", "hellPart")}
                                </h4>
                
                                <p>
                                {/* Nous souhaitons vous informer que le lien precedemment partage pour rejoindre l’equipe de Elakay n’est plus actif et ne peut plus être utilise pour l’inscription. */}
                                {infoSource.language(infoSource, "text", "textAdUserRoToken")}
                                </p>
                                <p>
                                {/* Cependant, vous avez toujours la possibilite de consulter notre plan d’affaires ainsi que les thèmes et conditions associes. Ces documents restent accessibles et peuvent vous fournir des informations precieuses sur notre projet et nos objectifs. */}
                                {infoSource.language(infoSource, "text", "textAdUserRoToken0")}
                               </p> 
                               <p>
                                {/* Nous nous excusons pour tout desagrement cause et vous remercions de votre interêt pour Elakay. Pour toute question ou si vous souhaitez obtenir des informations supplementaires, n’hesitez pas à nous contacter. */}
                                {infoSource.language(infoSource, "text", "textAdUserRoToken1")}
                            </p>
                                <button onClick={() => setMessageExp(false)}>
                                   {infoSource.laguangeElakay('Button', infoSource.languageAPI).continue}
                                </button>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            )

            let plsh = planAffaire || termCondition || addInfoUser|| userAgree ? "active" : ''
            let tcsh = termCondition || addInfoUser|| userAgree ? "active" : ''
            let insh = addInfoUser|| userAgree ? "active" : ''
            let Agsh = userAgree ? "active" : ''
            const stepSeller = ()=>(
                // <div className="boxCategory1 text-center">
           <div className="row">
          <div className="col-xs-12 col-md-8 offset-md-2 block pb-4">
            <div className="wrapper-progressBar">
                <h2>
                    {/* all to Know about  */}
                    {infoSource.language(infoSource, "label", "allKnowAbout")}{' '}
                    {role} </h2>
   {  checkObjEmpty(userInfo)  && <select value={role} onChange={handleUserRole()} className="form-select" aria-label="Default select example">
  {userRolle.filter(item =>item.leve <= userLeve).map((s, i)=>{
   return <option value={s.name}>{s.name}</option>
  })}
</select>}
              <ul className="progressBar">
                <li className={plsh} onClick={()=> setPages(BnPlanAffaire)}>
                    {/* Plan D'affaire */}
                    {infoSource.language(infoSource, "label", "planAffai")}
                    </li>
                <li className={tcsh} onClick={()=> setPages(bnTermCondition)}>
                    {/* Term & Condition */}
                    {infoSource.language(infoSource, "label", "termCondi")}
                    </li>
                <li className={insh}>
                    {/* Add your info */}
                    {infoSource.language(infoSource, "label", "AddInfo")}
                    </li>
                <li className={Agsh} onClick={()=> setPages(bnUserAgree)}>
                    {/* Final Agree */}
                    {infoSource.language(infoSource, "label", "FinalAgree")}
                    </li>
              </ul>

              
            </div>
          </div>
        </div>
                // </div>
            )

    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            loading={loading}
            SliderPunShow={false}
        >
        <div style={infoSource.styleNav}>
            {stepSeller()}
            {expireLink()}
            {allform()}
            {activaTorPageForm()}
        </div>
         </Layout>
    );
};

export default  UserAddingRoleByToken;