import React, { useState} from "react";
import { FaUserAlt } from "react-icons/fa";
// import { FiDelete } from "react-icons/fi";
import { isAuthenticated } from "../../auth"
import { laguangeElakay } from "../dataElakay";
import Vote from './vote'
import { AddComments, DeleteComments } from "../../auth/apiProduct";
// import ButtonPagination from "../ButtonPagination";
// import { AiFillLike, AiFillDislike } from "react-icons/ai";
// import { Like_disLike } from './voteLike'
// import Modal from 'react-bootstrap/Modal'
import ViewVoteStarButton from './ViewVoteStarButton'
import UserComment from './userComment'
// import {
//     userDislikeCommentsProduct,
//     userLikeCommentsProduct
// } from "../../auth/apiProduct";
// import Filters from "../Filter_fitlerP";
const ProductPreview = props => {
    const{
        infoSource,
        ProductsViewload,
        comments,
        userInfo, 
         productId,
        setPages,
        voteA,
        setShowComments,
        loadProductsView,
        ButtonAddComments,
        commentProduct,
        setUserVote,
        SetSizeComments,
        showComments,
        commentsPro,
        setCommentsPro,
        sizeComments,
        setSkip,
        size,
        skip, 
        limit
    }  =props
    const [addComment, setAddComment] = useState(false);
    const [values, setValues] = useState({
        name: '',
        description: "",
         error: ""
    });
    const {
        name,
        description,
        error
    } = values;

    const { token } = isAuthenticated()

    const deleCommentsUser = (commentID) => {
        DeleteComments(token,  productId, commentID).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error});
    
            } else {
                ProductsViewload( productId)
            }
        })
    }
    

    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, error: false, [name]: value });
    };

    const loadMore = () => {
        let toSkip = skip + limit;
        commentProduct(productId, toSkip, limit).then(data => {
            if (!data) {
                console.log(data.error);
              
            } else {
                setCommentsPro(data.commentsPagination)
                SetSizeComments(data.commentsCount[0].totalComments)
                setUserVote(data.userVote[0].vote)
                setSkip(toSkip)
            }
            })
    };

    const loadMore0 = () => {
        let toSkip = skip - limit;
        commentProduct(productId, toSkip, limit).then(data => {
            if (!data) {
                console.log(data.error);
              
            } else {
                setCommentsPro(data.commentsPagination)
                SetSizeComments(data.commentsCount[0].totalComments)
                setUserVote(data.userVote[0].vote)
                setSkip(toSkip)
            }
            })
    };

    const userRating =()=>{
       return voteA.filter(k => k.userId == userInfo._id)[0] ?voteA.filter(k => k.userId == userInfo._id)[0].voteNum : 0
    }
    const clickSubmit = event => {
      
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });

        AddComments(token,  productId, { name, description}).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    name: '',
                    description: "",
                     error: false,
                }); 
                ButtonAddComments(false)
                ProductsViewload( productId)
                ButtonProductPreview()
            }
        });
    };
    const ButtonProductPreview = () => {
        setShowComments(false)
    }

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const formEdit = () => (

        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-12 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 " style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).AddComments}</h3>
                </div>
                {showError()}
                <div className="mb-3">
                <form className=" m-3" >
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('title', infoSource.languageAPI).title}</label>
                        <input onChange={handleChange('name')} type="text" className="form-control"  value={name}/>
                            <label className="text-muted" style={{fontSize: "12px"}}>{infoSource.laguangeElakay('text', infoSource.languageAPI).textCommentProduct1}</label>
                        
                    </div>

                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).description}</label>
                        <textarea
                            onChange={handleChange('description')}
                            className="form-control"
                            rows="5"
                            value={description}
                        />
                            <label className="text-muted" style={{ fontSize: "12px" }}>{infoSource.laguangeElakay('text', infoSource.languageAPI).textCommentProduct2}</label>
                    </div> 
                       
                </form>
                {name.length > 3 && description.length > 8 ?
                        <button className="btn btn-primary float-right m-3" onClick={clickSubmit}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).add}</button>
                            :
                            <button className="btn float-right m-3">{infoSource.laguangeElakay('Button', infoSource.languageAPI).add}</button>
                        }
           
           <button className="btn btn-primary float-left m-3" onClick={()=>ButtonProductPreview()}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>
            </div>

                <div className="bg-light">
                    <label className="text-muted h4 ml-4">{infoSource.laguangeElakay('label', infoSource.languageAPI).vote}</label>
                    <span className="float-right">{error}</span>
                    <h1>
                    <Vote
                        ButtonAddComments={ButtonAddComments}
                        productID={productId}
                        token={token}
                        rating={userRating()}
                        clickShow={true}
                        starSize={'55px'}
                    />
                    </h1>
                </div>
            </div>
        </div>
    )
    const voteUser = voteU => (
        <Vote rating={voteU} />
    )
    const userName =(o)=>{
        
        let first1 = String(o.firstname).charAt(0)
        let last1 = String(o.lastname).charAt(0)
        let FL = first1.concat(last1).toLocaleUpperCase()
    return o.username? o.username : FL
    }

    const YourCommentaire = (
        comments &&  comments.map((k, i) => (
                userInfo._id === k.user && (
                <div key={i} className="row m-3" style={{ borderBottom: "1px dotted Silver" }}>
                    <div>
                        <div className="m-0 p-0" style={{ fontSize: "18px" }}>
                            <FaUserAlt />
                            <span style={{ fontSize: "14px" }}>{userName(k)}</span><br />
                            <span className="m-0 p-0" style={{ fontSize: "16px" }}>
                                {voteA.map((p, o) => (
                                    <div key={o}>
                                        {k.user === p.userId && (
                                            voteUser(p.voteNum)
                                            
                                        )}
                                    </div>
                                ))}
                            </span>
                        </div>
                        <p className="m-0" style={{ font: "15px Arial, sans-serif" }}>{k.title}</p>
                        <p className="m-0" style={{ fontSize: "12px" }}> {k.description}</p>
                    </div>
                </div>
            ))
        )
    )


    return (
       
            <div>
                  <ViewVoteStarButton
                        infoSource={infoSource}
                        voteA={voteA}
                        token={token}
                        productId={productId}
                        loadProductsView={loadProductsView}
                        ButtonAddComments={ButtonAddComments}
                        showComments={showComments}
                        addComment={addComment}
                        setAddComment={setAddComment}
                      />
            
                { YourCommentaire}
                {/* {!addComment && commentaireUser} */}
                {!addComment && <UserComment 
                infoSource={infoSource}
                 skip={skip}
                 limit={limit}
                 setCommentsPro={setCommentsPro}
                 siseProduct={sizeComments}
                 commentProduct={commentProduct}
                 SetSizeComments={SetSizeComments}
                 setUserVote={setUserVote}
                 comments={comments}
                 voteA={voteA}
                 productId={productId}
                 token={token}
                 loadMore={loadMore}
                 loadMore0={loadMore0}
                 size={size}
                 ButtonProductPreview={ButtonProductPreview}
                />}
                {addComment && formEdit()}
                   </div>
       
    );

};

export default ProductPreview;