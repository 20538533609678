import React from 'react';
import Filter from '../../includes/pageInside/Filter_fitlerP'
import FiltersSU from '../../includes/pageInside/Filter_fitlerSU';


import './SideDrawer1.css';

const siderDrawer1 = props => { 

    const {
        infoSource,
        e1, 
        categoriesID,
        handleFilters, 
        prices,
        loadProductsBySell,
        setMyFilters,
        AllCategorie,
        filterShop = false,
        setFilterShop,
        userShop,
        getCalFilShop
        } = props
    
    let drawerClass = 'side-drawer1';
    if(props.show || filterShop){
        drawerClass = 'side-drawer1 open';
    }
    // if(filterShop){
    //     drawerClass = 'side-drawer1 open'; 
    // }

   return(  
   <nav className={drawerClass}>
    {userShop?

    <>
    <FiltersSU
    infoSource ={infoSource}
    getCalFilShop={getCalFilShop}
    handleFilters={handleFilters} 
    setMyFilters={setMyFilters}
    click={props.click}
    />
    </>
    :  <Filter 
            infoSource ={infoSource}
            AllCategorie={AllCategorie}
            e1={e1} 
                categoryID={categoriesID}
                handleFilters={handleFilters} 
                prices={prices}
               loadProductsBySell={loadProductsBySell} 
               setMyFilters={setMyFilters}
               click={props.click}
                />}
    </nav>

)};

export default siderDrawer1;