import React, { useState} from "react";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {activatorUserAdding} from "../../auth";
import Pages from '../LanguagePage'
import nodata from '../../Layout/imgs/nodata0.png';
import {laguangeElakay} from "../../pageInside/dataElakay"; 

const AddinfoUser = (props) => {
    const {
        setPagesUser,
        bnTelephone,
        setUserInfo,
        token,
        infoSource,
        checkInfoUser,
        userInfo
        } = props

        let userValue ={
            firstname: "",
            lastname: "",
            email: "",
            emailRole: "",
            password: "",
            passwordConfirmation: "",
            error: "",
            success: false
        }
    const [showPassword, setShowPassword] = useState(true) 
    const [values, setValues] = useState(userValue)
;

    const { firstname, lastname, password, email, passwordConfirmation, success, error} = values;
    const CheckEmail = !checkInfoUser(userInfo, 'email', 'edit') ? email !== "" :  email === ""
    const productTrue = firstname !== "" && lastname !== "" && password !== "" && passwordConfirmation !== "" && CheckEmail

    const handleChange = firstname => event => {
        setValues({ ...values, error: false, [firstname]: event.target.value });
    };

    // const activeButton = ()=>{
    //     let a = numberR > 0 || numberR != ''
    //     let b = numberS > 0 || numberS != ''
    //     let c = numberP > 0 || numberP != ''
    //     let d = numberAS > 0 || numberAS != ''
    //     let e = numberI > 0  || numberI != ''
    //     let f = numberA > 0 || numberA != ''
    //     let p = password != ''

    //     let g1Form = selectRole >= 4 && a && b && c && p
    //     let g2Form = selectRole >= 5 && g1Form && d 
    //     let g3Form = selectRole >= 6 && g2Form  && e
    //     let g4Form = selectRole >= 7 &&   g3Form && f
    //     if(selectRole === 4){
    //         return g1Form
    //     } else if(selectRole === 5) {
    //         return g2Form
    //     } else if(selectRole === 6) {
    //         return g3Form
    //     }else if(selectRole === 7) {
    //         return g4Form
    //     }else{
    //         return false
    //     }
    // }

    const clickSubmit = event => {
       let userEle =   !checkInfoUser(userInfo, 'email', 'edit') ? 
       {firstname, lastname, email, password, passwordConfirmation}:
       {firstname, lastname, password, passwordConfirmation};

        event.preventDefault();
        activatorUserAdding( token, 'user', userEle , true).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                if(!data){
                    setValues({ ...values, error: 'BAD REQUEST or email is not ok', success: false });
                }else{
                userValue.success = true
                setValues(userValue);
                setUserInfo(data)
                setPagesUser(bnTelephone)
            }
            }
        });;
    };

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
            {/* Your account elakay is created. Please  go to your email to validate your account */}
            {infoSource.language(infoSource, "text", "textAddinFUs")} 
            <Link to="/signin">sign in</Link>
        </div>
    );
    const signUpForm = () => (

            <form className="form-signin">
                <h4 className="text-center">
                    {/* {infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount} */}
                    {/* Add your info to continue */}
                    {infoSource.language(infoSource, "text", "textAddinFUs0")} 
                    </h4>
                {showSuccess()}
                {showError()}
                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("firstname")} value={firstname} type="text" name="firstname" id="firstname" className="form-control" placeholder="first name" required/>
                    <label className="text-muted" htmlFor="firstname">{infoSource.laguangeElakay('label', infoSource.languageAPI).fisrtName}</label>
                </div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("lastname")} value={lastname} type="text" name="lastname" id="lastname" className="form-control" placeholder="Last name" required />
                    <label htmlFor="lastname">{infoSource.laguangeElakay('label', infoSource.languageAPI).lastName}</label>
                </div>

              {  !checkInfoUser(userInfo, 'email', 'edit') && <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("email")} value={email} type="email" name="email" id="inputEmail" className="form-control" placeholder="Email address" required />
                    <label htmlFor="inputEmail">{infoSource.laguangeElakay('label', infoSource.languageAPI).email}</label>
                </div>}

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("password")} value={password} type={showPassword ? "password" : "text"} name="password" id="inputPassword" className="form-control" placeholder="Password" />
                    <label htmlFor="inputPassword">{infoSource.laguangeElakay('label', infoSource.languageAPI).Password}</label>

                </div>

                <div className="form-label-group">
                    <input autoComplete="off" onChange={handleChange("passwordConfirmation")} value={passwordConfirmation} type={showPassword ? "password" : "text"} name="passwordConfirmation" id="inputPassword2" className="form-control" placeholder="Password" required />
                    <label htmlFor="inputPassword2">{infoSource.laguangeElakay('label', infoSource.languageAPI).confirmPassword}</label>
                                    <span className="h4"  > {showPassword ? <FaEye onClick={() => setShowPassword(false)} /> : <FaEyeSlash onClick={() => setShowPassword(true)} />}</span> 
                                </div>

                <input type="hidden" name="verication" placeholder="" />
                  
                { productTrue ?
                <button onClick={clickSubmit} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                    {/* {infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount} */}
                    save 
                    </button>:
                     <button className="btn btn-lg btn-block disabled" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                     {/* {infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount} */}
                     save 
                     </button>
                
                }
                 </form>
    );

    return (
       <>
       { signUpForm() }
       </>
    );
};

export default AddinfoUser;