import React,{useState} from "react";
import { isAuthenticated } from '../auth';
import { AddProductImageNote } from "../auth/apiProduct";
import { DataElakay } from "./dataElakay";
import { language } from "../pageInside/dataElakay";
import "./imagesLoading.css"

const ImagesLoadingNote = props => {
    const { token } = isAuthenticated();
    const {
        laguangeElakay,
        infoSource,
        maxImgSize = 6,
        imgSize = 0,
        handleSubmit,
    } = props
   const[post, setPost]=useState({
    title:"",
    desc:"",
   photos:[]
})
const [highlight, setHighlight] = useState(false)
const {title, desc, photos} = post
let sizeImg =  photos.length + imgSize 
const handlechange = e =>{
    setPost({
        ...post,
        [e.target.name]: e.target.value
    })
}
const handlefilechange = e =>{
let files = e.target.files;
handleFiles(files)
}
const handleFiles=(files)=>{
    let arrPhotos = [];
    let sizeArr
    for (let file of files){
        
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', ()=>{
            let fileobj ={
                file: file,
                name : file.name,
                type: file.type,
                size: file.size,
                src: reader.result
            }
        arrPhotos.push(fileobj)
        if(( sizeImg+ files.length) > maxImgSize){
            sizeArr = files.length -  ((sizeImg + files.length) - maxImgSize)
        }else{
            sizeArr =   files.length
        }
        setPost({
            ...post,
            photos:[...photos, ...arrPhotos.slice(0, sizeArr)]
        })
        });
    }
}
const handledelete = e =>{
    let target = e.target.parentElement;
    
    let tagetIndex = target.dataset.imgindex * 1;
setPost({
...post,
photos:[...photos.slice(0, tagetIndex), ...photos.slice(tagetIndex + 1)]
})
    
}

const handlehighlight = e =>{
e.preventDefault();
e.stopPropagation();
setHighlight(true);
}

const handleunhighlight = e =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
}

const handledrop = e =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
    let dt = e.dataTransfer;
    let files = dt.files
    handleFiles(files)
}

// function handleSubmit() {

//     photos.map(f => AddProductImageNote(token, productIDEdit, f.file).then(data => {
//         if (data.error) {
//             console.log(data.error);
//         } else {
//             loadProductsView(productIDEdit);
//             setPost({
//                 title:"",
//                 desc:"",
//                 photos:[]
//                 })
//         }
//     }))
// }

    const ImagesForm = () => (
        <div className="row mt-4 justify-content-center">
        <div className="col col-12 col-md-10 p-2" >
        <div className="file-upload">
        <h2 className="text-success">
            {/* add your images Note or Pub now if nessessary */}
            {language(infoSource, "sellPage", "addImgNotNess")}
            </h2>
        <form className="" encType= "multipart/form-data"> 
        <div className="custom-file-preview">
                  {photos.length > 0 && photos.map((a, i) =>(
                    <div className="prev-img" key={i} data-imgindex={i}>
                        <span onClick={handledelete}>&times;</span>
                        <img className="imgLoad" src={a.src} alt={a.name}/>
                    </div>
                    ))}
                </div>
            <div className="custom-form-group">
         {   sizeImg < maxImgSize ?
                <div className={highlight? "custom-file-drop-area highlight": "custom-file-drop-area"} 
                onDragEnter={handlehighlight}
                onDragOver={handlehighlight}
                onDragLeave={handleunhighlight}
                onDrop={handledrop}
                >
                    <input type="file" name="photos" placeholder="Enter photos" multiple id="filephotos" onChange={handlefilechange}/>
                    <label htmlFor="filephotos">
                         {/* add  */}
                         {language(infoSource, "Button", "add")}
                         {maxImgSize- sizeImg} 
                         {/* images or not */}
                         {language(infoSource, "sellPage", "morimagesnot")}
                         <br/> 
                         {/* Drag & Drop  */}
                         {language(infoSource, "sellPage", "dragDrop")}
                         </label>
                </div>
                :
                <div className={sizeImg < maxImgSize? "custom-file-drop-area": "custom-file-drop-area redlight"}>
                    <label htmlFor="filephotos">
                         {/* you can not Drag & Drop */}
                         {language(infoSource, "sellPage", "canDragDrop")}
                         </label>
                </div>
}
               
            </div>
        
        </form>
  <div>

   { photos.length > 0 ?
    <button  className="btn btn-primary float-right pb-2" onClick={()=>handleSubmit(photos, setPost)}>
        {infoSource.language(infoSource, 'Button', "submit")}
        </button>
   :
    <button  className="btn btn-secondary float-right pb-2" >
        {infoSource.language(infoSource, 'Button', "submit")}
        </button>}
  </div>
 </div>
     </div>
     </div>

    )

 
    return ImagesForm()
    
}
export default ImagesLoadingNote;