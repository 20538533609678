import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CardSetupForm';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_51Kr8rAF1003CDINHHAtGRXLFhoQEXYN6pHTlATpROB7FEMwiMDAhTT4ZESfnFj11Y3dS1QzmH5YPUA3kaGLnA04h00b3CF4klh");

export default function App({ 
  address, 
  token, 
  getAddressById,
  setAddCards,
  Customercreate,
  laguangeElakay,
  infoSource

}) {

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm 
          laguangeElakay={laguangeElakay}
          infoSource ={infoSource}
          address={address}
          token={token}
          getAddressById={getAddressById}
          setAddCards={setAddCards}
          Customercreate={Customercreate}
      />
    </Elements>
  );
};
