import React from "react";
import Loader from "../Layout/loader.gif";
import { laguangeElakay } from "../pageInside/dataElakay";
const ErrorPage = (props) => {
    const {
        infoSource
        } = props
    const laadingGIf = () => (
        <div className="page-container">
            <div className="row justify-content-center">
                <div className="col col-12 col-md-11 col-lg-11 col-xl-7 ">
                    <h2 className="mt-4">{infoSource.laguangeElakay('title', infoSource.languageAPI).errorLoading}</h2>

                    <div className="mx-auto" style={{ width: "200px", margin: "15%" }}>
                        <img src={Loader}  alt="loader" />
                    </div>

                </div>
            </div></div>
    )

    return (
     <>
          { laadingGIf()}
     </>

    );
};

export default ErrorPage;