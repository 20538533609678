import React, { useState, useEffect, Fragment} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import './Footer.css';
import Card from 'react-bootstrap/Card'
import { FaSellcast, FaUserCog, FaBoxes, FaShoppingCart, FaUserSecret, FaMoneyBill, FaHandsHelping } from "react-icons/fa";
import { MdMessage, MdToday, MdAccountBox } from "react-icons/md";
import { Seller } from "../pageInside/Rolle"
import { signout, isAuthenticated, signOutALL} from "../auth";
import ShopUserView from "../pages/lakayUser/ShopUser";
import { BsGridFill} from "react-icons/bs";
import { GrAscend } from "react-icons/gr";
import { sendMessageHelp } from "../auth/apiMessage";
// import {Adminstrator} from "../pageInside/Rolle"
import {laguangeElakay } from "../pageInside/dataElakay"; 
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import UserExitActAss from "../pages/userPageExiste/actPageAssUserExit";
import Pages from '../pages/LanguagePage'
import nodata from '../Layout/imgs/nodata0.png';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const  isTruthy = (obj)=>{

    if(obj.pubShow && obj.pub.success){
        if(
            obj.pub.promoInfo.prod_id === 'null' &&
            obj.pub.promoInfo.customer_id === 'null'
        ){
            return false
        }else{
            return true
        }
    }else{
    return false
    }

}
const ShowImage = (props) => {

    const {
      
        infoSource,
        elakayCategories
    }=props
    let query = useQuery();
    let aboutUsemail = query.get("aboutus")
    const {
        userRole,
        getPromoInfo, 
        setPromoInfo
       
    }= infoSource
   let navigate = useNavigate()
      const [loading, setLoading] = useState(false);
    const [showRoleExit, setRoleExit] = useState(true);
    const [showPromo, setPromo] = useState(isTruthy(getPromoInfo));
    const [showConditionsRole, setConditionsRole] = useState(false);
    const [showContactUs, setShowContactUs] = useState(false);
    const [About, setAbout] = useState(aboutUsemail ? aboutUsemail : false);
    const { token, a } = isAuthenticated();
    const [values, setValues] = useState({
        userReceived: "5ee5a77cc9ed3415300c10b2",
        userSend: a,
        tilte: "",
        description: ""
    });
    const {
        userReceived,
        userSend,
        tilte,
        description
    } = values;

    let ilcod = infoSource.languageAPI.checkLangObjDefault
    let ileia =  infoSource.languageAPI.elakayInfoAPI
    let ilei = infoSource.languageAPI.elakayInfo

    let InfoApiMenuList =  !infoSource.loading && ilcod(ileia, ilei, 'menuList')
    let InfoApiTilte = !infoSource.loading && ilcod(ileia, ilei, 'title')
    let InfoApiText = !infoSource.loading && ilcod(ileia, ilei, 'text')
    let InfoApiBuy = !infoSource.loading && ilcod(ileia, ilei, 'buy')
    let InfoApiButton = !infoSource.loading && ilcod(ileia, ilei, 'Button')
    let InfoApiSell = !infoSource.loading && ilcod(ileia, ilei, 'sell')
    let InfoApiSellPage = !infoSource.loading && ilcod(ileia, ilei, 'sellPage')
    let InfoApiHelpContact = !infoSource.loading && ilcod(ileia, ilei, 'helpContact')

    const checkObjEmpty =(obj)=>{
        if(Object.values(obj ? obj: []).length > 0){
            
            return true
        }else {
            // setMessageExp(true)
            return false
        }
        
        }

    // let InfoApiSell = infoSource.checkLangObj('sell')

    useEffect(() => { 
        if(showRoleExit && checkObjEmpty(userRole.userActiveRole)){
            setConditionsRole(true)
        }else{
            setConditionsRole(false) 
        }
    }, [userRole, showConditionsRole, setConditionsRole, showRoleExit]);


    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value });
    };

    const clickSubmit = () => {

        sendMessageHelp(token, { userReceived, userSend, tilte, description }).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setValues({
                    ...values,
                    userId: "",
                    tilte: "",
                    description: ""
                });
                setShowContactUs(false)
            } 
        })
    };

    const newPostForm = () => (
        <div className="container">
            <div className="boxCategory1 text-center" id="heading">
            </div>
            <form className="mb-3">
                <div className="form-group">
                    <label className="text-muted">{InfoApiTilte.title}</label>
                    <input
                        onChange={handleChange('tilte')}
                        type="text"
                        className="form-control"
                        value={tilte}
                    />
                </div>

                <div className="form-group">
                    <label className="text-muted">{InfoApiSellPage.description}</label>
                    <textarea
                        onChange={handleChange('description')}
                        className="form-control"
                        rows="6"
                        value={description}
                    />
                </div>
            </form>
            <button onClick={() => clickSubmit()} className="btn btn-primary float-right">{InfoApiButton.next}</button>
        </div>
    )

    const printListeShop = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={showContactUs}
                    onHide={() => setShowContactUs(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                          {InfoApiTilte.messageElakayAdmin}
          </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {newPostForm()}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
    const AboutUS = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={About}
                    onHide={() => setAbout(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                        <h2>{InfoApiTilte.aboutElakayTogether}</h2>
          </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                    {pageSteps(infoSource.laguangeElakay('aboutUs',  infoSource.languageAPI))}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
    
    const AllCatagories =()=>(
        <div>
            {elakayCategories && elakayCategories.map((c, i) => (
                <h6 key={i} className="mt-0 mb-0 pt-2 pb-2"><Link to={`/product/${c.supCategoryID._id && c.supCategoryID._id}`} style={{ color: "#ffffff" }}>
                    {/* {c.supCategoryID.name[0]} */}
                    {infoSource.getCategoryNames(c).name[0]}

                    </Link></h6>
            ))}

        </div>
    )
const HeaderSidebar = () => (
    <div >
        {!isAuthenticated() && (
            <Fragment>
                <Link
                    className="btn btn-outline-light btn-md"
                    style={{ color: 'black' }}
                    to="/signin"
                >
                   {InfoApiButton.signin}
                        </Link>

                <Link
                    className="btn btn-outline-light btn-md"
                    style={{ color: 'black' }}
                    to="/signup"
                >
                   {InfoApiButton.signup}
                        </Link>
            </Fragment>
        )}

        {isAuthenticated() && (
            <div>

                <button
                    className="btn btn-outline-light btn-md"
                    style={{ cursor: "pointer", color: "#000000" }}
                    onClick={() =>

                        signout(token).then(() => {

                            window.location.href = '/signin';

                        })
                    }
                >
                   {InfoApiButton.signOut}
                    </button>

                <span
                    className="btn btn-outline-light btn-md"
                    style={{ cursor: "pointer", color: "#000000" }}
                    onClick={() =>

                        signOutALL(token).then(() => {
                            window.location.href = '/signin';
                        })
                    }
                >
                  {InfoApiButton.signOutAll}
                    </span>
            </div>
        )}
    </div>
)

let paging = 'pt-1 pb-1'

const menuSimpleUser = () => (

        <div>
          

                {isAuthenticated && Seller() ?
            <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/user-serting" style={{ color: "#ffffff" }}><FaUserCog className="mr-2 h6" />{InfoApiMenuList.userSetting}</Link></h6>
                    :
                    <div>
                <h6 className={`mt-0 mb-0 ${paging}`}><Link to={"/sell"} style={{ color: "#ffffff" }}><FaSellcast className="mr-2 h6" />{InfoApiMenuList.sell}</Link></h6>
               
                    </div>
                } 
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/your-step" style={{ color: "#ffffff" }}> <GrAscend  className="mr-2 h6" />{InfoApiMenuList.yourStep}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/all-catageories" style={{ color: "#ffffff" }}> <BsGridFill className="mr-3 h6" />{InfoApiMenuList.allCategory}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/profile" style={{ color: "#ffffff" }}> <FaUserSecret className="mr-2 h6" />{InfoApiMenuList.myProfile}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/account" style={{ color: "#ffffff" }}> <MdAccountBox className="mr-2 h6" />{InfoApiMenuList.myAccount}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/c/orders" style={{ color: "#ffffff" }}><FaBoxes className="mr-2 h6" />{InfoApiMenuList.myOrders}</Link></h6>
        {/* <h6 className={`mt-0 mb-0 ${paging}`}><Link to="" style={{ color: "#ffffff" }}> <MdPlaylistAddCheck className="mr- h6" />My liste</Link></h6> */}
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/cart" style={{ color: "#ffffff" }}> <FaShoppingCart className="mr-2 h6" />{InfoApiMenuList.myCart}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/deal-today" style={{ color: "#ffffff" }}> <MdToday className="mr-2 h6" />{InfoApiMenuList.todayDeal}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}> <Link to="/help-contact" style={{ color: "#ffffff" }}>  <FaHandsHelping className="mr-2 h6" />{InfoApiMenuList.helpContact}</Link></h6>
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/purchased" style={{ color: "#ffffff" }}> <FaMoneyBill className="mr-2 h6" />{InfoApiMenuList.myPurchased}</Link></h6>
        {/* <h5 className={`mt-0 mb-0 ${paging}`}><Link to="/score-point" style={{ color: "#ffffff" }}> <BsGraphUp className="mr-2 h6" />{InfoApiMenuList.myScorePoint}</Link></h6> */}
        <h6 className={`mt-0 mb-0 ${paging}`}><Link to="/message" style={{ color: "#ffffff" }}><MdMessage className="mr-2 h6" />{InfoApiMenuList.myMessage}</Link></h6>
                {/* <h5><Link to=""> <FaCreditCard className="mr-2 h2"/>Gift Card</Link></h5> */}
        
        </div>
    )

    const checkProdKeyExit = (ad)=>{
        let ent 
        if(Array.isArray(ad)){
          let ac = ad[0]
          ent = Object.keys(ac ? ac:{});
          return ent.every(([key, val]) => ac[key] != undefined) && true
        } else{
           ent = Object.keys(ad ? ad:{});
           return ent.every(([key, val]) => ad[key] != undefined) && true
        }}

        const pageSteps = (a)=>{
            if (!checkProdKeyExit(a)){
               return <Pages  
                classAlert ={"container-fluid"}
                classText={"col col-11"}
                classImg={"col col-10"}
                classPara={'row justify-content-center'}
                paragra0={a}
            />
            }else{
              return <div> 
              <div className="row mt-4 mb-4 justify-content-center">
              <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
               </div>
               </div>
            }
        }
        let paging2 = 'pt-1 pb-2'

const footerComponents =()=>(
    <div className="container">
        <div>

        </div>
        <div className="row"> 
            <div className="col col-12 col-sm-3 col-md-3">
                <h5 className="p-0" style={{ color: "#ffffff", fontWeight: "bold" }}>{InfoApiTilte.setting}</h5>
                {menuSimpleUser()}
                {HeaderSidebar()} 
            </div>
            <div className="col col-12 col-sm-3 col-md-3">
                <h5 className="p-0" style={{ color: "#ffffff", fontWeight: "bold"}}>{InfoApiMenuList.allCategory}</h5>
                {AllCatagories()}
            </div>
         
            <div className="col col-12 col-sm-3 col-md-3">
                <div className="mb-4">
                    <h5 className="p-0" style={{ color: "#ffffff", fontWeight: "bold" }}>{InfoApiTilte.buy}</h5>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to={`/how-to-Step?step=makeYourFstep`}  target="_top" style={{ color: "#ffffff" }}>{InfoApiBuy.makeYourFistStep}</Link></h6>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to={`/how-to-Step?step=becomeRoyalty`}  target="_top" style={{ color: "#ffffff" }}>{InfoApiBuy.becomeRoyalty}</Link></h6>
                    {/* <h6 className={`mt-0 mb-0 ${paging}`}><Link to={`/how-to-Step?step==more`} style={{ color: "#ffffff" }}>More</Link></h6> */}

                </div>
                <div className="mt-2">
                    <h5 className="p-0" style={{ color: "#ffffff", fontWeight: "bold"  }}>{InfoApiMenuList.sell}</h5>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to={`/how-to-Step?step=becomeSeller`} style={{ color: "#ffffff" }}>{InfoApiSell.becomeSeller}</Link></h6>
                    {/* <h6 className={`mt-0 mb-0 ${paging}`}><Link to={`/how-to-Step?step=''`} style={{ color: "#ffffff" }}>Create your Shop</Link></h6> */}
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to={`/how-to-Step?step=editProduct`} style={{ color: "#ffffff" }}>{InfoApiSell.editProduct}</Link></h6>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to={`/how-to-Step?step=LinkcustomerShop`} style={{ color: "#ffffff" }}>{InfoApiSell.linkCostomertoShop}</Link></h6>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to={`/how-to-Step?step=becomeProvider`} style={{ color: "#ffffff" }}>{InfoApiSell.becomeProvider}</Link></h6>
                </div>
            </div>
        
            <div className="col col-12 col-sm-3 col-md-3">
            <div className="mb-4">
                <h5 className="p-0" style={{ color: "#ffffff", fontWeight:"bold" }}>{InfoApiTilte.aboutElakay}</h5>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><span style={{ color: "#ffffff" }} onClick={() => setAbout(true)}>{InfoApiTilte.aboutUs}</span></h6>
            </div>
                <div className="mt-2">
                    
                    <h5 className="p-0" style={{ color: "#ffffff", fontWeight: "bold" }}>{InfoApiMenuList.helpContact}</h5>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to="/help-contact" style={{ color: "#ffffff" }}>{InfoApiHelpContact.termsCondition}</Link></h6>
                    <h6 className={`mt-0 mb-0 ${paging2}`}><Link to="/help-contact" style={{ color: "#ffffff" }}>{InfoApiHelpContact.HelpContact}</Link></h6>
                   <h6 className={`mt-0 mb-0 ${paging2}`}><span onClick={() => setShowContactUs(true)} style={{ color: "#ffffff" }}>{InfoApiHelpContact.ContactUs}</span></h6>
                    
                </div>
            </div>
           

        </div>
    </div>
)
const AddTermsAndRole = () => (
    <div className="contaire">
        <div className="row justify-content-center " >
            <Modal
                size="xl"
                show={showConditionsRole}
                onHide={() => setRoleExit(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                    <h2>all to Know about {userRole.userActiveRole && userRole.userActiveRole.roleName} </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
            <UserExitActAss
               laguangeElakay={laguangeElakay}
               infoSource={infoSource}
            //    clickAgree={clickAgree}
                userActiveRole = {userRole.userActiveRole && userRole.userActiveRole}
                token={token}
            
            />
                </Modal.Body>
            </Modal>
        </div>
    </div>
)
const btnShowPubPromo = ()=>{
    setPromoInfo({
        ...getPromoInfo, 
        pubShow: false
     })
}

const directionPubPromo = (pastProdView)=>{
    setPromoInfo({
        ...getPromoInfo, 
        pubShow: false
     })
     return navigate(pastProdView)
}
const pubPromo = () => (
    <div className="contaire">
        <div className="row justify-content-center " >
            <Modal
                size="xl"
                show={isTruthy(getPromoInfo)}
                onHide={() => btnShowPubPromo()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton/>
    
                <Modal.Body>
          {isTruthy(getPromoInfo) && getPromoInfo.pub.promoUrl.pastProdView && directionPubPromo(getPromoInfo.pub.promoUrl.pastProdView)}
          {isTruthy(getPromoInfo) && getPromoInfo.pub.promoInfo.customer_id !== 'null' &&  <ShopUserView
             setLoading={setLoading}
             infoSource={infoSource}
            //  readUser={readUser}
            //  ShopName={ShopName}
            showFilter={false}
             userID={getPromoInfo.pub.promoInfo.customer_id}
             myFilters={{filters: {"categoriesID.gategoriesLink": [] }}}
            />}
                </Modal.Body>
            </Modal>
        </div>
    </div>
)

    return (
        <div>
        <footer className="navFooter">
           
            <Card.Footer>
                {footerComponents()}
            </Card.Footer> 
        
            <h6 className="pt-2 pb-2 text-center">Terms Conditions  &#169;  2018 - {new Date(Date.now()).getFullYear()} {' '} https://www.elakay.com</h6>
        </footer>
         { printListeShop() }
            {AboutUS()}
            { AddTermsAndRole()}
            {isTruthy(getPromoInfo) && pubPromo()}
         </div>


    )

};

export default ShowImage;
