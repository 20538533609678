export const TrackingUSPU = (token, tracking_number, order_Id)=>{
    let trackingN=  "92346903470167000000000010"

    const xmlRequest = `
    <?xml version="1.0" encoding="UTF-8"?>
    <TrackRequest USERID="54564BELAKA81">
      <TrackID ID="92346903470167000000000010"></TrackID>
    </TrackRequest>`;
    
    fetch("https://secure.shippingapis.com/ShippingAPI.dll", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: new URLSearchParams({
            API: "TrackV2",
            XML: xmlRequest
        })
    })
    .then(response => response.text())
    .catch(error => console.error("Error:", error));
    
    
}

export const TrackingUPS = ()=>{

}

export const TrackingFedEx = ()=>{

}

export const TrackingDHL = ()=>{

}

export const TrackingShippo = ()=>{

}

export const TrackingShipStation = ()=>{

}