import React, { useState } from "react";
import { Link, Navigate, useParams } from 'react-router-dom';
import { ChangePassword, isAuthenticated} from "../includes/auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logoElakay from '../includes/Layout/icon/LogoElakay.svg';
import './user.css';

const UserPasswordChange = props => {

    const {
        infoSource
    }=props

    let params = useParams()
    const [showPassword, setShowPassword] = useState(true) 
    const [values, setValues] = useState({
        password: "",
        passwordConfirmation: "",
        error: "",
        token: params.token,
        loading: false,
        redirectToReferrer: false
    });

    if (isAuthenticated()) {
        return <Navigate to="/" />;
    }
    const { password, passwordConfirmation, token, loading, error, redirectToReferrer  } = values;

    const handleChange = firstname => event => {
        setValues({ ...values, error: false, [firstname]: event.target.value });
    };



    const clickSubmit = event => {
        event.preventDefault();
        ChangePassword(token, {  password, passwordConfirmation }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({
                    ...values,
                    password: "",
                    passwordConfirmation: "",
                    error: "",
                    redirectToReferrer: true
                });
            }
        });;
   
    };

    const signUpForm = () => (
        <div>
            <div className="usHeader">
                <Link to="/"><h1><img className="img-responsive" src={logoElakay} width="24" alt="logo elakay"/>elakay</h1></Link>
            </div>
            <form className="form-signin mt-4">
                <h4 className="text-center">{infoSource.laguangeElakay('signinPage', infoSource.languageAPI).title1}</h4>
                {showLoading()}
                {showError()}
                <div className="form-label-group">
                    <input onChange={handleChange("password")} type={showPassword ? "password" : "text"} name="password" id="inputPassword" className="form-control" placeholder="Password" />
                    <label htmlFor="inputPassword">{infoSource.laguangeElakay('label', infoSource.languageAPI).Password}</label>
                </div>

                <div className="form-label-group">
                    <input onChange={handleChange("passwordConfirmation")} type={showPassword ? "password" : "text"} name="passwordConfirmation" id="inputPassword2" className="form-control" placeholder="Password" required />
                    <label htmlFor="inputPassword2">{infoSource.laguangeElakay('label', infoSource.languageAPI).confirmPassword}</label>
                </div>
                <span className="h4"  > {showPassword ? <FaEye onClick={() => setShowPassword(false)} /> : <FaEyeSlash onClick={() => setShowPassword(true)} />}</span>
                <input type="hidden" name="verication" placeholder="" />

                <button onClick={clickSubmit} className="btn btn-lg btn-block" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>{infoSource.language(infoSource, 'Button', "submit")}</button>
                <Link className="btn btn-lg btn-block mt-3" to="/signin" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}> {infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</Link>

            </form>
        </div>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>
                    {/* Loading... */}
                    {infoSource.language(infoSource, "title", "loanding")}
                </h2>
            </div>
        );
    const redirectUser = () => {
        if (redirectToReferrer) {
            return <Navigate to="/signin" />;
    }
    };
    return (
        <div>
            {signUpForm()}
            {redirectUser()}

        </div>
    );
};
    export default UserPasswordChange;