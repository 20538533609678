import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import imgProductLakay from '../Layout/imgs/md3.jpg' 
// import required modules
import { Mousewheel, Keyboard, FreeMode, Navigation, Thumbs} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";


import "./styles.css";



 const SwiperProdView = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const {
    images
  }= props

const swiperImage = ()=> {
return  images.map((a, i)=>(
   <SwiperSlide key={a} virtualIndex={i}>  
        <img 
         src={a} 
         alt={a} 
         onError={event => {
          event.target.src = imgProductLakay
          event.onerror = null
        }}
         style={{ height: "100%", width: "100%" }}
         />
   </SwiperSlide>
)) 
}

  return (
    <main>
      {images && images.length > 0 && <Swiper 
      modules={[ Mousewheel, Keyboard, FreeMode, Navigation, Thumbs]}
        style={{
          "--swiper-navigation-color": "#007399",
          "--swiper-pagination-color": "#007399",
        }}
        cssMode={true}
        spaceBetween={10}
        navigation={true}
        mousewheel={true}
        // onSlideChange={() => console.log({thumbsSwiper})}
        onSwiper={(swiper) => setThumbsSwiper(swiper)}
        // thumbs={{ swiper: thumbsSwiper }}
        keyboard={{
          enabled: true,
        }}
        className="mySwiper2"
      >
      { swiperImage()}
      </Swiper>}

      {images && images.length > 1 &&
      <Swiper  
        modules={[  Mousewheel, Keyboard, FreeMode, Navigation, Thumbs]} 
        keyboard={{
          enabled: true,
        }} 
        cssMode={true}
        // onSlideChange={() => console.log({thumbsSwiper})}
        onSwiper={(swiper) => setThumbsSwiper(swiper)}
        spaceBetween={8}
        slidesPerView={6}
        mousewheel={true}
        freeMode={true}
        watchSlidesProgress={true}
      
        className="mySwiper"
      
      >
      {swiperImage()}
      </Swiper>
     
      }
    </main>
  );
}


export default SwiperProdView ;