export const UserVoteCount = (
   nbrVote
) => {

let a = nbrVote
let b



if(a){
if(a < 1000){
b = a
}
    if(a >= 1000){
        b = parseFloat(a / 1000).toFixed(1)+'K'
    }
    if(a >= 1000000){
        b = parseFloat( a / 1000000).toFixed(1)+'M'
    }
a = b
}
    return (
        a
    )
}