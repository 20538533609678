import React,{useState} from "react";
import { isAuthenticated } from '../auth';
import { createImageProduct } from "../auth/apiProduct";
import { language } from "../pageInside/dataElakay";
import "./imagesLoading.css"

const checkImageBackground = async (file, colorList) => {
    if (!file || !colorList) return false;

    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                canvas.width = img.width;
                canvas.height = img.height;

                context.drawImage(img, 0, 0, canvas.width, canvas.height);

                const pixelData = context.getImageData(0, 0, 1, 1).data;

                const hexColor = `#${((1 << 24) + (pixelData[0] << 16) + (pixelData[1] << 8) + pixelData[2])
                    .toString(16)
                    .slice(1)}`;

                // Normalize both hexColor and colorList to lowercase
                const normalizedColorList = colorList.map((color) => color.toLowerCase());
                resolve(normalizedColorList.includes(hexColor.toLowerCase()));
            };

            img.onerror = () => resolve(false); // Handle error case
            img.src = e.target.result;
        };

        reader.onerror = () => resolve(false); // Handle error case
        reader.readAsDataURL(file);
    });
};


const ImagesLoading = props => {
    const { token } = isAuthenticated();
    const {
        laguangeElakay,
        infoSource,
        maxImgSize = 6,
        imgSize,
        namProduct,
        setValues,
        values,
        productIDEdit,
        filterID
    } = props
   const[post, setPost]=useState({
    title:"",
    desc:"",
   photos:[]
})


const [highlight, setHighlight] = useState(false)
const {title, desc, photos} = post
let sizeImg =  photos.length + imgSize 
const handlechange = e =>{
    setPost({
        ...post,
        [e.target.name]: e.target.value
    })
}
const handlefilechange = e =>{
let files = e.target.files;

handleFiles(files)
}

const handleFiles0 =  (files)=>{
    let arrPhotos = [];
    let sizeArr
    for (let file of files){
      
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', ()=>{
            // if (file) {
            //     const allowedColors = ['#F8F8FF', '#FFFFFF', "#F5F5F5", "#FFFAFA", "#F5FFFA"]; // Example allowed colors
            //     const isAllowed = checkImageBackground(file, allowedColors);
            // alert(isAllowed ? "Background color is allowed!" : "Background color is not allowed!");
            // }

            let fileobj ={
                file: file,
                name : file.name,
                type: file.type,
                size: file.size,
                src: reader.result
            }
        arrPhotos.push(fileobj)
        if(( sizeImg+ files.length) > maxImgSize){
            sizeArr = files.length -  ((sizeImg + files.length) - maxImgSize)
        }else{
            sizeArr =   files.length
        }
        setPost({
            ...post,
            photos:[...photos, ...arrPhotos.slice(0, sizeArr)]
        })
        });  
    }
    
}

const handleFiles = async (files) => {
    let arrPhotos = [];
    let sizeArr;

    for (let file of files) {
        const reader = new FileReader();

        // Promisify FileReader to wait for file to be loaded
        const fileData = await new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });

        // Check the background color of the image
        // const allowedColors = ['#F8F8FF', '#FFFFFF', "#F5F5F5", "#FFFAFA", "#F5FFFA"]; // Example allowed colors
        // const isAllowed = await checkImageBackground(file, allowedColors);

        // Log the result of the background check

        // if (!isAllowed) {
        //     alert("Background color is not allowed!");
        //     continue; // Skip adding this file if the background is not allowed
        // }

        //  else {
        //     alert("Background color is allowed!");
        // }

        // Add file information to the array
        const fileobj = {
            file: file,
            name: file.name,
            type: file.type,
            size: file.size,
            src: fileData, // Use the resolved result from FileReader
        };

        arrPhotos.push(fileobj);
    }

    // Calculate the number of files to add based on max size
    if ((sizeImg + files.length) > maxImgSize) {
        sizeArr = files.length - ((sizeImg + files.length) - maxImgSize);
    } else {
        sizeArr = files.length;
    }

    // Update the post state with the validated photos
    setPost({
        ...post,
        photos: [...photos, ...arrPhotos.slice(0, sizeArr)],
    });
};

const handledelete = e =>{
    let target = e.target.parentElement;
    
    let tagetIndex = target.dataset.imgindex * 1;
setPost({
...post,
photos:[...photos.slice(0, tagetIndex), ...photos.slice(tagetIndex + 1)]
})
    
}

const handlehighlight = e =>{
e.preventDefault();
e.stopPropagation();
setHighlight(true);
}

const handleunhighlight = e =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
}

const handledrop = e =>{
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
    let dt = e.dataTransfer;
    let files = dt.files
    handleFiles(files)
}

function handleSubmit() {

    photos.map(f => createImageProduct(token, filterID, productIDEdit, f.file).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
            setValues({
                ...values,
                inpProduct: false,
                filterProduct: false,
                imgPreview: false,
                productID: data.productID,
                redirectToFilter: true,
                loading: false,
                });
        }
    }))
}

    const ImagesForm = () => (
        <div className="row mt-4 justify-content-center">
        <div className="col col-12 col-md-10 col-lg-9 p-2" >
        <div className="file-upload">
        <h2>{namProduct}</h2>
        <form className="" encType= "multipart/form-data"> 
        <div className="custom-file-preview">
                  {photos.length > 0 && photos.map((a, i) =>(
                    <div className="prev-img" key={i} data-imgindex={i}>
                        <span onClick={handledelete}>&times;</span>
                        <img className="imgLoad" src={a.src} alt={a.name}/>
                    </div>
                    ))}
                </div>
            <div className="custom-form-group">
         {   sizeImg < maxImgSize ?
                <div className={highlight? "custom-file-drop-area highlight": "custom-file-drop-area"} 
                onDragEnter={handlehighlight}
                onDragOver={handlehighlight}
                onDragLeave={handleunhighlight}
                onDrop={handledrop}
                >
                    <input type="file" name="photos" placeholder="Enter photos" multiple id="filephotos" onChange={handlefilechange}/>
                    <label htmlFor="filephotos"> 
                        {/* add  */}
                        {language(infoSource, "Button", "add")}
                        {maxImgSize- sizeImg} 
                        {/* more images or not */}
                        {language(infoSource, "sellPage", "morimagesnot")}
                        <br/> 
                        {/* Drag & Drop */}
                        {language(infoSource, "sellPage", "dragDrop")}
                         </label>
                </div>
                :
                <div className={sizeImg < maxImgSize? "custom-file-drop-area": "custom-file-drop-area redlight"}>
                    <label htmlFor="filephotos">
                         {/* you can not Drag & Drop */}
                         {language(infoSource, "sellPage", "canDragDrop")}
                         </label>
                </div>
}
               
            </div>
           
        </form>
  <div>
   { photos.length > 0 ?
    <button  className="btn btn-primary float-right pb-2" onClick={handleSubmit}>
        {infoSource.language(infoSource, 'Button', "submit")}
        </button>
   :
    <button  className="btn btn-secondary float-right pb-2" >
        {infoSource.language(infoSource, 'Button', "submit")}
        </button>}
  </div>
 </div>
     </div>
     </div>

    )

 
    return ImagesForm()
    
}
export default ImagesLoading;