import React from "react";
import {Link} from "react-router-dom";
// Import Swiper React component
import { Swiper, SwiperSlide } from "swiper/react";
import Card from '../pageInside/Card'
import CardCircle from '../pageInside/CardCircle'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css"; 

// import required modules
import { Mousewheel, Keyboard, Pagination, Navigation, Virtual } from 'swiper/modules';
import "./mystyle.sliderProduct.css"
const SwiperProduct = (props)  => {

  const {
    infoSource,
    nameSlider ,
    linkSlider ,
    myStyles,
    ProductSwiper = [],
    circle = false,
    classLink,
    showB,
    wm0 = 2,
    wm640 = 3,
    wm768 = 4,
    wm1024= 5,
   wm1200 = 6,
   edit = false
  } = props

  let sliderShow = ProductSwiper && ProductSwiper.length > 0 ? true : false
  let classSlider = props.classSlider ? props.classSlider : "styles.sliderProduct" 

  return (
    sliderShow && (
          
      <div className={`mt-4  p-2 ${classSlider}`} style={myStyles} >
          
          {linkSlider && (
          <div><span className="h5">{nameSlider ? nameSlider + " " : ""}  </span><Link className={`"h6" ${classLink}`} to={linkSlider}>{infoSource.laguangeElakay('Link', infoSource.languageAPI).seeMore}</Link></div>
          )}
      
      <Swiper
         style={{
          "--swiper-navigation-color": "#007399",
          "--swiper-pagination-color": "#007399",
        }}
        keyboard={{
          enabled: true,
        }}
        cssMode={true}
        slidesPerView={wm0}
        spaceBetween={10}
        slidesPerGroup={2}
        // loop={true}
        navigation ={infoSource && infoSource.navigation}
        mousewheel={true}
        loopFillGroupWithBlank={true}
        breakpoints={{
          568: {
            slidesPerView: wm640,
            spaceBetween: 10
          },
          768: {
            slidesPerView: wm768,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: wm1024,
            spaceBetween: 10
          },
          1200: {
            slidesPerView: wm1200,
            spaceBetween: 10
          }
        }}
        modules={[ Mousewheel, Keyboard, Pagination,  Navigation, Virtual]}
        className="mySwiper"
      >
 {
ProductSwiper && ProductSwiper.map((product, i) => (

  <SwiperSlide key={i} >
    {
      circle ?
      <CardCircle 
      infoSource ={infoSource}
      product={product} 
      />
      :
      <Card
          infoSource ={infoSource}
          product={product}
          CataID={product.categoriesID.length > 0 ?product.categoriesID[0].catID: undefined} 
          showB={showB}
          classCard0="col p-0"
          showShopV={!edit}
          edit = {edit}
      />
    }
  </SwiperSlide>
))
 }
      </Swiper>
      </div>
  )
  )
  ;
};
export default SwiperProduct; 
