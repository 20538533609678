// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    top:0;
    left:0;
    background: rgba(0,0,0,0.3);
    z-index: 900;
}`, "",{"version":3,"sources":["webpack://./src/components/Backdrop/Backdrop.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,SAAS;IACT,KAAK;IACL,MAAM;IACN,2BAA2B;IAC3B,YAAY;AAChB","sourcesContent":[".backdrop{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    top:0;\n    left:0;\n    background: rgba(0,0,0,0.3);\n    z-index: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
