import React, { useState } from "react";
import { Link} from "react-router-dom";
import {GrClose} from "react-icons/gr";

import './catSearch.css';

const CatSearch = (props) => {
    const {
        infoSource,
        getCategoriesByName,
        categoyseleted =[],
        categories,
        search, 
        setSearch,
        loadCategory
} = props
    // const [search, setSearch] = useState([]);
    const [error, setError] = useState("");
    let limit = 10
    let skip = 0


    const calculateFontSize = (index, totalElements) => {
        const maxSize = 18;
        const minSize = 12;
        const sizeStep = (maxSize - minSize) / (totalElements - 1);
        return maxSize - (index * sizeStep);
      };

      const CategorieName = (a, l)=>{
        return <p>
        {Array.isArray(a) && a.slice(0, -1).map((word, index) => (
          <span 
            key={index} 
            style={{ fontSize: `${calculateFontSize(index, l)}px` }}
          >
            {word}{index < l - 1 ? ', ' : '.'}
          </span>
        ))}
      </p>

      }

    const searchData = (search) => {
        if (search) {
            getCategoriesByName(skip, limit, search).then(
                response => {
                    if (response.error) {
                        setError(response.error)
                        console.log(response.error);
                    } else {
                        setSearch(response);
                    }
                }
            );
        }
    };

    const loadingCategoyName = (search) => {
        return (
            <div className="card loadind_catSearch ">
                {search && search.map((cat, i) => (
                        <h5 onClick={()=>loadCategory(cat._id)}>
                            <span className="m-1"  style={{fontSize: '14px'}}>
                                {/* {cat.name.slice(0, -1).join(', ')} */}
                                {infoSource.getCategoryNames(cat, true).name.slice(0, -1).join(', ')}
                                </span>
                        </h5>      
                ))}
                {showError()}
            </div>
        );
    };

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const handleChange = name => event => {
        let val = event.target.value
        setError(false)
        // setSearch({ [name]: event.target.value });

        if (val.length === 0) {
            searchData("undefini")
        } else {
            searchData(event.target.value)
        }

    };


    const searchForm = () => (
            <form  className="mb-0">
            <div className="text-center " id="heading">
               {/* <p className="d-inline">{categories.name}</p>  */}
              { CategorieName(categories.name, categories.name.length)}
            </div>
                <div className="catSearchInp">
                    <input
                        id="txtSearch"
                        onChange={handleChange("search")}
                        type="search"
                        placeholder={'Search Categories by name'}
                        // value={CategorieName(categories.name, categories.name.length)}
                        name="ref"
                    />
                </div>
                {loadingCategoyName(search)}
            </form>
    );


 return searchForm()
}
export default CatSearch;