import React, { useState, useEffect } from "react";
import { getCategoriesByID } from "../auth/apiCategory";


    //  <td className="p-1 md-5">  
    //          <h6>{orders.prod_Description}</h6>
    //       {orders && orders.prod_filterProduct && orders.prod_filterProduct.filter &&  < Filters filter={orders.prod_filterProduct.filter} nexLine={true} />}
    //       </td>

const Filters = props =>{
    const { 
        filter,
        nexLine = false
    }=props
    const length = Object.keys(filter).length;

    const filterDetail = () => (

        <div className="mt-3">
            {
                 Object.entries(filter).map(([key, value], i) =>  <span ><span style={{ fontWeight: "bold", fontSize: "15px" }}>{key}</span>: <span style={{ fontSize: "16px", }}>{value}</span>{i < length - 1 ? '; ' : '.'}{nexLine&&(<br></br>)}</span>)
}
        </div>
    ) 

    return ( 
        filterDetail() 
        )
}
export default Filters;