import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {useLocation,  Link} from "react-router-dom";
import {isAuthenticated, listOrders, getUserViewed} from "../auth";
import moment from "moment";
import Slider from '../slider/swiperOder'
import SliderViewPro from '../slider/swiperProduct'
import Card from '../pageInside/CardOrders2'
import OrdersView from "../pages/OrdersView"
import FiltersOrders from "../pageInside/filersOrder";
import AddressSeller from '../Layout/imgs/no-ordres1.png'
import {laguangeElakay  } from "../pageInside/dataElakay";
import {taxProductArea} from "../auth/apiProduct";
import {TrackingUSPU} from "../auth/trackingaAPI"
import Products from '../pageInside/product'
import LakayUser from '../pageInside/lakayUser'
import NewProduct from '../pageInside/NewProduct'
import ButtonPagination from "../pageInside/ButtonPagination";
import {getTrackingShipping, getTrackingUrl} from "../auth/shipping"
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Orders = (props) => {
let filorder = {
    orderStatus: 'none',
    orderDate: 'months',
    myd: 6
}
    const {
        infoSource,
        PreLinK= '/c/orders'
        } = props

        const query = useQuery();
        const [limit, setLimit] = useState(3);
        const [skip, setSkip] = useState(0);
        const [sizeOrders, setSizeOrders] = useState(0);
        const [filtOrders, setFiltOrders] = useState(filorder);
    const [orders, setOrders] = useState([]);
    const [trackingOrder, setTrackingOrder] = useState(null);
    const { token } = isAuthenticated();
    const [loading, setLoading] = useState(true);  
    const [categoryID, setCategoryID] = useState('')
    const [getInfoOwner, setInfoOwner] = useState({})
    const [userViewedProduct, setuserViewedProduct] = useState([])
    const [showFilterOrder, setShowFilterOrder] = useState(false);
    const [showTrakingOrder, setShowTrakingOrder] = useState(false);
    const [showItermOrder, setShowItermOrder] = useState(false);
    const [getOrderID, setOrderID] = useState({oid:'', pid:'', opid:''});
  
    
   let userInfo = infoSource.userInfo ? infoSource.userInfo :[]

   const newCartProd = async (newCart)=>{
    for (let i = 0; i < newCart.length; i++) {
        const additionalData = await taxProductArea(newCart[i].prod_User_Address.State, newCart[i].prod_User_Address.postalCode)
        if (additionalData) {
            newCart[i].taxprodZone = additionalData.zoneTax; // Ajout du nouveau champ
        }   
    } 
    setOrders(newCart)
  }
const getCategoriesLastOrder =(ar)=>{
let li = ar.length - 1
  let la =  ar[li]
  ar.length > 0  && setCategoryID(la.prod_filterProduct.catID)
  ar.length > 0  && setInfoOwner({address: la.prod_User_Address,  prod_Owner : la.prod_Owner})
}
    const loadOrders = (token, limit, filtOrders) => {
        listOrders(token, skip, parseInt(limit), '', filtOrders).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                getCategoriesLastOrder(data.orders)
                newCartProd(data.orders)
                setSizeOrders(data.totalCount[0] && data.totalCount[0].count)
                setSkip(0)
                setLoading(false)
            }
        });
    };

    useEffect(() => { 
        
        loadOrders(token,  limit, filtOrders);
        LoadShipping()
        productViewedUser(token)
        query.get("oid") && setShowItermOrder(true)
        query.get("oid") && setOrderID({oid: query.get("oid"), pid: query.get("pid"), opid: query.get("opid")})
        // loadingTrakingOder()
    }, [token,  limit, filtOrders]);

    const loadingTrakingOder = ()=>{
        getTrackingUrl().then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    // setTrackingOrder(data)
                }
            });
        }

        const LoadShipping = ()=>{
            getTrackingShipping('USPS',  '9470111206204265442275')
        }
        const productViewedUser = token => {
            token && (
                getUserViewed(token).then(data => {
                    if (!data) {
                        console.log("data Not Found");
                    } else { 
                        infoSource.userInfo && infoSource.userInfo._id &&
                        setuserViewedProduct(data.products);
                    }
                })
            )
        };

        const loadMore = () => {
            let toSkip = skip + parseInt(limit);
            listOrders(token, toSkip, limit, '',  filtOrders).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    getCategoriesLastOrder(data.orders)
                    newCartProd(data.orders)
                    setSizeOrders(data.totalCount[0].count)
                    setSkip(toSkip)
                    setLoading(false)
                }
            });
        };
    
        const loadMore0 = () => {
            let toSkip = skip - parseInt(limit);
            listOrders(token, toSkip, limit, '',  filtOrders).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    getCategoriesLastOrder(data.orders)
                    newCartProd(data.orders)
                    setSizeOrders(data.totalCount[0].count)
                    setSkip(toSkip)
                    setLoading(false)
                }
            });
       
        };
        
        let myStyles = {
            width: "100%",
            backgroundColor: '#f0f0f0'
        }

        const bntOderView =(oid, pid,  opid)=>{
            setShowItermOrder(true)
            setOrderID({oid, pid, opid})
        }
    const showOrdersLength = () => {
        if (orders.length > 0) {
            return (
                <div className="col col-12" style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                <h4 className="text-dark m-2 p-0">
                <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>setShowFilterOrder(true)}>
                    {/* Filters */}
                    {infoSource.language(infoSource, "title", "filters")}
                    </div> 
                {infoSource.laguangeElakay('title', infoSource.languageAPI).totalOrder} {(skip - parseInt(limit)) + parseInt(limit) + 1} - {((skip + parseInt(limit)) > sizeOrders) ? sizeOrders : (skip + parseInt(limit))} 
                {/* of */}
                {infoSource.language(infoSource, "label", "of")} 
                {sizeOrders}  
                </h4>
                </div>
            );
        } else {
            return <div className="col-md-9" style={{ textAlign: "center"}}>
                  <h4 className="text-dark m-2 p-0">
                <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>setShowFilterOrder(true)}>
                    {/* Filters */}
                    {infoSource.language(infoSource, "title", "filters")}
                    </div> 
                </h4>
                 
                                <img
                                    className="rounded"
                                    src={AddressSeller}
                                    alt={"no Product"}
                                    style={{ 
                                        display: 'block',
                                        margin: '0 auto',
                                        width: "100%"
                                     }}
                                />
            </div>
        
        }
    };

    const orderSilder = (productOrder, order)=> {
        return(
        <Slider
        nameSlider={`Total products in the order: ${productOrder.length}`}
        classSlider = { "container mt-1 mb-1 p-0"}
        sliderShow = { true}
        ProductSwiper={productOrder}
        orderID={order._id}
        Plurial={true}
        PreLinK={'/c/orders'}
        view={''}
        laguangeElakay={laguangeElakay}
        infoSource ={infoSource}
         /> 
        )
        }
    


    const order =()=>(
         <div className="row my-4 justify-content-center" style={{backgroundColor: "WhiteSmoke"}}>
                {showOrdersLength()}

                {orders.map((order, oIndex) => {
            return(    
                <div key={oIndex} className="container m-2">
                    <Card
        laguangeElakay={laguangeElakay}
        infoSource ={infoSource}
        product={order}
        loadingTrakingOder={loadingTrakingOder}
        setTrackingOrder ={setTrackingOrder}
        setShowTrakingOrder={setShowTrakingOrder}
        bntOderView={bntOderView}
        // orderURL={`${PreLinK}/${order.orders_id}/${order.products.productID}`}
    />
                </div>
                )  })
                 }
        { orders.length > 0 && < ButtonPagination
                infoSource ={infoSource}
                size={orders.length}
                siseProduct={sizeOrders}
                skip={skip}
                limit={parseInt(limit)}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />}
           
        </div>
    )

    const filterOrdersView = ()=>{
        return <Modal
        size="md"
        show={showFilterOrder}
        onHide={() => setShowFilterOrder(false)}
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h2>
                {/* Orders Filters */}
                {infoSource.language(infoSource, "title", "ordFilt")}
                </h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FiltersOrders 
         laguangeElakay={laguangeElakay}
         infoSource ={infoSource}
         limit={parseInt(limit)}
         setLimit= {setLimit}
         setFiltOrders={setFiltOrders}
         setShowFilterOrder={setShowFilterOrder}
         filtOrders={filtOrders}
        />

        </Modal.Body>
    </Modal>

    }
    let trackUrl0 ='https://track.easypost.com/test'
    let trackUrl ='https://track.goshippo.com/preview/0b2be4d9e9f242bd855f8ed932fd7c35?redirect=true'
    let trakurl1 = 'https://track.goshippo.com/preview/0b2be4d9e9f242bd855f8ed932fd7c35'
    let urltrack ="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=9470111206204265442275%2C"
    const trackingOrderView = ()=>{
       return <Modal
        size="xl"
        show={showTrakingOrder}
        onHide={() => setShowTrakingOrder(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h2> </h2>
            </Modal.Title>
            
        </Modal.Header>
        <Modal.Body>
            
        {trackingOrder ?    <iframe className="embed-responsive-item" src={trackingOrder}  style={{width:'100%', minHeight:"700px"}} title="description">
                </iframe>:
                <h2>{infoSource.language(infoSource, "title", "orderprocess")}</h2>
                }
          
      
 
        </Modal.Body>
    </Modal>

    }

    const itemOrderView = ()=>{
      return  <Modal
        size="xl"
        show={showItermOrder}
        onHide={() => setShowItermOrder(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h2> </h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <OrdersView
         infoSource ={infoSource}
         getOrderID={getOrderID}
        />
        </Modal.Body>
    </Modal>
    }

    return (
        <Layout
        infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            loading={loading}
        >
            <div style={infoSource.styleNav}>
            {order()}
            { categoryID &&  <Products
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                categoryID={categoryID}
                SliderPunShow={true}
                showB={false}
            />}

{getInfoOwner && getInfoOwner.prod_Owner &&  <LakayUser
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                addressShop={getInfoOwner.address}
                userID={getInfoOwner.prod_Owner}
                showB={false}
            />} 
    {userViewedProduct &&  <SliderViewPro
                 laguangeElakay={laguangeElakay}
                 infoSource ={infoSource}
                 ProductSwiper={userViewedProduct}
                 User={infoSource.readUser}
                 nameSlider={infoSource.laguangeElakay('homePage', infoSource.languageAPI).yourViewed}
                 linkSlider={"/user-viewed"}
                 CataID={true}
                 myStyles={myStyles} 
                 />}

<NewProduct
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    readUser={infoSource.readUser}
                    showB={false}
        />
        {filterOrdersView()}
        {trackingOrderView()}
        {itemOrderView()}
        </div>
          </Layout>
    );
};

export default Orders;
