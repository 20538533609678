import React, { useState, useEffect } from "react";
import NewProduct from '../pageInside/NewProduct'
import Layout from "../Layout/Layout";
import Card from '../pageInside/CardCart'
import CardUnAvailbable from '../pageInside/CardCartAvailable'
// import Checkout from "../pageInside/CheckoutCart";
import Checkout from "../pageInside/CheckoutProd";
import {getAddressUserIfTrue, isAuthenticated, getCart} from "../auth";
import EmptyProductCatagies from '../Layout/imgs/Empty_Cart.png' 
import { laguangeElakay } from "../pageInside/dataElakay";
import { SiJson } from "react-icons/si";
import {taxProductArea} from "../auth/apiProduct";

let shipAmout = []

const Cart = (props) => {
const {
    infoSource
        } = props

    const {
        cartItems,
        getCartItems,
    } = infoSource
    const [ShipAmount, SetShipAmount] = useState([]);
    const [search] = useState({ search: '' }); 
    const [cartItemsPRoNew, setCartItemsPRoNew] = useState([]);
    const [loading, setLoading] = useState(false);  
    const { token } = isAuthenticated() 
    let myStyles = {
        width: "100%",
        backgroundColor: '#f0f0f0'
    }
    

    const [Saddress, setSAddressFinal] = useState({
        firstName: "",
        lastName: "",
        city: "",
        country: "",
        line1: "",
        postal_code: "",
        state: ""
    });

// get address by default    
    const addressId = (token) => {
        token && (
            getAddressUserIfTrue(token).then(data => {
                if (data.error) {
                    console.log("data not found")
                } else {
                    // setAddressDefault(data[0])
                    setSAddressFinal({
                        firstName: data[0].firstName,
                        lastName: data[0].lastName,
                        tel: data[0].tel,
                        city: data[0].city,
                        country: data[0].country,
                        line1: data[0].Address1,
                        postal_code: data[0].postalCode,
                        state: data[0].State
                    })
                    
                }
            }))
    }


    useEffect(() => {
        addressId(token)
     newCartProd(getCartItems)
    }, [token, getCartItems]);


   const newCartProd = async (newCart)=>{
    for (let i = 0; i < newCart.length; i++) {
        
        const additionalData = newCart[i].addressShop &&  await taxProductArea(newCart[i].addressShop.State, newCart[i].addressShop.postalCode)
        if (additionalData) {
            newCart[i].taxprodZone = additionalData.zoneTax; // Ajout du nouveau champ
        }
    }
  }
 const noItemsMessage = () => (
     <div className="row = justify-content-center">
         <div className="col-11 col-md-10 col-lg-9 col-xl-8">
             <div className="shadow-lg mt-3 mb-3">
                <img
                    className="rounded"
                    src={EmptyProductCatagies}
                    alt={"no Product"}
                    style={{height: "100%", width: "100%"}}
                />
            </div>
        </div>
     </div>
    );


    const loadingCartView = (getCartItems = []) => {
       
   return (
    <div className="col">
           <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourCart} {`${getCartItems.length}`} {infoSource.laguangeElakay('title', infoSource.languageAPI).items}</h2>
        <hr />
           {getCartItems && getCartItems.map((product, i) => { 
               if(product.productID && product.quantity > 0){
                   return(
        <div key={i}>
                 
        <Card 
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                shipAmout={shipAmout}
                SetShipAmount={SetShipAmount}
                Saddress={Saddress}
                countProuct={product.count}
                product={product}
        />

        </div>
                   )
               }else{
        return (

            <div key={i}>        
                <CardUnAvailbable
                    product={product}
                    cartItems={cartItems}
                />
            </div>
                   
                   )
               }
    })
    }
    </div>    
   )
}
 
const cartView =()=>{
   return <>
    {getCartItems.length > 0 ?
    <div className="row m-1">
        <div className="col col-12 col-md-8 col-lg-8">
         {loadingCartView(getCartItems)}
     </div>
   <div className="col col-12 col-md-4 col-lg-4">
   {/* <Checkout
           Saddress={Saddress}
           setSAddressFinal={setSAddressFinal}
           addressSel={infoSource.address}
           products={cartItemsPRoNew}
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
        /> */}
        <Checkout
              Saddress={Saddress}
            setSAddressFinal={setSAddressFinal}
            // taxprodZone={taxprodZone}
            addressSel={infoSource.address}
            products={getCartItems}
            // quantity={quantity}
            // count={count}
            // parcel={parcel}
            cart = {true}
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
        />
    </div>
    
    </div>
     : noItemsMessage()
    } 
    </>
}

const cartItems0 = (token) => {

   return getCart(token).cart
}
    
    return (
        <Layout
            infoSource ={infoSource}
            title="Cart Page"
            className="container-fluid"
            SliderPunShow={false}
            loading={loading}
        >
            <div style={infoSource.styleNav}>
            {cartView()}
            <NewProduct
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    readUser={infoSource.readUser}
                />
            </div>
        </Layout>
    );
};

export default Cart;