import React, { Component } from 'react';
import bwipjs from 'bwip-js';



class Barcode extends Component {
    constructor(props) { 
        super(props);

        this.state = {
            code: props.code ? props.code : 'code128',
            height: props.height ? props.height : '16',
            text: props.text ? props.text : 'www.elakay.com',
            scale: props.scale ? props.scale : 2,
            includetext: props.includetext ? props.includetext : false,
            nameCanvas: props.canvas ? props.canvas : " mycanvas0"
        };
    }
    componentDidMount() {
        try {
            // The return value is the canvas element
            let canvas = bwipjs.toCanvas(this.state.nameCanvas, {
                bcid: this.state.code,       // Barcode type
                text: this.state.text,    // Text to encode
                scale: this.state.scale,              // 3x scaling factor
                height: this.state.height,              // Bar height, in millimeters
                includetext: this.state.includetext,            // Show human-readable text
                textxalign: 'center',       // Always good to set this
                // backgroundcolor: '4D84F2'
            });
        } catch (e) {
            // `e` may be a string or Error object
        }
    }
    render() {
        return (
            <div>

                <canvas id={this.state.nameCanvas}> </canvas>
            </div>
        );
    }
}
export default Barcode;