import React, { useState} from "react";
import {Link} from "react-router-dom";
import PubProducts from "./pubProduct";
import ProductFollow from "./pubProdFollow";
import { Row } from 'react-bootstrap';
import { elakay_API } from "../../../config";
import {isAuthenticated, addFollowUserShop } from "../../../includes/auth";
import SwiperCatUserProd from "../../slider/swiperUserProd/swiperCatUserProd";

const CategoryPub = (props) => {
const { token } = isAuthenticated();
const [shopUser, setShopUser] = useState("seller");
const {  
    nbrArr = 3,
    Follow = false,
    booFollow = false,
    unFollow = false,
    ci = true,
    inView= false,
    setCi,
    pubCategoryProd, 
    unFollowShop,
    findToFollowU,
    setSearch,
    userID,
    infoSource
 } = props


 const clickToFollowShop = () => {
    addFollowUserShop(token ,userID).then(data => {
        if(!data){
            console.log(data.error)
        }else{
            // infoSource.userInfo && findToFollowU(data.followShopUser, infoSource.userAdmin, data._id) 
            unFollowShop(data.followShopUser)
            infoSource.readUser(token)
        }
    })
}; 

const user_id =(u)=>{
    return u._id._id ? u._id._id: u._id
}

// const productALL0 =(a)=>{
    

//     return <Row className="m-1 p-1 mb-4"  xs={nbrArr}>
//          {
//           pubCategoryProd && pubCategoryProd.map((items, i) => (
//             <div className="p-1" key={i}>
//             <div className="card"> 
//                    <PubProducts
//                 infoSource ={infoSource}
//                 setShopUser={setShopUser}
//                 userID={userID}
//                 categoryID={user_id(items)}
//                 />
//             <h6>{items._id.name && items._id.name[0]} 
//             {
//             inView?
//             <Link onClick={()=>setCi(user_id(items), items._id.name[0] )}>
//             {infoSource.language(infoSource, "Link", "seeMore")}
//             </Link>
//             :
//             <Link to={`/lakay-ShopSeller/${userID}?ci=${user_id(items)}`}>
//             {infoSource.language(infoSource, "Link", "seeMore")}
//             </Link>
//             }
//             </h6>
//             </div>
           
//             </div>
//              ))
//              }
//      </Row>
//  }

 let myStyles = {
    width: "100%",
    backgroundColor: '#f0f0f0'
}

 const productALL =()=>{
    return  <SwiperCatUserProd
            title={"shop Categories"}
            pubCategoryProd={pubCategoryProd}
            userID={userID}
            myStyles={myStyles} 
            infoSource ={infoSource}
            setCi={setCi}
            setSearch={setSearch}
            shop={true}
            setShopUser={setShopUser}
            inView={inView}
            />
}

 

const prodFollow = (arr)=>{
    return arr && arr.map((items, i) => 
       <div key={i}>
            <ProductFollow
            infoSource ={infoSource}
            setShopUser={setShopUser}
            userID={userID}
            categoryID={user_id(items)}
            />
        </div>
        )
}
const bntFollow = <button  className="m-1 py-3 px-0 btn btn-outline-success rounded-4 shadow-lg" role="button" onClick={()=>clickToFollowShop()}>
    {/* Follow */}
    {infoSource.language(infoSource, "Button", "follow")}
    </button>
const bntUnFollow = <button  className="m-1 py-3 px-0 btn btn-outline-danger rounded-4 shadow-lg" role="button" onClick={()=>clickToFollowShop()}>
    {/* UnFollow */}
    {infoSource.language(infoSource, "Button", "unFollow")}
    </button>
const LinkViewAll =  inView ?
<Link className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>{setCi(null); setSearch('')}}>
{/* View All */}
{infoSource.language(infoSource, "title", "viewAll")}
</Link>
: <Link  className="m-1 py-3 px-0 btn btn-outline-dark rounded-4 shadow-lg"  to={`/lakay-ShopSeller/${userID}`} role="button"> 
{/* view lakay  */}
{infoSource.language(infoSource, "Button", "viewLakay")} 
{" "}
{shopUser}</Link>

 const prodFollowALL =()=>{
    
    return <div className="card m-4 p-0">
    <table style={{width: '100%'}}>
        <tr>
            <td className="w-25 p-0">
        <div className="d-grid gap-2 mx-auto">
        {booFollow && unFollow?
            ci ? LinkViewAll : bntFollow
        :
        !booFollow? LinkViewAll : !unFollow && ci ? LinkViewAll : bntUnFollow

       }
          </div>
             </td>
            <td>
            <Row className="p-1"  xs={nbrArr}>
            {prodFollow(pubCategoryProd)}
             </Row>
             </td>
             </tr>
           </table>
             </div>
}
 
 return Follow? prodFollowALL() : productALL()
}
export default CategoryPub;