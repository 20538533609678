import React from 'react';
import { FcSimCardChip } from "react-icons/fc";
import { SiVisa, SiAmericanexpress, SiJcb } from "react-icons/si";
import { FaCcMastercard, FaCcDiscover, FaCcDinersClub, FaCcJcb } from "react-icons/fa";
import americanExpress from "../Layout/cardsIcon/americaExpress.png"
import americanExpress2 from "../Layout/cardsIcon/americanExpress2.png"
import dinersClub from "../Layout/cardsIcon/dinersClub.png"
import discover from "../Layout/cardsIcon/discover.png"
import jcb from "../Layout/cardsIcon/jcb.png"
import masterCard from "../Layout/cardsIcon/masterCard.png"
import unionPay from "../Layout/cardsIcon/unionPay.png"
import visa from "../Layout/cardsIcon/visa.png"
const CreditCardType = (props) => {
        let fullName = String(props.name).toLocaleUpperCase()
        let lastDigi =  props.last4
        let exp_month = props.exp_month
        let exp_year = String(props.exp_year).slice(-2)
        let brand = props.brand

    const cardStyle ={
        position:"relative",
        width: "323.52px",
        height: "204px",
        backgroundColor:"#191970",
        background: "radial-gradient(ellipse at right bottom, #EEB1BF 5%, #d42dab 25%, #4d1d63 50%, #030243 100%)", 
        margin: "10px",
        borderRadius:"15px",
        color: "white",
        boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
    }

    const numberDigi ={
        // margin: "13px 13px",
        padding: "60px 16px 5px 16px",
       fontSize: "17px",
       color:"white"
   
    }
    const numberAll ={
        position:"absolute",
        top:"40%",
        left:"6px",
        color:"white"
    }
    const nameStyle ={
        position:"absolute",
        bottom:"9px",
        left:"25px",
        color:"white"
    }
    const simStyle = {
        position:"absolute",
        top:"15px",
        left:"19px"
    }
    const cartTypeStyle = {
        position:"absolute",
        top:"10px",
        right:"20px"
    }

    const expiration ={
        position:"absolute",
        bottom:"8px",
        right:"36px",
        fontSize:"10px",
        textAlign:"center"
    }

    const cardForrm =(cardStyle, brandLogo)=>(
        <div style={cardStyle}>

            <div style={simStyle}><FcSimCardChip size={"50px"}/></div>
            <>{brandLogo}</>

<h5 style={numberAll}><span style={numberDigi} >* * * *</span>  <span style={numberDigi}>* * * *</span>  <span style={numberDigi}>* * * *</span> <span>{lastDigi}</span> </h5>
<h5 style={nameStyle}>{fullName}</h5>
<div style={expiration}><span>Valid Thru</span><br/> <h6>{exp_month}/{exp_year}</h6></div>
        </div>


    )
  
  const selCard =(brand)=>{
let creditCard
let cartTypeStyle
let cardStyle
let brandLogo
    
    switch(brand){
        case 'visa':
             cartTypeStyle = {
                position:"absolute",
                top:"0px",
                right:"20px"
            }
             cardStyle ={
                position:"relative",
                width: "323.52px",
                height: "204px",
                backgroundColor:"#191970",
                background: "linear-gradient(to bottom, #80FFDB, #5390D9)", 
                margin: "10px",
                borderRadius:"15px",
                color: "white",
                boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
            }
         brandLogo =  <div style={cartTypeStyle}>  <img src={visa} alt={"empty image"} style={{width: "85px" }}/></div>
            creditCard =  cardForrm(cardStyle, brandLogo)
        break;

        case 'mastercard':
            case 'visa':
                 cartTypeStyle = {
                    position:"absolute",
                    top:"15px",
                    right:"20px"
                }
                 cardStyle ={
                    position:"relative",
                    width: "323.52px",
                    height: "204px",
                    backgroundColor:"#191970",
                    background: "radial-gradient(ellipse at right bottom, #EEB1BF 5%, #d42dab 25%, #4d1d63 50%, #030243 100%)", 
                    margin: "10px",
                    borderRadius:"15px",
                    color: "white",
                    boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
                }
                 brandLogo =  <div style={cartTypeStyle}>  <img src={masterCard} alt={"empty image"} style={{width: "85px" }}/></div>
                creditCard =  cardForrm(cardStyle, brandLogo)
        break;

        case 'amex':
            cartTypeStyle = {
                position:"absolute",
                top:"0px",
                right:"20px"
            }
             cardStyle ={
                position:"relative",
                width: "323.52px",
                height: "204px",
                backgroundColor:"#191970",
                background: "radial-gradient(#ff66b3, #660033)", 
                margin: "10px",
                borderRadius:"15px",
                color: "white",
                boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
            }
             brandLogo =  <div style={cartTypeStyle}>  <img src={americanExpress} alt={"empty image"} style={{width: "90px" }}/></div>
            creditCard =  cardForrm(cardStyle, brandLogo)
        break;

        case 'discover':
            cartTypeStyle = {
                position:"absolute",
                top:"25px",
                right:"20px"
            }
             cardStyle ={
                position:"relative",
                width: "323.52px",
                height: "204px",
                backgroundColor:"#191970",
                background: "radial-gradient(ellipse at right bottom, #4d0000 5%,  #ff6666 25%, #d42dab 50%, #ff9999 100%)", 
                margin: "10px",
                borderRadius:"15px",
                color: "white",
                boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
            }
             brandLogo =  <div style={cartTypeStyle}>  <img src={discover} alt={"empty image"} style={{width: "130px" }}/></div>
            creditCard =  cardForrm(cardStyle, brandLogo)
        break;

        case 'diners':
            cartTypeStyle = {
                position:"absolute",
                top:"0px",
                right:"20px"
            }
             cardStyle ={
                position:"relative",
                width: "323.52px",
                height: "204px",
                backgroundColor:"#191970",
                background: "radial-gradient(ellipse at right bottom, #0077b3 10%, #80d4ff 30%,  #66ccff 70%, #1ab2ff 100%)", 
                margin: "10px",
                borderRadius:"15px",
                color: "white",
                boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
            }
             brandLogo =  <div style={cartTypeStyle}>  <img src={dinersClub} alt={"empty image"} style={{width: "85px" }}/></div>
            creditCard =  cardForrm(cardStyle, brandLogo)
        break;

        case 'jcb':
            cartTypeStyle = {
                position:"absolute",
                top:"1px",
                right:"20px"
            }
             cardStyle ={
                position:"relative",
                width: "323.52px",
                height: "204px",
                backgroundColor:"#191970",
                background: "radial-gradient(ellipse at right bottom, #8080ff 5%, #1a1aff 25%, #1a1aff 60%, #000080 100%)", 
                margin: "10px",
                borderRadius:"15px",
                color: "white",
                boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
            }
             brandLogo =  <div style={cartTypeStyle}>  <img src={jcb} alt={"empty image"} style={{width: "85px" }}/></div>
            creditCard =  cardForrm(cardStyle, brandLogo)
        break;

        case 'unionpay':
            cartTypeStyle = {
                position:"absolute",
                top:"15px",
                right:"20px"
            }
             cardStyle ={
                position:"relative",
                width: "323.52px",
                height: "204px",
                backgroundColor:"#191970",
                background: "linear-gradient(angle, colour-stop1, colour-stop2)", 
                margin: "10px",
                borderRadius:"15px",
                color: "white",
                boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.5)"
            }
             brandLogo =  <div style={cartTypeStyle}>  <img src={unionPay} alt={"empty image"} style={{width: "85px" }}/></div>
            creditCard =  cardForrm(cardStyle, brandLogo)
        break;

      
    }
  return creditCard
}     

return (
         <>
         {selCard(brand)}
         </>
    )
        ;
    
}

export default CreditCardType