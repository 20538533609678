import React, { useState }  from "react";
import { Link} from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import RadioBox from './RadioBox'
import RadioBoxCondition from './radioBoxCondition'
import RadioboxRating from './radioboxRating'
import Checkbox1 from "./Checkbox1";
import { MdClose} from "react-icons/md";
import { elakay_API } from "../../config";
import { laguangeElakay } from "../pageInside/dataElakay";
// import { Link } from "react-router-dom";

const Filters = props => {
    // const [filters, setFilters] = useState([]);
    // const [unCH, setUnCh] = useState(true);
    const [checked, setCheked] = useState([])
    const [useC, setUseC] = useState(false);
    const [newC, setNewC] = useState(false);
    const [stars1, setStars1] = useState(false);
    const [stars2, setStars2] = useState(false);
    const [stars3, setStars3] = useState(false);
    const [stars4, setStars4] = useState(false);
    const [stars5, setStars5] = useState(false);


    const allStars = {
        stars1, 
        setStars1,
        stars2, 
        setStars2,
        stars3, 
        setStars3,
        stars4, 
        setStars4,
        stars5, 
        setStars5,
    }
    const { 
        infoSource, 
        e1 =  true,
        categoryID,
        handleFilters,
        prices,
        click,
        setMyFilters,
        AllCategorie = false
     } = props

     const {
        filter = [],
        items = []
     } = AllCategorie ? infoSource.CategoriesProd : infoSource.categories


    const FilterCategoryID = categoryID ? categoryID : "5ed4946fd2ba8812c20261a8"
    
    
const clickButton =()=>{
    setUseC(false)
    setNewC(false)
    setStars1(false)
    setStars2(false)
    setStars3(false)
    setStars4(false)
    setStars5(false)
    setMyFilters( {filters: {}})
    let a = []
    setCheked([])
    var inputs = document.querySelectorAll('.check2');
    for (var i = 0; i < inputs.length; i++) {
        inputs[i].checked = false;
    }
    setMyFilters( {filters: {}})
 
}

    const F1 = e1? true: false
    
    const FilterProduct = () => (
        <>
            <h3 className="m-2">{infoSource.laguangeElakay('title', infoSource.languageAPI).filters} <MdClose className=" mt-1 mr-3 float-right h4" onClick={click} /></h3>
            <Accordion>
                {F1 && (
                   
                    <Accordion.Item eventKey="184">
                        <Accordion.Header>{infoSource.laguangeElakay('title', infoSource.languageAPI).categoriesPage}</Accordion.Header>
                        <Accordion.Body>
                            {
                            items &&  items.map((c, i) => (

                                    <Link key={i} to={`/product/${c.supCategoryID._id && c.supCategoryID._id}`}> <h5  className="m-1" style={{ borderBottom: "1px solid #258ecd", }}>
                                        {/* {c.supCategoryID.name[0]} */}
                                        {infoSource.getCategoryNames(c).name[0]}
                                        </h5></Link>
                                ))
                                }
                        </Accordion.Body>
                    </Accordion.Item>
                )}
                { 
               prices && (
                <>
                
                            <Accordion.Item eventKey="284">
                                <Accordion.Header>{infoSource.laguangeElakay('title', infoSource.languageAPI).filterPrice}</Accordion.Header>
                                <Accordion.Body>
                                    <RadioBox
                                        infoSource={infoSource}
                                        prices={prices}
                                        handleFilters={filtersC =>
                                            handleFilters(filtersC, "price")
                                        }
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                    
                    </>)}
                    
                 {!F1 &&  <Checkbox1
                        infoSource={infoSource}
                        filters={filter}
                        checked={checked} 
                        setCheked={setCheked}
                         handleFilters={(filtersC, color )=>
                        handleFilters(filtersC, color)
                         }
                    />}
            {handleFilters && (
                    <>
                       
                        <Accordion.Item eventKey="384">
                                <Accordion.Header>{infoSource.laguangeElakay('title', infoSource.languageAPI).condition}</Accordion.Header>
                            <Accordion.Body>
                                    <RadioBoxCondition
                                        infoSource={infoSource}
                                        useC={useC}
                                        setUseC={setUseC}
                                        newC={newC}
                                        setNewC={setNewC}
                                        handleFilters={filtersC =>
                                            handleFilters(filtersC, "condition")
                                        }
                                    />
                            </Accordion.Body>
                        </Accordion.Item>
                        
                        <Accordion.Item eventKey="584">
                                <Accordion.Header>{infoSource.laguangeElakay('title', infoSource.languageAPI).rating}</Accordion.Header>
                            <Accordion.Body>
                                    <RadioboxRating
                                    infoSource={infoSource}
                                    allStars={allStars}
                                        handleFilters={filtersC =>
                                            handleFilters(filtersC, "Stars")
                                        }
                                    />
                            </Accordion.Body>
                        </Accordion.Item>
                     </>
                )}
            </Accordion>
            <Button
                variant="outline-dark"
                size="sm"
                style={{ cursor: "pointer", color: "#000000" }}
                onClick={() => clickButton()}
            >
               {infoSource.laguangeElakay('Button', infoSource.languageAPI).clearAll}
            </Button>
             </>
        
    )
  

    return (
       
        FilterProduct()
      
    )
}
export default Filters;