import React, { useState } from "react";
import { FaRegTrashAlt,  FaEdit } from "react-icons/fa";
const EditableFilter = (props) => {
    const {
    filter,
    clickSubmitEdit
    } = props

    let fil123 = {
        name: "color",
        FilterItem: [
          "black",
          "gray",
          "gold",
          "silver",
          "white",
          "aquamarine",
          "blue",
          "royalblue",
          "Red"
        ]
    }
    const [data, setData] = useState(filter)

  // Handle name change
  const handleNameChange = (e) => {
    setData({ ...data, name: e.target.value });
  };

  // Handle filter item change
  const handleFilterChange = (index, newValue) => {
    const updatedFilters = [...data.FilterItem];
    updatedFilters[index] = newValue;
    setData({ ...data, FilterItem: updatedFilters });
  };

  // Add a new filter item
  const addFilterItem = () => {
    setData({ ...data, FilterItem: [...data.FilterItem, ""] });
  };

  // Remove a filter item
  const removeFilterItem = (index) => {
    const updatedFilters = data.FilterItem.filter((_, i) => i !== index);
    setData({ ...data, FilterItem: updatedFilters });
  };

  return (
    <div>
      {/* Name Field */}
      <label  className="itemCategory m-4" >
      <span style={{fontSize: "30px"}}> Name : </span> 
        <input 
        style={{fontSize: "26px", marginLeft: "10px"}}
        className="inputCategoryFil"
          type="text" 
          value={data.name} 
          onChange={handleNameChange} 
        />
      </label>
        <div className="m-4">
      {/* Filter Items */}
      <h3>Filter Value</h3>
      {data.FilterItem.map((item, index) => (
        <div  className="itemCategory" key={index} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <input
           className="inputCategoryFil"
            type="text"
            value={item}
            onChange={(e) => handleFilterChange(index, e.target.value)}
          />
          <FaRegTrashAlt onClick={() => removeFilterItem(index)}/>
        </div>
      ))}

      <button className="AddCategory float-left m-4" onClick={addFilterItem}>+</button>
      <button className="btn btn-outline-info float-right m-4 pt-2 pb-2 pr-3 pl-3 rounded-4" onClick={()=>clickSubmitEdit(filter.filter_id, data)}>submit</button>
     
      </div>
    </div>
  );
};

export default EditableFilter;