import React, { useState, useEffect } from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import logoElakay from './includes/Layout/icon/logoelakay.ico';
import { FirtIDCategory } from "./config";
// permission pages
import { signout, readMe, isAuthenticated, getElakayLang, SetElakayLang,  getCart} from "./includes/auth";
import { getCategoriesByID } from "./includes/auth/apiCategory";
import UserSignin from "./includes/auth/userSignin"
// import UserElakay from "./includes/auth/userCustomer"
import UserSeller from "./includes/auth/userSeller"
// import UserProvider from "./includes/auth/userProvider"
import UserAssoociate from "./includes/auth/userAssoociate"
// import UserInvestment from "./includes/auth/userInvestment"
import UserAdmin from "./includes/auth/userAdmin"
import UserFamily from "./includes/auth/userFamily"
import Loader1 from './includes/Layout/imgs/loading3.gif';
import {laguangeElakay,  language} from "./includes/pageInside/dataElakay";
import menuElakay from "./menuElakay.json";
import {LanguageElakayByLanguage} from "./includes/auth/apiLanguage"
import {allDeviceUserArgent} from "./includes/auth/checkDevice"
//  ---------- user --------- 

import Signup from "./user/Signup";
import Signin from "./user/Signin";
import Active from "./user/active";
import ForgetPassword from "./user/forgetPassword";
import Password from "./user/password"


// ----------------Page for All User---------------------- 
import Home from "./includes/pages/home";
import Step from "./includes/pages/userStep"
import ProductPreview from "./includes/pages/productPreview";
import ShopUser from "./includes/pages/ShopUser";
import ErrorPage from "./includes/pages/errorPage";
import NewProduct from "./includes/pages/newProduct";
import BestSellers from "./includes/pages/bestSellers";
import ProductCatalog from "./includes/pages/productCatalog";
import Search from "./includes/pages/resultsSearch";
import AllCategories from "./includes/pages/allCategories"
import HelpContart from "./includes/pages/helpContact"
import DealToday from "./includes/pages/dealtoday"
import UserView from "./includes/pages/userViewed"


//----------------Page for All User + top---------------------- 
import Cart from "./includes/pages/Cart";
import UserProduct from "./includes/pages/userProduct"
import EditProduct from "./includes/pages/editProduct"
import Wallet from "./includes/pages/wallet"
import HowToStep from "./includes/pages/howToStep"; 

import UserSerting from "./includes/pages/userSerting"
import Account from "./includes/pages/userAccount" 
import Profile from "./includes/pages/userProfile"  

import Orders from "./includes/pages/Orders"
import Purchased from "./includes/pages/userPurchased"
import Message from "./includes/pages/userMessage"

import Admin_associate from "./includes/pages/admin_associate"
import UserAddingRoleByToken from "./includes/pages/addUserPage/userAddingRoleByToken";

//  -------- Category for admin & familly --------- 
import CreatCategory from "./includes/pages/addCategory"
import CreatFilters from "./includes/pages/addFilters"
import CreatFiltersValue from "./includes/pages/addFilterValues"
import CreatFilerCategory from "./includes/pages/addCategoryFilter"
import CreatfilterValue from "./includes/pages/addCategoryFilterValue"
import AdminFamily from "./includes/pages/addAdminFamily"


import AddLanguage from "./includes/pages/AddLanguage";

import AddTraductorTeam from "./includes/pages/AddTraductorTeam";
import LinkCatToCat from "./includes/pages/LinkCatToCat";
import imgLakay1 from './includes/Layout/imgs/Elakay-account-1.png';
import imgLakay2 from './includes/Layout/imgs/Royalty-user-1.png';
import imgLakay3 from './includes/Layout/imgs/Seller-pub-1.png';

const pub = {
    promoInfo:{ 
                "_id": "null",
                "prod_id": "null",
                "customer_id": "null",
    }, 
    success: false,   
    promoUrl: {
       "urlProdView": null,
       "pastProdView": null,
       "urlShop": null,
       "pastShop": null
            }, 
    error: "Promo is not laoding."
        }
const RoutesElakay = () => { 
    const [getPromoInfo, setPromoInfo] = useState({pubShow: true, pub})
    const [languageAPI, setLanguageAPI] = useState([]); 
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]); 
    const [getpub, setPub] = useState([{ "urlImg": Loader1, "_id":1}]);
    const [filters, setFilters] = useState([]); 
    const [userInfo, setUserInfo] = useState({});
    const [getCartItems, setgetCart] = useState([])
    const [userRole, setUserRole] = useState([]);
    const [address, setAddress] = useState([]);
    const [phoneUser, setPhoneUser] = useState([]);
    const [elakayCategories, setElakayCategories] = useState([]);
    const [userAdmin, setUserAdmin] = useState([]);
    const { token } = isAuthenticated();
    const [loading, setLoading] = useState(true);
    const [showConditionsRole, setConditionsRole] = useState(false);
    const [getShopFollow, setShopFollow] = useState([])
    const [getPubToken, setPubToken] = useState([])
    const {
        device, 
        model, 
        browser, 
        navigation,
        headShop
        } = allDeviceUserArgent()

      const readUser = (token)=> {
            token && (
            readMe(token).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                    signout(token).then(() => { window.location.href = '/signin' })
                } else {
                    setUserInfo(data.user)
                    setAddress(data.address)
                    setUserRole(data.role)
                    setPhoneUser(data.telephone);
                    setShopFollow(data.user.followShopUser)
                    setUserAdmin(data.user.userActiveRole.userAdmin)
                    setPubToken(data.user.Pub)
                    if(data.role.role !== null && data.role.agreeRole === false){
                        setConditionsRole(true)
                    }
                   
                }

            })
            )
        };

        const loadCategory = IdSel => {
            let ID = IdSel ? IdSel: FirtIDCategory
            getCategoriesByID(ID).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                } else {
                    setCategories(data.cartegory)
                    setItems(data.items)
                    setFilters(data.filter)
                    setPub(data.pub.length > 0 ? data.pub : [{ "urlImg": imgLakay1, "_id": 1 }, { "urlImg": imgLakay2, "_id": 2 }, { "urlImg": imgLakay3, "_id": 3 }])
                    elakayCategories.length <= 0 && setElakayCategories(data.items)
                }
            })
        };

        const cartItems = (token) => {
            token && ( 
            getCart(token).then(data => {
                if (!data) {
                    console.log("data not found")
                } else {
                   setgetCart(data.cart)
                }
            })
            )
        }
        
        
   

        useEffect(() => {
            cartItems(token)
            readUser(token)
            loadCategory()
            getLangaugeAPI()
        }, [token]);

            const isNullOrEmpty = (value)=> {
                // Check for null, undefined, or false
                if (value == null || value === false) return true;
            
                // Check if the value is an empty object or array
                if (typeof value === 'object') {
                    return Object.keys(value).length === 0;
                }
                // Otherwise, return false (the value is neither null, undefined, false, nor empty)
                return false;
            }

        const isArrayNullOrEmpty = (arr)=> {
            return Array.isArray(arr);
        }

        const getObjectKeyLength = (api, key) => {
            // Vérifie si api ou key est null, vide ou indéfini
            if (!api || !key || typeof api[key] === 'undefined' || api[key] === null) {
              return 0; // Retourne 0 si l'une des conditions est vraie
            }
            // Retourne la longueur des clés de l'objet
            return Object.keys(api[key]).length;
          }


            const checkProdKeyExit = (api, elJSON, key)=>{ 
               
                if(isNullOrEmpty(api)){
                 if(isNullOrEmpty(key)){
                        return elJSON
                        }else{
                        return elJSON[key]
                        }
                }else{
    
                    if(isNullOrEmpty(key)){
                    return api
                    }else{    
                       if(isArrayNullOrEmpty(api[key])){
                        if(api[key].length > 0){

                         return api[key]
                        }else{
                           return elJSON[key]  
                        }   
                       }else{
                        if(getObjectKeyLength(api, key) === Object.keys(elJSON[key]).length){
                        return api[key]
                        }else{
                        return elJSON[key]
                        }
                    }}}}
        
        const checkLangDefault = (a, b, c)=>{
            return checkProdKeyExit(a, b)
         }

         const checkLangObjDefault = (a, b, c)=>{
           return checkProdKeyExit(a, b, c)
         }

         const getCategoryNames = (data, racine = false)=>{
            let enLang = racine? data:  data.supCategoryID
            let translations = racine? data.lang :  data.supCategoryID.lang  
           
            const {obj} = getElakayLang();
            const {code }= obj
            let checkLang = translations === undefined || !translations || Object.keys(translations).length === 0

            // 1) Si la langue est 'en', on retourne la clé "en" si elle existe.
            if(code == 'en'){
                return enLang || {}
            }
         // 2) Si l'objet translations est vide ou non défini, on retourne un objet vide ou la clé "en".
            if (checkLang) {
                return enLang || {}; // ou translations.en, selon votre préférence
            }
             // 3) Sinon, on essaie de retourner la langue sélectionnée.
            //    Si elle n'existe pas, on retourne 'en' par défaut.
            return translations[code] || enLang
        
         }

         const getFilTranslate = (data)=>{
            let fil = data._id.name? data._id : data
            let translations = fil.lang
            let itemsInEnglish  =  data.Filters.length === 0 ? fil.FilterItem : data.Filters
            const {obj} = getElakayLang();
            const {code }= obj
            let checkLang = translations === undefined || !translations || Object.keys(translations).length === 0

            // 1) Si la langue n'existe pas, on force "en"
            // const lang = checkLang && translations[code] ? code : "en";
            const lang = code === "en" && !checkLang ? "en" : code

            // 2) Récupère le name et FilterItem en anglais et dans la langue cible
            const enName = fil.name || "";
            const enArray = fil.FilterItem || [];

            const targetName = code === "en" ?  enName : !checkLang ? translations[lang].name : enName;
            const targetArray = code === "en" ? enArray : !checkLang ? translations[lang].FilterItem : enArray;

    
    // Cas c) Si englesh selected as "en",
  //        on veut retourner enArray sous forme de matrice triée.
  // -----------------------------------------------------------------------------------
  if (code == 'en') {
    // On transforme enArray => [ [val, val], [val, val], ... ]
    let matrix = enArray.map((val) => [val, val]);

    // On trie la matrice sur le second élément (index 1)
    matrix.sort((a, b) => {
      if (a[1] < b[1]) return -1;
      if (a[1] > b[1]) return 1;
      return 0;
    });

    // On retourne le name enName et la matrice
    return {
      name: enName,
      FilterItem: matrix
    };
  }

    // Cas A) Si `checkLang` est TRUE,
  //        on veut retourner enArray sous forme de matrice triée.
  // -----------------------------------------------------------------------------------
  if (checkLang) {
    // On transforme enArray => [ [val, val], [val, val], ... ]
    let matrix = enArray.map((val) => [val, val]);

    // On trie la matrice sur le second élément (index 1)
    matrix.sort((a, b) => {
      if (a[1] < b[1]) return -1;
      if (a[1] > b[1]) return 1;
      return 0;
    });

    // On retourne le name enName et la matrice
    return {
      name: enName,
      FilterItem: matrix
    };
  }

  const matrix = itemsInEnglish.map((item) => {
    const indexEn = enArray.indexOf(item);
    if (indexEn !== -1) {
      return [item, targetArray[indexEn]];
    }
    // Si l'item n'existe pas dans enArray, on fait une paire doublée
    return [item, item];
  });

  // On trie la matrice sur la 2e colonne (la valeur cible)
  matrix.sort((a, b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    return 0;
  });

  // Retour de la matrice
  return {
    name: targetName,
    FilterItem: matrix
  };
        
         }

         const getLangaugeAPI = () =>{
            const {obj} = getElakayLang();
            let elakayInfo = menuElakay[0]
            if(obj){ 
            LanguageElakayByLanguage(obj , false).then(data => {
                if (data.error) {
                    // SetElakayLang({"code":"en","name":"English","nativeName":"English"}, 0)  
                    setLanguageAPI({elakayInfo, elakayInfoAPI:{}, checkLangDefault, checkLangObjDefault})
                    setLoading(false) 
                    console.log(data.error);
                } else {
                 setLanguageAPI({elakayInfo, elakayInfoAPI: data, checkLangDefault, checkLangObjDefault,  loading: false})
                  setLoading(false)  
                }
            });
        }else{
            SetElakayLang({"code":"en","name":"English","nativeName":"English"}, 0)  
            setLanguageAPI({elakayInfo, elakayInfoAPI:{}, checkLangDefault, checkLangObjDefault,  loading: false})
            setLoading(false)
        }
        
         }
       
         let styleNav = {
            width: '100%', 
            overflowX: 'hidden', 
            overflowY: 'auto',
            position: 'relative',
            margin: 0,
            padding: 0,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
             }

        const infoSource = {
                            token,
                            showConditionsRole, 
                            setConditionsRole,
                            getShopFollow,
                            setShopFollow,
                            categories,
                            items,
                            filters,
                            getCartItems,
                            cartItems,
                            userInfo,
                            userAdmin,
                            address,
                            phoneUser,
                            userRole,
                            elakayCategories,
                            getPubToken,
                            setCategories,
                            setItems,
                            setFilters,
                            loadCategory,
                            readUser,
                            getpub,
                            loading,
                            getLangaugeAPI,
                            languageAPI,
                            navigation,
                            headShop,
                            styleNav,
                            laguangeElakay,
                            getCategoryNames,
                            getFilTranslate,
                            language,
                            getPromoInfo, 
                            setPromoInfo
                            }

     return (
        !loading && <BrowserRouter>
              <Routes>
                 {/* ---------- user --------- */}
                 <Route path="/image/logoElakayIco.ico" element={<img style={{ width: "50%" }} src={logoElakay} alt="logo elakay"/>} />  
                   {/* ---------- user --------- */}
                       <Route path="/signin" element={<Signin infoSource ={infoSource}  />} />  
                       <Route path="/signup" element={<Signup infoSource ={infoSource}  />} />
                       <Route path="/active/:token" element={<Active infoSource ={infoSource}  />} />   
                       <Route path="/forget-password" element={<ForgetPassword infoSource ={infoSource} />} />
                       <Route path="/password/:token" element={<Password infoSource ={infoSource}  />} />         
                        <Route path="/userPUB/:token" element={<Home infoSource ={infoSource} />} />
                        
                   {/* ----------------Page for All User---------------------- */}
                
                  <Route path="/" element={<Home infoSource ={infoSource} />} /> 
                 
                 <Route path="/product/preview/:productId" element={<ProductPreview infoSource ={infoSource} />}/>
                 <Route path="/new-product" element={<NewProduct infoSource ={infoSource}/>}/>
                 <Route path="/best-sellers" element={<BestSellers infoSource ={infoSource} />} />
                 <Route path="/product/:categoryId" element={<ProductCatalog infoSource ={infoSource} />} />
                <Route path="/search" element={<Search infoSource ={infoSource} />} />
                 <Route path="/all-catageories" element={<AllCategories infoSource ={infoSource} />}/>
                 <Route path="/help-contact" element={<HelpContart infoSource ={infoSource} />} />
                 <Route path="/deal-today" element={<DealToday infoSource ={infoSource} />} />
                  <Route path="/how-to-Step" element={<HowToStep infoSource ={infoSource} />}/>
                <Route path="/lakay-ShopSeller/:id" element={<ShopUser infoSource ={infoSource} />}/>
                  <Route path="*" element={<ErrorPage infoSource ={infoSource}/>} />


                 {/* ---------Page for All us er Sing in---------- */}

                 <Route path="/your-step" element={<UserSignin> <Step infoSource ={infoSource} /></UserSignin>} /> 
                 <Route path="/cart" element={<UserSignin> <Cart infoSource ={infoSource} /> </UserSignin>} />
                 <Route path="/user-viewed" element={<UserSignin> <UserView infoSource ={infoSource}/> </UserSignin>}/>
                 <Route path="/profile" element={<UserSignin> <Profile infoSource ={infoSource} /> </UserSignin>} />
                 <Route path="/c/orders" element={<UserSignin> <Orders infoSource ={infoSource}/> </UserSignin>}/>
                 <Route path="/account" element={<UserSignin> <Account infoSource ={infoSource}/> </UserSignin>} />
                 <Route path="/purchased" element={<UserSignin> <Purchased infoSource ={infoSource}/> </UserSignin>} />
                 <Route path="/message" element={<UserSignin> <Message infoSource ={infoSource}/> </UserSignin>} />
                <Route path="/wallet" element={<UserSignin> <Wallet infoSource ={infoSource}/> </UserSignin>}/>

                 {/* ---------Page for All Seller, Provider, Admin, Family---------- */}
                 <Route path="/user-serting" element={<UserSeller ><UserSerting infoSource ={infoSource}/></UserSeller>} />

                 <Route path="/myShop" element={<UserSeller ><UserProduct infoSource ={infoSource}/></UserSeller>} />
                 <Route path="/product/edit/:productId" element={<UserSeller ><EditProduct infoSource ={infoSource}/></UserSeller>} />

                {/* ______________Become a Associate Page_____________ */}
                <Route path="/user_Shoosing/:token" element={<UserAddingRoleByToken infoSource ={infoSource}  />} /> 
                <Route path="/Add-u-all" element={<UserAssoociate ><AdminFamily infoSource ={infoSource}/></UserAssoociate>} />
                 {/* ------------Page for All Administrator And Family-------------------- */}

                 <Route path="/admin_associat" element={<UserAssoociate> <Admin_associate infoSource = {infoSource}/></UserAssoociate>}/>
                 <Route path="/elakay-language" element={<UserAdmin ><AddLanguage infoSource ={infoSource}/></UserAdmin>} />
                  
                 
                 <Route path="/traductor-team" element={<UserFamily ><AddTraductorTeam infoSource ={infoSource} /></UserFamily>}/>
                 <Route path="/category" element={<UserFamily ><CreatCategory infoSource ={infoSource} /></UserFamily>}/>
                 <Route path="/filters" element={<UserFamily ><CreatFilters infoSource ={infoSource}/></UserFamily>}/>
                 <Route path="/filters/items/:filterID" element={<UserFamily ><CreatFiltersValue infoSource ={infoSource}/></UserFamily>} />
                 <Route path="/filter/:categoryId" element={<UserFamily ><CreatFilerCategory infoSource ={infoSource}/></UserFamily>} />
                 <Route path="/LinkCategory/:categoryId" element={<UserFamily ><LinkCatToCat infoSource ={infoSource}/></UserFamily>} />
                 <Route path="/filter/:categoryId/:filterId" element={<UserFamily ><CreatfilterValue infoSource ={infoSource}/></UserFamily>} />
              </Routes>
        </BrowserRouter>
        );
};

export default RoutesElakay;