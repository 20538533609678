import {getElakayLang } from "../auth";
import menuElakay from "../../menuElakay.json";

export const DataElakay = () => {
    const { id } = getElakayLang();
    let elakayInfo = menuElakay[0]

    return elakayInfo ;
}

export const ID = () => {
    const { id } = getElakayLang();
    
    return id ;
}

const checkIfFunction = (f)=>{
  return typeof f === "function" ? true : false
}
function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const laguangeElakay = (a, b) => {
    let ilcod = b.checkLangObjDefault
    let ileia =  b.elakayInfoAPI
    let ilei =  b.elakayInfo
    let il = !b.loading  &&  checkIfFunction(ilcod)
    let keysElakayInfo = Object.keys(ilei)

    if (keysElakayInfo.includes(a)) {
      return  il && ilcod(ileia, ilei, a)
      }else if (a === 'all') {
        return il && ileia
      } else {
        return il && ilei
      }
}

export const language = (a, b, c) => {
  return laguangeElakay(b, a.languageAPI)[c]
}



 // if (a === 'headNenu') {
    //     return  il && ilcod(ileia, ilei, 'headNenu')
    //   } else if (a === 'menuList') {
    //     return il && ilcod(ileia, ilei, 'menuList')
    //   }  else if (a === 'menuSertting') {
    //     return il && ilcod(ileia, ilei, 'menuSertting')
    //   } else if (a === 'filters') {
    //     return il && ilcod(ileia, ilei, 'filters')
    //   } else if (a === 'text') {
    //     return il && ilcod(ileia, ilei, 'text')
    //   } else if (a === 'title') {
    //     return il && ilcod(ileia, ilei, 'title')
    //   } else if (a === 'buy') {
    //    return il && ilcod(ileia, ilei, 'buy')
    //   } else if (a === 'sell') {
    //     return il && ilcod(ileia, ilei, 'sell')
    //   } else if (a === 'helpContact') {
    //     return il && ilcod(ileia, ilei, 'helpContact')
    //   } else if (a === 'Button') {
    //     return il && ilcod(ileia, ilei, 'Button')
    //   } else if (a === 'label') {
    //     return il && ilcod(ileia, ilei, 'label')
    //   } else if (a === 'Link') {
    //     return il && ilcod(ileia, ilei, 'Link')
    //   } else if (a === 'aboutUs') {
    //     return il && ilcod(ileia, ilei, 'aboutUs')
    //   } else if (a === 'signinPage') {
    //     return il && ilcod(ileia, ilei, 'signinPage')
    //   } else if (a === 'sellPage') {
    //     return il && ilcod(ileia, ilei, 'sellPage')
    //   } else if (a === 'homePage') {
    //     return il && ilcod(ileia, ilei, 'homePage')
    //   } else if (a === 'profilePage') {
    //     return il && ilcod(ileia, ilei, 'profilePage')
    //   } else if (a === 'walletPage') {
    //     return il && ilcod(ileia, ilei, 'walletPage')
    //   } else if (a === 'makeYourFstep') {
    //     return il && ilcod(ileia, ilei, 'makeYourFstep')
    //   } else if (a === 'becomeRoyalty') {
    //     return il && ilcod(ileia, ilei, 'becomeRoyalty')
    //   } else if (a === 'becomeSeller') {
    //     return il && ilcod(ileia, ilei, 'becomeSeller')
    //   } else if (a === 'LinkcustomerShop') {
    //     return il && ilcod(ileia, ilei, 'LinkcustomerShop')
    //   } else if (a === 'becomeProvider') {
    //     return il && ilcod(ileia, ilei, 'becomeProvider')
    //   } else if (a === 'termConditProdSel') {
    //     return il && ilcod(ileia, ilei, 'termConditProdSel')
    //   } else if (a === 'catalogProdSel') {
    //     return il && ilcod(ileia, ilei,'catalogProdSel')
    //   } else if (a === 'infoProdSel') {
    //     return il && ilcod(ileia, ilei, 'infoProdSel')
    //   } else if (a === 'filterProdSel') {
    //     return il && ilcod(ileia, ilei, 'filterProdSel')
    //   } else if (a === 'imageProdSel') {
    //     return il && ilcod(ileia, ilei, 'imageProdSel')
    //   }  else if (a === 'planUserRoyal') {
    //     return il && ilcod(ileia, ilei, 'planUserRoyal')
    //   } else if (a === 'termConditionRoyal') {
    //     return il && ilcod(ileia, ilei, 'termConditionRoyal')
    //   } else if (a === 'royalAgree') {
    //     return il && ilcod(ileia, ilei, 'royalAgree')
    //   } else if (a === 'planUserSeller') {
    //     return il && ilcod(ileia, ilei, 'planUserSeller')
    //   } else if (a === 'termConditionSeller') {
    //     return il && ilcod(ileia, ilei, 'termConditionSeller')
    //   } else if (a === 'sellerAgree') {
    //     return il && ilcod(ileia, ilei, 'sellerAgree')
    //   } else if (a === 'planUserProvider') {
    //     return il && ilcod(ileia, ilei, 'planUserProvider')
    //   } else if (a === 'termConditionProvider') {
    //     return il && ilcod(ileia, ilei, 'termConditionProvider')
    //   } else if (a === 'providerAgree') {
    //     return il && ilcod(ileia, ilei, 'providerAgree')
    //   }else if (a === 'planAffaireElakay') {
    //     return il && ilcod(ileia, ilei, 'planAffaireElakay')
    //   } else if (a === 'termConditionAssociate') {
    //     return il && ilcod(ileia, ilei, 'termConditionAssociate')
    //   } else if (a === 'associateAgree') {
    //     return il && ilcod(ileia, ilei, 'associateAgree')
    //   }  else if (a === 'elakayMail') {
    //     return il && ilcod(ileia, ilei, 'elakayMail')
    //   } else if (a === 'elakayTicket') {
    //     return il && ilcod(ileia, ilei, 'elakayTicket')