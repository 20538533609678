import React from "react";
import { isAuthenticated, removeItem } from "../auth";
import { FiDelete } from "react-icons/fi";
import Unavailable from '../Layout/icon/Unavailable.png'
import Empty from '../Layout/icon/Empty.png'

const urlFilter = (filter) =>{
    let texFil = ""
    for (let fil in filter){
        texFil +=  fil+"="+filter[fil]+"&"
    }
    return "?"+texFil
}

const Card = ({
    product,
    cartItems
}) => {
    const { token } = isAuthenticated();
    const CartID = product.cartID

    const deleteCart = (token, c, p) => {
        removeItem(token, { "count": c, "CartID": p }).then(data => {
            if (!data) {
                console.log("cart deleted");
            } else {
                cartItems(token)

            }
        })
    }


    let urlFil =  urlFilter(product.filterPro.filter)

    const buttonDeleteCart = () => {
        deleteCart(token, 0, CartID)
    }

    return (
        <div className="card mt-2" style={{ minHeight: "134px" }}>
            <div className="row"> 
                <table className="col-10 col-md-11 col-lg-11" >
                <a  href={product._id && `/product/preview/${product._id}${urlFil}`} >
                    <tbody>
                        <tr className="row">
                            <td className="col col-5 col-md-5 col-lg-5" style={{ maxWidth: "200px" }}>

                                <img
                                    src={Empty}
                                    alt={"empty image"}
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </td>

                            <td className="col col-7 col-md-7 col-lg-7">
                                <img
                                    src={Unavailable}
                                    alt="Unavailable image"
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </td>

                        </tr>
                    </tbody>
                    </a>
                </table>
                
                <div className="col-2 col-md-1 col-lg-1">
                    <span ><p style={{ textAlign: "right", fontSize: "25px", paddingRight: "14px" }} onClick={buttonDeleteCart}><FiDelete /></p> </span>
                </div>
               
            </div>

        </div>

    );
};

export default Card;