import React, { useState, useEffect } from "react";
// import Layout from "../Layout/Layout";
import {isAuthenticated, sellerlistOrdersStatus } from "../auth";
import Card from '../pageInside/CardOrders2'
import {useLocation,  Link} from "react-router-dom";
import {laguangeElakay} from "../pageInside/dataElakay";
import imgemptyLakay from '../Layout/imgs/no-orders.png' 
import Modal from 'react-bootstrap/Modal'
import FiltersOrders from "../pageInside/filersOrder";
import ButtonPagination from "../pageInside/ButtonPagination";
import Shipped from "../pages/orderShipped"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// ?oid="6768a690d7ba862d29652bcb"&pid="67664f839fd670bbbf03d019"&opid="6768a690d7ba862d29652bcc"
// ?oid=6768a690d7ba862d29652bcb&pid=67664f839fd670bbbf03d019&opid=6768a690d7ba862d29652bcc

const Orders = (props) => {
    const {
        infoSource
            } = props

            let filorder = {
                orderStatus: "none",
                orderDate: 'months',
                myd: 1
            }
        const query = useQuery();
        const [limit, setLimit] = useState(5);
        const [skip, setSkip] = useState(0);
        const [sizeOrders, setSizeOrders] = useState(0);
        const [filtOrders, setFiltOrders] = useState(filorder);
        const [showFilterOrder, setShowFilterOrder] = useState(false);
    const [orders, setOrders] = useState([]);

    const [showOrderView, setShowOrderView] = useState(false);
    const [getOrderID, setOrderID] = useState({oid:'', pid:'', opid:''});

    const {token } = isAuthenticated();

    useEffect(() => {
        laodOrderWalletSeller(token,  limit, filtOrders)
        query.get("oid") && setShowOrderView(true)
        query.get("oid") && setOrderID({oid: query.get("oid"), pid: query.get("pid"), opid: query.get("opid")})

    }, [token,  limit, filtOrders]);

    const bntOderView =(oid, pid,  opid)=>{
        setShowOrderView(true)
        setOrderID({oid, pid, opid})
    }

    const laodOrderWalletSeller = (token, limit, filtOrders) => {

        sellerlistOrdersStatus(token, skip, parseInt(limit), '', filtOrders).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setSizeOrders(data.totalCount[0] && data.totalCount[0].count)
               setOrders(data.orders)
               setSkip(0)
            }
        })
        
    }
    const loadMore = () => {
        let toSkip = skip + parseInt(limit);
        sellerlistOrdersStatus(token, toSkip, limit, '',  filtOrders).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
               setOrders(data.orders)
                setSizeOrders(data.totalCount[0].count)
                setSkip(toSkip)
                // setLoading(false)
            }
        });
    };

    const loadMore0 = () => {
        let toSkip = skip - parseInt(limit);
        sellerlistOrdersStatus(token, toSkip, limit, '',  filtOrders).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
               setOrders(data.orders)
                setSizeOrders(data.totalCount[0].count)
                setSkip(toSkip)
                // setLoading(false)
            }
        });
   
    };


    const showOrdersLength = () => {
        if (orders.length > 0) {
            return (
                <div className="col col-12" style={{ textAlign: "center", backgroundColor: "Gainsboro" }}>
                <h4 className="text-dark m-2 p-0">
                <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>setShowFilterOrder(true)}>
                    {/* Filters */}
                    {infoSource.language(infoSource, "title", "filters")}
                    </div> 
                {infoSource.laguangeElakay('title', infoSource.languageAPI).totalOrder} {(skip - parseInt(limit)) + parseInt(limit) + 1} - {((skip + parseInt(limit)) > sizeOrders) ? sizeOrders : (skip + parseInt(limit))}
                 {/* of  */}
                 {infoSource.language(infoSource, "label", "of")}
                 {sizeOrders}  
                </h4>
                </div>
            );
        } else {
            return <div className="col-md-9" style={{ textAlign: "center"}}>
                  <h4 className="text-dark m-2 p-0">
                <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>setShowFilterOrder(true)}>
                    {/* Filters */}
                    {infoSource.language(infoSource, "title", "filters")}
                    </div> 
                </h4>
                 
                                <img
                                    className="rounded"
                                    src={imgemptyLakay}
                                    alt={"no orders"}
                                    style={{ 
                                        display: 'block',
                                        margin: '0 auto',
                                        width: "100%"
                                     }}
                                />
            </div>
        
        }
    };

    const productALL = (
        <div className="row my-3 p-0 justify-content-center" style={{ backgroundColor: "WhiteSmoke", border: "1px solid Gainsboro" }}>
               {showOrdersLength()}
            {
            orders.map((p, i) => (
        <div key={i} className="container m-2">
            <Card
            key= {i+i}
            laguangeElakay={laguangeElakay}
            infoSource ={infoSource}
            product={p}
            // loadingTrakingOder={loadingTrakingOder}
            bntOderView ={bntOderView}
            bnt ={true}
            // orderURL={`/b/orders/${p.orders_id}/${p.products.productID}/view`}
        />
          </div> 
              ))   
            }
                  { orders.length > 0 && < ButtonPagination
                infoSource ={infoSource}
                size={orders.length}
                siseProduct={sizeOrders}
                skip={skip}
                limit={parseInt(limit)}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />}
        </div>
            ) 
       const filterOrdersView = ()=>{
        return <Modal
        size="md"
        show={showFilterOrder}
        onHide={() => setShowFilterOrder(false)}
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h2>
                {/* Orders Filters */}
                {infoSource.language(infoSource, "title", "ordFilt")}
                </h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FiltersOrders 
         laguangeElakay={laguangeElakay}
         infoSource ={infoSource}
         limit={parseInt(limit)}
         setLimit= {setLimit}
         setFiltOrders={setFiltOrders}
         setShowFilterOrder={setShowFilterOrder}
         filtOrders={filtOrders}
        />

        </Modal.Body>
    </Modal>

    }

    const orderVieux = () => (

        <Modal
            size="xl"
            show={showOrderView}
            fullscreen={true}
            onHide={() => setShowOrderView(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <Shipped 
            setShowOrderView={setShowOrderView}
            infoSource ={infoSource}
            getOrderID={getOrderID}
            /> */}
                 </Modal.Body>
        </Modal>

)

    return (
    <> 
      {showOrderView && <Shipped 
            setShowOrderView={setShowOrderView}
            showOrderView={showOrderView}
            infoSource ={infoSource}
            getOrderID={getOrderID}
        />}
         {filterOrdersView()}
            {productALL}
            {/* {orderVieux()} */}
             </>
    );
};

export default Orders;
