import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { isAuthenticated } from "./index";

const PrivateRoute = ({ 
    redirectPath = '/signin',
    children,
}) => {

    let location = useLocation()
 if (!isAuthenticated()) {
     return  <Navigate
        to={redirectPath} replace state={{ from: location }}
     />;
} 
    <Outlet />
 return children;
}
    

export default PrivateRoute;
