import React, { useState, useEffect, useRef } from "react"
import Layout from "../Layout/Layout";
import { Link, useNavigate, useParams, Navigate} from "react-router-dom";
import {getAddressById, isAuthenticated, addPubCategories} from '../auth';
import { getCategoriesByID, getCategoriesByName } from "../auth/apiCategory";
import { createProduct, createFilterProduct, createImageProduct } from "../auth/apiProduct";
import ImagesLoading from "../pageInside/imagesLoadingAdd"
import { FaLongArrowAltLeft, FaAddressCard} from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
// import AlertPage from "../pageInside/alertPage";
import {GrClose} from "react-icons/gr";
import Loader from '../Layout/loader.gif'
import AddressSeller from '../Layout/imgs/Address-Seller.png'
import CategoriesFilter from '../Layout/imgs/Category-filter-no.png' 
import SellerPub from '../Layout/imgs/Sell_Pro_ch.png' 
import Seller_caribbean from '../Layout/imgs/Seller_caribbean.png' 
import {laguangeElakay} from "../pageInside/dataElakay"; 
import nodata from '../Layout/imgs/nodata0.png';
import CatSelected from "./categories/catSelected";
import CatSearch from "./categories/catSearch";
import ShippingTable from "../pageInside/shippingInfoCadUsa";
import SalesSimulation from "../pageInside/salesSimulation";
import Pages from './LanguagePage'
import {
    ContactUs,
    TermsConditions,
    ReturnAndRefundPolicy
} from '../pageInside/conditionTherm'
import "./addProduct.css" 
import AlertPage from "../pageInside/alertPage";

const categoyseleted = []; 
const categoyIDseleted = [];
let filterArray = [];
const filter =  new Object();
let prodLink = '?ppb=true'
let sellUrl = '/myshop'
const Sell = (props) => {
    const {
        infoSource
        } = props

    let params = useParams()
    const {token} = isAuthenticated();
    const [showConditions, setConditions] = useState(false);
    const [catSelectedSearch, setcatSelectedSearch] = useState(false);
    const [addressSelect, setAddressSelect] = useState(''); 
    const [categories, setCategories] = useState([])
    const [filters, setFilters] = useState([]);
    const [items, setItems] = useState([]);
    // const [filterOne, setFilter] = useState()
    const [loading, setLoading] = useState(true); 
    const [search, setSearch] = useState([]);
    const [shwoSimulationSales, setSimulationSales] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
     const divSelCatego = useRef(null);
     const divProduct = useRef(null);
    const{
        address
    } = infoSource
 
    const scrollToDiv = (ref) => {
            ref.current.scrollIntoView({ behavior: 'smooth'});
          };
    let navigate = useNavigate()
    const [parcel, setParcel] = useState({
        length: "",
        width: "",
        height: "",
        weight: ""
        })

    const {
        length,
        width,
        height,
        weight
    } = parcel;

let jsonValues = {
    name: "",
    description: "",
    costPrice: "",
    price: "",
    categoryID: [],
    categoriesID: [],
    catalogID:"",
    shipping: "",
    quantity: "",
    condition:"",
    formData: "",
    selCategories: false,
    inpProduct: false,
    filterProduct: true,
    imgPreview: false,
    filterID:"",
    AcCat:"",
    AcInf:"",
    AcFilt:"",
    AcImg:"",
    productID: "",
    error: "",
    redirectToFilter: false
}
    const [values, setValues] = useState(jsonValues);
    const {
        name,
        description,
        costPrice,
        price,
        categoryID,
        categoriesID,
        catalogID,
        filterID,
        shipping,
        quantity,
        condition,
        inpProduct,
        filterProduct,
        selCategories,
        imgPreview,
        AcCat,
        AcInf,
        AcFilt,
        AcImg,
        productID,
        error,
        createdProduct,
        redirectToFilter,
    } = values;


    const category = '5ed4946fd2ba8812c20261a8'
 
    const elakayCategoryID = params.categoryId ? params.categoryId : category;

    const loadCategory = ID => {
        let CaId = ID ? ID : elakayCategoryID
        getCategoriesByID(CaId).then(data => {
            if (data.error) {
                console.log("data Not Found");
            } else {
                setCategories(data.cartegory)
                setFilters(data.filter)
                setItems(data.items)
                createdFilter(data.filter)
                data.items.length == 0 && setValues({ ...values, AcInf:"active" })
                setLoading(false)
                // navigate(sellUrl)
                setSearch([])
            }
        });
    };

  

    useEffect(() => { 
        loadCategory()
        setValues(jsonValues)
    }, [token, ]);

    const seleteCategory = () => event => {
        const supcategory = event.target.value;
        if (supcategory){
        categoyseleted.push(categories.name)
        categoyIDseleted.push(categories._id)
        setValues({ ...values, catalogID: categories._id });
        loadCategory(supcategory)
    }else{
        loadCategory(elakayCategoryID)
    }
    }

    const selectAdress = () => event => {
        const IdAdress = event.target.value;  
        addressId(token, IdAdress)
    }

    const clickBack = (LA) => {
        loadCategory(LA)
        categoyIDseleted.pop()
        categoyseleted.pop()
    }
    
    const addressId = (token, adressId) => {

    adressId && ( 
          getAddressById(token, adressId).then(data => {
                if (data.error) { 
                    setAddressSelect()
                    console.log("data not found");
                   
                } else {
                    setAddressSelect(data[0])
                }
            }))
    }

    const nameArrCat = (a, b)=>{
        let textfil  = "" 
        for (let i in a) {
            textfil  +=  a[i] + b + ' ' 
          }
          return textfil
    }
    
    const ButtonBackCategory = () => {
        let last_le = categoyIDseleted[categoyIDseleted.length - 1]
        let CategoryIDSEl = last_le ? last_le : category
        return (
            <span onClick={() => clickBack(CategoryIDSEl)} className="h5 float-left">
                <FaLongArrowAltLeft />
            </span>
        )
    }

const createdFilter =(ArryFilter)=>{
    filterArray.splice(0, filterArray.length)
    ArryFilter.forEach(items => { 
        filter[items._id.name] = ""
        // filterArray.push(null)
    })
}

//category by selection or category by name search

let listeCatSelSea = ['by selection', 'by name search']

const handleCatSelSea = (a)=>{
    if(a == 'by selection'){
        setcatSelectedSearch(true)
    }else if (a == 'by name search'){
        setcatSelectedSearch(false)
    }
}

const checkedSelSea = (a)=>{
    if(a){
    return 'by selection'
    }else{
    return 'by name search'
    }
}

const CheckingCat = ()=>{
    return <div className="form-group">
 <span  className="btn p-2"  onClick={() => clickButton2()}><GrClose/></span>
<h2>{catSelectedSearch && ButtonBackCategory()}
     elakay categories
     </h2>
    { listeCatSelSea.map((ob) =>{
return <> <input 
            className="form-check-input"
            onClick={()=>handleCatSelSea(ob)} 
            checked={checkedSelSea(catSelectedSearch) == ob ? 'checked' : ''} 
            type="radio" 
            name="typeList" 
            id={"typeList_" + ob}
            />
           <label className="form-check-label" for={"typeList_" + ob}>{ob}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> 
    </>
})}
</div>

}

    // selcet categorie pour les vendre 
    const selectCategory = () => {

     return selCategories && <>
        {CheckingCat()}
       { catSelectedSearch ? <CatSelected
        clickButton2={clickButton2}
        ButtonBackCategory={ButtonBackCategory}
        categoyseleted={categoyseleted}
        seleteCategory={seleteCategory}
        laguangeElakay={laguangeElakay}
        infoSource={infoSource}
        categories={categories}
         /> : <CatSearch 
         infoSource={infoSource}
         search ={search}
         setSearch ={setSearch}
         getCategoriesByName={getCategoriesByName}
         categories={categories}
         loadCategory={loadCategory}
         />}
         </>
    }


    const f1 = filters.length > 0 ? true : false
    // const imageTrue = filterArray.every(i => i !== null) && filterArray.length > 0;
      const imageTrue = Object.values(filter).every(i => i) && quantity !== "" 
    //   && length !== "" && width !== "" && height !== "" && weight !== ""
    const e1 = items.length > 0? true : false 
    const Adr = address.length
    const productTrue = name !== "" && price !== "" && description !== "" && shipping !== "" && condition !=="" && addressSelect !== undefined 
  
    const clickButton =()=>{
        scrollToDiv(divSelCatego)
        setValues({ ...values, selCategories: true, AcCat:"active" });
        // navigate(sellUrl)
    }
    const clickButton2 =()=>{
        loadCategory(elakayCategoryID)
        setValues(jsonValues)
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
        setValues({ ...values, selCategories: false, AcCat:"", AcInf:""});
        // navigate(sellUrl)
    }
    const clickButton3 =()=>{
        loadCategory(elakayCategoryID)
        setValues(jsonValues)
        categoyseleted.splice(0, categoyseleted.length)
        categoyIDseleted.splice(0, categoyIDseleted.length)
        setValues({ ...values, selCategories: false, AcCat:"",  AcInf:""});
    }
    //add product pour les vendre
    const clickSubmit = event => {
        scrollToDiv(divProduct)
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });

        createProduct(token, { name, description, costPrice, price, shipping, addressShop: addressSelect._id, condition, categoriesID}).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                addPubCategories(token, categories._id)
                setValues({
                    ...values,
                    inpProduct: false,
                    filterProduct: true,
                    AcFilt:"active",
                    productID: data.productID,
                    createdProduct: data.name,
                    // redirectToFilter: true
                    loading: loading,
                    
                });
                categoyseleted.splice(0, categoyseleted.length)
                categoyIDseleted.splice(0, categoyIDseleted.length)
            }
        });
    };

    const clickFilter = event => {
        event.preventDefault();
        setValues({ ...values, error: "", loading: true });
        createFilterProduct(token, productID, {filter, quantity: parseInt(quantity), catID: categories._id, parcel}).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    inpProduct: false,
                    filterProduct: false,
                    imgPreview: true,
                    AcImg:"active",
                    productID: data.productID,
                    filterID: data.filterProduct,
                    loading: false,
                });
                filterArray.splice(0, filterArray.length)
            }
        });
    };


    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, error: false, [name]: value, categoriesID:[{catID: categories._id, name: categories.name, gategoriesLink: categories.categoryID}]});
    };

    const parcelChange = name => event => {
        const value = event.target.value;
        setParcel({ ...parcel, [name]: value });
    };


        const handleFilter = (name) => e => { 
       
            const value = e.target.value;

            filter[name] = value;
            delete filter['quantity']
            setValues({ ...values, [name]: value});
    
            // navigate(sellUrl)
    
    }



    
    // const handleFilter = (i, name) => e => { 
       
    //     if (e.target.value === "") {
    //         let k = i + 1
    //         filterArray.fill(null, i, k) 
            
    //         } else {
                
    //         if (filterArray[i] === null) {
    //             if (e.target.value){
    //                 filterArray.splice(i, 1, e.target.value)
    //             }
               
    //         } else { 
    //             let k = i + 1
    //          filterArray.fill(e.target.value, i, k) 
               
    //         }
    //         } 

    
    // }


    const LayoutImg = ({ input, previews, submitButtonContent, submitButton, dropzoneProps ,files, extra: { maxFiles } }) => {
        return (
            <div>
                {previews}
                {submitButtonContent}
                

                <div {...dropzoneProps}>
                    {files.length < maxFiles && input}
                </div>

                {files.length >= 0 && submitButton}
            </div>
        )
    }
  

const parcelForm = () =>( 
   
        <div>
            <h5>{infoSource.laguangeElakay('title', infoSource.languageAPI).dimensionWeight}</h5>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).length}(in)</label>
            <input onChange={parcelChange("length")} type="number" min="1" className="form-control" value={length} />
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).width} (in)</label>
            <input onChange={parcelChange("width")} type="number" min="1" className="form-control" value={width} />
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).height}(in)</label>
            <input onChange={parcelChange("height")} type="number" min="1" className="form-control" value={height} />
            </div>
            <div className="form-group" >
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).weight}(lb)</label>
            <input onChange={parcelChange("weight")} type="number" min="1" className="form-control" value={weight} />
            </div>
        </div>

)


const calculateFontSize = (index, totalElements) => {
    const maxSize = 25;
    const minSize = 12;
    const sizeStep = (maxSize - minSize) / (totalElements - 1);
    return maxSize - (index * sizeStep);
  };
  const handlePaste = (event) => {
    // Empêche l'action de coller
    event.preventDefault();
    alert("Pasting is not allowed in this field.");
  };
  
    const newPostForm = () => (
        inpProduct && (
            
            <div>
                {/* {setValues({ ...values, AcInf:"active" })} */}
                 <span  className="btn p-2"  onClick={() => clickButton3()}><GrClose/></span>
                <div className="boxCategory1 text-center" id="heading">
                    {/* <h2>{categories.name} </h2> */}
                    {ButtonBackCategory()}    <p>
      {Array.isArray(categories.name) && categories.name.map((word, index) => (
        <span 
          key={index} 
          style={{ fontSize: `${calculateFontSize(index, categories.name.length)}px` }}
        >
          {word}{index < categories.name.length - 1 ? ', ' : '.'}
        </span>
      ))}
    </p>
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                </div>
                <form className="mb-3" onSubmit={clickSubmit}>
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).name}</label>
                        <input 
                        onChange={handleChange('name')} 
                        type="text" className="form-control" 
                        value={name} 
                        //  onPaste={handlePaste} 
                         />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).description}</label>
                        <textarea 
                        onChange={handleChange('description')} 
                        className="form-control" 
                        value={description}  
                        // onPaste={handlePaste} 
                        />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">cost Price</label>
                        <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                 </div>
                                <input onChange={handleChange('costPrice')} type="number" min="0" className="form-control" value={costPrice} />
                </div>
                    </div>

                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).price}</label>
                        <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                 </div>
                                <input onChange={handleChange('price')} type="number" min="0" className="form-control" value={price} />
                </div>
                    </div>
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).adressShop}</label>
                        <select onChange={selectAdress()} className="form-control" value={addressSelect && addressSelect._id ? addressSelect._id: 0}>
                            <option value='0'>{infoSource.laguangeElakay('label',  infoSource.languageAPI).pleaseSelect}</option>
                            {
                                address.map((Adre, i)=>(
                                    <option key={i} value={Adre._id}>({Adre.firstName} {Adre.lastName}) {Adre.Address1}, {Adre.city} {Adre.State} {Adre.postalCode} {Adre.country}</option>    
                            ))
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).freeShipping}</label>
                        <select onChange={handleChange('shipping')} className="form-control" value={shipping}>
                            <option value="" >{infoSource.laguangeElakay('label',  infoSource.languageAPI).pleaseSelect}</option>
                            <option value={false}>{infoSource.laguangeElakay('label',  infoSource.languageAPI).no} </option>
                            <option value={true}>{infoSource.laguangeElakay('label',  infoSource.languageAPI).yes}</option>
                        </select> 
                        <span className="font-weight-light text-info"> {infoSource.laguangeElakay('label',  infoSource.languageAPI).onChargeShipping}</span>
                    </div>
                
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).condition}</label>
                        <select onChange={handleChange('condition')} className="form-control" value={condition}>
                            <option value="" >{infoSource.laguangeElakay('label',  infoSource.languageAPI).pleaseSelect}</option>
                            <option value="New">{infoSource.laguangeElakay('label',  infoSource.languageAPI).new}</option>
                            <option value="Used">{infoSource.laguangeElakay('label',  infoSource.languageAPI).used}</option>
                        </select>
                    </div>
                
                    {/* <button className="btn btn-primary float-left"   onClick={() => clickButton3()}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button> */}
                    {nextToSelection(productTrue)}
                </form>
            </div>
        ))

    const laadingGIf = () => (
        loading && (
            <div className="alert alert-info">
                <h2>
                    {/* Loading... */}
                    {infoSource.language(infoSource, "title", "loanding")}
                    </h2>

                <img src={Loader} className={`search-loading ${loading ? 'show' : 'hide'}`} alt="loader" />
                
            </div>
        )
    )
    const seleteFilterProduct = () => (
        filterProduct && (
            <>
                <div className="boxCategory1 text-center" id="heading">
                    <h2> {categories.name} </h2>
                    {categoyseleted.map((c, i) => (<p className="d-inline" key={i} >{c}{" / "}</p>))}
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                </div>
                <form className="mb-3" onSubmit={clickFilter}>   
                    {categories.filter.map((k, i) => {

                    let translFil = infoSource.getFilTranslate(k)
                       return <div className="form-group" key={i}>
                          
                            <label className="text-muted">{translFil.name} </label>

                           <select onChange={handleFilter(k._id.name)} className="form-control">
                                <option value="" >{infoSource.laguangeElakay('label',  infoSource.languageAPI).pleaseSelect}</option>
                                { translFil.FilterItem.map((c, j) => (
                                  
                                  <option key={j} value={c[0]}>
                                      {c[1]}
                                  </option>
                                    ))
                                }
                              <option value={"Not Specified"}>
                              {/* Not Specified */}
                              {infoSource.language(infoSource, "label", "notSpecified")}
                          </option>

                            </select>
                            
                        </div>
                    })}
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).quantity}</label>
                        <input onChange={handleFilter('quantity')} type="number"  className="form-control" />
                    </div>
                    {/* {parcelForm()} */}
                    {nextToSelection(imageTrue)}
                </form>
            </>

        ))  
 

    const imagePreviewS = () => (
        imgPreview && (
            <>
                <ImagesLoading
                    laguangeElakay={laguangeElakay}
                    infoSource={infoSource}
                    filterID ={filterID}
                    imgSize={0}
                    namProduct={name}
                    setValues={setValues}
                    values={values}
                    productIDEdit={productID}
            />
                {/* <div className="boxCategory1 text-center" id="heading">
                    <h2> {categories.name} </h2>
                    {categoyseleted.map((c, i) => (<p className="d-inline" key={i} >{c}{" / "}</p>))}
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                </div>
                <Dropzone
                    onChangeStatus={handleChangeStatus}
                    LayoutComponent={LayoutImg}
                    onSubmit={handleSubmit}
                    submitButtonContent={"ADD SELL"}
                    maxFiles={12}
                    maxSizeBytes={3000000}
                    inputWithFilesContent={files => `Add ${12 - files.length} more or not`}
                    classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
                    inputContent="Drop your File or Click"
                    styles={{ dropzone: { minHeight: 250, maxHeight: 20, maxWidth: 250 }, previewImage: { minHeight: 180, minWidth: 180 }, inputLabel: { fontSize: 15 }, submitButton: { minHeight: 45, minWidth: 100, bordeRadius: 0, float: "right" } }}
                /> */}
              
            </>
        )
    )

    const checkProdKeyExit = (ad)=>{
        let ent 
        if(Array.isArray(ad)){
          let ac = ad[0]
          ent = Object.keys(ac ? ac:{});
          return ent.every(([key, val]) => ac[key] != undefined) && true
        } else{
           ent = Object.keys(ad ? ad:{});
           return ent.every(([key, val]) => ad[key] != undefined) && true
        }}


    const pageSteps = (a)=>{
            if (!checkProdKeyExit(a)){
               return <Pages  
                classAlert ={"container-fluid"}
                classText={"col col-11"}
                classImg={"col col-10"}
                classPara={'row justify-content-center'}
                paragra0={a}
            />
            }else{
              return <div> 
              <div className="row mt-4 mb-4 justify-content-center">
              <img className="shadow-lg" style={{ width: "100%" }} src={nodata} alt="firt Step" />
               </div>
               </div>
            }
        }

    const stepSeller = ()=>(
        // <div className="boxCategory1 text-center">
   <div className="row">
  <div className="col-xs-12 col-md-8 offset-md-2 block pb-4">
    <div className="wrapper-progressBar">
        <h2>{infoSource.laguangeElakay('title',  infoSource.languageAPI).sellingProduct}</h2>
      <ul className="progressBar">
        <li className={AcCat}>{infoSource.laguangeElakay('title',  infoSource.languageAPI).Categories}</li>
        <li className={AcInf}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).info}</li>
        <li className={AcFilt}>{infoSource.laguangeElakay('title',  infoSource.languageAPI).filters}</li>
        <li className={AcImg}>{infoSource.laguangeElakay('title',  infoSource.languageAPI).images}</li>
      </ul>
    </div>
  </div>
</div>
        // </div>
    )
    const lightColors = [
        { name: "White", hex: "#FFFFFF" },
        { name: "Snow", hex: "#FFFAFA" },
        { name: "Honeydew", hex: "#F0FFF0" },
        { name: "MintCream", hex: "#F5FFFA" },
        { name: "GhostWhite", hex: "#F8F8FF" },
        { name: "Ivory", hex: "#FFFFF0" },
        { name: "FloralWhite", hex: "#FFFAF0" },
        { name: "Seashell", hex: "#FFF5EE" },
        { name: "Gainsboro", hex: "#DCDCDC" },
        { name: "LightGray", hex: "#D3D3D3" },
        { name: "AliceBlue", hex: "#F0F8FF" },
        { name: "Azure", hex: "#F0FFFF" }
    
    ];
    
    const ColorDisplay = () => {
        return (
            <div>
                <h1 style={{ textAlign: "center", marginBottom: "20px", color: "#333" }}>
                    Color Background Authorized
                </h1>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "center" }}>
                    {lightColors.map((color) => (
                        <div
                            key={color.hex}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "40px",
                                height: "40px",
                                backgroundColor: color.hex,
                                color: color.hex === "#FFFFFF" ? "#000" : "#FFF", // Adjust text color for better visibility
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                textAlign: "center",
                                fontSize: "10px",
                                overflow: "hidden"
                            }}
                            title={color.name} // Tooltip for full name
                        >
                            <span>{color.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const StartSelling =()=>(
        !selCategories && (
        <>
        <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        <span className="circleSelling">1</span>  {infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition} 
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
        {pageSteps(infoSource.laguangeElakay('termConditProdSel',  infoSource.languageAPI))}
          <ul>
            <li><Link to="#" onClick={() => setConditions(true)}>{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</Link></li>
            <li><Link to="/help-contact" >{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).HelpContact}</Link></li>
        </ul>
        </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      <span className="circleSelling">2</span>  {infoSource.laguangeElakay('title',  infoSource.languageAPI).Categories}  {infoSource.laguangeElakay('title',  infoSource.languageAPI).ForSellingProduct} 
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      {pageSteps(infoSource.laguangeElakay('catalogProdSel',  infoSource.languageAPI))}
        </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      <span className="circleSelling">3</span>  {infoSource.laguangeElakay('Button', infoSource.languageAPI).info}  {infoSource.laguangeElakay('title',  infoSource.languageAPI).ForSellingProduct} 
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">
      {pageSteps(infoSource.laguangeElakay('infoProdSel',  infoSource.languageAPI))}
       </div>
    
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-heading04">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse04" aria-expanded="false" aria-controls="flush-collapse04">
      <span className="circleSelling">4</span>  {infoSource.laguangeElakay('title',  infoSource.languageAPI).filters}  {infoSource.laguangeElakay('title',  infoSource.languageAPI).ForSellingProduct} 
      </button>
    </h2>
    <div id="flush-collapse04" className="accordion-collapse collapse" aria-labelledby="flush-heading04" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      {pageSteps(infoSource.laguangeElakay('filterProdSel',  infoSource.languageAPI))}
        </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-heading05">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse05" aria-expanded="false" aria-controls="flush-collapse05">
      <span className="circleSelling">5</span>  {infoSource.laguangeElakay('title',  infoSource.languageAPI).images} {infoSource.laguangeElakay('title',  infoSource.languageAPI).ForSellingProduct} 
      </button>
    </h2>
    <div id="flush-collapse05" className="accordion-collapse collapse" aria-labelledby="flush-heading05" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
      {pageSteps(infoSource.laguangeElakay('imageProdSel',  infoSource.languageAPI))}
      {ColorDisplay()}
        </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-heading06">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse06" aria-expanded="false" aria-controls="flush-collapse06">
      <span className="circleSelling">6</span>
      {/* All you have to Know about shipping. */}
      {infoSource.language(infoSource, "Button", "allAboutShip")}
      </button>
    </h2>
    <div id="flush-collapse06" className="accordion-collapse collapse" aria-labelledby="flush-heading06" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">
         <ShippingTable
         infoSource={infoSource}
         />
     
        </div>
    </div>
  </div>
</div>
<div className="row justify-content-center">
<div className="bg clearfix ">
        <button className="btn btn-primary pt-2 pb-2 pr-3 pl-3 rounded-4 float-left"   onClick={() =>{clickButton()}}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).getStarded}</button>
        <button className="btn btn-primary pt-2 pb-2 pr-3 pl-3 rounded-4 float-right"   onClick={()=>setSimulationSales(true)}>
            {/* sales simulation */}
            {infoSource.language(infoSource, "Button", "salesSimul")}
            </button>
            </div></div>
    </>
        )
    )
    const nextToSelection = (activeBtnSelection) => (
        activeBtnSelection ? (
            <button className="btn btn-primary float-right">{infoSource.laguangeElakay('Button', infoSource.languageAPI).next}</button>
        ) : (<button type="button" className="btn btn-secondary float-right">{infoSource.laguangeElakay('Button', infoSource.languageAPI).next}</button>)
    )
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const redicectToFilters = () => {
        if (redirectToFilter) {
            return   navigate(sellUrl + prodLink)
        }
    }

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: createdProduct ? "" : "none" }}
        >
            <h2>{`${createdProduct}`} 
                {/* is created! */}
                {infoSource.language(infoSource,  "title", "iscreated")}
                </h2>
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>
                    {/* Loading... */}
                    {infoSource.language(infoSource, "title", "loanding")}
                    </h2>
            </div>
        );


    const AddTermsAndConditions = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="lg"
                    show={showConditions}
                    onHide={() => setConditions(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2 className="text-center">{infoSource.laguangeElakay('helpContact', infoSource.languageAPI).termsCondition}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {TermsConditions()}
                        {ReturnAndRefundPolicy()}
                        {ContactUs()}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )


    const simulationSalesPage =()=>{
        return <Modal
        size="xl"
        show={shwoSimulationSales}
        onHide={() => setSimulationSales(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                <h2 className="text-center"> 
                    {/* Sales Simulation */}
                    {infoSource.language(infoSource, "Button", "salesSimul")}
                </h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SalesSimulation
            infoSource={infoSource}
            laguangeElakay={laguangeElakay}
            search ={search}
            setSearch ={setSearch}
            getCategoriesByName={getCategoriesByName}
            categories={categories}
            loadCategory={loadCategory}
            />
          
        </Modal.Body>
    </Modal>
    }

    return (
        <>
        {stepSeller()}
            <div className="row " >
            <div className="col-md-8 offset-md-2 blockEl">

            {laadingGIf()}
            {(e1?
                <>
                    
                    {( 
                                Adr? 
                                <>
                               <div ref={divSelCatego}>{selectCategory()}</div> 
                                {StartSelling()}
                                </>
                        :
                        <>
                                        <div className="row = justify-content-center">
                                            <div className="col-11 col-md-10 col-lg-9 col-xl-8">
                                                <div className="shadow-lg mt-3 mb-3">
                                                <img
                                                    className="rounded"
                                                    src={AddressSeller}
                                                    alt={"no Product"}
                                                    style={{ height: "100%", width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        </div>
                            <Link to={"/profile?addr=true"}>
                                            <button className="btn btn-info float-right pt-2 pb-2 pr-3 pl-3">
                                 <span style={{ fontSize: "20px", paddingRight: "6px" }}><FaAddressCard /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addAdress}
                                </button>
                            </Link>
                        </>
                    )}
                           
                </>
                :
                        f1 ?
                   <div ref={divProduct}> 
                    {newPostForm()} 
                    {seleteFilterProduct()} 
                   {imagePreviewS()}
                   </div>
                :
                <div>
                    <span  className="btn p-2"  onClick={() => clickButton2()}><GrClose/></span>
                <div className="row = justify-content-center">
                <div className="col-11 col-md-10 col-lg-9 col-xl-8">
                 <div className="shadow-lg mt-3 mb-3">
                              <img
                                className="rounded"
                                src={CategoriesFilter}
                                alt={"no Product"}
                                style={{ height: "100%", width: "100%" }}
                                />
                    </div>
                </div>
                </div>
                </div>
            )}
                   
            </div>
            </div>
            { e1 && (
                <div className="row = justify-content-center">
                    <div className="col-11 col-md-10 col-lg-9 col-xl-8">
                        <div className="shadow-lg mt-3 mb-3">
                        <img
                            className="rounded"
                            src={SellerPub}
                            alt={"Seller Pub"}
                            style={{ height: "100%", width: "100%" }}
                        />
                    </div>
                </div>
                </div>
            )}
            { f1 && (
                <div className="row = justify-content-center">
                    <div className="col-11 col-md-10 col-lg-9 col-xl-8">
                        <div className="shadow-lg mt-3 mb-3">
                        <img
                            className="rounded"
                            src={Seller_caribbean}
                            alt={"no Product"}
                            style={{ height: "100%", width: "100%" }}
                        />
                    </div>
                </div>
                </div>
             ) }
      {AddTermsAndConditions()}
      {redicectToFilters()}
      {simulationSalesPage()}
</>
    );
};

export default Sell;