import React from "react";
import Layout from "../Layout/Layout";
import {FaFirstOrder, FaUserAlt, FaSellcast, FaLanguage, FaMapMarkerAlt, FaWallet, FaAddressCard, FaUserCog, FaUserSecret, FaMoneyBill, FaIdeal, FaSlidersH, FaBuysellads, FaPhoneAlt} from "react-icons/fa";
import {MdPublic, MdLanguage, MdCreateNewFolder, MdAddBusiness, MdAlternateEmail} from "react-icons/md";
import { AiFillShop } from "react-icons/ai";
import { GrAscend, GrBusinessService } from "react-icons/gr";
import { GiSellCard, GiChessKing, GiFamilyTree } from "react-icons/gi";
import { RiUserCommunityFill } from "react-icons/ri";
import Table from 'react-bootstrap/Table'
import { Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Family, Adminstrator, Investment, Assoociate, Provider, Seller } from "../pageInside/Rolle"
import { laguangeElakay } from "../pageInside/dataElakay";

const UserSerting = (props) => {
    const {
        infoSource
        } = props


    let userInfo = []
        userInfo = infoSource.userInfo

    let colClass ="col p-1"
    

    
    const roleUser =()=>(
        <>
        {
        Seller(true) && (
             <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).seller}</span>
        )}
         { Provider(true) && (
             <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).provider}</h4>
        )}
           {
        Assoociate(true) && (
             <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).assoociate}</span>
        )}   {
        Investment(true) && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).investment}</span>
            )}
       {Adminstrator(true) && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).Admin}</span>
        )}
        {Family() && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).family}</span>
        )}
        </>
    )
    let telephone =  infoSource.phoneUser && infoSource.phoneUser.filter(k => k.default === true)[0]
    let AddressTrue  = infoSource.address && infoSource.address.filter(k => k.addressDefault === true)[0]

    const infoUser = (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourInfo}  {roleUser()}</h3>
                </div>
                <div className="m-3">
               
                <Table>
                <tbody>
                    <tr>
                        <td><h4><span style={{ fontSize: "30px", paddingRight: "20px" }}><FaUserAlt /></span>  {`${userInfo.firstname} ${userInfo.lastname}`} </h4></td>
                    </tr>
                    <tr>
                        <td><h5><span style={{ fontSize: "30px", paddingRight: "20px" }}><MdAlternateEmail /></span>{userInfo.email} </h5></td>
                    </tr>
                    {    telephone &&   <tr><td>
                    <div>
                                <h5>
                                 <FaPhoneAlt /> {telephone.International}
                                </h5>
                            </div>
                            </td></tr>}
                   {AddressTrue &&  <tr><td>
                                <h5><span> <FaMapMarkerAlt />  { AddressTrue.firstName} {AddressTrue.lastName}</span> <br /> <span style={{ paddingLeft: "20px" }}>  {AddressTrue.Address1}, {AddressTrue.city} {AddressTrue.State} {AddressTrue.postalCode} {AddressTrue.country}</span> <br />
                                    <span style={{ paddingLeft: "21px", fontSize: "14px" }}> {infoSource.laguangeElakay('title', infoSource.languageAPI).defaultDeliryAdress}</span>
                                </h5>
                                </td></tr>}
                    </tbody>
                </Table>

                {/* <Link to="/address">{infoSource.laguangeElakay('Link', infoSource.languageAPI).changeAddress}</Link> */}
            </div></div>
        </div>
    ) 

    const menu = (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="container p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).menu}</h3>
                </div>
                <div className="container-fluid">
                    <Row xs={2} sm={2} md={3} lg={4} xl={4}>
                        <div className={colClass}>
                            <Link to={"/myshop"}>
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <AiFillShop /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).myShop}</div>
                                </div>
                            </Link>
                        </div>
                        <div className={colClass}>
                            <Link to={"/myshop?sel=true"}>
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <FaSellcast /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).sell}</div>
                                </div>
                            </Link>
                        </div>

                        <div className={colClass}>
                            <Link to="/myshop?mb=true&BPUB=false">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <MdPublic /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.language(infoSource, 'title', 'myShopPub')}</div>
                            </div>
                            </Link>
                        </div>  

                        <div className={colClass}>
                            <Link to={"/myshop?odr=true"}>
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <FaFirstOrder /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).buyerCustomer}</div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className={colClass}>
                            <Link to="/User-team">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <FaUserFriends /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).myTeam}</div>
                                </div>
                            </Link>
                        </div> */}
                        
 
                        <div className={colClass}>
                            <Link to="/myshop?td=true">
                            <div className="card">
                                <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <FaIdeal /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).myDealDay}</div>
                            </div>
                            </Link>
                        </div>


                        
                        

                        {/* <div className={colClass}>
                            <Link to="/wallet">
                            <div className="card">
                                <div className="card-body mx-auto">
                                    <span className="card-title" style={{ fontSize: "50px" }}> <FaWallet /></span>
                                </div>
                                <div className="card-footer text-center">{infoSource.laguangeElakay('menuList', infoSource.languageAPI).wallet}</div>
                            </div>
                            </Link>
                        </div> */}
                        {
                            Assoociate() && (  
                                <>
                        <div className={colClass}>
                        <Link to={"/admin_associat"}>
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <RiUserCommunityFill  /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).viewUser}</div>
                                </div>
                        </Link>
                        </div> 
                        <div className={colClass}>
                            <Link to="/admin_associat?page=usersAdd&usel=1">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <GiChessKing /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addUserRoyalty}</div>
                                </div>
                            </Link>
                        </div>
                        <div className={colClass}>
                            <Link to="/admin_associat?page=usersAdd&usel=2">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <FaBuysellads /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addSeller}</div>
                                </div>
                            </Link>
                        </div>
                        <div className={colClass}>
                            <Link to="/admin_associat?page=usersAdd&usel=3">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <GiSellCard /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addProducer}</div>
                                </div>
                            </Link>
                        </div>
                        
                           </>
                            )}
                               {
                            Investment() && (  
                                <>
                        <div className={colClass}>
                            <Link to="/admin_associat?page=usersAdd&usel=4">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <GrBusinessService /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addAssociate}</div>
                                </div>
                            </Link>
                        </div>
                           </>
                            )}
                        {
                            Adminstrator() && (  
                                <>
                        <div className={colClass}>
                        <Link to="/admin_associat?page=usersAdd&usel=5">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <MdAddBusiness /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addInvestment}</div>
                                </div>
                            </Link>
                        </div>
                           </>
                            )}
                        {Family() && (
                            <>
                            <div className={colClass}>
    
                            <Link to="/admin_associat?page=usersAdd&usel=6">
                                    <div className="card">
                                        <div className="card-body mx-auto">
                                            <span className="card-title" style={{ fontSize: "50px" }}> <FaUserCog /></span>
                                        </div>
                                        <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addAdministrator}</div>
                                    </div>
                                </Link>
                            </div>

                        <div className={colClass}>

                        <Link to="/admin_associat?page=usersAdd&usel=7">
                                <div className="card">
                                    <div className="card-body mx-auto">
                                        <span className="card-title" style={{ fontSize: "50px" }}> <GiFamilyTree /></span>
                                    </div>
                                    <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addFamilly}</div>
                                </div>
                            </Link>
                        </div>
                        </>
                    )}
                     { Adminstrator() &&   <div className={colClass}>
                                    <Link to="/elakay-language">
                                        <div className="card">
                                            <div className="card-body mx-auto">
                                                <span className="card-title" style={{ fontSize: "50px" }}> <MdLanguage /></span>
                                            </div>
                                            <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addLanguage}</div>
                                        </div>
                                    </Link>
                                </div>}

                    { Family() &&     <div className={colClass}>
                                    <Link to="/traductor-team">
                                        <div className="card">
                                            <div className="card-body mx-auto">
                                                <span className="card-title" style={{ fontSize: "50px" }}> <FaLanguage /></span>
                                            </div>
                                            <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).addTeanTraductor}</div>
                                        </div>
                                    </Link>
                                </div>}
                          { Family() &&     <div className={colClass}>
                                    <Link to="/filters">
                                        <div className="card">
                                            <div className="card-body mx-auto">
                                                <span className="card-title" style={{ fontSize: "50px" }}> <FaSlidersH /></span>
                                            </div>
                                            <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).creatFilter}</div>
                                        </div>
                                    </Link>
                                </div>}

                              {  Family() && <div className={colClass}>
                                    <Link to="/category">
                                        <div className="card">
                                            <div className="card-body mx-auto">
                                                <span className="card-title" style={{ fontSize: "50px" }}> <MdCreateNewFolder /></span>
                                            </div>
                                            <div className="card-footer text-center">{infoSource.laguangeElakay('menuSertting', infoSource.languageAPI).creatCatalog}</div>
                                        </div>
                                    </Link>
                                </div>}

                    </Row>
                </div>
            </div>
        </div>
    )


    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            loading={false}
          
        >
        <div style={infoSource.styleNav}>
            {infoUser}
            {menu}
        </div>
        </Layout>
    );
};

export default UserSerting;