import React, { useState, useEffect } from "react";
import { isAuthenticated, OrdersViewById,  OrdersBViewById, updateOrder} from "../auth";
import { Link} from "react-router-dom";
import {retrievePayment, getPaymentIntentView} from "../auth/stripe";
import { } from "../auth/shipping";
import {taxProductArea} from "../auth/apiProduct";
import moment from "moment";
import imgProductLakay from '../Layout/imgs/md3.jpg' 
import { laguangeElakay } from "../pageInside/dataElakay";
import CategoryPub from "./lakayUser/categoryPub";
import Filters from '../pageInside/FilterProduct'

import FollowPage from "./lakayUser/followPage";

const isNullOrEmpty = (value)=> {
    // Check for null, undefined, or false
    if (value == null || value === false) return true;

    // Check if the value is an empty object or array
    if (typeof value === 'object') {
        return Object.keys(value).length === 0 && true ;
    }
    // Otherwise, return false (the value is neither null, undefined, false, nor empty)
    return false;
}

const loadingTicket = (array ,allticket) => {
 
     // Vérifiez si `subTicketIds` ou `array` est vide
    if (isNullOrEmpty(allticket)) {
      console.warn("No data to process: subTicket or array is empty.");
      return [];
    } 
    const {
      _id,
      subTicket,
        } = allticket

    // Vérifiez si les entrées sont valides
    if (!Array.isArray(array) || !Array.isArray(subTicket)) {
      console.error("Invalid input: both arguments must be arrays.");
      return [];
    }

    if (subTicket.length === 0 || array.length === 0) {
        console.warn("No data to process: subTicket or array is empty.");
        return [];
      }
  
    // Filtrer les objets avec les `_id` correspondants
  return array.filter(v => v._id != _id).filter(item => subTicket.includes(item._id))
  
   };


function ReadMoreLess({infoSource, text, textLess= 'futur.'}) {
    const [isExpanded, setIsExpanded] = useState(false);
  
    // Fonction pour basculer l'état
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    // Séparation du texte
    const previewLength = text.indexOf(textLess) + textLess.length;
    const previewText = text.slice(0, previewLength);
    const remainingText = text.slice(previewLength);

    const seeMore = infoSource.language(infoSource, "Link", "seeMore")
    const seeLess = infoSource.language(infoSource, "Link", "seeLess")
  
    return (
      <div 
      style={
        {
        padding: '15px',
         maxWidth: '600px',  
         backgroundColor: '#fff3cd', 
         color: '#856404', 
         borderRadius: '5px',
        border: '1px solid #ffeeba',
         margin: '0 auto' 
         }}>
        {/* Texte de prévisualisation */}
        <p>
        <strong>NB: </strong>
          {previewText}
          {!isExpanded && '...'} {/* Ajout des ellipses si non développé */}
       
  
        {/* Texte supplémentaire affiché uniquement si étendu */}
        {isExpanded && remainingText}
  
        {/* Bouton bascule */}
        <span className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={toggleExpand} style={{padding: '5px' }}>
          {isExpanded ? seeLess : seeMore}
        </span> 
        </p>
      </div>
    );
  }

const Orders = (props) => {
 
    const {
        infoSource,
        getOrderID
        } = props

        const objNotEmpty = (obj)=>{
            for(var prop in obj) {
                if(obj.hasOwnProperty(prop))
                    return true;
            }
        
            return false;
        }
    
        const ticketValue = {
            status: '',
            cause : '',
            causeNote : '', 
            asking: ''
          }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [nbrArr, setNbrArr] = useState(3)
    const { token } = isAuthenticated();
    const [loading, setLoading] = useState(true);
    const [ordersShipping, setOrdersShipping] = useState({})
    const [orderPayment, setOrderPayment] = useState({})
    const [ordersProducts, setOrdersProducts] = useState([])
    const [pubCategoryProd0, setPubCategoryProd0] = useState([])
    const [transaction_id, setTransaction_id] = useState(null)
    const [MLShowText, setMLShowText] = useState([false, false]);

    const[ticket, setTicket] = useState(ticketValue)
    const[pageForm, setPageForm]= useState([true, false, false])

    const [noReturnOrReplace] = useState(false)

    const {
        status,
        cause,
        causeNote, 
        asking
       } = ticket
   
     const [orders, setOrders] = useState({});
     
    let orderID = getOrderID.oid;
    let orderIDI = getOrderID.pid;

    const reziArray = (a, n)=>{
        let arr = a.slice(0, n)
        setPubCategoryProd0(arr)
        setNbrArr(n)
     }

    let sizeWindow = window.innerWidth

     const resizeWidth = (w)=>{
        setWindowWidth(w)
    }

    const changeArray = (a)=>{
        // window.matchMedia(`(${w}: ${p})`).addEventListener('change', () => reziArray(a, n))
        // window.matchMedia(`(${w}: ${p})`).addEventListener('resize', () => reziArray(a, n))
        window.matchMedia(`(max-width: 768px`).addEventListener('change', () => reziArray(a, 3))
        window.matchMedia(`(max-width: 576px`).addEventListener('change', () => reziArray(a, 2))
        window.matchMedia(`(min-width: 576px`).addEventListener('change', () => reziArray(a, 2))

    if(windowWidth >= 768){
        reziArray(a, 3)
    }else{
        reziArray(a, 2)
    }
    }

    const newCartProd = async (data)=>{
        for (let i = 0; i < data.length; i++) {
            const additionalData = await taxProductArea(data[i].prod_User_Address.State, data[i].prod_User_Address.postalCode)
            if (additionalData) {
                data[i].taxprodZone = additionalData.zoneTax; // Ajout du nouveau champ
            }   
        } 
        setOrders(data[0]);
        setOrdersShipping(data[0].shippingAddress);
        setOrdersProducts(data[0].products);
      }
    
      const daysSinceOrder = (date) => {
        const today = new Date();
        const diffTime = Math.abs(today - new Date(date));
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Différence en jours
      };
    
      // Fonction pour déterminer si le bouton "Cancel" doit être affiché
      const showCancelButton = (status) => {
        return !["Cancelled", "Return or replace", "Delivered"].includes(status);
      };
    
      // Fonction pour déterminer si le bouton "Return or Replace" doit être affiché
      const showReturnOrReplaceButton = (status) => {
        if (noReturnOrReplace) return false;
        if (!["Cancelled", "Return or replace", "Delivered"].includes(status)) {
          return true;
        }
        // return daysSinceOrder(orders.createdAt) <= 7;
      };


    const loadOrders = (token, orderID, orderIDI) => {
        OrdersBViewById(token, orderID, orderIDI).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                newCartProd(data)
                setTransaction_id(data[0].products.transaction_id ? data[0].products.transaction_id: null)
                data[0] &&  
                data[0].products &&  
                data[0].products.paymentIntent && 
                data[0].products.paymentIntent.payment_method != null 
                ? laodRetrievePayment(token, data[0].products.paymentIntent.payment_method): 
                laodPaymentMethodView(token, data[0].products.transaction_id)
                setLoading(false)
            }
        });
    };

    const laodPaymentMethodView = (token, ID) => {
        getPaymentIntentView(token, ID).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setOrderPayment({
                        PM_id_sel: null,
                        b_name: null,
                        b_exp_month: data.cardInfo.card.exp_month,
                        b_exp_year: data.cardInfo.card.exp_year,
                        b_city: null,
                        b_brand: data.cardInfo.card.brand,
                        b_last4: data.cardInfo.card.last4,
                        b_country: null,
                        b_line1: null,
                        b_postal_code: null,
                        b_state: null
                    })
                    
            
                }
            }
        );
    };
    const laodRetrievePayment = (token, IDpaymentMethods) => {
        retrievePayment(token, {IDpaymentMethods}).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    setOrderPayment({
                        PM_id_sel: data.id,
                        b_name: data.billing_details.name,
                        b_exp_month: data.card.exp_month,
                        b_exp_year: data.card.exp_year,
                        b_city: data.billing_details.address.city,
                        b_brand: data.card.brand,
                        b_last4: data.card.last4,
                        b_country: data.billing_details.address.country,
                        b_line1: data.billing_details.address.line1,
                        b_postal_code: data.billing_details.address.postal_code,
                        b_state: data.billing_details.address.state
                    })
                }
            }
        );
    };

    const clickBtnTickect=()=>{
        updateOrder(token, getOrderID, {orders: {ticket, status}, transaction_id}).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
              
            }
        });
    }

    useEffect(() => {
        resizeWidth(sizeWindow)
        loadOrders(token, orderID,  orderIDI);
        orders && orders.user_pubCategoryProd && changeArray(orders.user_pubCategoryProd)
    }, [token, orderID,  orderIDI,  sizeWindow]);

    const sum = (total, num) => (
        total + Math.round(num)
    )
    const countProd = (
       [ ordersProducts].map((p) => p.count)
    )
    const countProduct = countProd.reduce(sum, 0)
   
    const CheckTaxZone = (tax)=>{
        return  tax ? tax.EstimatedCombinedRate : 0.088750
        }

    const checkQuantity = (Q)=>{
        return  Q === 0 ? 0 : 1
        }

    const ItemsOrder = () => {
        return [ordersProducts].reduce((currentValue, nextValue) => {
            return currentValue + (nextValue.count * (nextValue.price * (100 - nextValue.salePrice) / 100))
        }, 0);
    };


    const TaxOrder = () => {
        return [ordersProducts].reduce((currentValue, nextValue) => {
            return currentValue + (checkQuantity(nextValue.quantity) * nextValue.count *(nextValue.price * (100 - nextValue.salePrice) / 100) * CheckTaxZone(nextValue.taxprodZone))
        }, 0);
    };


    const getTotal = () => {
        let a = ItemsOrder()
        let c = TaxOrder()
        let d = a  + c
        return d
    };

let  productId = orders && orders.products && orders.products.productID
let categoryId = orders && orders.prod_filterProduct && orders.prod_filterProduct.catID
let urlFil = ``
let urlImage = `/product/preview/${productId}${urlFil}`
    const productView = ()=>{
       return <table style={{width: '100%'}}>
         <tr>
    <th colspan="2"><h3>{orders.prod_name}</h3></th>
  </tr>
  <tr style={{width: '100%'}}>
    <td className="col-4 col-sm-3 col-md-2">
    <div className="position-absolute top-0" style={{maxWidth: "143px"}}>
{orders && orders.products &&  <Link to={urlImage}><img
        className="embla__slide__img"
            src={orders.products.urlImg}
            onError={event => {
                event.target.src = imgProductLakay
                event.onerror = null
              }}
            alt={orders.prod_name}
            style={{width: "100%" }}
        /></Link>}
        </div>
    </td>
    <td className="p-1 md-5">  
         <Link to={urlImage}>
         {/* <a href={urlImage} > */}
            <h6>{orders.prod_Description}</h6>
         {/* </a> */}
         </Link> 
         {orders && orders.prod_filterProduct && orders.prod_filterProduct.filter &&  < Filters filter={orders.prod_filterProduct.filter} nexLine={true} />}
         </td>
  </tr>
</table>

    }
    
    const handPageForm = (page)=>{
        if(page ==="return"){
        setTicket({...ticket, status: "Return or replace"})
            setPageForm([false, true, false])
        }else if (page === 'cancel'){
        setTicket({...ticket, status: "Cancelled"})
        setPageForm([false, false, true])
        }else{
        setTicket({...ticket, status: ''})
        setPageForm([true, false, false])
        }
        
    }

    const handchange =(name)=>event=>{
        const value = event.target.value
        setTicket({...ticket, [name]: value})

    }

    const handMLShowText = (ML)=>{
        setMLShowText(ML)
    }
      const testInfo = (text, textLess, textBool=false, AML)=>{

        const previewLength = text.indexOf(textLess) + textLess.length;
        const previewText = text.slice(0, previewLength);
        const remainingText = text.slice(previewLength);
        const seeMore = infoSource.language(infoSource, "Link", "seeMore")
        const seeLess = infoSource.language(infoSource, "Link", "seeLess")
        let ML = !textBool ? AML : [false, false]

        return (
            <div style={{marginTop: '20px', maxWidth: '600px', margin: '0 auto' }}>
              {/* Initial text */}
              <p>
                {previewText}
                {!textBool && '...'} {/* Ellipsis if not expanded */}
            
        
              {/* Additional text shown when expanded */}
              {textBool && (
                <span>
                {/* Texte supplémentaire affiché uniquement si étendu */}
                {remainingText}
                </span>
              )}
            
              {/* Toggle button */}
              <span style={{padding: '5px' }} className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={()=>handMLShowText(ML)} 
            //   style={{ marginTop: '10px', padding: '10px 20px' }}
              >
                {textBool ? seeLess : seeMore}
              </span> 
              </p>
            </div>
          );
      }
        const PaymentNotS2 =()=>(
        <table className="w-75 table table-sm">
            <tbody>
                <tr>
                    <th>{countProduct} {infoSource.laguangeElakay('label', infoSource.languageAPI).items} : </th>
                    <td>{(ItemsOrder()).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })} </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).shipping} : </th>
                    <td>{
                        isAuthenticated() ?
                            (shipAmount).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }) :
                            (0).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                    }
                    </td>
                </tr>
                <tr>
                    <th>{infoSource.laguangeElakay('label', infoSource.languageAPI).tax} : </th>
                    <td>{(TaxOrder()).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                    </td>
                </tr>
                <tr className="text-danger">
                    <th style={{ fontSize: "18px" }}> {infoSource.laguangeElakay('label', infoSource.languageAPI).total}: </th> <td style={{ fontSize: "18px" }}>{(getTotal()).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}</td>
                </tr>
           
            </tbody>
        </table>
        
    )

    const infoPayment = ()=>{
        return <>
                                    <h6 className="p-1 m-0 pl-3" >
                                <span className="p-0 m-0">
                                    {moment(orders.createdAt).fromNow()}
                                    </span>
                            </h6>
                            <div className="row m-0">
                                <div className="col-sm-7 col-md-6">
                                    <h5 className="pl-0">{infoSource.laguangeElakay('title', infoSource.languageAPI).paymentInfo}</h5>
                                    {PaymentNotS2()}

                                  {  objNotEmpty(orderPayment) && <><h5 className="pl-0">{infoSource.laguangeElakay('title', infoSource.languageAPI).paymentMethods}</h5>
                                    <div className="w-75 bg-white p-2 mb-3">
                                    <h6> <span>{`${orderPayment.b_brand} ending: ${orderPayment.b_last4}`}</span></h6>
                                    <h6> <span>{`Exp: ${orderPayment.b_exp_month} / ${orderPayment.b_exp_year}`} </span></h6>
                                    </div></>}
    </div>
            
                        <div className="col-sm-7 col-md-6">
                                   
                                    <h5 className="pl-0">{infoSource.laguangeElakay('title', infoSource.languageAPI).shippnigAddress}</h5>
                                    <div className="w-75 bg-white px-1 py-2">
                                    <h6 >{ordersShipping.firstName} {ordersShipping.lastName}</h6>
                                    <h6>{ordersShipping.line1}</h6>
                                    <h6>{ordersShipping.city}, {ordersShipping.state}, {ordersShipping.postal_code}</h6>
                                    <h6>{ordersShipping.country}</h6>
                                    </div>
                        <div>
                                {showCancelButton(orders && orders.products && orders.products.status) && <button onClick={()=>handPageForm('cancel')} className="w-75 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4">
                                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).cancelOrder}
                                </button>}
                                {showReturnOrReplaceButton(orders && orders.products && orders.products.status) && <button onClick={()=>handPageForm('return')}  className="w-75 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4">
                                   {infoSource.laguangeElakay('Button', infoSource.languageAPI).returnReplace}
                                </button>}
                        </div>
                                    
                        </div>
                            </div>
        </>
    }

    const formRCR = ()=>{
        let text = infoSource.language(infoSource, "text", "textOrderReturnDet")
        let textLess = infoSource.language(infoSource, "text", "textOrderReturnDetLess")

        let text1 = infoSource.language(infoSource, "text", "textOrderReturnAct")
        let textLess1 = infoSource.language(infoSource, "text", "textOrderReturnActLess")

        let text2 = infoSource.language(infoSource, "text", "textOrderRefunds")
        let textLess2 = infoSource.language(infoSource, "text", "textOrderRefundsLess")

        let elakayTicket = infoSource.laguangeElakay('elakayTicket',  infoSource.languageAPI)
        let detailReturn = infoSource.laguangeElakay('elakayTicket',  infoSource.languageAPI)[0]
        let actionReturn =infoSource.laguangeElakay('elakayTicket', infoSource.languageAPI)[1]
        let detailCancel =infoSource.laguangeElakay('elakayTicket', infoSource.languageAPI)[2]
        return <>
              <div className="row m-0">
            <div className="boxCategory1 text-center" id="heading">
            </div>
            <form className="mb-3">
              
            <div className="form-group">
           <label className="text-muted">
            {/* {infoSource.language(infoSource, "label", "selLanguaComp")} */}
            </label>
            {testInfo(text, textLess, MLShowText[0], [true, false])}
           <select onChange={handchange('cause')} className="form-control" value={cause}>
           <option value={''} > select</option>
           {loadingTicket(elakayTicket, pageForm[1] ?detailReturn: detailCancel).map((ob, j) =>{
            return <option value={ob.ticket} >{ob.ticket}</option>
           })}
           </select>
       </div>

                <div className="form-group">
                    {/* <label className="text-muted">{infoSource.laguangeElakay('aboutUs',  infoSource.languageAPI)}</label> */}
                    <textarea
                        onChange={handchange('causeNote')}
                        className="form-control"
                        placeholder={'placeholder'}
                        rows="6"
                        value={causeNote}
                    />
                </div>

        <div className="form-group">
           <label className="text-muted">
            {/* {infoSource.language(infoSource, "label", "selLanguaComp")} */}
            </label>
            {testInfo(text1, textLess1, MLShowText[1], [false, true])}
         { pageForm[1] &&  <>
           <select onChange={handchange('asking')} className="form-control" value={asking}>
           <option value={''} > select</option>
           {loadingTicket(elakayTicket, actionReturn).map((ob, j) =>{
            return <option value={ob.ticket} >{ob.ticket}</option>
           })}
           </select>
           </>}
       </div>
            </form>
            <div>
            <button onClick={()=>handPageForm()} className="w-25 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4 float-left">
            {/* cancel */}
            {infoSource.language(infoSource, "Button", "cancel")}
            </button>
            <button onClick={clickBtnTickect} className="w-25 my-3 py-1 btn btn-outline-dark btn-md btn-block rounded-4 float-right">
            {/* submit */}
            {infoSource.language(infoSource, "Button", "submit")}
            </button>
            </div>
            <ReadMoreLess infoSource={infoSource} text={text2} textLess={textLess2}/>
        </div>
        </>
    }
    
    // let shipAmount = parseFloat(orders.shipping_amount_local).toFixed(2)
    let shipAmount = parseFloat(0).toFixed(2)


    const order = () => (
        <div>

            {
                    // <div  className="container mt-3 mb-3 p-0 pb-2">
                    <div>
                    <div className="m-2">
                {productView()}
                    </div>
                   <div style={{ backgroundColor: "#f0f0f0", color: "#000000" }}>
                   {pageForm[0] &&infoPayment()}
                    {!pageForm[0] && formRCR()}
                        </div>

                        <div className="m-2">
    {orders && orders.user_pubCategoryProd && <CategoryPub
        Follow ={true}
        nbrArr={nbrArr}
        userID={orders.prod_Owner}
        infoSource ={infoSource}
        pubCategoryProd ={orders.user_pubCategoryProd}
        />}
                        </div>
            
                    </div>
                    //  </div>
        
            }
        </div>
    )

    return (
        <>
            {order()}
              </>
    );
};

export default Orders;
