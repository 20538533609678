import React from "react";
import FormAddress from "../../pageInside/formInput/formAddress";

const EditAdrressUserAss = (props) => {
    const {
        laguangeElakay,
        setAdress,
        adress,
        phoneUser,
        infoSource
            } = props

        return <div>
        <FormAddress
            //   clickSubmit={clickSubmit}
              values ={adress} 
              setValues={setAdress}
              infoSource={infoSource}
              phoneUser={phoneUser}
              laguangeElakay ={laguangeElakay}
              buttonActive = {false}
              BorderActive = {false} 
            
            />  
                 
                </div>

   
};

export default EditAdrressUserAss;