// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swipe-container {
    position: relative;
    min-height: 100vh;
    touch-action: pan-y;
    /* z-index: 9000; */
  }
  
  .header0 {
    position: absolute;
    width: 100%;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 900;
    will-change: transform;
  }
  
  .header-primary {
    top: 0;
    /* background: #2563eb; */
    color: white;
    transform: translateY(-100%);
  }
  
  .header-secondary {
    top: 0;
    /* background: #059669; */
    color: black;
    transform: translateY(100%);
  }
  
  .header0.active {
    transform: translateY(0);
  }
  
  .header0.hidden {
    transition-duration: 0.6s;
    transform: translateY(calc(-100% - 10px));
  }
  
  .content {
    padding: 2rem;
    padding-top: 80px;
    text-align: center;
  }
  
  @media (max-width: 640px) {
    .header0 {
      /* height: 50px; */
      font-size: 0.9rem;
    }
    
    .content {
      padding-top: 70px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/includes/Layout/allHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uDAAuD;IACvD,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,MAAM;IACN,yBAAyB;IACzB,YAAY;IACZ,4BAA4B;EAC9B;;EAEA;IACE,MAAM;IACN,yBAAyB;IACzB,YAAY;IACZ,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;IACzB,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE;MACE,kBAAkB;MAClB,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".swipe-container {\n    position: relative;\n    min-height: 100vh;\n    touch-action: pan-y;\n    /* z-index: 9000; */\n  }\n  \n  .header0 {\n    position: absolute;\n    width: 100%;\n    height: 85px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n    z-index: 900;\n    will-change: transform;\n  }\n  \n  .header-primary {\n    top: 0;\n    /* background: #2563eb; */\n    color: white;\n    transform: translateY(-100%);\n  }\n  \n  .header-secondary {\n    top: 0;\n    /* background: #059669; */\n    color: black;\n    transform: translateY(100%);\n  }\n  \n  .header0.active {\n    transform: translateY(0);\n  }\n  \n  .header0.hidden {\n    transition-duration: 0.6s;\n    transform: translateY(calc(-100% - 10px));\n  }\n  \n  .content {\n    padding: 2rem;\n    padding-top: 80px;\n    text-align: center;\n  }\n  \n  @media (max-width: 640px) {\n    .header0 {\n      /* height: 50px; */\n      font-size: 0.9rem;\n    }\n    \n    .content {\n      padding-top: 70px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
