export const Like_disLike = (
    Like,
    Like_0_1
) => {

let a = {}
let b =[]
let c 
let d
    Like_0_1 && (
Like.forEach(p => {
    if (p.like===true){
    a.like =p.like
    b.push(a) 
    }
})  
) 
    !Like_0_1 && (
        Like.forEach(p => {
            if (p.like === false) {
                a.like = p.like
                b.push(a)
            }
        })
    ) 

c = b.length

if(c){
if(c < 1000){
d = c
}
    if(c >= 1000){
        d = parseFloat(c / 1000).toFixed(1)+'K'
    }
    if(c > 1000000){
        d = parseFloat( c / 1000000).toFixed(1)+'M'
    }
c = d
}
    return (
        c
    )
}