import React from "react";

const PaginationInfo =(props)=>{
    const{
        skip,
        limit,
        siseProduct,
        infoSource,
        prodCat
    }= props

    return  <div className="pl-2" style={{width: "100%",  borderRadius: "7px", backgroundColor: '#DCDCDC'}}>
            
                <h5>
                {prodCat} {' '}
                {(skip - limit) + limit + 1} - {((skip + limit) > siseProduct) ? siseProduct : (skip + limit)}
                 {/* of  */}
                 {' '}{infoSource.language(infoSource, "label", "of")}{' '}
                 {siseProduct}{' '}
                {infoSource.laguangeElakay('label', infoSource.languageAPI).items}
            </h5>
            </div>

}
export default PaginationInfo; 