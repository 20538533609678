import { elakay_API } from "../../config";

export const listePM_createStripeCusTomer = (token, paymentMethod) => {
    return fetch(`${elakay_API}/stripe/listePM_crateCustomer`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(paymentMethod)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const updateCustomer = (token, info) => {
    return fetch(`${elakay_API}/stripe/CustomersUpdate`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const createPaymentIntent = (token, paymentMethodReq) => {
    return fetch(`${elakay_API}/stripe/createPaymentIntent`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(paymentMethodReq)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const AttachPaymentMethod = (token, info) => {
    return fetch(`${elakay_API}/stripe/attachPaymentMethod`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
       
        body: JSON.stringify(info)
    })
        .then(response => { 
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const DeletePaymentMethod = (token, info) => {
    return fetch(`${elakay_API}/stripe/deletePaymentMethod`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
       
        body: JSON.stringify(info)
    })
        .then(response => { 
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const CustomersRetrieve = (token) => {
    return fetch(`${elakay_API}/stripe/CustomersRetrieve`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const get_client_secret = (token) => {
    return fetch(`${elakay_API}/stripe/get_client_secret`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};



export const PaymentIntent = (token, info) => {
    return fetch(`${elakay_API}/stripe/payment-intent`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const retrievePayment = (token, info) => {
    return fetch(`${elakay_API}/stripe/retrievePaymentMethod`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const updatePayment = (token, info) => {
    return fetch(`${elakay_API}/stripe/UpdatePaymentMethod`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },

        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const DeleteCuStID = (token) => {
    return fetch(`${elakay_API}/stripe/DeleteCuStID`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getPaymentIntentView = (token, id) => {
    return fetch(`${elakay_API}/stripe/payment-View/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const ListaccountsOrcreateStripeAccount = (token, info) => {
  
    return fetch(`${elakay_API}/stripe/List_ACC_Or_CRE_STR_ACC`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const CreatBankAccounts = (token, info) => {
   
    return fetch(`${elakay_API}/stripe/CreatBankAccounts`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })  
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const CreatExBankAccounts = (token, info) => {
 
    return fetch(`${elakay_API}/stripe/CreatBankAccountsExternal`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getListBankAccounts = (token) => {
    return fetch(`${elakay_API}/stripe/listBankAccounts`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const postCreatePayout = (token, info) => {

    return fetch(`${elakay_API}/stripe/CreatePayout`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const postCreateTransfer = (token, info) => {
 
    return fetch(`${elakay_API}/stripe/CreateTransfer`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(info)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getListExternalAccounts = (token) => {
    return fetch(`${elakay_API}/stripe/listExternalAccounts`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};