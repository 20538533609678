import React, { useState, useEffect } from "react";
import PreViewProduct from '../slider/swiperProdView'
import Vote, {ratingVote} from './commentaire/vote'
import Price from "../pageInside/productsPrice"
import PrevviewProFilt from "../pageInside/prevviewProFilt";
import TabsElakay, {tabElakay} from "../pageInside/html/formTabs";
import imgProductLakay from '../Layout/imgs/md3.jpg' 

const ProdViewEdit = (props) => {
    const [oneFilterPro, setOneFilterPro] = useState({})
    const [restAllFilterPro, setRestALLFilterPro] = useState({})
    const [allFilterPro, setALLFilterPro] = useState({})
    const [quantity, setQuantity] = useState(0)
    const [parcel, setParcel] = useState()
    const [images, setImages] = useState([])
    const [galeries, setGaleries] = useState([])
    const [filters, setFilters] = useState([]);
    const {
        infoSource,
        laguangeElakay,
        // images = [],
        getProduct,
        count,
        // quantity,
        // filters,
        // oneFilterPro,
        descriptionProduct,
        getImages
    } = props

    let voteRating = ratingVote(getProduct.vote)
    const priceProd = {price: getProduct.price, salePrice: getProduct.salePrice, count}

    useEffect(() => {          
        getProduct &&  getProduct.filterProduct && getProduct.galeries && loadImages(getProduct._id, getProduct.galeries, ProFilQuaGrToLe(getProduct.filterProduct)[0])      
        getProduct &&  getProduct.filterProduct && setALLFilterPro(ProFilQuaGrToLe(getProduct.filterProduct))
        getProduct &&  getProduct.filterProduct && setRestALLFilterPro(ProFilQuaGrToLe(getProduct.filterProduct))
        getProduct && getProduct.filterProduct && setOneFilterPro(ProFilQuaGrToLe(getProduct.filterProduct)[0])
        getProduct && getProduct.filterProduct && setNewAP(ProFilQuaGrToLe(getProduct.filterProduct)[0])
        getProduct &&  getProduct.galeries && setGaleries(getProduct.galeries)
        getProduct &&  getProduct.categoriesIDv == [] ? setFilters(getProduct.categoriesID[0].catID.filter) : setFilters([])
    }, [getProduct]);

    const setNewAP = (a)=>{
      a &&  setQuantity(a["quantity"]) 
        // setParcel(a.parcel && a.parcel.width !== null || undefined? a.parcel: restAllFilterPro[0].parcel && restAllFilterPro[0].parcel.width  !== null || undefined? restAllFilterPro[0].parcel: {}) 
        
    }

    const reLoadImages =(a, b, c)=>{
        let x = b.filter["color"]
        a.filter(item =>{
            if(item.name == x){
                c(item.images) 
            }
            
        })
            
    }

    const loadImages =(Id, a, b)=> {
        if (b && a[0] && a[0].images.length){
            reLoadImages(a,  b, setImages)
        }else{
        getImages(Id).then(data => {
            if (!data) {
                // console.log(data.error);
                setImages(imgProductLakay)
            } else {
                setImages(data.galeries.length > 0 ? [data.galeries[0].urlImg]: [imgProductLakay])
            }
        })}
    };

    const descrip = (
        <>
        <p>{getProduct.description}</p>  
        {descriptionProduct}
        </>
    )

    const ProFilQuaGrToLe =(a)=>{
        return a && a.sort(({quantity:y}, {quantity:x}) => x-y)
    }

    const stock = (a) => {
        if (a == 0) {
              return <h6 className="text-danger">{infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart1}</h6> 
             }
             else if (a < 20) {
              return <h6 className="text-danger"> {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart2} {a} {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart3}</h6>
             }
              else if (a >= 20) {
              return <h6 className="text-success"> {infoSource.laguangeElakay('text', infoSource.languageAPI).textCardCart4}</h6>
              }}

    const etoiteProduct = (
        <>
            {voteRating && (<h5 className='p-0 m-0'><Vote  rating={voteRating}/></h5>)}
            {!voteRating && (<h5 className='p-0 m-0'><Vote rating={0} /></h5>)}
        </>
        )

        const filterDetail = () =>(

            <div className="mt-3">
        {
       oneFilterPro  && filters && filters.map((k, i) => (
        <div key={i}>
        <p style={{minWidth: "130px", fontWeight:"bold", fontSize:"18px", display:"inline-block"}}>{k._id.name}: </p>  
               <p style={{ fontSize: "15px", display: "inline-block" }}>{ oneFilterPro.filter[k._id.name]}</p>
    </div>
        ))}
        </div>
        )

const view =()=>{ 
   return <>
    <div className="row mt-3">
    <div className="col col-12 col-md-6 col-lg-5">
    {images && images.length > 0 && <PreViewProduct
        images={images}
       />}
    </div>
    <div className="col col-12 col-md-6 col-lg-7">
    
    <table
    className ={"table table-sm"}
    >
        <tbody>
            <tr><th>{etoiteProduct}</th></tr>
            <tr><td>{<h5>{getProduct.name}</h5>}</td></tr>
            <tr><td>{getProduct.description}</td></tr>
            <tr><td><Price showAddToCartButton={false} products={priceProd}/></td></tr>
            <tr><td>{stock(quantity)}</td></tr>
        </tbody>
    </table>
    <PrevviewProFilt
    infoSource ={infoSource}
    setQuantity={setQuantity}
    galeries={galeries}
    setImages={setImages}
    oneFilterPro={oneFilterPro}
    setOneFilterPro={setOneFilterPro}
    setALLFilterPro={setALLFilterPro}
    restAllFilterPro={restAllFilterPro}
    allFilterPro = {allFilterPro}
    filter ={getProduct.filters}
    // getQueryFil={getQueryFil}
    setNewAP={setNewAP}
    
    />
    
    </div>
    </div>
    <div className="row m-3 justify-content-center" >
        <div className="col col-12 col-md-11 col-lg-11 col-xl-7 ">
            <TabsElakay
            title={"details"}
            >
            {tabElakay(filterDetail(), "details", "Details")}
            {tabElakay(descrip, "description", "Description")}
    
            </TabsElakay>
    </div>
    </div>
    </> 
}
return view()
};

export default ProdViewEdit;