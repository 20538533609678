import React, { useState, useEffect } from "react";
import { Link,  Navigate} from "react-router-dom";
// import imgProductLakay from './images/md3.jpg';
import {activatorUserAdding} from "../../auth";
import { MdSmartphone} from "react-icons/md";
import { laguangeElakay } from "../../pageInside/dataElakay";
// import "./sliderProduct.css"
// import { Seller, } from "../../pageInside/Rolle"
import FormAddress from "../../pageInside/formInput/formAddress";

const AddAddressUserAssociate = (props) => {
    const {
        setPagesUser,
        bnTelephone,
        setUserInfo,
        token,
        infoSource,
        phoneUser
            } = props

            
    let userValue = {
        firstName: "",
        lastName: "",
        shopName: null,
        tel: "",
        Address1: "", 
        Address2: "",
        city: '',
        State: '',
        postalCode: '',
        country: '',
        latitude: "",
        longitude: "",
        error: "",
    }

    const [values, setValues] = useState(userValue);

    const clickSubmit = event => {

        event.preventDefault();
           
        activatorUserAdding(token, 'address',  values, true).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues(userValue)
                setUserInfo(data)
                setPagesUser(bnTelephone)
            }
        });
    };

        return <FormAddress
                clickSubmit={clickSubmit}
                values ={values} 
                setValues={setValues}
                infoSource={infoSource}
                phoneUser={phoneUser}
                laguangeElakay ={laguangeElakay}
                />

   
};

export default AddAddressUserAssociate;

// {
//     firstName: 'Yves',
//     lastName: 'OXilus',
//     shopName: 'Yvox',
//     tel: '+1 347 781 3174',
//     Address1: '226 Norfeld BLVD',
//     Address2: '',
//     city: 'Elmont',
//     State: 'New York',
//     postalCode: '11003',
//     country: 'US',
//     latitude: '40.6976',
//     longitude: '-73.7049',
//     coordinates: [],
//     addressDefault: true,
//     BillAddress: true,
//     _id: ObjectId("673c023dca67817ed2d6075f"),
//     createdAt: ISODate("2024-11-19T03:13:01.351Z"),
//     updatedAt: ISODate("2024-11-19T03:13:01.351Z")
//   }