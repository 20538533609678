import React, { useState} from "react";
import {useLocation, Link, useNavigate} from 'react-router-dom';
import { signin, authenticate, isAuthenticated, getElakayLang, verificationCode} from "../includes/auth";
import Form from 'react-bootstrap/Form'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import logoElakay from '../includes/Layout/icon/LogoElakay.svg';
import './user.css';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Signin = (props) => {
    const {
        infoSource
    }=props
    let query = useQuery();
    let urlToken = query.get("token")
    let urlrandom = query.get("random")


    function isNullOrEmpty(value) {
        // Check for null, undefined, or false
        if (value == null || value === false) return true;

        // Otherwise, return false (the value is neither null, undefined, false, nor empty)
        return false;
    }

    const {obj} = getElakayLang();
    
    const [showPassword, setShowPassword] = useState(true) 
    const [showConditions, setConditions] = useState(false);
    const [interfaceToken, setInterfaceToken] = useState('');
    const [saveInterface, setsaveInterface] = useState(false);
    const [values, setValues] = useState({
        numberDigi: "",
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });

    let navigate = useNavigate()
    const {numberDigi, email, password, loading, error, redirectToReferrer } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };


  if(!isNullOrEmpty(urlToken) && !isNullOrEmpty(urlrandom)){
    let value = {
        interfaceToken : urlToken,
        numberDigi: urlrandom, 
        saveInterface : false
    }
   
    verificationCode(value)
            .then(data => { 
                setValues({ ...values, error: false, loading: true });
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false, Suc: false});
                    return navigate("/signin")
                } else {  
                    authenticate(data, () => {
                        setValues({
                            ...values
                        });
                        return navigate("/")
                    });
                    window.location.reload()
                }
            });
  }

    const clickSubmitCode = () => {
        setValues({ ...values, error: false, loading: true });
        verificationCode({interfaceToken, numberDigi, saveInterface})
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false, Suc: false});
                } else {  
                    authenticate(data, () => {
                        setValues({
                            ...values,
                            redirectToReferrer: true
                        });
                    });
                    window.location.reload()
                }
            });
    };
    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });
        signin({ email, password, language: obj})
        .then(data => {
            if (data.error) {
                console.log(data.error)
                setValues({ ...values, error: data.error, loading: false });
            } else {
                if(data.macInterface){
                setConditions(data.macInterface)
                setInterfaceToken(data.token)
                setValues({})
                }else{
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });
                });
                window.location.reload()
            }
            }
        });
    };

const clickelakay = ()=>{ 
    // navigate("/")
    // window.location.reload()
    return window.location.href = "/";
   
}

const veryCodde = () => (



<div>
        <form >
             <h6 className="text-center m-4">this a new devivi</h6> 
             <h6 className="text-center m-4">
                {infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text1}
                </h6> 
                <h6 className="text-center m-4">
                {infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text2}
                </h6> 
            {showError()}


            <div className="form-label-group">
                    <Form.Control onChange={handleChange("numberDigi")} value={numberDigi} type="text"  name="numberDigi" id="inputNumberdigi" maxlength="8" className="form-control" placeholder="Secure cerification code" required />
                <Form.Label htmlFor="inputNumberdigi">
                    {infoSource.laguangeElakay('label', infoSource.languageAPI).secuCode}
                    </Form.Label>
            </div>

            <div className="form-check form-switch m-4">
                <input 
                className="form-check-input" 
                type="checkbox"
                checked={saveInterface ? "checked" : ''} 
                onClick={() => setsaveInterface(saveInterface ? false : true)} 
                value={saveInterface} 
                name="saveInterface"  
                id="saveInterface"
                 />       
                  <label className="form-check-label" for="saveInterface" >
                    {infoSource.laguangeElakay('label', infoSource.languageAPI).saveDevice}
                    </label>
                 
         </div>
            <h6 className="fst-italic text-info">
                {infoSource.laguangeElakay('signinPage', infoSource.languageAPI).text3}
                </h6>
            <input type="hidden" name="verication" placeholder="" />

        </form>
        <div className="form-group mt-4">
                <button className="btn btn-outline-primary" onClick={() => clickSubmitCode()} >
                    {infoSource.language(infoSource, 'Button', "submit")}
                    </button>
        </div>
    </div>
);

const AddTermsAndConditions = () => (
    <div className="contaire">
        <div className="row justify-content-center " >
            <Modal
                show={showConditions}
                onHide={() => setConditions(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h2 className="text-center">
                            {infoSource.laguangeElakay('signinPage', infoSource.languageAPI).title}
                            </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                  {veryCodde()}
                </Modal.Body>
            </Modal>
        </div>
    </div>
)
    const signUpForm = () => (


        <div>
    
            <div className="usHeader">
                <h1 onClick={() =>clickelakay()}> <img className="img-responsive" src={logoElakay} width="24" alt="logo elakay"/>elakay</h1>
            </div> <br />


            <form className="form-signin" action="" method="post">
                <h4 className="text-center">
                     {infoSource.laguangeElakay('Button', infoSource.languageAPI).signin} 
                     </h4>
                {showLoading()}
                {showError()}
                <div className="form-label-group">
                    <Form.Control onChange={handleChange("email")} type="email" value={email} name="email" id="inputEmail" className="form-control" placeholder="Email address" required />
                    <Form.Label htmlFor="inputEmail">
                        {infoSource.laguangeElakay('label', infoSource.languageAPI).email}
                        </Form.Label>
                </div>


                <div className="form-label-group">
                    <input 
                    onChange={handleChange("password")} 
                    type={showPassword ? "password" : "text"} 
                    value={password} 
                    name="motsCache" id="inputPassword" 
                    className="form-control" 
                        placeholder="Password" required  />
                    <Form.Label htmlFor="inputPassword">
                        {infoSource.laguangeElakay('label', infoSource.languageAPI).Password}
                        </Form.Label> 
                    <span  className="h4"  > {showPassword ? <FaEye onClick={() => setShowPassword(false)} /> : <FaEyeSlash onClick={() => setShowPassword(true)}/>}</span> 
                </div>

                <input type="hidden" name="verication" placeholder="" />
                <div className="elakay_signin_input">


                </div>
                { interfaceToken && (<p><Link to="#" onClick={() => setConditions(true)}>
                {infoSource.laguangeElakay('Link', infoSource.languageAPI).CheckDevise}
                </Link></p>)}
                <p><Link to="/forget-password">
                {infoSource.laguangeElakay('Link', infoSource.languageAPI).forgetPassword}
                </Link></p>

                <button onClick={clickSubmit} className="btn btn-lg  btn-block" type="submit" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).signin}
                    </button>

                <Link className="btn btn-lg btn-block mt-3" to="/signup" style={{color:'#ffffff', backgroundColor: '#007399', position:'relative', padding: '8px', textAlign: "center", fontSize: "1.2rem"}}>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).creatAccount}
                </Link>  

            </form>
        </div>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            <div dangerouslySetInnerHTML={{__html: error}}></div>
        
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>
                    {/* Loading... */}
                    {infoSource.language(infoSource, "title", "loanding")}
                    </h2>
            </div>
        );

    const redirectUser0 = () => { 
        if (redirectToReferrer){ 
        navigate(-2)
        window.location.reload() 
        } 
    };
    const redirectUser1 = () => { 
     if (isAuthenticated()) {
         navigate("/")
         window.location.reload()
            } 
        }

    return (
        <div> 
            {/* <h1>yves oxlisy</h1> */}
             {redirectUser1()}
            {signUpForm()}
            {redirectUser0()}
           {AddTermsAndConditions()}
      </div>
    );

};

export default Signin;
