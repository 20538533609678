import React, {useEffect } from "react"

 const AlertPage = (props) => {
//   const [showAlert, setShowAlert] = useState(false);
const {
        showAlert = false,
        setShowAlert,
        messagetext = 'JSON copié dans le presse-papiers!'  
        } = props

    const functimeOut = (showAlert)=>{
       if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 3000);
      return () => clearTimeout(timer);
    }  
    }
      useEffect(() => { 
        if (showAlert) {
            const timer = setTimeout(() => setShowAlert(false), 3000);
            return () => clearTimeout(timer);
          }
        }, [showAlert]);

    // Style simple pour le petit « toast »
const toastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#fff',
    padding: '15px 25px',
    borderRadius: '5px',
    zIndex: 999900,
    fontSize: '16px',
  };

   
   return <div style={{ textAlign: 'center', marginTop: '50px' }}>
      {showAlert && (
        <div style={toastStyle}>
         { messagetext}
        </div>
      )}
    </div>
  ;
}
export default  AlertPage

