import React, { useState, useEffect, useRef} from "react";
import Card from '../../pageInside/Card' 
import { Row } from 'react-bootstrap';
import { getLakayUser } from "../../auth/apiProduct";
import ButtonPagination from "../../pageInside/ButtonPagination";
import { laguangeElakay } from "../../pageInside/dataElakay";
import SliderPun135 from "../../slider/swiperPub1500x400";
import CategoryPub from "./categoryPub";
import {userPub} from "../../auth";
import Modal from 'react-bootstrap/Modal'
import { prices } from "../../pageInside/fixedPrices";
import HeaderShopSeller from "../../Layout/headerShopSeller";
// import SideDra from "../../../components/SIdeDrawer/sideDra+";
import SwiperCatUserProd from "../../slider/swiperUserProd/swiperCatUserProd";

function processData(data) {
    // Obtenez une liste plate avec tous les noms
    const allNames = data.flatMap(item => item.name);
    const nameCounts = allNames.reduce((acc, name) => {
        acc[name] = (acc[name] || 0) + 1;
        return acc;
    }, {});

    return data.map(item => {
        // Filtrer les noms qui ne sont pas communs à tous les tableaux
        const filteredNames = item.name.filter(name => nameCounts[name] < data.length);

        // Trouver un groupe de genre (comme "Men", "Women", "Boys")
        const genderGroup = filteredNames.find(name =>
            ["Men", "Women", "Boys", 'Girls', 'Babies'].includes(name.trim())
        );

        // Construire "cateryName" avec l'élément principal et le groupe de genre
        const categoryNameParts = [genderGroup, item.name[0]].filter(Boolean);
        const categoryName = categoryNameParts.join(" ").trim();

        return {
            ...item,
            cateryName: categoryName
        };
    });
}


const ShopUserView = props => {
    const {
        setLoading,
        infoSource,
        promoMaker= false,
        outOffPage= true,
        showFilter = true,
        myShop,
        userID,
        ci,
        setFilterShop,
        myFilters = {filters: {"categoriesID.gategoriesLink": [] }},
        prices,
        setCalFilShop,
        btnShopPromo,
        setRefShopName,
        refShopName,
        setInfoHeaderShop
            } = props
            
    const [productsByArrival, setProductsByArrival] = useState([]);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [siseProduct, setSiseProduct] = useState(0);
    const [getUserPub, setUserPub] = useState([]);
    const [pubCategoryProd, setPubCategoryProd] = useState([])
    const [nbrArr, setNbrArr] = useState(3)
    const [pubCategoryProd0, setPubCategoryProd0] = useState([])
    const [getCategoriesUser, setCategoriesUser] = useState([]);
    const [getOwner, setOwner] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [categorisUser, setCategorisUser] = useState([])
    const [booFollow, setBooFollow] = useState(true);
    const [unFollow, setUnFollow] = useState(true);
    const [editStore, setEditStore] = useState(false);
    const [getCi, setCi] = useState(ci);
    const [search, setSearch] = useState('')
    const [prodCat, setProdcat] = useState('All Products')
    const divCatProduct = useRef(null);
    const scrollToParagraph = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      };


      const loadindSearCh = (s)=>{
        setSearch(s)
        if(s.length > 2){
            const timer = setTimeout(() => {
                scrollToParagraph(divCatProduct);
              }, 2000);
              return () => clearTimeout(timer);
        }
            // getLakayUser(userID, skip, limit, "createdAt", getCi, s, myFilters.filters).then(data => {
        //     if(s.length === 1){
        //      scrollToParagraph(divCatProduct)
        //     }
        //     setProductsByArrival(data.products);
        //     setSiseProduct(data.siseProduct);
        //     setSize(data.size);
        //     setSkip(skip);
        // }).catch(error => {
        //     console.log(error);
        // });     
    }
    

const onFilterShop = ()=>{
    setSearch('')
    setFilterShop(true)
    scrollToParagraph(divCatProduct)
}

    const findToFollowU = (a, ub, uc)=>{
        let boo
        if(ub &&  userID === ub._id){
            boo = false
        }else if(userID === uc){
            boo=  false
        }else{

            boo = true       
        }
        setBooFollow(boo)
     }
    

     const unFollowShop =(a)=>{
        let b =  !a.some(ob => ob && ob._id == userID)
        setUnFollow(b)
     }


 const reziArray = (a, n)=>{
       let arr = a.slice(0, n)
       setPubCategoryProd0(arr)
       setNbrArr(n)
    }

    const resizeWidth = ()=>{
        setWindowWidth(window.innerWidth)
    }

    const changeArray = (a)=>{
        // window.matchMedia(`(${w}: ${p})`).addEventListener('change', () => reziArray(a, n))
        // window.matchMedia(`(${w}: ${p})`).addEventListener('resize', () => reziArray(a, n))
        window.matchMedia(`(max-width: 768px`).addEventListener('change', () => reziArray(a, 3))
        window.matchMedia(`(max-width: 576px`).addEventListener('change', () => reziArray(a, 2))
        window.matchMedia(`(min-width: 576px`).addEventListener('change', () => reziArray(a, 2))

    if(windowWidth >= 768){
        reziArray(a, 3)
    }else{
        reziArray(a, 2)
    }
    }
   
    const concatArray = (a)=>{
        let np =[]
        let newA = a.forEach(p =>np.concat(p))
        return newA
    }

    const creatCatUser = (a, b)=>{
        let newA = a.map(product => product[b]).flat().filter((v, i, a)=>{
            const k = `${v.catID}`;
            return a.findIndex(t =>`${t.catID}`===k) === i
        })
        setCategorisUser(newA)
    }

const loadingUserPub =(uid)=>{
    userPub(uid).then(data => {
        if(!data){
console.log('user not Found')
        }else{
    setUserPub(data)
        }
    })
}
    const loadProductsByArrival = (uid, cid, search, myFilters) => {
        
        uid &&  getLakayUser(uid, skip, limit, "createdAt", cid, search, myFilters.filters).then(data => {
        
            // creatCatUser(data.products, 'categoriesID')
            setProductsByArrival(data.products);
            promoMaker && setRefShopName( Array.isArray(data.owner.shopName) ? "lakay " +  data.owner.shopName[0]: "lakay " + data.owner.shopName)
            setOwner(data.owner)
            setSiseProduct(data.siseProduct)
            setSize(data.size);
            setSkip(0);
            setLoading(false)
            setPubCategoryProd(data.owner.pubCategoryProd)
            changeArray(data.owner.pubCategoryProd) 
           infoSource.userInfo && infoSource.userInfo._id && findToFollowU(infoSource.getShopFollow, infoSource.userAdmin, infoSource.userInfo._id) 
           infoSource.getShopFollow && unFollowShop(infoSource.getShopFollow)            
           getCategoriesUser.length == 0 && setCategoriesUser(data.categories)
           getCategoriesUser.length == 0 && setCalFilShop({owner: data.owner, categoies: data.categories, filters: data.filters, prices})
            setInfoHeaderShop({setFilterShop, onFilterShop, loadindSearCh, search, shopName : data.owner.shopName && Array.isArray(data.owner.shopName) ? data.owner.shopName[0]: data.owner.shopName})
            }).catch(error => {
            console.log(error);
        });
    };

    const loadMore = () => {
        let toSkip = skip + limit;
        userID &&   getLakayUser(userID, toSkip, limit, "createdAt", getCi, search, myFilters.filters).then(data => {
          
            setProductsByArrival(data.products)
            setSiseProduct(data.siseProduct)
            setSize(data.size);
            setSkip(toSkip);

        }).catch(error => {
            console.log(error);
        })
    };

    const loadMore0 = () => {
        let toSkip = skip - limit;
        userID &&   getLakayUser(userID, toSkip, limit, "createdAt", getCi,  search, myFilters.filters).then(data => {

            setProductsByArrival(data.products);
            setSiseProduct(data.siseProduct);
            setSize(data.size);
            setSkip(toSkip);

        }).catch(error => {
            console.log(error);
        });
    };
    

    useEffect(() => { 
        window.addEventListener('resize', () =>resizeWidth())
     
        // findToFollowU(infoSource.getShopFollow, infoSource.userAdmin._id, infoSource.userInfo._id)
        loadProductsByArrival(userID, getCi, search, myFilters)
        // changeArray(pubCategoryProd)
        loadingUserPub(userID)
    }, [userID, getCi, myFilters, search]);

    const productALL = (
        <Row className="m-1" xs={2} sm={2} md={3} lg={4} xl={5}>
            {
                productsByArrival.map((product, i) => (
                    <div className="col p-2" key={i}>
                        <Card
                          laguangeElakay={laguangeElakay}
                          infoSource ={infoSource}
                            product={product}
                            classCard0 = "col p-0"
                            showShopV={false}
                            promoMaker={promoMaker}
                            btnShopPromo={btnShopPromo}
                            refShopName={refShopName}
                        />
                    </div>
                ))}

        </Row>
    )
    const resultPagination = (
        <div className="pl-2" style={{width: "100%", backgroundColor: '#DCDCDC',  borderRadius: "7px",}}>
            {/* <span>lakay: {ShopName} </span> */}
            <h5>
            {prodCat} {' '}
            {(skip - limit) + limit + 1} - {((skip + limit) > siseProduct) ? siseProduct : (skip + limit)}
             {/* of  */}
             {' '}{infoSource.language(infoSource, "label", "of")}{' '}
             {siseProduct}{' '}
            {infoSource.laguangeElakay('label', infoSource.languageAPI).items}
        </h5>
        </div>
    )
    let myStyles = {
        width: "100%",
        borderRadius: "18px",
        // backgroundColor: '#f0f0f0'
    }
const productAllAndCatProduct = () =>{
    return <div  ref={divCatProduct} style={myStyles}>  

            {resultPagination} 
            {productALL}
            < ButtonPagination
                infoSource ={infoSource}
                size={size}
                skip={skip}
                limit={limit}
                siseProduct={siseProduct}
                loadMore={loadMore}
                loadMore0={loadMore0}
            />
        </div>
}
const laodingCI = (ci, n= 'All Products')=>{
    setSearch('')
    setCi(ci) 
    setProdcat(n)
    scrollToParagraph(divCatProduct)
}


const handleShow = () => {
    setEditStore(true);

    // Fermer le modal après 3 secondes
    setTimeout(() => {
        setEditStore(false);
    }, 3000);
  };

const PromoUsPr = ()=>{
    return <Modal show={editStore} onHide={() => setEditStore(false)} centered>
    <Modal.Body className="text-center">
    <h1 className="text-danger">🚀 {infoSource.laguangeElakay('title',  infoSource.languageAPI).comingSoon}</h1>
    </Modal.Body>
</Modal>

}

    return (
    <>
{outOffPage && <>  <HeaderShopSeller
        infoSource={infoSource}
        userID={userID}
      setFilterShop={setFilterShop}
      onFilterShop={onFilterShop}
      shopName={getOwner ?getOwner.shopName &&  Array.isArray(getOwner.shopName) ? getOwner.shopName[0]: getOwner.shopName: 'seller'}
      loadindSearCh={loadindSearCh}
      search={search}
      outOffPage={outOffPage}
      showFilter={showFilter}
      myShop={myShop}
      setEditStore={handleShow}
      editStore={editStore}
     /></>}
     
     <div style={infoSource.styleNav}>
             <SliderPun135
                            // categoriesID={categoriesID}
                            // SliderPunShow={false}
                            infoSource ={infoSource}
                            getpub={getUserPub.sliderShop}
            />
           
            <CategoryPub
            nbrArr={nbrArr}
            userID={userID}
            infoSource ={infoSource}
            pubCategoryProd ={processData(getCategoriesUser)}
            setCi={laodingCI}
            setSearch={setSearch}
            inView = {true}
            />    
          
            <CategoryPub
            Follow ={true}
            booFollow={booFollow}
            unFollow={unFollow}
            unFollowShop={unFollowShop}
            findToFollowU={findToFollowU}
            setSearch={setSearch}
            setCi={laodingCI}
            inView = {true}
            ci={getCi}
            nbrArr={nbrArr}
            userID={userID}
            infoSource ={infoSource}
            pubCategoryProd ={pubCategoryProd0}
            />
        { productAllAndCatProduct()}
        {PromoUsPr()}
        </div>
         </>
    
    );
};

export default ShopUserView;