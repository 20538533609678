import { elakay_API } from "../../config";

export const signup = (user,  pubToken )=> {
    return fetch(`${elakay_API}/signup`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
             UserPUBToken : `Bearer ${pubToken}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const signupUPdateRole = (user,  pubToken )=> {
    return fetch(`${elakay_API}/signupUPdateRole`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
             UserPUBToken : `Bearer ${pubToken}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const userActivation = user => {
    return fetch(`${elakay_API}/user/activation`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const activatorUserAdding = (token, key, value, save) => {
    return fetch(`${elakay_API}/user/activatorUserAdding`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({[key]: value, save })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const verificationCode = user => {
    return fetch(`${elakay_API}/user/verificationCode`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const reSendCodeSecurity = user => {
    return fetch(`${elakay_API}/user/reSendCodeSecurity`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`
        },
        body: JSON.stringify(user.user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const signin = user => {
    return fetch(`${elakay_API}/signin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const authenticate = (data, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("elakayUser", JSON.stringify(data));
        next();
    }
};


export const SetElakayLang = (name, value) => {
    let langue
    
    if(value == 0){
        langue = 'englesh'
    }else if(value == 1){
        langue = 'frensh'
    }else if(value == 2){
        langue = 'creol'
    }else if(value == 3){
        langue = 'Spanish'
    }


    if (typeof window !== "undefined") {
        localStorage.setItem("elakayLanguage", JSON.stringify({id: value, obj : name}));
        window.location.reload()
    }
};

export const getElakayLang = () => {
    if (typeof window === "undefined") {
        return false;
    }
    if (localStorage.getItem("elakayLanguage")) {
        return JSON.parse(localStorage.getItem("elakayLanguage"));
    } else {
        return false;
    }
};

export const isAuthenticated = () => {
    if (typeof window === "undefined") {
        return false;
    }
    if (localStorage.getItem("elakayUser")) {
        return JSON.parse(localStorage.getItem("elakayUser"));
    } else {
        return false;
    }
};

export const signout = (token) => {
    if (typeof window !== "undefined") {
        return fetch(`${elakay_API}/signout`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                localStorage.removeItem("elakayUser");
            })
            .catch(err => console.log(err));
    };

};

export const signOutALL = (token) => {

    return fetch(`${elakay_API}/signoutAll`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }

    }).then(response => {
        localStorage.removeItem("elakayUser");
        return response.json()
    })
        .catch(err => {
            console.log(err);
        });
};

export const readMe = token => {
    return fetch(`${elakay_API}/readMe`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`

        }

    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const CheckEmail = user => {
    return fetch(`${elakay_API}/user/CheckEmail`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const ChangePassword = (token, user) => {
    return fetch(`${elakay_API}/user/ChangePassword`, {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
             Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getUserViewed = (token) => {
    return fetch(`${elakay_API}/user/getviewed`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
// -----------------cart user -----------------------

export const addItem = (token, item) => {
  
    return fetch(`${elakay_API}/cart/addCart`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });

};

export const getCart = token => {
    return fetch(`${elakay_API}/cart/getCart`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });

};
export const updateItem = (token, item) => {

    return fetch(`${elakay_API}/cart/updateCart`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });

};

export const removeItem = (token, item) => {

    return fetch(`${elakay_API}/cart/deleteCart`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const emptyCart = token => {

    return fetch(`${elakay_API}/cart/emptyCart`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

// user address 
export const AddAddress = (token, address) => {
    return fetch(`${elakay_API}/user/addAddress`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(address)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const editAddressUser = (token, id, address) => {

    return fetch(`${elakay_API}/user/editAddressUser/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(address)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAddressUserById = (token, id) => {

    return fetch(`${elakay_API}/user/getAddressUserById/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const getAddressUserIfTrue = (token) => {

    return fetch(`${elakay_API}/user/getAddressUserIfTrue`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getBillAdressUserIfTrue = (token) => {

    return fetch(`${elakay_API}/user/getBillAdressUserIfTrue`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAddressById = (token, id) => {

    return fetch(`${elakay_API}/user/getAddressById/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const checkUserAddress = (token, id) => {
    return fetch(`${elakay_API}/user/CheckAddresssDefault/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteUserAddress = (token, id) => {
    return fetch(`${elakay_API}/user/deleteAddress/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));

}

// user telephonee 
export const AddTelephone = (token, telephone) => {
    return fetch(`${elakay_API}/user/telephone`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(telephone)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteUserTelephoneAss = (token, id) => {
    return fetch(`${elakay_API}/user/deletePhoneUserAss/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));

}

export const deleteUserTelephone = (token, id) => {
    return fetch(`${elakay_API}/user/deletePhoneUser/${id}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        })
        .catch(error => console.log(error));

}
export const checkUserTelephone = (token, id) => {
    return fetch(`${elakay_API}/user/checkTelephoneUserDefaul/${id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getStatusValues = (token) => {
    return fetch(`${elakay_API}/order/status-values`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const listOrders = (token, skip, limit, sortBy, fil) => {
    return fetch(`${elakay_API}/user/order/list?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}&status=${fil.orderStatus}&date=${fil.orderDate}&myd=${fil.myd}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}; 

export const OrdersViewById = (token, oid, pid) => {
    return fetch(`${elakay_API}/user/orderViewId/${oid}/${pid}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const OrdersBViewById = (token, oid, pid) => {
    return fetch(`${elakay_API}/user/orderBViewId/${oid}/${pid}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateOrder = (token, getID, order) => {

    console.warn({getID})
    return fetch(`${elakay_API}/order/update/${getID.oid}/${getID.opid}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(order)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const sellerlistOrdersStatus = (token, skip, limit, sortBy, fil) => {
    return fetch(`${elakay_API}/seller/order/list?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}&status=${fil.orderStatus}&date=${fil.orderDate}&myd=${fil.myd}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const sellerlistOrders = (token, status) => {
    return fetch(`${elakay_API}/seller/list/ByOrder/${status}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}; 

export const orderViews = (token, id, status) => {
    return fetch(`${elakay_API}/seller/orderViews/${id}/${status}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const UpdateUserRoleByAdmin = (token, userInfo, role, myInfo, telPhone, emailExit, emailvsTel ) => {

    return fetch(`${elakay_API}/user/UpdateUserRoleByAdmin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ role, userInfo,  myInfo, telPhone, emailExit, emailvsTel})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 


export const UserStepUP = (token, role) => {

    return fetch(`${elakay_API}/user/step-up`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(role)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

export const UserAgree = (token) => {

    return fetch(`${elakay_API}/user/user_agree`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 

export const userAssUpRoleTrueRandom = (token) => {

    return fetch(`${elakay_API}/user/userAssUpRoleTrueRandom`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        }).catch(err => {
            console.log(err);
    });
}; 

export const UserByMail = (token, email, telPhone, emailvsTel, addme) => {

    return fetch(`${elakay_API}/user/UserByMail?addme=${addme}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email, telPhone, emailvsTel})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });

}; 

export const getElakayIncomme = (token, skip, limit, sortBy, fil) => {
    return fetch(`${elakay_API}/elakayWalletIncomme?sortBy=${sortBy}&skip=${skip}&order=desc&limit=${limit}&status=${fil.orderStatus}&date=${fil.orderDate}&myd=${fil.myd}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
    }).catch(err => console.log(err));
};

export const getOrderWalletSeller = (token, status, payment) => {
    return fetch(`${elakay_API}/seller/orderWalletSeller?status=${status}&payment=${payment}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
    }).catch(err => console.log(err));
};

export const getOrderWalletAdmin = (token, status, payment) => {
    return fetch(`${elakay_API}/seller/oorderWalletAdmin?status=${status}&payment=${payment}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const addPubCategories = (token, categoryID) => {

    return fetch(`${elakay_API}/user/addPubCategories/${categoryID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
       
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const addFollowUserShop = (token, userID) => {

    return fetch(`${elakay_API}/user/addFollowUserShop/${userID}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
       
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const Add_ImgPub = (token, w, h, n, photo, catID, info) => {
    const body = new FormData()
    body.append('photo', photo)
    return fetch(`${elakay_API}/addUserImgPud?w=${w}&h=${h}&n=${n}&ci=${catID}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            //'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        },
       
        body: body
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const deleteImgPub = (token, n, urlImg) => {
   
    return fetch(`${elakay_API}/deleteImgPub?n=${n}&ui=${urlImg}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}

export const userPub = (id) => {

    return fetch(`${elakay_API}/user_pub/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const getAddressCountries = (code, zipcode) => {

    return fetch(`${elakay_API}/user/countries?code=${code}&zipcode=${zipcode}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const creatUserPUBToken = (pubToken, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("UserPUBToken", JSON.stringify({pubToken}));
        next();
    }
};

export const getUserPUBToken = (next) => {
    if (typeof window === "undefined") {
        return false;
    }

    if (localStorage.getItem("UserPUBToken")) {
        return JSON.parse(localStorage.getItem("UserPUBToken"));
    } else {
        return false;
    }
    next()
};

export const creatTokenPUB = (token, title, date, user_id, prod_id) => {
    return fetch(`${elakay_API}/user/creatTokenPUB?data=${date}&user_id=${user_id}&prod_id=${prod_id}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({title})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const AllMyUserReF = (token, getFilterOurTeam) => {
    return fetch(`${elakay_API}/user/AllMyUserReF?myUs=${getFilterOurTeam.myUser}&rn=${getFilterOurTeam.roleName}&asc=${getFilterOurTeam.asc}&limit=${getFilterOurTeam.limit}&skip=${getFilterOurTeam.skip}&grByR=${getFilterOurTeam.grpByrole}&UsId=${getFilterOurTeam.userID}&sortBy=${getFilterOurTeam.sortBy}&search=${getFilterOurTeam.search}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        }); 
};