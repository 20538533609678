import React, { useState} from "react";
import { Link, useParams} from "react-router-dom";
import {FaRegTrashAlt} from "react-icons/fa";
import CheckboxFilter from "./CheckBoxFilter0";
import CheckboxFilterValue from "./CheckBoxFilterValue0";
import {linkFTP, UnlinkFTP, linkVFTP, UnlinkVFTP, AddFormFilProView, TextFilProView, AddFilImg, DeletFilImg, FormInputFilProView} from "../auth/apiProduct";
// import {  laguangeElakay  } from "../pageInside/dataElakay";
import PrevviewProFilt from "./prevviewProFilt";
import RadioImg from "./html/FormRadioImg";

 let  imageTextDiv = []

const AddFilterProduct = props => {
    let params = useParams() 
    const {
        laguangeElakay,
        infoSource
        } = props

    const [seletName, setSeletName] = useState('');
    // const [imageTextDiv, setImageTextDiv] = useState([]);
    const [pageFilVal, setPageFilVal] = useState(false);
    const [pageFilNa, setPageFilNa] = useState(true);
    const [getALLFilterPro, setALLFilterPro]= useState([])
    const [getOneFilterPro, setOneFilterPro]= useState([])
    const [getNewAP, setNewAP]= useState([])
     const [images, setImages] = useState("");
    const [textFil, setTextFil] = useState("");

   

const {
    ImagesProd =[],
    filterSel,
    filters,
    token,
    catID,
    loadProductsView,
    filtersProduct=[],
    items = [],
} = props

    const handleToggle = (catID, filID, token) => () => {
      
        linkFTP(catID, filID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
             if (data.Success){
               loadProductsView(catID)
                }
                checkSel(filID)
            }
        });    
    };


    const removeFilter = (catID, filID, token) => {
        UnlinkFTP(catID, filID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadProductsView(catID)
                // setDeleteAgree(false)
                // setChek(false)
                checkSel(filID)
            }
        });

    };

    const handleToggle0 = (catID, filID, name, token) => () => {
        linkVFTP(catID, filID, name, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
             if (data.Success){
               loadProductsView(catID, filID)
                }else{
                    SubmitB(name)
                } 
                checkSel(filID)
            }
        });    
    };


    const removeFilter0 = (catID, filID, name, token) => {
        UnlinkVFTP(catID, filID, name,  token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadProductsView(catID, filID)
                // setDeleteAgree(false)
                // setChek(false)
                checkSel(filID)
            }
        });

    };

    const SubmitB = (name) => {
        setSeletName(name)
        // setDeleteAgree(true)

    }

    const  handleFormSelectDIV = (catID, filID, formInput,  token) => {

        FormInputFilProView(catID, filID, formInput,  token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadProductsView(catID, filID)
                checkSel(filID)
            }
        })
    }

    const  handleTextFilProView = (catID, filID, textRead,  token) => {

        TextFilProView(catID, filID, textRead,  token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadProductsView(catID, filID)
                checkSel(filID)
            }
        })
    }

    const  handFormFilProView = () => event =>  {
            let filID = items._id._id
            let formValueFil = event.target.value
            imageTextDiv = []
            formValueFil &&
        AddFormFilProView(catID, filID, formValueFil,  token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadProductsView(catID, filID)
                checkSel(filID)
            }
        })
    }

    const  handFilterImage = () =>  {
        let filID = items._id._id

        AddFilImg(catID, filID, imageTextDiv,  token).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
            loadProductsView(catID, filID)
            checkSel(filID)
        }
    })
}

const  DelFilterImage = () =>  {
    let filID = items._id._id

    DeletFilImg(catID, filID, imageTextDiv,  token).then(data => {
    if (data.error) {
        console.log(data.error);
    } else {
        loadProductsView(catID, filID)
        checkSel(filID)
    }
})
}

    // const SelValfil =(a)=>{

    //     filterSel && filterSel.filter(Item => {
    //       if (Item._id._id === a){
    //         setItems(Item)
    //       }
    //     })

    // }

    const pageChange = (a)=>{
        loadProductsView(catID, a)
        setPageFilVal(true)
        setPageFilNa(false)

    }

    const pageChangeExit = (a)=>{
        // loadProductsView(catID, a)
        setPageFilVal(false)
        setPageFilNa(true)

    }

    const checkSel = (a) => {

        let c

        filterSel.forEach(Item => {
            if (a === Item._id._id) {
                c = true
            }
        })
        return c
    }

    const checkSel0 = (a) => {

        let c

        items && items.FilterItem.forEach(Item => {
            if (a === Item) {
                c = true
            }
        })
        return c
    }
   
    const FilterName = () =>(
     
       pageFilNa && filters && filters.length > 0 &&  <div className="row mt-3 ">
     <div className="col col-12 col-md-6 col-lg-6">
                <CheckboxFilter
                    handleToggle={handleToggle}
                    filters={filters}
                    filterSel={filterSel}
                    catID={catID}
                    token={token}
                    checkSel={checkSel}
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                />
            
       </div>
          <div className="col col-12 col-md-6 col-lg-6">
          
          <div className="boxCategory1" id="heading">
              <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).FilprodView}</h4>
                {/* {showSuccess()}
                {showError()} */}
          </div>
        <div className="boxCategory">
                  {filterSel && filterSel.map((c, i)=> (
          <form  key={i} className="container" >
          <div className="itemCategory row">
          <div className="col col-lg-7" onClick={() => pageChange(c._id._id)}>   
         {/* <Link to={'/filter/'+categories._id+'/'+c._id._id}> */}
        <h5>
            {c._id.name}
       </h5>
        {/* </Link> */}
         {/* its here  */}
            </div> 
            <div className="col col-lg-5">
                                  <span onClick={() => removeFilter(catID, c._id._id, token)} className="dellCategory"><FaRegTrashAlt /></span>
        </div>
      </div>
      <input className="inputCategory" type="hidden" name="listName" value=""></input>
                         
          </form>
      ))}
      </div>
  
        <div className="boxCategory">

        </div>
              
        </div> 
     
        </div>
  
    );

    const filformINputAll = (
        <div className="row mt-1 p-1">
        <div className="col col-12 col-md-6 col-lg-6">
                   <CheckboxFilterValue
                       handleToggle={handleToggle0} 
                       checkSel={checkSel0}
                       filter={items._id}
                       catID={catID}
                       token={token}   
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                   />
               
          </div>
          <div className="col col-12 col-md-5 col-lg-5">
          <div className="boxCategory1" id="heading">
          <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).FilValprodView}</h4>
          </div>
             
          <div className="boxCategory">
          {items.FilterItem && items.FilterItem.map((c, i)=> (
            <form key={i}   className="container" >
            <div className="itemCategory row">
            <div className="col col-lg-7">   
          <h5> {c} </h5>
              </div> 
          <div className="col col-lg-5">
               <span onClick={() => removeFilter0(catID, items._id._id, c, token)} className="dellCategory"><FaRegTrashAlt /></span>
          </div>
        </div>
       
                           
            </form>
       ))}
          </div></div></div>
    )
    const checkTextImg =(a ,b)=>{

        if(a == false && b == "Square"){
            return true
        }else if (a == false && b == "Circle"){
            return true
        } else {

            return false
        }

    }
    const addForminput = (
        <div className="row mt-1 p-1">
  
        <div className="col col-12 col-md-6 col-lg-6">
        <div className="row "> 
        <div className="col col-12 mb-4">
        <input
                onChange={() => handleFormSelectDIV(catID, items._id._id, false,  token)}
                // name="Condition"
                type="radio"
                className="form-check-input mr-2 ml-4"
                checked={!items.formInput ? 'checked' : ''}
                id="Images"
            />
            <label className="form-check-label" for="Images">{items.formValueFil == "Images" ? "With Border" : "Form Selected"}</label>

            <input
                onChange={() => handleFormSelectDIV(catID, items._id._id, true,  token)}
                // name="Condition"
                type="radio"
                className="form-check-input mr-2 ml-4"
                checked={items.formInput ? 'checked' : ''}
                id="Images01"
            />
            <label className="form-check-label" for="Images01">{items.formValueFil == "Images" ? "Without Border" : "Form Div"}</label>
        </div>
        <div className="col col-12 mb-4">
        <input
                onChange={() => handleTextFilProView(catID, items._id._id, true,  token)}
                // name="Condition"
                type="radio"
                className="form-check-input mr-2 ml-4"
                checked={items.textRead ? 'checked' : ''}
                id="Image02"
            />
            <label className="form-check-label" for="Image02">{checkTextImg(items.formInput, items.formValueFil)?"with Image":"with Text"}</label>

            <input
                onChange={() => handleTextFilProView(catID, items._id._id, false,  token)}
                // name="Condition"
                type="radio"
                className="form-check-input mr-2 ml-4"
                checked={!items.textRead ? 'checked' : ''}
                id="Image03"
            />
            <label className="form-check-label" for="Image03">{checkTextImg(items.formInput, items.formValueFil)?"without Image": "Without text"}</label>
        </div>
        </div>
        </div>       
    <div className="col col-12 col-md-5 col-lg-5">
       <select value={items.formValueFil} onChange={handFormFilProView()}className="form-control">
                        
                        <option >{infoSource.laguangeElakay('label', infoSource.languageAPI).allItems}</option> 
                        <option value="Square">
                            {/* Square */}
                            {infoSource.laguangeElakay('Button', infoSource.languageAPI).square}
                        </option>
                        <option value= "Circle">
                            {/* Circle */}
                            {infoSource.laguangeElakay('Button', infoSource.languageAPI).circle}
                        </option>
                        <option value="Images">
                            {/* Images */}
                            {infoSource.laguangeElakay('Button', infoSource.languageAPI).images}
                        </option>
                        {/* <option value="Color Circle">Color Circle</option>
                        <option value="Color Square">Color Square</option> */}
                        <option value= "None">
                            {/* None */}
                            {infoSource.laguangeElakay('Button', infoSource.languageAPI).none}
                        </option>
                    
        </select>
        </div></div>

    )

    const FilterValue = () =>(
     
        pageFilVal && <>
        <button type="button" className="btn btn-light btn-sm" onClick={pageChangeExit}>
            {/* close */}
            {infoSource.laguangeElakay('Button', infoSource.languageAPI).close}
            </button>
        {filformINputAll}
        {addForminput}
        
       {
    //    items.formValueFil != "Images" && 
       <div className="row mt-4 p-1">
        <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).FilValprodView}</h4>
        <div className="col col-12 col-md-10">
       { items._id && <PrevviewProFilt
       infoSource ={infoSource}
       allFilterPro={filtersProduct}
        filter ={[items]}
        imageTextDiv={imageTextDiv}
        setALLFilterPro={setALLFilterPro}
        setOneFilterPro={setOneFilterPro}
        setNewAP={setNewAP}
        setImages={setImages}
        setTextFil={setTextFil}
        del = {true}
        />}
        </div>
        </div>}
         </>  
     );
    

return (
    <div>
  <div className="row">
            <div className="col-md-10 offset-md-2">
               {FilterName()}
               {FilterValue()} 
            <div className="container">
          { items.formValueFil == "Images" && items._id &&  <RadioImg
           laguangeElakay={laguangeElakay}
           infoSource ={infoSource}
            ImagesProd={ImagesProd}
            items={items}
            handFilterImage={handFilterImage}
            DelFilterImage={DelFilterImage}
            imageTextDiv={imageTextDiv}
            images={images}
            setImages={setImages}
            textFil={textFil}
            setTextFil={setTextFil}

            />}
            </div>
         </div>
</div>
</div>
);

}; 

export default AddFilterProduct;