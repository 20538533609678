import React, { useState, useEffect, useRef } from "react";

/**
 * Fonction utilitaire qui mesure la largeur d'un texte avec une police donnée.
 *
 * @param {string} text - Le texte à mesurer.
 * @param {string} font - La définition de la police (exemple : "16px sans-serif").
 * @returns {number} La largeur du texte en pixels.
 */
function measureTextWidth(text, font) {
  // Réutilise un canvas pour de meilleures performances
  const canvas =
    measureTextWidth.canvas ||
    (measureTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

/**
 * Hook personnalisé qui ajuste la taille de police pour que le texte tienne dans le conteneur.
 *
 * @param {string} text - Le texte à afficher.
 * @param {number} defaultFontSize - La taille de police par défaut (en pixels).
 * @param {number} minFontSize - La taille minimale autorisée (en pixels).
 * @param {string} fontFamily - La famille de police (par défaut "sans-serif").
 * @returns {object} { fontSize, containerRef }
 */
function useAutoFontSize(
  text,
  defaultFontSize = 16,
  minFontSize = 10,
  fontFamily = "sans-serif"
) {
  const containerRef = useRef(null);
  const [fontSize, setFontSize] = useState(defaultFontSize);

  useEffect(() => {
    const adjustFontSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        let currentFontSize = defaultFontSize;
        let font = `${currentFontSize}px ${fontFamily}`;
        let textWidth = measureTextWidth(text, font);

        // Réduire la taille de police tant que le texte dépasse le conteneur
        while (textWidth > containerWidth && currentFontSize > minFontSize) {
          currentFontSize -= 1;
          font = `${currentFontSize}px ${fontFamily}`;
          textWidth = measureTextWidth(text, font);
        }
        setFontSize(currentFontSize);
      }
    };

    // Ajustement initial
    adjustFontSize();

    // Ré-ajustement lors du redimensionnement de la fenêtre
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, [text, defaultFontSize, minFontSize, fontFamily]);

  return { fontSize, containerRef };
}

/**
 * Composant CateryName qui affiche le nom d'une catégorie dans un conteneur
 * ajustant automatiquement la taille du texte pour qu'il ne dépasse pas la largeur.
 *
 * @param {object} props - Les propriétés du composant.
 * @param {object} props.items - Un objet contenant au moins la propriété `cateryName`.
 */
const CateryName = ({ items }) => {
  // Taille de police par défaut et minimale (en pixels)
  const defaultFontSize = 16;
  const minFontSize = 10;

  // Utilisation du hook pour obtenir la taille de police ajustée et la référence du conteneur
  const { fontSize, containerRef } = useAutoFontSize(
    items.cateryName,
    defaultFontSize,
    minFontSize,
    "sans-serif" // ou toute autre police souhaitée
  );

  return (
    <div
      ref={containerRef}
      className="ps-3"
      style={{
        width: "100%",
        backgroundColor: "#DCDCDC",
        fontSize: `${fontSize}px`,
        overflow: "hidden", // pour éviter le débordement du texte
        whiteSpace: "nowrap" // pour forcer le texte sur une seule ligne
      }}
    >
      {items.cateryName}
    </div>
  );
};

export default CateryName;
