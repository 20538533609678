import React from "react";
import { Link } from 'react-router-dom';
import Search from "../pageInside/search";
// import { FaBell } from "react-icons/fa";
import DrawerToggleButton from '../../components/SIdeDrawer/DrawerToggleButton';
import { isAuthenticated, SetElakayLang, getElakayLang} from "../auth";
import logoCart from './icon/cart.svg';
import logoElakay from './icon/LogoElakay.svg';
import { MdLanguage } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";
import { GrAscend } from "react-icons/gr";
import { laguangeElakay } from "../pageInside/dataElakay";
import {Seller} from "../pageInside/Rolle"
import './Header.css';

const Header = (props) => {
    const {
        infoSource,
        drawerClickHander,
        setActiveHeader,
        outOffPage = true
    } = props 

    const { id , obj} = getElakayLang();

    const allLanguage = [
        {"code":"en","name":"English","nativeName":"English"},
        {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
        {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
        {"code":"fr","name":"French","nativeName":"français, langue française"},
        {"code":"pt","name":"Portuguese","nativeName":"Português"}
    ]

    const handleChange = name => event => {
        const value = event.target.value;
        allLanguage.filter((l, i )=> {
            if(l.name == value){
               SetElakayLang(l, i)  
            } })
       
    };
        const logo=()=>(
        <div id="logo_mlakay">
            <Link to="/">
                <h1>
                <img  className="img-responsive" src={logoElakay} width="24" alt="logo elakay"/>
                 elakay
                 </h1>
            </Link>
        </div>
 )

 const SearchElakay =()=>(
     <div className="formElakaySearch">
         <Search 
         infoSource={infoSource}
         />
     </div>
 )
 const menuHeader =()=>(
     <div>
         <div className="elakay_departement">
             <DrawerToggleButton click={drawerClickHander} />
         </div>
         <div className="elakay_liste_header">

             <ul className="largenav pull-right1">
                 {!outOffPage && <li onClick={()=>setActiveHeader(2)}>
                              <GrUpdate />
                              </li>}
                 <li ><Link className="me-l-2" to="/deal-today">{infoSource.laguangeElakay('headNenu', infoSource.languageAPI).todayDeal}</Link></li> 
               {  Seller() ? 
                <>  
                    <li ><Link className="me-l-3" to="/myshop?sel=true">{infoSource.laguangeElakay('headNenu', infoSource.languageAPI).sell}</Link></li>
                    <li ><Link className="me-l-3" to="/myshop">{infoSource.laguangeElakay('headNenu', infoSource.languageAPI).myShop}</Link></li>
                </>
                :
                    <li ><Link to="/your-step"> { infoSource.laguangeElakay('menuList', infoSource.languageAPI).yourStep}</Link></li>
                }
                 {/* <li className=""><Link className="me-l-4" to="">Gift Card</Link></li> */}
                 <li className="me-l-5"><Link id="help_contact" to="/help-contact">{infoSource.laguangeElakay('headNenu', infoSource.languageAPI).helpContact}</Link></li>

                 {/* <Link className="me-l-6" to="/message">
                     <FaBell />
                 </Link> */}

             </ul>
         </div>
         {/* selected={id==1?'selected': ''} */}
         <div className="Langage_elakay">
        <MdLanguage/><select value={obj? obj.name: 'English'} style={{backgroundColor:'transparent', borderColor: 'transparent', color: 'white' }}  onChange={handleChange('elakayLanguage')} >
        {allLanguage.map((l ,i)=>{
           return <option key={i} value={l.name}>{l.code}</option>
           })
           }
            </select>
         </div>
         <div className="user_elakay">
             {!isAuthenticated() ? (<Link to="/signin">
             {/* {infoSource.laguangeElakay('title', infoSource.languageAPI).welcome} */}
             <br /><strong>
                {infoSource.laguangeElakay('Button', infoSource.languageAPI).signin} 
                {/* elakay */}
                </strong>
                </Link>) : (<Link to="/profile"> 
                {infoSource.laguangeElakay('title', infoSource.languageAPI).welcomeBack}
                <br /><strong>
                    {infoSource.userInfo.firstname}{' '}                    
                     {infoSource.userInfo.lastname} 
                {/* in elakay */}
                </strong></Link>)}
         </div>
         <div className="mlakay_shoppingcart">

             <div className="mlakay_shoppingcart_name ">
                 <Link to="/cart">
                     <span className="mb-1 pb-0">
                         <img className="" src={logoCart}  alt="logo cart"/>
                         <strong className="mb-1 pb-0">
                             <sub> {infoSource.laguangeElakay('title', infoSource.languageAPI).cart}</sub>
                         </strong>
                         <sup>
                             <span id="count" >{
                                 infoSource.getCartItems.length ? infoSource.getCartItems.length : 0
                             }</span>
                         </sup></span>
                 </Link>
             </div>
         </div>
     </div>
)

    return (
        <>
  <header className="header">
    <div className="navbar">
                {logo()}
                {SearchElakay()}
                 {menuHeader()}

</div>
  </header>
  </>
    )

};
export default Header;