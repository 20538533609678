// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.saleSim {
    border-collapse: collapse;
    width: 100%;
  }
  
  table.saleSim th, td {
    text-align: left;
    padding: 8px;
  }
  
  table.saleSim tr:nth-child(even) {
    background-color: #D6EEEE;
  }
  
  table.saleSim  td:hover {
    background-color: #DEB887;
  }
  `, "",{"version":3,"sources":["webpack://./src/includes/pageInside/salesSimulation.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["table.saleSim {\n    border-collapse: collapse;\n    width: 100%;\n  }\n  \n  table.saleSim th, td {\n    text-align: left;\n    padding: 8px;\n  }\n  \n  table.saleSim tr:nth-child(even) {\n    background-color: #D6EEEE;\n  }\n  \n  table.saleSim  td:hover {\n    background-color: #DEB887;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
