import React, { useState} from "react";
import imgLog3 from '../../imgs_steps/IMG_003.png';
import Modal from 'react-bootstrap/Modal';
import AllFilePage from './allFilePage'


const FormPageLang0 = (props) => {

    const { 
        laguangeElakay,
        infoSource,
        editAdd, 
        setEditAdd,
        editAddByJson= false,
        nameObj,
        paragra,
        setParagra,
        ObjetPage,
        edit_add,
        token,
        AlllanguageSelect,
        languageUsertra,
        languageTradictor,
        updateLanguageElakay0,
        deleteLanguageElakay0,
        updateLanguageFileUp,
        buttonAddLanguage
    }= props
    

const checkProdKeyExit = (ad)=>{
    let ac = ad[0]
    let ent = Object.keys(ac ? ac:{});
   return ent.every(([key, val]) => ad[key] != undefined) && true
    }

const checkObjEmpty = (a)=>{
    return JSON.stringify(a) === '{}'

}
   

    let objValue = {
            title:'',
            image:'',
            video:'',
            thumbnail:''
        }
    const [error, setError] = useState('')
    const [viewPage, setViewPage] = useState(true);
    const [deletePara, setDeletePara] = useState(false);
    const [checkdeletePara, setCheckDeletePara] = useState(false);
    const [imageVideo, setImageVideo] = useState('');
    const [fileElakay, setFileElakay] = useState(objValue);

const {
    title,
    video,
    thumbnail,
    image
} = fileElakay
    

    const checkProductTrue = (c)=>{
        let textfil  = "" 
        for (let fil in c){
            textfil  +=  c[fil] === '' && ''
     } 
     return textfil
        }
    
    const buttonAdd =()=>{
        updateLanguageElakay0(languageTradictor._id, token, {[nameObj]:{ _id: fileElakay._id, title: title}}, true).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setEditAdd(true)
                buttonAddFile()
                setImageVideo('')
                setFileElakay(ObjetPage)    
        }
        })
    }

    const buttonAddFile =()=>{
        let file = 'video' == imageVideo && video.file || 'image'  == imageVideo && image.file

        updateLanguageFileUp(languageTradictor._id, fileElakay._id, nameObj, token, file, imageVideo, true).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setEditAdd(true)
                setImageVideo('')
                setFileElakay(ObjetPage)    
        }
        })
    }


    const buttonDelPara =()=>{
        deleteLanguageElakay0(languageTradictor._id, fileElakay._id, nameObj, token, imageVideo, true).then(data => {
            if (data.error) {
               setError(data.error)
            } else {
                AlllanguageSelect()
                languageUsertra()
                setCheckDeletePara(false)
                setDeletePara(false)
                setEditAdd(true)
                setFileElakay(ObjetPage)  
        }
        })
    }


    const handleLanguage = () => event => {
        const value = event.target.value;
        setFileElakay(languageTradictor[nameObj][value])
        
    
    };

    const selectObAr =()=>{
        return <div className="form-group">
               <label className="text-muted">
                {/* select languages and compare */}
                {infoSource.language(infoSource, "label", "selLanguaComp")}
                </label>
               <select onChange={handleLanguage()} className="form-control">
               <option value={0} > select</option>
               {!checkObjEmpty(languageTradictor) && languageTradictor[nameObj].map((ob, j) =>{
                return <option value={j} >{ob.title}</option>
               })}
               </select>
           </div>
    
    }


  
    let clickEditAdd = ()=>{
        setEditAdd(editAdd? false : true)
        setImageVideo('')
         if(!edit_add && !checkProdKeyExit(languageTradictor[nameObj])){
            let newParagra = languageTradictor[nameObj][0]
            if(editAdd){
                setImageVideo(newParagra.VORI)
                 setFileElakay(newParagra)
            }else{
                 setFileElakay(ObjetPage)

            }
        }else{
            setError(editAdd? "they is no data to edit":"")
            setFileElakay(ObjetPage)
        }
          
 
     }

    const btnAddEdit =()=>{
    
    return<> 
        <h6> edit / add</h6>
    <label className="switch">
      <input 
      onChange={clickEditAdd} 
      type="checkbox" 
      checked={editAdd ? 'checked' : ''}
      />
      <span className="slider round"></span>
    </label>
    </>
    }

 
    const handleChange = name => event => {
        const value = event.target.value;  
            setFileElakay({...fileElakay, [name]: value});
    };

    const handleFileChange = name => event => {
        const files = event.target.files;  
        for (let file of files){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', ()=>{
            let fileobj ={
                file: file,
                name : file.name,
                type: file.type,
                size: file.size,
                src: reader.result
            }
            setFileElakay({ ...fileElakay, [name]: fileobj});
        })
    }
    };
    
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );
    
    const handleVideoimage =(value)=>{ 
        setImageVideo(value)
        setFileElakay({ ...fileElakay,  video:'', thumbnail: '', image: ''});
    }


    const VideoAndImages = () => {
        return !editAdd &&  <div  className="form-group">
            <br/>
            <input  
                className="form-check-input" 
                onChange={()=>handleVideoimage('video')} 
                checked={imageVideo === 'video' ? 'checked' : ''} 
                type="radio" 
                name="Image_video"
                id={"Image_video_AddVideo"}
                 />
           <label className="form-check-label" for={"Image_video_AddVideo"}>Add video</label>           
           <input  
                className="form-check-input"
                onChange={()=> handleVideoimage('image')} 
                checked={imageVideo === 'image' ? 'checked' : ''} 
                type="radio" 
                name="Image_video"
                id={"Image_video_AddImage"}
                />
           <label className="form-check-label" for={"Image_video_AddImage"}>Add image</label><br/>
          </div>
    }


    const CheckInputBool = (title)=>{
        let ti =  title !== ''
        return ti   
    }

    let paraALLBoo =  checkProductTrue(fileElakay) || !editAdd
const pageView =  !viewPage ? false : paraALLBoo


const deleteAgree = () => {
         
    return (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    show={deletePara}
                    onHide={() => setDeletePara(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h4 className="text-center">
                                {/* delete this items */}
                                {infoSource.language(infoSource, "title", "delThtems")}
                                </h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row justify-content-center m-4">
                        {<AllFilePage
        classAlert ={"alert alert-success"}
        paragra ={fileElakay}
        paragra0={languageTradictor[nameObj]}
        paraALLBoo ={pageView}
    /> }
                            <div className="list-unstyled">
                                <input
                                    onChange={() => setCheckDeletePara(checkdeletePara ? false : true)}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={checkdeletePara  ? 'checked' : ''}
                                    id="chButAgree"
                                />
                                <label className="form-check-label" for="chButAgree">
                                    {/* check the button if your are agree */}
                                    {infoSource.language(infoSource, "title", "chButAgree")}
                                    </label>
                            </div>
                          
                              {
                              viewPage && fileElakay._id != undefined && checkdeletePara ?
                               <button onClick={() => buttonDelPara()} className="btn btn-primary float-right">Delete this param </button>
                               :
                               <button className="btn btn-primary float-right" disabled>Delete this param </button>
                            }
                      
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>

    )
}
const btnAdd_Edit = ()=>{
    if(!editAdd){
        return {
            f : function allBtn(){
                buttonAddFile()
                buttonAdd()
            },
            b : !checkObjEmpty(languageTradictor) && image &&  image.name !== '' || video && video.name !==''
            }
    }else{
        return {
            f : buttonAdd,
            b : CheckInputBool(title)
        }

    }
}

   const formInput = ()=> {
   return <div onBlur={() => setError("")}>
{
   !viewPage ?
   <button onClick={() => setViewPage(true)} className="btn btn-primary">
    {/* View Input */}
    {infoSource.laguangeElakay('title', infoSource.languageAPI).viewInput}
    </button>
   :
    <button onClick={() => setViewPage(false)} className="btn btn-primary">
        {/* View Page */}
        {infoSource.laguangeElakay('title', infoSource.languageAPI).viewPage}
        </button>
}

{<AllFilePage
        classAlert ={"alert alert-success"}
        paragra ={fileElakay}
        paragra0={languageTradictor[nameObj]}
        paraALLBoo ={pageView}
    /> }
   
  { viewPage && <h2>Form to edit / add menu Head</h2>}
    {viewPage && btnAddEdit()}
    {viewPage && showError()} 
    {viewPage && fileElakay._id != undefined &&  <button onClick={() => setDeletePara(true)} className="btn btn-primary float-right">Delete this param </button>}
    {!editAdd && viewPage && selectObAr()}
   
    {/* <form className="mb-3"> */}
 {viewPage && <> 
   <div className="form-group">
         <label className="valuetext-muted">Title</label>
         <input onChange={handleChange('title')} type="text" className="form-control" placeholder={'enter your the title'} value={title} />
    </div>
    </>}
  
   {<>
   {VideoAndImages()}
    {imageVideo == 'video' && !editAdd && <><div className="form-group">
         <label className="text-muted mr-3">Add a video </label>
         <input onChange={handleFileChange('video')} type="file" accept="video/mp4,video/*" name="video"/>
    </div>
    <div className="form-group">
    <label className="text-muted mr-3">add a thumbnail for the video </label>
    <input onChange={handleFileChange('thumbnail')} type="file" name="thumbnail" accept="image/*"/>
    </div>
    </>} 
    {imageVideo == 'image' &&  !editAdd &&   <div className="form-group">
         <label className="text-muted mr-3">add a images </label>
        <input  onChange={handleFileChange('image')} type="file" name="image" accept="image/*" />
    </div>}
    </>}

   { viewPage &&  buttonAddLanguage(btnAdd_Edit().f , btnAdd_Edit().b)

    }
    </div> 
    }

    return (<>
        {formInput()}
        {deleteAgree()}
        </>)

}

export default  FormPageLang0 