// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItems{
    min-height: 30px;
    margin: 0px auto;
    display: flex;
    border-bottom:  1px solid rgb(108, 183, 245);
}

.listItems input{
    padding: 20px;
    background-color: rgb(231, 25, 25);
}
.Cacol {
    height: 50px;
    background: #fff;
    /* border-bottom: 0.9px solid rgb(178, 219, 252); */

}`, "",{"version":3,"sources":["webpack://./src/includes/pages/LanguagePage/CheckLngtofinish.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,mDAAmD;;AAEvD","sourcesContent":[".listItems{\n    min-height: 30px;\n    margin: 0px auto;\n    display: flex;\n    border-bottom:  1px solid rgb(108, 183, 245);\n}\n\n.listItems input{\n    padding: 20px;\n    background-color: rgb(231, 25, 25);\n}\n.Cacol {\n    height: 50px;\n    background: #fff;\n    /* border-bottom: 0.9px solid rgb(178, 219, 252); */\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
