import React from "react";
import { FaUser,  FaStore, FaUsersBetweenLines} from "react-icons/fa6";
import { AiFillProduct } from "react-icons/ai";
import {Seller, Provider,  Assoociate } from "../pageInside/Rolle"

const CardUSer = (props) => {

    const {
        fullName,
        email,
        roleName,
        nbrUser = 0,
        getFilterOurTeam,
        setFilterOurTeam,
        User_id,
        loadAllMyUserRef,
        setFullName,
        setRoleName,
        setPageTeam,
        setPageProd,
        setPageShop,
        infoSource
    }= props

    const styles = {
        container: {
          position: "relative",
          width: "100%",
          height: "150px",
          // margin: "10px"
        },
        circle: {
          width: "150px",
          height: "150px",
          borderRadius: "50%", // Cercle parfait
          backgroundColor: "#F8F8FF",
          position: "absolute",
          zIndex: 2,
          top: "0",
          left: "0",
          border: "3px solid #A9A9A9", // Bordure verte
          boxShadow: `
            0 0 0 2px #90EE90,
            -3px 0 8px rgba(0, 0, 0, 0.5)
          `,
        },
        rectangle: {
          width: "350px",
          height: "150px",
          backgroundColor: "#F8F8FF",
          position: "absolute",
          zIndex: 1,
          left: "75px", // Décalage à droite du cercle
          top: "0",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // textAlign: "center",
          padding: "10px",
          // margin: "10px",
          color: "#000000",
          fontSize: "14px",
          fontFamily: "Arial, sans-serif",
        },
        text: {
          color: "#000000",
          // marginLeft: "75px",
          fontSize: "14px",
          lineHeight: "1.5",
        },  
        rectangle1: {
          // position: "absolute",
          width: "350px",
          height: "150px",
          margin: "1px",
          backgroundColor: "#FAEBD7",
          zIndex: 4,
          clipPath: `path("M 0 0 Q 75 75 0 150 L 350 150 L 350 0 Z")`,
          /* Custom path to create the arc */
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)'
        },
        float_bottom_right: {
          position: "absolute",
          bottom: "10px", /* Espace depuis le bas */
          right: "10px", /* Espace depuis la droite */
          backgroundColor: "#000000",
          padding: "5px 10px",
          color: "white",
          borderRadius: "5px",
          fontFamily: "Arial, sans-serif",
          fontSize: "14px",
        }

      };
      const styles2 = {
        container: {
            position: "absolute",
            top: "75px", /* Espace depuis le bas */
          left: "40px", /* Espace depuis la droite */
          display: "flex",
          gap: "10px",
          margin: "20px",
        },
        base: {
          cursor: "pointer",
          padding: "5px 8px",
          borderRadius: "5px",
          fontWeight: "bold",
          transition: "background-color 0.3s, color 0.3s",
        },
        active: {
          backgroundColor: "#F5F5F5", // Light red
          border: "4px solid #000000",
          color: "#d32f2f", // Darker red
        },
        unActive: {
          backgroundColor: "#DCDCDC", // Dark red
          border: "1px solid #708090",
          color: "#000000",
        },
      };
const btnNotOk = (a)=>{
    if(a==='customer' || a==='seller' || a==='provider'){
        return false 
    }
    return true
}
const btnNotOk2 = (a)=>{
    if(a==='customer'){
        return false 
    }
    return true
}
const btnOrtherUser = ()=>{
    setPageTeam(true)
    setPageProd(false)
    setPageShop(false)
    setFullName(fullName)
    setRoleName(roleName)
   setFilterOurTeam({...getFilterOurTeam, userID: User_id})
   getFilterOurTeam.userID = User_id
   loadAllMyUserRef(getFilterOurTeam)

}

const btnShopUser = ()=>{
    setPageTeam(false)
    setPageProd(false)
    setPageShop(true)
    setFullName(fullName)
    setRoleName(roleName)
    setFilterOurTeam({...getFilterOurTeam, userID: User_id})
}

      const btnCardUser = ()=>{
        return  <h5 className="m-4"
                style={{...styles2.container,}}
        >
             {btnNotOk(roleName) && <span   style={{
                  ...styles2.base,
                  ...styles2.unActive,
                //   ...(getFilterOurTeam.asc === "asc" ? styles2.active : {}),
                }}
                 onClick={btnOrtherUser}
                 >
                <FaUsersBetweenLines />
                </span>}

                {btnNotOk2(roleName) &&  <>
                 {/* <span
                  style={{
                    ...styles2.base,
                    ...styles2.unActive,
                    // ...(getFilterOurTeam.asc === "desc" ? styles2.active : {}),
                  }}
                //  onClick={()=>setFilterOurTeam({...getFilterOurTeam, asc: 'desc'})}
                 >
                <AiFillProduct />
                </span> */}
                <span
                  style={{
                    ...styles2.base,
                    ...styles2.unActive,
                    // ...(getFilterOurTeam.asc === "desc" ? styles2.active : {}),
                  }}
                  onClick={btnShopUser}
                 >
                <FaStore />
                </span></>}
             

                </h5>
      }

         return (<div> 
         <div style={styles.container}>
          <div style={styles.circle}>
             <span style={{ margin:"25px", fontSize: "89px", alignItems: "center", textAlign: "center", justifyContent: "center"}}> 
             <FaUser/>
              </span>
          </div>
          <div style={styles.rectangle}>
            <div style={styles.text}>
            <h3 style={{ marginLeft: "55px",}}>{fullName}</h3>
            <h6 style={{ marginLeft: "73px",}}>{email}</h6>
            
            <p style={{ marginLeft: "74px",}}> {infoSource.language(infoSource, "title", "nbrTeam")}: {nbrUser}</p>
            {btnCardUser()}
          <h5 style={styles.float_bottom_right}>{roleName}</h5>
        </div>
            </div>
          </div>
        </div>)
}

export default CardUSer;