import React, { useState } from "react";

const SearchBar = (props) => {
  const {
    infoSource, 
    setSowFilterOurTeam,
    setFilterUserProd,
    getFilterUserProd,
    infoFil,
    setText,
    handleFilters
  } = props
 const {
  search
 } = getFilterUserProd
 const [checked, setChecked] = useState([]);

 const handleToggle = (c, d, cat) => () => {
  let existingIndex;
  let updatedValues;

  // Find the index of the filter group by name (d)
  checked.forEach((filter, index) => {
    if (filter.name === d) {
      existingIndex = index;
    }
  });

  // Clone the checked array to avoid mutating state directly
  const newChecked = [...checked];

  if (existingIndex !== undefined) {
    // Check if the value (c) exists in the selected filter group
    const valueIndex = newChecked[existingIndex].value.indexOf(c);
    if (valueIndex === -1) {
      // Add value to the filter group if not present
      newChecked[existingIndex].value.push(c);
    } else {
      // Remove value if it already exists
      newChecked[existingIndex].value.splice(valueIndex, 1);
    }
    updatedValues = newChecked[existingIndex].value;
  } else {
    // If the filter group doesn't exist, add it
    newChecked.push({ name: d, value: [c] });
    updatedValues = newChecked[newChecked.length - 1].value;
  }

  // Update state and trigger external function
  setChecked(newChecked);
  handleFilters(updatedValues, `filterProduct.filter.${d}`);
};

  // Fonction appelée à chaque changement dans le champ de recherche
  const handleSearch = (e) => {
    const value = e.target.value;
 setFilterUserProd({...getFilterUserProd,  search: value})
  };

  return (
    <div style={styles.container}>
       <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>setSowFilterOurTeam(true)}>
                    {/* Filters */}
                    {infoSource.language(infoSource, "title", "filters")} 
          </div>
      <input
        type="text"
        placeholder="Search by firstname, lastname, or email..."
        value={search}
        onChange={handleSearch}
        style={styles.input}
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  input: {
    width: "100%",
    maxWidth: "400px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "16px",
  },
};

export default SearchBar;
