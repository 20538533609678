import React, { useState } from "react";
import { Link } from "react-router-dom";

import {isAuthenticated, AddTelephone, deleteUserTelephone, checkUserTelephone } from "../auth";
import {MdRadioButtonChecked,  MdDeleteForever } from "react-icons/md";
import { FaPhone, FaCheckCircle, FaCheckSquare} from "react-icons/fa";
import { Row } from 'react-bootstrap';
import PhoneInput, { parsePhoneNumber, formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, getCountryCallingCode  } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-number-input/style.css'


const Message = (props) => {
    const {
        infoSource,
        handePage
            } = props

    const {
        phoneUser,
        readUser
    } = infoSource

    const [loading, setLoading] = useState(false);
    // const [phoneUser, setPhoneUser] = useState([]);
    const { token } = isAuthenticated(); 
    const [value, setValue] = useState()

    const DeleteTelephone = (id) => {
        deleteUserTelephone(token, id).then(response => {
            if (response.error) {
                console.log(response.error);
            } else {
                readUser(token)
            }
        });
    }

    const CheckDefaultTelephone = id => {
        checkUserTelephone(token, id).then(response => {
            if (response.error) {
                console.log(response.error);
            } else {
                readUser(token)
            }
        });
    }

    const clickSubmit = () => {
        const data = { "country": en[parsePhoneNumber(value).country], "abrCountry": parsePhoneNumber(value).country, "ariaCode": getCountryCallingCode(parsePhoneNumber(value).country) , "National": formatPhoneNumber(value), "International": formatPhoneNumberIntl(value)}
        AddTelephone(token, data).then(data => {
            if (data.error) {
                console(data.error)
            } else {
                setValue("")
                readUser(token)
            }
        });
    };

    const TelephoneButton =()=>(
        <div className="row justify-content-center">
        <div className="bg clearfix ">
            <Link onClick={()=>handePage()} className="btn btn-outline-info rounded-4 float-left m-4 pt-2 pb-2 pr-3 pl-3">
                <span style={{ fontSize: "18px", paddingRight: "6px" }}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).close}</span>
            </Link>

     {  value && isValidPhoneNumber(value) ?
        <button className="btn btn-outline-info float-right m-4 pt-2 pb-2 pr-3 pl-3 rounded-4" onClick={clickSubmit} >
                <span style={{ fontSize: "20px", paddingRight: "6px" }}><FaPhone /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addPhone}
        </button>
        :
            <button className="btn btn-outline-info disabled float-right m-4 pt-2 pb-2 pr-3 pl-3 rounded-4">
                <span style={{ fontSize: "20px", paddingRight: "6px" }}><FaPhone /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addPhone}
        </button>
        }
        </div>
        </div>
    )

    const deleteButtonTelephone = id => {
        return (

            <span onClick={() => DeleteTelephone(id)} className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <MdDeleteForever />
            </span>
        )
    }
    const checkButtonTelephone = (id, address) => (

        address ?
            <span className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <FaCheckCircle />
            </span>
            :
            <span onClick={() => CheckDefaultTelephone(id)} className="btn btn-info float-right mr-4" style={{ width: "30px", height: "30px", padding: 0, textAlign: "center", fontSize: "1.2rem", borderRadius: "50%" }}>
                <MdRadioButtonChecked />
            </span>
    )

    const buttonEditDelete = (id, addressDefault) => (

        <div className="row justify-content-center">
            <div className="bg clearfix ">
                {checkButtonTelephone(id, addressDefault)}
                {deleteButtonTelephone(id)}

            </div>
        </div>

    )

    const AddressUser = (
        phoneUser.length > 0 && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourPhoneNumber}</h4>
                </div>
                <div className="container-fluid">
                    <Row xs={2} sm={2} md={3} lg={4} xl={5}>
                        {
                            phoneUser.map((m, i) => (
                                <div key={i} style={{ minWidth: "310px" }}>
                                    <div  className="card m-2 p-2" style={{ minWidth: "300px" }} >
                                        <p>{m.country}{m.default && (<span className="float-right"><FaCheckSquare />{infoSource.laguangeElakay('label', infoSource.languageAPI).default}</span>)}</p>
                                        <p className="mx-auto"> {m.International}</p>
                                        {buttonEditDelete(m._id, m.default)}
                                    </div>
                                </div>
                            ))

                        }

                    </Row>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    ))


    const telephoneuser =()=>(
    <div>
          
            <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                placeholder={infoSource.laguangeElakay('label', infoSource.languageAPI).enterPhoneNumber}
                value={value}
                onChange={setValue}
                error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
         
        
    </div>
    )

    const countryTelephone =()=>(
       
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).addPhoneNumber}</h4>
                </div>
         <div className="m-4">
       {telephoneuser()}
       
       </div>
                {TelephoneButton()}
         </div>
        </div>
    )
 
    return (
          <>
            {countryTelephone()}
            {AddressUser}
           </>
    );
};

export default Message;