import { elakay_API } from "../../config";

export const sendMessageHelp = (token, Message) => {
   
    return fetch(`${elakay_API}/SendMessagHelp/send`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(Message)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const GetMessageHelp = (token, Message, SMStrue) => {
    
    return fetch(`${elakay_API}/getMessagHelp?Message=${Message}&SMStrue=${SMStrue}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
}; 