import React from "react";

// import { linkFTC } from "../auth/apiCategory";

// import "../pages/category.css"

const Checkbox = ({ 
    laguangeElakay,
    infoSource,
    handleToggle, 
    checkSel, 
    filter, 
    catID, 
    token 
}) => {


    const checkFilter = () => (

        filter && <>
            <div className="boxCategory1" id="heading">
                <h4> 
                    {/* elakay filter  */}
                    {infoSource.laguangeElakay('title', infoSource.languageAPI).filterelakay}  {filter && filter.name}</h4>
            </div>

            <div className="boxCategory">

                {filter.FilterItem && filter.FilterItem.map((c, i) => (
                  <form key={i} className="container" >
                            <div className="itemCategory row">

                                <div className="col col-lg-2">
                                    <input
                                        onChange={handleToggle(catID, filter._id, c, token)}
                                        value={c}
                                        checked={checkSel(c) ? 'checked' : ''}
                                        type="checkbox"
                                        className="form-check-input inputCategory"
                                        name="listName"
                                        id={filter._id + "_"+ c}
                                    />
                                    <label className="form-check-label"  for={filter._id + "_"+ c}></label>
                                </div>
                                <div className="col col-lg-10">
                                    <h5>
                                        {c}
                                    </h5>
                                </div>
                            </div>
                            <input className="inputCategory" type="hidden" name="listName" value=""></input>
                        </form>
                ))}
            </div>
        </>

    )

    return (
        <>
            {checkFilter()}
        </>
    )
};

export default Checkbox;
