import React from "react";

// import { linkFTC } from "../auth/apiCategory";

// import "../pages/category.css"

const Checkbox = ({ 
    infoSource,
    handleToggle, 
    filters, 
    catID, 
    token, 
    checkSel
}) => {

    const checkFilter = ()=>(
            
            <>
           <div className="boxCategory1" id="heading">
            <h2>
                {/* Filter elakay */}
                {infoSource.laguangeElakay('title', infoSource.languageAPI).filterelakay}
                </h2>  
         </div>

        <div className="boxCategory">

            {filters.map((c, i) => (
                <form key={i} className="container" >
                <div className="itemCategory row">
              
                 <div className="col col-lg-2">
                                <input
                                    className="form-check-input inputCategory"
                                    onChange={handleToggle(catID, c._id, token, c.name)}
                                    value={c._id}
                                    checked={checkSel(c._id) ? "checked" : ''}
                                    type="checkbox"
                                    name="listName"
                                    id={c._id + "_"+ c.name}
                                />
                                <label className="form-check-label"  for={c._id + "_"+ c.name}></label>
                </div>     
                 <div className="col col-lg-10">  
                 
                <h5>
                                    {c.name}
                </h5>
                 </div> 
                </div>
                <input className="inputCategory" type="hidden" name="listName" value=""></input>
                </form>
             ))}
        </div>
        </>

    )

    return (
        <>
        { checkFilter()}
        </>
    )
};

export default Checkbox;
