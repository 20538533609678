import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {isAuthenticated} from '../auth';
import { Link, useParams } from "react-router-dom";
import {FaRegTrashAlt } from "react-icons/fa";
import CheckboxFilter from "../pageInside/CheckBoxFilterValue";
import { getCategoriesByID, readFilterValue, getFilterByID, linkVFTC, UnlinkVFTC} from "../auth/apiCategory";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import "./category.css"


const CreatCategory = props => {
    const {
        infoSource,
        laguangeElakay
            } = props
    let params = useParams()
    const [filter, setfilter] = useState([]);
    const [filterSel, setFilterSel] = useState([]);
    const [categories, setCategories] = useState([]);
    const [error] = useState(false);
    const [success] = useState(false);
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDeleteAgree, setDeleteAgree] = useState(false);
    const [chek, setChek] = useState(false);
    const [seletName, setSeletName] = useState('');
    const handl = (a) => {
        setChek(a)
    }

    // destructure user and token from localstorage
    const {token } = isAuthenticated();
    // const name = props.match.params.nv
    const filterID = params.filterId
    const catID = params.categoryId

    // creat button filter is true
    const filterButton = true

    // load categories and set form data
    const loadFiltersBYID = (catID, filterID) => {
        readFilterValue(catID, filterID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setFilterSel(data.filter[0].Filters)
               
            }
        });
    };

    const handleToggle = (catID, filID, name, token) => () => {
        // return the first index or -1
        linkVFTC(catID, filID, {name}, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else { 
                if (data.Success) {
                    loadFiltersBYID(catID, filID)
                } else {
                    SubmitB(name)
                }
                checkSel(filID)

            }
        });
    };

    const removeFilter = (catID, filID, name, token) => {
        UnlinkVFTC(catID, filID, {name}, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadFiltersBYID(catID, filID)
                setDeleteAgree(false)
                setChek(false)
                checkSel(filID)
                
            }
        });

    };

    const loadFilterSel = (catID, token) => {
        getCategoriesByID(catID).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCategories(data.cartegory)
                // setFilterSel(data.filter);
                setLoading(false)

            }
        });
    };


    const loadFilters = (filterID, token) => {
        getFilterByID(filterID, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setfilter(data)
            }
        });
    };
 
    useEffect(() => {
        loadFilters(filterID)
        loadFiltersBYID(catID, filterID)
        loadFilterSel(catID, token)
    }, [catID, filterID, token])

    const SubmitB = (name) => {
        setSeletName(name)
        setDeleteAgree(true)

    }
    
    const checkSel = (a) => {

        let c

        filterSel.forEach(Item => {
            if (a === Item) {
                c = true
            }
        })
        return c
    }

    const deleteAgree = (catID, filID, name,  token) => {

        return (
            <div className="contaire">
                <div className="row justify-content-center " >
                    <Modal
                        show={showDeleteAgree}
                        onHide={() => setDeleteAgree(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h4 className="text-center">
                                    {/* delete this items */}
                                    {infoSource.language(infoSource, "title", "delThtems")}
                                    </h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row justify-content-center m-4">
                                <h5>
                                    {/* are you sure you want to delete this category name's */}
                                    {infoSource.language(infoSource, "title", "surToDelete")}
                                     <span className="text-danger" > {name}</span> </h5>
                                <div className="list-unstyled">
                                    <input
                                        onChange={() => handl(chek ? false : true)}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={chek ? 'checked' : ''}
                                        id="surToDelete"
                                    />
                                    <label className="form-check-label" for="surToDelete">
                                        {/* check the button if your are agree */}
                                        {infoSource.language(infoSource, "title", "chButAgree")}
                                        </label>
                                </div>
                                {
                                    chek ?
                                        <Button onClick={() => removeFilter(catID, filID, name, token)}>
                                          {infoSource.language(infoSource, 'Button', "submit")}
                                        </Button> :
                                        <button className="btn" >{infoSource.language(infoSource, 'Button', "submit")}</button>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        )
    }

    const CategoryFom = () => (
        <div className="row mt-3">
            <div className="col col-12 col-md-6 col-lg-6">
                <CheckboxFilter
                 infoSource ={infoSource}
                    handleToggle={handleToggle}
                    filter={filter}
                    filterSel={filterSel}
                    catID={catID}
                    token={token}
                    checkSel={checkSel}
                />

            </div>
            <div className="col col-12 col-md-6 col-lg-6">

                <div className="boxCategory1" id="heading">
                    <h2>{categories.name  && categories.name[0]}
                         {/* filter Value */}
                         {infoSource.language(infoSource, "Link", "filValue")}
                         </h2>
                    {showSuccess()}
                    {showError()}
                </div>

                <div className="boxCategory">
                    {filterSel.map((c, i) => (
                        <form key={i} className="container" >
                            <div className="itemCategory row">
                                <div className="col col-lg-7">
                                   
                                        <h5>
                                            {c}
                                        </h5>


                                </div>
                                <div className="col col-lg-5">
                                    <span onClick={() => SubmitB(c)} className="dellCategory"><FaRegTrashAlt /></span>
                                </div>
                            </div>
                           { (c === seletName) && (
                                deleteAgree(catID, filterID, c, token)
                            )}
                            <input className="inputCategory" type="hidden" name="listName" value=""></input>
                        </form>
                    ))}
                </div>

            </div>
        </div>

    );

    const createFilterProduct = (filterButton) => {
        return (
            filterButton && (
                <Link to={`/filter/${catID}`} className="btn btn-outline-primary mt-2 mb-2 float-right">
                    {/* View Elakay Filter */}
                    {infoSource.language(infoSource, "Link", "ViElakayFil")}
                </Link>
            ))
    }

    const createCategoryButton = () => {
        return (
            <Link to={`/category`} className="btn btn-outline-primary mt-2 mb-2">
                {/* View Elakay Category */}
                {infoSource.language(infoSource, "Button", "VieElaCat")}
            </Link>
        )
    }

    const showSuccess = () => {
        if (success) {
            return <div className="alert alert-info" style={{ display: message ? "" : "none" }}> {message} </div>;
        }
    };

    const showError = () => {
        if (error) {
            return <div className="alert alert-danger" style={{ display: error ? "" : "none" }}> {error} </div>;
        }
    };


    return (
        <Layout
            className="container blockEl"
            SliderPunShow={false}
            infoSource ={infoSource}
            loading={loading}
        >
             <div style={infoSource.styleNav}>
            <div className="row">
                <div className="col-md-8 offset-md-2  blockEl" onBlur={() => setMessage("")}>
                    {showError()}
                    {CategoryFom()}
                    <div className="container">
                        {createCategoryButton()}  
                        {createFilterProduct(filterButton)}
                    </div>
                </div>
            </div>
            </div>
        </Layout>
    );

};

export default CreatCategory;