import React, { useState, useEffect, useRef } from "react";
import { Link} from "react-router-dom";
import Layout from "../Layout/Layout";
import {getElakayLang, isAuthenticated  } from "../auth";
import { DataElakay } from "../pageInside/dataElakay";
import { AddTraductoTeam, updateLanguageElakay, updateLanguageElakay0, deleteLanguageElakay0, updateLanguageFileUp, allLanguageElakay, LanguageElakayById, LanguageElakayBySUID, LanguageElakayByTUID, LanguageElakayByLanguage} from "../auth/apiLanguage"
import FormPageLang from "./LanguagePage/formPageLang";
import FormPageLang0 from "./LanguagePage/formPageLang0";
import FormPageLang1 from "./LanguagePage/formPageLang1";
import FormPageErrorElakay from "./LanguagePage/formPageErrorElakay"
import FormPageDataMail from "./LanguagePage/formPageDataMail"
import FormPageTicket from "./LanguagePage/formPageTicket";
import CodeEdit from "./LanguagePage/codeEdit";
import CheckLngToFinish from "./LanguagePage/CheckLngtofinish";
import { laguangeElakay } from "../pageInside/dataElakay";
import { Family} from "../pageInside/Rolle" 
import AlertPage from "../pageInside/alertPage";
import "./AddLanguage.css" 


const seletedLanguageDirect = (a, b)=>{
    let t = a[b] === undefined || a[b] === null || a[b] === '' || a[b] == {} ||  a[b] == []
   return !t ? Object.values(a[b]) : []
}

 const allLanguage = [
    {"code":"en","name":"English","nativeName":"English"},
    {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
    {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
    {"code":"fr","name":"French","nativeName":"français, langue française"},
    {"code":"pt","name":"Portuguese","nativeName":"Português"}
]

const AddLanguage = (props) => {
    const {
      infoSource,
        } = props
        let elakayInfo = !infoSource.loading && infoSource.languageAPI.elakayInfo

    
    const { token } = isAuthenticated();

       const ObjetPage = {
        title:'',
        text:'',
        list:'',
        video:'',
        thumbnail:'',
        image: '',
        href: {},
        link: {}
    }

    const ObjetCoding = {
      jsonText:'{ "subject": "elakay email"}',
      subject:'',
      HTLMCSStext:'<div><h2>elakay is the best</h2></div>',
     
  }
  const [errorMessage, setErrorMessage] = useState(null);
    const [pageSelected, setPageSelected] = useState(Family()?'advertisingEmail':'errorMessage');
    const [loading, setLoading] = useState(false);
    const [compLanguage, setCompLanguage] = useState('');
    const[paragra, setParagra]= useState(ObjetPage)
    const[coding, setCoding]= useState(ObjetCoding)
    const [language, setLanguage]= useState({})
    const [languageTradictor, setLanguageTradictor] = useState({});
    const [Alllanguage, setALLLanguage] = useState([]);
    const [Tradlanguage, setTradLanguage] = useState([]);
    const [languageOne, setLanguageOne] = useState({});
    const [languageTwo, setLanguageTwo] = useState({});
    const [languageThree, setLanguageThree] = useState({});
    const [editAdd, setEditAdd] = useState(true);
    const [showCheckLang, setShowCheckLang] = useState(false);
    const [getDataEmail, setDataEmail] = useState([]);
    const [getDataMessErr, setDataMessErr] = useState([]);
     const [showAlert, setShowAlert] = useState(false);
     const divLoadingPage = useRef(null);
const [jsonInput, setJsonInput] = useState({});
    const ConvertToEmptyObj = (a)=>{
        let obj =  new Object()
        let array =  Object.keys(a)
        array.forEach(a => obj[a] = '')
       return obj
    }

    const checkProdKeyExit = (ad)=>{
        let ent 
        if(Array.isArray(ad)){
          let ac = ad[0]
          ent = Object.keys(ac ? ac:{});
          return ent.every(([key, val]) => ac[key] != undefined) && true
        } else{
           ent = Object.keys(ad ? ad:{});
           return ent.every(([key, val]) => ad[key] != undefined) && true
        }}

        const checkEditPro = (ad)=>{
          let ent = Object.keys(ad ? ad:[]);
          return  ent.length > 0 ? true : false 
      }

       const checkUndefName = (a, b)=>{
        let aa  = a && Object.keys(a)
        return  a && aa.includes(b)
       } 

    const [objet, setObejet] = useState(ConvertToEmptyObj(elakayInfo[pageSelected]));

    useEffect(() => {
        languageUsertra()
        AlllanguageSelect()    
    }, []);

    const scrollToParagraph = (ref) => {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    
    const handlelanguage = (a) => event => {
        const value = event.target.value;
        let all = allLanguage.filter(l => l.name == value)[0]
        LanguageElakayByLanguage(all, false).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                a(data)
            }
        });
        
    };

    const handleDatamail = (objName)=>{
      LanguageElakayByLanguage({"code":"en","name":"English","nativeName":"English"}, false).then(data => {
        if (data.error) {
            console.log(data.error);
        } else {
          setDataEmail(data["advertisingEmail"])
          setDataMessErr(data[objName])
        }
    });
    }

    const languageUsertra = ()=>{
        const {obj} = getElakayLang();
        LanguageElakayByTUID(token, obj.name, Family()? true: false).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setLanguageTradictor(data)
            }
        });
    }

    const languageSelect = (f, Id)=>{
        LanguageElakayById(Id).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
               f(data)
            }
        });
    }

    const AlllanguageSelect = ()=>{
        allLanguageElakay(true).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setALLLanguage(data)
            }
        });
    }

    const handleChange = (name, fonct, values) => event => {
        const value = event.target.value;
        fonct({ ...values, [name]: value});
    };
    
const clickPageseleted =(a)=>{
  setCompLanguage('')
    checkEditPro(languageTradictor[a]) ?  setEditAdd(false) : setEditAdd(true)
    setDataEmail()
    scrollToParagraph(divLoadingPage)
    setObejet(ConvertToEmptyObj(elakayInfo[a]))
    setParagra(ObjetPage)
    // setEditAdd(true)
    setPageSelected(a)
    languageUsertra()
    a =='errorMessage' && handleDatamail(a)
    a =='elakayMail' && handleDatamail(a)
}

const clickPageseleted1 =(a)=>{
  setPageSelected('')
  // languageUsertra()
}

const LanguageSeleted =(a)=>{
    return <div className="form-group" >
           <select onChange={handlelanguage(a)} className="form-control" >
           <option>
            {/* Choose a language */}
            {infoSource.language(infoSource, "Label", "ChoLang")}
            </option>
           {allLanguage.map((l ,i)=>{
           return <option key={i} value={l.name}>{l.name}</option>
           })
           }
           </select>
       </div>

}

const activeLink = (a) =>{
   return pageSelected === a? 'active': 'notActive'
}
let sidebarStyle = {
  'overflow': 'auto',
 'maxHeight' : "700px"
}

const linksData = infoSource.laguangeElakay('menuAddLang', infoSource.languageAPI)
const sideBarForm =()=>{

return <div className="sidebar" styles={{'height': '100%'}}>
     <div style={sidebarStyle}>
      {Object.entries(linksData).map(([key, value]) => (
        (Family() || !["elakayTicket", "advertisingEmail", "allElakayFile"].includes(key)) && (
          <Link key={key} className={activeLink(key)} onClick={() => clickPageseleted(key)}>
            {value}
          </Link>
        )
      ))}
    </div>
 </div>

}

  
const checkValueEx = (a, v)=> a.includes(v)
const checkUserLang =(a, b)=>{
  let ut = b && b.translator && b.translator.user
  let us = b && b.supervisor && b.supervisor.user
  let uA = b && b.Admin && b.Admin.user
  let uId = a.userInfo && a.userInfo._id
 let Fa =  Family(a.userInfo.email)
  if(uId == ut){
      return true
  }else if(uId == us){
      return true
  }else if(uId == uA){
      return true
  }
  else if(Fa){
      return true
  }
  else{
      return false
  }
}
const handleLanguage = () => event => {
    const value = event.target.value;
    setCompLanguage(value);
    pageSelected === "" && setPageSelected('headNenu');

};

const inputOK = (a, b, c)=>{
  if(c == undefined){
      return  {v : b,  s: 'form-control'}
  }else  if(c == {}){
    return {v :"",  s: 'form-control is-invalid'}
}else if(checkUndefName(c, a)){
    return {v : c[a],  s: "form-control is-valid" }
  }else{
    return {v :"",  s: 'form-control is-invalid'}
  }

}

const InputPage = (a ,b, i, c)=>{

    let vc = c == undefined ? b : checkUndefName(c, a) ? c[a] : ""
    let inpClass = c == undefined ? "form-control" : checkUndefName(c, a) ? "form-control is-valid" : 'form-control is-invalid'
    return b && <div key = {i}  className="form-group compareElLan">
     {b.length > 90 ?
    //  <textarea type="text" className={inpClass} value={vc} rows="4" readOnly/>
     <textarea type="text" className={ inputOK(a, b, c).s} value={inputOK(a, b, c).v} rows="4" readOnly/>
     :
    //  <input type="text" className={inpClass} value={vc} readOnly/>
     <input type="text" className={inputOK(a, b, c).s} value={inputOK(a, b, c).v} readOnly/>
     }
     </div>
 }
const copyToClipboard = (jsonData) => {
  const jsonStr = JSON.stringify(jsonData, null, 2);
  navigator.clipboard.writeText(jsonStr).then(() => {
    // alert('JSON copié dans le presse-papiers!');
    setShowAlert(true)
  }).catch((err) => {
    console.error('Erreur lors de la copie : ', err);
  });
};

 const InputPageArray1 =(a ,b, i)=>{
 
  return <div key = {i}  className="form-group compareElLan">
    {a === 'title' && <input type="text" className={`form-control`} value={b} readOnly/>}

    {a === 'text' && b.map((x, u)=> x !== '' && <p key={u}>{x} </p>)}

  {a === 'list' && <ol type="a">
     {b.map((a,i)=>  a !== '' &&<li key={i}> {a} </li>)}
        </ol> }
   </div>
}

 const InputPageArray0 = (a,  j)=>{
  let oe = Object.entries(a)
  return <div key = {j}>
{oe.map(([key, val], i) => { return InputPageArray1(key, val, i)})}
  
   </div>
}

 const checkLangDefault = (a, b, c)=>{
    if(a && checkProdKeyExit(a[c])){return b}else {return a}
 }

 const showError = (a) => (
  <div
      className="alert alert-danger"
  >
    {a}
  </div>
);

 const entriesLang =(a)=>{
  return  Object.entries(a[pageSelected]?a[pageSelected]:{})
 }
 const entriesLang0 =(a)=>{
  return  Object.entries(checkLangDefault(a, elakayInfo, pageSelected)[pageSelected]);
 }
const checkValue = (val)=>{
  return languageTradictor[pageSelected] != undefined ? languageTradictor[pageSelected] : val
}

const compare1Page = ()=>{
    return compLanguage ==="language1" && <div className="row mt-3">
            <div className="col col-12 col-md-6"> 
            
                   <h4>LNG-1</h4>
                   {
                    Array.isArray(checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang0(languageOne).map(([key, val], i) => { return InputPage(key, val, i)})
                   }
                   {LanguageSeleted(setLanguageOne)}
                   <button className="m-1  btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>copyToClipboard(checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected])}>{infoSource.language(infoSource, 'Button', "copyJson")}</button>
            </div>
            <div className="col col-12 col-md-6">
                <h4>
                  {/* Your tracdiction */}
                  {infoSource.language(infoSource, "title", "YoTracdic")}
                  </h4>
                {
                    Array.isArray(checkLangDefault(languageTradictor, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageTradictor, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang(elakayInfo).length > 0 ? entriesLang(elakayInfo).map(([key, val], i) => { return InputPage(key, val, i, checkValue({}))}) : showError('you do not have data translate for this title')
                   }
                   <button className="m-1  btn btn-outline-dark rounded-4 shadow-lg" onClick={()=>copyToClipboard(checkLangDefault(languageTradictor, elakayInfo, pageSelected)[pageSelected])}>{infoSource.language(infoSource, 'Button', "copyJson")}</button>
                {/* {entries1.map(([key, val], i) => { return InputPage(key, val, i)})} */}
                {/* {seletedLanguageDirect(languageTradictor, pageSelected).map(a=><p className="compareElLan">{a}</p>)} */}
            </div>
   
       </div>
   }

const compare2Page = ()=>{
 return compLanguage ==="language2" && <div className="row mt-3">
       <div className="col col-12 col-md-6">
                <h4>LNG-1</h4>
                {
                    Array.isArray(checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang0(languageOne).length > 0 ? entriesLang0(languageOne).map(([key, val], i) => { return InputPage(key, val, i)}) : showError('this title does not have data translate')
                   }
                   {LanguageSeleted(setLanguageOne)}
         </div>
         <div className="col col-12 col-md-6">
         <h4>LMG-2</h4>
         {
                    Array.isArray(checkLangDefault(languageTwo, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageTwo, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang0(languageTwo).length > 0 ? entriesLang0(languageTwo).map(([key, val], i) => { return InputPage(key, val, i)}) : showError('this title does not have data translate')
                   }
                   {LanguageSeleted(setLanguageTwo)}
        </div>

    </div>
}
const compare3Page = ()=>{
    return compLanguage ==="language3"   && <div className="row mt-3">
    <div className="col col-12 col-md-4">
           <h4>LMG-1</h4>
           {
                    Array.isArray(checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageOne, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang0(languageOne).length > 0 ? entriesLang0(languageOne).map(([key, val], i) => { return InputPage(key, val, i)}) : showError('this title does not have data translate')
                   }
                   {LanguageSeleted(setLanguageOne)}
    </div>
    <div className="col col-12 col-md-4">
    <h4>LMG-2</h4>
    {
                    Array.isArray(checkLangDefault(languageTwo, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageTwo, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang0(languageTwo).length > 0 ? entriesLang0(languageTwo).map(([key, val], i) => { return InputPage(key, val, i)}) : showError('this title does not have data translate')
                   }
                   {LanguageSeleted(setLanguageTwo)}
   </div>
   <div className="col col-12 col-md-4">
    <h4>LMG-3</h4>
    {
                    Array.isArray(checkLangDefault(languageThree, elakayInfo, pageSelected)[pageSelected])?

                    checkLangDefault(languageThree, elakayInfo, pageSelected)[pageSelected].map((oa,  j) => 
                    InputPageArray0(oa, j)
                    )
                    :
                    entriesLang0(languageThree).length > 0 ? entriesLang0(languageThree).map(([key, val], i) => { return InputPage(key, val, i)}) : showError('this title does not have data translate')
                   }
                   {LanguageSeleted(setLanguageThree)}
   </div>

</div>
    
}

const submitJsonInput =()=>{
  
  try {
    setObejet(JSON.parse(jsonInput))
    setLanguageTradictor({...languageTradictor, [pageSelected]: JSON.parse(jsonInput)})
  // setNewObejet(JSON.stringify(obj,  null, 3))
  setErrorMessage(null)
//    
  } catch (error) {
// Afficher l'erreur si le parsing échoue
setErrorMessage(`Erreur de syntaxe JSON : ${error.message}`);
}
}

const addByJson = ()=>{
  // let obj = checkLangDefault(languageTradictor, elakayInfo, pageSelected)[pageSelected]
  return compLanguage ==="addByJson" && <div className="row mt-3">
          <div className="col col-12 col-md-6"> 
          
                 <h4>
                  {infoSource.language(infoSource, "title", "copyTradJson")}
                  {/* copy & parse your Json traduction */}
                  </h4>

          </div>
       {errorMessage && <div  className=" alert alert-danger" style={{  display: errorMessage ? "" : "none",  color: 'red', marginTop: '10px' }}>
          
      {errorMessage}
    </div>}
          <textarea
        className='p-4'
        // value={jsonInput}
        // value={jsonInput== {}? JSON.stringify(jsonInput,  null, 3):""}
          // value={JSON.stringify(languageTradictor[pageSelected],  null, 3)}
          onChange={(e) => setJsonInput(e.target.value)}
          placeholder={JSON.stringify(checkLangDefault(languageTradictor, elakayInfo, pageSelected)[pageSelected],  null, 3)}
          style={{ width: '100%', minHeight: '270px', fontFamily: 'monospace', marginBottom: '20px' }}
        />

<div className="row justify-content-center">
            <div className="col col-sm-8 col-lg-5">
                <div className="bg clearfix ">
                <button className="m-1  btn btn-outline-dark pt-2 pb-2 pr-2 pl-2 rounded-4 float-left" onClick={()=>copyToClipboard(checkLangDefault(languageTradictor, elakayInfo, pageSelected)[pageSelected])}>{infoSource.language(infoSource, 'Button', "copyJson")}</button>
                <button className="m-1  btn btn-outline-dark pt-2 pb-2 pr-2 pl-2  rounded-4  float-right" onClick={()=>submitJsonInput()}>{infoSource.language(infoSource, 'Button', "submit")} json</button>
                </div>
            </div></div>
     </div>
 }

const buttonAddLanguage=(clickAdd, ok)=>{
  return  <div>
  { ok ? <button onClick={() => (clickAdd())} className="btn btn-primary">
    {/* submit */}
    {infoSource.language(infoSource, 'Button', "submit")}
    </button> 
  :
    <button className="btn btn-primary" disabled>
      {/* submit */}
      {infoSource.language(infoSource, 'Button', "submit")}
      </button>}
</div>}

const buttonFinal=(clickAdd, ok)=>{
    return  <div>
    { ok ? <button onClick={() => (clickAdd)} className="btn btn-primary float-right">
      {/* finishing */}
      {infoSource.language(infoSource, "Button", "finishing")}
      </button> :
      <button className="btn btn-primary float-right" disabled>
        {/* finishing */}
        {infoSource.language(infoSource, "Button", "finishing")}
        </button>}
  </div>}

const compareLanguages =()=>{
    return <div className="form-group">
           <label className="text-muted">
            {/* select languages and compare */}
            {infoSource.language(infoSource, "label", "selLanguaComp")}
            </label>
           <select onChange={handleLanguage()} className="form-control" value={compLanguage}>
           <option value=""> 
            {/* no languages selected & compare */}
            {infoSource.language(infoSource, "label", "nolangSelComp")}
            </option>

            <option value="addByJson"> 
            {/* no languages selected & compare */}
            {/* {infoSource.language(infoSource, "label", "nolangSelComp")} */}
            {/* add a traduction by Json */}
            {infoSource.language(infoSource, "title", "addTradJson")}
            </option>
           <option value={'language1'}>
            {/* select language & compare to your tracdiction */}
            {infoSource.language(infoSource, "label", "selLangcompTracd")}
            </option>
           <option value={'language2'}>
            {/* select 2 languages & compare  */}
            {infoSource.language(infoSource, "label", "sel2langComp")}
            </option>
           <option value={'language3'}>
            {/* select 3 languages & compare  */}
            {infoSource.language(infoSource, "label", "sel3langComp")}
            </option>
           </select>
       </div>

}

const checKL_L1  = languageTradictor[pageSelected] === undefined || languageTradictor[pageSelected] === null || languageTradictor[pageSelected] === '' || languageTradictor[pageSelected] == {} ||  languageTradictor[pageSelected] == []



const loadingFormPage = (o, so)=>{
 return   <FormPageLang
                laguangeElakay={laguangeElakay}
                infoSource ={infoSource}
                objet ={o}
                setObejet ={so}
                editAdd={editAdd}
                setEditAdd={setEditAdd}
                menu={ConvertToEmptyObj(elakayInfo[pageSelected])}
                nameObj ={pageSelected}
                infoElakay = {elakayInfo[pageSelected]}
                edit_add ={checKL_L1}
                AlllanguageSelect={AlllanguageSelect}
                languageUsertra={languageUsertra}
                token={token}
                languageTradictor={languageTradictor}
                updateLanguageElakay ={updateLanguageElakay}
                buttonAddLanguage ={buttonAddLanguage}
            />
}

const loadingFormPage0 = (o, so)=>{
    return   <FormPageLang0
                  laguangeElakay={laguangeElakay}
                  infoSource ={infoSource}
                   editAdd={editAdd}
                   editAddByJson={compLanguage ==="addByJson"}
                   paragra ={o}
                   setParagra={so}
                   ObjetPage ={ObjetPage}
                   setEditAdd={setEditAdd}
                   nameObj ={pageSelected}
                   edit_add ={checKL_L1}
                   AlllanguageSelect={AlllanguageSelect}
                   languageUsertra={languageUsertra}
                   token={token}
                   languageTradictor={languageTradictor}
                   updateLanguageElakay0 ={updateLanguageElakay0}
                   deleteLanguageElakay0 ={deleteLanguageElakay0}
                   updateLanguageFileUp={updateLanguageFileUp}
                   buttonAddLanguage ={buttonAddLanguage}
               />
   }

   const loadingFormPage1 = (o, so)=>{
    return <div> 
       <CodeEdit
                  laguangeElakay={laguangeElakay}
                  infoSource ={infoSource}
                   editAdd={editAdd}
                   paragra ={o}
                   setParagra={so}
                   ObjetPage ={ObjetPage}
                   setEditAdd={setEditAdd}
                   nameObj ={pageSelected}
                   edit_add ={checKL_L1}
                   AlllanguageSelect={AlllanguageSelect}
                   languageUsertra={languageUsertra}
                   token={token}
                   languageTradictor={languageTradictor}
                   updateLanguageElakay0 ={updateLanguageElakay0}
                   deleteLanguageElakay0 ={deleteLanguageElakay0}
               />
               </div>
   }

   const loadingFormPageEmail = (o, so)=>{
    return <div>  
              <FormPageDataMail  
                  laguangeElakay={laguangeElakay}
                  infoSource ={infoSource}
                  getDataEmail={getDataEmail}
                  getDataMessErr={getDataMessErr}
                   editAdd={editAdd}
                   paragra ={o}
                   setParagra={so}
                   ObjetPage ={ObjetPage}
                   editAddByJson={compLanguage ==="addByJson"}
                   setEditAdd={setEditAdd}
                   nameObj ={pageSelected}
                   edit_add ={checKL_L1}
                   AlllanguageSelect={AlllanguageSelect}
                   languageUsertra={languageUsertra}
                   token={token}
                   languageTradictor={languageTradictor}
                   updateLanguageElakay0 ={updateLanguageElakay0}
                   deleteLanguageElakay0 ={deleteLanguageElakay0}
               />
               </div>
   }

   const loadingFormPageError = (o, so)=>{
    return <div> 
               <FormPageErrorElakay
                 infoSource ={infoSource}
                 objet ={o}
                 setObejet ={so}
                 editAdd={editAdd}
                 setEditAdd={setEditAdd}
                 menu={ConvertToEmptyObj(elakayInfo[pageSelected])}
                 editAddByJson={compLanguage ==="addByJson"}
                 nameObj ={pageSelected}
                 infoElakay = {elakayInfo[pageSelected]}
                 edit_add ={checKL_L1}
                 getDataEmail={getDataEmail}
                 getDataMessErr={getDataMessErr}
                 AlllanguageSelect={AlllanguageSelect}
                 languageUsertra={languageUsertra}
                 token={token}
                 languageTradictor={languageTradictor}
                 updateLanguageElakay0 ={updateLanguageElakay0}
                 deleteLanguageElakay0 ={deleteLanguageElakay0}
                 buttonAddLanguage ={buttonAddLanguage}
               />
               </div>
   }

   const loadingFormPage2 = ()=>{
    return   <CheckLngToFinish
                laguangeElakay={laguangeElakay}
                  infoSource ={infoSource}
                  elakayInfo={elakayInfo}
                  showCheckLang ={showCheckLang}
                  editAddByJson={compLanguage ==="addByJson"}
                  setShowCheckLang ={setShowCheckLang}
                   AlllanguageSelect={AlllanguageSelect}
                   languageUsertra={languageUsertra}
                   token={token}
                   languageTradictor={languageTradictor}
                   updateLanguageElakay0 ={updateLanguageElakay0}
                   updateLanguageFileUp={updateLanguageFileUp}
                   buttonAddLanguage ={buttonAddLanguage}
                   activeLink={activeLink}
                   clickPageseleted={clickPageseleted}
               />
   }

   const loadingFilles = (o, so)=>{
    return   <FormPageLang1
                  laguangeElakay={laguangeElakay}
                  infoSource ={infoSource}
                   editAdd={editAdd}
                   paragra ={o}
                   setParagra={so}
                   ObjetPage ={ObjetPage}
                   editAddByJson={compLanguage ==="addByJson"}
                   setEditAdd={setEditAdd}
                   nameObj ={pageSelected}
                   edit_add ={checKL_L1}
                   AlllanguageSelect={AlllanguageSelect}
                   languageUsertra={languageUsertra}
                   token={token}
                   languageTradictor={languageTradictor}
                   updateLanguageElakay0 ={updateLanguageElakay0}
                   deleteLanguageElakay0 ={deleteLanguageElakay0}
                   updateLanguageFileUp={updateLanguageFileUp}
                   buttonAddLanguage ={buttonAddLanguage}
               />
   }
   const loadingTicket= (o, so)=>{
    return   <FormPageTicket
    laguangeElakay={laguangeElakay}
    editAddByJson={compLanguage ==="addByJson"}
    infoSource ={infoSource}
     editAdd={editAdd}
     paragra ={o}
     setParagra={so}
     ObjetPage ={ObjetPage}
     setEditAdd={setEditAdd}
     nameObj ={pageSelected}
     edit_add ={checKL_L1}
     AlllanguageSelect={AlllanguageSelect}
     languageUsertra={languageUsertra}
     token={token}
     languageTradictor={languageTradictor}
     updateLanguageElakay0 ={updateLanguageElakay0}
     deleteLanguageElakay0 ={deleteLanguageElakay0}
     updateLanguageFileUp={updateLanguageFileUp}
     buttonAddLanguage ={buttonAddLanguage}
               />
   }

const LoadingPage =(a)=>{
    if (a === 'headNenu') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'menuList') {
        return loadingFormPage(objet, setObejet)
      }  else if (a === 'menuSertting') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'filters') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'text') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'title') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'buy') {
       return loadingFormPage(objet, setObejet)
      } else if (a === 'sell') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'helpContact') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'Button') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'label') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'Link') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'menuAddLang') {
        return loadingFormPage(objet, setObejet)
      }else if (a === 'aboutUs') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'signinPage') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'sellPage') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'homePage') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'profilePage') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'walletPage') {
        return loadingFormPage(objet, setObejet)
      } else if (a === 'makeYourFstep') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'becomeRoyalty') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'becomeSeller') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'LinkcustomerShop') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'editProduct') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'admAssPage') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'becomeProvider') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'termConditProdSel') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'catalogProdSel') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'infoProdSel') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'filterProdSel') {
        return loadingFormPage0(paragra, setParagra)
      } else if (a === 'imageProdSel') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'planUserRoyal') { 
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'termConditionRoyal') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'royalAgree') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'planUserSeller') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'termConditionSeller') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'sellerAgree') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'planUserProvider') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'termConditionProvider') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'providerAgree') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'planAffaireElakay') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'termConditionAssociate') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'associateAgree') {
        return loadingFormPage0(paragra, setParagra)
      }else if (a === 'elakayMail' ) {
        return loadingFormPageEmail(paragra, setParagra)
      }else if (a === 'errorMessage') {
        return loadingFormPageError(paragra, setParagra)
      }else if (a === 'advertisingEmail') {
        return loadingFormPage1(coding, setCoding)
      }else if (a === 'checkLngToFinish') {
        return loadingFormPage2(paragra, setParagra)
      }else if (a === 'allElakayFile') {
        return loadingFilles(paragra, setParagra)
      }else if (a === 'elakayTicket') {
        return loadingTicket(paragra, setParagra)
      } else {
        //  block of code to be executed if the condition1 is false and condition2 is false
        // return loadingFormPage2(paragra, setParagra)
      }

}

const contentForm =()=>{
    return <div className="content">
        <div className="alert alert-info" style={{  'overflow': 'auto', 'maxHeight' : "400px"}}>
        {compareLanguages()}
        {compare1Page()}
        {compare2Page()}
        {compare3Page()}
        {addByJson()}
        </div>

   <div ref={divLoadingPage} className="alert alert-secondary">

  { LoadingPage(pageSelected)}
  
   </div>
  </div>
}

    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            loading={loading}
            SliderPunShow={false}
        >
    <div style={infoSource.styleNav}>
<div className="container-fluid">
    
{sideBarForm()}
{contentForm()}
{loadingFormPage2()}
      <AlertPage
        showAlert={showAlert} 
        setShowAlert={setShowAlert}
        /> 
</div>
</div>
        </Layout>
    );
};

export default AddLanguage;