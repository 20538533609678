import React from 'react';
// import { shippingData } from './shippingData';

const shippingData = {
    USA: {
      USPS: {
        services: [
          {
            name: "First-Class Mail",
            ecommerce: { standard: "$3 - $5", professional: "$2.80 - $4.75" },
            wholesale: { standard: "$3 - $5", professional: "$2.80 - $4.75" },
            retail: { standard: "$3 - $5", professional: "$2.80 - $4.75" },
            deliveryTime: "1-3 jours"
          },
          {
            name: "Priority Mail",
            ecommerce: { standard: "$7 - $15", professional: "$6.50 - $14.50" },
            wholesale: { standard: "$7 - $15", professional: "$6.50 - $14.50" },
            retail: { standard: "$7 - $15", professional: "$6.50 - $14.50" },
            deliveryTime: "1-3 jours"
          },
          {
            name: "Priority Mail Express",
            ecommerce: { standard: "$25 - $40", professional: "$23 - $38" },
            wholesale: { standard: "$25 - $40", professional: "$23 - $38" },
            retail: { standard: "$25 - $40", professional: "$23 - $38" },
            deliveryTime: "1-2 jours"
          }
        ]
      },
      // Ajoutez plus de compagnies et de services ici...
    },
    Canada: {
      CanadaPost: {
        services: [
          {
            name: "Regular Parcel",
            ecommerce: { standard: "$10 - $20", professional: "$9 - $18" },
            wholesale: { standard: "$10 - $20", professional: "$9 - $18" },
            retail: { standard: "$10 - $20", professional: "$9 - $18" },
            deliveryTime: "2-9 jours"
          },
          {
            name: "Xpresspost",
            ecommerce: { standard: "$20 - $40", professional: "$18 - $36" },
            wholesale: { standard: "$20 - $40", professional: "$18 - $36" },
            retail: { standard: "$20 - $40", professional: "$18 - $36" },
            deliveryTime: "1-2 jours"
          },
          {
            name: "Priority",
            ecommerce: { standard: "$30 - $60", professional: "$27 - $54" },
            wholesale: { standard: "$30 - $60", professional: "$27 - $54" },
            retail: { standard: "$30 - $60", professional: "$27 - $54" },
            deliveryTime: "1 jour"
          }
        ]
      },
      // Ajoutez plus de compagnies et de services ici...
    }
  };

const ShippingTable = ({ country, company}) => {
  const data = shippingData[country][company].services;

  return (
    <table border="1">
      <thead>
        <tr>
          <th>Service</th>
          <th>E-commerce (Standard)</th>
          <th>E-commerce (Pro Account)</th>
          <th>Vente en Gros (Standard)</th>
          <th>Vente en Gros (Pro Account)</th>
          <th>Vente au Detail (Standard)</th>
          <th>Vente au Detail (Pro Account)</th>
          <th>Delai de Livraison</th>
        </tr>
      </thead>
      <tbody>
        {data.map((service, index) => (
          <tr key={index}>
            <td>{service.name}</td>
            <td>{service.ecommerce.standard}</td>
            <td>{service.ecommerce.professional}</td>
            <td>{service.wholesale.standard}</td>
            <td>{service.wholesale.professional}</td>
            <td>{service.retail.standard}</td>
            <td>{service.retail.professional}</td>
            <td>{service.deliveryTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ShippingInfoCadUsa = ({infoSource}) => {
  return (
    <div>
      <h2>
        {/* Frais de Shipping Local - USA */}
        {infoSource.language(infoSource, "sellPage", "fraisShipUsa")}
        </h2>
      <ShippingTable country="USA" company="USPS" />
      {/* Ajoutez d'autres tableaux pour d'autres compagnies si necessaire */}
      
      <h2>
        {/* Frais de Shipping Local - Canada */}
        {infoSource.language(infoSource, "sellPage", "fraisShipCana")}
        </h2>
      <ShippingTable country="Canada" company="CanadaPost" />
      {/* Ajoutez d'autres tableaux pour d'autres compagnies si necessaire */}
    </div>
  );
};

export default ShippingInfoCadUsa;
