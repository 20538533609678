import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
    DeleteCuStID
} from "../auth/stripe";
import { isAuthenticated } from "../auth";


const StripeError = ({

    laguangeElakay,
    infoSource,
    setShow, 
    show,
    listePM_createStripeCusTomer
    }) => {
    const [success, setSuccess] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const token = isAuthenticated() && isAuthenticated().token;

    const FixError = () => {
        token && (
            DeleteCuStID(token).then(data => {
                if(data.error) {
                    console.log("data not found")
                } else {
                    setSuccess(data.success);
                    handleClose()
                    listePM_createStripeCusTomer(token)
                }
                
            })
        );
    };

    return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{infoSource.laguangeElakay('text', infoSource.languageAPI).textSripeError1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {infoSource.laguangeElakay('text', infoSource.languageAPI).textSripeError2}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).close}
                    </Button>
                    <Button onClick={FixError} variant="primary">{infoSource.laguangeElakay('Button', infoSource.languageAPI).fix}</Button>
                </Modal.Footer>
            </Modal>

    );
    }

    export default StripeError;