import React, { useState, useEffect} from "react";
import Layout from "../Layout/Layout"; 
import {isAuthenticated, getUserViewed} from "../auth";
import { FaMapMarkerAlt,  FaUserAlt, FaAddressCard, FaPhoneAlt} from "react-icons/fa";
import { MdSmartphone,  MdAlternateEmail, MdEdit} from "react-icons/md";
import Slider from '../slider/swiperProduct'
import Table from 'react-bootstrap/Table'
import {useLocation} from "react-router-dom";
import { Family, Adminstrator, Investment, Assoociate, Provider, Seller } from "../pageInside/Rolle"
import Modal from 'react-bootstrap/Modal'
import UserTelephone from "../pages/addTelephone"
import AddressUser from "../pageInside/AddressUser"

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

 
const UserProfile = (props) => {
    const {
        infoSource
        } = props
    const location = useLocation(); // Surveille les changements d'URL
    const query = useQuery();   
    const [userViewedProduct, setuserViewedProduct] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [pageAddress, setPageAddress] = useState(false)
    const [pagePhone, setPagePhone] = useState(false)
   
    const [loading, setLoading] = useState(false);  
    const { token } = isAuthenticated();

    let userInfo = infoSource.userInfo ? infoSource.userInfo : []

const handePage =(page)=>{ 
       if(page == "pageAddress"){
        setShowModal(true)
        setPageAddress(true)
        setPagePhone(false)
    }else if(page == "pagePhone"){
        setShowModal(true)
        setPageAddress(false)
        setPagePhone(true)
    } else{
        setShowModal(false)
        setPageAddress(false)
        setPagePhone(false)
    }
}

    const productViewedUser = (token) => {
        token && (
            getUserViewed(token).then(data => {
                if (!data) {
                    console.error("data Not Found");
                } else { 
                    setuserViewedProduct(data.products);
                }
            })
        )
    };

useEffect(() => {
    productViewedUser(token)
    query.get("addr") && handePage("pageAddress")
    query.get("tel") && handePage("pagePhone")
  
}, []);



    const roleUser =()=>(
        <>
        {
        Seller(true) && (
             <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).seller}</span>
        )}
         { Provider(true) && (
             <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).provider}</h4>
        )}
           {
        Assoociate(true) && (
             <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).assoociate}</span>
        )}   {
        Investment(true) && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).investment}</span>
            )}
       {Adminstrator(true) && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).Admin}</span>
        )}
        {Family() && (
            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).family}</span>
        )}
        </>
    )
    const addressButton = (
        // <Link to="/address">
        <button onClick={()=>handePage("pageAddress")} className="btn btn-outline-info pt-2 pb-2 pr-3 pl-3 rounded-4 float-left">
            <span style={{ fontSize: "20px", paddingRight: "6px" }}><FaAddressCard /></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addAdress}
        </button>
        // </Link>
    )

    const telephoneButton = (
        // <Link to="/telephone">
        <button onClick={()=>handePage("pagePhone")} className="btn btn-outline-info pt-2 pb-2 pr-3 pl-3 rounded-4 float-right">
            <span style={{ fontSize: "20px", paddingRight: "6px" }}><MdSmartphone/></span>{infoSource.laguangeElakay('Button', infoSource.languageAPI).addTel}
        </button>
        // </Link>
    )
    
    let telephone =  infoSource.phoneUser && infoSource.phoneUser.filter(k => k.default === true)[0]
    let AddressTrue  = infoSource.address && infoSource.address.filter(k => k.addressDefault === true)[0]

 

    const UserDetails = (
        
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourInfo}</h3>
                </div>
                <div style={{ overflow: "auto"}}>
                <Table>
                    <tbody>
                    <tr>
                        <td style={{width:"180px"}}><h6> {infoSource.laguangeElakay('label', infoSource.languageAPI).fisrtName}</h6></td>
                        <td><h6> {`${userInfo.firstname}`} </h6></td>
                                {/* <td><h4> </h4></td> */}
                    </tr>
                    <tr>
                        <td style={{ width: "180px" }}><h6>{infoSource.laguangeElakay('label', infoSource.languageAPI).lastName}</h6></td>
                        <td><h6> {`${userInfo.lastname}`} </h6></td>
                                {/* <td><h4>  </h4></td> */}
                    </tr>
                    <tr>
                        <td style={{ width: "180px" }}><h6>{infoSource.laguangeElakay('label', infoSource.languageAPI).Email}</h6></td>
                        <td><h6> {`${userInfo.email}`} </h6></td>
                                {/* <td><h4>  </h4></td> */}
                    </tr>
                    {
                      infoSource.phoneUser && infoSource.phoneUser.map((k,i)=>(
                            k.default && (
                    <tr key={i}>
                        <td style={{ width: "180px" }}><h6>{infoSource.laguangeElakay('label', infoSource.languageAPI).telephon}</h6></td>
                        <td><h6> {`${k.International}`} </h6></td>
                        {/* <td><Link to="/telephone"> <h4><MdEdit /></h4></Link></td> */}
                    </tr>
                        )))}
            
                    {infoSource.address && infoSource.address.map((m, i) => (
                        m.addressDefault && (
                          
                    <tr  key={i}>

                        <td style={{ width: "180px" }}><h6>{infoSource.laguangeElakay('label', infoSource.languageAPI).AdressDefault}</h6></td>
                                <td><h6> {m.firstName} {m.lastName}<br /> {m.Address1}, {m.city} {m.State} {m.postalCode} {m.country}</h6></td>
                                {/* <td><Link to={`/address/Edit/${m._id}`}> <h4><MdEdit /></h4></Link></td> */}
                       
                    </tr>
                        )
                     ))

                    }
                        </tbody>
                </Table>
                </div>
             </div>
        </div>
    )  
    
       
    let myStyles = {
        width: "100%",
        backgroundColor: '#f0f0f0'
    }

  const buttonGroupUser =(
<div className="row justify-content-center">
<div className="bg clearfix ">
     {telephoneButton}
      {addressButton}
</div> 
</div>
  )
  const infoUser = (
    <div className="row mt-4 mb-4 justify-content-center">
        <div className="col col-12 col-md-10 col-lg-8 col-xl-6 p-0" style={{ border: "1px solid Gainsboro" }} >
            <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                <h3>{infoSource.laguangeElakay('title', infoSource.languageAPI).yourInfo}  {roleUser()}</h3>
            </div>
            <div className="m-3">
           
            <Table>
                <tbody>
                <tr>
                    <td><h4><span style={{ fontSize: "30px", paddingRight: "20px" }}><FaUserAlt /></span>  {`${userInfo.firstname} ${userInfo.lastname}`} </h4></td>
                </tr>
                <tr>
                    <td><h5><span style={{ fontSize: "30px", paddingRight: "20px" }}><MdAlternateEmail /></span>{userInfo.email} </h5></td>
                </tr>
               {    telephone &&   <tr><td>
                <div>
                            <h5>
                             <FaPhoneAlt /> {telephone.International}
                            </h5>
                        </div>
                        </td></tr>}
               {AddressTrue &&  <tr><td>
                            <h5><span> <FaMapMarkerAlt />  { AddressTrue.firstName} {AddressTrue.lastName}</span> <br /> <span style={{ paddingLeft: "20px" }}>  {AddressTrue.Address1}, {AddressTrue.city} {AddressTrue.State} {AddressTrue.postalCode} {AddressTrue.country}</span> <br />
                                <span style={{ paddingLeft: "21px", fontSize: "14px" }}> {infoSource.laguangeElakay('title', infoSource.languageAPI).defaultDeliryAdress}</span>
                            </h5>
                            </td></tr>}
                </tbody>
            </Table>

{/*                                    
           {    telephone &&  <div>
                            <h5>
                             <FaPhoneAlt /> {telephone.International}

                            </h5>
                        </div>}
            {AddressTrue &&
                            <h5><span> <FaMapMarkerAlt />  { AddressTrue.firstName} {AddressTrue.lastName}</span> <br /> <span style={{ paddingLeft: "20px" }}>  {AddressTrue.Address1}, {AddressTrue.city} {AddressTrue.State} {AddressTrue.postalCode} {AddressTrue.country}</span> <br />
                                <span style={{ paddingLeft: "21px", fontSize: "14px" }}> {infoSource.laguangeElakay('title', infoSource.languageAPI).defaultDeliryAdress}</span>
                            </h5>} */}

            {/* <Link to="/address">{infoSource.laguangeElakay('Link', infoSource.languageAPI).changeAddress}</Link> */}

        
        </div></div>
    </div>
) 
  const pageAddPhoneAddress = () => (
    <div className="contaire">
        <div className="row justify-content-center " >
            <Modal
                size="xl"
                show={showModal}
                onHide={() => handePage()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
            
                <Modal.Body>
                {pagePhone && <UserTelephone handePage={handePage} infoSource ={infoSource}/>}
                {pageAddress && <AddressUser handePage={handePage} infoSource ={infoSource} />}
                </Modal.Body>
            </Modal>
        </div>
    </div>
)
   
    return (
        <Layout
            infoSource ={infoSource}
            title="UserProfile Page"
            description="Node React E-commerce App"
            className="container-fluid"
            SliderPunShow={false}
            loading={loading}
        >
        <div style={infoSource.styleNav}>
       <div className="p-2">
        {infoUser}
         {/* {UserDetails} */}
         <div className="row mt-4 mb-4 justify-content-center">
         <div className="col col-12 col-md-8">
        {buttonGroupUser}
        </div>
        </div>
        {pageAddPhoneAddress()}
            </div>
            {userViewedProduct &&  <Slider
                 infoSource ={infoSource}
                 ProductSwiper={userViewedProduct}
                 User={infoSource.readUser}
                 nameSlider={infoSource.language(infoSource, 'homePage', 'yourViewed')}
                 linkSlider={"/user-viewed"}
                 CataID={true}
                 myStyles={myStyles} 
                 />}
            </div>
        </Layout>
    );
};

export default UserProfile;