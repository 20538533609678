import React from "react";

const AddParcel =({
    laguangeElakay,
    infoSource,
    parcel,
    parcelChange
}) => {
   

    const parcelForm = () => (

      <form className=" m-3" >
            <h5>{infoSource.laguangeElakay('title', infoSource.languageAPI).dimensionWeight}</h5>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).length} (in)</label>
                <input onChange={parcelChange("length")} type="number" min="0" className="form-control" value={parcel.length} />
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).width} (in)</label>
                <input onChange={parcelChange("width")} type="number" min="0" className="form-control" value={parcel.width} />
            </div>
            <div className="form-group">
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).height} (in)</label>
                <input onChange={parcelChange("height")} type="number" min="0" className="form-control" value={parcel.height} />
            </div>
            <div className="form-group" >
                <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).weight} (lb)</label>
                <input onChange={parcelChange("weight")} type="number" min="0" className="form-control" value={parcel.weight} />
            </div>
     </form>

    )

 
    return parcelForm()
    
}
export default  AddParcel;