import React from "react";
import { Link } from 'react-router-dom';

export const privacyPolicy = () => {
    return ( <div>
        <h1>Privacy Policy</h1>
        <p>Last updated: June 27, 2020</p>
        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
        <p>We use Your Personal data to provide and improve the Servic,se. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
    </div>
    )
};


export const interpretationDefinitions = () => {
    return (<div>
        <h1>Interpretation and Definitions</h1>
        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <h3>Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
            <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
            <li>
                <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Elakay, 80 Moffat St Brooklyn ny.</p>
            </li>
            <li>
                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
            </li>
            <li>
                <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
            </li>
            <li>
                <p><strong>Website</strong> refers to Elakay , accessible from <Link to="https://elakay.com/" rel="external nofollow noopener" target="_blank">https://elakay.com/</Link></p>
            </li>
            <li>
                <p><strong>Service</strong> refers to the Website.</p>
            </li>
            <li>
                <p><strong>Country</strong> refers to: New York,  United States</p>
            </li>
            <li>
                <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
            </li>
            <li>
                <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
            </li>
            <li>
                <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
            </li>
            <li>
                <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
            </li>
            <li>
                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li>
                <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
            </li>
        </ul>
         </div>
    )
};
export const CollectingUsingYourPersonalData = () => {
    return (<div>
        <h1>Collecting and Using Your Personal Data</h1>
        <h3>Types of Data Collected</h3>
        <h3>Personal Data</h3>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Bank account information in order to pay for products and/or services within the Service</li>
            <li>Usage Data</li>
        </ul>
        <p>When You pay for a product and/or a service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity. Such information may include, without limitation:</p>
        <ul>
            <li>Date of birth</li>
            <li>Passport or National ID card</li>
            <li>Bank card statement</li>
            <li>Other information linking You to an address</li>
        </ul>
        <h3>Usage Data</h3>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
        <h3>Information from Third-Party Social Media Services</h3>
        <p>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
        <ul>
            <li>Google</li>
            <li>Facebook</li>
            <li>Twitter</li>
        </ul>
        <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
        <p>You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>
        <h3>Tracking Technologies and Cookies</h3>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.</p>
        <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
        <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn more about cookies: <Link to="https://www.termsfeed.com/blog/cookies/" target="_blank">All About Cookies</Link>.</p>
        <p>We use both session and persistent Cookies for the purposes set out below:</p>
        <ul>
            <li>
                <p><strong>Necessary / Essential Cookies</strong></p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
            </li>
            <li>
                <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
            </li>
            <li>
                <p><strong>Functionality Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
            </li>
            <li>
                <p><strong>Tracking and Performance Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Third-Parties</p>
                <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.</p>
            </li>
            <li>
                <p><strong>Targeting and Advertising Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Third-Parties</p>
                <p>Purpose: These Cookies track your browsing habits to enable Us to show advertising which is more likely to be of interest to You. These Cookies use information about your browsing history to group You with other users who have similar interests. Based on that information, and with Our permission, third party advertisers can place Cookies to enable them to show adverts which We think will be relevant to your interests while You are on third party websites.</p>
            </li>
        </ul>
        <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
        <h3>Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
            <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
            <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</li>
            <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
            <li><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
            <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
        </ul>
        <p>We may share your personal information in the following situations:</p>
        <ul>
            <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to advertise on third party websites to You after You visited our Service, for payment processing,  to contact You.</li>
            <li><strong>For Business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
            <li><strong>With Business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
            <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
        </ul>
        <h3>Retention of Your Personal Data</h3>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        <h3>Transfer of Your Personal Data</h3>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
        <h3>Disclosure of Your Personal Data</h3>
        <h3>Business Transactions</h3>
        <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
        <h3>Law enforcement</h3>
        <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        <h3>Other legal requirements</h3>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
        </ul>
        <h3>Security of Your Personal Data</h3>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
           </div>
    )
};

export const DetailedInformationProcessingYourPersonalData = () => {
    return (<div>
        <h1>Detailed Information on the Processing of Your Personal Data</h1>
        <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.</p>
        <h3>Analytics</h3>
        <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
        <ul>
            <li>
                <p><strong>Google Analytics</strong></p>
                <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
                <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</p>
                <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <Link to="https://policies.google.com/privacy" rel="external nofollow noopener" target="_blank">https://policies.google.com/privacy</Link></p>
            </li>
            <li>
                <p><strong>Firebase</strong></p>
                <p>Firebase is an analytics service provided by Google Inc.</p>
                <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <Link to="https://policies.google.com/privacy" rel="external nofollow noopener" target="_blank">https://policies.google.com/privacy</Link></p>
                <p>We also encourage you to review the Google's policy for safeguarding your data: <Link to="https://support.google.com/analytics/answer/6004245" rel="external nofollow noopener" target="_blank">https://support.google.com/analytics/answer/6004245</Link></p>
                <p>For more information on what type of information Firebase collects, please visit the Google Privacy &amp; Terms web page: <Link to="https://policies.google.com/privacy" rel="external nofollow noopener" target="_blank">https://policies.google.com/privacy</Link></p>
            </li>
            <li>
                <p><strong>Matomo</strong></p>
                <p>Matomo is a web analytics service. You can visit their Privacy Policy page here: <Link to="https://matomo.org/privacy-policy" rel="external nofollow noopener" target="_blank">https://matomo.org/privacy-policy</Link></p>
            </li>
            <li>
                <p><strong>Clicky</strong></p>
                <p>Clicky is a web analytics service. Read the Privacy Policy for Clicky here: <Link to="https://clicky.com/terms" rel="external nofollow noopener" target="_blank">https://clicky.com/terms</Link></p>
            </li>
            <li>
                <p><strong>Statcounter</strong></p>
                <p>Statcounter is a web traffic analysis tool. You can read the Privacy Policy for Statcounter here: <Link to="https://statcounter.com/about/legal/" rel="external nofollow noopener" target="_blank">https://statcounter.com/about/legal/</Link></p>
            </li>
            <li>
                <p><strong>Flurry Analytics</strong></p>
                <p>Flurry Analytics service is provided by Yahoo! Inc.</p>
                <p>You can opt-out from Flurry Analytics service to prevent Flurry Analytics from using and sharing your information by visiting the Flurry's Opt-out page: <Link to="https://developer.yahoo.com/flurry/end-user-opt-out/" rel="external nofollow noopener" target="_blank">https://developer.yahoo.com/flurry/end-user-opt-out/</Link></p>
                <p>For more information on the privacy practices and policies of Yahoo!, please visit their Privacy Policy page: <Link to="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm" rel="external nofollow noopener" target="_blank">https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm</Link></p>
            </li>
            <li>
                <p><strong>Mixpanel</strong></p>
                <p>Mixpanel is provided by Mixpanel Inc.</p>
                <p>You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: <Link to="https://mixpanel.com/optout/" rel="external nofollow noopener" target="_blank">https://mixpanel.com/optout/</Link></p>
                <p>For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: <Link to="https://mixpanel.com/terms/" rel="external nofollow noopener" target="_blank">https://mixpanel.com/terms/</Link></p>
            </li>
            <li>
                <p><strong>Unity Analytics</strong></p>
                <p>Unity Analytics is provided by Unity Technologies.</p>
                <p>For more information on what type of information Unity Analytics collects, please visit their Privacy Policy page: <Link to="https://unity3d.com/legal/privacy-policy" rel="external nofollow noopener" target="_blank">https://unity3d.com/legal/privacy-policy</Link></p>
            </li>
        </ul>
        <h3>Advertising</h3>
        <p>We may use Service providers to show advertisements to You to help support and maintain Our Service.</p>
        <ul>
            <li>
                <p><strong>Google AdSense &amp; DoubleClick Cookie</strong></p>
                <p>Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.</p>
                <p>You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: <Link to="http://www.google.com/ads/preferences/" rel="external nofollow noopener" target="_blank">http://www.google.com/ads/preferences/</Link></p>
            </li>
            <li>
                <p><strong>AdMob by Google</strong></p>
                <p>AdMob by Google is provided by Google Inc.</p>
                <p>You can opt-out from the AdMob by Google service by following the instructions described by Google: <Link to="https://support.google.com/ads/answer/2662922?hl=en" rel="external nofollow noopener" target="_blank">https://support.google.com/ads/answer/2662922?hl=en</Link></p>
                <p>For more information on how Google uses the collected information, please visit the &quot;How Google uses data when you use our partners' sites or app&quot; page: <Link to="https://policies.google.com/technologies/partner-sites" rel="external nofollow noopener" target="_blank">https://policies.google.com/technologies/partner-sites</Link> or visit the Privacy Policy of Google: <Link to="https://policies.google.com/privacy" rel="external nofollow noopener" target="_blank">https://policies.google.com/privacy</Link></p>
            </li>
            <li>
                <p><strong>Bing Ads</strong></p>
                <p>Bing Ads is an advertising service provided by Microsoft Inc.</p>
                <p>You can opt-out from Bing Ads by following the instructions on Bing Ads Opt-out page: <Link to="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" rel="external nofollow noopener" target="_blank">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</Link></p>
                <p>For more information about Bing Ads, please visit their Privacy Policy: <Link to="https://privacy.microsoft.com/en-us/PrivacyStatement" rel="external nofollow noopener" target="_blank">https://privacy.microsoft.com/en-us/PrivacyStatement</Link></p>
            </li>
            <li>
                <p><strong>Flurry</strong></p>
                <p>Flurry is provided by Yahoo! Inc.</p>
                <p>You can opt-out of the Flurry service and prevent it from using and sharing your information by visiting the Flurry Opt-out page: <Link to="https://developer.yahoo.com/flurry/end-user-opt-out/" rel="external nofollow noopener" target="_blank">https://developer.yahoo.com/flurry/end-user-opt-out/</Link></p>
                <p>For more information on the privacy practices policies of Yahoo!, please visit their Privacy Policy: <Link to="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm" rel="external nofollow noopener" target="_blank">https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm</Link></p>
            </li>
            <li>
                <p><strong>InMobi</strong></p>
                <p>InMobi is provided by InMobi Pte Ltd.</p>
                <p>You can opt-out from InMobi service by following the instructions as described on the InMobi Opt-out page: <Link to="http://www.inmobi.com/page/opt-out/" rel="external nofollow noopener" target="_blank">http://www.inmobi.com/page/opt-out/</Link></p>
                <p>For more information on the privacy practices and policies of InMobi, please visit the InMobi Privacy Policy: <Link to="http://www.inmobi.com/privacy-policy/" rel="external nofollow noopener" target="_blank">http://www.inmobi.com/privacy-policy/</Link></p>
            </li>
            <li>
                <p><strong>MoPub</strong></p>
                <p>MoPub is provided by MobPub Inc.</p>
                <p>You can opt-out from MobPub service by following the instructions on MoPub Opt-out page: <Link to="http://www.mopub.com/optout/" rel="external nofollow noopener" target="_blank">http://www.mopub.com/optout/</Link></p>
                <p>For more information on what information MobPub collects and how it is used, please read MoPub Privacy Policy: <Link to="http://www.mopub.com/legal/privacy/" rel="external nofollow noopener" target="_blank">http://www.mopub.com/legal/privacy/</Link></p>
            </li>
            <li>
                <p><strong>StartApp</strong></p>
                <p>StartApp is provided by StartApp Inc.</p>
                <p>You can opt-out from interest-based ads served by StartApp by following the instructions on their Privacy Policy, section &quot;8. Your Choices and Controls&quot;: <Link to="https://www.startapp.com/policy/privacy-policy/" rel="external nofollow noopener" target="_blank">https://www.startapp.com/policy/privacy-policy/</Link></p>
                <p>For more information on what information StartApp collects and how it is used, please read their Privacy Policy: <Link to="https://www.startapp.com/policy/privacy-policy/" rel="external nofollow noopener" target="_blank">https://www.startapp.com/policy/privacy-policy/</Link></p>
            </li>
            <li>
                <p><strong>AdColony</strong></p>
                <p>AdColony is provided by Jirbo Inc.</p>
                <p>You can opt-out from AdColony service by visiting the AdColony Opt-out page: <Link to="https://www.adcolony.com/privacy-policy/" rel="external nofollow noopener" target="_blank">https://www.adcolony.com/privacy-policy/</Link></p>
                <p>You can also opt-out from AdColony through the Digital Advertising Alliance in the USA <Link to="http://www.aboutads.info/choices/" rel="external nofollow noopener" target="_blank">http://www.aboutads.info/choices/</Link> or opt-out using your mobile device settings.</p>
                <p>For more information AdColony, please visit the Privacy Policy of AdColony: <Link to="http://www.adcolony.com/privacy-policy/" rel="external nofollow noopener" target="_blank">http://www.adcolony.com/privacy-policy/</Link></p>
            </li>
            <li>
                <p><strong>AppLovin</strong></p>
                <p>AppLovin is provided by the AppLovin Corporation.</p>
                <p>If you'd like to opt-out from AppLovin service, please visit the AppLovin Opt-out page: <Link to="https://www.applovin.com/optout" rel="external nofollow noopener" target="_blank">https://www.applovin.com/optout</Link></p>
                <p>If you'd like to learn more about AppLovin, please visit the Privacy Policy page of AppLovin: <Link to="https://www.applovin.com/privacy" rel="external nofollow noopener" target="_blank">https://www.applovin.com/privacy</Link></p>
            </li>
            <li>
                <p><strong>Vungle</strong></p>
                <p>Vungle is provided by Vungle Inc.</p>
                <p>You can opt-out from Vungle service by following the instructions as described by Vungle on their Privacy Policy page: <Link to="http://vungle.com/privacy/" rel="external nofollow noopener" target="_blank">http://vungle.com/privacy/</Link></p>
                <p>For more information about Vungle, please visit Vungle Privacy Policy: <Link to="http://vungle.com/privacy/" rel="external nofollow noopener" target="_blank">http://vungle.com/privacy/</Link></p>
            </li>
            <li>
                <p><strong>AdButler</strong></p>
                <p>AdButler is an advertising service provided by Sparklit Networks Inc.</p>
                <p>For more information about AdButler, please visit their Privacy Policy: <Link to="https://www.sparklit.com/agreements.spark?agreement=privacy" rel="external nofollow noopener" target="_blank">https://www.sparklit.com/agreements.spark?agreement=privacy</Link></p>
            </li>
            <li>
                <p><strong>Unity Ads</strong></p>
                <p>Unity Ads is provided by Unity Technologies.</p>
                <p>You can opt-out from Unity Ads service by following the instructions as described by Unity Technologies on their Privacy Policy page: <Link to="https://unity3d.com/legal/privacy-policy" rel="external nofollow noopener" target="_blank">https://unity3d.com/legal/privacy-policy</Link></p>
                <p>For more information about Unity Technologies, please visit Unity Technologies Privacy Policy: <Link to="https://unity3d.com/legal/privacy-policy" rel="external nofollow noopener" target="_blank">https://unity3d.com/legal/privacy-policy</Link></p>
            </li>
        </ul>
        <h3>Email Marketing</h3>
        <p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.</p>
        <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>
        <ul>
            <li>
                <p><strong>Mailchimp</strong></p>
                <p>Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.</p>
                <p>For more information on the privacy practices of Mailchimp, please visit their Privacy policy: <Link to="https://mailchimp.com/legal/privacy/" rel="external nofollow noopener" target="_blank">https://mailchimp.com/legal/privacy/</Link></p>
            </li>
            <li>
                <p><strong>AWeber</strong></p>
                <p>AWeber is an email marketing sending service provided by AWeber Communications.</p>
                <p>For more information on the privacy practices of AWeber, please visit their Privacy policy: <Link to="https://www.aweber.com/privacy.htm" rel="external nofollow noopener" target="_blank">https://www.aweber.com/privacy.htm</Link></p>
            </li>
            <li>
                <p><strong>GetResponse</strong></p>
                <p>GetResponse is an email marketing sending service provided by GetResponse.</p>
                <p>For more information on the privacy practices of GetResponse, please visit their Privacy policy: <Link to="https://www.getresponse.com/legal/privacy.html" rel="external nofollow noopener" target="_blank">https://www.getresponse.com/legal/privacy.html</Link></p>
            </li>
        </ul>
        <h3>Payments</h3>
        <p>We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).</p>
        <p>We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
        <ul>
            <li>
                <p><strong>Apple Store In-App Payments</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="https://www.apple.com/legal/privacy/en-ww/" rel="external nofollow noopener" target="_blank">https://www.apple.com/legal/privacy/en-ww/</Link></p>
            </li>
            <li>
                <p><strong>Google Play In-App Payments</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="https://www.google.com/policies/privacy/" rel="external nofollow noopener" target="_blank">https://www.google.com/policies/privacy/</Link></p>
            </li>
            <li>
                <p><strong>Stripe</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="https://stripe.com/us/privacy" rel="external nofollow noopener" target="_blank">https://stripe.com/us/privacy</Link></p>
            </li>
            <li>
                <p><strong>PayPal</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="https://www.paypal.com/webapps/mpp/ua/privacy-full" rel="external nofollow noopener" target="_blank">https://www.paypal.com/webapps/mpp/ua/privacy-full</Link></p>
            </li>
            <li>
                <p><strong>Braintree</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="https://www.braintreepayments.com/legal/braintree-privacy-policy" rel="external nofollow noopener" target="_blank">https://www.braintreepayments.com/legal/braintree-privacy-policy</Link></p>
            </li>
            <li>
                <p><strong>FastSpring</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="http://fastspring.com/privacy/" rel="external nofollow noopener" target="_blank">http://fastspring.com/privacy/</Link></p>
            </li>
            <li>
                <p><strong>Square</strong></p>
                <p>Their Privacy Policy can be viewed at <Link to="https://squareup.com/legal/privacy-no-account" rel="external nofollow noopener" target="_blank">https://squareup.com/legal/privacy-no-account</Link></p>
            </li>
        </ul>
        <p>When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.</p>
        <h3>Behavioral Remarketing</h3>
        <p>The Company uses remarketing services to advertise on third party websites to You after You visited our Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.</p>
        <ul>
            <li>
                <p><strong>Google Ads (AdWords)</strong></p>
                <p>Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
                <p>You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: <Link to="http://www.google.com/settings/ads" rel="external nofollow noopener" target="_blank">http://www.google.com/settings/ads</Link></p>
                <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <Link to="https://tools.google.com/dlpage/gaoptout" rel="external nofollow noopener" target="_blank">https://tools.google.com/dlpage/gaoptout</Link> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
                <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web page: <Link to="https://policies.google.com/privacy" rel="external nofollow noopener" target="_blank">https://policies.google.com/privacy</Link></p>
            </li>
            <li>
                <p><strong>Bing Ads Remarketing</strong></p>
                <p>Bing Ads remarketing service is provided by Microsoft Inc.</p>
                <p>You can opt-out of Bing Ads interest-based ads by following their instructions: <Link to="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" rel="external nofollow noopener" target="_blank">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</Link></p>
                <p>You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy page: <Link to="https://privacy.microsoft.com/en-us/PrivacyStatement" rel="external nofollow noopener" target="_blank">https://privacy.microsoft.com/en-us/PrivacyStatement</Link></p>
            </li>
            <li>
                <p><strong>Twitter</strong></p>
                <p>Twitter remarketing service is provided by Twitter Inc.</p>
                <p>You can opt-out from Twitter's interest-based ads by following their instructions: <Link to="https://support.twitter.com/articles/20170405" rel="external nofollow noopener" target="_blank">https://support.twitter.com/articles/20170405</Link></p>
                <p>You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: <Link to="https://twitter.com/privacy" rel="external nofollow noopener" target="_blank">https://twitter.com/privacy</Link></p>
            </li>
            <li>
                <p><strong>Facebook</strong></p>
                <p>Facebook remarketing service is provided by Facebook Inc.</p>
                <p>You can learn more about interest-based advertising from Facebook by visiting this page: <Link to="https://www.facebook.com/help/164968693837950" rel="external nofollow noopener" target="_blank">https://www.facebook.com/help/164968693837950</Link></p>
                <p>To opt-out from Facebook's interest-based ads, follow these instructions from Facebook: <Link to="https://www.facebook.com/help/568137493302217" rel="external nofollow noopener" target="_blank">https://www.facebook.com/help/568137493302217</Link></p>
                <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <Link to="http://www.aboutads.info/choices/" rel="external nofollow noopener" target="_blank">http://www.aboutads.info/choices/</Link>, the Digital Advertising Alliance of Canada in Canada <Link to="http://youradchoices.ca/" rel="external nofollow noopener" target="_blank">http://youradchoices.ca/</Link> or the European Interactive Digital Advertising Alliance in Europe <Link to="http://www.youronlinechoices.eu/" rel="external nofollow noopener" target="_blank">http://www.youronlinechoices.eu/</Link>, or opt-out using your mobile device settings.</p>
                <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <Link to="https://www.facebook.com/privacy/explanation" rel="external nofollow noopener" target="_blank">https://www.facebook.com/privacy/explanation</Link></p>
            </li>
            <li>
                <p><strong>Pinterest</strong></p>
                <p>Pinterest remarketing service is provided by Pinterest Inc.</p>
                <p>You can opt-out from Pinterest's interest-based ads by enabling the &quot;Do Not Track&quot; functionality of your web browser or by following Pinterest instructions: <Link to="http://help.pinterest.com/en/articles/personalization-and-data" rel="external nofollow noopener" target="_blank">http://help.pinterest.com/en/articles/personalization-and-data</Link></p>
                <p>You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy Policy page: <Link to="https://about.pinterest.com/en/privacy-policy" rel="external nofollow noopener" target="_blank">https://about.pinterest.com/en/privacy-policy</Link></p>
            </li>
            <li>
                <p><strong>AdRoll</strong></p>
                <p>AdRoll remarketing service is provided by Semantic Sugar, Inc.</p>
                <p>You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page: <Link to="http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false" rel="external nofollow noopener" target="_blank">http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false</Link></p>
                <p>For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page: <Link to="http://www.adroll.com/about/privacy" rel="external nofollow noopener" target="_blank">http://www.adroll.com/about/privacy</Link></p>
            </li>
            <li>
                <p><strong>Perfect Audience</strong></p>
                <p>Perfect Audience remarketing service is provided by NowSpots Inc.</p>
                <p>You can opt-out of Perfect Audience remarketing by visiting these pages: Platform Opt-out (<Link to="http://pixel.prfct.co/coo" rel="external nofollow noopener" target="_blank">http://pixel.prfct.co/coo</Link>) and Partner Opt-out (<Link to="http://ib.adnxs.com/optout" rel="external nofollow noopener" target="_blank">http://ib.adnxs.com/optout</Link>).</p>
                <p>For more information on the privacy practices of Perfect Audience, please visit the Perfect Audience Privacy Policy &amp; Opt-out web page: <Link to="https://www.perfectaudience.com/privacy/index.html" rel="external nofollow noopener" target="_blank">https://www.perfectaudience.com/privacy/</Link></p>
            </li>
            <li>
                <p><strong>AppNexus</strong></p>
                <p>AppNexus remarketing service is provided by AppNexus Inc.</p>
                <p>You can opt-out of AppNexus remarketing by visiting the Privacy &amp; the AppNexus Platform web page: <Link to="https://www.appnexus.com/platform-privacy-policy" rel="external nofollow noopener" target="_blank">https://www.appnexus.com/platform-privacy-policy</Link></p>
                <p>For more information on the privacy practices of AppNexus, please visit the AppNexus Platform Privacy Policy web page: <Link to="https://www.appnexus.com/platform-privacy-policy" rel="external nofollow noopener" target="_blank">https://www.appnexus.com/platform-privacy-policy</Link></p>
            </li>
        </ul>
        <h3>Usage, Performance and Miscellaneous</h3>
        <p>We may use third-party Service Providers to provide better improvement of our Service.</p>
        <ul>
            <li>
                <p><strong>Google Places</strong></p>
                <p>Google Places is a service that returns information about places using HTTP requests. It is operated by Google</p>
                <p>Google Places service may collect information from You and from Your Device for security purposes.</p>
                <p>The information gathered by Google Places is held in accordance with the Privacy Policy of Google: <Link to="https://www.google.com/intl/en/policies/privacy/" rel="external nofollow noopener" target="_blank">https://www.google.com/intl/en/policies/privacy/</Link></p>
            </li>
        </ul>
        </div>
    )
};

///////////////////////////////////////////////
export const YourCaliforniaPrivacyRights = () => {
    return (<div>
<h1>Your California Privacy Rights (California's Shine the Light law)</h1>
    <p>Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.</p>
    <p>If you'd like to request more information under the California Shine the Light law, and if you are a California resident, You can contact Us using the contact information provided below.</p>
    <h1>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</h1>
    <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>
    <p>To request removal of such data, and if you are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
    <p>Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>
    </div>
    )
};
   ////////////////////////////////////////////

export const LinksOtherWebsites = () => {
    return ( <div>
    <h1>Links to Other Websites</h1>

    <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            </div>
    )
};
    ///////////////////////////////////////////

export const ChangesthisPrivacyPolicy = () => {
    return ( <div>
    <h1>Changes to this Privacy Policy</h1>
    <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
    <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
           </div>
    )
};
   ////////////////////////////////////////////

export const ContactUs = () => {
    return ( <div>
    <h1>Contact Us</h1>
    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
    <ul>
        <li>
            <p>By email: Admin@elakay</p>
        </li>
        <li>
            <p>By visiting this page on our website: <Link to="https://elakay.com/help-contact" rel="external nofollow noopener" target="_blank">https://elakay.com/help-contact</Link></p>
        </li>
        <li>
            <p>By mail: 80 Moffat St</p>
        </li>
    </ul>
            </div>
    )
};

    /////////////////////////////////////////////

export const TermsConditions = (setShowContactUs) => {
    return ( <div>
    <h1>Terms and Conditions</h1>
    <p>Last updated: December 09, 2018</p>
    <p>These Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) govern your relationship with <Link to="https://elakay.com" rel="external nofollow noopener" target="_blank">https://elakay.com</Link> website (the &quot;Service&quot;) operated by elakay (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).</p>
    <p>Please read these Terms and Conditions carefully before using the Service.</p>
    <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
    <h2>Purchases</h2>
    <p>If you wish to purchase any product or service made available through the Service (&quot;Purchase&quot;), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
    <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>
    <p>By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.</p>
    <h2>Availability, Errors and Inaccuracies</h2>
    <p>We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other web sites.</p>
    <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>
    <h2>Contests, Sweepstakes and Promotions</h2>
    <p>Any contests, sweepstakes or other promotions (collectively, &quot;Promotions&quot;) made available through the Service may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>
    <h2>Subscriptions</h2>
    <p>Some parts of the Service are billed on a subscription basis (&quot;Subscription(s)&quot;). You will be billed in advance on a recurring and periodic basis (&quot;Billing Cycle&quot;). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>
    <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or elakay cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting elakay customer support team.</p>
    <p>A valid payment method, including credit card or PayPal, is required to process the payment for your Subscription. You shall provide elakay with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize elakay to charge all Subscription fees incurred through your account to any such payment instruments.</p>
    <p>Should automatic billing fail to occur for any reason, elakay will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
    <h2>Free Trial</h2>
    <p>elakay may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (&quot;Free Trial&quot;).</p>
    <p>You may be required to enter your billing information in order to sign up for the Free Trial.</p>
    <p>If you do enter your billing information when signing up for the Free Trial, you will not be charged by elakay until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>
    <p>At any time and without notice, elakay reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>
    <h2>Fee Changes</h2>
    <p>elakay, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
    <p>elakay will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
    <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
    <h2>Refunds</h2>
    <p>Certain refund requests for Subscriptions may be considered by elakay on a case-by-case basis and granted in sole discretion of elakay.</p>
    <h2>Content</h2>
    <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (&quot;Content&quot;). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</p>
    <p>By posting Content to the Service, you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights.</p>
    <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>
    <h2>Accounts</h2>
    <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
    <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
    <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
    <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
    <h2>Copyright Policy</h2>
    <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes the copyright or other intellectual property infringement (&quot;Infringement&quot;) of any person.</p>
    <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, you must submit your notice in writing to the attention of &quot;Copyright Infringement&quot; of administrator@elakay.com and include in your notice a detailed description of the alleged Infringement.</p>
    <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing your copyright.</p>
    <h2>Intellectual Property</h2>
    <p>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of elakay and its licensors. The Service is protected by copyright, trademark, and other laws of both the  and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of elakay.</p>
    <h2>Links To Other Web Sites</h2>
    <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by elakay.</p>
    <p>elakay has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that elakay shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
    <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
    <h2>Termination</h2>
    <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
    <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
    <h2>Limitation Of Liability</h2>
    <p>In no event shall elakay, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
    <h2>Disclaimer</h2>
    <p>Your use of the Service is at your sole risk. The Service is provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
    <p>elakay its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
    <h2>Governing Law</h2>
    <p>These Terms shall be governed and construed in accordance with the laws of  Haiti, without regard to its conflict of law provisions.</p>
    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
    <h2>Changes</h2>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
    <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
    {/* <h2>Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us. <span style={{ cursor: "auto" }} className="text-info" onClick={() => setShowContactUs(true)}>Click here</span></p>
             */}
            </div>
    )
};
    ///////////////////////////////////////////
export const ReturnAndRefundPolicy = () => {
    return ( <div>
    <h1>Return and Refund Policy</h1>
    <p>Last updated: June 27, 2020</p>
    <p>Thank you for shopping at Elakay .</p>
    <p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns.</p>
    <p>The following terms are applicable for any products that You purchased with Us.</p>
        </div>
        )
    };
    ///////////////////////////////////////////////

export const InterpretationDefinitions = () => {
    return ( <div>
    <h1>Interpretation and Definitions</h1>
    <h2>Interpretation</h2>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
    <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
    <h2>Definitions</h2>
    <p>For the purposes of this Return and Refund Policy:</p>
    <ul>
        <li>
            <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        </li>
        <li>
            <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Elakay, 80 moffat St Brooklyn N.Y. 11207.</p>
        </li>
        <li>
            <p><strong>Service</strong> refers to the Website.</p>
        </li>
        <li>
            <p><strong>Website</strong> refers to Elakay , accessible from <Link to="https://elakay.com" rel="external nofollow noopener" target="_blank">https://elakay.com</Link></p>
        </li>
        <li> 
            <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
        </li>
        <li>
            <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
        </li>
    </ul>
        </div>
        )
    };
    ////////////////////////////////////////////////
export const YourOrderCancellationRights = () => {
    return (<div>
    <h1>Your Order Cancellation Rights</h1>
    <p>You are entitled to cancel Your Order within 30 days without giving any reason for doing so.</p>
    <p>The deadline for cancelling an Order is 30 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
    <p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
    <ul>
        <li>
            <p>By email: admin@elakay.com</p>
        </li>
        <li>
            <p>By visiting this page on our website: <Link to="https://elakay.com/help-contact" rel="external nofollow noopener" target="_blank">https://elakay.com/help-contact</Link></p>
        </li>
        <li>
            <p>By mail: 80 Moffat St Brooklyn N.Y. 11207</p>
        </li>
    </ul>
    <p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
          </div>
        )
    };

    ////////////////////////////////////////////////////////////////
export const ConditionsReturns = () => {
    return (<div>
    <h1>Conditions for Returns</h1>
    <p>In order for the Goods to be eligible for a return, please make sure that:</p>
    <ul>
        <li>The Goods were purchased in the last 30 days</li>
        <li>The Goods are in the original packaging</li>
        <li>The Goods were not used or damaged</li>
    </ul>
    <p>The following Goods cannot be returned:</p>
    <ul>
        <li>The supply of Goods made to Your specifications or clearly personalized.</li>
        <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
        <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
        <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
    </ul>
    <p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
          </div>
        )
    };
    
    //////////////////////////////////////////////////////
export const ReturningGoods = () => {
    return (<div>
    <h1>Returning Goods</h1>
    <p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
    <p>80 Moffat St Brooklyn N.Y. 11207</p>
    <p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
    </div>
    )
};
    
    /////////////////////////////////////////////////////////
    export const Exchanges = () => {
    return (<div>
    <h1>Exchanges</h1>
    <p>If you need to exchange a product for the same one, contact us. Please note that We only replace Goods you received defective or damaged.</p>
          </div>
        )
    };
    //////////////////////////////////////////////////////////
export const Gifts = () => {
    return (<div>
    <h1>Gifts</h1>
    <p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
    <p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
    <h2>Contact us</h2>
    <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
    <ul>
        <li>
            <p>By email: admin@elakay.com</p>
        </li>
        <li>
            <p>By visiting this page on our website: <Link to="https://elakay.com/help-contact" rel="external nofollow noopener" target="_blank">https://elakay.com/help-contact</Link></p>
        </li>
        <li>
            <p>By mail: 80 Moffat St Brooklyn N.Y. 11207</p>
        </li>
    </ul>
          </div>
        )
    };