import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { Adminstrator} from "../pageInside/Rolle"

const PrivateRoute = ({
    redirectPath = "/your-step",
    children,
}) => {

    let location = useLocation()
    if (!Adminstrator()) {
        return <Navigate
            to={redirectPath} replace state={{ from: location }}
        />;
    }
    <Outlet />
    return children;
}

export default PrivateRoute;
