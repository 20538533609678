import React from 'react';

const IncomePrice = ({
    products,
    taxSell = 1,
    taxAdmin = 1
}) => {

    const newPrice = (
        (taxSell * taxAdmin * products.count * (products.price * (100 - products.salePrice) / 100))
    )


    // const thePrice = products.salePrice > 0 ? newPrice : products.price

    return (
            (newPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })

    )
}

export default IncomePrice