import React,{useState}  from 'react';
import Tabs from 'react-bootstrap/Tabs' 
import Tab from 'react-bootstrap/Tab'

export const tabElakay =(a, ek, t)=>{
    return <Tab eventKey={ek} title={t}>{a}</Tab>
}

const TabsElakay =(props) => {
    const{
        children,
        eClass,
        eStyle,
        title,
        IDC ="controlled-tab-example"
        
    } = props
    const [key, setKey] = useState(title);

    return <Tabs id={IDC}  activeKey={key}  onSelect={(k) => setKey(k)} className={eClass} style={eStyle}>{children}</Tabs>
  
}

export default TabsElakay