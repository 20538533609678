import React from 'react';
import MenuUser from '../../includes/menu/menuUser';
import './SideDrawer.css';


const SiderDrawer = props => { 
    
    let drawerClass = 'side-drawer';
    if(props.show){
        drawerClass = 'side-drawer open';
    }
   return(   
    <nav className={drawerClass}>
        <MenuUser 
        infoSource ={props.infoSource}
        // readUser={props.infoSource.readUser} 
        userInfo={props.infoSource.userInfo} 
        click={props.click} 
        />
    </nav>
)};

export default SiderDrawer;