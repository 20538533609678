import React, { useState} from "react";
import Layout from "../Layout/Layout"; 
import { signout, isAuthenticated} from "../auth";
import { sendMessageHelp } from "../auth/apiMessage";
import Modal from 'react-bootstrap/Modal'
import { laguangeElakay } from "../pageInside/dataElakay";
import {    
        privacyPolicy, 
        interpretationDefinitions, 
        CollectingUsingYourPersonalData,
        DetailedInformationProcessingYourPersonalData,
    YourCaliforniaPrivacyRights,
    LinksOtherWebsites,
    ChangesthisPrivacyPolicy,
    ContactUs,
    TermsConditions,
    ReturnAndRefundPolicy,
    InterpretationDefinitions,
    YourOrderCancellationRights,
    ConditionsReturns,
    ReturningGoods,
    Exchanges,
    Gifts,
     }  from '../pageInside/conditionTherm'





const Message = (props) => {
    const {
        infoSource
        } = props

    const [showContactUs, setShowContactUs] = useState(false);
    const { token, a } = isAuthenticated();
    const [values, setValues] = useState({
        userReceived: "5ee5a77cc9ed3415300c10b2",
        userSend: a,
        tilte: "",
        description: ""
    });
    const {
        userReceived,
        userSend,
        tilte,
        description
    } = values;


    const handleChange = name => event => {
        const value = event.target.value;
        setValues({ ...values, [name]: value});
    };
    
    const clickSubmit = () => {

        sendMessageHelp(token, { userReceived, userSend, tilte, description}).then(data => {
            if (!data) {
                console.log("data Not Found");
            } else {
                setValues({
                    ...values,
                    userId: "",
                    tilte: "",
                    description: ""
                });
                setShowContactUs(false)
            }
        });
    };

    const newPostForm = () => (
            <div className="container">
                <div className="boxCategory1 text-center" id="heading">
                </div>
            <form className="mb-3">
                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('title', infoSource.languageAPI).title}</label>
                        <input 
                        onChange={handleChange('tilte')} 
                        type="text" 
                        className="form-control"
                        value={tilte} 
                         />
                    </div>

                    <div className="form-group">
                        <label className="text-muted">{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).description}</label>
                        <textarea 
                        onChange={handleChange('description')} 
                        className="form-control" 
                        rows="6"
                        value={description} 
                        />
                    </div>
                </form>        
                <button onClick={() => clickSubmit()} className="btn btn-primary float-right">{infoSource.laguangeElakay('Button', infoSource.languageAPI).next}</button>
            </div>
        )
    const printListeShop = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="xl"
                    show={showContactUs}
                    onHide={() => setShowContactUs(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                          {infoSource.laguangeElakay('title', infoSource.languageAPI).messageElakayAdmin}
          </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {newPostForm()}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
    return (
        <Layout
            infoSource ={infoSource}
            title="Home Page"
            description="Node React E-commerce App"
            className="container-fluid"
            loading={false}
            SliderPunShow={false}
        >
            <div style={infoSource.styleNav}>
        <div className="p-2">
                {/* {termCondition1()} */}
                {privacyPolicy()} 
                {interpretationDefinitions()}
                {CollectingUsingYourPersonalData()}
                {DetailedInformationProcessingYourPersonalData()}
                {YourCaliforniaPrivacyRights()}
               { LinksOtherWebsites()}
                {ChangesthisPrivacyPolicy()}
               { ContactUs()}
              {  TermsConditions()}
                {ReturnAndRefundPolicy()}
               { InterpretationDefinitions()}
              { YourOrderCancellationRights()}
               { ConditionsReturns()}
             { ReturningGoods()}
               { Exchanges()}
            { Gifts()}
                {printListeShop()}

        </div>
    </div>
    </Layout>
    );
};

export default Message;