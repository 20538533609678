import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import Layout from "../Layout/Layout";
import { 
    getAddressById, 
    isAuthenticated, 
    getOrderWalletSeller, 
    getOrderWalletAdmin, 
    getElakayIncomme} from "../auth";
import Form from 'react-bootstrap/Form'
import Accordion from 'react-bootstrap/Accordion'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { getListBankAccounts, CreatBankAccounts, ListaccountsOrcreateStripeAccount, listePM_createStripeCusTomer, updateCustomer, DeletePaymentMethod, updatePayment, CustomersRetrieve, postCreateTransfer } from "../auth/stripe"
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import { REACT_PK} from "../../config";
import StripeError from "../pageInside/StripeError"
import CardSetupForm from "../pageInside/Stripe/stripeIndex";
import EditSetupForm from "../pageInside/Stripe/editCardForm";
import Table from 'react-bootstrap/Table';
import { Family, 
        Adminstrator, 
        Investment,
        Assoociate,
        Seller,
    } from "../pageInside/Rolle"
import Price from "../pageInside/productsPrice"
import FormBC from "../pageInside/FormBC"
import FormEBC from "../pageInside/FormEBC"
import IncomePrice from "../pageInside/incomePrice"
import IncomeTotalPrice from "../pageInside/incomeTotalPrice"
import { AiFillBank } from "react-icons/ai";
import { laguangeElakay } from "../pageInside/dataElakay";
import CreditCardType from "../pageInside/creditCardType"
import FiltersIncomme from "../pageInside/filtersIncomme";
import {taxProductArea} from "../auth/apiProduct";
import {
    ItemsOrder,
    prodDiscount,
    prodPrice,
    prodFrService, 
    prodFrVente,
    prodFrRef,
    prodDisAdmin,
    TaxOrder,
    getTotal,
    prodFrTransaction,
    totalFrais,
    inComeSeller,
    elakayFrais,
    calProfit
} from '../auth/allPriceFeet'


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const promise = loadStripe(REACT_PK);

const Wallet = (props) => {
    const {
        infoSource
        } = props

   const query = useQuery();  

        let filorder = {
            orderStatus: 'none',
            orderDate: 'months',
            incomme: 0,
            myd: 6
        }
    const [limit, setLimit] = useState(20);
    const [skip, setSkip] = useState(0);
    const [sizeOrders, setSizeOrders] = useState(0);
    const [filtOrders, setFiltOrders] = useState(filorder);
    const [showFilterOrder, setShowFilterOrder] = useState(false);


    const [loading, setLoading] = useState(true);
    const [loadingB, setLoadingB] = useState(false);  
    const [AddBankAcc, setAddBankAcc] = useState(false);
    const [AddExBankAcc, setAddExBankAcc] = useState(false);
    const [transferMoneyShow, setTransferMoneyShow] = useState(false);
    const [addCards, setAddCards] = useState(false);
    const [bankAccountsList, setBankAccountsList] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [editCardsInfo, setEditCardsInfo] = useState();
    const [editCards, setEditCards] = useState(false);
    const [show, setShow] = useState(false);
    const [customersRe, setCustomersRe] = useState([]);
    const [walletSeller, setWalletSeller] = useState([]);
    const [walletAdmin, setWalletAdmin] = useState([]);
    const [paymentSeller, setPaymentSeller] = useState([]);
    const [paymentAdmin, setPaymentAdmin] = useState([]);
    const [walletAdminMonney, setWalletAdminMonney] = useState([]);
    const [walletSellMonney, setWalletSellMonney] = useState([]);
    const [getACCStripe, setACCStripe] = useState([]);
    const [orderStatus, setOrderStatus] = useState();
    const [countrySel, setcountrySel] = useState();
    const { token } = isAuthenticated();
    
    const [pagesWallet, setPagesWallet] = useState({
        paymentL: true,
        bankingL: false,
        incommeL : false
    })

    const {
        paymentL,
        bankingL,
        incommeL
        } = pagesWallet

        const {
            address
        }= infoSource
const incommeList = ['Incomme as Seller', 'Incomme for my Seller', 'Incomme for my Provider', 'Incomme for my Associate', 'Incomme for my Investment', 'Incomme for my Administrator', 'Incomme for my Familly', 'all Incomme']

    useEffect(() => {
        query.get("addCard") && setAddCards(true)
        Customercreate(token)
        laodbankAccountsList(token)
        loadCustomersRetrieve(token)
        laodOrderWalletSeller(token,  limit, filtOrders)
        // laodOrderWalletAdmin(orderStatus)
        // laodOrderWalletAdminMonney()
        // laodOrderWalletSellerMonney()
        laodAdminPaymentOrder()
        laodSellerPaymentOrder()
        AccountStripe()
    }, [token, orderStatus, token,  limit, filtOrders]);


    const AccountStripe = () => {
        Family() && (
            ListaccountsOrcreateStripeAccount(token).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setACCStripe(data)
                }
            })
        )
    }
const newOrderPro = async (data)=>{
    let additionalData = await taxProductArea(data.prod_User_Address.State, data.prod_User_Address.postalCode)
    if (additionalData) {
        data.taxprodZone = additionalData.zoneTax; // Ajout du nouveau champ
    } 
    return data
}
    // const laodOrderWalletSellerMonney =()=>{
    //     Seller() && (
    //         getOrderWalletSeller(token, "Delivered").then(data =>{
    //             if (data.error) {
    //                 console.log("data Not Found");
    //             } else {
    //                 setWalletSellMonney(data)

    //             }
    //         })
    //     )
    // }

    // const laodOrderWalletAdminMonney = () => {
    //     Adminstrator() && (
    //         getOrderWalletAdmin(token, "Delivered").then(data => {
    //             if (data.error) {
    //                 console.log("data Not Found");
    //             } else {
    //                 setWalletAdminMonney(data)

    //             }
    //         })
    //     )
    // }

    const laodOrderWalletSeller = (token, limit, filtOrders) => {
        Seller() && (
            getElakayIncomme(token, skip, parseInt(limit), '', filtOrders).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                } else {
                    setWalletSeller(data)

                }
            })
        )
    }

    const laodAdminPaymentOrder = () => {
        Seller() && (
            getOrderWalletSeller(token, "Delivered", true).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                } else {
                    setPaymentSeller(data)

                }
            })
        )
    }

    const laodSellerPaymentOrder = () => {
        Adminstrator() && (
            getOrderWalletAdmin(token, "Delivered", true).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                } else {
                    setPaymentAdmin(data)

                }
            })
            )
    }

    const Customercreate = (token) => {
        token && (
            listePM_createStripeCusTomer(token).then(data => {
                if (data.error) {
                    console.log("bad connection")
                    setShow(true)
                } else {
                    setPaymentMethods(data)
                    setLoading(false)
                }
            }));

    };
    const laodbankAccountsList = (token) => {
        token && (
            getListBankAccounts(token).then(data => {
                if (data.error) {
                    console.log("bad connection")
                    setShow(true)
                } else {
                    setBankAccountsList(data)
                }
            }));

    };

    const loadCustomersRetrieve = (token) => {
        token && (
            CustomersRetrieve(token).then(data => {
                if (data.error) {
                    console.log("bad connection")
                    setShow(true)
                } else {
                    setCustomersRe(data)
                }
            }));

    };

    const buttonDefaultCard = (PM_id)=>{
        token && (
        updateCustomer(token, { invoice_settings: { default_payment_method: PM_id }}).then(data => {
            if (data.error) {
                console.log("bad connection")
            } else {
                Customercreate(token)
            }
        })
        )
    }

    const DelPaymentMethod = (PM_id) => {
       
            DeletePaymentMethod(token, {PM_id}).then(data => {
                if (data.error) {
                    console.log("bad connection")
                } else {
                    Customercreate(token)
                }
            });

    };
    const AccountBank = (token) => {
        token && (
            CreatBankAccounts(token).then(data => {
                if (data.error) {
                    console.log("data Not Found");
                } else {
                    setACCStripe(data)
                }
            })
        )
    }

    const ButtonEdit =(CV, K)=>{
        setEditCards(K)
        setEditCardsInfo(CV)

    }


   const cardSCreditDetail =()=>(
       paymentMethods.length > 0 && (
       <div style={{ overflow: "auto" }}>
           <h5>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).cards}</h5>
           <Accordion>
               {
                   paymentMethods.map((CV, i)=>(

                       <Accordion.Item key={i} eventKey={CV.id}>

                             <Accordion.Header>
                                   <h6 className="m-0 p-0">{`${CV.card.brand}  ....${CV.card.last4}--`}</h6>
                                   <p className="m-0 p-0">{`Expires ${CV.card.exp_month}/${CV.card.exp_year}`}</p>
                            </Accordion.Header>
                   
                           <Accordion.Body>
                               <>
                          <div className="row">
                            <div className="col col-12 col-md-6" >
                                <CreditCardType  
                                    cardsDetail ={CV.card}    
                                    last4 = {CV.card.last4} 
                                    brand = {CV.card.brand}
                                    exp_month = {CV.card.exp_month}
                                    exp_year =  {CV.card.exp_year}
                                    name={CV.billing_details.name}
                                   />  
                              </div>
                            <div className="col col-12 col-md-6" >
                                           <h4>{infoSource.laguangeElakay('title', infoSource.languageAPI).billingAddress}</h4>
                                           <h6>{CV.billing_details.name}</h6>
                                           <h6>{CV.billing_details.address.line1}</h6>
                                           <h6>{CV.billing_details.address.city}</h6>
                                           <h6>{`${CV.billing_details.address.state}, ${CV.billing_details.address.country}, ${CV.billing_details.address.postal_code}`}</h6>

                            </div>

                          </div> 
                            <div className="row mt-2">
                            <div className="col col-12 col-md-7" >
                                           <Button variant="primary" onClick={() => DelPaymentMethod(CV.id)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).delete}</Button>
                                           <Button variant="primary" onClick={() => ButtonEdit(CV, true)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}</Button>
                                </div>  
                                       <div className="col col-12 col-md-5" >
                                           <Link to="#" variant="link" onClick={() => buttonDefaultCard(CV.id)}>{infoSource.laguangeElakay('Link', infoSource.languageAPI).setDefault}</Link>
                                       </div>
                          </div>
                         
                          </>
                           </Accordion.Body>
                       </Accordion.Item>
                   ))
               }
           </Accordion>
       </div>
       )
   )

    const banksDetailList = () => (
        bankAccountsList.length > 0 && ( 
            <div style={{ overflow: "auto" }}>
                <h5>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).banks}</h5>
                {
                    bankAccountsList.map((CV, i) => (
                        <div key={i} className="p-1 mb-1 bg-light">
                            <AiFillBank className="m-2 h2"/> 
                            <span className="h6">{CV.bank_name} </span>
                            <span> ...{CV.last4}</span>

                            <div className="row mt-2">
                                <div className="col col-12 col-md-7" >
                                    <Button variant="primary" onClick={() => DelPaymentMethod(CV.id)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).delete}</Button>
                                    <Button variant="primary" onClick={() => ButtonEdit(CV, true)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}</Button>
                                </div>
                                <div className="col col-12 col-md-5" >
                                    <Link to={"#"} variant="link" onClick={() => buttonDefaultCard(CV.id)}>{infoSource.laguangeElakay('Link', infoSource.languageAPI).setDefault}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
 
            </div>
        )
    )



    const PaymentStri = () => (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-11 col-lg-10 col-xl-9" style={{ border: "1px solid Gainsboro" }} >

            <Elements stripe={promise}>
                <CardSetupForm
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    address={address}
                    token={token}
                    getAddressById={getAddressById}
                    setAddCards={setAddCards}
                    Customercreate={Customercreate}
                />
            </Elements>
        </div>
        </div>
    )

    const AddBankAccount = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="lg"
                    show={AddBankAcc}
                    onHide={() => setAddBankAcc(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).addBankAccount}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {formSelect()}
                        <FormBC
                            laguangeElakay={laguangeElakay}
                            infoSource ={infoSource}
                            country = {countrySel}
                            setAddBankAcc={setAddBankAcc}
                            isAuthenticated={isAuthenticated}
                            setcountrySel={setcountrySel}
                        />
                      
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
    const AddExBankAccount = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="lg"
                    show={AddExBankAcc}
                    onHide={() => setAddExBankAcc(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).addExBankAccoounts}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {formSelect()}
                        <FormEBC
                            laguangeElakay={laguangeElakay}
                            infoSource ={infoSource}
                            country={countrySel}
                            setAddExBankAcc={setAddExBankAcc}
                            isAuthenticated={isAuthenticated}
                            setcountrySel={setcountrySel}

                        />

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )

    const transferMoney = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="dm"
                    show={transferMoneyShow}
                    onHide={() => setTransferMoneyShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).moneyTransfer}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5> <span onClick={() => setAddBankAcc(true)}>{infoSource.laguangeElakay('title', infoSource.languageAPI).seller}</span></h5> 
                        <h5> <span onClick={() => setAddBankAcc(true)}>{infoSource.laguangeElakay('title', infoSource.languageAPI).Admin}</span></h5>


                        <h5>{infoSource.laguangeElakay('label', infoSource.languageAPI).amount}: <span>{PaymentWallet(paymentSeller, paymentAdmin)}</span></h5>
                        <div className="form-group mt-4">
                            <button className="btn btn-outline-primary">{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>
                            <button className="btn btn-outline-primary">
                                <span >{infoSource.laguangeElakay('Button', infoSource.languageAPI).transfer}</span>
                            </button>
                        </div> 
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )

    const SaveCard = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="lg"
                    show={addCards}
                    onHide={() => setAddCards(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).addPaymentMethods}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>          
                        {PaymentStri()}   
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )

    const countryChange = () => event => {
        const value = event.target.value;
        setcountrySel(value)
    }

    const formSelect = () => (
        <div className="row justify-content-center m-4" >
            <div className="col col-12 col-md-8 col-lg-8 col-xl-6 p-2" style={{ border: "1px solid WhiteSmoke" }}>
                <Form>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>{infoSource.laguangeElakay('label', infoSource.languageAPI).customSelect}</Form.Label>
                        <Form.Select onChange={countryChange()} aria-label="Default select example">
                        <option value="Selected" >{infoSource.laguangeElakay('label', infoSource.languageAPI).selCountry}</option>
                        <option value="CA">Canada</option>
                        <option  value="US">United States</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </div></div>
    )

    const EditCard = () => (
        <div className="contaire">
            <div className="row justify-content-center " >
                <Modal
                    size="lg"
                    show={editCards}
                    onHide={() => setEditCards(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            <h2>{infoSource.laguangeElakay('title', infoSource.languageAPI).editCards}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mt-4 mb-4 justify-content-center">
                            <div className="col col-12 col-md-11 col-lg-10 col-xl-9" style={{ border: "1px solid Gainsboro" }} >
                                <EditSetupForm 
                                    laguangeElakay={laguangeElakay}
                                    infoSource ={infoSource}
                                    address={address}
                                    token={token}
                                    getAddressById={getAddressById}
                                    setEditCards={setEditCards}
                                    Customercreate={Customercreate}
                                    updatePayment={updatePayment}
                                    PM={editCardsInfo}
                                />
                              
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )

    const ButtonPaymentL = () => {
        setPagesWallet({
            ...pagesWallet,
            paymentL: true,
            bankingL: false,
            incommeL: false
        })
    }

    const ButtonBankingL = () => {
        setPagesWallet({
            ...pagesWallet,
            paymentL: false,
            bankingL: true,
            incommeL: false
        })
    }
    const ButtonIncommeL = () => {
        setPagesWallet({
            ...pagesWallet,
            paymentL: false,
            bankingL: false,
            incommeL: true
        })
    }

    let StyleMenu0 = {
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
        borderBottom: "None",
        backgroundColor: "GhostWhite"
    }

    let StyleMenu = {
        borderBottom: "6px solid Gainsboro",
        backgroundColor: "AliceBlue"
    }

    let paymentStyle = paymentL ? StyleMenu : StyleMenu0

    let bankingStyle = bankingL ? StyleMenu : StyleMenu0

    let incommeStyle = incommeL ? StyleMenu : StyleMenu0

    const WalletMenu = () => (

        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" >
                <div className="col col-12 col-md-12" style={{ textAlign: "center"}}>
                    <div className="row">
                        <div className="col col-12 col-md-4" style={paymentStyle} onClick={ButtonPaymentL}>
                            <p className="m-2">{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).setUpPayments}</p>
                        </div>
                        {Seller() && (
                            <>
                        <div className="col col-12 col-md-4" style={bankingStyle} onClick={ButtonBankingL}>
                            <p className="m-2">{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).BankingPayouts}</p>
                        </div>
                        <div className="col col-12 col-md-4" style={incommeStyle} onClick={ButtonIncommeL}>
                            <p className="m-2">{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).viewIncomePayout}</p>
                        </div>
                        </>
                        )}
                </div>

                </div>
         {  incommeL && <div className="col col-12 col-md-12" >
            <div className="btn btn-dark btn-md py-1 rounded-4" onClick={()=>setShowFilterOrder(true)}>Filters</div> 
        </div>}
            </div>
            
        </div>
    )
    const UserDetails = () => (
        paymentL && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).addMethPayment}</h3>
                </div>
                
                    {cardSCreditDetail()}

                    {banksDetailList()}

                <button onClick={() => setAddCards(true)} className="btn btn-outline-primary m-4 float-left">
                   {infoSource.laguangeElakay('Button', infoSource.languageAPI).addCard}
                </button>
                    <button className="btn btn-outline-primary m-4 float-left">
                        <span onClick={() => setAddBankAcc(true)}> {infoSource.laguangeElakay('Button', infoSource.languageAPI).addBank}</span>
                    </button>
            </div>
        </div>
     ) )


    const UserDetailsDD = () => (
        Seller() &&  bankingL && (
            <>
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                    <h1>{PaymentWallet(paymentSeller, paymentAdmin)}</h1>

                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).setUpPayments}</h3>
                </div>

                <button onClick={() => setAddExBankAcc(true)} className="btn btn-outline-primary m-4 float-left">
                    {infoSource.laguangeElakay('Button', infoSource.languageAPI).addBank}
                </button>
                    <button onClick={() => setTransferMoneyShow(true)}  className="btn btn-outline-primary m-4 float-left">
                  {infoSource.laguangeElakay('Button', infoSource.languageAPI).transfer}
                </button>
            </div>
        </div>
        </>
    ))

    const MonneyWalletViewSell =()=>(   
        Seller() && incommeL && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).viewincommeSell}</h3>
                </div>
                <div style={{ overflow: "auto" }}>
                    <Table striped bordered hover>
                        <tbody >
                            <tr>
                                <th>#</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).name}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).count}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).productPrice}</th>   
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).productSale}</th>                      
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).incomeSeller}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).transFeet}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).feet}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).totalFeet}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).profit}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).loss}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).statut}</th>
                            </tr>
                         {    
                        walletSeller.My_Order.map((order, i)=>{
                           return (
                              <>
                             
                               <tr>
                                   <td style={{ margin: "2px" }}>
                                    {i+1}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                    {order.prod_name}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                       {order.products.count}
                                   </td>
                                   <td style={{ margin: "2px" }}>

                                  {/* { (ItemsOrder([order])).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })} */}
                                 <Price
                                     showAddToCartButton={false}
                                     products={{price: order.products.price, salePrice: order.products.salePrice}}
                                 />
                                </td>
                                <td style={{ margin: "2px" }}>
                                   {getTotal([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {inComeSeller([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {prodFrTransaction([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {elakayFrais([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {totalFrais([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {calProfit([order], 0, order.prod_costPrice).profit.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {calProfit([order], 0, order.prod_costPrice).loss.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {calProfit([order], 0, order.prod_costPrice).statut.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                               
                                
                            </tr>
                            </>
                           )
                        }) }  
                            <tr>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}> 
                                    TOTAL:
                                </td>
                                <td style={{ margin: "2px" }}>
                                { (ItemsOrder(walletSeller.My_Order)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                </td>
                                <td style={{ margin: "2px" }}>
                                   {getTotal(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                <td style={{ margin: "2px" }}>
                                {inComeSeller(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                </td>
                                <td style={{ margin: "2px" }}>
                                   {prodFrTransaction(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                   <td style={{ margin: "2px" }}>
                                   {elakayFrais(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                                <td style={{ margin: "2px" }}>
                                   {totalFrais(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                   </td>
                            </tr>
                        </tbody>
                </Table>
                </div>


            </div>
        </div>
    ))

    const MonneyAsAss = () => (
        Assoociate(true) && incommeL && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>Incomme as Associate</h3>
                </div>
                <div style={{ overflow: "auto" }}>
                    <Table striped bordered hover>
                        <tbody >
                            <tr>
                                <th>#</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).name}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).count}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).productPrice}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).productSale}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeSeller}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeProvider}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).totalIncome}</th>
                            </tr>
                            {
                                walletSeller.My_Order.map((order, i) => {
                                    return (
                                        <tr key={i}>
                                           <td style={{ margin: "2px" }}>
                                    {i+1}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                    {order.prod_name}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                       {order.products.count}
                                   </td>
                                            <td style={{ margin: "2px" }}>
                                            <Price
                                     showAddToCartButton={false}
                                     products={{price: order.products.price, salePrice: order.products.salePrice}}
                                 />
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodDisAdmin([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef([order], 0) + prodDisAdmin([order], 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                    {infoSource.laguangeElakay('label', infoSource.languageAPI).total}:
                                </td>
                                <td style={{ margin: "2px" }}>
                                { (ItemsOrder(walletSeller.My_Order)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodDisAdmin(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef(walletSeller.My_Order, 0) + prodDisAdmin(walletSeller.My_Order, 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                            </tr>
                  { walletSeller.MyUserIncomme.length > 0 &&   <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td colspan="2" style={{ margin: "2px" }}>
                                    incomme As Associate
                                </td>
                                <td colspan="3" style={{ margin: "2px" }}>
                                { (walletSeller.MyUserIncomme[0].count * 0.03).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                </td>
                            </tr>}
                        </tbody>
                    </Table>
                </div>


            </div>
        </div>
        )
    )

    const MonneyAsInv = () => (
        Investment(true) && incommeL && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>Incomme As Investment</h3>
                </div>
                <div style={{ overflow: "auto" }}>
                    <Table striped bordered hover>
                        <tbody >
                            <tr>
                                <th>#</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).name}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).count}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).productPrice}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).productSale}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeSeller}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeProvider}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).totalIncome}</th>
                            </tr>
                            {
                                walletSeller.My_Order.map((order, i) => {
                                    return (
                                        <tr key={i}>
                                           <td style={{ margin: "2px" }}>
                                    {i+1}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                    {order.prod_name}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                       {order.products.count}
                                   </td>
                                            <td style={{ margin: "2px" }}>
                                            <Price
                                     showAddToCartButton={false}
                                     products={{price: order.products.price, salePrice: order.products.salePrice}}
                                 />
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodDisAdmin([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef([order], 0) + prodDisAdmin([order], 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                        </tr>
                                    )
                                })}
                          
                                <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                    {infoSource.laguangeElakay('label', infoSource.languageAPI).total}:
                                </td>
                                <td style={{ margin: "2px" }}>
                                { (ItemsOrder(walletSeller.My_Order)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodDisAdmin(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef(walletSeller.My_Order, 0) + prodDisAdmin(walletSeller.My_Order, 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                            </tr>
                 { walletSeller.MyUserIncomme.length > 0 &&   <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td colspan="2" style={{ margin: "2px" }}>
                                    incomme As investiment:
                                </td>
                                <td colspan="3" style={{ margin: "2px" }}>
                                { (walletSeller.MyUserIncomme[0].count * 0.05).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                </td>
                            </tr>}
                        </tbody>
                    </Table>
                </div>


            </div>
        </div>
        )
    )

    const MonneyAsAdmin = () => (
        Adminstrator(true) && incommeL && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>Incomme As Administrator</h3>
                </div>
                <div style={{ overflow: "auto" }}>
                    <Table striped bordered hover>
                        <tbody >
                            <tr>
                                <th>#</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).name}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).count}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).productPrice}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).productSale}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeSeller}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeProvider}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).totalIncome}</th>
                            </tr>
                            {
                                walletSeller.My_Order.map((order, i) => {
                                    return (
                                        <tr key={i}>
                                           <td style={{ margin: "2px" }}>
                                    {i+1}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                    {order.prod_name}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                       {order.products.count}
                                   </td>
                                            <td style={{ margin: "2px" }}>
                                            <Price
                                     showAddToCartButton={false}
                                     products={{price: order.products.price, salePrice: order.products.salePrice}}
                                 />
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}  </td>
                                  <td style={{ margin: "2px" }}>
                                            {prodDisAdmin([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef([order], 0) + prodDisAdmin([order], 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                    {infoSource.laguangeElakay('label', infoSource.languageAPI).total}:
                                </td>
                                <td style={{ margin: "2px" }}>
                                { (ItemsOrder(walletSeller.My_Order)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}</td>
                           <td style={{ margin: "2px" }}>
                                            {prodDisAdmin(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef(walletSeller.My_Order, 0) + prodDisAdmin(walletSeller.My_Order, 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                        
                            </tr>
                 { walletSeller.MyUserIncomme.length > 0 &&  <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td colspan="2" style={{ margin: "2px" }}>
                                    incomme As  Administrator
                                </td>
                                <td colspan="3" style={{ margin: "2px" }}>
                                { (walletSeller.MyUserIncomme[0].count * 0.07).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                </td>
                            </tr>}
                        </tbody>
                    </Table>
                </div>


            </div>
        </div>
        )
    )

    const MonneyAsFamil = () => (
        Family() && incommeL && (
        <div className="row mt-4 mb-4 justify-content-center">
            <div className="col col-12 col-md-10 col-lg-9 col-xl-8 p-0" style={{ border: "1px solid Gainsboro" }} >
                <div className="col col-12 col-md-12" style={{ textAlign: "center", backgroundColor: "WhiteSmoke" }}>
                    <h3>Incomme As Familly</h3>
                </div>
                <div style={{ overflow: "auto" }}>
                    <Table striped bordered hover>
                        <tbody >
                            <tr>
                                <th>#</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).name}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).count}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).productPrice}</th>
                                <th>{infoSource.laguangeElakay('sellPage', infoSource.languageAPI).productSale}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeSeller}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).inComeProvider}</th>
                                <th>{infoSource.laguangeElakay('walletPage', infoSource.languageAPI).totalIncome}</th>
                            </tr>
                            {
                                walletSeller.My_Order.map((order, i) => {
                                    return (
                                        <tr key={i}>
                                           <td style={{ margin: "2px" }}>
                                    {i+1}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                    {order.prod_name}
                                </td>
                                   <td style={{ margin: "2px" }}>
                                       {order.products.count}
                                   </td>
                                            <td style={{ margin: "2px" }}>
                                            <Price
                                     showAddToCartButton={false}
                                     products={{price: order.products.price, salePrice: order.products.salePrice}}
                                 />
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal([order]).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodDisAdmin([order], 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef([order], 0) + prodDisAdmin([order], 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                    {infoSource.laguangeElakay('label', infoSource.languageAPI).total}:
                                </td>
                                <td style={{ margin: "2px" }}>
                                { (ItemsOrder(walletSeller.My_Order)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {getTotal(walletSeller.My_Order).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodFrRef(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            {prodDisAdmin(walletSeller.My_Order, 0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                                            <td style={{ margin: "2px" }}>
                                            { (prodFrRef(walletSeller.My_Order, 0) + prodDisAdmin(walletSeller.My_Order, 0)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                            </td>
                            </tr>

                     {  walletSeller.MyUserIncomme.length > 0 &&  <tr className="text-danger h4">
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td style={{ margin: "2px" }}>
                                </td>
                                <td colspan="2" style={{ margin: "2px" }}>
                                    incomme As Familly:
                                </td>
                                <td colspan="3" style={{ margin: "2px" }}>
                                { (walletSeller.MyUserIncomme[0].count * 0.30).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                                </td>
                            </tr>}
                        </tbody>
                    </Table>
                </div>


            </div>
        </div>
        )
    )



    const PaymentWallet = (seller, admin) => {
        let priceSell
        let priceAdmin
        let total
        Seller ?
            priceSell = seller.reduce((currentValue, nextValue) => {
                return currentValue + (nextValue.count * 0.97 * (nextValue.price * (100 - nextValue.salePrice) / 100))
            }, 0)
            :
            priceSell = 0

        Adminstrator ?
            priceAdmin = admin.reduce((currentValue, nextValue) => {
                return currentValue + (nextValue.count * 0.33 * 0.03 * (nextValue.price * (100 - nextValue.salePrice) / 100))
            }, 0)
            :
            priceAdmin = 0

        total = priceSell + priceAdmin

        return <span className="text-muted">
            {(total).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })}
        </span>

    }

    const filterOrdersView = ()=>{
        return <Modal
        size="md"
        show={showFilterOrder}
        onHide={() => setShowFilterOrder(false)}
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            <h2>
                {/* Orders Filters */}
                {infoSource.language(infoSource, "title", "ordFilt")}
                </h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <FiltersIncomme 
         laguangeElakay={laguangeElakay}
         infoSource ={infoSource}
         limit={parseInt(limit)}
         setLimit= {setLimit}
         setFiltOrders={setFiltOrders}
         setShowFilterOrder={setShowFilterOrder}
         filtOrders={filtOrders}
        />

        </Modal.Body>
    </Modal>

    }

    return (
        <Layout
        infoSource ={infoSource}
            title="Home Page"
            className="container-fluid"
            loading={loading}
            SliderPunShow={false}
            setLoading={false}
        >
        <div style={infoSource.styleNav}>
            {WalletMenu()}
            {UserDetails()}
            {UserDetailsDD()}
            {MonneyWalletViewSell()}

            { MonneyAsAss()}
            {MonneyAsInv()}
            {MonneyAsAdmin()}
            {MonneyAsFamil()}
            {AddBankAccount()}
            {AddExBankAccount()}
            {SaveCard()}
            {EditCard()} 
            {filterOrdersView()}
            {transferMoney()}
                <StripeError
                    laguangeElakay={laguangeElakay}
                    infoSource ={infoSource}
                    show={show}
                    setShow={setShow}
                />

                < CreditCardType
                />
                </div>
        </Layout>
    );
};

export default Wallet;