import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { getUserViewed } from "../auth";
import Card from '../pageInside/Card'
import { Row } from 'react-bootstrap';
import {isAuthenticated } from "../auth";
import { prices } from "../pageInside/fixedPrices";
import { laguangeElakay } from "../pageInside/dataElakay";

const UserVieweds = props => {
    const {
        infoSource
        } = props
    const [productsByArrival, setProductsByArrival] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token } = isAuthenticated();


    useEffect(() => {
        loadProductsByArrival(token)
    }, [token]);

    const loadProductsByArrival = token => {
        getUserViewed(token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setProductsByArrival(data.products);
                setLoading(false)
            }
        });
    };


    const productALL = (
        <Row className="m-1" xs={2} sm={2} md={3} lg={4} xl={5}>
            {
                productsByArrival.reverse().map((product, i) => (
                    <div className="col p-2" key={i}>
                    <Card
                        laguangeElakay={laguangeElakay}
                        infoSource ={infoSource}
                        classCard1={`card`}
                        product={product}
                        CataID={product.catalogID}
                        classCard0 = "col p-0"
                    />
                    </div>
                ))}

        </Row>
    )

    return (
        <Layout   
            infoSource ={infoSource}         
            loading={loading}
            className="container-fluid"
            SliderPunShow={false}
            prices={prices}
        >
           <div style={infoSource.styleNav}>
            {productALL}
           </div>
        </Layout>
    );
};

export default UserVieweds;