import React, { useState} from "react";
import Modal from 'react-bootstrap/Modal';
import AlertPage from "./alertPage";

const JsonAddLangueCat = (props)=>{
    const { 
        infoSource,
        showJsonAdd,
        setShowJsonAdd,
        submitJsonInput,
        catalogItem,
        filter = false
    }= props
    const [showAlert, setShowAlert] = useState(false);
    const [getJsonInput, setJsonInput]= useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
   let jsonTranslate =  filter ? {name: catalogItem.name,  FilterItem: catalogItem.FilterItem}  : {name: catalogItem.name}
   let id =  filter ?catalogItem.filter_id :  catalogItem.subCategory_id
    const copyToClipboard = (jsonData) => {
        const jsonStr = JSON.stringify(jsonData, null, 2);
        navigator.clipboard.writeText(jsonStr).then(() => {
          setShowAlert(true)
        }).catch((err) => {
          console.error('Erreur lors de la copie : ', err);
        });
      };

    return <Modal
    show={showJsonAdd}
    onHide={() =>setShowJsonAdd(false)}
    dialogClassName="modal-100w"
    aria-labelledby="example-custom-modal-styling-title"
>
    <Modal.Body>
    <div className="row mt-3">
        <div className="col col-12"> 
            <h5>enter the json tranlate for categories</h5>
        </div>
       {errorMessage && <div  className=" alert alert-danger" style={{  display: errorMessage ? "" : "none",  color: 'red', marginTop: '10px' }}>   
                        {errorMessage}
                        </div>}
        <AlertPage
        showAlert={showAlert} 
        setShowAlert={setShowAlert}
        />
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10">
                        <button className="m-1  btn btn-outline-dark pt-2 pb-2 pr-2 pl-2 rounded-4 float-left" onClick={()=>copyToClipboard(jsonTranslate)}>{infoSource.language(infoSource, 'Button', "copyJson")+ ' '+ 'name'}</button>
                        <button className="m-1  btn btn-outline-dark pt-2 pb-2 pr-2 pl-2 rounded-4 float-right" onClick={()=>copyToClipboard(catalogItem.lang)}>{infoSource.language(infoSource, 'Button', "copyJson")+ ' '+ 'lang'}</button>
                        </div>
                    </div>
          <textarea
        className='p-4'
          onChange={(e) => setJsonInput(e.target.value)}
          placeholder={JSON.stringify(jsonTranslate,  null, 3)}
          style={{ width: '100%', minHeight: '270px', fontFamily: 'monospace', marginBottom: '20px' }}
        />
    <div className="row justify-content-center">
            <div className="col-12 col-sm-8">
                <div className="bg clearfix ">
                <button className="m-1  btn btn-outline-dark pt-2 pb-2 pr-2 pl-2 rounded-4 float-left" onClick={()=>setShowJsonAdd(false)}>{infoSource.language(infoSource, 'Button', "cancel")}</button>
                <button className="m-1  btn btn-outline-dark pt-2 pb-2 pr-2 pl-2  rounded-4  float-right" onClick={()=>submitJsonInput({getJsonInput, setErrorMessage, id})}>{infoSource.language(infoSource, 'Button', "submit")}</button>
                </div>
            </div></div>
     </div> 
     </Modal.Body>
</Modal>
}

export default JsonAddLangueCat;