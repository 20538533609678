// import React, { useState, useEffect } from "react";
// import { Row } from 'react-bootstrap';
// import Slider from '../../slider/swiperProduct'
// import { getProductsF } from "../../auth/apiProduct";
// import { useParams } from "react-router-dom";
// import { getPubCategories , getImages} from "../../auth/apiProduct";
// import { Link} from 'react-router-dom';
// import imgProductLakay from '../../Layout/imgs/md3.jpg' 
// import Loader from "../../Layout/loader.gif";
// import { addItem, isAuthenticated} from "../../auth";

// import ShowImage from "../../pageInside/ShowImage";

// // import "../Card.css"



// const PubProducts = (props) => {
//   const { token } = isAuthenticated();
//   const {categoryID, userID, setShopUser} = props
//   const [products, setProducts] = useState([]);


//   const loadProductsBySell = (cid, uId) => {
//     getPubCategories(cid, uId, 4, 'asc', "sold").then(data => {
//       if(!data){
//           console.log("data not found");
//       }else{
//             setProducts(data.products);
//             data.products[0] && data.products[0].owner && loadShopUser(data.products[0])
//       } 
//     });
// };

// const loadShopUser =(a)=>{ 
//   const exists = a.owner.address.some(address => address.id === a.addressShop);

//   if (exists) {
//     let shopUser = a.owner.address.filter(AS =>  AS._id === a.addressShop)[0].shopName
//     setShopUser(shopUser)
//     return shopUser
//    } else {
//     if(a.owner.address.length > 0){
//        let first1 = String(a.owner.address[0].firstName).charAt(0)
//       let last1 =  String(a.owner.address[0].lastName).charAt(0)
//       let FL = first1.concat(last1).toLocaleUpperCase()
//       setShopUser(FL)
//       return FL
//     }else{
//       setShopUser('seller')
//        return 'seller'
//     }
//    }

//   // let shop = a.owner.address.filter(AS =>  AS._id === a.addressShop)[0].shopName
//   // let first1 = String(a.owner.address[0].firstName).charAt(0)
//   // let last1 = String(a.owner.address[0].lastName).charAt(0)
//   // let FL = first1.concat(last1).toLocaleUpperCase()
 
//   // let lakayUser = shop ? shop.toLocaleUpperCase(): FL
//   // setShopUser(lakayUser)
// }

// useEffect(() => {
//     loadProductsBySell(categoryID, userID);
// }, [categoryID, userID]);

// const imageShow =(p)=>{
// let urlImg = p.galeries.length > 0 && p.galeries[0].images.length > 0 ? p.galeries[0].images[0] : p.galeriesID.galeries.length > 0 ? p.galeriesID.galeries[0].urlImg  : imgProductLakay
//   return  <img  
//     className="card-img-top"
//     src={urlImg}
//     onError={event => {
//         event.target.src = imgProductLakay
//         event.onerror = null
//       }}
//     alt={p.name}
//     style={{ height: "100%", width: "100%" }}
// />
// }
// const productALL =()=>{
       
//     return <Row className="m-1"  xs={2} >
//          {
//          products && products.map((product, i) => (
//             <div className="col p-1">
//         {imageShow(product)}
//              </div>
//              ))}
//      </Row>
//  }

//  return productALL()
// }
// export default PubProducts;

import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { getPubCategories } from "../../auth/apiProduct";
import { isAuthenticated } from "../../auth";

// Tableau de 50 couleurs
const COLORS1 = [
  "#FFB3BA", // pastel rouge
  "#FFDFBA", // pastel orange
  "#FFFFBA", // pastel jaune
  "#BAFFC9", // pastel vert
  "#BAE1FF", // pastel bleu
  "#E3BAFF", // pastel violet
  "#C9C9FF", // pastel lavande
  "#D0E1F9", // pastel bleu ciel
  "#F9E2D0", // pastel pêche
  "#F7D6E0", // pastel rose
  "#DFFFE0", // pastel menthe clair
  "#E0FFE0", // pastel vert très clair
  "#E0F7FF", // pastel bleu très clair
  "#FFE0F7", // pastel magenta clair
  "#FFF0BA", // pastel citron
  "#BAFFD6", // pastel turquoise
  "#E0BAFF", // pastel lilas
  "#FFBAE0", // pastel rose saumon
  "#F0E2BA", // pastel beige
  "#D6BAFF", // pastel pervenche
  "#FFB3E6", // pastel rose fuchsia
  "#FFC8C8", // pastel rose tendre
  "#FFE1B3", // pastel abricot
  "#FFE5BA", // pastel abricot clair
  "#FFF5BA", // pastel jaune très clair
  "#BAFFD1", // pastel vert d'eau
  "#BAFFDE", // pastel vert d'eau 2
  "#BAFFEE", // pastel aqua
  "#BAF9FF", // pastel ciel
  "#BAF7FF", // pastel bleu glacial
  "#E3C0FF", // pastel violet doux
  "#E3D0FF", // pastel lavande doux
  "#D0FFFC", // pastel turquoise clair
  "#D0FFEA", // pastel aqua clair
  "#FFC0FF", // pastel fuchsia
  "#FFD1FF", // pastel rose fuchsia léger
  "#FFF0F5", // pastel lavender blush
  "#F5F5DC", // pastel beige clair
  "#FFFACD", // pastel lemon chiffon
  "#F0FFF0", // pastel honeydew
  "#F0FFFF", // pastel azure
  "#E6E6FA", // pastel lavender
  "#FFF5EE", // pastel seashell
  "#FAFAD2", // pastel light goldenrod yellow
  "#D8BFD8", // pastel thistle
  "#FFE4E1", // pastel misty rose
  "#F5DEB3", // pastel wheat
  "#FFEFD5", // pastel papaya whip
  "#FFDAB9"  // pastel peach puff
];

const COLORS = [
"Gray",              // 1
  "GhostWhite",        // 2
  "Ivory",             // 3
  "MintCream",         // 4
  "Silver",            // 5
  "White",             // 6
  "LightGray",         // 7
  "Gainsboro",         // 8
  "DimGray",           // 9
  "SlateGray",         // 10
  "DarkGray",          // 11
  "LightSlateGray",    // 12
  "LightSteelBlue",    // 13
  "AliceBlue",         // 14
  "AntiqueWhite",      // 15
  "Azure",             // 16
  "Beige",             // 17
  "Bisque",            // 18
  "BlanchedAlmond",    // 19
  "Blue",              // 20
  "BlueViolet",        // 21
  "Brown",             // 22
  "BurlyWood",         // 23
  "CadetBlue",         // 24
  "Chartreuse",        // 25
  "Chocolate",         // 26
  "Coral",             // 27
  "CornflowerBlue",    // 28
  "Cornsilk",          // 29
  "Crimson",           // 30
  "Cyan",              // 31
  "DarkBlue",          // 32
  "DarkCyan",          // 33
  "DarkGoldenRod",     // 34
  "DarkGreen",         // 35
  "DarkKhaki",         // 36
  "DarkMagenta",       // 37
  "DarkOliveGreen",    // 38
  "DarkOrange",        // 39
  "DarkOrchid",        // 40
  "DarkRed",           // 41
  "DarkSalmon",        // 42
  "DarkSeaGreen",      // 43
  "DarkSlateBlue",     // 44
  "DarkSlateGray",     // 45
  "DarkTurquoise",     // 46
  "DarkViolet",        // 47
  "DeepPink",          // 48
  "DeepSkyBlue",       // 49
  "DodgerBlue"         // 50
];

const getRandomColor = () =>
  COLORS[Math.floor(Math.random() * COLORS.length)];

const PubProducts = (props) => {
  const { categoryID, userID, setShopUser } = props;
  const [products, setProducts] = useState([]);
  // On stocke la hauteur mesurée d'une image chargée
  const [imageHeight, setImageHeight] = useState(0);

  // Chargement des produits
  const loadProductsBySell = (cid, uId) => {
    getPubCategories(cid, uId, 4, "asc", "sold").then((data) => {
      if (!data) {
        console.log("data not found");
      } else {
        setProducts(data.products);
        if (data.products[0] && data.products[0].owner) {
          loadShopUser(data.products[0]);
        }
      }
    });
  };

  // Gestion du shopUser
  const loadShopUser = (a) => {
    const exists = a.owner.address.some(
      (address) => address.id === a.addressShop
    );
    if (exists) {
      let shopUser = a.owner.address.filter(
        (AS) => AS._id === a.addressShop
      )[0].shopName;
      setShopUser(shopUser);
      return shopUser;
    } else {
      if (a.owner.address.length > 0) {
        let first1 = String(a.owner.address[0].firstName).charAt(0);
        let last1 = String(a.owner.address[0].lastName).charAt(0);
        let FL = first1.concat(last1).toLocaleUpperCase();
        setShopUser(FL);
        return FL;
      } else {
        setShopUser("seller");
        return "seller";
      }
    }
  };

  useEffect(() => {
    loadProductsBySell(categoryID, userID);
  }, [categoryID, userID]);

  // Lorsque l'image se charge, on mesure sa hauteur
  const handleImageLoad = (event) => {
    const height = event.target.clientHeight;
    // Si la hauteur n'est pas encore définie, on la stocke
    if (!imageHeight && height) {
      setImageHeight(height);
    }
  };

  // Composant qui affiche l'image d'un produit ou, en cas d'absence, un fallback
  const imageShow = (p) => {
    // Teste si une image est présente dans p.galeries
    if (
      p.galeries &&
      p.galeries.length > 0 &&
      p.galeries[0].images &&
      p.galeries[0].images.length > 0
    ) {
      const urlImg = p.galeries[0].images[0];
      return (
        <img
          className="card-img-top"
          src={urlImg}
          alt={p.name}
          style={{ height: "100%", width: "100%" }}
          onLoad={handleImageLoad}
          onError={(event) => {
            event.target.style.display = "none";
          }}
        />
      );
    }
    // Sinon, teste dans p.galeriesID.galeries
    else if (
      p.galeriesID &&
      p.galeriesID.galeries &&
      p.galeriesID.galeries.length > 0 &&
      p.galeriesID.galeries[0].urlImg
    ) {
      const urlImg = p.galeriesID.galeries[0].urlImg;
      return (
        <img
          className="card-img-top"
          src={urlImg}
          alt={p.name}
          style={{ height: "100%", width: "100%" }}
          onLoad={handleImageLoad}
          onError={(event) => {
            event.target.style.display = "none";
          }}
        />
      );
    }
    // Sinon, retourne le fallback
    else {
      return getDefaultDivImage();
    }
  };

  // Retourne un <div> de fallback avec une hauteur basée sur l'image chargée (ou une valeur par défaut)
  const getDefaultDivImage = () => {
    return (
      <div
        style={{
          height: imageHeight ? `${imageHeight}px` : "150px",
          width: "100%",
          backgroundColor: getRandomColor(),
        }}
      />
    );
  };

  // Génère la grille comportant exactement 4 cellules (2 lignes x 2 colonnes)
  const productALL = () => {
    const totalCells = 4;
    const cells = [];

    // Pour chaque produit réel, affiche son image ou le fallback
    products.forEach((product, i) => {
      cells.push(
        <div key={i} className="col p-1">
          {imageShow(product)}
        </div>
      );
    });

    // Complète avec des cellules de fallback si le nombre de produits est inférieur à 4
    const missingCount = totalCells - products.length;
    for (let j = 0; j < missingCount; j++) {
      cells.push(
        <div key={`missing-${j}`} className="col p-1">
          {getDefaultDivImage()}
        </div>
      );
    }

      // Style du conteneur principal carré
  const containerStyle = {
    width: "100%",
    maxWidth: "600px", // Vous pouvez ajuster cette valeur selon vos besoins
    margin: "auto",
    aspectRatio: "0", // Force le conteneur à être carré
    // border: "1px solid #ddd", // Optionnel, juste pour visualiser le contour
  };

  // Style de la grille 2x2 qui occupe 100% du conteneur
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gap: "0px", // Optionnel, espace entre les cellules
    width: "100%",
    height: "100%",
  };

    // Utilisation de xs={2} pour obtenir 2 colonnes par ligne (2 lignes x 2 colonnes)
    return (
      // <Row className="m-1" xs={2}>
      <div style={containerStyle}>
      <div style={gridStyle}>
        {cells.map((cell, index) => (
          <div key={index} style={{ width: "100%", height: "100%" }}>
            {cell}
          </div>
        ))}
      </div>
    </div>
      // </Row>
    );
  };

  return productALL();
};

export default PubProducts;



