import React, { useState} from "react";
import contries from "../../../countries.json";
export default function CardSetupForm({
    laguangeElakay,
    infoSource,
    address,
    token,
    getAddressById,
    setEditCards,
    Customercreate,
    updatePayment,
    PM

}) {
    const [name, setName] = useState(PM.billing_details.name);
    const [addressA, setAddressA] = useState(false);
    const [loadingB, setLoadingB] = useState(true);  
    const [SCard, setSCard] = useState({
        exp_month: PM.card.exp_month,
        exp_year: PM.card.exp_year,
    });
    const {
        exp_month,
        exp_year
    } = SCard;

    const [Saddress, setAddress] = useState({
        city: PM.billing_details.address.city,
        country: PM.billing_details.address.country,
        line1: PM.billing_details.address.line1,
        postal_code: PM.billing_details.address.postal_code,
        state: PM.billing_details.address.state
    });
    const {
        city,
        country,
        line1,
        postal_code,
        state
    } = Saddress;

    const selectAdress = () => event => {
        const IdAdress = event.target.value;
        addressId(token, IdAdress)
    }

    const addressId = (token, adressId) => {

        adressId && (
            getAddressById(token, adressId).then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    setAddress({
                        city: data[0].city,
                        country: data[0].country,
                        line1: data[0].Address1,
                        postal_code: data[0].postalCode,
                        state: data[0].State
                    })
                }
            }))
    }



    const handleSubmit = async (event) => {
        setLoadingB(false)
        token && (
            updatePayment(token, {id: PM.id, BODY:{billing_details: { address: Saddress, name: name }, card: SCard}}).then(data => {
                if (data.error) {
                    console.log("bad connection")
                    setLoadingB(true)
                } else {
                    Customercreate(token)
                    setEditCards(false)
                    setLoadingB(true)
                }
            }));
    };


    const handleChange = name => event => {
        const value = event.target.value;
        setAddress({ ...Saddress, [name]: value });
    };

    const NameChange = () => event => {
        const value = event.target.value;
        setName(value);
    };

    const DateChange = name => event => {
        const value = event.target.value;
        setSCard({ ...SCard, [name]: value });
    };

    const SeleditAddress = () => (
        <>
            <h4>billing Address</h4>
            <h6>{line1}</h6>
            <h6>{city}</h6>
            <h6>{`${state}, ${country}, ${postal_code}`}</h6>
        </>
    )

    const selectAddress = () => (

        <div className="form-group">
            <label className="text-muted float-left"> {infoSource.laguangeElakay('title', infoSource.languageAPI).billingAddress}</label>
            <select onChange={selectAdress()} className="form-control">
                <option value='0'>{infoSource.laguangeElakay('label', infoSource.languageAPI).pleaseSelect}</option>
                {
                    address.map((Adre, i) => (
                        <option key={i} value={Adre._id}>({Adre.firstName} {Adre.lastName}) {Adre.Address1}, {Adre.city} {Adre.State} {Adre.postalCode} {Adre.country}</option>
                    ))
                }
            </select>
            <button onClick={() => setAddressA(true)} type="button" className="float-right btn btn-link mt-0">{infoSource.laguangeElakay('Link', infoSource.languageAPI).addOtherBillAddress}</button>
        </div>
    )

    const formAddress = () => (
        <>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).Address1}</label>
                <div className="controls">
                    <input id="address-line1" onChange={handleChange('line1')} type="text" placeholder={line1} className="form-control" />
                    <p className="help-block">{infoSource.laguangeElakay('title', infoSource.languageAPI).Address1}</p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).city}</label>
                <div className="controls">
                    <input id="city" onChange={handleChange('city')} type="text" placeholder={city} className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).State}</label>
                <div className="controls">
                    <input id="region" onChange={handleChange('state')} type="text" placeholder={state}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).zipCode}</label>
                <div className="controls">
                    <input id="postal-code" onChange={handleChange('postal_code')} type="text" placeholder={postal_code}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="form-group">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).country}</label>
                <div className="controls">
                    <select id="country" value={country} onChange={handleChange('country')} className="form-control" placeholder={country}>
                        <option value="Selected" >{infoSource.laguangeElakay('label', infoSource.languageAPI).selCountry}</option>
                        { contries.map((c,i)=> <option value={c.code}>{c.name}</option>) }              
                    </select>
                </div>

                <button onClick={() => setAddressA(false)} type="button" className="float-right btn btn-link mt-0">{infoSource.laguangeElakay('Button', infoSource.languageAPI).selBillingAddress}</button>
            </div>
        </>
    )
    return (
<>
        <form>
            <div className="form-group ">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).fullName}</label>
                <div className="controls">
                        <input onChange={NameChange("name")} type="text" name="name" id="name" placeholder={name}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            <div className="row">
                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6">
            <div className="form-group ">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).exp_month}</label>
                <div className="controls">
                                <input onChange={DateChange("exp_month")} type="month" id="exp_month" placeholder={exp_month}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
            </div>
            </div>
                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6">
            <div className="form-group ">
                <label className="text-muted float-left">{infoSource.laguangeElakay('label', infoSource.languageAPI).exp_year}</label>
                <div className="controls">
                                <input onChange={DateChange("exp_year")} type="year" id="exp_year" placeholder={exp_year}
                        className="form-control" />
                    <p className="help-block"></p>
                </div>
                </div>
            </div>
            </div>
            <div className="m-4">
                    {SeleditAddress()}
            </div>
            {
                addressA ? formAddress() : selectAddress()
            }
    
        </form>
            <div className="form-group mt-4">
                 <button className="btn btn-outline-primary" onClick={() => setEditCards(false)}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).cancel}</button>
                <button className="btn btn-outline-primary">
                    {loadingB ?
                        <span onClick={() => handleSubmit()}>{infoSource.laguangeElakay('Button', infoSource.languageAPI).edit}</span>
                        :
                        <>
                            <span className="spinner-border spinner-border-sm mr-1" role="status"></span>
                            <span>{infoSource.laguangeElakay('title', infoSource.languageAPI).loanding}</span>
                        </>
                    }
                </button>
            </div>
        </>
    );
}
